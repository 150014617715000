import React from 'react'
import { theme } from 'theme'
import { Fa } from '../styled/muiComponents'

type ChildEntry = {
  key: string,
  label: string,
  path: string
}

type BaseEntry = {
  icon: ((entry: BaseEntry) => React.ReactNode) | React.ReactNode,
  iconSelected: ((entry: BaseEntry) => React.ReactNode) | React.ReactNode,
  color?: string,
  tooltipColor?: string,
  tooltipBackgroundColor?: string
}

type NavEntryWithChildren = {
  children: ChildEntry[]
  key: string
  label: string
} & BaseEntry

export type NavEntry = (BaseEntry & ChildEntry) | NavEntryWithChildren

//first entry is considered default
const navEntries: NavEntry[] = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/app/dashboard",
    icon: entry => <Fa icon={["fal", "tachometer-alt-fast"]} fixedWidth color={entry.color} />,
    iconSelected: entry => <Fa icon={["fas", "tachometer-alt-fast"]} fixedWidth color={entry.color} />,
    color: "#ffffff",
    tooltipColor: "#ffffff",
    tooltipBackgroundColor: theme.palette.primary.main
  },
  /*{
    key: "stock",
    label: "Bestand",
    icon: entry => <Fa icon={["fal", "user"]} fixedWidth color={entry.color}/>,
    color: "#83ac1b",
    path: "/app/stock",
    children: [
      {
        key: "recipients",
        label: "Kontakte",
        path: "/app/stock/recipients"
      },
      {
        key: "webforms",
        label: "Anmeldeformulare",
        path: "/app/stock/webforms"
      },
      {
        key: "qrforms",
        label: "Printmedien",
        path: "/app/stock/qrforms"
      },
      {
        key: "chats",
        label: "Chatbots",
        path: "/app/stock/chats"
      }
    ]
  },*/
  {
    key: "recipients",
    label: "Kontakte",
    path: "/app/stock/recipients",
    icon: entry => <Fa icon={["fal", "user"]} fixedWidth color={entry.color}/>,
    iconSelected: entry => <Fa icon={["fas", "user"]} fixedWidth color={entry.color}/>,
    color: "#e83338",
    tooltipColor: "#ffffff",
    tooltipBackgroundColor: "#e83338"
  },
  {
    key: "webforms",
    label: "Anmeldeformulare",
    path: "/app/stock/webforms",
    icon: entry => <Fa icon={["fal", "square-list"]} fixedWidth color={entry.color}/>,
    iconSelected: entry => <Fa icon={["fas", "square-list"]} fixedWidth color={entry.color}/>,
    color: "#83ac1b",
    tooltipColor: "#ffffff",
    tooltipBackgroundColor: "#83ac1b"
  },
  {
    key: "qrforms",
    label: "Printmedien",
    path: "/app/stock/qrforms",
    icon: entry => <Fa icon={["fal", "print"]} fixedWidth color={entry.color}/>,
    iconSelected: entry => <Fa icon={["fas", "print"]} fixedWidth color={entry.color}/>,
    color: "#269dca",
    tooltipColor: "#ffffff",
    tooltipBackgroundColor: "#269dca"
  },
  {
    key: "reviews",
    label: "Bewertungen",
    path:"/app/stock/ratings",
    icon: entry => <Fa icon={["fal", "star"]} fixedWidth  color={entry.color}/>,
    iconSelected: entry => <Fa icon={["fas", "star"]} fixedWidth  color={entry.color}/>,
    color: "#ffc600",
    tooltipColor: "#000000",
    tooltipBackgroundColor: "#ffc600"
  },
  {
    key: "chats",
    label: "Chatbots",
    path: "/app/stock/chats",
    icon: entry => <Fa icon={["fal", "message-bot"]} fixedWidth color={entry.color}/>,
    iconSelected: entry => <Fa icon={["fas", "message-bot"]} fixedWidth color={entry.color}/>,
    color: "#e300ff",
    tooltipColor: "#ffffff",
    tooltipBackgroundColor: "#e300ff"
  },
  /*{
    key: "bindings",
    label: "Bindung",
    path:"/app/bindings",
    icon: entry => <Fa icon={["fal", "magnet"]} fixedWidth color={entry.color}/>,
    color: "#e300ff"
  },*/
  {
    key: "preferences",
    label: "Einstellungen",
    path: "/app/preferences",
    icon: <Fa icon={["fal", "sliders-h"]} fixedWidth/>,
    iconSelected: <Fa icon={["fas", "sliders-h"]} fixedWidth/>,
    color: "#ffffff",
    tooltipColor: "#ffffff",
    tooltipBackgroundColor: theme.palette.primary.main
  }
]

export { navEntries }