import React, { useState, useMemo } from 'react'
import View from './View'
import Config from './Config'
import { insertPlaceholders } from 'util/placeholders'
import { IPlaceholder } from 'models/webform'
import { makeStyles } from '@material-ui/core'
import { TemplateKey } from 'models/webform'
import { FormValues } from "../../routes/app/stock/webforms/wizard/steps/FirstStep"
import { Address } from "hooks/useSession"
import { Device } from 'types/common'
import { IChats, ITemplateMailSettings } from 'routes/app/stock/webforms/wizard/WebformWizard'
import { Form, FormSpy } from 'react-final-form'

export type ICustomHeader = {
    //header: string | React.ReactNode  
    header: string | React.ReactNode                         // does this create bugs?
    description: string | React.ReactNode
}

type Props = {
    onChange?: (placeholders: Record<string, IPlaceholder>, template: string) => void,
    template: string,
    placeholders: Record<string, IPlaceholder>
    inputGroups: IInputGroup[]
    preConfigComponent?: React.ReactNode
    customConfigHeader: ICustomHeader
    previewIdentifier: TemplateKey
    basicInfo: FormValues | null
    device: Device 
    onDeviceChange: (device: Device) => void
    templateMailSettings: ITemplateMailSettings
    handleChangeMailSettings: (event: {target: {name: keyof IPlaceholder, value: any}}) => void
    chats: IChats | null
    handleLoadMoreChats: () => void
    handleSetChat: (id: number | null) => void
    handleOpenChat: () => void
    wizardState: any
    imprint: Address | null
}

export type IInputGroup = {
    name: string,
    placeholders: string[],
    faIcon: string
    iconType: string
}

export type Field = {
    name: string,
    placeholder: string,
    label: string
}

type Values = Record<string, any>

const useStyles = makeStyles(theme => ({
    editorWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "stretch",
        backgroundColor: theme.palette.primary.light,
    },
    editorConfigSideBar: {
        flex: "0 1 auto",
        backgroundColor: theme.palette.primary.light,
        width: "400px",
        minWidth: "400px",
        height: "100%",
        maxHeight: "100vh",
        borderRadius: "0px",
        padding: "0px",
        overflow: "hidden",
        fontWeight: 300,
    },
    editorPreviewWrapper: {
        flex: "1 1 auto",
        height: "100%",
        maxHeight: "100vh",
        overflowX: "auto",
    }
}))

// const customReplace = (template:string, placeholders:Record<string, Pick<IPlaceholder, "key" | "value" | "default"> | IPlaceholder>, realm:IRealm | null, user:User | null): string => {
//     return insertImprint(insertPlaceholders(template, placeholders), realm, user)
// }

const Editor = ({ handleChangeMailSettings, templateMailSettings, basicInfo, device, onDeviceChange, template, onChange = () => { }, placeholders, inputGroups, preConfigComponent, customConfigHeader, previewIdentifier, chats, handleLoadMoreChats, handleSetChat, handleOpenChat, wizardState, imprint }: Props) => {
    // const [ replacedTemplate, setReplacedTemplate ] = useState<string | undefined>()
    const [chatView, setChatView] = useState<"chat" | "graph">("chat")
    const usedPlaceholderKeys = useMemo(() => (template.match(/\${[^}]*}/g)?.map(p => p.substring(2, p.length-1)) || []), [template])
    const classes = useStyles()

    // const {user, realm} = useSession()

    const handleChangeChatView = (view: "chat" | "graph") => {
        setChatView(view)
    }
    const handleChange = (name: keyof IPlaceholder, value: string) => { // moving to view and using formspy should work
        const newPlaceholders = {
            ...placeholders,
            [name]: {
                ...placeholders[name],
                value
            }
        }
        //  const newTemplate = customReplace(template, newPlaceholders, realm, user)
        // setReplacedTemplate(newTemplate) 
         //onChange(newPlaceholders, template)
         //onChange(newPlaceholders, customReplace(template, newPlaceholders, realm, user)) // ! LOOK FOR WAY TO INSERT IMRPINT WITHOUT RERENDER DUE TO REALM / USER
         onChange(newPlaceholders, insertPlaceholders(template, newPlaceholders))
    }

    // useEffect(() => {
    //     setReplacedTemplate(customReplace(template, placeholders, realm, user))
    // },[template, placeholders, realm, user])

    let initialValues: Values =
        (Object.keys(placeholders) as Array<keyof typeof placeholders>).reduce((res, key) =>
        ({
            ...res,
            [key]: placeholders[key].value ? placeholders[key].value : placeholders[key].default
        })
            , {}) as Record<string, any>
    initialValues = { ...initialValues, subject: templateMailSettings.subject.default ? templateMailSettings.subject.default : templateMailSettings.subject.value }

    /*useEffect(() => { // move to view
        const replacedTemplate = customReplace(template, placeholders, realm, user)
        setReplacedTemplate(replacedTemplate)
    }, [placeholders, realm, template, user])*/

    return (
        <div className={classes.editorWrapper}>
            <Form onSubmit={() => { }} initialValues={initialValues} subscription={{ submitting: true, pristine: true }}
                render={({ handleSubmit, form, submitting, pristine }) =>
                    // <form onSubmit={handleSubmit}>
                    <>
                        <div className={classes.editorConfigSideBar}>
                            {preConfigComponent}
                            <Config previewIdentifier={previewIdentifier} handleChangeMailSettings={handleChangeMailSettings} templateMailSettings={templateMailSettings} customHeader={customConfigHeader} placeholders={placeholders} usedPlaceholderKeys={usedPlaceholderKeys} inputGroups={inputGroups} onChange={handleChange} device={device} onDeviceChange={onDeviceChange} chats={chats} handleLoadMoreChats={handleLoadMoreChats} handleSetChat={handleSetChat} handleOpenChat={handleOpenChat} wizardState={wizardState} handleChangeChatView={handleChangeChatView}/>
                        </div>
                        <div className={classes.editorPreviewWrapper}>
                            <FormSpy subscription={{ values: true }}>
                                {({ values, active }) => (
                                    <View templateMailSettings={templateMailSettings} basicInfo={basicInfo} template={template} placeholders={placeholders} customHeader={customConfigHeader} previewIdentifier={previewIdentifier} device={device} values={values} active={active} wizardState={wizardState} chats={chats} chatView={chatView} imprint={imprint}/>
                                )}
                            </FormSpy>
                        </div>
                        </>
                    //   </form> 
                }
            />
        </div>
    )
}

export default Editor
