import React, { useEffect, useState, useRef } from "react"
import DialogTitle from "dialogs/DialogTitle"
import { Dialog, DialogContent, Tab, Grid, makeStyles, Tabs, Popover } from '@material-ui/core';
import Unsplash, { toJson } from "unsplash-js"
import { Fa, FaButton } from "styled/muiComponents"
import { useTranslation } from "react-i18next"
import URL from "./URL"
import UnsplashImages from "./UnsplashImages"
import UploadedImages from "./UploadedImages"
import UploadImage from "./UploadImage"
import images from "models/images"
import CurrentImage from "./CurrentImage"


function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function TabPanel({ value, index, children }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ overflowY: "auto", width: "100%" }}
        >
            {value === index && (
                <Grid item container style={{ height: "100%" }}>
                    {children}
                </Grid>
            )}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    mediaLibraryTabs: {
        borderRight: "1px solid #cccccc",
        minWidth: "210px"
    },
    tab: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        textTransform: "none",
        "& span": {
            alignItems: "flex-start"
        }
    },
    dialogPaper: {
        height: "80vh",
        overflow: "hidden"
    },
    dialogContent: {
        display: "flex",
        flexGrow: 1,
        padding: 0,
        overflow: "hidden"
    },
    ButtonGridItem: {
        flex: "0 1",
        "& button": {
            padding: "0px",
            color: "white"
        }
    },
    LabelGridItem: {
        flex: "1 1",
        cursor: "context-menu"
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    previewImage: {
        maxHeight: "200px",
        maxWidth: "200px"
    }
}))

const Images = ({ autosize, ...props }) => {

    const { input: { name, value, ...field } } = props
    const classes = useStyles()
    const { t } = useTranslation()
    const unsplash = new Unsplash({
        accessKey: "VypJBwWbSjwvWLm8T0Oq0lnAiDQxv-_hjR-iYB-bFUM"
    })

    const [open, setOpen] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)

    const [deletedImages, setDeletedImages] = useState({})

    const [imageList, setImageList] = useState()
    const [uploadedImage, setUploadedImage] = useState()

    const [unsplashQuery, setUnsplashQuery] = useState("")
    const [unsplashPics, setUnsplashPics] = useState([])
    const [unsplashPage, setUnsplashPage] = useState(1)

    const ContentRef = React.createRef()
    const initialRender = useRef(true)
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleUnsplashQueryChange = (e) => {
        setUnsplashQuery(e.target.value)
    }

    // useEffect(() => {
    //     const fetchNewPageUnsplash = async () => {
    //         unsplash.search
    //             .photos(unsplashQuery, unsplashPage, 28)
    //             .then(toJson)
    //             .then((Json) => { setUnsplashPics([...unsplashPics, ...Json.results]) })
    //     }
    //     fetchNewPageUnsplash()
    // }, [unsplashPage])

    const handleUnsplashSearch = async (event) => {
        event.preventDefault()
        initialRender.current = true
        setUnsplashPage(1)
        unsplash.search
            .photos(unsplashQuery, 1, 28)
            .then(toJson)
            .then((Json) => { setUnsplashPics(Json.results) })
    }

    const handleLoadMoreUnsplash = () => {
        setUnsplashPage(unsplashPage + 1)
    }
    // TODO refactor searches into one function
    const handleTermSearch = (term) => {
        setUnsplashQuery(term)
        initialRender.current = true
        setUnsplashPage(1)
        unsplash.search
            .photos(term, 1, 28)
            .then(toJson)
            .then((Json) => { setUnsplashPics(Json.results) })
    }


    useEffect(() => {
        const fetchImages = async () => {
            const list = await images.getList(1, 100)
            setImageList(list.data)
        }
        fetchImages()
    }, [deletedImages, uploadedImage])

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChangeTabIndex = (event, newValue) => {
        setTabIndex(newValue)
    }

    const selectImage = ({ image }) => {
        props.onChange({ target: { value: image, name: name } })
        field.onChange({ target: { value: image, name: name } })
        setOpen(false)
    }

    const handleRemoveImage = () => {
        props.onChange({ target: { value: "", name: name } })
        field.onChange({ target: { value: "", name: name } })
        setOpen(false)
    }

    const popOverOpen = Boolean(anchorEl);

    return (
        <>
            <Grid container direction="row" alignItems="center" >
                <Grid item className={classes.LabelGridItem}>
                    {props.label}
                </Grid>

                <Grid item className={classes.ButtonGridItem}>
                    {!value || value === "" ? null :
                        <Popover
                            id="mouse-over-popover"
                            className={classes.popover}
                            classes={{
                                paper: classes.paper,
                            }}
                            open={popOverOpen}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                        >
                            <img src={value} className={classes.previewImage} />
                        </Popover>
                    }
                    <FaButton onClick={handleOpen} style={{ margin: "6px" }}>
                        <Fa icon={["fal", "image"]} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} aria-owns={popOverOpen ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true" />
                    </FaButton>

                </Grid>
            </Grid>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg" classes={{ paper: classes.dialogPaper }}>
                <DialogTitle onClose={handleClose}>
                    <Fa icon={["fat", "images"]} className={classes.warningIcon} mr={1.75} />{t("Mediathek")}
                </DialogTitle>
                <DialogContent
                    ref={ContentRef} className={classes.dialogContent}>

                    <Tabs
                        orientation="vertical"
                        indicatorColor="secondary"
                        variant="fullWidth"
                        //scrollbuttons="on"
                        value={tabIndex}
                        onChange={handleChangeTabIndex}
                        className={classes.mediaLibraryTabs}
                    >
                        <Tab label={t("Verwendetes Bild")} {...a11yProps(0)} className={classes.tab} />
                        <Tab label={t("Gespeicherte Bilder")} {...a11yProps(1)} className={classes.tab} />
                        <Tab label={t("Bild hochladen")} {...a11yProps(2)} className={classes.tab} />
                        <Tab label={t("Bilddatenbank")} {...a11yProps(3)} className={classes.tab} />
                        <Tab label={t("Bild von URL")} {...a11yProps(4)} className={classes.tab} />
                        <Tab label={t("Logo Hochladen")} {...a11yProps(5)} className={classes.tab} />
                    </Tabs>
                    <TabPanel value={tabIndex} index={0}>
                        <CurrentImage
                            current={value}
                            handleRemoveImage={handleRemoveImage}
                        />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <UploadedImages
                            imageList={imageList}
                            setDeletedImages={setDeletedImages}
                            selectImage={selectImage}
                        />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        <UploadImage
                            setUploadedImage={setUploadedImage}
                            selectImage={selectImage}
                        />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={3}>
                        <UnsplashImages
                            handleQueryChange={handleUnsplashQueryChange}
                            handleSearch={handleUnsplashSearch}
                            pics={unsplashPics} query={unsplashQuery}
                            selectImage={selectImage}
                            handleTermSearch={handleTermSearch}
                            handleLoadMore={handleLoadMoreUnsplash}
                        />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={4}>
                        <URL
                            selectImage={selectImage}
                        />
                    </TabPanel>
                </DialogContent>
                {/*<DialogActions>
                    <Button onClick={handleClose} variant="contained" color="error">{t("Abbrechen")}</Button>
                </DialogActions>*/}
            </Dialog>
        </>
    )
}



export default Images