import React, {useEffect, useState} from 'react'
import { Card, Grid, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom';
import { useDialog } from 'hooks/useDialog';
import PagePadding from 'components/PagePadding';
import { useSidebar } from 'hooks/useSidebar';
import chats from 'models/chats';
import * as TEMPLATES from "./chatbuilder/templates/index"
// import ChatPreview from './ChatPreview';
import LoadingAnimation from 'components/LoadingAnimation'
import { Fa, Typography } from 'styled/muiComponents'
import ConfirmRemove, { Props as ConfirmRemoveProps } from 'dialogs/ConfirmRemove'
import ConfirmChatRemoveDialog from './ConfirmChatRemoveDialog';
import Button from 'components/Button/Button';
import Zoom from '@material-ui/core/Zoom';
import NoPermission from 'components/NoPermission';
import { IPermissionModules } from 'hooks/useTariff';

const useStyles = makeStyles<Theme, {}>(theme => ({
    dashboardCard: {
        height: "200px",
        width: "200px",
        padding: theme.spacing(3),
        transition: "color 0.3s, box-shadow 0.4s ease",
        "&:hover $cardLink": {
          color: "#e300ff"
        },
        "&:hover": {
          boxShadow: "0px 2px 3px -1px rgb(0 0 0 / 20%), 0px 3px 10px 5px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        },
        "&:hover $createCardIcon": {
            color: "#e300ff"
        },
        "&:hover $actionButtonsWrapper": {
            backgroundColor: "#ffffff"
        }
      },
      createCardIcon: {
        transition: "0.3s all linear",
      },
      cardLink: {
        transition: "0.3s all linear",
      },
      headline: {
        fontSize: "2em",
        fontWeight: 300,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
        display: "flex",
        flexWrap: "nowrap",
        alignContent: "center",
        alignItems: "center",
      },
      purpelHeadlineIcon: {
        color: "#e300ff"
      },
      cardContent: {
       // marginTop: "35px"
      },
      deleteCard: {
        width: "48px",
        marginRight: "-10px",
        marginTop: "-10px",
        "&:hover": {
            color: theme.palette.secondary.main
        }
      },
      actionButtonsWrapper: {
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0
      }
}))

const Chats = () => {
    const classes = useStyles({})
    const {fixedClose} = useSidebar()
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const {dialog} = useDialog()
    const [chatList, setChatList] = useState<any | null>(null)

    useEffect(() => {
        const fetchChats = async () => {
            const list = await chats.getList() //TODO next 10
            setChatList(list.data)
        }
        fetchChats()
    },[])
    const [isShown, setIsShown] = React.useState<Record<number, boolean>>({});
    const handleMouseEnter = (index: number) => {
        setIsShown(prevState => ({
          ...prevState,
          [index]: true
        }));
      };
    
      const handleMouseLeave = (index: number) => {
        setIsShown(prevState => ({
          ...prevState,
          [index]: false
        }));
      };
    const handleCreate = () => {
        fixedClose()
        history.push(`${location.pathname}/builder`)
    }

    const handleEdit = (item: any, type: string) => {
        fixedClose()
        if(type==="template"){
            history.push(`${location.pathname}/builder`,item)
        } else if (type === "saved") {
            history.push(`${location.pathname}/builder/${item.id}`)
        }
    }

    // const handleView = (template:any) => {
    //     dialog.show({
    //         component: ChatPreview,
    //         props:{
    //             template
    //         }
    //     })
    // }

    const checkUsed = async (id: number) => {
        const response = await chats.one(id).checkUse()
        return response
    }

    const handleDelete = async (chat: any) => {
        await checkUsed(chat.id).then((webforms) => {
            if (webforms.length > 0) {
                dialog.show({
                    component: ConfirmChatRemoveDialog,
                    props: {
                        webforms
                    }
                }).then((response) => {
                    if (response.delete) {
                        dialog.show<ConfirmRemoveProps>({
                            component: ConfirmRemove,
                            props: {
                                items: [chat],
                                model: chats
                            }
                        })
                    } else {
                        return
                    }
                })
            } else if (webforms.length === 0) {
                dialog.show<ConfirmRemoveProps>({
                    component: ConfirmRemove,
                    props: {
                        items: [chat],
                        model: chats
                    }
                })
            }
        })
    }

    if(!chatList) {
        return(
            <LoadingAnimation />
        )
    }
    
    return (
        <>
            <NoPermission module={IPermissionModules.chats} />

            <PagePadding>
                <Typography variant='h2' className={classes.headline} style={{marginTop: 0}}>
                    <Fa icon={["fal", "message-bot"]} fixedWidth mr={1} className={classes.purpelHeadlineIcon}/> 
                    {t("Meine Chats")}
                </Typography>
                <Grid container direction="row" spacing={2}>
                    <Grid item>
                        <Card className={classes.dashboardCard} onClick={handleCreate} style={{cursor: "pointer"}}>
                            <Grid container direction='column' justify='center' alignContent='center' alignItems='center' style={{height: "100%"}}>
                                <Grid item>
                                    <Fa icon={["fat", "plus"]} size="4x" style={{display: "block"}} className={classes.createCardIcon} mb={2}/>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.cardLink}>{t("Chat erstellen")}</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    {chatList.map((chat: any, index: number) =>
                        <Grid item >
                            <Card className={classes.dashboardCard} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)}>
                                <Grid container direction="column" justify='center' alignContent='center' style={{height: "100%", position: "relative", textAlign: "center"}}>
                                    <Zoom in={!isShown[index]}>
                                        <Grid container direction="column" justify='center' alignItems='center' style={{height: "100%", position: "relative"}}>
                                            <Grid item className={classes.cardContent}>
                                                <Typography style={{fontSize: "1.25em"}}>{chat.name}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>ID: {chat.id}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Zoom>
                                    {isShown[index] ?
                                        <Grid item container justify='center' alignItems='center' className={classes.actionButtonsWrapper}>
                                            <Zoom in={isShown[index]}>
                                                <Grid container item justify='center' alignItems='center' direction='column'>
                                                    <Grid item>
                                                        <Typography style={{ fontSize: "1em", fontWeight: "bold" }} mb={1}>{chat.name}</Typography>
                                                    </Grid>
                                                    <Grid container item justify='center' alignItems='center'>
                                                       {/* <Button color="viewButton" variant="action" disableElevation size="small" tooltip={t("Anzeigen")} tooltipProps={{ placement: 'bottom' }}>
                                                            <Fa icon={["fas", "eye"]} />
                                                        </Button>*/}
                                                        <Button onClick={() => handleEdit(chat, "saved")} color="editButton" variant="action" disableElevation  tooltip={t("Bearbeiten")} tooltipProps={{ placement: 'bottom' }}>
                                                            <Fa icon={["fas", "wrench"]} />
                                                        </Button>                                               
                                                        <Button onClick={() => handleDelete(chat)} color="deleteButton" variant="action" disableElevation  tooltip={t("Löschen")} tooltipProps={{ placement: 'bottom' }}> {/* TODO reload on delete*/}
                                                            <Fa icon={["fas", "trash"]} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Zoom>
                                        </Grid>
                                    
                                    : null}
                                
                                </Grid>
                            </Card>
                        </Grid>
                    )}
                </Grid>
                <Typography variant='h2' className={classes.headline}>
                    {t("Mit Vorlage beginnen")}
                </Typography>
                <Grid container direction="row" spacing={2}>
                    {Object.values(TEMPLATES).map((tmp: any, idx: number) => { 
                        return (
                            idx === 0 ? // !remove when other templates are actually used
                                <Grid item>
                                    <Card className={classes.dashboardCard} onClick={() => handleEdit(tmp, "template")} style={{cursor: "pointer"}}>
                                        <Grid container direction="column" justify='center' style={{height: "100%"}}>
                                            <Grid item>
                                                <Typography className={classes.cardLink} style={{ fontSize: "1.25em", textAlign: "center" }}>{tmp.name}</Typography>
                                            </Grid>
                                            {/*<Grid item>
                                                <Typography>{JSON.stringify(tmp.id)}</Typography>
                                            </Grid>*/}
                                        </Grid>
                                    </Card>
                                </Grid> :
                                null
                        )
                    }
                    )}
                </Grid>
            </PagePadding>
        </>
    )
}

export default Chats