import { makeStyles, Theme} from "@material-ui/core"
import React from "react"
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTariff, IPermissionModules } from "hooks/useTariff"
import { useTranslation } from "react-i18next"

type Props = {
    module: IPermissionModules
}

const useStyles = makeStyles<Theme, {}>(theme => ({
    NoPermission: {
        marginBottom: theme.spacing(4)
    }
}))

const NoPermission = ({ module }: Props) => {
    const classes = useStyles({})
    const { permissions } = useTariff()
    const { t } = useTranslation()

    console.log(permissions)

    const InfoText = () => {
        switch (module) {
            case (IPermissionModules.pdf_templates): return (
                <>
                    <AlertTitle>{t("Du kannst keine weiteren Printmedien erstellen")}</AlertTitle>
                    {t("Um mehr Printmedien erstellen zu können, lösche entweder bereits erstellte Printmedien oder akuallisiere deinen Tarif.")}
                </>
            )
            case (IPermissionModules.image_volume): return (
                <>
                   <AlertTitle>{t("Du kannst keine weiteren Bilder hochladen")}</AlertTitle>
                    {t("Um weitere Bilder hochladen zu können, musst du entweder bereits hochgeladene Bilder löschen oder deinen Tarif akuallisieren.")}
                </>
            )
            case (IPermissionModules.cornerstone_webforms): return (
                <>
                    <AlertTitle>{t("Du kannst keine weiteren Anmeldestrecken erstellen")}</AlertTitle>
                    {t("Um mehr Anmeldestrecken erstellen zu können, musst du entweder bereits erstellte Strecken löschen oder deinen Tarif akuallisieren.")}
                </>
            )
            default: console.warn(`Unknown module ${module}`); return (
                <>
                    {t("Funktionen eingeschränkt")}
                </>
            )
        }
    }

    return (
        !permissions[module] ?
            <Alert severity="info" className={classes.NoPermission}>
                <InfoText />
            </Alert>
            :
            null
    )
}

export default NoPermission