import React from 'react'

import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  }
});


const DialogContent = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogContent disableTypography className={classes.root} {...other}>
      {children}
    </MuiDialogContent>
  );
});

export default DialogContent