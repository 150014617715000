import React, {useState} from "react"
import { Paper, makeStyles, Grid, Tooltip} from "@material-ui/core"
import { Fa, FaButton } from "styled/muiComponents"
import { useDialog } from "hooks/useDialog"
import ConfirmRemove from "dialogs/ConfirmRemove"
import images from "models/images"
import { useTranslation } from "react-i18next"
import LoadingAnimation from 'components/LoadingAnimation'
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles( theme => ({
    paper: ({
        width: "200px",
        margin: "4px",
        wordWrap: "break-word"

    }),
    highlight: {
        outline: "10px solid red"
    },
    img: {
        height: "100%",
        width: "100%",
    },
    names: {
        color: "black"
    },
    icon: {
        color: theme.palette.primary.main,
        transition: "0.35s all ease",
        "&:hover": {
            color: theme.palette.companyRed.main
        },
    },
    imgContainer: {
        position: "relative",
        height: "200px",
        width: "200px"
    },
    iconBar: {
        position: "absolute",
        top: "20px",
        justifyContent: "space-evenly",
        backgroundColor: "rgba(26,30,45, 0.2)", //theme.palette.companyDark.light
    }
}))

const UploadedImages = ({ imageList, setDeletedImages, selectImage }) => {

    const [largePreviewOpen, setLargePreviewOpen] = useState(false)
    const [largePreviewImage, setLargePreviwImage] = useState()

    const classes = useStyles()
    const { t } = useTranslation()

    if (!imageList) {
        return (
            <div>
                <LoadingAnimation>
                    Loading images
                </LoadingAnimation>
            </div>
        )
    }

    const handleClickEnlarge = (image) => {
        setLargePreviewOpen(true)
        setLargePreviwImage(image)
    }

    const handleClickMinimize = () => {
        setLargePreviewOpen(false)
    }

    return (
        largePreviewOpen ?
            (<Grid container>
                <FaButton className={classes.icon} onClick={handleClickMinimize}>
                    <Fa icon={["fal", "long-arrow-left"]} />
                </FaButton>
                <FaButton classname={classes.icon} onClick={() => selectImage({image: largePreviewImage})}>
                    <Fa icon={["fal", "plus-circle"]} />
                </FaButton>
                <img src={largePreviewImage} alt={t("Große Vorschau des ausgewählten Bildes")}/>
            </Grid>)
            :
            (<Grid container direction="row">
                {imageList.map((image, idx) =>
                    <Grid item key={idx}>
                        <Preview image={image} setDeletedImages={setDeletedImages} selectImage={selectImage} handleClickEnlarge={handleClickEnlarge} />
                    </Grid>
                )}
            </Grid>)
    )
}

const Preview = ({ image, setDeletedImages, selectImage, handleClickEnlarge }) => {

    const { dialog } = useDialog()
    const classes = useStyles()
    const { t } = useTranslation()

    const [isHovered, setIsHovered] = useState(false)
   
    const handleDeleteImage = async () => {
        dialog.show({
            component: ConfirmRemove,
            props: {
                items: [image],
                model: images
            }
        }).then(() => {
            setDeletedImages(image)
        })
    }

    return (
        <PerfectScrollbar>
        <Paper className={classes.paper}>
            <Grid container direction="column" className={classes.imgContainer} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                {isHovered &&
                    <Grid container className={classes.iconBar} alignItems="center">
                        <Grid item>
                            <Tooltip title={t("Löschen")}>
                                <FaButton onClick={handleDeleteImage} className={classes.icon}>
                                    <Fa icon={["fas", "trash"]} />
                                </FaButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={t("Vergrößern")}>
                                <FaButton onClick={() => handleClickEnlarge(`/api/images/${image.id}/getImage`)}>
                                    <Fa icon={["fal", "expand-wide"]} className={classes.icon} />
                                </FaButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={t("Einfügen")}>
                                <FaButton onClick={() => selectImage({ image: image.url })}>
                                    <Fa icon={["fal", "plus-circle"]} className={classes.icon} />
                                </FaButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                }
                <img className={classes.img} src={`/api/images/${image.id}/getImage`} id={image.id} alt={t("Hochgeladenes Bild")}/>
                <p className={classes.names}>{image.name}</p>
            </Grid>
        </Paper>
        </PerfectScrollbar>
    )
}

export default UploadedImages