import recipients from './recipient'
import webforms from './webform'
import domains from "./senderDomain"
import templates from './template'
import user from "./user"
import pdf_templates from "./pdfTemplate"
import customFields from "./customFields"
import imprints from "./imprint"
import cornerstone_domains from "./domain"
import images from "./images"
import tariffs from "./tariffs"
import invoices from './invoices'
import chats from "./chats"
import senderDomain from './senderDomain'
import senderAddresses from './senderAddresses'
import webformOpenTrackings from "./webformOpenTracking"
import ratingOpenTrackings from "./ratingOpenTracking"
import ratings from "./ratings"
import feedbacks from "./feedbacks"
import bundles from './bundles'

const initMap = () => {

}

window.recipients = recipients
window.webforms = webforms
window.domains = domains
window.templates = templates
window.users = user
window.customFields = customFields
window.pdfTemplates = pdf_templates
window.imprints = imprints
window.domain = cornerstone_domains
window.images = images
window.tariffs = tariffs
window.invoices = invoices
window.chats = chats
window.senderDomain = senderDomain
window.senderAddresses = senderAddresses
window.webformOpenTrackings = webformOpenTrackings
window.ratingOpenTrackings = ratingOpenTrackings
window.ratings = ratings
window.feedbacks = feedbacks
window.bundles = bundles
window.initMap = initMap
