//import './wdyr';
import './debug'

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './devices.min.css';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import './fontawesome/solid'
import './fontawesome/regular'
import './fontawesome/light'
import './fontawesome/duotone'
import './fontawesome/thin'

import './models/global'

console.time("loadup")
ReactDOM.render(
    <Root />,
  document.getElementById('root')
);
console.timeEnd("loadup")

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
