import React, { useState } from 'react'
import { Link, Grid, makeStyles } from '@material-ui/core'
import Button from '../../components/Button/Button'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Typography, Fa } from '../../styled/muiComponents'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InputAdornment from '@material-ui/core/InputAdornment'
import users from "models/user"
import { Form } from 'react-final-form'
import FormikInput from 'components/formik/FormikInput'

const validationSchema = yup.object().shape({
    email: yup.string().email().required()
})

const initialValues = {
    email: ""
}

const useTextStyles = makeStyles({
    root: {
        marginTop: "0"
    }
})

const ResetPassword = ({ classes }) => {
    const textfieldclasses = useTextStyles()
    const { t } = useTranslation()
    const [emailSent, setEmailSent] = useState()

    const handleSubmit = async (values) => {
        await users.sendResetPasswordMail(values.email, true)
            .then(() => { setEmailSent(true) })
            .catch(() => { setEmailSent(false) })

    }
   
    if (emailSent !== undefined) {
        return (
            <Grid container direction="column" justify="center" alignItems="stretch" className={classes.root}>
                <Grid item>
                    <img alt="logo" className={classes.logo} src="/assets/images/logo.svg" />
                </Grid>
                <Grid item>
                    <Typography>
                    {emailSent
                        ? t("Die E-Mail für das Zurücksetzten Ihres Passworts wurde soeben verschickt.")
                        : t("Die Email konnte nicht versendet werden")}
                  </Typography>      
                </Grid>
                
                <Grid item className={classes.regpw}>
                    <Typography mb={1}>
                        <Fa icon={["fal", "chevron-left"]} color="secondary.main" fixedWidth mr={1} />
                        <Link component={RouterLink} to="/auth/login"> {t("zurück zum login")}</Link>
                    </Typography>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container direction="column" justify="center" alignItems="stretch" className={classes.root}>
                <Grid item>
                    <img alt="logo" className={classes.logo} src="/assets/images/logo.svg" />
                </Grid>
                <Grid item>
                    <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}
                        render={({ handleSubmit,submitting }) => (
                            <form onSubmit={handleSubmit}>
                                <FormikInput
                                    classes={textfieldclasses}
                                    type="string"
                                    variant="filled"
                                    name="email"
                                    label="E-Mail"
                                    InputProps={
                                        {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Fa icon={["fal", "envelope"]} />
                                                </InputAdornment>
                                            ),
                                        }
                                    } />
                                <Button startIcon={<ExitToAppIcon />} isLoading={submitting} variant="contained" color="success" type="submit">
                                    {t("Passwort wiederherstellen")}
                                </Button>
                            </form>
                        )}
                    />
                </Grid>
                <Grid item>
                    <Grid item className={classes.regpw}>
                        <Typography mb={1}>
                            <Fa icon={["fal", "chevron-left"]} color="secondary.main" fixedWidth mr={1} />
                            <Link component={RouterLink} to="/auth/login"> {t("zurück zum login")}</Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

        )
    }
}

export default ResetPassword