import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import MuiButton, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress'
import { TooltipProps } from '@material-ui/core';
import Tooltip from './Tooltip';
import Zoom from '@material-ui/core/Zoom'

import { ColorOption } from '../../types/palette'


type Props = {
    isLoading?: boolean,
    tooltip?: React.ReactNode,
    disabledTooltip?: React.ReactNode,
    color?: ColorOption,
    tooltipProps?: Omit<TooltipProps, "children" | "title">,
    variant?: "text" | "contained" | "outlined" | "action"
    target?: string
    component?: string
} & Omit<ButtonProps, "color" | "variant">

/*const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiButton-sizeSmall": {

        }
    }
}))*/


const useStyles = makeStyles(({ palette }) => ({
    root: ({ color, variant, sizeGiven }: { color: ColorOption, variant?: "text" | "contained" | "outlined" | "action" , sizeGiven: boolean}) =>

        (variant === 'contained' ? {
            color: palette[color].contrastText,
            backgroundColor: palette[color].main,
            border: "none",
            padding: (sizeGiven) => !sizeGiven ? "6px 18px" : "none",
            textTransform: "none",
            "&:hover": {
                backgroundColor: palette[color].dark,
                color: palette[color].contrastText,
                border: "none",
                // Reset on touch devices, it doesn't add specificity
                "@media (hover: none)": {
                    backgroundColor: palette[color].main
                }
            }
        } : variant === 'text' ? {
            color: palette[color].main,
            backgroundColor: "transparent",
            border: "none",
            textTransform: "none",
            "&:hover": {
                backgroundColor: "transparent",
                color: palette[color].dark,
                border: "none",
                "@media (hover: none)": {
                    color: palette[color].main
                }
            }
        } : variant === 'action' ? {
                    textTransform: "none",
                    color: palette[color].contrastText,
                    backgroundColor: palette[color].main,
                    border: `1px solid ${palette[color].main}`,
                    transition: "0.5s all ease",
                    minWidth: "10px",
                    padding: "3px 5px",
                    marginRight: "3px",
                    width: "25px",
                    height: "22px",
                    fontSize: "11px",
                    "&:hover": {
                        color: palette[color].main,
                        backgroundColor: '#ffffff',
                        border: `1px solid ${palette[color].main}`,
                        "@media (hover: none)": {
                            color: palette[color].main
                        }
                    }
                } : {
                        textTransform: "none",
                        color: palette[color].main,
                        backgroundColor: "#ffffff",
                        border: `1px solid ${palette[color].main}`,
                        "&:hover": {
                            color: palette[color].dark,
                            backgroundColor: '#ffffff',
                            border: `1px solid ${palette[color].dark}`,
                            "@media (hover: none)": {
                                color: palette[color].main
                            }
                        }
                    }),
    "sizeSmall": {
        lineHeight: "0",
        padding: "10px 12px",
    },
    /*"sizeMedium": {
        lineHeight: "0",
        padding: "12px 14px",
    },*/
    "sizeLarge": {
        lineHeight: "0",
        padding: "14px 16px",
    },
}))

const Button: React.FC<Props> = React.forwardRef(({ color = "primary" as ColorOption, children, isLoading = false, disabled = false, startIcon = null, tooltip = null, disabledTooltip = null, variant, tooltipProps = {}, ...props }, ref) => {
    const sizeGiven = props.size ? true : false
    const classes = useStyles({ color, variant, sizeGiven })

    const ButtonElement = <MuiButton
        classes={classes}
        startIcon={
            isLoading ?
                //<CircularProgress size={20} style={{ color: color && palette[color].main }} />
                <CircularProgress size={15} style={{ color: 'inherit' }} />
                : startIcon}
        disabled={isLoading || disabled}
        ref={ref}
        {...props}
    >
        {children}
    </MuiButton>



    if (tooltip && disabled === false) {
        return (
            <Tooltip enterDelay={250} title={tooltip} arrow TransitionComponent={Zoom} {...tooltipProps}>
                {ButtonElement}
            </Tooltip>
        )
    }
    if (disabledTooltip && disabled === true) {
        //wrapper needed because disabled buttons do not fire userinteraction events
        return (
            <Tooltip title={disabledTooltip} arrow TransitionComponent={Zoom} {...tooltipProps}>
                <div style={{ display: "unset" }}>
                    {ButtonElement}
                </div>
            </Tooltip>
        )
    } else {
        return ButtonElement
    }
})

Button.defaultProps = {
    isLoading: false,
    disabled: false
}

export default Button