import React, { useContext } from 'react'

import { DatePicker } from '@material-ui/pickers'

import FormControl from '@material-ui/core/FormControl'
import { MuiPickersContext } from '@material-ui/pickers'
import { dateFormatMap } from '../../constants/formatMap'

const Date = ({ label, ...props }) => {
  const { locale } = useContext(MuiPickersContext)
  const { input: { name, value, ...field } } = props

  const handleBlur = (date) => {
    if (props.onBlur) {
      props.onBlur({ target: { value: date, name: field.name } })
    }
    field.onBlur({ target: { value: date, name: field.name } })
  }

  const handleChange = (date) => {

    if (props.onChange) {
      props.onChange({ target: { value: date, name: field.name } })
    }
    field.onChange({ target: { value: date, name: field.name } })
  }

  return (
    <FormControl fullWidth className={"MuiFormControl-marginNormal"}>
      <DatePicker
        inputVariant={props.variant ?? "outlined"}
        value={value}
        {...field}
        label={label}
        format={dateFormatMap[locale]}
        {...props}
        onChange={date => handleChange(date)}
        onBlur={date => handleBlur(date)}
      />
    </FormControl>
  )
}

export default Date