import React, { useState, useEffect, useCallback } from "react"
import DialogTitle from "dialogs/DialogTitle"
import { Dialog, DialogContent, Box, Grid, makeStyles, Tabs, Tab, Divider, Theme, Radio } from "@material-ui/core"
import { DialogProps } from "hooks/useDialog"
import DialogActions from "dialogs/DialogActions"
import FormikInput from "components/formik/FormikInput"
import { useTranslation } from "react-i18next"
import Button from "components/Button/Button"
import { Fa } from "styled/muiComponents"
import imprints from "models/imprint"
import { Form } from "react-final-form"
import { Typography } from 'styled/muiComponents'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles<Theme, { tabFilledIn: boolean[] }>(theme => ({
    title: {
        backgroundColor: theme.palette.primary.light,
        color: "white"
    },
    divider: {
        backgroundColor: theme.palette.companyRed.main,
        height: "2px"
    },
    mediaLibraryTabs: {
        borderRight: "1px solid #cccccc",
        minWidth: "250px"
    },
    tab: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        textTransform: "none",
        "& span": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start"
        },
        "& svg": {
            marginBottom: "4px !important",
            marginRight: "8px"
        }
    },
    tabPanelWrapper: {
        flexGrow: 1,
        overflow: "auto"
    },
    dialogPaper: {
        height: "80vh",
        overflow: "hidden"
    },
    dialogContent: {
        display: "flex",
        flexGrow: 1,
        padding: "0px !important",
        overflow: "hidden"
    },
    tabIconCompany: {
        color: ({ tabFilledIn }) => tabFilledIn[1] ? theme.palette.success.main : theme.palette.companyRed.main,
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ tabFilledIn }) => tabFilledIn[1] ? "1" : "0.5",
        }
    },
    tabIconManagment: {
        color: ({ tabFilledIn }) => tabFilledIn[2] ? theme.palette.success.main : theme.palette.companyRed.main,
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ tabFilledIn }) => tabFilledIn[2] ? "1" : "0.5",
        }
    },
    tabIconRegister: {
        color: ({ tabFilledIn }) => tabFilledIn[3] ? theme.palette.success.main : theme.palette.companyRed.main,
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ tabFilledIn }) => tabFilledIn[3] ? "1" : "0.5",
        }
    },
    tabIconTax: {
        color: ({ tabFilledIn }) => tabFilledIn[4] ? theme.palette.success.main : theme.palette.companyRed.main,
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ tabFilledIn }) => tabFilledIn[4] ? "1" : "0.5",
        }
    },
    tabIconJob: {
        color: ({ tabFilledIn }) => tabFilledIn[5] ? theme.palette.success.main : theme.palette.companyRed.main,
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ tabFilledIn }) => tabFilledIn[5] ? "1" : "0.5",
        }
    },
    tabIconExtra: {
        color: ({ tabFilledIn }) => tabFilledIn[6] ? theme.palette.success.main : theme.palette.companyRed.main,
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ tabFilledIn }) => tabFilledIn[6] ? "1" : "0.5",
        }
    }
}))

const useStylesTooltip = makeStyles((theme: Theme) => ({
    arrow: {
      color: theme.palette.primary.main,
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
    },
}));
function CPTooltip(props: TooltipProps) {
    const classes = useStylesTooltip();
  
    return <Tooltip arrow classes={classes} {...props} />;
  }
type Props = {
    fetchedImprint: any
}

type TabPanelProps = {
    value: number,
    index: number,
    children?: React.ReactNode
}

function TabPanel({ value, index, children }: TabPanelProps) {

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{width: "100%"}}
        >
            {value === index && (
                <Box p={3} >
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const ChangeImprintDialog = ({ onClose, open, onError, fetchedImprint }: DialogProps & Props) => {
// const testing={
//         gender: "male",
//         first_name: "Max",
//         last_name: "Mustermann",
//         city: "Musterstadt",
//         country:"Musterland",
//         imprint_mail:"muster@beispiel.de",
//         phone_number: "0123456789",
//         postal_code: "12345",
//         street_number:"12",
//         vat_identifier:"0123456789",
//         ust_identifier:"9876543210",
//         street_name: "Musterstraße",
//         company_name:"Musterfirma",
//         legal_form: "Gesellschaft mit beschränkter Haftung (GmbH)",
//         company_board: "Chef",                   // saves Geschäftsführer or Vostand depending on legal form
//         supervisory_board: "Vertretung",               // saves Vetretungsberechtiger or Aufsichtsrat depending on legal form
//         company_register: "Handelsregister",
//         registry_court: "gericht",
//         registry_number: "1234567",
//         supervisory_authority: "Aufsichtsbehörde",
//         responsible_chamber: "zuständige kammer",
//         job_title_plus_country_awarded: "Musterjob, Deutschland",
//         professional_regulations: "Regelungen",
//         additional_information:"extras"
//     }

    const { t } = useTranslation()
    const [tabFilledIn, setTabFilledIn] = useState([true, true, true, true, true, true, true])
    const classes = useStyles({ tabFilledIn })
    const [tabIndex, setTabIndex] = useState(0)
    const [imprint, setImprint] = useState<any>(fetchedImprint)
    const [disableButton, setDisableButton] = useState(false)

    useEffect(() => {
        if (tabFilledIn.every(item => item === true)) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    }, [tabFilledIn])

    const initialValues = (imprint)

    const handleChangeTabIndex = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue)
    }

    const handleChangeTabFilled = useCallback((value: boolean, index: number) => {
        setTabFilledIn(
            tabFilledIn.map((item, idx) => {
                if (index === idx) {
                    return value
                } else {
                    return item
                }
            }))
    }, [tabFilledIn])

    const handleSubmit = async (values: any) => {
        await imprints.update(imprint.id, values)
        onClose({ success: true, imprint })
    }

    const handleClose = () => {
        onClose({ action:null })
    }

    const handleChangeImprint = (event: any) => {
        setImprint({ ...imprint, [event.target.name]: event.target.value })
    }

    if(!imprint){
        return <p> loading </p> // TODO loading animation
    }

    return (
        <Dialog open={open} fullWidth maxWidth="lg" classes={{ paper: classes.dialogPaper }}>
            <DialogTitle onClose={handleClose}>
                <Fa icon={["fal", "edit"]} className={classes.warningIcon} mr={1.75} />{t("Impressum bearbeiten")}
            </DialogTitle>
           
                <Form initialValues={initialValues} onSubmit={handleSubmit}
                    render={({ handleSubmit, submitting }) => (
                        <form  onSubmit={handleSubmit} style={{flexGrow: 1}}>
                             <DialogContent className={classes.dialogContent}>
                                <Tabs
                                    orientation="vertical"
                                    indicatorColor="secondary"
                                    variant="fullWidth"
                                    scrollButtons="on"
                                    value={tabIndex}
                                    onChange={handleChangeTabIndex}
                                    className={classes.mediaLibraryTabs}
                                >
                                    
                                    <Tab icon={<Fa icon={["fal", "industry-alt"]} fixedWidth />} label={t("Allgemeine Daten")} className={classes.tab} {...a11yProps(0)} />
                                    <Tab icon={<Fa icon={["fal", "user-tie"]} fixedWidth />}  label={t("Geschäftsführer")} className={classes.tab} {...a11yProps(1)} />                                    
                                    <Tab icon={<Fa icon={["fal", "landmark"]}  fixedWidth />} label={t("Register")} className={classes.tab} {...a11yProps(2)} />
                                    <Tab icon={<Fa icon={["fal", "coins"]} fixedWidth />} label={t("Ust-ID & Steuernr.")} className={classes.tab} {...a11yProps(3)} />
                                    <Tab icon={<Fa icon={["fal", "briefcase"]} fixedWidth/>} label={t("Behördliche Zulassung")} className={classes.tab} {...a11yProps(4)} />
                                    <Tab icon={<Fa icon={["fal", "money-check-edit"]} fixedWidth />} label={t("Zusätliche Angaben")} className={classes.tab} {...a11yProps(5)} />

                                </Tabs>          
                                <Grid container item className={classes.tabPanelWrapper} >
                                    <TabPanel value={tabIndex} index={0}>
                                        <CompanyInformation
                                            handleChangeImprint={handleChangeImprint}
                                            imprint={imprint}
                                            handleChangeTabFilled={handleChangeTabFilled}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={1}>
                                        <CompanyManagement
                                            handleChangeImprint={handleChangeImprint}
                                            imprint={imprint}
                                            handleChangeTabFilled={handleChangeTabFilled}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={2}>
                                        <CompanyRegister
                                            handleChangeImprint={handleChangeImprint}
                                            imprint={imprint}
                                            handleChangeTabFilled={handleChangeTabFilled}
                                            fetchedImprint={fetchedImprint}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={3}>
                                        <TaxNumbers
                                            handleChangeImprint={handleChangeImprint}
                                            imprint={imprint}
                                            handleChangeTabFilled={handleChangeTabFilled}
                                            fetchedImprint={fetchedImprint}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={4}>
                                        <Job
                                            handleChangeImprint={handleChangeImprint}
                                            imprint={imprint}
                                            handleChangeTabFilled={handleChangeTabFilled}
                                            fetchedImprint={fetchedImprint}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={5}>
                                        <Extra
                                            handleChangeImprint={handleChangeImprint}
                                            imprint={imprint}
                                            handleChangeTabFilled={handleChangeTabFilled}
                                        />
                                    </TabPanel>
                                </Grid>
                            </DialogContent>
                            <Divider />
                            <DialogActions>
                                <Grid container item direction="row" justify="space-between" alignItems="center">
                                    <Button onClick={handleClose} variant="contained" color="deleteButton" size="small"><Fa icon={["fas", "times"]} mr={1} /> {t("Abbrechen")}</Button>
                                    <CPTooltip title={t("Um Änderungen nach dem Speichern zu sehen, muss die Seite aktualisiert werden.")!} placement="bottom" arrow>
                                        <Button type="submit" isLoading={submitting} variant="contained" color="success" disabled={disableButton} size="small"><Fa icon={["fas", "check"]} mr={1} /> {t("Speichern")}</Button>
                                    </CPTooltip>
                                </Grid>
                            </DialogActions>
                        </form>)
                    }
                />
        </Dialog>
    )
}

const CompanyInformation = ({ handleChangeImprint, handleChangeTabFilled, imprint }: { handleChangeImprint: (event: any) => void, handleChangeTabFilled: (value: boolean, index: number) => void, imprint: any }) => {
    const { t } = useTranslation()

    useEffect(() => {
        if (imprint.legal_form === "Andere") {
            if (imprint.company_name && imprint.legal_form_offlist && imprint.postal_code && imprint.city && imprint.street_name && imprint.street_number && imprint.imprint_mail && imprint.phone_number) {
                handleChangeTabFilled(true, 1)
            } else {
                handleChangeTabFilled(false, 1)
            }
        } else if (imprint.company_name && imprint.legal_form && imprint.postal_code && imprint.city && imprint.street_name && imprint.street_number && imprint.imprint_mail && imprint.phone_number) {
            handleChangeTabFilled(true, 1)
        } else {
            handleChangeTabFilled(false, 1)
        }
    }, [imprint.company_name, imprint.legal_form, imprint.postal_code, imprint.city, imprint.street_name, imprint.street_number, imprint.imprint_mail, imprint.phone_number, imprint.legal_form_offlist])

    return (
        <Grid item container>
            <Typography variant="h2" mb={2}>{t("Allgemeine Daten")}</Typography>
            <Grid container item spacing={3}>
                <Grid item sm={6}>
                    <FormikInput
                        type="string"
                        name="company_name"
                        label={t("Name des Unternehmens (inklusive Rechtsform)")}
                        variant="outlined"
                        onChange={handleChangeImprint}
                        required
                    />
                </Grid>
                <Grid item sm={6}>
                    <FormikInput
                        type="enum"
                        name="legal_form"
                        label={t("Rechtsform *")}
                        variant="outlined"
                        onChange={handleChangeImprint}
                        labelProps={{
                            variant: "outlined"
                        }}
                        typeParams={[
                            "Einzelunternehmen",
                            "Gesellschaft bürgerlichen Rechts (GbR)",
                            "Eingetragener Kaufmann (e.K.)",
                            "Offene Handelsgesellschaft (OHG)",
                            "Kommanditgesellschaft (KG)",
                            "Gesellschaft mit beschränkter Haftung (GmbH)",
                            "Aktiengesellschaft (AG)",
                            "GmbH & Co KG",
                            "Verein (e. V.)",
                            "Genossenschaft (e. G.)",
                            "Andere"
                        ]}
                        required
                    />
                </Grid>
            </Grid>
            {imprint.legal_form === "Andere" ?
                <Grid item sm={12}>
                    <FormikInput
                        type="string"
                        name="legal_form_offlist"
                        label={t("Keine der angegebenen Rechtsformen trifft zu?")}
                        variant="outlined"
                        onChange={handleChangeImprint}
                    />
                </Grid>
            : null}
                <Grid container item spacing={3}>
                    <Grid item sm={4} >
                        <FormikInput
                            type="string"
                            name="postal_code"
                            label={t("Postleitzahl")}
                            onChange={handleChangeImprint}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={8}>
                        <FormikInput
                            type="string"
                            name="city"
                            label={t("Stadt")}
                            onChange={handleChangeImprint}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item sm={8}>
                        <FormikInput
                            type="string"
                            name="street_name"
                            label={t("Straße")}
                            onChange={handleChangeImprint}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={4}>
                        <FormikInput
                            type="string"
                            name="street_number"
                            label={t("Hausnummer")}
                            onChange={handleChangeImprint}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={3}>
                    <Grid item sm={6}>
                        <FormikInput
                            type="string"
                            name="imprint_mail"
                            label={t("Impressums-E-Mail")}
                            onChange={handleChangeImprint}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <FormikInput
                            type="string"
                            name="phone_number"
                            label={t("Telefonnummer")}
                            onChange={handleChangeImprint}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
        </Grid>
    )
}

const CompanyManagement = ({ handleChangeImprint, handleChangeTabFilled, imprint }: { handleChangeImprint: (event: any) => void, handleChangeTabFilled: (value: boolean, index: number) => void, imprint: any }) => {
    const { t } = useTranslation()

    useEffect(() => {
        if (imprint.company_board) {
            handleChangeTabFilled(true, 2)
        } else {
            handleChangeTabFilled(false, 2)
        }
    }, [imprint.company_board, imprint.supervisory_board])

    if (["GbR", "OHG", "KG", "GmbH", "GmbH & Co KG", "Andere", "AG", "e. G.", "Einzelunternehmen"].some(v => (imprint.legal_form).includes(v))) {
        return (
            <Grid item container direction="column" >
                <Typography variant="h2" mb={2}>{t("Geschäftsführer")}</Typography>
                <Alert severity="info" style={{marginBottom: "16px"}}>{t("Bitte vollständigen Namen angeben.")}</Alert>
                <Grid container item spacing={3} >
                    <Grid item sm={12}>
                        <FormikInput
                            type="string"
                            name="company_board"
                            label={
                                (["GbR", "OHG", "KG", "GmbH", "GmbH & Co KG", "Andere"].some(v => (imprint.legal_form).includes(v)))
                                    ?
                                    t("Geschäftsführer")
                                    :
                                    (["AG", "e. G."].some(v => (imprint.legal_form.includes(v))))
                                        ?
                                        t("Vorstand")
                                        : null
                            }
                            variant="outlined"
                            onChange={handleChangeImprint}
                            required
                            />
                    </Grid>
                </Grid>
                <Grid container item spacing={3} >
                    <Grid item sm={12}>
                        <FormikInput
                            type="string"
                            name="supervisory_board"
                            label={
                                (["GbR", "OHG", "KG", "GmbH", "GmbH & Co KG", "Andere"].some(v => (imprint.legal_form).includes(v)))
                                    ?
                                    t("Vertretungsberechtigter")
                                    :
                                    (["AG", "e. G."].some(v => (imprint.legal_form.includes(v))))
                                        ?
                                        t("Aufsichtsrat")
                                        : null
                            }
                            variant="outlined"
                            onChange={handleChangeImprint}
                        />
                    </Grid>
                </Grid>
            </Grid> 
            
        )
    } else {
        return (
            <p>{t(`Da dein Unternehmen die Rechtsform ${imprint.legal_form} hat, kannst du hier keine Einstellungen vornehmen.`)}</p>
        )
    }
}

const CompanyRegister = ({ handleChangeImprint, handleChangeTabFilled, imprint, fetchedImprint }: { handleChangeImprint: (event: any) => void, handleChangeTabFilled: (value: boolean, index: number) => void, imprint: any, fetchedImprint: any }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(() => {
        if(fetchedImprint.company_register || fetchedImprint.company_register_offlist || fetchedImprint.registry_court || fetchedImprint.registry_number){
            return true
        } else {
            return false
        }
    })

    useEffect(() => {
        if (open) {
            if (imprint.company_register === "Andere") {
                if (imprint.company_register_offlist && imprint.registry_court && imprint.registry_number) {
                    handleChangeTabFilled(true, 3)
                } else {
                    handleChangeTabFilled(false, 3)
                }
            } else if (!imprint.company_register_offlist && imprint.company_register && imprint.registry_court && imprint.registry_number) {
                handleChangeTabFilled(true, 3)
            }
        } else if (!(imprint.company_register && imprint.registry_court && imprint.registry_number && imprint.registry_court && imprint.registry_number && imprint.company_register_offlist)) {
            handleChangeTabFilled(true, 3)
        }
    }, [open, imprint.company_register, imprint.company_register_offlist, imprint.registry_court, imprint.registry_number])

    const ShowWhenOpen = () => {
        return (
            
                <Grid container item>
                    <Grid container item spacing={3} >
                        <Grid item sm={12}>
                            <FormikInput
                                type="enum"
                                name="company_register"
                                label={t("Register")}
                                variant="outlined"
                                onChange={handleChangeImprint}
                                typeParams={[
                                    "Handelsregister",
                                    "Genossenschaftsregister",
                                    "Partnerschaftsregister",
                                    "Vereinsregister",
                                    "Güterrechtsregister",
                                    "Schiffsregister",
                                    "Andere",
                                    undefined
                                ]}
                                labelProps={{
                                    variant: "outlined"
                                }}
                            />
                        </Grid>
                    </Grid>
                        {imprint.company_register === "Andere" ?
                            <Grid container spacing={3} >
                                <Grid item sm={12}>
                                    <FormikInput
                                        type="string"
                                        name="company_register_offlist"
                                        label={t("Andere")}
                                        variant="outlined"
                                        onChange={handleChangeImprint}
                                    />
                                </Grid>
                            </Grid>
                            : null}
                    <Grid container spacing={3} >
                        <Grid item sm={6}>
                            <FormikInput
                                type="string"
                                name="registry_court"
                                label={t("Registergericht")}
                                variant="outlined"
                                onChange={handleChangeImprint}
                                required
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <FormikInput
                                type="string"
                                name="registry_number"
                                label={t("Registernummer")}
                                variant="outlined"
                                onChange={handleChangeImprint}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
        )
    }

    return (
        <>
            <Grid item container >
                <Typography variant="h2" mb={2}>{t("Ist dein Unternehmen in einem Register eingetragen?")}</Typography>
                <Grid item container>
                    <RadioGroup>
                        <Grid item container>
                            <Grid item >
                                <FormControlLabel value={t("Nein")} control={<Radio checked={!open} onClick={() => setOpen(false)} />} label={t("Nein")} />
                            </Grid>
                            <Grid item>
                                <FormControlLabel value={t("Ja")} control={<Radio checked={open} onClick={() => setOpen(true)} />} label={t("Ja")} />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>
                <Collapse in={open} style={{width: "100%"}}>
                    {ShowWhenOpen()}
                </Collapse>
            </Grid>
        </>
    )
}

const TaxNumbers = ({ handleChangeImprint, handleChangeTabFilled, imprint, fetchedImprint }: { handleChangeImprint: (event: any) => void, handleChangeTabFilled: (value: boolean, index: number) => void, imprint: any, fetchedImprint: any }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(() => {
        if(fetchedImprint.ust_identifier){
            return false
        } else {
            return true
        }
    })

    useEffect(() => {
        if (open) {
            if (!imprint.ust_identifier && imprint.vat_identifier) {
                handleChangeTabFilled(true, 4)
            } else {
                handleChangeTabFilled(false, 4)
            }
        } else {
            if (imprint.ust_identifier && imprint.vat_identifier) {
                handleChangeTabFilled(true, 4)
            } else if (imprint.ust_identifier){
                handleChangeTabFilled(true, 4)
            } else {
                handleChangeTabFilled(false, 4)
            }
        }
    }, [open, imprint.ust_identifier, imprint.vat_identifier])

    return (
        <Grid item container>
            <Typography variant="h2" mb={2}>{t("Steuernummer und Umsatzsteuer-Identifikationsnummer")}</Typography>
            <Alert severity="info" style={{marginBottom: "16px"}}>{t('Solltest du oder dein Unternehmen eine Umsatzsteuer-Identifikationsnummer haben, dann muss diese angegeben werden. In dem Fall ist die Steuernummer optional. Aktiviere "Ich habe eine Ust-ID", um deine Ust-ID zu hinterlegen.')}</Alert>
            <Grid container item spacing={3}>
                <Grid item sm={12}>
                    <FormikInput
                        name="useUID"
                        type="switch"
                        label={t('Ich habe eine Ust-ID (Umsatzsteuer-Identifikationsnummer)')}
                        onChange={() => {setOpen(!open); handleChangeImprint({target: {name: "ust_identifier", value: ""}}) }}
                        checked={open}
                        />
                </Grid>
            </Grid>
            <Collapse in={!open} style={{width: "100%"}}>
                <Grid container item spacing={3}>
                    <Grid item sm={12}>
                        <FormikInput
                            type="string"
                            name="ust_identifier"
                            label={t("Umsatzsteuer-Identifikationsnummer")}
                            variant="outlined"
                            onChange={handleChangeImprint}
                            disabled={open}
                        />
                    </Grid>
                </Grid>
            </Collapse>
            <Grid container item spacing={3}>
                <Grid item sm={12}>
                    <FormikInput
                        type="string"
                        name="vat_identifier"
                        label={t("Steuernummer")}
                        variant="outlined"
                        onChange={handleChangeImprint}
                        required={imprint.ust_identifier === ""}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

const Job = ({ handleChangeImprint, handleChangeTabFilled, imprint, fetchedImprint }: { handleChangeImprint: (event: any) => void, handleChangeTabFilled: (value: boolean, index: number) => void, imprint: any, fetchedImprint: any }) => {
    const { t } = useTranslation()

    const [open, setOpen] = useState(!(!fetchedImprint.supervisory_authority))

    useEffect(() => {
        if (open) {
            if (imprint.supervisory_authority && imprint.responsible_chamber && imprint.job_title_plus_country_awarded && imprint.professional_regulations) {
                handleChangeTabFilled(true, 5)
            } else {
                handleChangeTabFilled(false, 5)
            }
        } else {
            if ((!imprint.supervisory_authority && !imprint.responsible_chamber && !imprint.job_title_plus_country_awarded && !imprint.professional_regulations)) {
                handleChangeTabFilled(true, 5)
            } else {
                handleChangeTabFilled(false, 5)
            }
        }
    }, [open, imprint.supervisory_authority, imprint.responsible_chamber, imprint.job_title_plus_country_awarded, imprint.professional_regulations])

    const ShowWhenOpen = () => {
        return (
            <Grid item container>
                <Grid container spacing={3}>
                    <Grid item md={6}>
                        <FormikInput
                            type="string"
                            name="supervisory_authority"
                            label={t("Aufsichtsbehörde")}
                            variant="outlined"
                            onChange={handleChangeImprint}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <FormikInput
                            type="string"
                            name="responsible_chamber"
                            label={t("Zuständige Kammer")}
                            variant="outlined"
                            onChange={handleChangeImprint}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md>
                        <FormikInput
                            type="string"
                            name="job_title_plus_country_awarded"
                            label={t("Gesetzliche Berufsbezeichnung")}
                            variant="outlined"
                            onChange={handleChangeImprint}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item md>
                        <FormikInput
                            type="string"
                            multiline={true}
                            name="professional_regulations"
                            label={t("Berufsrechtliche Regelungen")}
                            variant="outlined"
                            onChange={handleChangeImprint}
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Grid item container>
                <Typography variant="h2" mb={2}>{t("Bedarf die Tätigkeit deines Unternehmens einer behördlichen Zulassung?")}</Typography>
                <Grid item container>
                    <RadioGroup>
                        <Grid item container>
                            <Grid item>
                                <FormControlLabel value={t("Nein")} control={<Radio checked={!open} onClick={() => setOpen(false)} />} label={t("Nein")} />
                            </Grid>
                            <Grid item >
                                <FormControlLabel value={t("Ja")} control={<Radio checked={open} onClick={() => setOpen(true)} />} label={t("Ja")} />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>
                <Collapse in={open} style={{width: "100%"}}>
                    {ShowWhenOpen()}
                </Collapse>
            </Grid>
        </>
    )
}

const Extra = ({ handleChangeImprint, handleChangeTabFilled, imprint }: { handleChangeImprint: (event: any) => void, handleChangeTabFilled: (value: boolean, index: number) => void, imprint: any }) => {
    const { t } = useTranslation()

    return (
        <Grid item container>
            <Typography variant="h2" mb={2}>{t("Zusätzliche Angaben")}</Typography>
            <FormikInput
                type="string"
                name="additional_information"
                label={t("Zusätzliche Angaben")}
                onChange={handleChangeImprint}
                variant="outlined"
                multiline={true}
                rows={4}
            />
        </Grid>
    )
}

export default ChangeImprintDialog