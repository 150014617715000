import React from 'react'
import MUIDataTable, { FilterType, CustomMUIDataTableColumnDef, CustomMUIDataTableOptions, CustomDownloadParams } from "../../../../components/table/MUIDataTable";
import recipientApi, { IRecipient } from '../../../../models/recipient'
import { Fa } from '../../../../styled/muiComponents'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Button/Button'
import { useDialog } from '../../../../hooks/useDialog';
import ConfirmRemove, { Props as ConfirmRemoveProps, Response as ConfirmRemoveResponse } from '../../../../dialogs/ConfirmRemove'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom';
import { RecipientLocationState, EditState } from './Recipient'
import AddIcon from '@material-ui/icons/Add'
import { IconButton } from '@material-ui/core'
import ExportDialog, { Props as ExportDialogProps } from 'dialogs/Export';
//import AddCustomField, { Response as AddCustomFieldsResponse } from "./AddCustomField"
import PagePadding from "components/PagePadding"
import AddRecipient from "./AddRecipient"
import { useAlert } from "hooks/useAlert"

const columns: CustomMUIDataTableColumnDef[] = [
    {
        name: "id",
        label: "#",
        options: {
            align: "right"
        }
    },
    {
        name: "email",
        options: {
            editable: true
        }
    },
    {
        name: "first_name",
        options: {
            editable: true
        }
    },
    {name: "last_name" },
    {name: "postal_code" },
    {name: "city" },
    {name: "address" },
    {
        name:"birthday",
        options: {
            editable: true
        }
    },
    {
        name:"unsubscribed",
        options: {
            editable: true,
            filter: true,
            filterType: "dropdown",
            align: "right",
            customFilterListOptions: {
                render: value => <><b>{i18next.t("/fields:unsubscribed")}</b>:{i18next.t(`/common:${value}`)}</>
            },
            filterOptions: {
                renderValue: value => i18next.t(`/common:${value}`),
                fullWidth: true
            }

        }
    },
    { 
        name:"deactivated",
        options: {
            editable: true,
            filter: true,
            filterType: "dropdown",
            align: "right",
            customFilterListOptions: {
                render: value => <><b>{i18next.t("/fields:deactivated")}</b>:{i18next.t(`/common:${value}`)}</>
            },
            filterOptions: {
                renderValue: value => i18next.t(`/common:${value}`),
                fullWidth: true
            }
        }
    },
    {
        name: "created_at"
    },
    {
        "name":"doi_state",
        options: {
            editable: true,
            filter: true,
            filterType: "dropdown",
            align: "right",
            customFilterListOptions: {
                render: value => <span><b>{i18next.t(`/fields:doi_state`)}</b>: {i18next.t(`/common:${value}`)}</span>
            },
            filterOptions: {
                renderValue: value => i18next.t(`/common:${value}`),
                fullWidth: true
            },
            customBodyRender: (value) => <span style={{color: value === "CONFIRMED"? "green" : "orange"}}>{i18next.t(`/common:${value}`)}</span>
        }
    }

];

const Recipients = () => {
    const { t } = useTranslation()
    const { dialog } = useDialog()
    const history = useHistory<RecipientLocationState>()
    const location = useLocation()
    const { alert } = useAlert()

    const handleRemove = (item: IRecipient, reload: () => void) => {
        dialog.show<ConfirmRemoveProps>({
            component: ConfirmRemove,
            props: {
                items: [item],
                model: recipientApi
            }
        }).then((response: ConfirmRemoveResponse) => {
            alert.success(t("Empfänger erfolgreich gelöscht!"));
            reload()
        }).catch(() => alert.error(t("Empfänger konnte nicht gelöscht werden!")))
    }

    const handleEdit = (item: IRecipient) => {
        history.push(`${location.pathname}/${String(item.id)}`, {recipient: item, editState: EditState.IsEditing})
    }

    const handleView = (item: IRecipient) => {
        history.push(`${location.pathname}/${String(item.id)}`, {recipient: item, editState: EditState.IsViewing})
    }

    const handleDownload = ({ page, pageSize, searchText, filterObject, sort, columns:tableColumns }: CustomDownloadParams) => {
        //recipientApi.export(searchText, filterObject)
        dialog.show<ExportDialogProps>({
            component: ExportDialog,
            props: {
                model: recipientApi,
                searchText,
                filterObject,
                columns: tableColumns
            }
        })
        return false
    }

    /*const handleAddCustomField = async () => {
        dialog.show({
            component: AddCustomField,
            props: {

            }
        }).then((response: AddCustomFieldsResponse) => {
            if (response) {
                //TODO implement
                console.warn("not implemented")
            }
        }).catch(() => {

        })
    }*/

    const handleAddRecipient = async () => { // TODO reload when success
        dialog.show({
            component: AddRecipient,
            props:{

            }
        }).then(() => alert.success(t("Empfänger wurde erfolgreich angelegt!")))
          .catch(() => alert.error(t("Empfänger konnte nicht angelegt werden!")))
    }

    const options: CustomMUIDataTableOptions = {
        filterType: "multiselect" as FilterType,
        filter: true,
        customDownload: handleDownload,
        downloadOptions: {
            filename: `${moment().format("YYYY_MM_DD")}_recipients_export.csv`
        },
    };

    return (
        <PagePadding>
            <MUIDataTable<IRecipient>
                translateLabels={true}
                modelApi={recipientApi}
                title={t("Bestand")}
                columns={columns}
                options={options}

                customToolbarActions = {[
                    <IconButton key="add" onClick={handleAddRecipient}>
                        <AddIcon />
                    </IconButton>
                ]}

                customActionsRender={(item, reload) => (
                    [
                        <Button key={"show"} color="viewButton" variant="action" disableElevation size="small" tooltip={t("Anzeigen")} tooltipProps={{ placement: 'left' }} onClick={() => handleView(item)}>
                            <Fa icon={["fas", "eye"]} />
                        </Button>,
                        <Button key={"edit"} color="editButton" variant="action" disableElevation size="small" tooltip={t("Bearbeiten")} tooltipProps={{ placement: 'left' }} onClick={() => handleEdit(item)}>
                            <Fa icon={["fas", "wrench"]} />
                        </Button>,
                        <Button key={"delete"} color="deleteButton" variant="action" disableElevation size="small" tooltip={t("Löschen")} tooltipProps={{ placement: 'left' }} onClick={() => handleRemove(item, reload)}>
                            <Fa icon={["fas", "trash"]} />
                        </Button>
                    ]
                )}
            />
        </PagePadding>
    )
}

export default Recipients