import React, { useEffect, useReducer, useRef, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import axios from "axios"
import useSession, { Address, LoginState } from "hooks/useSession"
import { ChatState, convertTextfields } from "routes/app/chats/chatbuilder/chatFunctions"
import { Grid, makeStyles, Badge, withStyles, createStyles, Theme } from "@material-ui/core"
import chatSimReducer from "./chatSimReducer"
import { RenderCurrentElement, RenderCurrentDialog, DataToPost } from "./ChatSimFunctions"
import { delay } from "constants/delay"
import LoadingAnimation from 'components/LoadingAnimation'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import Pixi from 'components/Pixi'
import { FaButton, Fa } from 'styled/muiComponents'
import customFields from "models/customFields"


// TODO server redirects with encoded ids (realm, chat, webform? und irgendwie den angelegten recipient)
// TODO get request and den server der dann über die encoded ids den chat als json schickt
// TODO post request mit feldern/customfeldern, um den angelegten recpient upzudaten

type Props = {
    testChat?: ChatState,
    builderDispatch?: any
    imp?: any
}

const useStyles = makeStyles((theme) => ({
    input: {
        position: "absolute",
        height: "100%"

    },
    divider: {
        marginTop: theme.spacing(2)
    },
    chat: {
        overflowY: "scroll",
        overflowX: "hidden"
    },
    stage: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "relative",
        margin: "0",
        overflow: "hidden",
        padding: 20
    },
    snippet: {
        position: "relative",
        background: "#fff",
        borderRadius: ".25rem"
    },
    botChatBubbleLoading: {
        marginRight: "auto",
        marginBottom: 10,
        backgroundColor: "#ffffff",
        padding: "0 15px",
        boxShadow: "0 1px .5px rgba(11,20,26,.13)",
        borderRadius: "7px",
        width: "50px"
    },

    botChatBubble: {
        marginRight: "auto",
        marginBottom: 10,
        //backgroundColor: "#ffffff",
        padding: "10px 15px",
        boxShadow: "0 1px .5px rgba(11,20,26,.13)",
        borderRadius: "7px"
    },
    chatBackgroundImage: {
        height: "100%", width: "100%",
        //backgroundColor: "#CCC0B0",
        //mask: "url(/assets/images/chat-background.svg) repeat",
        //WebkitMask: "url(/assets/images/chat-background.svg) repeat",
        position: "absolute",
        top: 0,
        left: 0,
    },
    chatBackgroundColor: {
        height: "100%",
        width: "100%",
        //backgroundColor: "#efeae2",
        position: "absolute",
        top: 0,
        left: 0,
    },
    chatWrapper: {
        height: "calc(100% - 48px)",
        width: "100%",
        boxSizing: "border-box",
        flexWrap: "nowrap"
    },
    chatContentWrapper: {
        height: "calc(100% - 70px)",
        width: "100%",
        boxSizing: "border-box",
        flexWrap: "nowrap"
    },
    ChatBubbleSpacer: { minWidth: 50 },
    userInput: {
        background: "white",
        zIndex: 51,
        minHeight: "80px",
        padding: theme.spacing(2),
        borderTop: "1px solid lightgray"
    },
    userChatBubble: {
        marginLeft: "auto",
        marginRight: 0,
        marginBottom: 10,
        //backgroundColor: "#d9fdd3",
        padding: "10px 15px",
        boxShadow: "0 1px .5px rgba(11,20,26,.13)",
        borderRadius: "7px"
    },


    /* Waiting Animation: Variant Typing */
    dotTyping: {
        transform: "scale(0.75)",
        position: "relative",
        /*left: "-9999px,*/
        left: "-30px",
        width: "10px",
        height: "10px",
        borderRadius: "5px",
        backgroundColor: theme.palette.companyRed.main,
        color: theme.palette.companyRed.main,
        boxShadow: `0 0 0 0 ${theme.palette.companyRed.main}, 15px 0 0 0 ${theme.palette.companyRed.main}, 30px 0 0 0 ${theme.palette.companyRed.main}`,
        animation: "$dotTyping 2s infinite linear"
    },
    userInputPlaceholder: {
        background: "red",
        minHeight: "80px"
    },

    "@keyframes dotTyping": {
        "0%": {
            boxShadow: `25px 0 0 0 ${theme.palette.companyRed.main}, 40px 0 0 0 ${theme.palette.companyRed.main}, 55px 0 0 0 ${theme.palette.companyRed.main}`,
        },
        "10%": {
            boxShadow: `25px -10px 0 0 ${theme.palette.companyRed.main}, 40px 0 0 0 ${theme.palette.companyRed.main}, 55px 0 0 0 ${theme.palette.companyRed.main}`
        },
        "20%": {
            boxShadow: `25px 0 0 0 ${theme.palette.companyRed.main}, 40px -10px 0 0 ${theme.palette.companyRed.main}, 55px 0 0 0 ${theme.palette.companyRed.main}`
        },
        "30%": {
            boxShadow: `25px 0 0 0 ${theme.palette.companyRed.main}, 40px 0 0 0 ${theme.palette.companyRed.main}, 55px -10px 0 0 ${theme.palette.companyRed.main}`,
        },
        "40%": {
            boxShadow: `25px 0 0 0 ${theme.palette.companyRed.main}, 40px 0 0 0 ${theme.palette.companyRed.main}, 55px 0 0 0 ${theme.palette.companyRed.main}`
        },
        "100%": {
            boxShadow: `25px 0 0 0 ${theme.palette.companyRed.main}, 40px 0 0 0 ${theme.palette.companyRed.main}, 55px 0 0 0 ${theme.palette.companyRed.main}`,
        }
    },
    /* Waiting Animation: Variant Flashing */
    dotFlashing: {
        transform: "scale(0.75)",
        position: "relative",
        width: "10px",
        height: "10px",
        borderRadius: "5px",
        backgroundColor: theme.palette.companyRed.main,
        color: theme.palette.companyRed.main,
        animation: "$dotFlashing 0.6s infinite linear alternate",
        animationDelay: ".3s",
        "&:before": {
            content: "' '",
            display: "inlineBlock",
            position: "absolute",
            top: 0,
            left: "-15px",
            width: "10px",
            height: "10px",
            borderRadius: "5px",
            backgroundColor: theme.palette.companyRed.main,
            color: theme.palette.companyRed.main,
            animation: "$dotFlashing 0.6s infinite alternate",
            animationDelay: "0s"
        },
        "&:after": {
            content: "' '",
            display: "inlineBlock",
            position: "absolute",
            top: 0,
            left: "15px",
            width: "10px",
            height: "10px",
            borderRadius: "5px",
            backgroundColor: theme.palette.companyRed.main,
            color: theme.palette.companyRed.main,
            animation: "$dotFlashing 0.6s infinite alternate",
            animationDelay: ".6s",
        },
    },
    "@keyframes dotFlashing": {
        "0%": {
            opacity: 1
        },
        "50%": {},
        "100%": {
            opacity: ".4"
        }
    },
    /* Waiting Animation: Variant Pulse */
    dotPulse: {
        position: "relative",
        left: "-9999px",
        width: "10px",
        height: "10px",
        borderRadius: "5px",
        backgroundColor: theme.palette.companyRed.main,
        color: theme.palette.companyRed.main,
        boxShadow: `9999px 0 0 -5px ${theme.palette.companyRed.main}`,
        animation: "$dotPulse 1.5s infinite linear",
        animationDelay: ".25s",
        "&:before": {
            content: "' '",
            display: "inline-block",
            position: "absolute",
            top: 0,
            width: "10px",
            height: "10px",
            borderRadius: "5px",
            backgroundColor: theme.palette.companyRed.main,
            color: theme.palette.companyRed.main,
            boxShadow: "9984px 0 0 -5px #9880ff",
            animation: "$dotPulseBefore 1.5s infinite linear",
            animationDelay: "0s"
        },
        "&:after": {
            content: "' '",
            display: "inline-block",
            position: "absolute",
            top: 0,
            width: "10px",
            height: "10px",
            borderRadius: "5px",
            backgroundColor: theme.palette.companyRed.main,
            color: theme.palette.companyRed.main,
            boxShadow: "10014px 0 0 -5px #9880ff",
            animation: "$dotPulseAfter 1.5s infinite linear",
            animationDelay: ".5s"
        }
    },
    "@keyframes dotPulseBefore": {
        "0%": {
            boxShadow: `9984px 0 0 -3px ${theme.palette.companyRed.main}`
        },
        "30%": {
            boxShadow: `9984px 0 0 0px ${theme.palette.companyRed.main}`
        },
        " 60%": {},
        "100%": {
            boxShadow: `9984px 0 0 -3px ${theme.palette.companyRed.main}`
        }
    },

    "@keyframes dotPulse": {
        "0%": {
            boxShadow: `9999px 0 0 -3px ${theme.palette.companyRed.main}`
        },
        "30%": {
            boxShadow: `9999px 0 0 0px ${theme.palette.companyRed.main}`
        },
        "60%": {},
        "100%": {
            boxShadow: `9999px 0 0 -3px ${theme.palette.companyRed.main}`
        }
    },

    "@keyframes dotPulseAfter": {
        "0%": {
            boxShadow: `10014px 0 0 -3px ${theme.palette.companyRed.main}`
        },
        "30%": {
            boxShadow: `10014px 0 0 0px ${theme.palette.companyRed.main}`
        },
        "60%": {},
        "100%": {
            boxShadow: `10014px 0 0 -3px ${theme.palette.companyRed.main}`
        }
    },
    chatContent: {
        width: "100%",
        overflow: "hidden"
    },
    chatHeader: {
        height: "70px",
        backgroundColor: "#ffffff",
        zIndex: 100,
        flexWrap: "nowrap",
        borderBottom: "1px solid lightgray"
    },
    chatHeaderHeadline: {
        lineHeight: "1em",
        fontWeight: "bold"
    },
    chatCompanyLogo: {
        width: "80px",
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    }

}))


export const TypingAnimation = ({settings}:any) => {
    const classes = useStyles()
    switch(settings.typing){
        case "Typing":{
            return <div className={classes.dotTyping}></div>
        }

        case "Pulse":{
            return <div className={classes.dotPulse}></div>
        }

        case "Flashing":{
            return <div className={classes.dotFlashing}></div>
        }

        default:{
            return <div className={classes.dotPulse}></div>
        }
    }
}

const ChatSim = ({ testChat, builderDispatch, imp= null }: Props) => {

    const initialState = {
        chat: null,
        currentNode: null,
        previousDialog: ([]),
        stackPosition: null,
        currentDialog: ([]),
        isWaiting: false,
        isTesting: true,
        userData: {},
        clickedPlatform: false,
        dataCollection_set:[]
    }
    const [state, dispatch] = useReducer(chatSimReducer, initialState)
    const [imprint, setImprint] = useState<Address | null>(imp)
    const scrollDivRef = useRef(null)

    const location = useLocation()
    const hid = location.pathname.split("/")[2]
    const { loginState } = useSession()
    const classes = useStyles()


    useEffect(() => {
        if (!builderDispatch) {
            return
        }
        builderDispatch({ type: "CURRENT_CHATSIM_NODE", payload: state.currentNode })
    }, [state.currentNode])

    useEffect(() => {
        if(!testChat){
            return
        }
        dispatch({type:"CHAT_SETTINGS", payload:{values: testChat?.chatSettings}})
    },[testChat?.chatSettings])

    useEffect(() => {
        let chat: any
        let isTesting: any
        let fields: any
        const getChat = async () => {
            if (loginState === LoginState.LoggedOut) {
                // TODO response für confirm speichern und rabatt code(?) nicht nochmal rausgeben, falls 400
                axios.post(`https://chat.cornermail.de/webform/${hid}/chat_confirm`)
                const response = await axios.get(`https://chat.cornermail.de/webform/${hid}/chat`)
                // const response = await axios.get(`http://test1.cornerstone.de/webform/${hid}/chat`)
                chat = response.data[1].chat
                setImprint(response.data[0])
                isTesting = false
                fields = response.data[1].chat.fields
                return
            } else {
                if (!testChat) {
                    console.error("something went wrong")
                    return
                } else {
                    isTesting = true
                    chat = Object.create(testChat)
                    chat.elements = convertTextfields(chat.elements, "toRaw")
                    chat.settings = testChat.chatSettings
                    const response = await customFields.getList()
                    fields = response.data
                    return
                }
            }
        }
        getChat();
        // ! hack for now. This useEffects needs to be refactored when its actual chat and not only testing || need to have non-customfields and their types somewhere
        delay(2000).then(() => dispatch({ type: "INITIALIZE_CHAT", payload: { chat, isTesting, fields } }))
    }, [])

    useEffect(() => {
        delay(state.currentDialog.delay * 1000).then(() => dispatch({ type: "WAITING_OVER" }))
    }, [state.currentDialog])

    useEffect(() => {
        if(!state.isTesting && state.currentNode.type === "end" ){
            const data = DataToPost(state.userData)
            axios.post(`https://chat.cornermail.de/webform/${hid}/chat_update`, data);
        }
    },[state.currentNode])

    useEffect(() => {
        if (!scrollDivRef.current) {
            return
        }
        //@ts-ignore
        scrollDivRef.current._container.scrollTop = scrollDivRef.current._container.scrollHeight
    }, [state.isWaiting])

    if (!state.chat || !state.currentNode || !imprint) {
        return <LoadingAnimation />
    }

    const StyledBadge = withStyles((theme: Theme) =>
        createStyles({
            badge: {
                left: -4,
                top: 1,
                backgroundColor: '#44b700',
                color: '#44b700',
                boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                '&::after': {
                    position: 'absolute',
                    top: "-2px",
                    left: "-2px",
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    animation: '$ripple 2.5s infinite ease-in-out',
                    border: '2px solid currentColor',
                    content: '""',
                },
            },
            '@keyframes ripple': {
                '0%': {
                    transform: 'scale(.4)',
                    opacity: 1,
                },
                '100%': {
                    transform: 'scale(1.6)',
                    opacity: 0,
                },
            },
        }),
    )(Badge);


    return (

        <Grid container direction="column" justify="flex-start" className={classes.chatWrapper}>
            <div className={classes.chatBackgroundColor} style={{backgroundColor: state.chatSettings.chatColor}}></div>
            <div className={classes.chatBackgroundImage} style={{backgroundColor: state.chatSettings.imageColor, mask: `url(/assets/images/chat/${state.chatSettings.chatBackgroundImage}.svg) repeat`, WebkitMask: `url(/assets/images/chat/${state.chatSettings.chatBackgroundImage}.svg) repeat`}}></div>
            <Grid item container alignContent="center" className={classes.chatHeader}>
                <Grid item container justify="center" alignContent="center" className={classes.chatCompanyLogo}>
                     <img src="/assets/images/Campaign-Plus-Signet.svg" alt="logo" style={{ display: "block" }} />
                </Grid>
                <Grid item container direction="column" alignContent="flex-start" justify="center" style={{ flexGrow: 1 }}>
                    <h3 className={classes.chatHeaderHeadline}>{imprint.company_name}</h3>
                    <Grid item container direction="row" alignContent="center" justify="flex-start">
                        <Grid item container direction="row" alignContent="center" justify="flex-start" style={{ width: "15px" }}>
                            <StyledBadge color="secondary" variant="dot"></StyledBadge>
                        </Grid>
                        <Grid item>
                            <span>Online</span>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container justify="center" alignContent="center" style={{ width: "48px" }}>
                    <FaButton style={{ width: "48px" }}>
                        <Fa icon={["far", "ellipsis-vertical"]} />
                    </FaButton>
                </Grid>
            </Grid>
            <Grid item container direction="column" justify="space-between" className={classes.chatContentWrapper}>
                <Grid item container className={classes.chatContent}>
                    <PerfectScrollbar ref={scrollDivRef} style={{ width: "100%" }}>
                        <Grid item container style={{ padding: "16px" }}>
                            {state.chatSettings.showAvatar && <Pixi size="large" pose="waiting" />}
                            {state.previousDialog}
                            {state.isWaiting && <div className={classes.botChatBubbleLoading}>
                                <div className={classes.snippet}>
                                    <div className={classes.stage}>
                                        {/* <div className={classes.dotPulse}></div> */}
                                        <TypingAnimation settings={state.chatSettings}/>
                                    </div>
                                </div>
                            </div>}
                            {!state.isWaiting && <RenderCurrentDialog state={state} dispatch={dispatch}/>}
                        </Grid>
                    </PerfectScrollbar>
                </Grid>
                <Grid item container direction="column" justify="center" alignItems="center" className={classes.userInput}>
                    {!state.isWaiting && <RenderCurrentElement state={state} dispatch={dispatch} />}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ChatSim
