import React, { useState} from 'react'

export type SidebarProps = {
    isFixedOpen: boolean,
    fixedClose: () => void,
    fixedOpen: () => void,

}

const SidebarContext = React.createContext<SidebarProps>({
    isFixedOpen: true,
    fixedOpen: () => {},
    fixedClose: () => {},
})


export const SidebarProvider: React.FC<{}> = ({children}) => {
    const [fixedOpen, setFixedOpen ] = useState(true)

    const handleFixedOpen = () => {
        setFixedOpen(true)
    }

    const handleFixedClose= () => {
        setFixedOpen(false)
    }

    return (
        <SidebarContext.Provider value={{isFixedOpen: fixedOpen, fixedClose: handleFixedClose, fixedOpen: handleFixedOpen}}>
            {children}
        </SidebarContext.Provider>
    )
}

export const useSidebar = () => {
    return React.useContext(SidebarContext)
}