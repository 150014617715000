import { Grid, MenuItem, Select, Paper } from "@material-ui/core"
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from "components/Button/Button"
import LoadingAnimation from "components/LoadingAnimation"
import React, { useState, useEffect } from "react"
import { isNode, Node, Edge } from "react-flow-renderer"
import { useTranslation } from "react-i18next"
import { Fa } from "styled/muiComponents"
import { Alert, AlertTitle } from '@material-ui/lab';

const NodeMenu = ({ state, dispatch, classes }: any) => {
    const { t } = useTranslation()
    const [currentNode, setCurrentNode] = useState<Node | null>(null)
    const [nodes, setNodes] = useState<Node[] | null>(null)

    useEffect(() => {
        setNodes(state.elements.filter((ele: Node | Edge) => {
            return isNode(ele)
        }))
    },[])

    if (!nodes) {
        return <LoadingAnimation />
    }

    const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const node = nodes.filter((node: Node) => node.id === e.target.value)[0]
        setCurrentNode(node)
    }

    const handleClick = () => {
        dispatch({ type: "SELECT", payload: currentNode })
    }
    const menuOptions = () => {

        const options = nodes.map((node: Node, index: number) => {
            return <MenuItem key={index} value={node.id}>{node.data.name}</MenuItem>
        })

        return options
    }
    return (
        <Grid container className={classes.settingsContainer} style={{borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px"}}>
            <Alert severity="info" variant="outlined" style={{marginBottom: "16px", backgroundColor: "#ffffff"}}><AlertTitle>{t("Wähle einen Block")}</AlertTitle>{t("Du hast noch keinen Block ausgewählt, den du bearbeiten kannst. Wähle hier einen Block, den du bearbeiten möchtest.")}</Alert>
            <Paper elevation={2} className={classes.settingsGroupPaper}>
                <Grid container item direction="column" justify="center" className={classes.settingsGroup}>
                    <Grid item>                    
                        <FormControl variant="outlined" style={{width: "100%"}}>
                            <InputLabel id="selectNode">{t("Wähle einen Block")}</InputLabel>
                            <Select onChange={handleChange} defaultValue="" variant="outlined" labelId="selectNode" label={t("Wähle einen Block")} style={{width: "100%"}}>
                                {menuOptions()}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button disableElevation color="success" variant="contained" size="large" onClick={handleClick} style={{marginTop: "16px"}} disabled={!currentNode ? true : false}>
                            <Fa icon={["fas", "check"]} mr={1.5} />
                            {t("Block auswählen")}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default NodeMenu