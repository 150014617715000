import { Typography } from "@material-ui/core"
import Block, { BlockContent, BlockTitle } from "components/Block"
import Button from "components/Button/Button"
import LoadingAnimation from "components/LoadingAnimation"
import bundles from "models/bundles"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Fa } from "styled/muiComponents"
import ConfirmPurchaseDialog from "./ConfirmPurchaseDialog"
import { useDialog } from "hooks/useDialog"
import { useTariff } from "hooks/useTariff"


export const Subscriptions = () => {
    const {t} = useTranslation()
    const { dialog } = useDialog()
    const {current, candidate} = useTariff()
    
    const [prices, setPrices] = useState<any>(null)
    const [scanLimit, setScanLimit] = useState<number | null>(null)


    useEffect(() => {
        const fetchPrices = async () => {
            await bundles.pricelist()
            .then((response) => setPrices(response))
        }

        const fetchScanLimit = async () => {
            await bundles.scanLimit()
            .then((response) => setScanLimit(response.limit))
        }

        fetchPrices(); fetchScanLimit()
    },[])

    console.log(prices)
    console.log(scanLimit)
    console.log(current,candidate)

    const handlePurchase = async (type:string) => {

        if(!current){ // currently no subscription
            await bundles.purchaseSubscription(type)
            .then((response) => {
                if(response?.customer_portal_url){
                    console.log('switch tab')
                    window.location = response.customer_portal_url
                }})
        } else {
            dialog.show({
                component: ConfirmPurchaseDialog,
                props: {
                    subscription: {...prices[type], name: type}
                }
            })
            .then( async (response)=> {
                if(response.action === 'purchase'){
                    await bundles.purchaseSubscription(type)
                    .then((response) => {console.log('works')}) // TODO toast
                    .catch((error) => console.log(error))
                }
            })
            console.log('dialog with confirmation')
        }
    }

    const handleCancel = async () => {
        await bundles.cancelSubscription()
        .then((response) => console.log('success message'))
        .catch((error) => console.log('unsucessful message'))
    }

    const handleChangeLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScanLimit(parseInt(event.target.value))
        console.log(event.target.value)
    }

    const handleSetLimit = async () => {
        if (!scanLimit){
            return
        }
        await bundles.setScanLimit(scanLimit)
        .then((response) => console.log('positiv toast'))
        .catch((error) => console.log('negative taost'))
    }

    const customerPortal = async () => {
        await bundles.customerPortal()
        .then((response) => {
            if(response?.customer_portal_url){
                console.log('switch tab')
                // @ts-ignore
                window.location = response.customer_portal_url
            }})
    }

    if (!prices || !scanLimit){
        return <LoadingAnimation/>
    }

    return(
            <Block>
                <BlockTitle>
                    <Typography variant="h2">{t("Subscriptions")}</Typography>
                </BlockTitle>
                <BlockContent>
                   
                    <Button color="success" variant="contained" size="small" >
                        <Fa icon={["far", "pen-to-square"]} mr={1}/>{t("Bearbeiten")}
                    </Button>

                    {JSON.stringify(prices)}
                    <button onClick={customerPortal}>customer portal</button>
                    <button onClick={() => handlePurchase('sprout')}>purchase sprout</button>
                    <button onClick={() => handlePurchase('grow')}>purchase grow</button>
                    <button onClick={() => handlePurchase('roots')}>purchase roots</button>
                    <button onClick={handleCancel}>cancel</button>
                    <input type="number" value={scanLimit} onChange={handleChangeLimit}></input><button onClick={handleSetLimit}>set limit</button>


                </BlockContent>
            </Block>
    )
}


