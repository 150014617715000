import { createMuiTheme } from '@material-ui/core/styles'
import { deDE } from '@material-ui/core/locale'
//TODO dynamic locale way later

const { augmentColor } = createMuiTheme().palette

const themeDef = {
  palette: {
    primary: {
      //main: "#373737"
      main: "#070c1d",
      light: "#1a1e2d"
    },
    secondary: {
      //main: '#d53237'
      main: '#E83338',
    },
    success: {
      main: '#83ac1b',
      contrastText: "white"
    },
    sfxblue: {
      main: "#006980",
      contrastText: "white"
    },
    background: {
      mainContent: "#eeeeee",
      contentHeader: "#f3f3f3"
    },
    textColor: {main: "#212529"},
    grey: {main: "#f9f9f9"},
    lightGrey: augmentColor({main: "#f9f9f9"}),
    companyRed: augmentColor({main: "#E83338"}),
    companyDark: augmentColor({main: "#070c1d", light: "#1a1e2d"}),
    tooltip: augmentColor({main: "#070c1d"}),

    // Action Buttons
    viewButton: {
      main: "#16a085",
      contrastText: "#ffffff",
      dark: "#ffffff"
    },
    secondaryButton: augmentColor({main: "#8a8989"}),
    editButton: augmentColor({main: "#2c3e50"}),
    deleteButton: augmentColor({main: "#E83338"}),
  },
  
  typography: {
    h1: {
      fontSize: "1.65rem",
      fontFamily: "'Roboto', sans-serif"
    },
    h2: {
      fontSize: "1.4rem",
      fontWeight: 300,
      fontFamily: "'Roboto', sans-serif"
    },
    h3: {
      fontSize: "1.3rem",
      fontWeight: 300,
      fontFamily: "'Roboto', sans-serif"
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: 300,
      fontFamily: "'Roboto', sans-serif"
    },
    h5: {
      fontSize: "1.2rem",
      fontFamily: "'Roboto', sans-serif"
    },
    h6: {
      fontSize: "1.1rem",
      fontFamily: "'Roboto', sans-serif"
    },
    body1: {
      fontWeight: "300",
      fontFamily: "'Roboto', sans-serif"
    },
    p: {
      fontFamily: "'Roboto', sans-serif"
    },
    button: {
      fontSize: "1rem",
      fontWeight: 400,
      fontFamily: "'Roboto', sans-serif"
    }
  }
}

const theme = createMuiTheme(themeDef, deDE);

const darkTheme = createMuiTheme({
  ...themeDef,
  palette: {
    ...themeDef.palette,
    type: "dark"
  }
}, deDE)

export { theme, darkTheme }

export const secondaryHue = {
  '50' : "#ffebef",
  '100' : "#ffcdd3",
  '200' : "#f0999c",
  '300' : "#e67176",
  '400' : "#f14f54",
  '500' : "#f63d3a",
  '600' : "#e73339", //Original
  '700' : "#d52933",
  '800' : "#c8212c",
  '900' : "#b91220",
  //Complementary
  'c50' : "#d9f9f9",
  'c100' : "#9cf0ed",
  'c200' : "#33e7e1", //original
  'c300' : "#00dad1",
  'c400' : "#00cec2",
  'c500' : "#00c1b2",
  'c600' : "#00b2a2",  
  'c700' : "#00a18f",
  'c800' : "#00907e",
  'c900' : "#00725d",
}

export const primaryHue = {
  '50': '#f1f6ff',
  '100': '#e7ebff',
  '200': '#dadef4',
  '300': '#c6cbe0',
  '400': '#a1a6ba',
  '500': '#808498',
  '600': '#595d70',
  '700': '#464b5c',
  '800': '#292d3d',
  '900': '#070c1d', //Original
};