import { MUIDataTableTextLabels } from 'mui-datatables'

const textLabels: Record<string, Partial<MUIDataTableTextLabels>> = {
  "de": {
    body: {
      noMatch: "Es konnten keine passenden Einträge gefunden werden.",
      toolTip: "Sortieren"
    },
    pagination: {
      next: "Nächste Seite",
      previous: "Vorherige Seite",
      rowsPerPage: "Zeilen pro Seite",
      displayRows: "von",
    },
    toolbar: {
      search: "Suchen",
      downloadCsv: "Als CSV herunterladen",
      print: "Drucken",
      viewColumns: "Spalten",
      filterTable: "Filtern",
    },
    filter: {
      all: "Alle",
      title: "Filter",
      reset: "Reset",
    },
    viewColumns: {
      title: "Spalten anzeigen",
    },
    selectedRows: {
      text: "Einträge ausgewählt",
      delete: "Einträge Löschen",
      deleteAria: "Einträge löschen",
    },
  }
}

export default textLabels