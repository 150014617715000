import { get, put } from "api/crud";
import { withCrud, CrudModel, BaseModel } from "./builder";

export type ImageLibraryApi = CrudModel<any> & BaseModel // TODO typing

export default {
        name: "images",
    ...withCrud<any>("images"),

    allImages: () => get("images/allImages"),

    one: (id:number) => ({
        getImage: () => {
            return get(`images/${id}/getImage`)
        },
    }),

    getLogo: () => get<string>("images/get_logo"),

    selectNewLogo: (id:number) => put("images/select_new_logo", {id})
} 