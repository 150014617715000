import React, { useState, useEffect } from 'react'
import Editor, { IInputGroup, ICustomHeader } from '../../../../../../components/formEditor/Editor'
import { Navigation, NavigationProps, ITemplateMailSettings, IChats } from '../WebformWizard'
import { IPlaceholder, TemplateKey } from 'models/webform'
import { FormValues } from './FirstStep'
import { Device } from 'types/common'
import { Address } from 'hooks/useSession'

type Props = {
    onSubmit: (placeholders: Record<string, IPlaceholder>, template: string) => void,
    template: string,
    placeholders: Record<string, IPlaceholder>,
    inputGroups: IInputGroup[]
    navigationProps: Omit<NavigationProps, "onNext"> & {onNext?:() => void}
    customConfigHeader: ICustomHeader
    previewIdentifier: TemplateKey
    basicInfo: FormValues | null
    device: Device
    onDeviceChange: (device: Device) => void
    templateMailSettings: ITemplateMailSettings
    handleChangeMailSettings: (event: {target: {name: keyof IPlaceholder, value: any}}) => void
    chats: IChats | null
    handleLoadMoreChats: () => void | null
    handleSetChat: (id: number | null) => void
    handleOpenChat: () => void
    wizardState: any
    imprint: Address
}

const FormBuilder = ({ handleChangeMailSettings, basicInfo, device, onDeviceChange, previewIdentifier, onSubmit, template, placeholders:initialPlaceholders, inputGroups, navigationProps, customConfigHeader, templateMailSettings, chats, handleLoadMoreChats, handleSetChat, handleOpenChat, wizardState, imprint}: Props) => {
    const [ state, setState ] = useState<{placeholders: Record<string, IPlaceholder>, template: string}>({placeholders:initialPlaceholders, template:template})
    // const state = useRef<{placeholders: Record<string, IPlaceholder>, template: string}>({placeholders:initialPlaceholders, template:template})

    // useEffect(() => {
    //     setState({
    //         template,
    //         placeholders: initialPlaceholders
    //     })
    //     // state.current.placeholders = initialPlaceholders
    //     // state.current.template = template
    // }, [template, initialPlaceholders])

    const handleChange = (placeholders: Record<string, IPlaceholder>, replacedTemplate: string) => {
        setState({
            placeholders,
            template: replacedTemplate
        })
        // state.current.placeholders = placeholders
        // state.current.template = replacedTemplate
    }

    const handleSubmit = () => {
         onSubmit(state.placeholders, state.template)
        //  onSubmit(state.current.placeholders, state.current.template)
    }

    return (
    <Editor handleChangeMailSettings={handleChangeMailSettings} device={device} onDeviceChange={onDeviceChange} basicInfo={basicInfo} previewIdentifier={previewIdentifier} customConfigHeader={customConfigHeader} template={template} placeholders={state.placeholders} onChange={handleChange} inputGroups={inputGroups} templateMailSettings={templateMailSettings} chats={chats} handleLoadMoreChats={handleLoadMoreChats} handleSetChat={handleSetChat} handleOpenChat={handleOpenChat} wizardState={wizardState} imprint={imprint} preConfigComponent={<Navigation {...navigationProps} onNext={handleSubmit}/>} />
    )
}
export default FormBuilder