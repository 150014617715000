import { MUIDataTableColumn } from './MUIDataTable'
import { FilterObject, VerboseFilterObject, Filter } from '../../types/filter.object'

export const getPageValue = (count:number, rowsPerPage:number, page:number) => {
  const totalPages = count <= rowsPerPage ? 1 : Math.ceil(count / rowsPerPage);

  // `page` is 0-indexed
  return page >= totalPages ? totalPages - 1 : page;
}

export const tableFilterToFilterObject = (filterList: any[], columns: MUIDataTableColumn[]): VerboseFilterObject | null => {
    if(filterList.every(l => l.length === 0)) return null

    const filterObject: FilterObject = {
        op: "AND",
        exps: filterList.map((f, idx) =>(
            f.length > 0 ? {
                name: "field_eq",
                params: [columns[idx].name, ...f]
            } : null
        )).filter((x): x is Filter => x !== null) 
    }

    return filterObjectToVerboseFilterObject(filterObject)
}

const filterObjectToVerboseFilterObject = (filterObject: FilterObject): VerboseFilterObject => {
    return {
        ...filterObject,
        exps: filterObject.exps.map(exp => ({filter: {selectedFilterFunction: exp}}))
    }
}