import React from "react"
import { IState, steps } from "../HtmlWizard"
import { makeStyles, Theme } from '@material-ui/core'
import { Device } from "types/common"
import SwipeableViews from "react-swipeable-views"
import PreviewFrame from "components/PreviewFrame"
import { IPlaceholder, TemplateKey } from "models/ratings"
import { insertDynamicImprint, insertImprint, insertPlaceholders, insertPrivacy, insertQR } from "util/placeholders"

const useStyles = makeStyles<Theme, { device: Device }>(theme => ({
    root: {
        width: "100%",
        height: "100%",
        overflowY: "visible",
    },
    previewWrapper: {
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.primary.light,

    },
    previewDesktop: {
        width: "100%",
        minWidth: "1048px",
        overflow: "hidden",
        height: "100%",
        maxHeight: "100vh",
        border: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    previewMobile: {
        width: "100%",
        height: "100%",
        maxHeight: "100vh",
        overflow: "auto",
        border: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    previewTablet: {
        width: "100%",
        height: "100%",
        maxHeight: "100vh",
        overflow: "auto",
        border: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    previewFullscreen: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        border: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    style: {
        color: "#fff"
    },
    slide1: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        border: "0",
        justifyContent: "center",
        alignItems: "center"
    },
    slide2: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        border: "0",
        justifyContent: "center",
        alignItems: "center"
    },
    slide3: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        border: "0",
        justifyContent: "center",
        alignItems: "center"
    },
    slide4: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        border: "0",
        justifyContent: "center",
        alignItems: "center"
    }
}))

type Props = {
    state: IState
    dispatch: any,
    values: any,
}

const DEVICE_ORDINAL: Record<Device, number> = {
    [Device.Smartphone]: 0,
    [Device.Tablet]: 1,
    [Device.Desktop]: 2,
    [Device.Fullscreen]: 3
}


type keys = { qr: { template: string, placeholders: Record<string, IPlaceholder> | null }; query: string; portals: string; feedback: string; feedbackLP: string }

const View = ({ state, dispatch, values }: Props) => {
    const classes = useStyles({ device: state.device })

    const customReplace = (template: string, placeholders: Record<string, Pick<IPlaceholder, "key" | "value" | "default"> | IPlaceholder>, imprint: any | null): string => {
        // return insertImprint(insertDynamicImprint(insertPlaceholders(insertLogo(template, logoURL), placeholders), imprint), imprint) //! logo replace 
        return insertPrivacy(insertQR(insertImprint(insertDynamicImprint(insertPlaceholders(template, placeholders), imprint), imprint)))
    }

    // makes this while thing render twice, why?
    // useEffect(() => {
    //     console.log(values)
    //     dispatch({type: "UPDATE_PLACEHOLDER_VALUE", payload: values})
    // }, [values])

    return (
        <div className={classes.root}>
            <div className={classes.previewWrapper}>
                <SwipeableViews index={DEVICE_ORDINAL[state.device]} style={{ height: "100%" }}>
                    <div className={classes.previewMobile}>
                        {DEVICE_ORDINAL[state.device] === 0 && state.prebuild.templates[steps[state.step].key as keyof keys] && <PreviewFrame
                            variant="smartphone"
                            html={
                                customReplace(
                                    state.prebuild.templates[steps[state.step].key as keyof keys].template,
                                    { ...state.prebuild.config.placeholders, ...state.prebuild.templates[steps[state.step].key as keyof keys].placeholders },
                                    state.imprint)}
                            previewIdentifier={steps[state.step].key as TemplateKey}
                            basicInfo={null}
                        />}
                    </div>
                    <div className={classes.previewTablet}>
                        {DEVICE_ORDINAL[state.device] === 1 && state.prebuild.templates[steps[state.step].key as keyof keys] && <PreviewFrame
                            variant="tablet"
                            html={
                                customReplace(
                                    state.prebuild.templates[steps[state.step].key as keyof keys].template,
                                    { ...state.prebuild.config.placeholders, ...state.prebuild.templates[steps[state.step].key as keyof keys].placeholders },
                                    state.imprint)
                            }
                            previewIdentifier={steps[state.step].key as TemplateKey}
                            basicInfo={null}
                        />}
                    </div>
                    <div className={classes.previewDesktop}>
                        {DEVICE_ORDINAL[state.device] === 2 && state.prebuild.templates[steps[state.step].key as keyof keys] && <PreviewFrame
                            variant="desktop"
                            html={
                                customReplace(
                                    state.prebuild.templates[steps[state.step].key as keyof keys].template,
                                    { ...state.prebuild.config.placeholders, ...state.prebuild.templates[steps[state.step].key as keyof keys].placeholders },
                                    state.imprint)
                            }
                            previewIdentifier={steps[state.step].key as TemplateKey}
                            basicInfo={null}
                        />}
                    </div>
                    <div className={classes.previewFullscreen}>
                        {DEVICE_ORDINAL[state.device] === 3 && state.prebuild.templates[steps[state.step].key as keyof keys] && <PreviewFrame
                            variant="fullscreen"
                            html={
                                customReplace(
                                    state.prebuild.templates[steps[state.step].key as keyof keys].template,
                                    { ...state.prebuild.config.placeholders, ...state.prebuild.templates[steps[state.step].key as keyof keys].placeholders },
                                    state.imprint)
                            }
                            previewIdentifier={steps[state.step].key as TemplateKey}
                            basicInfo={null}
                        />}
                    </div>
                </SwipeableViews>
            </div>
        </div >
    )
}

export default View