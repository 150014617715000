import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../../../components/Button/Button';
import { Box, Fa, Grid } from 'styled/muiComponents'
import { MobileStepper } from '@material-ui/core'
import WebformPrebuilds from './steps/WebformPrebuilds';
import FormBuilder from './steps/FormBuilder'
import { downloadPrebuildConfig, downloadPrebuildHtml } from '../../../../../util/download';
import FirstStep, { FormValues } from './steps/FirstStep'
import FinalStep from './steps/FinalStep'
import { IConfig, IPlaceholder, IPrebuild } from 'models/webform'
import { makeStyles } from '@material-ui/core'
import { TemplateKey } from 'models/webform'
import { Device } from "types/common"
import { useQueryParamsState } from 'hooks/useQueryParams'
import {useHistory} from "react-router-dom"
import{ useDialog} from "hooks/useDialog"
import CancelWizardDialog from 'dialogs/CancelWizardDialog';
import imprints from "models/imprint"
import LoadingAnimation from 'components/LoadingAnimation';
import { ChatState } from 'routes/app/chats/chatbuilder/chatFunctions';
import chats from "models/chats"
import { Address } from 'hooks/useSession';

const useStyles = makeStyles(theme => ({
    wizardwrapper: {
        height: "100%",
        margin: "0px",
        width: "100%",
        overflow: "hidden",
    },
    WizardStepper: {
        color: "white",
        backgroundColor: "transparent",
        padding: theme.spacing(0),
        flexGrow: 1
    },
    Wizardnav:{
        padding: theme.spacing(2),
    }
}))

const deepCopy = (obj: object): object => {
    return (Object.keys(obj) as Array<keyof typeof obj>).reduce((res, key) => ({ ...res, [key]: obj[key] }), {})
}

export type IChats ={
    chats: ChatState[],
    total: number
}

export type TemplateConfig = {
    template?: string,
    placeholders: Record<string, IPlaceholder>
}

export type IState = {
    prebuild: IPrebuild | null
    activeStep: number
    chat: number | null
    formLP?: TemplateConfig
    soiLP?: TemplateConfig
    doiMail?: TemplateConfig
    doiLP?: TemplateConfig
    doneMail?: TemplateConfig
}

const initialState: IState = {
    prebuild: null,
    activeStep: 0,
    chat: null
}

export type IStep = {
    label: string
    description?: string
    key?: keyof Omit<IState, "prebuild">
}

type IQueryParams = {
    device: Device
}

export type ITemplateMailSettings = {
    subject: IPlaceholder
}

export type IEmailSettings = {
    formLP: ITemplateMailSettings
    soiLP: ITemplateMailSettings
    doiMail: ITemplateMailSettings
    doiLP: ITemplateMailSettings
    doneMail: ITemplateMailSettings
}


const steps: IStep[] = [
    { label: "Design Auswahl", description: 'Wähle die Vorlage, die dir am meisten zusagt und klicke auf "weiter", um diese zu bearbeiten.' },
    { key: "formLP", label: "Anmeldeseite", description: "Das Anmeldeformular ist die erste Seite, die deine Kunden sehen. Hier können Kunden ihre Daten wie zum Beispiel die E-Mail Adresse eingeben." },
    { key: "soiLP", label: "Formular Landingpage", description: "Auf dieser Seite landen deine Kunden nachdem sie das ausgefüllte Anmeldeformular abgeschickt haben." },
    { key: "doiMail", label: "Dopt-In Mail", description: "Dies ist die Bestätigungs-E-Mail, die deine Kunden erhalten. Mit dieser E-Mail bestätigt der Kontakt zum einen seine E-Mail-Adresse und zum Anderen, dass er oder sie Werbung von dir erhalten möchte." },
    { key: "doiLP", label: "Dopt-In Landingpage", description: "Auf dieser Seite landen deine Kunden nachdem sie auf den Bestätigungslink in der Bestätigungs-E-Mail geklickt haben." },
    { key: "doneMail", label: "Willkommens-Mail", description: "Diese E-Mail bekommen deine Kontakte nach einer erfolgreichen Anmeldung." },
    { label: "Abschließen" }
]

const WebformWizard = () => {
    const classes = useStyles()
    const [state, setState] = useState<IState>(initialState)
    const [basicInfo, setBasicInfo] = useState<FormValues | null>(null)
    const [chatList, setChatList] = useState<IChats | null>(null)
    const [previewIndex, setPreviewIndex] = useState<number>(0)
    const [imprint, setImprint] = useState<Address | null>(null)
    const [queryParams, setQueryParams] = useQueryParamsState<IQueryParams, IQueryParams>((a: IQueryParams) => a, (a: IQueryParams) => a, { device: Device.Smartphone })
    const [emailSettings, setEmailSettings] = useState<IEmailSettings>({
        formLP: {
            subject: {
                default: "",
                key: "subject",
                label: "Betreff",
                type: "text",
                value: ""

            }
        },
        soiLP: {
            subject: {
                default: "",
                key: "subject",
                label: "Betreff",
                type: "text",
                value:""
            }
        },
        doiMail: {
            subject: {
                default: "Bitte bestätigen Sie Ihre Anmeldung",
                key: "subject",
                label: "Betreff",
                type: "text",
                value: "Bitte bestätigen Sie Ihre Anmeldung"
            }
        },
        doiLP: {
            subject: {
                default: "",
                key: "subject",
                label: "Betreff",
                type: "text",
                value:""
            }
        },
        doneMail: {
            subject: {
                default: "Vielen Dank für ihre Anmeldung",
                key: "subject",
                label: "Betreff",
                type: "text",
                value: "Vielen Dank für ihre Anmeldung"
            }
        }
    })

    useEffect(() => {
        const filterObject = {"op": "AND", "exps":[{"filter":{"selectedFilterFunction":{"name":"field_eq", "params":["valid", true]}}}]}

        const fetchChats = async () => {
            //@ts-ignore why filterobject error when it works?
            const result = await chats.getList(1, 10, undefined, null, filterObject )
            setChatList({chats:result.data, total: result.meta.item_count})
        }

        const fetchImprint = async () => {
            const result = await imprints.getFirst()
            setImprint(result)
        }
        fetchChats(); fetchImprint()
    },[])

    const handleLoadMoreChats = () => {
        // TODO
    }

    const handleChangeMailSettings = (event: any) => {
        const template = event.target.id.split("_")[0]
        setEmailSettings({...emailSettings, [template]: {subject:{ ...emailSettings[template as TemplateKey].subject, value: event.target.value}}})
    }

    const handleDeviceChange = (device: Device) => {
        setQueryParams({ device })
    }

    // const handlePreviewIndexChange = (index: number) => {
    //     setPreviewIndex(index)
    // }

    const handlePrevious = () => {
        setState({ ...state, activeStep: state.activeStep - 1 })
    }

    const handleNext = () => {
        setState({ ...state, activeStep: state.activeStep + 1 })
    }

    const handleSetChat = (id: number | null) => {
        setState({ ...state, chat: id})
    }

    const handleOpenChat = () => {
        if(state.chat === null){
            setState({...state, chat: 0})
        } else {
            setState({...state, chat: null})
        }
    }

    const handlePrebuildChosen = (prebuildKey: string) => {
        console.warn("deprecated, use webformApi.getPrebuild(key) instead")
        Promise.all([
            downloadPrebuildConfig(prebuildKey),
            downloadPrebuildHtml(prebuildKey, "form_lp"),
            downloadPrebuildHtml(prebuildKey, "soi_lp"),
            downloadPrebuildHtml(prebuildKey, "doi_mail"),
            downloadPrebuildHtml(prebuildKey, "doi_lp"),
            downloadPrebuildHtml(prebuildKey, "done_mail")
        ]).then(([config, formLP, soiLP, doiMail, doiLP, doneMail]) => {
            setState({
                ...state,
                //activeStep: state.activeStep+1,
                prebuild: {
                    config: config as IConfig,
                    templates: {
                        formLP: formLP as string,
                        soiLP: soiLP as string,
                        doiMail: doiMail as string,
                        doiLP: doiLP as string,
                        doneMail: doneMail as string
                    }
                }
            })
        })

    }

    const handleSaveFormLP = (placeholders: Record<string, IPlaceholder>, template: string) => handleSaveTemplate("formLP", placeholders, template)
 
    const handleSaveSoiLP = (placeholders: Record<string, IPlaceholder>, template: string) => handleSaveTemplate("soiLP", placeholders, template)

    const handleSaveDoiMail = (placeholders: Record<string, IPlaceholder>, template: string) => handleSaveTemplate("doiMail", placeholders, template)

    const handleSaveDoiLP = (placeholders: Record<string, IPlaceholder>, template: string) => handleSaveTemplate("doiLP", placeholders, template)

    const handleSaveDoneMail = (placeholders: Record<string, IPlaceholder>, template: string) => handleSaveTemplate("doneMail", placeholders, template)

    const handleSaveTemplate = (key: keyof IState, placeholders: Record<string, IPlaceholder>, template: string, nextKey?: keyof IState) => {
        if (state.prebuild !== null)
            setState({
                ...state,
                activeStep: state.activeStep + 1,
                prebuild: { ...state.prebuild, config: { ...state.prebuild.config, placeholders: { ...deepCopy(placeholders) } } },
                [key]: { placeholders, template },
                ...(nextKey ? { [nextKey]: { placeholders: { ...deepCopy(placeholders) } } } : {})
            })
    }

    if(!chatList || !imprint){
        return <LoadingAnimation/>
    }

    console.log(state)

    const renderStep = () => {
        switch (state.activeStep) {
            case 0: return <FirstStep
                state={state}
                navigationProps={{ activeStep: state.activeStep, steps: steps.length, onPrevious: handlePrevious }}
                setBasicInfo={setBasicInfo}
                setState={setState}
            />
            case 1:
                return <WebformPrebuilds
                    onSubmit={handlePrebuildChosen}
                    template={state?.prebuild?.templates.formLP}
                    placeholders={state?.prebuild?.config.placeholders}
                    navigationProps={{ activeStep: state.activeStep, steps: steps.length, onPrevious: handlePrevious, onNext: handleNext }}
                    basicInfo={basicInfo}
                    customConfigHeader={{
                        header: steps[0].label,
                        description: steps[0].description
                    }}
                    previewIndex={previewIndex}
                    device={queryParams.device}
                    onDeviceChange={handleDeviceChange}
                />
            case 2:
                if (state?.prebuild?.templates?.formLP && state?.prebuild?.config?.placeholders && state?.prebuild?.config?.groups)
                    return <FormBuilder
                        template={state.prebuild.templates.formLP}
                        placeholders={state.prebuild.config.placeholders}
                        inputGroups={state.prebuild.config.groups}
                        onSubmit={handleSaveFormLP}
                        navigationProps={{ activeStep: state.activeStep, steps: steps.length, onPrevious: handlePrevious }}
                        customConfigHeader={{
                            header: steps[1].label,
                            description: steps[1].description
                        }}
                        previewIdentifier={TemplateKey.formLP}
                        basicInfo={basicInfo}
                        device={queryParams.device}
                        onDeviceChange={handleDeviceChange}
                        templateMailSettings={emailSettings.formLP}
                        handleChangeMailSettings={handleChangeMailSettings}
                        chats={chatList}
                        handleLoadMoreChats={handleLoadMoreChats}
                        handleSetChat={handleSetChat}
                        handleOpenChat={handleOpenChat}
                        wizardState={state}
                        imprint={imprint}
                    />
                else return null
            case 3:
                if (state?.prebuild?.templates.soiLP && state?.prebuild?.config?.placeholders && state.prebuild.config.groups)
                    return <FormBuilder
                        template={state.prebuild.templates.soiLP}
                        placeholders={state.prebuild.config.placeholders}
                        inputGroups={state.prebuild.config.groups}
                        onSubmit={handleSaveSoiLP}
                        navigationProps={{ activeStep: state.activeStep, steps: steps.length, onPrevious: handlePrevious }}
                        customConfigHeader={{
                            header: steps[2].label,
                            description: steps[2].description
                        }}
                        previewIdentifier={TemplateKey.soiLP}
                        basicInfo={basicInfo}
                        device={queryParams.device}
                        onDeviceChange={handleDeviceChange}
                        templateMailSettings={emailSettings.soiLP}
                        handleChangeMailSettings={handleChangeMailSettings}
                        chats={chatList}
                        handleLoadMoreChats={handleLoadMoreChats}
                        handleSetChat={handleSetChat}
                        handleOpenChat={handleOpenChat}
                        wizardState={state}
                        imprint={imprint}
                    />
                else return null
            case 4:
                if (state?.prebuild?.templates.doiMail && state?.prebuild?.config?.placeholders && state.prebuild.config.groups)
                    return <FormBuilder
                        template={state.prebuild.templates.doiMail}
                        placeholders={state.prebuild.config.placeholders}
                        inputGroups={state.prebuild.config.groups}
                        onSubmit={handleSaveDoiMail}
                        navigationProps={{ activeStep: state.activeStep, steps: steps.length, onPrevious: handlePrevious }}
                        customConfigHeader={{
                            header: steps[3].label,
                            description: steps[3].description
                        }}
                        previewIdentifier={TemplateKey.doiMail}
                        basicInfo={basicInfo}
                        device={queryParams.device}
                        onDeviceChange={handleDeviceChange}
                        templateMailSettings={emailSettings.doiMail}
                        handleChangeMailSettings={handleChangeMailSettings}
                        chats={chatList}
                        handleLoadMoreChats={handleLoadMoreChats}
                        handleSetChat={handleSetChat}
                        handleOpenChat={handleOpenChat}
                        wizardState={state}
                        imprint={imprint}
                    />
                else return null
            case 5:
                if (state?.prebuild?.templates.doiLP && state?.prebuild?.config?.placeholders && state.prebuild.config.groups)
                    return <FormBuilder
                        template={state.prebuild.templates.doiLP}
                        placeholders={state.prebuild.config.placeholders}
                        inputGroups={state.prebuild.config.groups}
                        onSubmit={handleSaveDoiLP}
                        navigationProps={{ activeStep: state.activeStep, steps: steps.length, onPrevious: handlePrevious }}
                        customConfigHeader={{
                            header: steps[4].label,
                            description: steps[4].description
                        }}
                        previewIdentifier={TemplateKey.doiLP}
                        basicInfo={basicInfo}
                        device={queryParams.device}
                        onDeviceChange={handleDeviceChange}
                        templateMailSettings={emailSettings.doiLP}
                        handleChangeMailSettings={handleChangeMailSettings}
                        chats={chatList}
                        handleLoadMoreChats={handleLoadMoreChats}
                        handleSetChat={handleSetChat}
                        handleOpenChat={handleOpenChat}
                        wizardState={state}
                        imprint={imprint}
                    />
                else return null
            case 6:
                if (state?.prebuild?.templates.doneMail && state?.prebuild?.config?.placeholders && state.prebuild.config.groups)
                    return <FormBuilder
                        template={state.prebuild.templates.doneMail}
                        placeholders={state.prebuild.config.placeholders}
                        inputGroups={state.prebuild.config.groups}
                        onSubmit={handleSaveDoneMail}
                        navigationProps={{ activeStep: state.activeStep, steps: steps.length, onPrevious: handlePrevious }}
                        customConfigHeader={{
                            header: steps[5].label,
                            description: steps[5].description
                        }}
                        previewIdentifier={TemplateKey.doneMail}
                        basicInfo={basicInfo}
                        device={queryParams.device}
                        onDeviceChange={handleDeviceChange}
                        templateMailSettings={emailSettings.doneMail}
                        handleChangeMailSettings={handleChangeMailSettings}
                        chats={chatList}
                        handleLoadMoreChats={handleLoadMoreChats}
                        handleSetChat={handleSetChat}
                        handleOpenChat={handleOpenChat}
                        wizardState={state}
                        imprint={imprint}
                    />
                else return null
            case 7:
                return <FinalStep
                    result={state}
                    steps={steps}
                    basicInfo={basicInfo}
                    navigationProps={{ activeStep: state.activeStep, steps: steps.length, onPrevious: handlePrevious }}
                    emailSettings={emailSettings}
                    onPrevious={handlePrevious}
                    setState={setState}
                />
            default:
                console.warn("unknown step: ", state.activeStep)
                return null
        }
    }


    return (
        <Box className={classes.wizardwrapper}>
            {renderStep()}
        </Box>
    )
}

export type NavigationProps = {
    steps: number
    activeStep: number
    // onNext?: () => void
    // onPrevious?: () => void
    onNext?: any
    onPrevious?: any
    nextEnabled?: boolean
}

export const Navigation = ({ steps, activeStep, onNext, onPrevious, nextEnabled }: NavigationProps) => {
    const { t } = useTranslation()
    const classes = useStyles()


    return (
        <Grid container className={classes.Wizardnav} justify="flex-start" alignItems="center" direction='row'>
            <CancelButton destination={"webforms"}/>
            <MobileStepper
                className={classes.WizardStepper}
                variant="text"
                steps={steps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    activeStep === 0 || activeStep === 7 ? null : <Button onClick={onNext} variant="contained" disableElevation color="success" size="small" disabled={nextEnabled}>
                        {/*   {activeStep !== 6 ? t("weiter") : t("Abschließen")}*/}
                        {t("Weiter")}
                        <Fa icon={["fas", "chevron-right"]} ml={1}></Fa>
                    </Button>
                }
                backButton={
                    activeStep !== 0 ?
                        <Button onClick={onPrevious} disabled={activeStep === 0} variant="contained" disableElevation color="success" size="small">
                            <Fa icon={["fas", "chevron-left"]} mr={1}></Fa>
                            {t("Zurück")}
                        </Button>
                        : null
                }
            />
        </Grid>
    )
}
export default WebformWizard

export const CancelButton = ({destination}: {destination: string}) => {
    const history = useHistory()
    const {t} = useTranslation()
    const { dialog } = useDialog()
    
    const handleClick = () => {
        dialog.show({
            component: CancelWizardDialog,
            props: {}
        }).then((response) => {
            if(response.leave){
                history.push(`/app/stock/${destination}`)
            }
        })
    }

    return (
        <Button onClick={handleClick} variant="contained" disableElevation color="error" size="small" style={{ marginRight: "16px" }}>
            <Fa icon={["fas", "times"]} mr={1} />
            {t("Abbrechen")}
        </Button>
    )
}