import {withCrud, CrudModel, BaseModel} from "./builder"
import {get} from "api/crud"

export type IChat = {
    id: number
    name: string
    settings: any
}

export type ChatApi = CrudModel<IChat> & BaseModel //TYPING

export default{

    // info() not working????
    name:"chats",
    ...withCrud<any>("chats"),

    one: (id:number) => ({
        checkUse:() => {
            return get<Number[]>(`chats/${id}/used_in_webforms`)
        }
    })
}