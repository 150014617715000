import PagePadding from 'components/PagePadding';
import React from 'react';


const Binding = () => {

  return (
    <PagePadding>
      <p>binding</p>
    </PagePadding>
  )
}

export default Binding