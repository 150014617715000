import React from "react"
import { Box, Paper, makeStyles } from "@material-ui/core"

const useStyles = makeStyles ((theme) => ({
    main: {
        marginBottom: theme.spacing(3)
    },
    title: {
        padding: `${theme.spacing(0)}px ${theme.spacing(3)}px`,
        //boxSizing: "border-box",
        background: theme.palette.lightGrey.main,
        borderBottom: `1px solid ${theme.palette.companyRed.main}`,
        display: "flex",
        position: "relative",
        alignItems: "center",
        minHeight: "64px"
    },
    content: {
        padding: theme.spacing(3),
        boxSizing: "border-box"
    }
}))

export const Block: React.FC<{}> = ({children} ) => {
    const classes = useStyles()
    return(
        <Paper elevation={2} className={classes.main}>
            {children}
        </Paper>
    )
}
export const BlockTitle: React.FC<{}> = ({children} ) => {
    const classes = useStyles()

    return(
        <Box className={classes.title}>
            {children}
        </Box>
    )
}
export const BlockContent: React.FC<{}> = ({children} ) => {
    const classes = useStyles()

    return(
        <Box className={classes.content}>
            {children}
        </Box>
    )
}


export default Block