import React from "react"
import { useTranslation } from "react-i18next"
import { DialogProps } from "hooks/useDialog"
import { makeStyles, Theme, Dialog, Grid } from "@material-ui/core"
import DialogTitle from "dialogs/DialogTitle"
import DialogContent from "dialogs/DialogContent"
import DialogActions from "dialogs/DialogActions"
import Button from "components/Button/Button"
import { Fa, Typography } from 'styled/muiComponents'

const useStyles = makeStyles<Theme, {}>(theme => ({
    title: {
        backgroundColor: theme.palette.primary.light,
        color: "white"
    },
    divider: {
        backgroundColor: theme.palette.companyRed.main,
        height: "2px"
    },
    card: {
        marginBottom: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        cursor: "pointer"
    },
    errorColumn: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    warningIcon: {
        color: "orange",
        marginRight: theme.spacing(2)
    }
}))

type Props = {
    
}

const ExitChatDialog: React.FC<Props & DialogProps> = ({ onClose, onError, open }) => {
    const { t } = useTranslation()
    const classes = useStyles({})

    const handleClose = () => {
        onClose({leave:false, save: false})
    }

    const handleLeaveNoSave = () => {
        onClose({leave: true, save:false})
    }

    const handleSave = () => {
        onClose({leave: false, save: true})
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>
                <Fa icon={["fal", "exclamation-triangle"]} className={classes.warningIcon} mr={1.75} />{t("Achtung")}
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column">
                    <Typography variant="h2" mb={2}>{t("Du hast nicht gespeicherte Änderungen")}</Typography>
                    {t("Möchtest du den Chat-Editor wirklich verlassen, ohne die Änderungen zu speichern? Bitte beachte, dass alle Änderungen verloren gehen.")}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify="space-between">
                    <Grid item>
                        <Button onClick={handleClose} variant="contained" color="error" size="small"><Fa icon={["fas", "times"]} mr={1}  /> {t("Abbrechen")}</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleSave} variant="contained" color="success" size="small" style={{marginRight: "8px"}}><Fa icon={["fas", "floppy-disk"]} mr={1} />{t("Speichern & Verlassen")}</Button>
                        <Button onClick={handleLeaveNoSave} variant="contained" color="success" size="small"><Fa icon={["fas", "floppy-disk-circle-xmark"]} mr={1} /> {t("Verlassen")}</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default ExitChatDialog