import React, { useState } from "react"
import { SetupNavigationProps, Navigation, ISetupInformation } from "../SetupWizard"
import { Checkbox } from "@material-ui/core"
import { Typography, Grid } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { useTranslation } from "react-i18next"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Block, BlockContent, BlockTitle } from "components/Block"

type Props = {
    navigationProps: SetupNavigationProps
    classes: any
    setupInformation: ISetupInformation
    handleComplete: () => void
}

const ConfirmSetup = ({ navigationProps, classes, setupInformation, handleComplete }: Props) => {
    const { t } = useTranslation()
    const [enableNext, setEnableNext] = useState<boolean>(false)

    return (
        <Grid container item direction="column" className={classes.root}>
            <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                <Grid container item direction="column" className={classes.form}>
                    <Grid container direction="column" justify="flex-start">
                        <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                            <Grid item sm={4}>
                                <div style={{ maxWidth: "150px" }}>
                                    <Pixi size="full" pose="waiting"></Pixi>
                                </div>
                            </Grid>
                            <Grid item sm={8}>
                                <div className={classes.headerDescription} >
                                <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                    <Typography variant="h5" className={classes.pixiSpeech}>
                                        {t("Und schon hast du es geschafft. Kontrolliere bitte noch mal deine Angaben und klicke anschließend auf „Fertig stellen“.")} 
                                    </Typography>
                                </Typist>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item container className={classes.formContent}>
                            <Grid item xs={12}>
                                <Block>
                                    <BlockTitle>
                                        <Typography variant="h2">{t("Vorschau des Impressums")}</Typography>
                                    </BlockTitle>
                                    <BlockContent>
                                        <p>{setupInformation.company_name} {/* {setupInformation.legal_form} */}<br />
                                            {setupInformation.street_name} {setupInformation.street_number}<br />
                                            {setupInformation.postal_code} {setupInformation.city}</p>

                                        <p>
                                            <strong>{t("Telefonnummer")}:</strong> {setupInformation.phone_number}<br />
                                            <strong>{t("E-Mail Adresse")}:</strong> {setupInformation.imprint_mail}
                                        </p>

                                        {["GbR", "OHG", "KG", "GmbH", "GmbH & Co KG", "Andere"].some(v => (setupInformation.legal_form).includes(v)) ?
                                                <p>
                                                    <strong>{t("Geschäftsführer/Vetretungsberechtigter")}:</strong> {setupInformation.company_board}<br />
                                                </p>
                                            : (["AG", "e. G."].some(v => (setupInformation.legal_form.includes(v)))) ?
                                                <>
                                                    <p>
                                                        <strong>{t("Vorstand")}:</strong> {setupInformation.company_board}<br />
                                                        <strong>{t("Aufsichtsrat")}:</strong> {setupInformation.supervisory_board}
                                                    </p>
                                                    <br />
                                                </>
                                                : null
                                        }
                                        {setupInformation.vat_identifier ?
                                        <p>
                                            <strong>{t("Steuernummer")}:</strong> {setupInformation.vat_identifier}
                                        </p>
                                        : null}

                                        {setupInformation.ust_identifier ?
                                            <p>
                                                <strong>{t("Umsatzsteuernummer")}:</strong> {setupInformation.ust_identifier}
                                            </p>
                                            : null}

                                        {setupInformation.company_register ?
                                                <p>
                                                    <strong>{t("Register")}:</strong> {setupInformation.company_register}<br />
                                                    <strong>{t("Registergericht")}:</strong> {setupInformation.registry_court}<br />
                                                    <strong>{t("Registernummer")}:</strong> {setupInformation.registry_number}
                                                </p>
                                            : null}

                                        {setupInformation.supervisory_authority ?
                                                <p>
                                                    <strong>{t("Aufsichtsbehörde")}:</strong> {setupInformation.supervisory_authority}<br />
                                                    <strong>{t("Zuständige Kammer")}:</strong> {setupInformation.responsible_chamber}<br />
                                                    <strong>{t("Gesetzliche Berufsbezeichnung und Staat in dem sie verliehen wurde")}:</strong> {setupInformation.job_title_plus_country_awarded}<br />
                                                    <strong>{t("Berufsrechtliche Regelungen")}:</strong> {setupInformation.professional_regulations}
                                                </p>
                                            : null}

                                        <p>{setupInformation.additional_information}</p>
                                    </BlockContent>
                                </Block>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    value="end"
                                    control={
                                        <Checkbox
                                            value={enableNext}
                                            onChange={() => setEnableNext(!enableNext)}
                                        />
                                    }
                                    label={t("Ich versichere alle Angaben korrekt angegeben zu haben *")}
                                    labelPlacement="end"
                                />
                            </Grid>
                        </Grid>
                        
                        <Grid item>
                            <Navigation  {...navigationProps} enableNext={!enableNext} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.formNote}>
                    <Typography variant="body1" className={classes.requiredNotice}>
                        <i>* {t("Pflichtfeld")}</i>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ConfirmSetup