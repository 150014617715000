import React, { useState, useEffect, useRef, MutableRefObject } from 'react'
import webformApi, { IWebform } from 'models/webform'
import useLocationStateOrFetch from 'hooks/useLocationStateOrFetch'
import { QRCode } from 'react-qrcode-logo'
import { Box, Grid, makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Typography } from 'styled/muiComponents'
import Button from 'components/Button/Button'
import { Fa } from 'styled/muiComponents'
import { useHistory } from "react-router-dom"
import isImprintValid from 'constants/isImprintValid'
import Typist from "react-typist"
import Pixi from "components/Pixi"
import { Block, BlockContent, BlockTitle } from "components/Block"
import { delay } from "constants/delay"

const QRCodeOptionsContainer = () => {
	const webform = useLocationStateOrFetch<IWebform>(webformApi)

	return (
		webform ? <QRCodeOptions webform={webform} /> : null
	)
}

const useStyles = makeStyles<Theme>(theme => ({
	root: {
			height: "100%",
			width: "100%",
			backgroundColor: theme.palette.companyDark.light,
			overflow: "auto",
			//padding: theme.spacing(1),
			boxSizing: "border-box",
			"& p": {
				fontWeight: "300",
				"& strong": {
						fontWeight: "500"
				}
			}
		},
		form: {
			width: "100%",
			margin: "auto",
			maxWidth: "600px",
			// padding: theme.spacing(4),
			backgroundColor: theme.palette.lightGrey.main,
			borderRadius: theme.spacing(0.5),
			boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
			boxSizing: "border-box",
			overflow: "hidden",
			border: "1px solid #ffffff",
			marginBottom: theme.spacing(2)
		},
		formHeader: {
			padding: theme.spacing(4),
			//backgroundColor: "#83ac1b2b",
			backgroundColor: "#E6ECEF",
		},
		formContent: {
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4),
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(4),
		},
		formFooter: {
		 //   paddingLeft: theme.spacing(4),
		 //   paddingRight: theme.spacing(4),
		},
		formNote: {
			width: "100%",
			maxWidth: "600px",
			color: "#ffffff75",
			paddingTop: theme.spacing(2)
		},
		headerDescription: {
			padding: theme.spacing(2),
			fontSize: "0.8rem",
			//backgroundColor: "#00000015",
			backgroundColor: "#ffffff90",
			borderRadius: "5px",
			position: "relative",
			'&:after': {
				content: "''",
				position: "absolute",
				left: 0,
				top: "50%",
				width: 0,
				height: 0,
				border: "18px solid transparent",
				//borderRightColor: "#00000015",
				borderRightColor: "#ffffff90",
				borderLeft: 0,
				borderBottom: 0,
				marginTop: "-9px",
				marginLeft: "-18px",
			},
		},
	LinkToForm:{
		padding: theme.spacing(2),
	},
	LinkToFormWrapper: {
		borderRadius: "5px",
		backgroundColor: "#ebebeb",
		margin: "16px 0",
		"& a": {
			textDecoration: "none",
			wordBreak: "break-all"
		}
	},
	exampleImage: {
		backgroundImage: "url(/assets/images/qr-code-simple-blue.jpg)",
		backgroundSize: "cover",
		backgroundPosition: "center"
	},
	qrcodeBanner: {
		backgroundColor: "#E6ECEF",
	},
	qrCodeWrapper: {
		borderRadius: "4px",
		width: "auto",
		backgroundColor: "white",
		display: "flex",
		flexDirection: "column",
//		padding: "4px 4px 0",
		overflow: "hidden"
	},
	qrGroupButton: {
		margin: "0 8px 8px"
	}
}))

type Props = {
	webform: IWebform
}

const QRCodeOptions = ({ webform }: Props) => {
	const [url, setUrl] = useState("")
	const [validImprint, setValidImprint] = useState<boolean>(false)
	const { t } = useTranslation()
	const classes = useStyles()
	const history = useHistory()
	const qrRef = useRef<QRCode>()

	useEffect(() => {
		const checkImprint = async () => {
			const result = await isImprintValid()
			setValidImprint(result)
		}

		const fetchURL = async () => {
			const result = await webformApi.getFormUrl(webform)
			setUrl(result)
		}
		checkImprint(); fetchURL()
	},[])

	const handleDownload = () => {
		// qrRef.current?.download()
		console.log(qrRef.current)
		const link = document.createElement('a')
		link.download = `${webform!.name} QRCode`
		const canvas = document.getElementById('react-qrcode-logo') as HTMLCanvasElement
		link.href = canvas.toDataURL()
		delay(1000).then(() => link.click())
	}

	const handleCopy = () => {
		navigator.clipboard.writeText(url).then(() => {
			// alert("URL copied to clipboard!");
		}).catch(err => {
			// console.error("Failed to copy: ", err);
		});
	}

	const handlePrint = () => {
		const canvas = document.getElementById('react-qrcode-logo') as HTMLCanvasElement;
	
		// Get the data URL of the canvas
		const dataUrl = canvas.toDataURL();
	
		// Open a new window
		const printWindow = window.open('', '_blank', 'width=800,height=600');
	
		if (printWindow) {
			// Write the image to the new window's document
			printWindow.document.write(`
				<!DOCTYPE html>
				<html>
				<head>
					<title>Print QR Code</title>
					<style>
						body {
							display: flex;
							justify-content: center;
							align-items: center;
							height: 100vh;
							margin: 0;
						}
						img {
							max-width: 100%;
							max-height: 100%;
						}
					</style>
				</head>
				<body>
					<img src="${dataUrl}" alt="QR Code">
					<script>
						window.onload = function() {
							window.print();
							window.onafterprint = function() {
								window.close();
							};
						};
					</script>
				</body>
				</html>
			`);
	
			// Focus the new window
			printWindow.document.close();
			printWindow.focus();
		}
	};


	// const handleGoBack = () => {
	//   history.go(-1) // TODO back button doesnt lead you to parent page in cornerstone when accessing this page from outside
	// }
	
	const handleGoToDisplayWizard = () => {
		history.push(`/app/stock/QRforms/create`)
	}

	return (


	<Grid container item direction="column" className={classes.root}>
		<Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto", paddingTop: "32px", paddingBottom: "32px"}}>
			{ validImprint ? 
				<>
					<Grid container direction="row" justify="flex-start" className={classes.form}>
						<Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
							<Grid item sm={4}>
								<div style={{ maxWidth: "150px" }}>
									<Pixi size="full" pose="waiting"></Pixi>
								</div>
							</Grid>
							<Grid item sm={8}>
								<div className={classes.headerDescription} >
									<Typist avgTypingDelay={25} cursor={{ show: false }}>
										<Typography variant="h5" className={classes.pixiSpeech}>
											{t("Um Adressen sammeln zu können, müssen Interessenten dein Formular auch finden. Bringe den QR-Code in Umlauf oder platziere den Link zum Formular auf deiner Webseite.")}
										</Typography>
									</Typist>
								</div>
							</Grid>
						</Grid>
						<Grid item container className={classes.formContent}>
							<Grid item container xs={12}>
								<Grid item sm={8}>
									<Typography variant="h2" mb={1}>
										<strong>{t("QR-Code")} </strong>
									</Typography>
									<Typography mb={2}>
										{t("Dies ist der QR-Code zu deinem Anmeldeformular. Diesen kannst du nun drucken und sofort mit der generierung von Kontakten beginnen!")}
									</Typography>
									<Typography>
										<strong>{t("Tipp")}: </strong>
										{t("Erstelle ein atraktives Printmedium, damit dein QR-Code besser wahrgenommen wird. Zum Beispiel einen Aufsteller, Flyer, Visitenkarten oder Rubbelkarten-Gewinnspiele.")}<br />
										<a href="#" onClick={handleGoToDisplayWizard}>{t("Jetzt Printmedium gestalten.")}</a>
									</Typography>
								</Grid>
								<Grid item sm={4} container justify='flex-end'>
									<Box boxShadow={1} className={classes.qrCodeWrapper}>
										<a href={url} target="_blank" rel="noopener noreferrer">
											<QRCode value={url} size={140} ref={qrRef as MutableRefObject<QRCode>}/>
										</a>
										<Button variant="contained" disableElevation color="success" size="small" className={classes.qrGroupButton} onClick={() => handleDownload()}>
											<Fa icon={["fas", "download"]} mr={1} />
											{t("Herunterladen")}
										</Button>
										<Button variant="contained" disableElevation color="success" size="small" className={classes.qrGroupButton} onClick={() => handlePrint()}>
											<Fa icon={["fas", "print"]} mr={1} />
											{t("Drucken")}
										</Button>
										{/* <Button variant="outlined" disableElevation color="success" size="small" className={classes.qrGroupButton} onClick={() => console.warn("not implemented")} disabled>
											<Fa icon={["fas", "paintbrush"]} mr={1}/>
											{t("QR Gestalten")}
										</Button> */}
									</Box>
								</Grid>
							</Grid>
							<Grid item container xs={12} style={{marginTop: "32px", paddingTop: "32px", borderTop: "1px solid #ced1d2" }}>
								<h2 style={{marginBottom: "8px"}}>{t("URL zum Formular ")}</h2>
								<Typography>
									{t("Ergänzend zum QR-Code kannst du diesen Link beispielsweise auf deiner Webseite einbinden, um auch online Abonnenten zu gewinnen.")}
								</Typography>
								<Grid container direction='row' className={classes.LinkToFormWrapper}>
									<Box className={classes.LinkToForm}><a href={url} target="_blank" rel="noopener noreferrer">{url} <Fa icon={["fal", "external-link"]} style={{ color: "#83ac1b" }} /></a></Box>
								</Grid>
								<Button variant="contained" disableElevation color="success" size="small" onClick={handleCopy}>
									<Fa icon={["fas", "copy"]} mr={1} />
									{t("URL kopieren")}
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<Grid container direction="row" justify="flex-start" className={classes.form}>
						<Grid item sm={12} container justify="flex-start" className={classes.qrcodeBanner}>
							<Grid item sm={4} className={classes.exampleImage}>
								
							</Grid>
							<Grid item sm={8} style={{padding: "20px"}}>
								<Typography variant="h2" mb={1}>
									<strong>{t("Verbreite deinen QR-Code")}</strong>
								</Typography>
								<Typography mb={2}>
									{t("Erstelle Aufsteller, Flyer, Visitenkarten oder Rubbelkarten mit deinem QR-Code drauf, um noch schneller neue Kontaktaderssen zu gewinnen.")}
								</Typography>
								<Button variant="contained" disableElevation color="success" size="small" onClick={handleGoToDisplayWizard}>
									<Fa icon={["fas", "sign-in"]} mr={1} />
									{t("Jetzt gestalten")}
								</Button>
							</Grid>
						</Grid>
					</Grid>
					</>
					:
					<>
						<div style={{width: "100%", maxWidth: "600px"}}>
							<Block>
								<BlockTitle>
									<Typography variant="h2">{t("QR-Code")}</Typography>
								</BlockTitle>
								<BlockContent>
									<Typography style={{marginBottom: "16px"}}>
										{t("Der QR Code zum Anmeldeformular ist nicht einsehbar, da dein Impressum noch nicht vollständig ist. Dein Impressum kannst du unter Einstellungen bearbeiten.")}
									</Typography>
									<Button variant="contained" disableElevation color="success" size="small" href="/app/preferences">
										<Fa icon={["fas", "pencil"]} mr={1} />
										{t("Jetzt bearbeiten")}
									</Button>
								</BlockContent>
							</Block>
						</div>
					</>}
		</Grid>
	</Grid>
	)
}

export default QRCodeOptionsContainer