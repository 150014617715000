import { withCrud, CrudModel, BaseModel } from "./builder";
import { get } from '../api/crud'

export type InvoicesApi = CrudModel<any> & BaseModel // TODO TYPING

export default {
    name: "invoices",
    ...withCrud<any>("invoices"),

    one: (id:number) => ({
        download:() => {
            return get<any>(`invoices/${id}/download`)
        }
    })
}