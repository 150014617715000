import React from "react"
import { Switch as MuiSwitch } from "@material-ui/core"
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Switch = ({...props}) => {
    // console.log(props)
    const {input:{name, value,...field}}= props
    // console.log(props)
    // console.log(value)
    // console.log(field)
    // console.log(meta)
    // console.log(props.label)

    const handleBlur = (e) => {
        if(props.onBlur) {
          props.onBlur({target:{value: e.target.value, name: name}})
        }
        field.onBlur({target:{value: e.target.value, name: name}})
      }
    
      const handleChange = (e) => {
        if(props.onChange) {
          props.onChange(e)
        }
        field.onChange(e)
      }

    return (
        <FormControlLabel
            control={
                <MuiSwitch 
                    name={name} 
                    chcked={value}
                    onChange={(e) => {
                        handleChange(e)
                    }}
                    onBlur={(e) => {
                        handleBlur(e)
                    }} 
                />
            }
            label={props.label}
        />
    )
}

export default Switch