import { ChatState, getAllEdges, getAllNodes } from "./chatFunctions"
import { Node, Edge, getOutgoers } from "react-flow-renderer"
// import * as yup from 'yup';
import i18n from 'i18next';


export const validation = (state: ChatState) => {
    let errors: string[] = []

    // has a single startnode
    !singleStartNode(state.elements) && errors.push(i18n.t("Es gibt mehr als einen Start."))

    // has at least one end Node
    !hasEndNode(state.elements) && errors.push(i18n.t("Es gibt kein Ende."))

    // all necessary general settings put
    generalSettingsPut(state.generalSettings).then((value) => !value && errors.push(i18n.t("Deine Einstellungen sind nicht vollständig.")))

    // all textfields of nodes are filled out
    !allFieldsFilled(state.elements) && errors.push(i18n.t("Es gibt Nodes die nicht vollständig ausgefüllt sind."))

    // all nodes have incomming edge (excluding start) AND all nodes have outgoing edge (excluding end, goto)
    !edgesPresent(state.elements) && errors.push(i18n.t("Es gibt Nodes welche nicht verbunden sind."))

    // all goto nodes are connected
    !gotoConnected(state.elements) && errors.push(i18n.t("Es gibt goto Nodes welche keinen Nachfolger haben"))

    // no loop
    loopPresent(state.elements) && errors.push(i18n.t("Es gibt eine Endlosschleife."))
    return errors
}

const singleStartNode = (elements: (Node | Edge)[]) => {
    if (elements.filter((element: Node | Edge) => element.type === "start").length === 1) {
        return true
    } else {
        return false
    }
}

const hasEndNode = (elements: (Node | Edge)[]) => {
    if (elements.filter((element: Node | Edge) => element.type === "end").length >= 1) {
        return true
    } else {
        return false
    }
}

const generalSettingsPut = async (settings: ChatState["generalSettings"]) => {
    // const schema = yup.object().shape({
    //     name: yup.string().required()
    // })

    // const value = await schema.isValid(settings)

    // return value
    //TODO always false for some reason??
    return true
}

const allFieldsFilled = (elements: (Node | Edge)[]) => {
    const allNodes = getAllNodes(elements)
    return allNodes.every((node: Node) => {
        switch (node.type) {
            case "botResponse": {
                return node.data.responses.every((response: any) => {
                    // expand switch when response gets more response types
                    switch (response.type) {
                        case "text": {
                            return response.text !== ""
                        }
                        case "textWithButton": {
                            return response.text !== "" && response.buttonText !== ""
                        }
                        case "coupon": {
                            return response.text !== "" && response.coupon !== ""
                        }

                        default: return false
                    }
                })
            }

            case "splitquestion":
                return node.data.question !== "" && node.data.answers.every((answer: any) => answer.answer !== "")

            case "splitanswer":
                return node.data.answer !== ""

            case "socialMedia":
                if (node.data.socialMedias.length === 0) {
                    return false
                }
                return node.data.text !== "" && node.data.socialMedias.every((sm: any) => sm.plattform !== "" && sm.url !== "")

            case "start":
                return node.data.text !== "" && node.data.buttonTexts.textOne !== "" && node.data.buttonTexts.textTwo !== ""

            case "userFeedback":
                return node.data.text !== ""

            case "dataCollection":
                return node.data.questions.every((question: any) => question.field !== "" && question.question !== "" && question.validation !== "")
            case "feedback":

                return node.data.text !== ""
            default: return true
        }
    })
}

const edgesPresent = (elements: (Node | Edge)[]) => {
    const allNodes = getAllNodes(elements)
    const allEdges = getAllEdges(elements)

    const outgoingEdgesPresent = () => {
        // goto and end have not outgoing matches
        const filteredNodes = allNodes.filter((node: Node) => node.type !== "end" && node.type !== "goTo")
        return filteredNodes.every((node: Node) => allEdges.some((edge: Edge) => edge.source === node.id))
    }

    const incommingEdgesPresent = () => {
        // start has no incomming
        const filteredNodes = allNodes.filter((node: Node) => node.type !== "start")
        return filteredNodes.every((node: Node) => allEdges.some((edge: Edge) => edge.target === node.id))
    }

    return (outgoingEdgesPresent() && incommingEdgesPresent())
}

const gotoConnected = (elements: (Node | Edge)[]) => {
    const gotos: Node[] = elements.filter((ele: Node | Edge): ele is Node => ele.type === "goTo")
    if (gotos.length === 0) {
        return true
    }
    return gotos.some((goto: Node) => { return goto.data.continueWith })
}

const loopPresent = (elements: (Node | Edge)[]) => {
    const allNodes = getAllNodes(elements)

    const adj: number[][] = []

    allNodes.forEach((_: Node) => {
        adj.push([])
    })

    const isCyclicUtil = (i: number, visited: boolean[], recStack: boolean[]) => {
        if (recStack[i])
            return true;

        if (visited[i])
            return false;

        visited[i] = true;

        recStack[i] = true;
        const children = adj[i];

        for (let c = 0; c < children.length; c++)
            if (isCyclicUtil(children[c], visited, recStack))
                return true;

        recStack[i] = false;

        return false;
    }

    const isCyclic = () => {
        let visited = new Array(allNodes.length)
        let recStack = new Array(allNodes.length)

        for (let i = 0; i < allNodes.length; i++) {
            visited[i] = false;
            recStack[i] = false;
        }

        for (let i = 0; i < allNodes.length; i++)
            if (isCyclicUtil(i, visited, recStack)) {
                return true
            }
        return false;
    }

    const addEdge = (source: number, dest: number) => {
        adj[source].push(dest)
    }

    allNodes.forEach((node: Node, idx: number) => {

        const children = getOutgoers(node, elements)
        const indicies = children.map((node: Node) => {
            return allNodes.indexOf(node)
        })

        indicies.forEach((i: number) => addEdge(idx, i))
    })

    if (isCyclic()) {
        return true
    } else {
        return false
    }
}

