import { useEffect } from "react"
import axios from 'axios'
import useSession, { LoginState } from "./useSession"

const useAxiosInterceptor = () => {
  const { setLoginState, loginState } = useSession()

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => Promise.resolve(response),
      (error) => {
        switch (error?.response?.status) {
          case 401:
            if (loginState === LoginState.LoggedIn && setLoginState !== null) {
              setLoginState(LoginState.LoggedOut)
            }
            
          default:
            return Promise.reject(error)
        }
      }
    )
    return axios.interceptors.request.eject(interceptor)
  }, [loginState]) // ignore es lint warning
}

export default useAxiosInterceptor