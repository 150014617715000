import { Grid, makeStyles } from "@material-ui/core"
import Button from "components/Button/Button"
import React from "react"
import { ChatState } from "routes/app/chats/chatbuilder/chatFunctions"
import { Node, Edge, getOutgoers } from "react-flow-renderer"
import FormikInput from "components/formik/FormikInput"
import { useTranslation } from "react-i18next"
import { Fa } from "styled/muiComponents"
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Form } from 'react-final-form'
import { delay } from "constants/delay"

export type IChatSimState = {
    chat: ChatState,
    currentNode: Node,
    previousDialog: any[],
    stackPosition: null | number,
    currentDialog: CurrentDialog,
    isWaiting: boolean,
    isTesting: boolean
    clickedPlatform: boolean
    dataCollection_set: string[]
    chatSettings: ChatSettings
}
type ChatSettings = {
    showAvatar: boolean,
    chatColor: string,
    chatBackgroundImage: string,
    imageColor: string,
    botBubbleColor: string,
    customerBubbleColor: string
    typing: string
}

type CurrentDialog = {
    text: string | any,
    delay: number,
    userResponseType: UserResponseType
    buttons?: Buttons[], // TODO later
    inputType?: string,
    type: string
    goto?: string
    field?: string
    dataCollectionType?: string,
    coupon?: string
}

enum UserResponseType {
    NONE = "NONE",
    BUTTONS = "BUTTONS",
    INPUT = "INPUT",
    END = "END"
}

type Buttons = {
    text: any,
    continueID?: string
    url?: string

}

const useStyles = makeStyles((theme) => ({
    input: {
        position: "absolute",
        height: "100%"

    },
    typingArea: {

    },
    divider: {
        marginTop: theme.spacing(2)
    },
    chat: {
        overflowY: "scroll",
        overflowX: "hidden"
    },
    rightAlign: {
        marginLeft: "auto",
        marginRight: 0
    },
    leftAlign: {
        marginLeft: 0,
        marginRight: "auto"
    },
    botChatBubble: {
        marginRight: "auto",
        marginBottom: 10,
        //backgroundColor: "#ffffff",
        padding: "10px 15px",
        boxShadow: "0 1px .5px rgba(11,20,26,.13)",
        borderRadius: "7px"
    },
    userChatBubble: {
        marginLeft: "auto",
        marginRight: 0,
        marginBottom: 10,
        backgroundColor: "#d9fdd3",
        padding: "10px 15px",
        boxShadow: "0 1px .5px rgba(11,20,26,.13)",
        borderRadius: "7px"
    },
    ChatBubbleSpacer: { minWidth: 50 },
    responseButton: {
        border: 0,
        //backgroundColor: "#d9fdd3",
        borderRadius: "20px",
        //marginLeft: theme.spacing(2),
        //marginBottom: theme.spacing(2),
        padding: "10px 15px",
        lineHeight: 1,
        textTransform: "none",
        boxShadow: "0 1px 5px rgb(11 20 26 / 13%)",
        transition: "0.5s background-color ease",
        "&:hover": {
            border: 0,
            backgroundColor: "#c3dfbe",
        }
    },
    responseButtonWrapper: {
        "&:last-child": {
            marginRight: 0
        }
    },
    userChatInput: {
        margin: 0,
        "& input": {
            paddingLeft: "15px",
            margin: 0,
        },
        "& fieldset": {
            border: 0
        }
    },
    chatSendButton: {
        border: 0,
        minWidth: 0,
        padding: theme.spacing(1.25),
        fontSize: "1.25em",
        "&:hover": {
            border: 0
        }
    },
    chatInputWrapper: {
        flexGrow: 1,
        maxHeight: "100px",
        overflow: "hidden",
    },
    socialFavicon: {
        maxWidth: "16px",
        borderRadius: "50%",
        backgroundColor: "#fff",
        marginRight: "5px"
    }

}))
// TODO STYLING
export const RenderCurrentElement = ({ state, dispatch }: { state: IChatSimState, dispatch: any }) => {
    const classes = useStyles()

    const continueDialog = (userInput: string | null, nextID: string | null = null, dataCollection: any | null = null) => {
        dispatch({ type: "CONTINUE_DIALOG", payload: { userInput, nextID, dataCollection } })
    }

    const handleSubmit = (values: any) => {
        if (state.currentDialog.type === "userFeedback") {
            // if not testing save feedback on server
            continueDialog(null)
        }
        
        if(state.currentDialog.dataCollectionType === "set"){
            values = {[state.currentDialog.field as string]: state.dataCollection_set}
        }

        const key = Object.keys(values)[0]
        continueDialog(values[key], null, values)
    }

    const continueDialogSkipNext = (userInput: any, id: string) => {
        const nextNode = state.chat.elements.filter((ele: Node | Edge) => ele.id === id)[0]
        const actualNextNode: Node = getOutgoers(nextNode as Node, state.chat.elements)[0]
        
        if (!actualNextNode) {
            continueDialog(userInput)
            return
        }
        continueDialog(userInput, actualNextNode.id)
    }

    const RenderUserResponseType = () => {
        const classes = useStyles()
        const { t } = useTranslation()
        switch (state.currentDialog.userResponseType) {
            case "NONE": {
                if (state.currentDialog.type === "goTo") {
                    continueDialog(null, state.currentDialog.goto)
                    return null
                } else if (state.currentDialog.type === "testingEnd") {
                    return null
                } else {
                    continueDialog(null)
                    return null
                }

            }
            case "BUTTONS": {
                if (state.currentDialog.type === "splitQuestion") {
                    return (
                        <Grid container justify="flex-end" alignItems="center" spacing={2}>
                            {state.currentDialog.buttons?.map((button: any) =>
                                <ResponseButton onClick={() => continueDialogSkipNext(button.text, button.continueID)}>
                                    {button.text}
                                </ResponseButton>
                            )}
                        </Grid>
                    )
                }

                if (state.currentDialog.type === "socialMedia") {
                    return (
                        <Grid container justify="flex-end" alignItems="center">
                            {/* {state.currentDialog.buttons?.map((button: any) =>
                                <Grid item>
                                    <Button href={button.url} target="_blank" className={classes.responseButton}>
                                        <img src={"https://www.google.com/s2/favicons?domain=" + button.url + "&sz=32"} className={classes.socialFavicon}></img> {button.text} <Fa icon={["far", "arrow-up-right-from-square"]} ml={1} style={{fontSize: "0.7em", marginBottom: "3px"}}/>
                                    </Button>
                                </Grid>
                            )} */}
                            <ResponseButton disabled={!state.clickedPlatform} onClick={() => continueDialog(t("Ich habe eine Bewertung abgegeben"))}>
                                {t("Ich habe eine Bewertung abgegeben")}
                            </ResponseButton>
                        </Grid>
                    )
                }

                if (state.currentDialog.type === "start") {
                    return (
                        <Grid container justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item className={classes.responseButtonWrapper}>
                                <Button onClick={() => continueDialog(state.currentDialog.buttons![0].text)} className={classes.responseButton}>
                                    {state.currentDialog.buttons![0].text}
                                </Button>
                            </Grid>
                            <Grid item className={classes.responseButtonWrapper}>
                                <Button className={classes.responseButton}>
                                    {state.currentDialog.buttons![1].text}
                                </Button>
                            </Grid>
                        </Grid>
                    )
                }

                if (state.currentDialog.type === "feedback") {
                    return (
                        <Grid container justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item className={classes.responseButtonWrapper}>
                                <Button onClick={() => continueDialog(state.currentDialog.buttons![0].text, state.currentDialog.buttons![0].continueID!)} className={classes.responseButton}>
                                    {state.currentDialog.buttons![0].text}
                                </Button>
                            </Grid>
                            <Grid item className={classes.responseButtonWrapper}>
                                <Button onClick={() => continueDialog(state.currentDialog.buttons![1].text, state.currentDialog.buttons![1].continueID!)} className={classes.responseButton}>
                                    {state.currentDialog.buttons![1].text}
                                </Button>
                            </Grid>
                        </Grid>
                    )
                }
                if (state.currentDialog.type === "botResponse") {
                    return(
                        <Grid container justify="flex-end" alignItems="center">
                            <Grid item className={classes.responseButtonWrapper}>
                                <Button onClick={() => continueDialog(state.currentDialog.buttons![0].text)} className={classes.responseButton}>
                                    {state.currentDialog.buttons![0].text}
                                </Button>
                            </Grid>
                        </Grid>
                    )
                }
                if (state.currentDialog.type === "dataCollection") {
                    if (state.currentDialog.dataCollectionType === "enum") {
                        return (
                            <Grid container justify="flex-end" alignItems="center" spacing={2}>
                                {state.currentDialog.buttons?.map((button: any) =>
                                    <ResponseButton onClick={() => continueDialog(button.text, null, {[state.currentDialog.field as string]:button.text})}>
                                        {button.text}
                                    </ResponseButton>
                                )}
                            </Grid>
                        )
                    }
                    if(state.currentDialog.dataCollectionType === "boolean"){
                        return(
                            <Grid container justify="flex-end" alignItems="center" spacing={2}>    
                                <ResponseButton onClick={() => continueDialog(t("Ja"), null, {[state.currentDialog.field as string]:true})}>
                                    {t("Ja")}
                                </ResponseButton>
                            
                                <ResponseButton onClick={() => continueDialog(t("Nein"), null, {[state.currentDialog.field as string]:false})}>
                                    {t("Nein")}
                                </ResponseButton>
                            </Grid>
                        )
                    }
                }
            }
            case "INPUT": {
                const initialValues = {
                }

                return (
                    <Form initialValues={initialValues} onSubmit={handleSubmit}
                        render={({ handleSubmit, submitting }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container justify="center" alignItems="center" direction="row">
                                    <Grid item className={classes.chatInputWrapper}>
                                        <PerfectScrollbar>
                                            <Grid container >
                                                <FormikInput
                                                    disabled={state.currentDialog?.dataCollectionType === "set"}
                                                    type="textarea"
                                                    variant="outlined"
                                                    name={state.currentDialog.field ?? "field"}
                                                    autoFocus={true}
                                                    className={classes.userChatInput}
                                                    placeholder="Text eingeben..." // TODO translate
                                                    handleSubmit={handleSubmit}
                                                    conditionalValue={state.dataCollection_set.join(", ")}
                                                />
                                            </Grid>
                                        </PerfectScrollbar>
                                    </Grid>
                                    <Grid item>
                                        <Button disableRipple type="submit" className={classes.chatSendButton}>
                                            <Fa icon={["fas", "paper-plane-top"]} size="lg" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    />
                )
            }
            case "END": {
                return (<p>{state.currentDialog.text}</p>)
            }
            default: {
                return <p>something went horribly wrong</p>
            }
        }
    }

    return (
        <Grid container direction="column" justify="space-between">
            <Grid item style={{ marginTop: "auto" }}>
                <TypingArea className={classes.typingArea}>
                    <RenderUserResponseType />
                </TypingArea >
            </Grid>
        </Grid>
    )
}

export const TypingArea: React.FC<{ className: any }> = ({ children, className }) => {
    return (
        <Grid item className={className}>
            {children}
        </Grid>
    )
}

export const CurrentToPrevious = ({ state, userInput }: { state: any, userInput: string | null }) => {
    const classes = useStyles()

    console.log(userInput)

    if (state.currentNode.type === "splitAnswer" || state.currentNode.type === "goTo") {
        return null
    }

    if (Array.isArray(userInput) && userInput[0] === "string") {
        userInput = userInput.join(", ")
    }

    switch (state.currentNode.type) {
        // TODO COMPONENT FOR EACH RETURN, SO CURRENTOPREVIOUS AND RENDERCURRENTDIALOG HAVE NO DUPLICATE CODE
        case "botResponse": {
            if (state.currentDialog.coupon) {
                return (
                    <>
                        <Grid item container style={{ flexWrap: "nowrap" }}>
                            <Grid item>
                                <div className={classes.botChatBubble} style={{ backgroundColor: state.chatSettings.botBubbleColor }}>{state.currentDialog.text}</div>
                            </Grid>
                            <Grid item>
                                <div className={classes.ChatBubbleSpacer}></div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <div className={classes.botChatBubble} style={{ backgroundColor: state.chatSettings.botBubbleColor }}>{state.currentDialog.coupon}</div>
                            </Grid>
                            <Grid item>
                                <div className={classes.ChatBubbleSpacer}></div>
                            </Grid>
                        </Grid>
                    </>
                )
            } else {
                return (
                    <Grid container direction="column">
                        <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{ flexWrap: "nowrap" }}>
                            <Grid item className={classes.botChatBubble} style={{ backgroundColor: state.chatSettings.botBubbleColor }}>
                                {state.currentDialog.text}
                            </Grid>
                            <Grid item className={classes.ChatBubbleSpacer}>
                            </Grid>
                        </Grid>
                        {userInput &&
                            <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{ flexWrap: "nowrap" }}>
                                <Grid item className={classes.ChatBubbleSpacer}>
                                </Grid>
                                <Grid item className={classes.userChatBubble} style={{ backgroundColor: state.chatSettings.customerBubbleColor }}>
                                    {userInput}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                )
            }
        }

        case "socialMedia": {

            const Buttons = () => {
                return (
                    <Grid container item spacing={2} style={{marginBottom: "10px"}}>
                        {state.currentNode.data.socialMedias.map((socialMedia: any) => {
                            return (
                                <ResponseButton target="_blank" href={socialMedia.url}>
                                    <img src={"https://www.google.com/s2/favicons?domain=" + socialMedia.url + "&sz=32"} className={classes.socialFavicon} alt="social media icon"></img> {socialMedia.platform} <Fa icon={["far", "arrow-up-right-from-square"]} ml={1} style={{ fontSize: "0.7em" }} />
                                </ResponseButton>     
                            )
                        })}
                    </Grid>
                )
            }

            return (
                <>
                    <Grid item container style={{ flexWrap: "nowrap" }}>
                        <Grid item>
                            <div className={classes.botChatBubble} style={{backgroundColor: state.chatSettings.botBubbleColor}}>{state.currentDialog.text[0]}</div>
                        </Grid>
                        <Grid item>
                            <div className={classes.ChatBubbleSpacer}></div>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{ flexWrap: "nowrap" }}>
                        <Grid item>
                            <div className={classes.ChatBubbleSpacer}></div>
                        </Grid>
                        <Grid item>
                            <Buttons/>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <div className={classes.botChatBubble} style={{backgroundColor: state.chatSettings.botBubbleColor}}>{state.currentDialog.text[1]}</div>
                        </Grid>
                        <Grid item>
                            <div className={classes.ChatBubbleSpacer}></div>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{ flexWrap: "nowrap" }}>
                        <Grid item className={classes.ChatBubbleSpacer}>
                        </Grid>
                        <Grid item className={classes.userChatBubble} style={{background: state.chatSettings.customerBubbleColor}}>
                            {userInput}
                        </Grid>
                    </Grid>
                </>
            )
        }
        default: {
            return (
                <Grid container direction="column">
                    <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{ flexWrap: "nowrap" }}>
                        <Grid item className={classes.botChatBubble} style={{backgroundColor: state.chatSettings.botBubbleColor}}>
                            {state.currentDialog.text}
                        </Grid>
                        <Grid item className={classes.ChatBubbleSpacer}>
                        </Grid>
                    </Grid>
                    {userInput &&
                        <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{ flexWrap: "nowrap" }}>
                            <Grid item className={classes.ChatBubbleSpacer}>
                            </Grid>
                            <Grid item className={classes.userChatBubble} style={{backgroundColor: state.chatSettings.customerBubbleColor}}>
                                {userInput}
                            </Grid>
                        </Grid>
                    }
                </Grid>
            )
        }
    }
}

export const replaceEntities = (userData: any, value: any) => {
    console.log(userData, value)
    if (typeof value === "string") {
        return value
    }

    const userString = (key:string) => {
        if (Array.isArray(userData[key])){
            return userData[key].join(", ")
        } else {
            return userData[key]
        }
    }

    // might slow down significantly with a lot of userData fields

    let replaced = value.blocks.map((block: any) => {
        let temp = block.text
        userData.default.forEach((def: any) => {
            const key = Object.keys(def)[0]
            const reg = new RegExp("\\${" + key + "}", "g")
            temp = temp.replace(reg, userString(key))
        })
        return (
            <>
                <span>{temp}</span>
                <br />
            </>
        )
    })
    return replaced
}

export const ResponseButton = ({ children, ...props }: any) => {
    const classes = useStyles()
    return (
        <Grid item className={classes.responseButtonWrapper}>
            <Button className={classes.responseButton} {...props}>
                {children}
            </Button>
        </Grid>
    )
}

export const RenderCurrentDialog = ({ state, dispatch }: { state: IChatSimState, dispatch: any }) => {
    const classes = useStyles()
    
    switch (state.currentDialog.type) {
        case "socialMedia": {

            const Buttons = () => {
                delay(180 * 1000).then(() => dispatch({ type: "CLICKED_PLATFORM" }))

                const handleClick = () => {
                    dispatch({ type: "CLICKED_PLATFORM" })
                }

                return (
                    <Grid container item spacing={2} style={{marginBottom: "10px"}}>
                        {state.currentNode.data.socialMedias.map((socialMedia: any) => {
                            return (
                                <Grid item className={classes.responseButtonWrapper}>
                                    <Button href={socialMedia.url} target="_blank" className={classes.responseButton} onClick={handleClick}>
                                        <img src={"https://www.google.com/s2/favicons?domain=" + socialMedia.url + "&sz=32"} className={classes.socialFavicon} alt="social media icon"></img> {socialMedia.platform} <Fa icon={["far", "arrow-up-right-from-square"]} ml={1} style={{ fontSize: "0.7em" }} />
                                    </Button>
                                </Grid>
                            )
                        })}
                    </Grid>
                )
            }

            return (
                <>
                    <Grid item container style={{ flexWrap: "nowrap" }}>
                        <Grid item>
                            <div className={classes.botChatBubble} style={{backgroundColor: state.chatSettings.botBubbleColor}}>{state.currentDialog.text[0]}</div>
                        </Grid>
                        <Grid item>
                            <div className={classes.ChatBubbleSpacer}></div>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{ flexWrap: "nowrap" }}>
                        <Grid item>
                            <div className={classes.ChatBubbleSpacer}></div>
                        </Grid>
                        <Grid item>
                            <Buttons />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item>
                            <div className={classes.botChatBubble} style={{backgroundColor: state.chatSettings.botBubbleColor}}>{state.currentDialog.text[1]}</div>
                        </Grid>
                        <Grid item>
                            <div className={classes.ChatBubbleSpacer}></div>
                        </Grid>
                    </Grid>
                </>
            )
        }
        case "dataCollection":{
            if (state.currentDialog.dataCollectionType === "set") {

                const Buttons2: any = () => {
                    
                    const handleClick = (value:string) => {
                        dispatch({type: "DATACOLLECTION_SET", payload: {text:value}})
                    }
                    
                    return (
                        //@ts-ignore 
                        state.currentDialog.buttons.map((button: any) => {
                            return (
                                <ResponseButton onClick={() => {handleClick(button.text)}} style={{color: state.dataCollection_set.includes(button.text) ? "black" : "grey"}}>
                                    {button.text}
                                </ResponseButton>
                            )
                        })
                    )
                }

                return (
                    <>
                    <Grid item container style={{ flexWrap: "nowrap" }}>
                        <Grid item>
                            <div className={classes.botChatBubble} style={{backgroundColor: state.chatSettings.botBubbleColor}}>{state.currentDialog.text}</div>
                        </Grid>
                        <Grid item>
                            <div className={classes.ChatBubbleSpacer}></div>
                        </Grid>
                    </Grid>
                    <Grid item container style={{ flexWrap: "nowrap" }}>
                        <Grid item>
                           <Buttons2/>
                        </Grid>
                        <Grid item>
                            <div className={classes.ChatBubbleSpacer}></div>
                        </Grid>
                    </Grid>
                    </>
                )
            }
        }
        default: {
            return (
                <Grid item container style={{ flexWrap: "nowrap" }}>
                    <Grid item>
                        <div className={classes.botChatBubble} style={{backgroundColor: state.chatSettings.botBubbleColor}}>{state.currentDialog.text}</div>
                    </Grid>
                    <Grid item>
                        <div className={classes.ChatBubbleSpacer}></div>
                    </Grid>
                </Grid>
            )
        }
    }
}

export const DataToPost = (userData: any) => {
    const params = new URLSearchParams();
    Object.keys(userData).forEach((key: string) => params.append(key, userData[key]))
    return params
}