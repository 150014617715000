import { setLocale } from 'yup';
import i18next from 'i18next';

setLocale({
  // use constant translation keys for messages without values
  mixed: {
    default: i18next.t('/errors:Fehlerhafte Eingabe'),
    required: ( { path } ) => {
        const field = i18next.t(`/fields:${path}`)
        return i18next.t('/errors:is_required', {field})
    },
   
  }, 
  string: {
      url: ({ path }) => {
        const field = i18next.t(`/fields:${path}`)
        return i18next.t('/errors:is_invalid_url', {field})
      }
    }
  // use functions to generate an error object that includes the value from the schema
});