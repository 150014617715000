import React from "react"
import { useTranslation } from "react-i18next"
import { DialogProps } from "hooks/useDialog"
import { makeStyles, Theme, Dialog, Grid } from "@material-ui/core"
import DialogTitle from "dialogs/DialogTitle"
import DialogContent from "dialogs/DialogContent"
import DialogActions from "dialogs/DialogActions"
import Button from "components/Button/Button"
import { Fa, Typography } from 'styled/muiComponents'

const useStyles = makeStyles<Theme>(theme => ({
    title: {
        backgroundColor: theme.palette.primary.light,
        color: "white"
    },
    divider: {
        backgroundColor: theme.palette.companyRed.main,
        height: "2px"
    },
    card: {
        marginBottom: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        cursor: "pointer"
    },
    errorColumn: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    warningIcon: {
        color: "orange",
        marginRight: theme.spacing(2)
    }
}))

const LeaveWarningDialog = ({open, handleCancel, handleConfirm}: any) => {
    const classes = useStyles()
    const {t} = useTranslation()

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle onClose={handleCancel}>
                <Fa icon={["fal", "exclamation-triangle"]} className={classes.warningIcon} mr={1.75} />{t("Achtung")}
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column">
                    <Typography variant="h2" mb={2}>{t("Editor verlassen")}</Typography>
                    {t("Nicht gespeicherte Änderungen gehen beim Verlassen verloren.")}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify="space-between">
                    <Grid item>
                        <Button onClick={handleCancel} variant="contained" color="error" size="small"><Fa icon={["fas", "times"]} mr={1} /> {t("Abbrechen")}</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handleConfirm} variant="contained" color="success" size="small"><Fa icon={["fas", "floppy-disk-circle-xmark"]} mr={1} /> {t("Verlassen")}</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default LeaveWarningDialog