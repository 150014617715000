import React, {useEffect, useState} from "react"
import { SetupNavigationProps, Navigation, ISetupInformation } from "../SetupWizard"
import { Checkbox } from "@material-ui/core"
import { Form } from 'react-final-form'
import FormikInput from "components/formik/FormikInput"
import { Typography, Grid } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { useTranslation } from "react-i18next"
import ClearIcon from '@material-ui/icons/Clear';
import FormControlLabel from '@material-ui/core/FormControlLabel';

type Props = {
    navigationProps: SetupNavigationProps
    classes: any
    handleChange: (event:any) => void
    setupInformation: ISetupInformation
    handleChangeNoInfo: (name: string) => void
    noInfo: boolean
}

const VatAndUstid = ({ navigationProps, classes, noInfo, handleChange, setupInformation, handleChangeNoInfo }: Props) => {
    const { t } = useTranslation()
    const [enableNext, setEnableNext] = useState<boolean>(false)

    const handleSubmit = () => {
    }

    useEffect(() => {
        if(setupInformation.ust_identifier && setupInformation.vat_identifier) {
            setEnableNext(true)
        } else if (!noInfo && setupInformation.vat_identifier) {
            setEnableNext(true)
        } else if (setupInformation && noInfo){
            setEnableNext(true)
        } else {
            setEnableNext(false)
        }
    },[setupInformation, noInfo])


    const initialValues = {
        ust_identifier: setupInformation.ust_identifier,
        vat_identifier: setupInformation.vat_identifier
    }
    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}
            render={({  handleSubmit, submitting }) => (
                <Grid container item direction="column" className={classes.root}>
                    <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" justify="flex-start">
                                <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                    <Grid item sm={4}>
                                        <div style={{ maxWidth: "150px" }}>
                                            <Pixi size="full" pose="waiting"></Pixi>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <div className={classes.headerDescription} >
                                        <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                            <Typography variant="h5" className={classes.pixiSpeech}>
                                                {t("Wie lautet deine Ust-ID und Steuernummer?")}
                                            </Typography>
                                        </Typist>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={3} mt={1} className={classes.formContent}>
                                    <Grid item xs={12}>
                                        <FormikInput
                                            type="string"
                                            value={setupInformation.ust_identifier}
                                            name="ust_identifier"
                                            label={t("Umsatzsteuer-Identifikationsnummer ")}
                                            variant="outlined"
                                            onChange={handleChange}
                                            disabled={!noInfo}
                                            required={noInfo}
                                            className={!noInfo ? " " : classes.disabledInput}
                                        />
                                        <FormControlLabel
                                            value="end"
                                            control={
                                                <Checkbox
                                                    onChange={() => handleChangeNoInfo("taxes")}
                                                    value={!noInfo}
                                                    checkedIcon={<ClearIcon/>}
                                                    checked={!noInfo}
                                                />
                                            }
                                            label={t("Ich habe keine Ust-ID")}
                                            labelPlacement="end"
                                        />

                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormikInput
                                            type="string"
                                            value={setupInformation.vat_identifier}
                                            name="vat_identifier"
                                            label={t("Steuernummer")}
                                            variant="outlined"
                                            onChange={handleChange}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item sm={12}>
                                    <Navigation  {...navigationProps} enableNext={!enableNext} skipEnabled={true}/>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item className={classes.formNote}>
                            <Typography variant="body1" className={classes.requiredNotice}>
                                <i>* {t("Pflichtfeld")}</i>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    )
}

export default VatAndUstid