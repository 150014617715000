import React from "react"
import { useTranslation } from "react-i18next"
import { DialogProps } from "hooks/useDialog"
import { makeStyles, Theme, Dialog, Grid } from "@material-ui/core"
import DialogTitle from "dialogs/DialogTitle"
import DialogContent from "dialogs/DialogContent"
import DialogActions from "dialogs/DialogActions"
import Button from "components/Button/Button"
import { Fa, Typography } from "styled/muiComponents"



const useStyles = makeStyles<Theme, {}>(theme => ({
    card: {
        marginBottom: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        cursor: "pointer"
    },
    errorColumn: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    warningIcon: {
        color: theme.palette.companyRed.main
    }
}))

const CancelWizardDialog: React.FC<DialogProps>  = ({ onClose, onError, open }) => {

    const { t } = useTranslation()
    const classes = useStyles({})

    const handleClose = () => {
        onClose({})
    }

    const handleLeave = () => {
        onClose({leave:true})
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>
                <Fa icon={["fal", "exclamation-triangle"]} className={classes.warningIcon} mr={1.75} />{t("Achtung")}
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column">
                    <Typography variant="h2" mb={2}>{t("Möchtest du den Editor wirklich beenden?")}</Typography>        
                    <Typography>{t("Alle Änderungen gehen beim Verlassen des Editors verloren. Möchtest du den Editor trotzdem beenden?")}</Typography>
                </Grid>
            </DialogContent>
            
            <DialogActions>
                <Grid container justify="space-between">
                    <Button onClick={handleClose} variant="contained" color="error" size="small"><Fa icon={["fas", "times"]} mr={1}  size={"lg"} /> {t("Nein, nicht beenden")}</Button>
                    <Button onClick={handleLeave} variant="contained" color="success" size="small"><Fa icon={["fas", "check"]} mr={1} /> {t("Ja, beenden")}</Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default CancelWizardDialog