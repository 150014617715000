// TODO generalize

export const webformsWithChat = (id:string) => {
    return {"op": "AND", "exps":[{"filter":{"selectedFilterFunction":{"name":"field_eq", "params":["chat_id", id]}}}]}
}

export const cornermailSenderAddresses = () => {
    return {"op": "AND", "exps":[{"filter":{"selectedFilterFunction":{"name":"field_contains", "params":["domain", "cornermail"]}}}]}
}

export const logo = () => {
    return {"op": "AND", "exps":[{"filter":{"selectedFilterFunction":{"name":"field_eq", "params":["logo",true]}}}]}
}

export const onlyQRTrackings = () => {
    return {"op": "AND", "exps":[{"filter":{"selectedFilterFunction":{"name":"field_eq", "params":["qr_scan", true]}}}]}
}

export const uniqueName = (name: string) => {
    return {"op": "AND", "exps":[{"filter":{"selectedFilterFunction":{"name":"field_eq", "params":["name", name]}}}]}
}

export const fromCornerstone = () => {
    return {"op": "AND", "exps":[{"filter":{"selectedFilterFunction":{"name":"field_eq", "params":["from_cornerstone", true]}}}]}
}