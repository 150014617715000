import React from 'react'
import MuiCheckbox from "@material-ui/core/Checkbox";
import {FormControlLabel} from "@material-ui/core"

const Checkbox = ({ name, ...props }) => {
    const {input:{...field},label} = props

    return (
        <FormControlLabel
            control={<MuiCheckbox {...props} {...field} color={props.color || "primary"} checked={field.value} onChange={e => field.onChange({ target: { name, value: e.target.checked } })} />}
            label={label}
        />

    )
}

export default Checkbox