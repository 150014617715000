import { useQueryParamsState } from 'hooks/useQueryParams'
import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import FormEditor from 'components/formEditor/Editor'
import { ITemplate } from 'models/template'
import webformApi, { TemplateKey, IWebform, IPlaceholder } from 'models/webform'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button/Button'
import { makeStyles } from '@material-ui/core'
import templateApi from 'models/template'
import { useAlert } from 'hooks/useAlert'
import { Device } from 'types/common'
import { IChats, ITemplateMailSettings } from './wizard/WebformWizard'
import { Fa } from "styled/muiComponents"
import imprints from "models/imprint"
import chats from 'models/chats'
import { Address } from 'hooks/useSession'
import CancelWizardDialog from 'dialogs/CancelWizardDialog'
import { useDialog } from 'hooks/useDialog'

const templateKeyHeaders: Record<TemplateKey, { headerText: string, description: string }> = {
    [TemplateKey.formLP]: {
        headerText: "Anmeldeformular",
        description: "Hier kannst du deine Anmeldeseite ändern und anpassen." //ToDo translate
    },
    [TemplateKey.soiLP]: {
        headerText: "Landingpage",
        description: "Hier kannst du die Bestätigungsseite ändern und anpassen, die angezeigt wird sobald jemand das Anmeldeformular abgeschickt hat." //ToDo translate
    },
    [TemplateKey.doiMail]: {
        headerText: "Dopt-In-Mail",
        description: "Hier kannst du die E-Mail ändern und anpassen, die deinem Kontakt zur Anmeldebestätigung zugeschickt wird." //ToDo translate
    },
    [TemplateKey.doiLP]: {
        headerText: "Dop-In Landingpage",
        description: "Hier kannst du die Bestätigungsseite ändern und anpassen, die angezeigt wird sobald jemand seine Anmeldung bestätigt hat." //ToDo translate
    },
    [TemplateKey.doneMail]: {
        headerText: "Willkommensmail",
        description: "Hier kannst du die E-Mail ändern und anpassen, die deinem Kontakt als Willkommens-E-Mail nach einer erfolgreichen Anmeldung zugeschickt wird." //ToDo translate
    }
}

/**
 * TODO
 * Speichern button über headline
 *  bearbeiten dropdown => template zu editor, 
 */

type IRouteParams = {
    webformId: string,
    templateKey: string
}

type IQueryParams = {
    device: Device
}

const TemplateEdit = () => {
    const params = useParams<IRouteParams>()
    const webformId = parseInt(params.webformId)
    const { alert } = useAlert()
    const { t } = useTranslation()
    const templateKey = params.templateKey as unknown as TemplateKey
    const [queryParams, setQueryParams] = useQueryParamsState<IQueryParams, IQueryParams>((a: IQueryParams) => a, (a: IQueryParams) => a, { device: Device.Smartphone })

    const [template, setTemplate] = useState<ITemplate | null>(null)
    const [webform, setWebform] = useState<IWebform | null>(null)
    const webformRef = useRef<IWebform | null>(null)
    const templateRef = useRef<ITemplate | null>(null)
    const [templatePrebuild, setTemplatePrebuild] = useState<string>("")
    const [chatList, setChatList] = useState<IChats | null>(null)
    const [imprint, setImprint] = useState<Address | null>(null)
    const [templateMailSettings, setTemplateMailSettings] = useState<ITemplateMailSettings>({
        subject:
        {
            default: "",
            key: "subject",
            type: "string",
            label: "Betreff"
            
        }
    })
    const history = useHistory()

    const handleDeviceChange = (device: Device) => {
        setQueryParams({ device })
    }

    useEffect(() => {
        (async () => {
            const w = await webformApi.get(webformId)
            const t = (await webformApi.getTemplates(w, [templateKey])).filter(a => a !== null)[0]
            webformRef.current = w
            templateRef.current = t
            setWebform(w)
            setTemplate(t)

            if (t) setTemplateMailSettings({
                subject: {
                    default: t.subject,
                    key: "subject",
                    type: "string",
                    value: t.subject,
                    label: "Betreff"
                }
            })

            if (w?.config?.key) {
                const { templates } = await webformApi.getPrebuild(w.config.key)
                setTemplatePrebuild(templates[templateKey])
            }
        })()
    }, [webformId, templateKey])

    useEffect(() => {
        const filterObject = {"op": "AND", "exps":[{"filter":{"selectedFilterFunction":{"name":"field_eq", "params":["valid", true]}}}]}
        const fetchChats = async() => {
            //@ts-ignore why filterobject error when it works?
            const result = await chats.getList(1,10,undefined, null, filterObject)
            setChatList({chats: result.data, total: result.meta.item_count})
        }

        const fetchImprint= async () => {
            const result = await imprints.getFirst()
            setImprint(result.data)
        }

        fetchChats(); fetchImprint()
    },[])

    if (!template || !webform || !webform.config || !templatePrebuild) {
        return null
    }

    const handleSave = () => {
        if (webformRef.current && templateRef.current) {
            Promise.all([
                templateApi.update(templateRef.current.id, { ...templateRef.current, subject: templateMailSettings.subject.value }),
                webformApi.update(webformRef.current.id, webformRef.current)
            ]).then(() => {
                alert.success(t("Speichern erfolgreich"))
                history.push("/app/stock/webforms")
            })
         }
    }

    const handleChange = (placeholders: Record<string, IPlaceholder>, html: string) => {

        if(webformRef.current && webformRef.current.config){
            webformRef.current = {
                ...webformRef.current,
                 config:{
                     ...webformRef.current.config,
                     placeholders
                 }}
        }

        if (templateRef.current) {
            templateRef.current = {
                ...templateRef.current,
                body_html: html
            }
        }
        // if (!webform.config) return

        // setWebform({
        //     ...webform,
        //     config: {
        //         ...webform.config,
        //         placeholders
        //     }
        // })

        // setTemplate({
        //     ...template,
        //     body_html: html
        //  })
    }

    const handleChangeMailSettings = (event: any) => {
        setTemplateMailSettings({subject: {...templateMailSettings.subject, value: event.target.value}})
    }

    const handleLoadMoreChats = () => {
        // TODO 
    }

    const handleSetChat = (id: number | null) => {
        if (webformRef.current && webformRef.current.config) {
            webformRef.current = {
                ...webformRef.current,
                chat_id: id
            }
        }
        setWebform({ ...webform, chat_id: id })
    }

    const handleOpenChat = () => {
        if(webform.chat_id === null){
            setWebform({...webform, chat_id: 0})
        } else {
            setWebform({...webform, chat_id: null})
        }
    }
     
    return (
        <FormEditor
            onChange={handleChange}
            template={templatePrebuild}
            placeholders={webform.config.placeholders}
            inputGroups={webform.config.groups}
            customConfigHeader={{ header: templateKeyHeaders[templateKey].headerText, description: templateKeyHeaders[templateKey].description }}
            preConfigComponent={<Header onSave={handleSave} />}
            previewIdentifier={templateKey}
            basicInfo={null}
            device={queryParams.device}
            onDeviceChange={handleDeviceChange}
            templateMailSettings={templateMailSettings}
            handleChangeMailSettings={handleChangeMailSettings}
            chats={chatList}
            handleLoadMoreChats={handleLoadMoreChats}
            handleSetChat={handleSetChat}
            handleOpenChat={handleOpenChat}
            wizardState={{chat:webform.chat_id}}
            imprint={imprint}
        />
    )
}

const useStyles = makeStyles(theme => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(2)
    },
    headerText: {
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}))

type HeaderProps = {
    onSave: () => void
}

const Header = ({ onSave }: HeaderProps) => {
    const { t } = useTranslation()
    const history = useHistory()
    const classes = useStyles()
    const { dialog } = useDialog()

    const handleCancel = () => {
        dialog.show({
            component: CancelWizardDialog,
            props: {}
        }).then((response) => {
            if (response.leave) {
                history.push(`/app/stock/webforms`)
            }
        })
    }

    return (
        <div className={classes.header}>
            <Button onClick={handleCancel} variant="contained" disableElevation color="error" size="small" style={{marginRight: "16px"}}>
                <Fa icon={["fas", "times"]} mr={1}/>
                {t("Abbrechen")}
            </Button>
            <Button variant="contained" color="success" onClick={onSave} size="small">
                <Fa icon={["fas", "check"]} mr={1}/>
                {t("Speichern und schließen")}
            </Button>
        </div>
    )
}

export default TemplateEdit