import React from "react"
import { useChatState } from "../../ChatContext"
import Button from "components/Button/Button"
import { Grid } from "@material-ui/core"
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from "react-i18next"
import { Fa } from "styled/muiComponents"

const GoToNodeOptions = ({node, classes}: any) => {
    const { state, dispatch } = useChatState()
    const { t } = useTranslation()
    const handleSelectContinueNode = () => {
        dispatch({ type: "ENABLE_GOTO_SELECTION" })
    }

    return (
        <Grid container item className={classes.settingsContainer} style={{borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px"}} direction="column" justify="center">
                { state.selected.data.continueWith?.data?.name ?  
                    <Alert icon={<Fa icon={["far", "link"]} />} severity="success" variant="outlined" style={{marginBottom: "16px", backgroundColor: "#ffffff", margin: 0}}>
                        <AlertTitle>{t("Block verbunden")}</AlertTitle>
                        {t("Dieser Block ist verbunden mit")}:<br />
                        <strong style={{display: "block"}}><i>"{state.selected.data.continueWith?.data?.name}"</i></strong>
                        <Button disableElevation color="success" variant="contained" size="large" style={{marginTop: "16px"}} onClick={handleSelectContinueNode}>
                            <Fa icon={["far", "link"]} mr={1.5} />
                            {t("Block neu verbinden")}
                        </Button>
                    </Alert>
                :
                    <Alert icon={<Fa icon={["far", "link-slash"]} />} severity="info" variant="outlined" style={{marginBottom: "16px", backgroundColor: "#ffffff", margin: 0}}>
                        <AlertTitle>{t("Block noch nicht verbunden")}</AlertTitle>
                        {t('Dieser Block ermöglicht es dir zu einem anderen, beliebigen Block zu springen. Klicke auf "Block verbinden" und wähle das gewünschte Ziel aus.')}
                        <Button disableElevation color="success" variant="contained" size="large" style={{marginTop: "16px"}} onClick={handleSelectContinueNode}>
                            <Fa icon={["far", "link"]} mr={1.5} />
                            {t("Block verbinden")}
                        </Button>
                    </Alert>
                }
        </Grid>
    )
}

export default GoToNodeOptions