import React, { useState } from 'react';

import { Fade, ExpansionPanel, makeStyles, Avatar as MuiAvatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useSession from '../../../hooks/useSession';
import { Grid, Typography, Fa } from '../../../styled/muiComponents'
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
    root: {
        position: "relative",
        //background:' url("/assets/profile_bg_transparent.png"), linear-gradient(50deg,#0c5da0,#259bff,#e300ff)',
        background: 'url(/assets/images/profile_bg_transparent.png), linear-gradient(50deg,#011640,#ff070d,#e300ff)',
        padding: "20px 24px",
        backgroundSize: "100% 100%",
        '&:before': {
            background: "url(/assets/images/profile_bg_transparent.png)",
            backgroundSize: "100% 100%",
            content: "''",
            display: "block",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            zIndex: "2",
        }
    },
    label: {
    },
    expandable: {
        backgroundColor: "rgba(0,0,0,0)",
        color: "white",
        position: "absolute",
        left:0,
        bottom:0,
        right: 0,
        zIndex: "3",
        "&:hover": {
           backgroundColor: "rgba(0,0,0,0.1)",
           cursor: "pointer"
        }
    },
    userInfoList: {
        zIndex: "3",
        margin: "0 !important",
    },
    userAvatar: {
        marginLeft: "-5px",
        border: "2px solid rgba(255, 255, 255, 0.5)",
        width: "35px",
        height: "35px",
        backgroundColor: "transparent"
    },
    userName: {
       maxWidth: "163px"
    },
    userList: {
        maxWidth: "210px",
        "& p": {
            fontSize: "0.9em",
            fontWeight: "300"
        }
     },
    navDivider: {
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        margin: "16px 0",
    }
})

const UserQuickInfo = ({isSidebarFixedOpen}) => {
    const { user } = useSession()
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState(false)
    const classes = useStyles()
    
    const handleToggleExpand = () => {
        setExpanded(!expanded)
    }

    return (
        <Grid className={classes.root} container direction="column" >
            <ExpansionPanel expanded={expanded && (isSidebarFixedOpen)} elevation={0} style={{backgroundColor:"transparent", color:"white"}} square className={classes.userInfoList}>
                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Avatar user={user} />
                    </Grid>
                    <Grid item className={classes.userName}>
                        <Fade in={isSidebarFixedOpen} timeout={250}>
                            <Typography className={classes.label} noWrap={true} ml={2}> { user.first_name } { user.last_name } </Typography>
                        </Fade>
                    </Grid>
                </Grid>
                <Divider variant="fullWidth" className={classes.navDivider}/>
                <Grid container direction="row" wrap="nowrap" className={classes.userList}> <Typography className={classes.label} noWrap={true} mt="5px"><Fa icon={["fas", "envelope"]} fixedWidth mr={1}/> {user.email } </Typography> </Grid>
                <Grid container direction="row" wrap="nowrap" className={classes.userList}> <Typography className={classes.label} noWrap={true} mt="5px"><Fa icon={["fas", "id-card"]} fixedWidth mr={1}/> { t(`/common:${user.user_role}`) } </Typography> </Grid>
            </ExpansionPanel>
            <Grid className={classes.expandable} container justify="center" onClick={handleToggleExpand}>
                <Fade in={isSidebarFixedOpen} timeout={250}>
                    { expanded ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                </Fade>
            </Grid>
        </Grid>
    )
}

const Avatar = ({ user }) => {
    const classes = useStyles()

    return (
        <MuiAvatar 
            alt={`${user.first_name} ${user.last_name}`} 
            src={"/api/avatar"} 
            className={classes.userAvatar} 
        >{user.first_name[0].toUpperCase()}{user.last_name[0].toUpperCase()}</MuiAvatar>
    )
}

export default UserQuickInfo