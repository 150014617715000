import { withCrud, CrudModel, BaseModel } from "./builder"

export type IFeedback = {
    id: number
    name: string
    email: string
    feedback: string
}

export type FeedbackApi = CrudModel<IFeedback> & BaseModel

export default {
    name: "feedbacks",
    ...withCrud<IFeedback>("feedbacks")
}

