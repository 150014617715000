import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Grid, makeStyles, Box, Link } from "@material-ui/core"
import FormikInput from "../../components/formik/FormikInput"
import Button from "components/Button/Button"
import { useParams } from "react-router-dom"
import users from "models/user"
import { Typography, Fa } from "styled/muiComponents"
import { Link as RouterLink } from "react-router-dom"
import useSession from "hooks/useSession"
import { IClasses } from "./Auth"
import { Form, FormSpy } from 'react-final-form'
// TODO look for a type like formikerrors

type IValues = {
    password: string
    passwordConfirm: string
}

const initialValues: IValues = {
    password: "",
    passwordConfirm: ""
}
export type Validator = {
    key: string
    label: string,
    isValid: (args: IValues) => boolean
}

export type Password = {
    password: string
}

export enum IType {
    "Activate" = "Activate",
    "Reset" = "Reset_password"
}

export type ITokenParams = {
    token: string
}

type Props = {
    classes: IClasses
    type: IType
}

const useStyles = makeStyles(theme => ({
    sendNewEmailButton: {
        paddingTop: `${theme.spacing(4)}px`
    }
}))

const ActivateReset = ({ classes:classesOverride, type: activateOrReset }:Props) => {
    const { t } = useTranslation()
    const { token } = useParams<ITokenParams>()
    const [isTokenValid, setIsTokenValid] = useState<boolean>()
    const { restore } = useSession()
    const [isPasswordReset, setIsPasswordReset] = useState(false)
    const classes = {
        ...classesOverride,
        ...useStyles()
    }

    useEffect(() => {
        if (activateOrReset === "Reset_password") {
            users.verifyTokenReset(token)
                .then(() => { setIsTokenValid(true) })
                .catch(() => { setIsTokenValid(false) })
        }
        else {
            users.verifyTokenActivate(token)
                .then(() => { setIsTokenValid(true) })
                .catch(() => { setIsTokenValid(false) })
        }
    }, [activateOrReset, token])

        const validators: Validator[] = [
        {
            key: "min_8_letters",
            label: t("Mindestens 8 Zeichen"),
            isValid: ({ password }) => password ? password.length >= 8 : false
        },
        {
            key: "special_character",
            label: t("Sonderzeichen"),
            isValid: ({ password }) => password ? password.match(/[^a-zA-Z0-9]/) !== null: false
        },
        {
            key: "numbers",
            label: t("Zahlen"),
            isValid: ({ password }) => password ? password.match(/[0-9]/) !== null : false
        },
        {
            key: "lowercaseletters",
            label: t("Kleinbuchstaben"),
            isValid: ({ password }) => password ? password.match(/[a-z]/) !== null : false
        },
        {
            key: "uppercaseletters",
            label: t("Großbuchstaben"),
            isValid: ({ password }) => password ? password.match(/[A-Z]/) !== null : false
        },
        {
            key: "passwords_are_identical",
            label: t("Passwörter stimmen Überein"),
            isValid: ({ password, passwordConfirm }) => password && passwordConfirm ? password === passwordConfirm && password.length > 0 : false 
        }
    ]

    const handleSubmit = async (values: IValues) => {
        if (activateOrReset === "Activate") {
            await users.activateUser(values.password, token)
                .then(() => { restore() })
                .catch((e) => { console.log(e) })
        }
        else {
            await users.resetPassword(values.password, token)
                .then(() => { setIsPasswordReset(true) })
                .catch((e) => console.log(e))
        }
    }

    const handleValidate = (values: IValues) => {
        // const errors: FormikErrors<IValues> = {}
        const errors: any = {}
        let errorCount = 0
        const validationResult = validators.reduce((res, validator) => {
            if (!validator.isValid(values)) {
                errorCount++
                return { ...res, [validator.key]: false }
            } return { ...res, [validator.key]: true }
        }, {})
        if (errorCount > 0) {
            errors.password = "empty"
            errors.passwordConfirm = "empty"
        }
        // setValidationResult(validationResult)
        return validationResult
    }
    const handleNewRegistrationMail = () => {
        console.warn("not implemented")
    }

    if (isTokenValid === false) {
        return (
            <Grid container direction="column" justify="center" alignItems="stretch" className={classes.root}>
                <Grid item >
                    <img alt="Campaign.Plus Cornerstone" className={classes.logo} src="/assets/images/logo.svg" />
                </Grid>
                <Grid item>
                    {t("Der von Ihnen benutzte Link ist entweder ungültig oder abgelaufen. Sollten Sie eingeladen worden sein setzen Sie sich bitte mit Ihrem System Administrator in verbindung." )}   {/*TODO*/}
                </Grid>
                {/* <Grid item className={classes.sendNewEmailButton}>
                    <Button onClick={handleNewRegistrationMail} variant="contained" color="success">
                        <Fa icon={["fal", "envelope"]} pr={1} />
                        {t("neue E-Mail anfordern")}
                    </Button>
                </Grid> */}
                <Grid item className={classes.regpw}>
                    <Typography>
                        <Fa icon={["fal", "chevron-left"]} color="secondary.main" fixedWidth />
                        <Link component={RouterLink} to="/auth/login">{t("Zurück zum Login")}</Link>
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    else if (isPasswordReset === true) {
        return (
            <Grid container direction="column" justify="center" alignItems="stretch" className={classes.root}>
                <Grid item >
                    <img alt="Campaign.Plus Cornerstone" className={classes.logo} src="/assets/images/logo.svg" />
                </Grid>
                <Grid item>
                    {t("Ihr Passwort wurde erfolgreich geändert. Sie können sich nun mit ihrem neuen Password einloggen.")}   {/*TODO*/}
                </Grid>
                <Grid item className={classes.regpw}>
                    <Typography>
                        <Fa icon={["fal", "chevron-left"]} color="secondary.main" fixedWidth />
                        <Link component={RouterLink} to="/auth/login">{t("Zurück zum Login")}</Link>
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    else if (isTokenValid === true) {
        return (
            <Grid container direction="column" justify="center" alignItems="stretch" className={classes.root} >
                <Grid item>
                    <img alt="Campaign.Plus Cornerstone" className={classes.logo} src="/assets/images/logo.svg" />
                </Grid>
                <Form initialValues={initialValues} onSubmit={handleSubmit} validateOnMount={true}
                    render={({ handleSubmit, submitting }) => (
                        <form autoComplete="disabled" onSubmit={handleSubmit}>
                            <Grid item>
                                <Typography>
                                    {activateOrReset === "Activate"
                                        ? t("Ihr Passwort")
                                        : t("Ihr neues Passwort")}
                                </Typography>
                            </Grid>
                            <Grid item style={{ marginBottom: "16px" }}>
                                <FormikInput
                                    type="password"
                                    name="password"
                                    label={t("Passwort")}
                                />
                                <FormikInput
                                    type="password"
                                    name="passwordConfirm"
                                    label={t("Passwort bestätigen")}
                                />
                            </Grid>
                            <FormSpy subscription={{values:true}}>
                            {({values}:{values: IValues}) => (
                                    <Validation errors={handleValidate(values)}/>
                                )}
                            </FormSpy>
                            <Grid item className={classes.regpw}>
                                <Button isLoading={submitting} color="success" type="submit" variant="contained"><Fa icon={["far", "key"]} style={{ paddingRight: "8px" }} /> {t("Passwort setzen")}</Button>
                            </Grid>
                        </form>
                    )}
                />
            </Grid>
        )
    }
    else {
        return (
            <></>
        )
    }
}

const Validation = ({errors}: any) => {
    const {t} = useTranslation()
    return (
        <>
            {Object.keys(errors).map((key: string) =>
                <Grid>{errors[key]}
                    <Grid item key={key} >
                        <Box display="flex" alignItems="center">
                            {errors[key] ? <Fa icon={["fas", "check-square"]} pr={1} color="green" /> : <Fa icon={["fas", "minus-square"]} pr={1} color="secondary.main" />}
                            {t(`/fields:${key}`)}
                        </Box>
                    </Grid>
                </Grid>

            )}
        </>
    )
}

export default ActivateReset