import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Form } from "react-final-form"
import FormikInput from 'components/formik/FormikInput'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button/Button'
import { Typography, Grid, Fa } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import InputAdornment from '@material-ui/core/InputAdornment';
import ratings from 'models/ratings'
import { uniqueName } from 'constants/filters'
import { CancelButton, IState } from '../HtmlWizard'
import { Alert, AlertTitle } from '@material-ui/lab';

export type FormValues = {
    name: string
    rating_portals: string[]
}

type Props = {
    state: IState
    dispatch: any
}

export const useStyles = makeStyles(theme => ({

    navigation: {
        backgroundColor: "transparent",
        color: "transparent",
        padding: `0px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
    },
    root: {
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.companyDark.light,
        overflow: "auto",
        //padding: theme.spacing(1),
        boxSizing: "border-box",
        "& p": {
            fontWeight: "300",
            "& strong": {
                fontWeight: "500"
            }
        }
    },
    form: {
        width: "100%",
        margin: "auto",
        maxWidth: "600px",
        // padding: theme.spacing(4),
        backgroundColor: theme.palette.lightGrey.main,
        borderRadius: theme.spacing(0.5),
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        boxSizing: "border-box",
        overflow: "hidden"
    },
    formHeader: {
        padding: theme.spacing(4),
        //backgroundColor: "#83ac1b2b",
        backgroundColor: "#E6ECEF",
    },
    formContent: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    formFooter: {
        //   paddingLeft: theme.spacing(4),
        //   paddingRight: theme.spacing(4),
    },
    formNote: {
        width: "100%",
        maxWidth: "600px",
        color: "#ffffff75",
        paddingTop: theme.spacing(2)
    },
    headerDescription: {
        padding: theme.spacing(2),
        fontSize: "0.8rem",
        //backgroundColor: "#00000015",
        backgroundColor: "#ffffff90",
        borderRadius: "5px",
        position: "relative",
        '&:after': {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            width: 0,
            height: 0,
            border: "18px solid transparent",
            //borderRightColor: "#00000015",
            borderRightColor: "#ffffff90",
            borderLeft: 0,
            borderBottom: 0,
            marginTop: "-9px",
            marginLeft: "-18px",
        },
    },
    success: {
        color: theme.palette.success.main
    },
    error: {
        color: theme.palette.error.main
    },
    pixiSpeech: {
        //   color: "white"
    },
    requiredNotice: {
        //   color: "#95a0a6",
        fontSize: "0.9em"
    },
    disabledInput: {
        "& .Mui-disabled fieldset": {
            //       borderColor: "rgba(255, 255, 255, 0.25) !important"
        }
    },
    table: {
        "& td": {
            padding: theme.spacing(0.5)
        }
    },
    stepsReviewRow: {
        margin: "0 -10px",
        padding: "0 10px",
        transition: "0.3s all ease",
        "&:hover": {
            backgroundColor: "#E6ECEF",
        }
    }
}))

const HtmlSettings = ({ state, dispatch }: Props) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [hasNameFocus, setNameFocus] = useState(false)
    const [hasDomainFocus, setDomainFocus] = useState(false)
    const [isUniqueName, setIsUniqueName] = useState(true)

    const handleSubmit = async (values: FormValues) => {
        const filterObject = uniqueName(values.name)
        //@ts-ignore
        const response = await ratings.getList(1, 10, undefined, null, filterObject)
        if (response.meta.item_count > 0) {
            setIsUniqueName(false)
        } else {
            setIsUniqueName(true)
            dispatch({ type: "FINISH_SETTINGS", payload: values })
        }
    }

    const handleChangeName = () => {
        setIsUniqueName(true)
    }

    const initialValues = {
        name: state.name,
        ratingPortal: state.ratingPortals[0]
    }
    const ratingPortal = state.ratingPortals[0]
    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}
            render={({ handleSubmit, submitting }) => (
                <Grid container item direction="column" className={classes.root}>
                    <Grid container item direction="column" justify="center" alignItems="center" style={{ margin: "auto" }}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" justify="flex-start">
                                <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                    <Grid item sm={4}>
                                        <div style={{ maxWidth: "150px" }}>
                                            <Pixi size="full" pose="waiting"></Pixi>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <div className={classes.headerDescription} >
                                            <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                                <Typography variant="h5" className={classes.pixiSpeech}>
                                                    {t("Gib deiner Bewertungskampagne einen Namen und wähle eine Bewertungsplattform.")}
                                                </Typography>
                                            </Typist>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item container className={classes.formContent}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" className={classes.pixiSpeech} mb={1}>{t("Kampagneneinstellungen")}</Typography>
                                        <FormikInput
                                            name="name"
                                            variant="outlined"
                                            label={t("Name der Bewertungsstrecke")}
                                            type="text"
                                            autoComplete="off"
                                            required
                                            onChange={handleChangeName}
                                            error={!isUniqueName}
                                            helperText={!isUniqueName ? t("Dieser Name ist bereits vergeben") : null}
                                            InputProps={
                                                {
                                                    onFocus: (() => setNameFocus(true)),
                                                    onBlur: (() => setNameFocus(false)),
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            {hasNameFocus ? <Fa icon={["fas", "memo-pad"]} /> : <Fa icon={["fal", "memo-pad"]} />}
                                                        </InputAdornment>
                                                    ),
                                                }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} mb={1} mt={2}>
                                        <Alert severity="info">
                                            <AlertTitle>Bewertungsportal</AlertTitle>
                                            {t("Wenn du bereits einen Google My Business Eintrag hast, ermitteln wir anhand deines hinterlegten Impressums automatisch den direkten Link zur Bewertung deines Unternehmens auf Google. Bitte überprüfe den automatisch generierten Link.")}
                                            <br /><br />
                                            {t("Wenn du keinen Google My Business Eintrag hast oder Google nicht als Bewertungsplattform nutzen möchtest, kannst du hier den direkten Link zu deinem Bewertungsportal angeben.")}
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={12} >
                                        {/* <Typography variant="h4" className={classes.pixiSpeech}>{t("Versandeinstellungen")}</Typography> */}
                                        <FormikInput
                                            name="ratingPortal"
                                            required
                                            variant="outlined"
                                            type="text"
                                            label={t("URL zu deiner Bewertungsseite")}
                                            InputProps={
                                                {
                                                    onFocus: (() => setDomainFocus(true)),
                                                    onBlur: (() => setDomainFocus(false)),
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                             {hasDomainFocus ? <Fa icon={["fas", "globe"]} /> : <Fa icon={["fal", "globe"]} />} 
                                                        </InputAdornment>
                                                    ),
                                                }
                                            }
                                        />
                                        <Button variant='contained' color="success" href={ratingPortal} size="small" target="_blank"><Fa icon={["fas", "eye"]} mr={1}></Fa>Portal prüfen</Button>
                                    </Grid>
                                </Grid>
                            </Grid>



                            <Grid item sm={12} container className={classes.navigation} justify="space-between" alignItems="center" >
                                <CancelButton destination='ratings' />
                                <Button variant="contained" color="success" type="submit" disabled={!isUniqueName} isLoading={submitting} size="small">
                                    {t("Weiter")}
                                    <Fa icon={["fas", "chevron-right"]} ml={1}></Fa>
                                </Button>
                            </Grid>

                        </form>
                    </Grid >
                </Grid >

            )}

        />
    )
}

export default HtmlSettings