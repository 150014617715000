import React, { useMemo } from "react"
import { makeStyles } from '@material-ui/core'
import { Form, FormSpy } from 'react-final-form'
import Edit from "./Edit"
import View from "./View"
import { IState } from "../HtmlWizard"
import { steps } from "../HtmlWizard"
import { IPlaceholder } from "models/ratings"

const useStyles = makeStyles(theme => ({
    editorWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "stretch",
        backgroundColor: theme.palette.primary.light,
    },
    editorConfigSideBar: {
        flex: "0 1 auto",
        backgroundColor: theme.palette.primary.light,
        width: "400px",
        minWidth: "400px",
        height: "100%",
        maxHeight: "100vh",
        borderRadius: "0px",
        padding: "0px",
        overflow: "hidden",
        fontWeight: 300,
    },
    editorPreviewWrapper: {
        flex: "1 1 auto",
        height: "100%",
        maxHeight: "100vh",
        overflowX: "auto",
    }
}))

type Props = {
    state: IState,
    dispatch: any,
    preConfigComponent: React.ReactNode

}

type Values = Record<string, any>
type keys = { qr: { template: string, placeholders: Record<string, IPlaceholder> | null }; query: string; portals: string; feedback: string; feedbackLP: string }


const Editor = ({ state, dispatch, preConfigComponent }: Props) => {
    const classes = useStyles()

    const usedPlaceholderKeys = useMemo(() => (state.prebuild.templates[steps[state.step].key as keyof keys].template.match(/\${[^}]*}/g)?.map(p => p.substring(2, p.length - 1)) || []), [state.step, state.prebuild.templates])
    const combinedPlaceholders: Values ={...state.prebuild.config.placeholders, ...state.prebuild.templates[steps[state.step].key as keyof keys].placeholders}

    const initialValues: Values =
        (Object.keys(state.prebuild.config.placeholders) as Array<keyof typeof state.prebuild.config.placeholders>).reduce((res, key) =>
        ({
            ...res,
            [key]: combinedPlaceholders[key].value ? combinedPlaceholders[key].value : combinedPlaceholders[key].default
        })
            , {}) as Record<string, any>

    return (

        <div className={classes.editorWrapper}>
            <Form onSubmit={() => { }} initialValues={initialValues} subscription={{ submitting: true, pristine: true }}
                render={({ handleSubmit, form, submitting, pristine }) =>
                    <>
                        <div className={classes.editorConfigSideBar}>
                            {preConfigComponent}
                            <Edit
                                state={state}
                                dispatch={dispatch}
                                usedPlaceholderKeys={usedPlaceholderKeys}
                            />
                        </div>
                        <div className={classes.editorPreviewWrapper}>
                            <FormSpy subscription={{ values: true }}>
                                {({ values, active }) => (
                                    <View
                                        state={state}
                                        dispatch={dispatch}
                                        values={values}
                                    />
                                )}
                            </FormSpy>
                        </div>
                    </>
                }
            />
        </div>
    )
}

export default Editor