import { get, post, put, remove } from '../api/crud'
import { VerboseFilterObject } from '../types/filter.object'
import { cached } from '../util/cached'
import { AxiosResponse } from "axios"

export type FieldType = "string" | "date" | "set" | "enum" | "integer" | "boolean" | "datetime" | "string_array" | "float"

//export type string = {type:"string", params: []}
export type DBdate = {type:"date", params: []}
export type DBset = {type:"set", params: string[]}
export type DBenum = {type:"enum", params: string[]}
//export type number = {type:"integer", params: []}
//export type DBboolean = {type:"boolean", params: []}
export type DBdatetime = {type:"datetime", params: []}
export type DBstring_array = {type:"string_array", params: string[]}
export type DBfloat = {type: "float", params: []}

export type DBValues = string | DBdate | DBset | DBenum | number | boolean | DBdatetime | DBstring_array | DBfloat

export type DBValuesSchema = Record<string, DBValues>

export type DBSchema = {
    allow_null: boolean,
    db_type: "integer" | "double prevision" | "text" | "recipients_doi_state" | "timestamp without time zone" | "boolean" | "date",
    type: FieldType
    oid: number,
    default: any,
    primary_key: boolean,
    type_params: string[],
    enum_values: string[]
}

export type Info = {
    editable_fields: string[],
    visible_fields: string[],
    schema_info: Record<string, DBSchema>
}

export type BaseModel = {
    name: string
}

export type BaseDualModel = {
    name: string
}

export type CrudModel<T> = {
    get: (id:number) => Promise<T>,
    getList: (page?:number, pageSize?:number, filter?:string, orderBy?:string|null, filterObject?:VerboseFilterObject) => Promise<{data: T[], meta: {item_count:number}}>
    create: (model:Partial<T>) => Promise<T>,
    remove: (id:number) => Promise<any>,
    update: (id:number, model:Partial<T>) => Promise<T>,
    info: () => Promise<Info>,
    multiRemove: (ids: number[]) => Promise<AxiosResponse<void>>[]
}

export const withCrud = <T>(basePath:string): CrudModel<T> => ({
    get: (id:number) => get<T>(`${basePath}/${id}`),
    getList: (page:number = 1, pageSize:number = 10, filter:string = "", orderBy: string | null = null, filterObject: VerboseFilterObject = null) => get<{data:T[], meta:{item_count:number}}>(basePath, { page, page_size: pageSize, filter_string: filter, order_by: orderBy, filter_object: filterObject }),
    create: (model: Partial<T>) => post<T>(basePath, model),
    remove: (id: number) => remove(`${basePath}/${id}`),
    update: (id:number, model: Partial<T>) => put<T>(`${basePath}/${id}`, model),
    info: cached(() => get<Info>(`${basePath}/info`)),
    multiRemove: (ids: number[]) => ids.map(id => remove(`${basePath}/${id}`))
})