import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-xhr-backend';

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step
  .init({
    ns: ['/fields', '/recipients', '/models', '/common', '/errors', '/routes', '/countries', '/months'], // TODO why does this need a slash?!?
    lng: "de",
    fallbackLng: "de", // use en if detected lng is not available
    //debug: true,
    whitelist: ['de'],
    //saveMissing: true,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n
