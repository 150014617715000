import React from "react"
import { Grid, Paper } from "@material-ui/core"
import FormikInput from "components/formik/FormikInput"
import { useChatState } from "../../ChatContext"
import { useTranslation } from "react-i18next"

const EndNodeOptions = ({ node, classes }: any) => {
    const { state } = useChatState()
    const { t } = useTranslation()
    return (
        <Grid container direction="column" alignItems="center" className={classes.settingsContainer} style={{borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px"}}>
            <div className={classes.seperator}></div>
            <FormikInput
                type="slider"
                name="delay"
                max={10}
                min={0}
                valueLabelDisplay="auto"
                label="s delay"
                variant="delay"
            />
            <div className={classes.seperator}></div>
            <Paper elevation={2} className={classes.settingsGroupPaper}>
                <Grid container item direction="column" justify="center" alignItems="stretch" className={classes.settingsGroup}>
                    <Grid item style={{ width: "100%" }}>
                        <label className="FormikFormLabel">{t("Endtext")}</label>
                        <FormikInput
                            type="entityText"
                            name="text"
                            label="Text"
                            typeParams={state.fields}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default EndNodeOptions
