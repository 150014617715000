import React, { useReducer } from "react"
import reducer from "./reducer"

const ChatContext = React.createContext<any>({
    
})

const initialState = {
    elements: [{}],
    selected: null,
    selectedHandle: null,
    generalSettingsOpen: false,
    generalSettings:{
      name: "",
      withAvatar: false,
      fieldDefaults: {
          first_name: "Klaus",
          last_name: "Mustermann"
      }
    },
    drawingEdge:null
  }

export const ChatProvider : React.FC<{}> = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const providerState ={
        state,
        dispatch
    }

    return(
        <ChatContext.Provider value={providerState}>
            {children}
        </ChatContext.Provider>

    )
}


 export const useChatState = () => {
     return React.useContext(ChatContext)
 }



