import React from "react"
import { useTranslation } from "react-i18next"
import Button from "components/Button/Button"
import {Grid, makeStyles} from "@material-ui/core"
import { Typography, Fa } from 'styled/muiComponents'

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2)
    },
}))

const CurrentImage = ({current, handleRemoveImage}) => {
    const { t } = useTranslation()
    const classes = useStyles()
    
    return (
        <Grid item container alignItems="stretch" alignContent="flex-start" direction="column" className={classes.container}>
            {current !== "" ?
                <>
                    <Typography variant="h2" mb={1}>{t("Derzeitig benutztes Bild")}</Typography>
                    <img src={current} alt="currently used"/>
                    <Button variant="contained" onClick={handleRemoveImage}>
                        {t("Bild nicht mehr verwenden")} <Fa icon={["fas", "trash"]} ml={1}/> 
                    </Button>
                </>
                :
                <Typography variant="h2" mb={1}>{(t("Noch kein Bild in Benutzung. Wähle ein Bild."))}</Typography>
            }

        </Grid>
        
   )
}

export default CurrentImage