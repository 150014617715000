import React from 'react'
import FormikTextInput from './Text'
import FormikTinymce from './Tinymce'

import FormikSelect from './Select'
import FormikDate from './Date'
import ColorPicker from './ColorPicker'

import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'

// import Checkbox from './Checkbox'
// import { FormControlLabel } from '@material-ui/core'
import SenderEmailInput from './SenderEmailInput'
import FormikSlider from "./Slider"
import Image from "./Images/Image"
import IbanInput from './IbanInput'
import EntityText from "./entityText/EntityText"
import { Field } from "react-final-form"
import Bool from "./Boolean"
import Enum from "./Enum"
import Checkbox from "./Checkbox"
import Country from "./Country"
import LogoSelect from "./LogoSelect"
import senderAddresses from 'models/senderAddresses'
import Switch from './Switch'
import MultiSelect from './Multiselect'
import Select from './Select'
import ImprovedEnum from './ImprovedEnum'
import senderDomain from 'models/senderDomain'

const FormikInput = ({type, ...props}) => {
  const { t } = useTranslation()
  switch(type) {
    case "string" : return <Field {...props} component={FormikTextInput} />
    case "text" : return <Field {...props} component={FormikTextInput} />
    case "textarea": return <Field component={FormikTextInput} multiline={true} {...props} />
    case "tinymce": return <Field component={FormikTinymce} {...props} />
    case "integer": return <Field component={FormikTextInput} type="number" {...props} />
    case "url": return <Field component={FormikTextInput} type="url" {...props} />
    case "password": return <Field component={FormikTextInput} type="password" {...props} />
    case "boolean": return (
      <Field {...props} component={Bool}/>
    )
    case "checkbox":
      return (
        <Field component={Checkbox} {...props} />
      )
    case "color": return (
      <Field component={ColorPicker} {...props} />
    )
    case "select": return(
      <Field component={Select} {...props}/>
    )
    case "date": return (
      <Field component={FormikDate} {...props} />
    )

    case "improvedEnum": return (
      <Field component={ImprovedEnum} {...props}/> // TODO refactor all normal enum to this
    )

    case "enum": return (
      <Field component={Enum} {...props}/>
      )
    case "domain": return (
      <Field component={FormikSelect} {...props} model={senderDomain} async={true} />
    )
    case "senderEmail": return (
      <SenderEmailInput {...props} /> 
    )
    case "gender": return ( // TODO react final form
      <FormikSelect {...props} >
        <MenuItem value={"male"}>{ t(`/common:männlich`) }</MenuItem>
        <MenuItem value={"female"}>{ t(`/common:weiblich`) }</MenuItem>
        <MenuItem value={"divers"}>{t("divers")}</MenuItem>
      </FormikSelect>
    )
    case "country": return ( // TODO react final form
      <Field component={Country} {...props}/>      

    )
    case "slider": return (
      <Field component={FormikSlider} {...props}/>
    )
    case "image": return (
      <Field component={Image} {...props} />
    )
    case "iban": return (
      <Field component={IbanInput} {...props}/>
    )
    case "entityText":return (
      <Field component={EntityText} {...props}/>
    )
    case "switch": return (
      <Field component={Switch} {...props}/>
    )
    case "multiSelect": return (
      <Field component={MultiSelect} {...props}/>
    )
    case "logo": return (
      <Field component={LogoSelect} {...props}/>
    )
    
    default:
      console.error("unknown type", type, props)
      return null
  }
}

export default FormikInput