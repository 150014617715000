import { Grid, makeStyles, MobileStepper, Typography } from "@material-ui/core"
import Button from "components/Button/Button"
import LoadingAnimation from "components/LoadingAnimation"
import CancelWizardDialog from "dialogs/CancelWizardDialog"
import { useAlert } from "hooks/useAlert"
import { useDialog } from "hooks/useDialog"
import images from "models/images"
import imprints from "models/imprint"
import ratings, { IPrebuild } from "models/ratings"
import { IPlaceholder } from "models/webform"
import React, { useEffect, useReducer, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Fa } from "styled/muiComponents"
import { Device } from "types/common"
import ConfirmHtml from "./components/ConfirmHtml"
import HtmlBuilder from "./components/HtmlBuilder"
import HtmlSettings from "./components/HtmlSettings"
import htmlReducer from "./HtmlReducer"
import { useHistory, useLocation } from "react-router-dom"
import { ISetupInformation } from "routes/setup/setupWizard/SetupWizard"
import isImprintValid from "constants/isImprintValid"
import Block, { BlockContent, BlockTitle } from "components/Block"
import useSession from "hooks/useSession"


const useStyles = makeStyles(theme => ({
    wizardwrapper: {
        height: "100%",
        margin: "0px",
        width: "100%",
        overflow: "hidden",
    },
    WizardStepper: {
        color: "white",
        backgroundColor: "transparent",
        padding: theme.spacing(0),
        flexGrow: 1
    },
    Wizardnav: {
        padding: theme.spacing(2),
    }
}))

export type TemplateConfig = {
    template?: string,
    placeholders: Record<string, IPlaceholder>
}

// how generic?
export type IState = {
    prebuild: IPrebuild,
    step: number
    qr?: TemplateConfig
    query?: TemplateConfig
    portals?: TemplateConfig
    feedback?: TemplateConfig
    feedbackLP?: TemplateConfig
    device: Device
    imprint: any,
    name: string,
    ratingPortals: string[]
    confirmEditMode: boolean
    key: string
}

export type IStep = {
    label: string
    description?: string
    key?: keyof Omit<IState, "prebuild">
}

export const steps: IStep[] = [
    { label: "Design Auswahl", description: '' },
    { key: "qr", label: "Landingpage mit QR-Code", description: "Diese Seite kannst du dazu verwenden, um einen QR-Code zu deiner Bewertungsabfrage zum Beispiel auf einem Smartphone oder einem Tablet anzuzeigen." },
    { key: "query", label: "Vorqualifizierung", description: "Hier trennt sich Spreu von Weizen. Mit dieser Landingpage kannst du die Erfahrungen deiner Kunden vorqualifizieren. Halte es am besten sehr einfach und allgemein." },
    { key: "portals", label: "Bewertungsportal", description: "Wenn deine Kunden mit deinem Unternehmen zufrieden waren, können sie auf dieser Seite zum Bewertungsportal weitergeleitet werden." },
    { key: "feedback", label: "Feedback-Formular", description: "Sollten Kunden mal keine gute Erfahrung gemacht haben, können Kunden hier ihre konstruktive Kritik direkt mit dir teilen." },
    { key: "feedbackLP", label: "Feedback-Bestätigungsseite", description: "Hier landen die Kunden, nachdem sie das Feedback-Formular abgeschickt haben." },
    { label: "Abschließen" }
]

export const HtmlEditor = () => {

    const [imprint, setImprint] = useState<null | ISetupInformation>(null)
    const [logo, setLogo] = useState<null | string>(null)
    const [validImprint, setValidImprint] = useState(false)
    const { t } = useTranslation()
    const [mapContainer, setMapContainer] = useState<any>(null)
    const [ratingPortal, setRatingPortal] = useState<string | null>(null)
    const {logo: currentLogo} = useSession()

    useEffect(() => {
        const fetchImprint = async () => {
            const response = await imprints.getFirst()
            setImprint(response)
        }

        const fetchLogo = async () => {
            const response = await images.getLogo()
            setLogo(response)
        }
        const checkImprintValidity = async () => {
            const response = await isImprintValid()
            setValidImprint(response)
        }

        fetchImprint(); fetchLogo(); checkImprintValidity()
    }, [])


    useEffect(() => {
        if (!mapContainer || !imprint?.company_name || !imprint.city) {
            return
        }
        const map = new google.maps.Map(mapContainer)
        const request = {
            query: `${imprint.company_name} ${imprint.city}`,
            fields: ["place_id"]
        }

        const service = new google.maps.places.PlacesService(map)
        service.findPlaceFromQuery(request, ((
            results: google.maps.places.PlaceResult[] | null,
            status: google.maps.places.PlacesServiceStatus
        ) => {
            console.log(status, results)
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                
                setRatingPortal(`https://search.google.com/local/writereview?placeid=${results[0].place_id!}`)
            } else {
                console.log('here')
                setRatingPortal(' ')
            }
        }))
    }, [mapContainer, imprint])


    if (!imprint || ratingPortal === null ) {
        return (
            <>
                <div id="map" ref={node => setMapContainer(node)}></div>
                <LoadingAnimation />
            </>
        )
    }


    return (
        <>
            {validImprint
                ?
                <HtmlWizard
                    logoURL={currentLogo}
                    imprint={imprint}
                    ratingPortal={ratingPortal}
                />
                :
                <Grid container item direction="column" justify="center" alignItems="center" style={{ margin: "auto", paddingTop: "32px", paddingBottom: "32px" }}>
                    <div style={{ width: "100%", maxWidth: "600px" }}>
                        <Block>
                            <BlockTitle>
                                <Typography variant="h2">{t("Bewertung")}</Typography>
                            </BlockTitle>
                            <BlockContent>
                                <Typography style={{ marginBottom: "16px" }}>
                                    {t("Du kannst noch keine Bewertungsstrecken erstellen, da dein Impressum noch nicht vollständig ist. Dein Impressum kannst du unter Einstellungen bearbeiten.")}
                                </Typography>
                                <Button variant="contained" disableElevation color="success" size="small" href="/app/preferences">
                                    <Fa icon={["fas", "pencil"]} mr={1} />
                                    {t("Jetzt bearbeiten")}
                                </Button>
                            </BlockContent>
                        </Block>
                    </div>
                </Grid>
            }

        </>

    )

}



const HtmlWizard = ({ logoURL, imprint, ratingPortal }: { logoURL: string | null, imprint: ISetupInformation, ratingPortal: string }) => {

    const initialState = () => {
        const startWith = {
            prebuild: null,
            step: 0,
            device: Device.Smartphone,
            imprint: null,
            name: "",
            ratingPortals: [ratingPortal],
            confirmEditMode: false,
            key: "001" // TODO change later when more templates
        }

        return startWith
    }


    const [state, dispatch] = useReducer(htmlReducer, initialState())

    const classes = useStyles()
    const { alert } = useAlert()
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()

    console.log(state)

    useEffect(() => {
        const fetchAndGo = async () => {
            const prebuild = await ratings.getPrebuild("001") // TODO later with whatever was chosen
            dispatch({ type: "INITIAL_RENDER", payload: { prebuild, imprint, logo: logoURL ?? "" } })
        }
        fetchAndGo()
    }, [imprint, logoURL])

    const handlePrevious = () => {
        dispatch({ type: "PREVIOUS_STEP", payload: null })
    }

    const save = async () => {
        ratings.createRating(state)
            .then((response) => { alert.success(t("Bewertungsstrecke wurde erfolgreich gespeichert")); history.push(`${location.pathname}/${response.id}/success`, { state: response }) })
            .catch(() => alert.error(t("Bewertungsstrecke konnte nicht gespeichert werden")))
    }


    const renderStep = () => {
        if (!state.prebuild || !state.imprint) {
            return <LoadingAnimation />
        }



        switch (state.step) {
            case 0: return <HtmlSettings
                state={state}
                dispatch={dispatch}
            />
            // qr
            case 1: return <HtmlBuilder
                state={state}
                dispatch={dispatch}
                navigationProps={{
                    activeStep: state.step,
                    steps: steps.length,
                    onPrevious: handlePrevious,
                    state,
                    dispatch
                }}
            />
            // query
            case 2: return <HtmlBuilder
                state={state}
                dispatch={dispatch}
                navigationProps={{
                    activeStep: state.step,
                    steps: steps.length,
                    onPrevious: handlePrevious,
                    state,
                    dispatch
                }}
            />
            // portals
            case 3: return <HtmlBuilder
                state={state}
                dispatch={dispatch}
                navigationProps={{
                    activeStep: state.step,
                    steps: steps.length,
                    onPrevious: handlePrevious,
                    state,
                    dispatch
                }}
            />
            // feedback
            case 4: return <HtmlBuilder
                state={state}
                dispatch={dispatch}
                navigationProps={{
                    activeStep: state.step,
                    steps: steps.length,
                    onPrevious: handlePrevious,
                    state,
                    dispatch
                }}
            />
            // feedbackLP
            case 5: return <HtmlBuilder
                state={state}
                dispatch={dispatch}
                navigationProps={{
                    activeStep: state.step,
                    steps: steps.length,
                    onPrevious: handlePrevious,
                    state,
                    dispatch
                }}
            />
            // last page
            case 6: return <ConfirmHtml
                saveHtml={save}
                state={state}
                dispatch={dispatch}
            />
        }
    }

    if (!state.prebuild && state.step !== 0) {
        return <LoadingAnimation />
    }

    return (

        <Box className={classes.wizardwrapper}>
            {renderStep()}
        </Box>

    )
}

export default HtmlWizard


export type NavigationProps = {
    steps: number
    activeStep: number
    onNext?: () => void
    onPrevious?: () => void
    nextEnabled?: boolean
    state: IState
    dispatch: any
}

export const Navigation = ({ steps, activeStep, onNext, onPrevious, nextEnabled, state, dispatch }: NavigationProps) => {
    const classes = useStyles()


    return (
        <Grid container className={classes.Wizardnav} justify="flex-start" alignItems="center" direction='row'>
            <CancelButton destination={"ratings"} /> {/** get destination from somewhere */}
            <MobileStepper
                className={classes.WizardStepper}
                variant="text"
                steps={steps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <NextButton
                        state={state}
                        dispatch={dispatch}
                        onNext={onNext}
                        nextEnabled={nextEnabled}
                        activeStep={activeStep}
                        steps={steps}
                    />
                }
                backButton={
                    <BackButton
                        state={state}
                        dispatch={dispatch}
                        onPrevious={onPrevious}
                        nextEnabled={nextEnabled}
                        activeStep={activeStep}
                        steps={steps}
                    />
                }
            />
        </Grid>
    )
}

const NextButton = ({ state, dispatch, onNext, nextEnabled, activeStep, steps }: NavigationProps) => {
    const { t } = useTranslation()

    const handleDone = () => {
        dispatch({ type: "CONFIRM_EDIT_DONE" })
    }

    if (state.confirmEditMode) {
        return (
            <Button onClick={handleDone} variant="contained" disableElevation color="success" size="small" disabled={nextEnabled}>
                {t("Fertig")}
                <Fa icon={["fas", "chevron-right"]} ml={1}></Fa>
            </Button>
        )
    }

    if (activeStep === steps) {
        return null
    } else {
        return (
            <Button onClick={onNext} variant="contained" disableElevation color="success" size="small" disabled={nextEnabled}>
                {t("Weiter")}
                <Fa icon={["fas", "chevron-right"]} ml={1}></Fa>
            </Button>
        )

    }
}

const BackButton = ({ state, dispatch, onPrevious, activeStep, steps }: NavigationProps) => {
    const { t } = useTranslation()

    if (activeStep !== 0) {
        return (
            <Button onClick={onPrevious} disabled={activeStep === 0} variant="contained" disableElevation color="success" size="small">
                <Fa icon={["fas", "chevron-left"]} mr={1}></Fa>
                {t("Zurück")}
            </Button>
        )
    } else {
        return null
    }
}

export const CancelButton = ({ destination }: { destination: string }) => {
    const history = useHistory()
    const { t } = useTranslation()
    const { dialog } = useDialog()

    const handleClick = () => {
        dialog.show({
            component: CancelWizardDialog,
            props: {}
        }).then((response) => {
            if (response.leave) {
                history.push(`/app/stock/${destination}`)
            }
        })
    }

    return (
        <Button onClick={handleClick} variant="contained" disableElevation color="error" size="small" style={{ marginRight: "16px" }}>
            <Fa icon={["fas", "times"]} mr={1} />
            {t("Abbrechen")}
        </Button>
    )
}