import React, { useState } from 'react'
import { Box, makeStyles, Collapse, Grid, Theme } from '@material-ui/core'
import { IInputGroup, ICustomHeader } from './Editor'
import FormikInput from '../formik/FormikInput'
import { FaButton, Fa } from 'styled/muiComponents'
import { useTranslation } from "react-i18next"
import { IPlaceholder, TemplateKey } from 'models/webform'
import { Typography } from 'styled/muiComponents'
import Pixi from 'components/Pixi'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Device } from 'types/common'
import { IChats, ITemplateMailSettings } from 'routes/app/stock/webforms/wizard/WebformWizard'
import Chats from 'routes/app/stock/webforms/wizard/steps/ChatsMenu'

type Props = {
    placeholders: Record<string, IPlaceholder>,
    onChange: (name: keyof IPlaceholder, value: any) => void,
    inputGroups: IInputGroup[]
    usedPlaceholderKeys: string[]
    customHeader?: ICustomHeader
    onDeviceChange: (device: Device) => void
    device: Device
    templateMailSettings: ITemplateMailSettings
    handleChangeMailSettings: (event: {target: {name: keyof IPlaceholder, value: any}}) => void
    previewIdentifier: TemplateKey
    chats: IChats | null
    handleLoadMoreChats: () => void
    handleSetChat:(id: number | null) => void
    handleOpenChat: () => void
    wizardState: any
    handleChangeChatView: (view: "chat" | "graph") => void
}

type Values = Record<string, any>

const useStyles = makeStyles<Theme, { device?: Device, open? : boolean }>(theme => ({
    root: {
        backgroundColor: theme.palette.primary.light,
        color: "#ffffff",
        height: "calc(100% - 64px)",
        display: "flex",
        flexFlow: "column",
        boxSizing: "border-box",
    },
    header: {
        /*padding: theme.spacing(1)*/
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subheader: {
        fontSize: "1.25rem",
    },
    headerDescription: {
        padding: theme.spacing(1.2),
        fontSize: "0.8rem",
        backgroundColor: "#ffffff26",
        borderRadius: "5px",
        position: "relative",
        '&:after': {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            width: 0,
            height: 0,
            border: "18px solid transparent",
            borderRightColor: "#ffffff26",
            borderLeft: 0,
            borderBottom: 0,
            marginTop: "-9px",
            marginLeft: "-18px",
        },
    },
    DescriptionWrapper: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    cardFixedHeader: {
        flex: "0 1 auto",
    },
    cardContent: {
        padding: 0,
        overflow: "auto",
        flex: "1 1 auto",
    },
    inputGroupHeader: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        transition: "0.35s all ease",
        color:({ open }) => open ? theme.palette.companyRed.main : "white",
        "&:hover": {
            color: theme.palette.companyRed.main,
            cursor: "pointer",
        },
    },
    inputGroupContainer: {
       transition: "0.35s all ease",
       borderTopRightRadius: "5px",
       borderBottomRightRadius: "5px",
        "&:hover": {
            backgroundColor: "#ffffff0d",
        }
    },
    inputWrapper: {
      padding: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      "&:first-child": {
        paddingTop: theme.spacing(2.5),
      },
      "&:last-child": {
        paddingBottom: theme.spacing(2.5),
      },
      transition: '0.35s all ease',
      "&:hover": {
          color: theme.palette.companyRed.main
      }
    },
    collapse: {
        borderTop: "1px solid #ffffff0d",
    },
    previewDeviceIconsWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    previewLabel: {
        textAlign: "left",
        flex: "1 1 auto",
        "& p": {
            margin: 0,
        }
    },
    previewModeFullscreenButton: { // TO DO
        color: ({ device }) => device === Device.Fullscreen ? theme.palette.companyRed.main : "white",
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ device }) => device === Device.Fullscreen ? "1" : "0.5",
        },
        "& button": {
            paddingRight: 0,
        }
    },
    previewModeDesktopButton: { //TO DO
        color: ({ device }) => device === Device.Desktop ? theme.palette.companyRed.main : "white",
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ device }) => device === Device.Desktop ? "1" : "0.5",
        }
    },
    previewModeTabletButton: { // TO DO
        color: ({ device }) => device === Device.Tablet ? theme.palette.companyRed.main : "white",
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ device }) => device === Device.Tablet ? "1" : "0.5",
        }
    },
    previewModeSmartphonebutton: { // TO DO
        color: ({ device }) => device === Device.Smartphone ? theme.palette.companyRed.main : "white",
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ device }) => device === Device.Smartphone ? "1" : "0.5",
        }
    },
}))

const Config: React.FC<Props> = ({ previewIdentifier, templateMailSettings, handleChangeMailSettings, placeholders, onChange, inputGroups, usedPlaceholderKeys, customHeader, onDeviceChange, device, chats, handleLoadMoreChats, handleSetChat, handleOpenChat, wizardState, handleChangeChatView }: Props) => {
    const { t } = useTranslation()
    // const form = useForm()

    // let initialValues: Values =
    //     (Object.keys(placeholders) as Array<keyof typeof placeholders>).reduce((res, key) =>
    //         ({
    //             ...res,
    //             [key]: placeholders[key].value ? placeholders[key].value : placeholders[key].default
                
    //         })
    //         , {}) as Record<string, any>
    // initialValues = {...initialValues, subject: templateMailSettings.subject.default ? templateMailSettings.subject.default : templateMailSettings.subject.value}
    const classes = useStyles({ device })

    const handleChange = (event: { target: { name: keyof IPlaceholder, value: any } }) => {
        const { name, value } = event.target
        onChange(name, value)
    }

    const handleSubmit = () => {

    }

    return (
        <div className={classes.root}>
            <div className={classes.cardFixedHeader}>
                <Typography variant="h3" className={classes.header}>
                    {customHeader?.header}
                </Typography>
                <Grid container direction="row" justify="center" alignItems="center" className={classes.DescriptionWrapper}>
                    <Grid item xs={4}>
                        <Pixi size="full" pose="waiting"></Pixi>
                    </Grid>
                    <Grid item xs={8}>
                        <div className={classes.headerDescription}>
                            {customHeader?.description}
                        </div>
                    </Grid>
                </Grid>
                <Grid container justify="flex-end" alignItems="center" className={classes.previewDeviceIconsWrapper}>
                    <Grid item className={classes.previewLabel}>
                        <p>{t("Vorschau")}:</p>
                    </Grid>
                    <Grid item onClick={() => onDeviceChange(Device.Smartphone)} className={classes.previewModeSmartphonebutton}>
                        <FaButton style={{ color: "inherit" }}>
                            <Fa icon={["fal", "mobile"]} />
                        </FaButton>
                    </Grid>
                    <Grid item onClick={() => onDeviceChange(Device.Tablet)} className={classes.previewModeTabletButton}>
                        <FaButton style={{ color: "inherit" }}>
                            <Fa icon={["fal", "tablet"]} />
                        </FaButton>
                    </Grid>
                    <Grid item onClick={() => onDeviceChange(Device.Desktop)} className={classes.previewModeDesktopButton}>
                        <FaButton style={{ color: "inherit" }}>
                            <Fa icon={["fal", "laptop"]} />
                        </FaButton>
                    </Grid>
                    <Grid item onClick={() => onDeviceChange(Device.Fullscreen)} className={classes.previewModeFullscreenButton}>
                        <FaButton style={{ color: "inherit" }} >
                            <Fa icon={["fal", "window"]} />
                        </FaButton>
                    </Grid>
                </Grid>
            </div>
            <PerfectScrollbar>
                <div className={classes.cardContent}>
                    {/* <Form onSubmit={() => { }} initialValues={initialValues} subscription={{submitting:true, pristine:true}}
                        render={({handleSubmit, form}) => ( */}
                             <form className="editor-config" onSubmit={handleSubmit}>
                             <div className="editor-config">
                                {previewIdentifier === "doiMail" || previewIdentifier === "doneMail" ?
                                    <InputGroup previewIdentifier={previewIdentifier} key={"Email Einstellungen"} name={"E-Mail Einstellungen"} faIcon={"envelope"} iconType={"fal"} onChange={handleChangeMailSettings} placeholderKeys={Object.keys(templateMailSettings)} placeholders={templateMailSettings} usedPlaceholderKeys={Object.keys(templateMailSettings)} />
                                    : null}
                                {inputGroups.filter(group => group.placeholders.some(p => usedPlaceholderKeys.includes(p))).map(inputGroup => (
                                    <InputGroup previewIdentifier={previewIdentifier} key={inputGroup.name} name={inputGroup.name} faIcon={inputGroup.faIcon} iconType={inputGroup.iconType} onChange={handleChange} placeholderKeys={inputGroup.placeholders} placeholders={placeholders} usedPlaceholderKeys={usedPlaceholderKeys} />
                                ))}
                                </div>
                                <div>
                                    {previewIdentifier === "doiLP" &&
                                    <input type="checkbox" checked={wizardState.chat !== null} onClick={handleOpenChat}/>
                                    }
                                </div>
                                <div>
                                    {(wizardState.chat !== null && chats && previewIdentifier === "doiLP") && <Chats chats={chats} handleLoadMoreChats={handleLoadMoreChats} handleSetChat={handleSetChat} handleChangeChatView={handleChangeChatView}/>}
                                </div>
                              </form>
                        {/* )} */}
                    {/* />  */}
                </div>
            </PerfectScrollbar>
        </div>
    )
}

type InputGroupProps = {
    name: string
    placeholderKeys: string[]
    placeholders:  Record<string, IPlaceholder > 
    usedPlaceholderKeys: string[]
    onChange: (event: {target: {name: keyof IPlaceholder, value: any}}, key?: TemplateKey) => void
    faIcon: string
    iconType: string
    previewIdentifier: TemplateKey
}

const InputGroup = ({ previewIdentifier, name, placeholderKeys, placeholders, usedPlaceholderKeys, onChange, faIcon, iconType }: InputGroupProps) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles({ open })

    return (
        <div className={classes.inputGroupContainer}>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={2} className={classes.inputGroupHeader} onClick={() => setOpen(!open)}>
                <Typography variant="h3" className={classes.subheader}>
                    {name}
                </Typography>
                <Fa icon={[iconType as IconPrefix, faIcon as IconName]} size="lg" fixedWidth />
            </Box>
            <Collapse in={open} className={classes.collapse} >
                {placeholderKeys.filter(p => usedPlaceholderKeys.includes(p)).map((placeholderKey: keyof typeof placeholders) => (
                    <div className={classes.inputWrapper} key={placeholderKey} >
                        {/* <Field name={placeholders[placeholderKey].key} variant="outlined" onChange={onChange} id={`${previewIdentifier}_${placeholders[placeholderKey].key}`} type={placeholders[placeholderKey].type} label={placeholders[placeholderKey].label}  {...placeholders[placeholderKey].props} component={FormikInput} /> */}
                         <FormikInput variant="outlined" onChange={onChange} id={`${previewIdentifier}_${placeholders[placeholderKey].key}`} type={placeholders[placeholderKey].type} label={placeholders[placeholderKey].label} name={placeholders[placeholderKey].key} {...placeholders[placeholderKey].props} />
                        
                    </div>
                ))}
            </Collapse>
        </div>
    )
}

export default Config

