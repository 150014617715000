import React, { useEffect, useState } from "react"
import { SetupNavigationProps, Navigation, ISetupInformation } from "../SetupWizard"
import { Radio } from "@material-ui/core"
import { Form } from 'react-final-form'
import FormikInput from "components/formik/FormikInput"
import { Typography, Grid } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { useTranslation } from "react-i18next"
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

type Props = {
    navigationProps: SetupNavigationProps
    classes: any
    handleChange: (event: any) => void
    setupInformation: ISetupInformation
    noInfo: boolean
    handleChangeNoInfo: (name: string) => void
}

const CompanyJobTitle = ({ navigationProps, classes, handleChange, setupInformation, noInfo, handleChangeNoInfo }: Props) => {
    const { t } = useTranslation()
    const [enableNext, setEnableNext] = useState<boolean>(false)
    const handleSubmit = (values: any) => {
        console.log(values)
    }

    const initialValues = {
        supervisory_authority: setupInformation.supervisory_authority,
        responsible_chamber: setupInformation.responsible_chamber,
        job_title_plus_country_awarded: setupInformation.job_title_plus_country_awarded,
        professional_regulations: setupInformation.professional_regulations
    }

    useEffect(() => {
        if (!noInfo || setupInformation.supervisory_authority) {
            setEnableNext(true)
        } else {
            setEnableNext(false)
        }
    },[setupInformation, noInfo])

    const renderContent = () => {
        if (noInfo) {
            return (
                <Grid item container>
                    <Grid item xs={12}>
                        <FormikInput
                            type="string"
                            value={setupInformation.supervisory_authority}
                            name="supervisory_authority"
                            label={t("Aufsichtsbehörde")}
                            variant="outlined"
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikInput
                            type="string"
                            value={setupInformation.job_title_plus_country_awarded}
                            name="job_title_plus_country_awarded"
                            label={t("Job Titel und Land in dem er verliehen wurde")}
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikInput
                            type="string"
                            value={setupInformation.responsible_chamber}
                            name="responsible_chamber"
                            label={t("Zuständige Kammer")}
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikInput
                            type="string"
                            value={setupInformation.professional_regulations}
                            name="professional_regulations"
                            label={t("Berufsrechtliche Regelungen")}
                            variant="outlined"
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            )
        }
    }

    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}
            render={({ handleSubmit, submitting }) => (
                <Grid container item direction="column" className={classes.root}>
                    <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" justify="flex-start">
                                <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                    <Grid item sm={4}>
                                        <div style={{ maxWidth: "150px" }}>
                                            <Pixi size="full" pose="waiting"></Pixi>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <div className={classes.headerDescription} >
                                        <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                            <Typography variant="h5" className={classes.pixiSpeech}>
                                            {t("Bedarf die Tätigkeit die dein Unternehmen ausübst einer behördlichen Zulassung? Zum Beispiel Ärzte, Makler, Anwälte usw.")}
                                            </Typography>
                                        </Typist>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item container className={classes.formContent}>
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{t("Bitte wählen")}</FormLabel>
                                            <RadioGroup row aria-label="jobtitle" name="jobtitle" onChange={handleChange} style={{marginTop: "8px"}}>
                                                <FormControlLabel value="yes" control={<Radio checked={noInfo} onClick={() => handleChangeNoInfo("job")} />} label={t("Ja")} />
                                                <FormControlLabel value="no" control={<Radio checked={!noInfo} onClick={() => handleChangeNoInfo("job")} />} label={t("Nein")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {renderContent()}
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <Navigation  {...navigationProps} enableNext={!enableNext} skipEnabled={noInfo}/>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item className={classes.formNote}>
                            <Typography variant="body1" className={classes.requiredNotice}>
                                <i>* {t("Pflichtfeld")}</i>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    )
}

export default CompanyJobTitle