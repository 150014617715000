import React from "react";
import Button from "../components/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "./DialogTitle";
import DialogContent from "./DialogContent";
import DialogActions from "./DialogActions";
import { Fa } from '../styled/muiComponents'

import { useTranslation } from "react-i18next";
import { DialogProps } from '../hooks/useDialog'
import Slide from '@material-ui/core/Slide'
import { VerboseFilterObject } from "types/filter.object";
import FormikInput from "components/formik/FormikInput";
import { Form } from "react-final-form"
import { Box, Grid, Typography } from "@material-ui/core";

export type Response = {
  action: "reload" | null
}

type Item = {
  id: number,
  name?: string,
  domain?: string,
  email?: string
}

export type Props = {
  model: {
    name: string
    export: (searchText: string, filterObject: VerboseFilterObject, columns: string[]) => Promise<void>
  },
  searchText: string
  filterObject: VerboseFilterObject
  columns: any[]
}

type IFormikValues = {
  dontUseFilters: boolean
  columns: Record<string, boolean>
}

const ExportDialog: React.FC<Props & DialogProps> = ({ onClose, open, onError, model, columns, searchText, filterObject }) => {
  const { t } = useTranslation();

  const initialValues: IFormikValues = {
    dontUseFilters: false,
    columns: columns.reduce((res, cur) => ({ ...res, [cur.name]: cur.display === "true" }), {})
  }

  const handleClose = () => {
    onClose({ action: null })
  }

  const handleSubmit = async (values: IFormikValues) => {
    //nur E-Mail = alle felder ?!
    model.export(searchText, filterObject, Object.keys(values.columns).filter(key => values.columns[key]))
  }

  const translatedModelName = t(`/models:${model.name}`, { count: 2 });

  console.log({ searchText, filterObject })

  return (
    

      <Form initialValues={initialValues} onSubmit={handleSubmit}
        render={({ submitting, values, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Slide}>
              <DialogTitle onClose={handleClose}>
                <Fa icon={["fal", "cloud-download-alt"]} mr={1.75} />{t("{{model}} exportieren", { model: translatedModelName })}
              </DialogTitle>
              <DialogContent>
                {(searchText !== "" || filterObject !== null) &&
                  <>
                    <Box mb={3}>
                      <Typography variant="h5">
                        {t("Wähle die folgende Checkbox, wenn du das gesamte Adressbuch exportieren möchtest")}
                      </Typography>
                      <Box mt={2}>
                        <FormikInput
                          name="dontUseFilters"
                          type="checkbox"
                          label={t("Aktuelle Filterung ignorieren")}
                        />
                      </Box>
                    </Box>
                  </>
                }
                <Box mb={2}>
                  <Typography variant="h5">
                    {t("Wähle hier die Datenfelder, die du exportieren möchtest")}
                  </Typography>
                </Box>
                  {columns.map(column => (
                    <div key={column.name} >
                      <FormikInput
                        name={"columns." + column.name}
                        label={column.label}
                        type="checkbox"
                      />
                    </div>
                  ))}
              </DialogContent>
              <DialogActions>
                <Grid container justify="space-between">
                  <Button onClick={handleClose} variant="contained" color="error"><Fa icon={["fas", "times"]} mr={1}  size={"lg"} /> {t("/common:Abbrechen")}</Button>
                  <Button isLoading={false} type="submit" variant="contained" color="success">
                    <Fa icon={["fas", "cloud-download-alt"]} mr={1} />
                    {t("Download starten")}
                  </Button>
                </Grid>
              </DialogActions>
            </Dialog>
          </form>
        )}
      />
    
  );
};

export default ExportDialog