import React from 'react';
import { Grid, makeStyles} from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    ldsSpinner: {
        color: "#ffffff",
        display: "block",
        position: "relative",
        width: "80px",
        height: "80px",
        transform: "scale(0.5)",
        margin: "0 auto",
        zIndex: 100,
        "& div": {
            transformOrigin: "40px 40px",
            animation: "$ldsSpinner 1.2s linear infinite",
        },
        "& div:after": {
            content: "''",
            display: "block",
            position: "absolute",
            top: "3px",
            left: "37px",
            width: "6px",
            height: "18px",
            borderRadius: "20%",
            background: theme.palette.companyRed.main
        },
        "& div:nth-child(1)": {
            transform: "rotate(0deg)",
            animationDelay: "-1.1s"
        },
        "& div:nth-child(2)": {
            transform: "rotate(30deg)",
            animationDelay: "-1s"
        },
        "& div:nth-child(3)": {
            transform: "rotate(60deg)",
            animationDelay: "-0.9s"
        },
        "& div:nth-child(4)": {
            transform: "rotate(90deg)",
            animationDelay: "-0.8s"
        },
        "& div:nth-child(5)": {
            transform: "rotate(120deg)",
            animationDelay: "-0.7s"
        },
        "& div:nth-child(6)": {
            transform: "rotate(150deg)",
            animationDelay: "-0.6s"
        },
        "& div:nth-child(7)": {
            transform: "rotate(180deg)",
            animationDelay: "-0.5s"
        },
        "& div:nth-child(8)": {
            transform: "rotate(210deg)",
            animationDelay: "-0.4s"
        },
        "& div:nth-child(9)": {
            transform: "rotate(240deg)",
            animationDelay: "-0.3s"
        },
        "& div:nth-child(10)": {
            transform: "rotate(270deg)",
            animationDelay: "-0.2s"
        },
        "& div:nth-child(11)": {
            transform: "rotate(300deg)",
            animationDelay: "-0.1s"
        },
        "& div:nth-child(12)": {
            transform: "rotate(330deg)",
            animationDelay: "0s"
        },
    },
    "@keyframes ldsSpinner": {
        "0%": {
            opacity: 1
        },
        "100%": {
            opacity: 0
        }
    },
    ldsSpinnerChildren: {
        fontWeight: 600,
        letterSpacing: 3,
        textTransform: "uppercase",
        fontSize: "0.8rem"
    },




    cleanLoader: {
        width: "30px",
        height: "30px",
        "&::before": {
            
                content: "''",
                boxSizing: "border-box",
                position: "absolute",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                borderTop: "2px solid #e83338",
                borderBottom: "2px solid transparent",
                borderRight: "2px solid #e83338",
                animation: "$spinner8217 0.8s linear infinite",
        
       }
       
       
       },
       
       "@keyframes spinner8217": {
        "to": {
         transform: "rotate(360deg)"
        }
       }
}))



 export const LoadingAnimation: React.FC<{}> = ({children} ) => {
    const classes = useStyles()
    return(
        <Grid container direction="column" alignItems="center" justify="center" style={{height: "100%", width: "100%"}}> 
            {/*<div className={classes.ldsSpinner}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>*/}
            <div className={classes.cleanLoader}></div>
            <Grid item container alignItems="center" justify="center" className={classes.ldsSpinnerChildren}> 
                {children}
            </Grid>
        </Grid>
    )
}


export default LoadingAnimation