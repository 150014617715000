import React from 'react';
import PropTypes from 'prop-types'
import { useForm } from "react-final-form"

// import { useField, useFormikContext } from 'formik'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'

const TextInput = ({ autosize, ...props }) => {
  const { t } = useTranslation()
  const initialValues = useForm().getState().initialValues
  const { input: { name, value, ...field }, meta } = props
  const fallbackPlaceholder = t(`/fields:${props.name}`)
  const errorText = meta.error && meta.touched ? meta.error : ''

  if (!props.placeholder && !props.label) {
    //console.warn("neither placeholder nor label are set!", {name, ...props})
  }

  const handleBlur = (e) => {
    if (props.onBlur) {
      props.onBlur(e)
    }
    field.onBlur(e)
  }

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(e)
    }
    field.onChange(e)
  }

  if (props.multiline) {
    return (
      <TextField
        name={name}
        placeholder={props.placeholder || (!props.label && fallbackPlaceholder)}
        value={value || "" || props.conditionalValue}
        helperText={errorText === "empty" ? "" : errorText}
        error={!!errorText}
        {...props}
        {...field}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={(e) => {
          console.log(e.keyCode)
          if (props.handleSubmit) {
            if (e.keyCode === 13 && !e.shiftKey) {
              props.handleSubmit()
              e.preventDefault();
              //setInputValue("");
            }
          }
        }}
        style={autosize ? {
          ...props.style,
          width: `${(value || "").length + 4}ch`,
          SminWidth: `${Math.max((initialValues[name] || "").length, 5)}ch`

        } : props.style}
      ></TextField>
    )
  }

  return (
    <TextField
      name={name}
      placeholder={props.placeholder || (!props.label && fallbackPlaceholder)}
      value={value || ""}
      helperText={errorText === "empty" ? "" : errorText}
      error={!!errorText}
      {...props}
      {...field}
      onBlur={handleBlur}
      onChange={handleChange}
      style={autosize ? {
        ...props.style,
        width: `${(value || "").length + 4}ch`,
        SminWidth: `${Math.max((initialValues[name] || "").length, 5)}ch`
      } : props.style}
    ></TextField>
  )

}

TextInput.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(["string", "number", "password", "url"]),
  label: PropTypes.string,
  margin: PropTypes.string,
  inputProps: PropTypes.object,
  onFocus: PropTypes.func,
  fullWidth: PropTypes.bool,
  fast: PropTypes.bool,
  autosize: PropTypes.bool
}

TextInput.defaultProps = {
  placeholder: "",
  label: "",
  margin: "normal",
  inputProps: {},
  fullWidth: true,
  autosize: false
}

export default TextInput 
