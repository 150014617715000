export const template = {
    id: "template_003",
    name: "test3",
    template: [
        {
            "id": "1",
            "type": "start",
            "data": {
                "node": {
                    "id": "1",
                    "type": "start",

                },
                "text": "",
                "buttonTexts": ["", ""]
            },
            "position": {
                "x": 0,
                "y": -200
            }
        },
        {
            "id": "1641998348028",
            "position": {
                "x": 200,
                "y": -200
            },
            "data": {
                "node": {
                    "id": "1641998348028",
                    "type": "botResponse"
                },
                "responses": []
            },
            "type": "botResponse"
        },
        {
            "id": "e1S-1641998348028N",
            "source": "1",
            "target": "1641998348028",
            "targetHandle": "N",
            "sourceHandle": "S",
            "animated": true,
            "style": {
                "stroke": "#ffffff"
            }
        },
        {
            "id": "e1641998348028S-1642085848163N",
            "source": "1641998348028",
            "target": "1642085848163",
            "targetHandle": "N",
            "sourceHandle": "S",
            "animated": true,
            "style": {
                "stroke": "#ffffff"
            }
        },
        {
            "id": "e1642085848163S-1642153939220N",
            "source": "1642085848163",
            "target": "1642153939220",
            "targetHandle": "N",
            "sourceHandle": "S",
            "animated": true,
            "style": {
                "stroke": "#ffffff"
            }
        },
        {
            "id": "1642153939490",
            "position": {
                "x": 600,
                "y": -150
            },
            "data": {
                "node": {
                    "id": "1642153939490",
                    "type": "splitAnswer"
                },
                "answer": "123"
            },
            "type": "splitAnswer"
        },
        {
            "id": "e1642085848163S-1642153939490N",
            "source": "1642085848163",
            "target": "1642153939490",
            "targetHandle": "N",
            "sourceHandle": "S",
            "animated": true,
            "style": {
                "stroke": "#ffffff"
            }
        },
        {
            "id": "1642085848163",
            "type": "splitQuestion",
            "position": {
                "x": 400,
                "y": -200
            },
            "data": {
                "node": {
                    "id": "1642085848163",
                    "type": "splitQuestion"
                },
                "question": "",
                "answers": [
                    {
                        "answer": "123123123",
                        "answerKey": "1642153939220"
                    },
                    {
                        "answer": "123",
                        "answerKey": "1642153939490"
                    }
                ]
            }
        },
        {
            "id": "1642153939220",
            "type": "splitAnswer",
            "position": {
                "x": 600,
                "y": -250
            },
            "data": {
                "node": {
                    "id": "1642153939220",
                    "type": "splitAnswer"
                },
                "answer": "123123123"
            }
        },
        {
            "id": "1642154236508",
            "position": {
                "x": 800,
                "y": -250
            },
            "data": {
                "node": {
                    "id": "1642154236508",
                    "type": "dataCollection"
                },
                "questions": []
            },
            "type": "dataCollection"
        },
        {
            "id": "e1642153939220S-1642154236508N",
            "source": "1642153939220",
            "target": "1642154236508",
            "targetHandle": "N",
            "sourceHandle": "S",
            "animated": true,
            "style": {
                "stroke": "#ffffff"
            }
        },
        {
            "id": "1642155463293",
            "position": {
                "x": 800,
                "y": -150
            },
            "data": {
                "node": {
                    "id": "1642155463293",
                    "type": "feedback"
                },
                "split": 4
            },
            "type": "feedback"
        },
        {
            "id": "e1642153939490S-1642155463293N",
            "source": "1642153939490",
            "target": "1642155463293",
            "targetHandle": "N",
            "sourceHandle": "S",
            "animated": true,
            "style": {
                "stroke": "#ffffff"
            }
        },
        {
            "id": "1642155463293P",
            "position": {
                "x": 1000,
                "y": -50
            },
            "data": {
                "node": {
                    "id": "1642155463293P",
                    "type": "socialMedia"
                },
                "socialMedias": []
            },
            "type": "socialMedia"
        },
        {
            "id": "e1642155463293S-1642155463293PN",
            "source": "1642155463293",
            "target": "1642155463293P",
            "targetHandle": "N",
            "sourceHandle": "S",
            "animated": true,
            "style": {
                "stroke": "#ffffff"
            }
        },
        {
            "id": "1642155463293U",
            "position": {
                "x": 1000,
                "y": -250
            },
            "data": {
                "node": {
                    "id": "1642155463293U",
                    "type": "userFeedback"
                }
            },
            "type": "userFeedback"
        },
        {
            "id": "e1642155463293S-1642155463293UN",
            "source": "1642155463293",
            "target": "1642155463293U",
            "targetHandle": "N",
            "sourceHandle": "S",
            "animated": true,
            "style": {
                "stroke": "#ffffff"
            }
        },
        {
            "id": "1642673097017",
            "position": {
                "x": 1000,
                "y": -250
            },
            "data": {
                "node": {
                    "id": "1642673097017",
                    "type": "botResponse"
                },
                "responses": []
            },
            "type": "botResponse"
        },
        {
            "id": "e1642154236508S-1642673097017N",
            "source": "1642154236508",
            "target": "1642673097017",
            "targetHandle": "N",
            "sourceHandle": "S",
            "animated": true,
            "style": {
                "stroke": "#ffffff"
            }
        }
    ]
}