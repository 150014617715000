export const template = { 
  id: "template_002",
  name: "test2",
  template: [
    {
        "id": "1",
        "type": "start",
        "data": {
            "node": {
                "id": "1",
                "type": "start"
            }
        },
        "position": {
            "x": 0,
            "y": -200
        }
    },
    {
        "id": "1641998348028",
        "position": {
            "x": 200,
            "y": -200
        },
        "data": {
            "node": {
                "id": "1641998348028",
                "type": "botResponse"
            },
            "responses": []
        },
        "type": "botResponse"
    },
    {
        "id": "e1S-1641998348028N",
        "source": "1",
        "target": "1641998348028",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "e1641998348028S-1642085848163N",
        "source": "1641998348028",
        "target": "1642085848163",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "e1642085848163S-1642153939220N",
        "source": "1642085848163",
        "target": "1642153939220",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642153939490",
        "position": {
            "x": 600,
            "y": -150
        },
        "data": {
            "node": {
                "id": "1642153939490",
                "type": "splitAnswer"
            },
            "answer": "123"
        },
        "type": "splitAnswer"
    },
    {
        "id": "e1642085848163S-1642153939490N",
        "source": "1642085848163",
        "target": "1642153939490",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642085848163",
        "type": "splitQuestion",
        "position": {
            "x": 400,
            "y": -200
        },
        "data": {
            "node": {
                "id": "1642085848163",
                "type": "splitQuestion"
            },
            "question": "",
            "answers": [
                {
                    "answer": "123123123",
                    "answerKey": "1642153939220"
                },
                {
                    "answer": "123",
                    "answerKey": "1642153939490"
                }
            ]
        }
    },
    {
        "id": "1642153939220",
        "type": "splitAnswer",
        "position": {
            "x": 600,
            "y": -350
        },
        "data": {
            "node": {
                "id": "1642153939220",
                "type": "splitAnswer"
            },
            "answer": "123123123"
        }
    },
    {
        "id": "1642154236508",
        "position": {
            "x": 800,
            "y": -350
        },
        "data": {
            "node": {
                "id": "1642154236508",
                "type": "dataCollection"
            },
            "questions": []
        },
        "type": "dataCollection"
    },
    {
        "id": "e1642153939220S-1642154236508N",
        "source": "1642153939220",
        "target": "1642154236508",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642155463293",
        "position": {
            "x": 800,
            "y": -150
        },
        "data": {
            "node": {
                "id": "1642155463293",
                "type": "feedback"
            },
            "split": 4
        },
        "type": "feedback"
    },
    {
        "id": "e1642153939490S-1642155463293N",
        "source": "1642153939490",
        "target": "1642155463293",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642155463293P",
        "position": {
            "x": 1000,
            "y": -50
        },
        "data": {
            "node": {
                "id": "1642155463293P",
                "type": "socialMedia"
            },
            "socialMedias": []
        },
        "type": "socialMedia"
    },
    {
        "id": "e1642155463293S-1642155463293PN",
        "source": "1642155463293",
        "target": "1642155463293P",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642155463293U",
        "position": {
            "x": 1000,
            "y": -250
        },
        "data": {
            "node": {
                "id": "1642155463293U",
                "type": "userFeedback"
            }
        },
        "type": "userFeedback"
    },
    {
        "id": "e1642155463293S-1642155463293UN",
        "source": "1642155463293",
        "target": "1642155463293U",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642673097017",
        "position": {
            "x": 1000,
            "y": -350
        },
        "data": {
            "node": {
                "id": "1642673097017",
                "type": "botResponse"
            },
            "responses": []
        },
        "type": "botResponse"
    },
    {
        "id": "e1642154236508S-1642673097017N",
        "source": "1642154236508",
        "target": "1642673097017",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "e1642673097017S-1642680422210N",
        "source": "1642673097017",
        "target": "1642680422210",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642680422210",
        "type": "splitQuestion",
        "position": {
            "x": 1200,
            "y": -800
        },
        "data": {
            "node": {
                "id": "1642680422210",
                "type": "splitQuestion"
            },
            "question": "",
            "answers": [
                {
                    "answer": "",
                    "answerKey": "1642680424629"
                },
                {
                    "answer": "",
                    "answerKey": "1642680425438"
                },
                {
                    "answer": "",
                    "answerKey": "1642680426243"
                },
                {
                    "answer": "",
                    "answerKey": "1642680427015"
                },
                {
                    "answer": "",
                    "answerKey": "1642680428856"
                },
                {
                    "answer": "",
                    "answerKey": "1642680430319"
                }
            ]
        }
    },
    {
        "id": "1642680424629",
        "position": {
            "x": 1400,
            "y": -1050
        },
        "data": {
            "node": {
                "id": "1642680424629",
                "type": "splitAnswer"
            },
            "answer": ""
        },
        "type": "splitAnswer"
    },
    {
        "id": "e1642680422210S-1642680424629N",
        "source": "1642680422210",
        "target": "1642680424629",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642680425438",
        "position": {
            "x": 1400,
            "y": -950
        },
        "data": {
            "node": {
                "id": "1642680425438",
                "type": "splitAnswer"
            },
            "answer": ""
        },
        "type": "splitAnswer"
    },
    {
        "id": "e1642680422210S-1642680425438N",
        "source": "1642680422210",
        "target": "1642680425438",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642680426243",
        "position": {
            "x": 1400,
            "y": -850
        },
        "data": {
            "node": {
                "id": "1642680426243",
                "type": "splitAnswer"
            },
            "answer": ""
        },
        "type": "splitAnswer"
    },
    {
        "id": "e1642680422210S-1642680426243N",
        "source": "1642680422210",
        "target": "1642680426243",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642680427015",
        "position": {
            "x": 1400,
            "y": -750
        },
        "data": {
            "node": {
                "id": "1642680427015",
                "type": "splitAnswer"
            },
            "answer": ""
        },
        "type": "splitAnswer"
    },
    {
        "id": "e1642680422210S-1642680427015N",
        "source": "1642680422210",
        "target": "1642680427015",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642680428856",
        "position": {
            "x": 1400,
            "y": -650
        },
        "data": {
            "node": {
                "id": "1642680428856",
                "type": "splitAnswer"
            },
            "answer": ""
        },
        "type": "splitAnswer"
    },
    {
        "id": "e1642680422210S-1642680428856N",
        "source": "1642680422210",
        "target": "1642680428856",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642680430319",
        "position": {
            "x": 1400,
            "y": -550
        },
        "data": {
            "node": {
                "id": "1642680430319",
                "type": "splitAnswer"
            },
            "answer": ""
        },
        "type": "splitAnswer"
    },
    {
        "id": "e1642680422210S-1642680430319N",
        "source": "1642680422210",
        "target": "1642680430319",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "e1642155463293US-1642680440350N",
        "source": "1642155463293U",
        "target": "1642680440350",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642680440350",
        "type": "splitQuestion",
        "position": {
            "x": 1200,
            "y": -250
        },
        "data": {
            "node": {
                "id": "1642680440350",
                "type": "splitQuestion"
            },
            "question": "",
            "answers": [
                {
                    "answer": "",
                    "answerKey": "1642680446234"
                },
                {
                    "answer": "",
                    "answerKey": "1642680446737"
                },
                {
                    "answer": "",
                    "answerKey": "1642680447284"
                },
                {
                    "answer": "",
                    "answerKey": "1642680448111"
                },
                {
                    "answer": "",
                    "answerKey": "1642680448755"
                }
            ]
        }
    },
    {
        "id": "1642680446234",
        "position": {
            "x": 1400,
            "y": -450
        },
        "data": {
            "node": {
                "id": "1642680446234",
                "type": "splitAnswer"
            },
            "answer": ""
        },
        "type": "splitAnswer"
    },
    {
        "id": "e1642680440350S-1642680446234N",
        "source": "1642680440350",
        "target": "1642680446234",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642680446737",
        "position": {
            "x": 1400,
            "y": -350
        },
        "data": {
            "node": {
                "id": "1642680446737",
                "type": "splitAnswer"
            },
            "answer": ""
        },
        "type": "splitAnswer"
    },
    {
        "id": "e1642680440350S-1642680446737N",
        "source": "1642680440350",
        "target": "1642680446737",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642680447284",
        "position": {
            "x": 1400,
            "y": -250
        },
        "data": {
            "node": {
                "id": "1642680447284",
                "type": "splitAnswer"
            },
            "answer": ""
        },
        "type": "splitAnswer"
    },
    {
        "id": "e1642680440350S-1642680447284N",
        "source": "1642680440350",
        "target": "1642680447284",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642680448111",
        "position": {
            "x": 1400,
            "y": -150
        },
        "data": {
            "node": {
                "id": "1642680448111",
                "type": "splitAnswer"
            },
            "answer": ""
        },
        "type": "splitAnswer"
    },
    {
        "id": "e1642680440350S-1642680448111N",
        "source": "1642680440350",
        "target": "1642680448111",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642680448755",
        "position": {
            "x": 1400,
            "y": -50
        },
        "data": {
            "node": {
                "id": "1642680448755",
                "type": "splitAnswer"
            },
            "answer": ""
        },
        "type": "splitAnswer"
    },
    {
        "id": "e1642680440350S-1642680448755N",
        "source": "1642680440350",
        "target": "1642680448755",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642693352530",
        "position": {
            "x": 1600,
            "y": -1050
        },
        "data": {
            "node": {
                "id": "1642693352530",
                "type": "feedback"
            },
            "split": 4
        },
        "type": "feedback"
    },
    {
        "id": "e1642680424629S-1642693352530N",
        "source": "1642680424629",
        "target": "1642693352530",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642693352530P",
        "position": {
            "x": 1800,
            "y": -950
        },
        "data": {
            "node": {
                "id": "1642693352530P",
                "type": "socialMedia"
            },
            "socialMedias": []
        },
        "type": "socialMedia"
    },
    {
        "id": "e1642693352530S-1642693352530PN",
        "source": "1642693352530",
        "target": "1642693352530P",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642693352530U",
        "position": {
            "x": 1800,
            "y": -1150
        },
        "data": {
            "node": {
                "id": "1642693352530U",
                "type": "userFeedback"
            }
        },
        "type": "userFeedback"
    },
    {
        "id": "e1642693352530S-1642693352530UN",
        "source": "1642693352530",
        "target": "1642693352530U",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642693361707",
        "position": {
            "x": 1600,
            "y": -750
        },
        "data": {
            "node": {
                "id": "1642693361707",
                "type": "feedback"
            },
            "split": 4
        },
        "type": "feedback"
    },
    {
        "id": "e1642680427015S-1642693361707N",
        "source": "1642680427015",
        "target": "1642693361707",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642693361707P",
        "position": {
            "x": 1800,
            "y": -650
        },
        "data": {
            "node": {
                "id": "1642693361707P",
                "type": "socialMedia"
            },
            "socialMedias": []
        },
        "type": "socialMedia"
    },
    {
        "id": "e1642693361707S-1642693361707PN",
        "source": "1642693361707",
        "target": "1642693361707P",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642693361707U",
        "position": {
            "x": 1800,
            "y": -850
        },
        "data": {
            "node": {
                "id": "1642693361707U",
                "type": "userFeedback"
            }
        },
        "type": "userFeedback"
    },
    {
        "id": "e1642693361707S-1642693361707UN",
        "source": "1642693361707",
        "target": "1642693361707U",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642693365640",
        "position": {
            "x": 1600,
            "y": -350
        },
        "data": {
            "node": {
                "id": "1642693365640",
                "type": "feedback"
            },
            "split": 4
        },
        "type": "feedback"
    },
    {
        "id": "e1642680446737S-1642693365640N",
        "source": "1642680446737",
        "target": "1642693365640",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642693365640P",
        "position": {
            "x": 1800,
            "y": -250
        },
        "data": {
            "node": {
                "id": "1642693365640P",
                "type": "socialMedia"
            },
            "socialMedias": []
        },
        "type": "socialMedia"
    },
    {
        "id": "e1642693365640S-1642693365640PN",
        "source": "1642693365640",
        "target": "1642693365640P",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642693365640U",
        "position": {
            "x": 1800,
            "y": -450
        },
        "data": {
            "node": {
                "id": "1642693365640U",
                "type": "userFeedback"
            }
        },
        "type": "userFeedback"
    },
    {
        "id": "e1642693365640S-1642693365640UN",
        "source": "1642693365640",
        "target": "1642693365640U",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642693370067",
        "position": {
            "x": 1600,
            "y": -250
        },
        "data": {
            "node": {
                "id": "1642693370067",
                "type": "feedback"
            },
            "split": 4
        },
        "type": "feedback"
    },
    {
        "id": "e1642680447284S-1642693370067N",
        "source": "1642680447284",
        "target": "1642693370067",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642693370067P",
        "position": {
            "x": 1800,
            "y": -150
        },
        "data": {
            "node": {
                "id": "1642693370067P",
                "type": "socialMedia"
            },
            "socialMedias": []
        },
        "type": "socialMedia"
    },
    {
        "id": "e1642693370067S-1642693370067PN",
        "source": "1642693370067",
        "target": "1642693370067P",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    },
    {
        "id": "1642693370067U",
        "position": {
            "x": 1800,
            "y": -350
        },
        "data": {
            "node": {
                "id": "1642693370067U",
                "type": "userFeedback"
            }
        },
        "type": "userFeedback"
    },
    {
        "id": "e1642693370067S-1642693370067UN",
        "source": "1642693370067",
        "target": "1642693370067U",
        "targetHandle": "N",
        "sourceHandle": "S",
        "animated": true,
        "style": {
            "stroke": "#ffffff"
        }
    }
]}