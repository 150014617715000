import { Divider, Theme, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react"

const useStyles = makeStyles<Theme>(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
    arrow: {
        color: "white"
    },
    font: {
        color:"red"
    }
}))

const TooltipContent = (data: any) => {
    const classes = useStyles()
    return(
    <React.Fragment>
        <p className={classes.font}>{data.name}</p>
        <Divider/>
        <p>nodespezifische informationen</p>
    </React.Fragment>
    )
}

export const NodeTooltip = ({data, children}: {data:any, children: any}) => {
    const classes = useStyles()
    return (
        <Tooltip title={TooltipContent(data)} arrow={true} placement="top" classes={{tooltip: classes.tooltip, arrow: classes.arrow}}>
            {children}
        </Tooltip>
    )
}