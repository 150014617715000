import React from "react"
import { CancelButton, IEmailSettings, IState, NavigationProps } from "../WebformWizard"
import Button from "components/Button/Button"
import { useHistory, useLocation } from "react-router-dom"
import webformApi from "models/webform"
import { FormValues } from "./FirstStep"
import { Typography, Grid, Fa } from "styled/muiComponents"
import { Divider } from "styled/muiComponents"
import { useTranslation } from "react-i18next"
import { IStep } from "routes/app/stock/webforms/wizard/WebformWizard"
import { useAlert } from "hooks/useAlert"
import Pixi from "components/Pixi"
import { useStyles } from "./FirstStep"
import Typist from "react-typist"
import WebformTemplatesViewDialog from "dialogs/WebformTemplateView"
import { useDialog } from 'hooks/useDialog';
import { Block, BlockContent, BlockTitle } from "components/Block"


type Props = {
    result: IState
    navigationProps: NavigationProps
    basicInfo: FormValues | null
    steps: IStep[]
    emailSettings: IEmailSettings,
    onPrevious: () => void
    setState: React.Dispatch<React.SetStateAction<any>>
}

const FinalStep = ({ emailSettings, result, navigationProps, basicInfo, steps, onPrevious, setState }: Props) => {

    const history = useHistory()
    const location = useLocation()
    const { t } = useTranslation()
    const classes = useStyles({setup: false})
    const { alert } = useAlert()
    const { dialog } = useDialog()

    const handleFinish = async () => {
        if (basicInfo) {
            webformApi.createWebformFromWebformWizard(basicInfo, result, emailSettings)
            .then((response) => {alert.success(t("Webform wurde erfolgreich gespeichert!")); history.push(`${location.pathname}/${response.id}/success`, { state: response })})
            .catch(() => alert.error(t("Webform konnte nicht gespeichert werden!")))
            
        }
    }

    // const handleContinue = async () => {
    //     if (basicInfo) {
    //         webformApi.createWebformFromWebformWizard(basicInfo, result, emailSettings)
    //         .then(() => {alert.success(t("Webform wurde erfolgreich gespeichert!")); history.push("/app/stock/qrforms/create")})
    //         .catch(() => alert.error(t("Webform konnte nicht gespeichert werden!")))
    //     }
    // }

    const handleJumpTo = (index: number) => {
        setState({...result, activeStep: result.activeStep - index})
    }

    const handleView = (step:number) => {
        dialog.show({
            component: WebformTemplatesViewDialog,
            props: {
                webform: {...result, step},
                editorView: true
            }
        })
    }

    return (
        <>
            <Grid container item direction="column" className={classes.root}>
                <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                        <Grid container direction="row" justify="flex-start" className={classes.form}>
                            <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                <Grid item sm={4}>
                                    <div style={{ maxWidth: "150px" }}>
                                        <Pixi size="full" pose="waiting"></Pixi>
                                    </div>
                                </Grid>
                                <Grid item sm={8}>
                                    <div className={classes.headerDescription} >
                                    <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                        <Typography variant="h5" className={classes.pixiSpeech}>
                                            {t('Klasse, schon bist du fertig. Klicke auf "Weiter mit Print-Medium", um einen Aufsteller, Visitenkarten oder Flyer zu gestalten. So bekommst du ganz schnell neue Abonnenten.')}
                                        </Typography>
                                    </Typist>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item container className={classes.formContent}>
                                <Grid item xs={12}>
                                    {basicInfo ?
                                         <Block>
                                            <BlockTitle>
                                                <Typography variant="h2">{t("Formular- und Versandeinstellungen")}</Typography>
                                            </BlockTitle>
                                            <BlockContent>
                                                <Grid item container direction="column" sm={12}>
                                                    <Grid item container alignItems="center" mt={1} mb={1}>
                                                        <Fa icon={["fal", "memo-pad"]} fixedWidth mr={2}/><span>{basicInfo.name}</span>
                                                    </Grid>
                                                    
                                                    <Grid item container alignItems="center" mt={1} mb={1}>
                                                        <Fa icon={["fal", "user"]} fixedWidth mr={2}/><span>{basicInfo.senderName}</span>
                                                    </Grid>
                                                    
                                                    <Grid item container alignItems="center" mt={1} mb={1}>
                                                        <Fa icon={["fal", "envelope"]} fixedWidth mr={2}/><span>{basicInfo.senderEmailLocalPart}@{basicInfo.senderEmailDomain?.domain}</span>
                                                    </Grid>
                                                </Grid>
                                                
                                            </BlockContent>
                                        </Block>
                                        :
                                        null}


                                        <Typography variant="h3" mb={2} mt={3}>{t("Anmeldestrecke")}</Typography>
                                        {steps.filter(e => e.label !== "Design Auswahl" && e.label !== "Abschließen").map((step: IStep, idx: number) =>
                                            <div key={idx}>
                                                <Grid container direction="row" justify="space-between" alignItems="center" >
                                                    <Grid item mt={1} mb={1}>
                                                        {step.label}
                                                    </Grid>
                                                    <Grid item mt={1} mb={1}>
                                                        <Button color="viewButton" variant="action" disableElevation size="small" onClick={() => handleView(idx)} tooltip={t("Ansehen")} tooltipProps={{ placement: 'left' }}>
                                                            <Fa icon={["fas", "eye"]} />
                                                        </Button>
                                                        <Button color="editButton" variant="action" disableElevation size="small" onClick={() => handleJumpTo(5 - idx)} tooltip={t("Bearbeiten")} tooltipProps={{ placement: 'left' }}>
                                                            <Fa icon={["fas", "wrench"]} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </div>
                                        )}
                                    
                                </Grid>
                            </Grid>
                            
                            <Grid item sm={12} container justify="space-between" className={classes.navigation}>
                                <Grid item>
                                    <CancelButton destination="webforms"/>
                                    <Button onClick={onPrevious} variant="contained" disableElevation color="success" size="small">
                                        <Fa icon={["fas", "chevron-left"]} mr={1}></Fa>
                                        {t("Zurück")}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="success" onClick={handleFinish} size="small">
                                        <Fa icon={["fas", "check"]} mr={1}/>
                                        {t("Speichern und beenden")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid> 
                </Grid>
            </Grid>
        </>
    )
}

export default FinalStep