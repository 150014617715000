import React from 'react'
import App from './App'
import Dashboard from './Dashboard'
import Binding from './Binding'
import { Recipients, Recipient } from './stock/recipients'
import Webforms from './stock/webforms/Webforms'
import WebformWizard from './stock/webforms/wizard/WebformWizard'
import { Route, Switch, Redirect } from 'react-router-dom'
import QRCodeOptions from './stock/webforms/QRCodeOptions'
import Account from "./preferences/Account"
import PDFWizard from "./stock/qrStoreDisplay/wizard/StoreDisplayWizard"
import PDFforms from "./stock/qrStoreDisplay/PDFforms"
import ViewPDF from "./stock/qrStoreDisplay/ViewPDF"
import WebformTemplateEdit from './stock/webforms/TemplateEdit'

import Chats from './chats/Chats'
import ChatBuilder from './chats/chatbuilder/ChatBuilder'
import ChatPreview from './chats/ChatPreview'
import RatingsWizard, { HtmlEditor } from './stock/ratings/HtmlBuildWizard/HtmlWizard'
import Feedback from "./stock/ratings/feedback/Feedback"
import Ratings from './stock/ratings/Ratings'
import RatingQRCode from './stock/ratings/RatingQRCode'
import EditHtmlTemplate from './stock/ratings/HtmlBuildWizard/components/EditHtmlTemplate'

const AppRouter = () => {
    return (
        <App >
            <Switch>
                <Route exact path="/app">
                    <Redirect to="/app/dashboard" />
                </Route>

                <Route path="/app/dashboard" component={Dashboard} />

                <Route path="/app/stock/recipients/:id" component={Recipient} />
                <Route path="/app/stock/recipients" component={Recipients} />

                <Route path="/app/stock/webforms/:id/qrcode" component={QRCodeOptions} />
                <Route path="/app/stock/webforms/create/:id/success" component={QRCodeOptions} />
                <Route path="/app/stock/webforms/create" component={WebformWizard} />
                <Route path="/app/stock/webforms/edit/:webformId/:templateKey" component={WebformTemplateEdit} />
                <Route path="/app/stock/Webforms" component={Webforms} />

                <Route path="/app/stock/QRForms/:id/view" component={ViewPDF} />
                <Route path="/app/stock/QRforms/create" component={PDFWizard} />
                <Route path="/app/stock/QRforms/:id/" component={PDFWizard} />
                <Route path="/app/stock/QRforms" component={PDFforms} />

                <Route path="/app/stock/chats/builder/:id" component={ChatBuilder}/>
                <Route path="/app/stock/chats/builder" component={ChatBuilder} />
                <Route path="/app/stock/chats" component={Chats}/>

                <Route path="/app/stock/ratings/edit/:id/:templateKey" component={EditHtmlTemplate}/>
                <Route path="/app/stock/ratings/create/:id/success" component={RatingQRCode}/>
                <Route path="/app/stock/ratings/:id/qrcode" component={RatingQRCode}/>
                <Route path="/app/stock/ratings/create" component={HtmlEditor} />
                <Route path="/app/stock/ratings" component={Ratings} />

                <Route path="/app/stock/feedback/:id" component={Feedback} />
                {/* <Route path="app/stock/feedback" component={} /> */}
                
                

                <Route path="/app/bindings" component={Binding} />
                <Route path="/app/preferences" component={Account} />


            </Switch>
        </App>
    )
}

export default AppRouter