import React from "react"
import { Grid } from "@material-ui/core"
import { Typography, Fa } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { useTranslation } from "react-i18next"
import Button from "components/Button/Button"
import { useHistory } from "react-router-dom" 
import useSession from "hooks/useSession"

type Props = {
    classes: any
}

const SetupDone = ({ classes }: Props) => {
    const { t } = useTranslation()
    const history = useHistory()
    const {restore} = useSession()

    const handleContinue = () => {
        restore().then(() => history.push("/app/stock/Webforms/create", {setup: true}))
    }

    const handleSkip = () => {
        restore().then(() => history.push("/app/dashboard"))
    }

    return (
            <Grid container item direction="column" className={classes.root}>
                <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                    <Grid className={classes.form} >
                        <Grid container direction="column" justify="flex-start">
                            <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                <Grid item sm={4}>
                                    <div style={{ maxWidth: "150px" }}>
                                        <Pixi size="full" pose="waiting"></Pixi>
                                    </div>
                                </Grid>
                                <Grid item sm={8}>
                                    <div className={classes.headerDescription} >
                                    <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                        <Typography variant="h5" className={classes.pixiSpeech}>
                                            {t("Super, das Gröbste hast du nun geschafft. Ab sofort werde ich das Impressum für dich überall dort einpflegen wo es notwendig ist.")}
                                            <br /><br />
                                            {t("Du kannst nun direkt loslegen und dein erstes Anmeldeformular erstellen.")}
                                        </Typography>
                                    </Typist>
                                    </div>
                                </Grid>
                            </Grid>
                            
                            <Grid item container justify="space-between" alignItems="center" className={classes.navigation} style={{paddingTop: "32px"}}>
                                <Button onClick={handleSkip} variant="contained" disableElevation color="success" size="large">{("Überspringen")} <Fa icon={["fas", "chevron-double-right"]} ml={1}></Fa></Button>
                                <Button onClick={handleContinue} variant="contained" disableElevation color="success" size="large">{t("Jetzt durchstarten")} <Fa icon={["fas", "rocket-launch"]} ml={1}></Fa></Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default SetupDone
