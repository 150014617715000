import React, { useEffect, useState } from "react"
import Typist from "react-typist"
import Pixi from "components/Pixi"
import { Block, BlockContent, BlockTitle } from "components/Block"
import { Box, Grid, makeStyles, Theme } from '@material-ui/core'
import { Typography } from 'styled/muiComponents'
import { useHistory, useParams } from "react-router-dom"
import ratings, { IRating } from "models/ratings"
import { useTranslation } from "react-i18next"
import isImprintValid from 'constants/isImprintValid'
import LoadingAnimation from "components/LoadingAnimation"
import { Fa } from 'styled/muiComponents'
import Button from "components/Button/Button"
import { QRCode } from 'react-qrcode-logo'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '../../../../components/Button/Tooltip';
import { delay } from "constants/delay"

const useStyles = makeStyles<Theme>(theme => ({
    root: {
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.companyDark.light,
        overflow: "auto",
        //padding: theme.spacing(1),
        boxSizing: "border-box",
        "& p": {
            fontWeight: "300",
            "& strong": {
                fontWeight: "500"
            }
        }
    },
    form: {
        width: "100%",
        margin: "auto",
        maxWidth: "600px",
        // padding: theme.spacing(4),
        backgroundColor: theme.palette.lightGrey.main,
        borderRadius: theme.spacing(0.5),
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        boxSizing: "border-box",
        overflow: "hidden",
        border: "1px solid #ffffff",
        marginBottom: theme.spacing(2)
    },
    formHeader: {
        padding: theme.spacing(4),
        //backgroundColor: "#83ac1b2b",
        backgroundColor: "#E6ECEF",
    },
    formContent: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    formFooter: {
        //   paddingLeft: theme.spacing(4),
        //   paddingRight: theme.spacing(4),
    },
    formNote: {
        width: "100%",
        maxWidth: "600px",
        color: "#ffffff75",
        paddingTop: theme.spacing(2)
    },
    headerDescription: {
        padding: theme.spacing(2),
        fontSize: "0.8rem",
        //backgroundColor: "#00000015",
        backgroundColor: "#ffffff90",
        borderRadius: "5px",
        position: "relative",
        '&:after': {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            width: 0,
            height: 0,
            border: "18px solid transparent",
            //borderRightColor: "#00000015",
            borderRightColor: "#ffffff90",
            borderLeft: 0,
            borderBottom: 0,
            marginTop: "-9px",
            marginLeft: "-18px",
        },
    },
    LinkToForm: {
        padding: theme.spacing(2),
    },
    LinkToFormWrapper: {
        borderRadius: "5px",
        backgroundColor: "#ebebeb",
        margin: "16px 0",
        "& a": {
            textDecoration: "none",
            wordBreak: "break-all"
        }
    },
    exampleImage: {
        backgroundImage: "url(/assets/images/qr-code-simple-blue.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    qrcodeBanner: {
        backgroundColor: "#E6ECEF",
    },
    qrCodeWrapper: {
        borderRadius: "4px",
        width: "auto",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        //		padding: "4px 4px 0",
        overflow: "hidden"
    },
    qrGroupButton: {
        margin: "0 8px 8px"
    }
}))

type IRouteParams = {
    id: string
}

const RatingQRCode = () => {
    const history = useHistory()
    const params = useParams<IRouteParams>()
    const { t } = useTranslation()
    const classes = useStyles()

    const [url, setUrl] = useState<{qr:string, query: string} | null>(null)
    const [validImprint, setValidImprint] = useState<boolean>(false)
    const [rating, setRating] = useState<IRating | null>(null)

    useEffect(() => {
        const fetchRating = async () => {
            const response = await ratings.get(parseInt(params.id))
            const responseUrl = await ratings.getRatingUrl(response.hid)
            setUrl(responseUrl)
            setRating(response)
        }

        const checkImprint = async () => {
			const result = await isImprintValid()
			setValidImprint(result)
		}

        fetchRating(); checkImprint();
    },[])

    const handleDownload = () => {
        const link = document.createElement('a')
        link.download = `${rating!.name} QRCode`
        const canvas = document.getElementById('react-qrcode-logo') as HTMLCanvasElement
        link.href = canvas.toDataURL()
        delay(1000).then(() => link.click())
    }

    if(!url || !rating){
        return <LoadingAnimation/>
    }

    return (
        <Grid container item direction="column" className={classes.root}>
            <Grid container item direction="column" justify="center" alignItems="center" style={{ margin: "auto", paddingTop: "32px", paddingBottom: "32px" }}>
                {validImprint ?
                    <>
                        <Grid container direction="row" justify="flex-start" className={classes.form}>
                            <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                <Grid item sm={4}>
                                    <div style={{ maxWidth: "150px" }}>
                                        <Pixi size="full" pose="waiting"></Pixi>
                                    </div>
                                </Grid>
                                <Grid item sm={8}>
                                    <div className={classes.headerDescription} >
                                        <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                            <Typography variant="h5" className={classes.pixiSpeech}>
                                                {t("Verwende den QR-Code und die Links unten, um Bewertungen zu generieren. Verwende den Online-Link für den Einsatz auf Webseiten, E-Mails usw. und den POS-Link für den Einsatz vor Ort.")}
                                            </Typography>
                                        </Typist>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item container className={classes.formContent}>
                                <Grid item container xs={12}>
                                    <Grid item sm={8}>
                                        <Typography variant="h2" mb={1}>
                                            <strong>{t("QR-Code")} </strong>
                                        </Typography>
                                        <Typography mb={2}>
                                            {t("Das ist der QR-Code für deine Bewertungsstrecke. Du kannst ihn jetzt ausdrucken und sofort mit dem Sammeln von Bewertungen beginnen!")}
                                        </Typography>
                                        {/* <Typography>
                                            <strong>{t("Tipp")}: </strong>
                                            {t("Erstelle ein atraktives Printmedium, damit dein QR-Code besser wahrgenommen wird. Zum Beispiel einen Aufsteller, Flyer, Visitenkarten oder Rubbelkarten-Gewinnspiele.")}<br /> */}
                                            {/* <a href="#" onClick={handleGoToDisplayWizard}>{t("Jetzt Printmedium gestalten.")}</a> */}
                                        {/* </Typography> */}
                                    </Grid>
                                    <Grid item sm={4} container justify='flex-end'>
                                        <Box boxShadow={1} className={classes.qrCodeWrapper}>
                                            <a href={`${url.qr}`} target="_blank" rel="noopener noreferrer">
                                                <QRCode value={`${url.qr}/t`} size={140} />
                                            </a>
                                            <Box p={1} pt={0}>
                                                <ButtonGroup size="small" fullWidth>
                                                    <Tooltip title={t("Runterladen")!} placement="bottom" arrow>
                                                        <Button variant="contained" disableElevation color="success" size="small"  onClick={handleDownload}>
                                                            <Fa icon={["fas", "download"]} />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title={t("Drucken")!} placement="bottom" arrow>
                                                        <Button variant="contained" disableElevation color="success" size="small"  onClick={() => console.warn("not implemented")}>
                                                            <Fa icon={["fas", "print"]}/>
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title={t("QR Gestalten")!} placement="bottom" arrow>
                                                        <Button variant="contained" disableElevation color="success" size="small"  onClick={() => console.warn("not implemented")}>
                                                            <Fa icon={["fas", "paintbrush"]} />
                                                        </Button>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} style={{ marginTop: "32px", paddingTop: "32px", borderTop: "1px solid #ced1d2" }}>
                                    <h2 style={{ marginBottom: "8px" }}>{t("Online-Version")}</h2>
                                    <Typography>
                                        {t("Verwende diesen Link, um direkt zur Vorqualifizierungsseite deiner Bewertungsstrecke zu gelangen. Dieser Link ist ideal, um online Bewertungen zu sammeln.")}
                                    </Typography>
                                    <Grid container direction='row' className={classes.LinkToFormWrapper}>
                                        <Box className={classes.LinkToForm}><a href={url.query} target="_blank" rel="noopener noreferrer">{url.query} <Fa icon={["fal", "external-link"]} style={{ color: "#83ac1b" }} /></a></Box>
                                    </Grid>
                                    <Button variant="contained" disableElevation color="success" size="small" onClick={() => {navigator.clipboard.writeText(url.query)}} >
                                        <Fa icon={["fas", "copy"]} mr={1} />
                                        {t("URL kopieren")}
                                    </Button>
                                </Grid>
                                <Grid item container xs={12} style={{ marginTop: "32px", paddingTop: "32px", borderTop: "1px solid #ced1d2" }}>
                                    <h2 style={{ marginBottom: "8px" }}>{t("POS-Version")}</h2>
                                    <Typography>
                                        {t("Dieser Link führt dich zur QR-Code Landingpage deiner Bewertungsstrecke. Dieser Link eignet sich besonders für den Einsatz vor Ort. Öffne diese URL z.B. auf deinem Smartphone, damit deine Kunden den QR-Code scannen können.")}
                                    </Typography>
                                    <Grid container direction='row' className={classes.LinkToFormWrapper}>
                                        <Box className={classes.LinkToForm}><a href={url.qr} target="_blank" rel="noopener noreferrer">{url.qr} <Fa icon={["fal", "external-link"]} style={{ color: "#83ac1b" }} /></a></Box>
                                    </Grid>
                                    <Button variant="contained" disableElevation color="success" size="small" onClick={() => {navigator.clipboard.writeText(url.qr)}} >
                                        <Fa icon={["fas", "copy"]} mr={1} />
                                        {t("URL kopieren")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid container direction="row" justify="flex-start" className={classes.form}>
                            <Grid item sm={12} container justify="flex-start" className={classes.qrcodeBanner}>
                                <Grid item sm={4} className={classes.exampleImage}>

                                </Grid>
                                <Grid item sm={8} style={{ padding: "20px" }}>
                                    <Typography variant="h2" mb={1}>
                                        <strong>{t("Verbreite deinen QR-Code")}</strong>
                                    </Typography>
                                    <Typography mb={2}>
                                        {t("Erstelle Aufsteller, Flyer, Visitenkarten oder Rubbelkarten mit deinem QR-Code drauf, um noch schneller neue Kontaktaderssen zu gewinnen.")}
                                    </Typography>
                                    <Button variant="contained" disableElevation color="success" size="small" onClick={handleGoToDisplayWizard}>
                                        <Fa icon={["fas", "sign-in"]} mr={1} />
                                        {t("Jetzt gestalten")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </>
                    :
                    <>
                        <div style={{ width: "100%", maxWidth: "600px" }}>
                            <Block>
                                <BlockTitle>
                                    <Typography variant="h2">{t("QR-Code")}</Typography>
                                </BlockTitle>
                                <BlockContent>
                                    <Typography style={{ marginBottom: "16px" }}>
                                        {t("Der QR Code zum Anmeldeformular ist nicht einsehbar, da dein Impressum noch nicht vollständig ist. Dein Impressum kannst du unter Einstellungen bearbeiten.")}
                                    </Typography>
                                    <Button variant="contained" disableElevation color="success" size="small" href="/app/preferences">
                                        <Fa icon={["fas", "pencil"]} mr={1} />
                                        {t("Jetzt bearbeiten")}
                                    </Button>
                                </BlockContent>
                            </Block>
                        </div>
                    </>}
            </Grid>
        </Grid>
    )
}

export default RatingQRCode