import Button from "components/Button/Button"
import React from "react"
import { Grid, Paper, Tooltip } from "@material-ui/core"
import { useChatState } from "../../ChatContext"
import { Fa, FaButton, FormikInput } from "styled/muiComponents"
import { getOutgoers, Node, Edge } from "react-flow-renderer"
import DeleteNodeDialog from "../deleteDialog"
import { useDialog } from "hooks/useDialog"
import { useForm } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from "react-i18next"


const SplitQuestionNodeOptions = ({ node, classes }: any) => {
    const { t } = useTranslation()
    const { state } = useChatState()
    const { dialog } = useDialog()
    const mutators = useForm().mutators
    const currentAnswers = useForm().getState().values.answers
    
    const handleDeleteAnswer = (fields: any, index: number) => {
        const nodeID = currentAnswers[index].answerKey
        const answerNode = state.elements.filter((ele: Node | Edge) => ele.id === nodeID)[0]
        const outgoers = answerNode ? getOutgoers(answerNode, state.elements) : []
        if (outgoers.length === 0) {
            fields.remove(index)
        } else {
            dialog.show<any>({
                component: DeleteNodeDialog,
                props: {
                }
            })
                .then((result: any) => {
                    if (result.success) {
                        fields.remove(index)
                    }
                })
        }
    }

    return (
        <PerfectScrollbar className={classes.settingsContainer} style={{borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px", flexDirection: "column"}}>
            <Grid container direction="column" alignItems="center" >
                <Grid container direction="column" alignItems="center">
                    <div className={classes.seperator}></div>
                    <FormikInput
                        type="slider"
                        name="delay"
                        max={10}
                        min={0}
                        valueLabelDisplay="auto"
                        label="s delay"
                        variant="delay"
                    />
                    <div className={classes.seperator}></div>
                </Grid>
                <Grid item container>
                    <Paper elevation={2} className={classes.settingsGroupPaper} style={{marginBottom: "16px"}}>
                        <Grid container item direction="column" justify="center" alignItems="stretch" className={classes.settingsGroup}>
                            <Grid item style={{ width: "100%" }}>
                                <label className="FormikFormLabel">{t("Text")}</label>
                                <FormikInput
                                    type="entityText"
                                    name="question"
                                    typeParams={state.fields}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                    <FieldArray name="answers" subscription={{ submitting: true }}>
                        {({ fields }) =>
                            fields.map((name: string, index: number) => {
                                return (
                                    <Paper elevation={2} className={classes.settingsGroupPaper} key={name} style={{marginBottom: "16px"}}>
                                        <Grid container item className={classes.header}  wrap="nowrap" direction="row" alignItems="center" justify="space-between">
                                            <Grid item>
                                                {t("Antwort / Abzweigung #")}{index+1}
                                            </Grid>
                                            <Grid item className={classes.controls}>
                                                <Tooltip title={t("Löschen")!}>
                                                    <FaButton size="small" className={classes.icon} onClick={() => handleDeleteAnswer(fields, index)}>
                                                        <Fa icon={["fal", "trash"]} fixedWidth size="xs"/>
                                                    </FaButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction="column" justify="center" alignItems="stretch" className={classes.settingsGroup}>
                                            <Grid item style={{ width: "100%" }}>
                                                <FormikInput
                                                    type="entityText"
                                                    name={`${name}.answer`}
                                                    //@ts-ignore
                                                    defaultValue={fields[index]?.answer ?? ""}
                                                    typeParams={state.fields}
                                                />
                                                <FormikInput
                                                    type="text"
                                                    name={`${name}.answerKey`}
                                                    style={{ display: "none" }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>                                    
                                )
                            })
                        }
                    </FieldArray>
                </Grid>
                <Grid item>
                    <Button startIcon={<Fa icon={["fal", "plus"]} size="xs" />} style={{marginBottom: "16px", marginTop: "16px"}} variant="contained" disableElevation color="success" size="large" onClick={() => mutators.push('answers', { answer: "", answerKey: `${Date.now()}` })}>{t("Antwort / Abzweigung hinzufügen")}</Button>
                </Grid>
            </Grid>
        </PerfectScrollbar>
    )
}

export default SplitQuestionNodeOptions