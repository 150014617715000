import { withCrud, CrudModel, BaseModel } from "./builder";
import { post, get, put } from 'api/crud'

export type ISubscription = {
    id: number,
    current_period_end: number,
    current_period_start: number,
    cancel_at_period_end: boolean,
    bundle_option: JSON,
    candidate?: JSON,
    amount_to_pay: number

}

export type IPricelistOption = {
    volume: number,
    type: string,
    price_per_month: number
}

export type tariffApi = CrudModel<ISubscription> & BaseModel

export default {
    name: "bundles",
    ...withCrud<any>("bundle_purchases"),


    cancelSubscription: () => put<string>('bundle_purchases/cancel_cornerstone_subscription'),

    purchaseSubscription: (type: string) => post<any>('bundle_purchases/purchase_cornerstone_subscription', {type}),

    customerPortal: () => get<{customer_portal_url: string}>('bundle_purchases/customer_portal'),

    current: () => get<ISubscription>('cornerstone_subscriptions/current'),

    pricelist: () => get<any>('cornerstone_subscriptions/pricelist'),

    setScanLimit: (limit: number) => post('cornerstone_subscriptions/scan_limit', {limit}),

    scanLimit: () => get<{limit: number}>('cornerstone_subscriptions/scan_limit'),

    scansCurrentPeriod: () => get<{scans: number}>('cornerstone_subscriptions/scans_current_period')

    // candidate: () => get<ISubscription>('cornerstone_subscription/candidate')
    
    

    // current
    // candidate
    // pricelist
    

}