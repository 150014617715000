import React, { useEffect, useState } from "react"
import { SetupNavigationProps, Navigation, ISetupInformation } from "../SetupWizard"
import { Form } from 'react-final-form'
import FormikInput from "components/formik/FormikInput"
import { Typography, Grid, Fa } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { useTranslation } from "react-i18next"
import InputAdornment from '@material-ui/core/InputAdornment';


type Props = {
    navigationProps: SetupNavigationProps
    classes: any
    handleChange: (event: any) => void
    setupInformation: ISetupInformation
}

const CompanyLegalForm = ({ navigationProps, classes, handleChange, setupInformation }: Props) => {
    const { t } = useTranslation()
    const [enableNext, setEnableNext] = useState<boolean>(false)


    const initialValues = {
          company_board: setupInformation.company_board,
          supervisory_board: setupInformation.supervisory_board
    }

    const handleSubmit = () => {
    }

    useEffect(() => {
        if (["GbR", "OHG", "KG", "GmbH", "GmbH & Co KG", "Andere", "Einzelunternehmen"].some(v => (setupInformation.legal_form).includes(v))) {
            if (setupInformation.company_board) {
                setEnableNext(true)
            } else {
                setEnableNext(false)
            }
        } else {
            // if (setupInformation.company_board && setupInformation.supervisory_board){
            if(setupInformation.company_board){
                setEnableNext(true)
            } else {
                setEnableNext(false)
            }
        }

    }, [setupInformation])
    const [hasCompanyBoardFocus, setCompanyBoardFocus] = useState(false);
    const [hasSupervisoryBoardFocus, setSupervisoryBoardFocus] = useState(false);
    const renderContent = () => {
        if (["GbR", "OHG", "KG", "GmbH", "GmbH & Co KG", "Andere", "Einzelunternehmen"].some(v => (setupInformation.legal_form).includes(v))) {
            return (
                <Form initialValues={initialValues} onSubmit={handleSubmit}
                    render={({ handleSubmit, submitting }) => (
                        <Grid container item direction="column" className={classes.root}>
                            <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                                <form className={classes.form} onSubmit={handleSubmit}>
                                    <Grid container direction="column" justify="flex-start">
                                        <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                            <Grid item sm={4}>
                                                <div style={{ maxWidth: "150px" }}>
                                                    <Pixi size="full" pose="waiting"></Pixi>
                                                </div>
                                            </Grid>
                                            <Grid item sm={8}>
                                                <div className={classes.headerDescription} >
                                                <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                                    <Typography variant="h5" className={classes.pixiSpeech}>
                                                        {t("Wie heißt der Geschäftsführer bzw. Vetretungsberechtigte deines Unternehmens?")}
                                                    </Typography>
                                                </Typist>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid item container className={classes.formContent}>
                                            <Grid item xs={12} mt={1}>
                                                <FormikInput
                                                    type="string"
                                                    value={setupInformation.company_board}
                                                    name="company_board"
                                                    label={t("Geschäftsführer")}
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    required
                                                    style={{marginTop: 0}}
                                                    InputProps={
                                                        {
                                                            onFocus:(() => setCompanyBoardFocus(true)),
                                                            onBlur:(() => setCompanyBoardFocus(false)),     
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    { hasCompanyBoardFocus ? <Fa icon={["fas", "chess-king"]}/> : <Fa icon={["fal", "chess-king"]}/>}
                                                                </InputAdornment>
                                                            ),
                                                        }
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormikInput
                                                    type="string"
                                                    value={setupInformation.supervisory_board}
                                                    name="supervisory_board"
                                                    label={t("Vetretungsberechtigter (wenn vorhanden)")}
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    //required
                                                    InputProps={
                                                        {
                                                            onFocus:(() => setSupervisoryBoardFocus(true)),
                                                            onBlur:(() => setSupervisoryBoardFocus(false)),    
                                                            endAdornment: (
                                                                <InputAdornment position="start">
                                                                    { hasSupervisoryBoardFocus ? <Fa icon={["fas", "crown"]}/> : <Fa icon={["fal", "crown"]}/>}
                                                                </InputAdornment>
                                                            ),
                                                        }
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item spacing={3} sm={12}>
                                        <Navigation  {...navigationProps} enableNext={!enableNext}/>
                                    </Grid>
                                </form>
                                <Grid item className={classes.formNote}>
                                    <Typography variant="body1" className={classes.requiredNotice}>
                                        <i>* {t("Pflichtfeld")}</i>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                />
            )
        } else if (["AG", "e. G."].some(v => (setupInformation.legal_form.includes(v)))) {
            return (
                <Form initialValues={initialValues} onSubmit={handleSubmit}
                    render={({ handleSubmit, submitting }) => (
                        <Grid container item direction="column" className={classes.root}>
                            <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                                <form className={classes.form} onSubmit={handleSubmit}>
                                    <Grid container direction="column" justify="flex-start">
                                        <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                            <Grid item sm={4}>
                                                <div style={{ maxWidth: "150px" }}>
                                                    <Pixi size="full" pose="waiting"></Pixi>
                                                </div>
                                            </Grid>
                                            <Grid item sm={8}>
                                                <div className={classes.headerDescription} >
                                                    <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                                        <Typography variant="h5" className={classes.pixiSpeech}>
                                                            {t("Wer sitzt im Vorstand und Aufsichtsrat?")}
                                                        </Typography>
                                                    </Typist>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid item container className={classes.formContent}>
                                            <Grid item sm={12}>
                                                <FormikInput
                                                    type="string"
                                                    value={setupInformation.company_board}
                                                    name="company_board"
                                                    label={t("Vorstand")}
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    required
                                                    InputProps={
                                                        {
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                <Fa icon={["fal", "chess-king"]}/>
                                                            </InputAdornment>
                                                        ),
                                                        }
                                                    }
                                                />
                                            </Grid>
                                            <Grid item sm={12}>
                                                <FormikInput
                                                    type="string"
                                                    value={setupInformation.supervisory_board}
                                                    name="supervisory_board"
                                                    label={t("Aufsichtsrat")}
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    required
                                                    InputProps={
                                                        {
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                <Fa icon={["fal", "crown"]}/>
                                                            </InputAdornment>
                                                        ),
                                                        }
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        
                                        <Grid item sm={12}>
                                            <Navigation  {...navigationProps} enableNext={!enableNext}/>
                                        </Grid>
                                    </Grid>
                                </form>
                                <Grid item className={classes.formNote}>
                                    <Typography variant="body1" className={classes.requiredNotice}>
                                        <i>* {t("Pflichtfeld")}</i>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                />
            )
        } else {
             navigationProps.onNext(enableNext)
        }
    }
    return (
        <div style={{height: "100%"}}>
            {renderContent()}
        </div>
    )
}

export default CompanyLegalForm