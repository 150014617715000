import { useAlert } from "hooks/useAlert"
import React, { MouseEvent } from "react"
import ReactFlow, { isNode, Background, Controls, Node, OnLoadParams, Connection, Edge } from "react-flow-renderer"
import { useTranslation } from "react-i18next"
import { useChatState } from "./ChatContext"
import { isValidConnection, isValidEdgeUpdate, nodeTypes, isValidGoTo } from "./chatFunctions"
import { makeStyles } from "@material-ui/core"
import { useSnackbar } from "notistack"


const useStyles = makeStyles(theme => ({
    flowBackground: {
        backgroundColor: "#1a1e2d"
    }
}))


const View = () => {
    const { state, dispatch } = useChatState()
    const { alert } = useAlert()
    const { t } = useTranslation()
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar();
    const onLoad = (reactFlowInstance: OnLoadParams) => setTimeout(() => reactFlowInstance.fitView({padding: 0.5}), 0)

    // ! is there a way to make this smooth without upgrading to v10 ?
    // const focusNode = () => {
    //     if(!state.currentChatSimNode){
    //         return
    //     }
    //     const x = state.currentChatSimNode.position.x
    //     const y = state.currentChatSimNode.position.y
    //     setCenter(x,y, 2)
    // }
    
    // useEffect(() => {
    //     focusNode()
    // },[state.currentChatSimNode])

    const onElementClick = (_:MouseEvent, element: Node | Edge) => {
        if(!isNode(element)){
            return
        }
        if (state.goToSelection) {
            if (!isValidGoTo(element)){
                enqueueSnackbar( t(`Nodes vom typen ${element.type} können nicht als Nachfolger bestimmt werden`),{variant: "warning"})
                return
            }
            dispatch({type: "SELECT_GOTO_NODE", payload: element})
        } else {
            dispatch({ type: "SELECT", payload: element })
        }
    }

    // const onNodeDragStop = (_: MouseEvent, node: Node) => {
    //     dispatch({ type: "UPDATE_NODE", payload: node })
    // }

    const onNodeMouseEnter = (_: MouseEvent, node: Node) => {
        if (state.goToSelection) {
            dispatch({type: "HOVER_NODE_GOTO", payload: node})
        }
    }

    const onNodeMouseLeave = (_: MouseEvent, node: Node) => {
        if(state.goToSelection) {
            dispatch({ type: "LEAVE_NODE_GOTO"})
        }
    }

    return (
        <>
            <ReactFlow
                elements={state.elements}
                onLoad={onLoad}
                onElementClick={onElementClick}
                nodeTypes={nodeTypes}
                //onNodeDragStop={onNodeDragStop}
                onNodeMouseEnter={onNodeMouseEnter}
                onNodeMouseLeave={onNodeMouseLeave}
            >
                <Controls />
                <Background
                    color="#cccccc"
                    gap={10}
                    size={0}
                    className={classes.flowBackground}
                 />
            </ReactFlow>
        </>
    )
}

export default View