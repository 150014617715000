import LoadingAnimation from "components/LoadingAnimation"
import React from "react"
import ReactFlow, { Background } from "react-flow-renderer"
import { nodeTypes } from "routes/app/chats/chatbuilder/chatFunctions"

const ChatView = ({chats, id, chatView}: any) => {
    const chat = chats.chats.find((chat: any) => chat.id === id)

    switch (chatView) {

        case "graph": {
            return (
                <>
                    <ReactFlow
                        elements={chat.chat.elements}
                        nodeTypes={nodeTypes}
                    >
                        <Background
                            color="#ffffff"
                            gap={10}
                            size={0}
                        />
                    </ReactFlow>
                </>
            )
        }
        default:{
            console.warn(chatView)
            return <LoadingAnimation/>
        }
    }


}

export default ChatView