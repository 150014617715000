import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export default withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.tooltip.main,
    fontSize: "0.75em"
  },
  arrow: {
    color: theme.palette.tooltip.main,
  }
}))(Tooltip);