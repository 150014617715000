//generic cache wrapperfunction
// usage:
// [ cachedFunction, resetCache ] = cached(originalFunction)
// [ cachedFunctionsObject, resetCache ] = cachedObject(ObjectOfFunctions)

export const cached = (f) => {
    let cache = {}
 	const cachedFunction = (...params) => {
        if(!cache[params]){
            cache[params] = f(...params)
        }
        return cache[params]
    }
    cachedFunction.resetCache = () => cache = {}
    return cachedFunction
}

export const cachedObject = (obj) => {
    const mappedObject = Object.keys(obj)
    .map(key => ([key, cached(obj[key])]))
    .reduce((result, cur) => [
        {...result[0], [cur[0]]: cur[1][0]},
        [...result[1], cur[1][1]]
    ], [{}, []])

    return [
        mappedObject[0],
        () => mappedObject[1].forEach(f => f())
    ]
}