import React from "react"
import { Link, Grid } from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Typography, Fa } from "styled/muiComponents"
import { IClasses } from "./Auth" 


export enum IType {
    "Success" = "Success",
    "Failure" = "Failure"
}

type Props = {
    classes: IClasses
    type: IType

}

const RegisterSuccessFailure = ({ classes, type: successOrFailure}: Props ) => {
    const { t } = useTranslation()

    return (
        <Grid container direction="column" justify="center" alignItems="stretch" className={classes.root}>
            <Grid item >
                <img alt="Campaign.Plus Cornerstone" className={classes.logo} src="/assets/images/logo.svg" />
            </Grid>
            {successOrFailure === "Success"
                ?
                    <Grid item>
                        {t("Geschafft!")}
                    </Grid>
                : 
                    null
            }
            <Grid item>
                <Typography variant="body1">
                    {successOrFailure === "Failure"
                        ? t("Ihre Registrierung konnte nicht abgeschlossen werden.") //TODO
                        : ("Sie haben sich erfolgreich registriert")                 //TODO
                    }
                </Typography>
            </Grid>
            <Grid item className={classes.regpw}>
                <Typography>
                    <Fa icon={["fal", "chevron-left"]} color="secondary.main" fixedWidth />
                    <Link component={RouterLink} to="/auth/login">{t("Zurück zum Login")}</Link>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default RegisterSuccessFailure