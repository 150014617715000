import React, { useState } from "react"
import Button from "components/Button/Button"
import { Grid, Paper } from "@material-ui/core"
import { getOutgoers } from "react-flow-renderer"
import { findElement, validConnections } from "../../chatFunctions"
import FormikInput from "components/formik/FormikInput"
import { handleHasEdge } from "../../chatFunctions"
import { useTranslation } from "react-i18next"
import { Fa } from 'styled/muiComponents'
import { Alert, AlertTitle } from '@material-ui/lab';

// when nothing selected
const CreateNodeOptions = ({ state, dispatch, classes }: any) => {
    const [node, setNode] = useState<any | null>(null)
    const { t } = useTranslation()

    const handleChangeType = (e: any) => {
        setNode({ type: e.target.value })
    }


    const menuOptions = () => {
        if (!handleHasEdge(state)) {
            //@ts-ignore TODO TYPING FOR INDEX
            const items = validConnections[state.selectedHandle.node.type].map((connection: string) => {
                return connection
            })
            return items
        } else {
            const nextNode = getOutgoers(findElement(state.selectedHandle.node.id, state), state.elements)[0]
            //@ts-ignore TODO TYPING FOR INDEX
            const connections = validConnections[state.selectedHandle.node.type].filter((validOutgoing: string, index: number) =>
                //@ts-ignore
                validConnections[validOutgoing].includes(nextNode.type)
            )
            return connections.filter((c:string) => c !== "goTo" )
        }
    }

    return (
        <div>
            {state.selectedHandle &&
                <>
                <Grid container direction="column" alignItems="center" className={classes.settingsContainer} style={{borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px"}}>
                    {handleHasEdge(state) ? 
                        <Alert severity="info" variant="outlined" style={{marginBottom: "16px", backgroundColor: "#ffffff"}}><AlertTitle>Verbundener Block</AlertTitle>Der vorangegangene Block ist bereits mit einem anderen Block verbunden. Die Blocktyp-Auswahl kann dadurch eingeschränkt sein.</Alert> : null }
                    <Paper elevation={2} className={classes.settingsGroupPaper}>
                        <Grid container item className={classes.header} wrap="nowrap" direction="row" alignItems="center" justify="space-between" style={{height: "46px"}}>
                            <Grid item>
                                {t("Wähle einen Block")}
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" justify="center" alignItems="center" className={classes.settingsGroup}>
                            <FormikInput 
                            name="type"
                            type="enum"
                            variant="outlined"
                            label={t("Blocktyp")}
                            labelProps={{
                                variant: "outlined"
                            }}
                            formControlProps={{
                                required: true
                            }}
                            typeParams={menuOptions()}
                            onChange={handleChangeType}
                            disabled={menuOptions().length === 0}
                            />
                            <FormikInput 
                            type="text"
                            name="name"
                            label={t("Blockname")}
                            variant="outlined"

                            />
                            
                            <Button startIcon={<Fa icon={["fal", "plus"]} size="xs" />} style={{marginBottom: "16px", marginTop: "16px"}}  type="submit" variant="contained" disableElevation color="success" size="large" disabled={!node}>Block hinzufügen</Button>
                            
                        </Grid>
                    </Paper>
                </Grid>
                </>
            }
        </div>
    )
}

export default CreateNodeOptions
