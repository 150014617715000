import { withCrud, CrudModel, BaseModel } from "./builder";

export type IRecipientGroup = {
  id: number
  name: string
  active_recipients_count: number
  click_rate: number
  deactivated_recipients_count: number
  open_rate: number
  recipients_count: number
  unsubscribed_recipients_count: number
}

export type RecipientGroupApi = CrudModel<IRecipientGroup> & BaseModel

export default {
    name: "recipientGroups",
    ...withCrud<IRecipientGroup>("recipient_groups")
}