import { useMemo, useState } from 'react'
import { useLocation, useHistory } from "react-router-dom"

const useQueryParams = <E extends Record<string, string>, D>(
    encodeQueryParams: (decodedParams: D) => E,
    decodeQueryParams: (encodedParams: E) => D
    ): [D, ( decodedParams: D ) => void] => {
    const location = useLocation()
    const history = useHistory()

    const queryParams = useMemo(() => (
        decodeQueryParams(Object.fromEntries(new URLSearchParams(decodeURIComponent(location.search))) as E)
    ), [decodeQueryParams, location.search])

    const setQueryParams = (params: D) => {
        const encodedParams = encodeQueryParams(params)
        const searchString = Object.entries(encodedParams)
            .map(kvp => [kvp[0], encodeURIComponent(kvp[1] as string)].join("="))
            .join("&")

        history.push(`${location.pathname}?${searchString}`)
    }

    return [
        queryParams,
        setQueryParams
    ]
}

export const useQueryParamsState = <E extends Record<string, string>, D>(
    encodeQueryParams: (decodedParams: D) => E,
    decodeQueryParams: (encodedParams: E) => D,
    initialState?: D
    ): [D, ( decodedParams: D ) => void] => {

        const [ params, setParams ] = useQueryParams<E, D>(encodeQueryParams, decodeQueryParams)
        const [ state, setInternalState ] = useState<D>({...initialState, ...params})

        const setState = (p: D) => {
            setParams(p)
            setInternalState(p)
        } 

        return [
            state,
            setState
        ]
}

export default useQueryParams
