import React from 'react'
import TextField from '@material-ui/core/TextField'
import { useDialog } from '../../hooks/useDialog'
import TinymceDialog from '../../dialogs/Tinymce'
import { stripHtml } from '../../util/string'

const FormikTinymce = ({ fullWidth=true, multiline=true, onChange, onBlur, ...props }) => {
    const {input:{name, value, ...field} } = props
    const { dialog } = useDialog()

    const handleClick = () => {
        dialog.show({
            component: TinymceDialog,
            props: {
                initialValue: field.value || props.input.value
            }
        }).then((value) => {
            const ret = {target: {name, value}}
            field.onChange(ret)
            if(onChange) {
               onChange(ret)
            }
            if(onBlur) {
               onBlur(ret)
            }
            field.onBlur(ret)
        })
    }

    return (
        
        <TextField {...props} {...field} value={stripHtml(field.value || props.input.value)} onMouseDown={handleClick} fullWidth={fullWidth} />
    )
}

export default FormikTinymce