import React from "react"
import { Route, Switch } from "react-router-dom"
import POSWebforms from "./POSWebform"

const POSRouter = () => {
    return (
        <Switch>
            <Route path="/pos">
                <POSWebforms/>
            </Route>
        </Switch>
    )
}

export default POSRouter