import { createContext, useContext, Dispatch, SetStateAction } from 'react'
import User from '../types/user'

import { get, post } from '../api/crud'
import { ImprintApi } from 'models/imprint'

export enum LoginState {
    "LoggedIn"="LoggedIn",
    "LoggedOut"="LoggedOut",
    "Unknown"="Unknown"
}
export type Address = {
    city: string
    company_name: string
    country: string
    csa_mode: string
    email_volume_remaining: number
    email_volume_total: number
    first_name: string
    last_name: string
    gender: string
    has_dedicated_ips: boolean
    imprint_mail: string
    phone_number: string
    postal_code: string
    status: string
    street_name: string
    street_number: string
    vat_identifier: string
    website: string
    ust_identifier: string
    legal_form: string
    company_board: string
    supervisory_board: string
    company_register: string
    registry_court: string
    registry_number: string
    supervisory_authority: string
    responsible_chamber: string
    job_title_plus_country_awarded: string
    professional_regulations: string
    additional_information: string
}
type billing = {
    name: string
    brand: string
    last4: number
    exp_month: number
    exp_year: number
    id: number
    iban: string
    account_holder:string
}
type Editor = {
    features: object[]
}
export type IRealm = {
    address: Address
    active_subscription: string
    billing: billing | null
    billing_provider: string
    bundle_details: object
    csa_mode: string
    editor: Editor
    email_volume_period_planned: number
    email_volume_remaining: number
    email_volume_total: number
    has_dedicated_ips: boolean
    status: string
    subscription_features: []

}

// TODO logo

export type SessionContextProps = {
    user: User | null,
    setUser: Dispatch<SetStateAction<User | null>> | null,
    loginState: LoginState,
    setLoginState: Dispatch<SetStateAction<LoginState>> | null,
    realm: IRealm | null,
    setRealm: Dispatch<SetStateAction<IRealm | null>> | null,
    isPOS: boolean | null
    setIsPOS: Dispatch<SetStateAction<boolean>> | null
    imprint: Address | null //TODO correct type
    setImprint: Dispatch<SetStateAction<Address | null>> | null // TODO correct type
    logo: string | null
    setLogo: Dispatch<SetStateAction<string | null>> | null
    
    
}

export const SessionContext = createContext<SessionContextProps>({user:null, setUser: null, loginState: LoginState.Unknown, setLoginState: null, realm: null, setRealm: null, setIsPOS: null, isPOS: null, imprint: null, setImprint: null, logo: null, setLogo: null})

const useSession = () => {
    const { user, setUser, loginState, setLoginState, realm, setRealm, setIsPOS, isPOS, imprint, setImprint, logo, setLogo } = useContext(SessionContext)

    const login = async (email: string, password: string, POS: boolean) => {
        if(!setUser || !setLoginState || !setRealm || !setIsPOS || !setImprint || !setLogo) {
            return Promise.reject("Can only be called within SessionContext.Provider")
        }
        try {
            await post<User>("/users/login", {email, password})
            const response = await get<any>("/imprints")
            try{
            const logoResponse = await get<string>('/images/get_logo')
            setLogo(logoResponse)
            } catch {
                setLogo('https://app.cornermail.de/assets/images/cp-logo.png')
            }

            if(response.meta.item_count > 0){
                setImprint(response.data[0])
            }
            setIsPOS(POS)
            restore(POS)
        }catch(e) {
            throw e
        }
    }
    const logout = async (): Promise<void> => {
        if(!setUser || !setLoginState || !setIsPOS || !setImprint || !setLogo) {
            return Promise.reject("Can only be called within SessionContext.Provider")
        }
        try {
            await post("/users/logout")
            setLoginState(LoginState.LoggedOut)
            // setIsPOS(false)
            setImprint(null)
            setLogo(null)
            setUser(null)
        }catch(e) {
            throw e
        }
    }

    const restore = async (POS?: boolean) => {
        if(!setImprint || !setUser  || !setRealm || !setLoginState  || !setLogo || !setIsPOS  || isPOS === null && typeof isPOS === "boolean") {
            return Promise.reject("Can only be called within SessionContext.Provider")
        }
        try {
            const result: User = await get<User>("/users/current_user")
            const realmInfo: IRealm = await get<IRealm>("users/realm/client_format")
            const response = await get<any>("/imprints")
            try{
                const logoResponse = await get<string>('/images/get_logo')
                setLogo(logoResponse)
                } catch {
                    setLogo('https://app.cornermail.de/assets/images/cp-logo.png')
                }
            
            if(response.meta.item_count > 0){
                setImprint(response.data[0])
            }
            // setIsStand(false)
            setUser(result)
            setRealm(realmInfo)
            
            if(POS){

                setIsPOS(POS)
            } else {
                //@ts-ignore
                setIsPOS(isPOS)
            }
            setLoginState(LoginState.LoggedIn)
        }catch(e) {
            setLoginState(LoginState.LoggedOut)
            setUser(null)
            setRealm(null)
            throw e
        }
    }

    return {
        login,
        logout,
        restore,
        loginState,
        user,
        setLoginState,
        realm,
        setRealm,
        isPOS,
        imprint,
        setImprint,
        logo,
        setLogo
    }
}

export default useSession