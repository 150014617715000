import { Grid, Paper } from "@material-ui/core"
import FormikInput from "components/formik/FormikInput"
import React from "react"
import { useChatState } from "../../ChatContext"
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useTranslation } from "react-i18next"
import { Alert, AlertTitle } from '@material-ui/lab';

const UserFeedbackNodeOptions = ({ node, classes }: any) => {
    const { state } = useChatState()
    const { t } = useTranslation()
    return (
        <Grid container className={classes.settingsContainer} direction="column" alignItems="center" style={{borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px" }}>
            <Grid item container direction="column" alignItems="center" style={{width: "100%"}} >
                <div className={classes.seperator}></div>
                <FormikInput
                    type="slider"
                    name="delay"
                    max={10}
                    min={0}
                    // defaultValue={node.data.delay ?? 2}
                    //onChange={(e: any) => handleChange(e.target.value, "delay")}
                    valueLabelDisplay="auto"
                    label="s delay"
                    variant="delay"
                />
                <div className={classes.seperator}></div>
                <Alert severity="info" variant="outlined" style={{marginBottom: "16px", backgroundColor: "#ffffff"}}>
                    <AlertTitle>{t("Feedback")}</AlertTitle>
                    {t("Der Kunde wird als Antwort auf deinen Text unten ein Feedback abgeben können.")}
                </Alert>
            </Grid>
            <Grid item style={{width: "100%"}}>
                <Paper elevation={2} className={classes.settingsGroupPaper}>
                    <Grid container item direction="column" justify="center" alignItems="stretch" className={classes.settingsGroup}>
                        <Grid item style={{width: "100%"}}>
                            <label className="FormikFormLabel">{t("Text")}</label>
                            <FormikInput
                                type="entityText"
                                name="text"
                                // label="text"
                                // defaultValue={node.data.text}
                                //onChange={(e: any) => handleChange(e.target.value, "text")}
                                typeParams={state.fields}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default UserFeedbackNodeOptions