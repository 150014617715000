import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { Grid } from '../../styled/muiComponents'
import SideBar from './sideBar/SideBar'
import { useSidebar } from '../../hooks/useSidebar'
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles<Theme, {isFixedOpen: boolean}>(theme => ({
  root: {
      marginLeft: ({isFixedOpen}) => isFixedOpen ? "256px" : "80px",
      overflowX: "hidden",
      transition: "0.35s ease",
      flexGrow: 1
  },
  overlay: {
    position:"absolute",
    left:0,
    right:0,
    top:0,
    bottom:0,
    zIndex:105,
    backgroundColor:"rgba(7, 12, 29, 0.5)",
    cursor: "pointer",
    transition: "0.25s all ease"
  },
}))

const App: React.FC = ({ children }) => {
  const {isFixedOpen} = useSidebar()
  const classes = useStyles({isFixedOpen})

  return (
      <Grid item height="100%">
        <Box flexDirection="row" display={"flex"} height="100%">
          <SideBar />
          <PerfectScrollbar className={classes.root}>
            <Box flexGrow={1} height="100%">
                {children}
            </Box>
          </PerfectScrollbar>
        </Box>
      </Grid>
  );
}

export default App;
