import React, { useEffect, useState } from "react"
import { SetupNavigationProps, Navigation, ISetupInformation } from "../SetupWizard"
import { Radio } from "@material-ui/core"
import { Form } from 'react-final-form'
import FormikInput from "components/formik/FormikInput"
import { Typography, Grid } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { useTranslation } from "react-i18next"
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

type Props = {
    navigationProps: SetupNavigationProps
    classes: any
    handleChange: (event: any) => void
    noInfo: boolean
    handleChangeNoInfo: (name: string) => void
    setupInformation: ISetupInformation
}

const CompanyRegistry = ({navigationProps, classes, setupInformation, handleChange, noInfo, handleChangeNoInfo}: Props) => {
    const { t } = useTranslation()
    const [enableNext, setEnableNext] = useState<boolean>(false)
    const handleSubmit = () => {
    }

    const initialValues = {
        company_register: setupInformation.company_register,
        company_register_offlist: setupInformation.company_register_offlist,
        registry_court: setupInformation.registry_court,
        registry_number: setupInformation.registry_number
    }

    useEffect(() => {
        if (noInfo && setupInformation.company_register === "Andere" && setupInformation.registry_court && setupInformation.registry_number && setupInformation.company_register_offlist) {
            setEnableNext(true)
        } else if (noInfo && setupInformation.company_register && setupInformation.registry_court && setupInformation.registry_number) {
            setEnableNext(true)
        } else if (!noInfo && !setupInformation.company_register && !setupInformation.registry_court && !setupInformation.registry_number) {
            setEnableNext(true)
        } else {
            setEnableNext(false)
        }
    }, [setupInformation, noInfo])

    const renderContent = () => {
        if (noInfo) {
            return (
                <>
                    <Grid item container>
                        <Grid item xs={12}>
                            <FormikInput
                                type="enum"
                                value={setupInformation.company_register}
                                name="company_register"
                                label={t("Register")}
                                variant="outlined"
                                onChange={handleChange}
                                style={{marginBottom: "8px"}}
                                typeParams={[
                                    "Handelsregister",
                                    "Genossenschaftsregister", // TODO redo with translated fields
                                    "Partnerschaftsregister",
                                    "Vereinsregister",
                                    "Güterrechtsregister",
                                    "Schiffsregister",
                                    "Andere"
                                ]}
                                labelProps={{
                                    variant:"outlined"
                                }}
                                formControlProps={{
                                    required: true
                                }}
                            />
                        </Grid>
                        
                        {renderOfflist()}
                        
                        <Grid item xs={12}>
                            <FormikInput
                                type="string"
                                value={setupInformation.registry_court}
                                name="registry_court"
                                label={t("Registergericht")}
                                variant="outlined"
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormikInput
                                type="string"
                                value={setupInformation.registry_number}
                                name="registry_number"
                                label={t("Registernummer")}
                                variant="outlined"
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                    </Grid>
                </>
            )
        }
    }

    const renderOfflist = () => {
        if (setupInformation.company_register === "Andere") {
            return (
                <Grid item xs={12}>
                    <FormikInput
                        type="string"
                        value={setupInformation.company_register_offlist}
                        name="company_register_offlist"
                        label={t("Andere")}
                        variant="outlined"
                        onChange={handleChange}
                        required
                    />
                </Grid>
            )
        }
    }

    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}
            render={({ handleSubmit, submitting }) => (
                <Grid container item direction="column" className={classes.root}>
                    <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" justify="flex-start">
                                <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                    <Grid item sm={4}>
                                        <div style={{ maxWidth: "150px" }}>
                                            <Pixi size="full" pose="waiting"></Pixi>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <div className={classes.headerDescription} >
                                        <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                            <Typography variant="h5" className={classes.pixiSpeech}>
                                                {t("Ist dein Unternehmen in einem Register, z. B. dem Handelsristier eingetragen?")}
                                            </Typography>
                                        </Typist>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item container className={classes.formContent}>
                                    <Grid item sm={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{t("Bitte wählen")}</FormLabel>
                                            <RadioGroup row aria-label="register" name="register" style={{marginTop: "16px", marginBottom: "24px"}}>
                                                <FormControlLabel control={<Radio checked={noInfo} onChange={() => handleChangeNoInfo("register")}/>} label={t("Ja")} />
                                                <FormControlLabel control={<Radio checked={!noInfo} onChange={() => handleChangeNoInfo("register")} />} label={t("Nein")} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                
                                    {renderContent()}
                                    
                                </Grid>
                                
                                <Grid item sm={12}>
                                    <Navigation  {...navigationProps} enableNext={!enableNext} skipEnabled={true}/>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item className={classes.formNote}>
                            <Typography variant="body1" className={classes.requiredNotice}>
                                <i>* {t("Pflichtfeld")}</i>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    )
}

export default CompanyRegistry