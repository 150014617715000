import React, { useEffect, useState } from "react"
import { SetupNavigationProps, Navigation, ISetupInformation } from "../SetupWizard"
import { Form } from "react-final-form"
import FormikInput from "components/formik/FormikInput"
import { Typography, Grid, Fa } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { useTranslation } from "react-i18next"
import InputAdornment from '@material-ui/core/InputAdornment';

type Props = {
    navigationProps: SetupNavigationProps
    classes: any
    handleChange: (event: any) => void
    setupInformation: ISetupInformation
}

const CompanyContact = ({ navigationProps, classes, handleChange, setupInformation }: Props) => {
    const { t } = useTranslation()
    const [enableNext, setEnableNext] = useState<boolean>(false)
    const [correctMail, setCorrectMail] = useState<boolean>(false)
    const handleSubmit = () => {
        console.warn("not implemented")
    }

    const initialValues = {
        imprint_mail: setupInformation.imprint_mail,
        phone_number: setupInformation.phone_number
    }

    useEffect(() => {
        const re = /\S+@\S+\.\S+/;
        setCorrectMail(re.test(setupInformation.imprint_mail))
    }, [setupInformation.imprint_mail])

    useEffect(() => {
        if (setupInformation.imprint_mail && setupInformation.phone_number) {
            setEnableNext(true)
        } else {
            setEnableNext(false)
        }
    }, [setupInformation])
    const [hasEmailFocus, setEmailFocus] = useState(false);
    const [hasPhoneFocus, setPhoneFocus] = useState(false);
    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}
            render={({ handleSubmit, submitting }) => (
                <Grid container item direction="column" className={classes.root}>
                    <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" justify="flex-start">
                                <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                    <Grid item sm={4}>
                                        <div style={{ maxWidth: "150px" }}>
                                            <Pixi size="full" pose="waiting"></Pixi>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <div className={classes.headerDescription} >
                                        <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                            <Typography variant="h5" className={classes.pixiSpeech}>
                                                {t("Jedes Impressum braucht eine E-Mail-Adresse und eine Telefonnummer.")}
                                            </Typography>
                                        </Typist>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item container className={classes.formContent}>
                                    <Grid item xs={12} mt={1}>
                                        <FormikInput
                                            type="string"
                                            value={setupInformation.imprint_mail}
                                            name="imprint_mail"
                                            label={t("E-Mail Adresse")}
                                            variant="outlined"
                                            onChange={handleChange}
                                            style={{marginTop: 0}}
                                            required
                                            error={!correctMail && setupInformation.imprint_mail.length > 0}
                                            InputProps={
                                                {
                                                    onFocus:(() => setEmailFocus(true)),
                                                    onBlur:(() => setEmailFocus(false)),    
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            { hasEmailFocus ? <Fa icon={["fas", "envelope"]}/> : <Fa icon={["fal", "envelope"]}/>}
                                                        </InputAdornment>
                                                    ),
                                                }
                                            }
                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormikInput
                                            type="string"
                                            value={setupInformation.phone_number}
                                            name="phone_number"
                                            label={t("Telefonnummer")}
                                            variant="outlined"
                                            onChange={handleChange}
                                            required
                                            InputProps={
                                                {
                                                    onFocus:(() => setPhoneFocus(true)),
                                                    onBlur:(() => setPhoneFocus(false)),    
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            { hasPhoneFocus ? <Fa icon={["fas", "phone-flip"]}/> : <Fa icon={["fal", "phone-flip"]}/>}
                                                        </InputAdornment>
                                                    ),
                                                }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item sm={12}>
                                    <Navigation  {...navigationProps} enableNext={!enableNext}/>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item className={classes.formNote}>
                            <Typography variant="body1" className={classes.requiredNotice}>
                                <i>* {t("Pflichtfeld")}</i>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    )
}

export default CompanyContact