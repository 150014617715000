import React, { useState } from 'react'
import { Grid, InputAdornment } from '@material-ui/core'
import FormikInput from './FormikInput'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

const SenderEmailInput = ({ localPartProps, domainPartProps, ...props }) => {
    const [ open, setOpen ] = useState(false)

    const handleKeyPress = (e) => {
        if(e.key === "@") {
            setOpen(true)
        }
    }
    const [hasLocalEmailPartFocus, setLocalEmailPartFocus] = useState(false);
    return (
        <Grid container>
            <Grid item sm={6} xs={12}>
                <FormikInput
                    InputProps={{
                        onFocus:(() => setLocalEmailPartFocus(true)),
                        onBlur:(() => setLocalEmailPartFocus(false)),
                        endAdornment: (
                            <InputAdornment position="end">
                                { hasLocalEmailPartFocus ? <AlternateEmailIcon style={{ color: "#070c1d" }} /> : <AlternateEmailIcon style={{ color: "gray" }} />}
                            </InputAdornment>
                        )
                    }}
                    onKeyPress={handleKeyPress}
                    type="text"
                    {...props}
                    {...localPartProps}
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                 <FormikInput
                    type="domain"
                    {...domainPartProps}
                    {...props}
                    labelProps={{
                        variant: "outlined"
                    }}
                    style={{
                        minWidth: "200px",
                        paddingLeft: "4px",
                        ...domainPartProps.style,
                        ...props.style
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                /> 
            </Grid>
        </Grid>
    )
}

export default SenderEmailInput