import React, {useState, useRef, useEffect} from 'react'
// import ScratchCard from 'react-scratch-coupon'
import ScratchCard from 'react-scratchcard-v2';
import './style.css'
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import {codearray} from './codes'
import { useHistory } from 'react-router-dom';
import axios from "axios"

const Scratch = () => {
    const randomNumber = Math.floor(Math.random() * 1000);
    const ref = useRef(null);
    const [code, setCode] = useState(codearray[randomNumber]);
    // const [code, setCode] = useState('A123')
    const [email, setEmail] = useState('');
    const [codeIsValid, setCodeIsValid] = useState(true);
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [success, setSuccess] = useState(true);
    const [scratched, setScratched] = useState(false)
    const [clicked, setClicked] = useState(false)
    
    useEffect(() => {
      document.body.style.overflow = scratched ? 'scroll' : 'hidden'
      return () => (document.body.style.overflow = 'scroll')
    })


    const handleCodeChange = (e) => {
        console.log(e, 'code')
      const newCode = e.target.value.toUpperCase();
      setCode(newCode);
  
      if (/^[A-Za-z]\d{3}$/.test(newCode)) {
        setCodeIsValid(true);
      } else {
        setCodeIsValid(false);
      }
    };
  
    const handleEmailChange = (e) => {
        console.log(e, 'email')
      const newEmail = e.target.value;
      setEmail(newEmail);
  
      if (/^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(newEmail)) {
        setEmailIsValid(true);
      } else {
        setEmailIsValid(false);
      }
    };
  
    const handleTermsChange = (e) => {
      setClicked(true)
      setTermsAccepted(e.target.checked);
    };
  

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        setClicked(true)
    
        // Validation logic
        // let isValid = true;
    
        if (!termsAccepted) {
            return
        //   isValid = false;
        }
    
        if (!codeIsValid) {
          return
        //   isValid = false;
        }
    
        if (!emailIsValid) {
          
          return
        //   isValid = false;
        }

    
        // Construct the data to be sent in the POST request
        const formData = new FormData();
        formData.append('Teilnahme', termsAccepted);
        formData.append('a1', code);
        formData.append('email', email);
    
        try {
          const response = await axios.post('https://newsletterplus.de/webform/bdkH8/subscribe', formData);
          
        } catch (error) {
          // Handle any errors here
        }
         window.location.href = 'https://www.campaign.plus/scratch/bitte-bestaettigen.html'
      };

    return (
      <>
      <link rel="apple-touch-icon" sizes="180x180" href="https://www.campaign.plus/wp-content/uploads/fbrfg/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="https://www.campaign.plus/wp-content/uploads/fbrfg/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="https://www.campaign.plus/wp-content/uploads/fbrfg/favicon-16x16.png" />
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous" />
      <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"></script>
      <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
      <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>
      <link href="https://fonts.googleapis.com/css?family=Open+Sans|Raleway" rel="stylesheet" />
      <link rel="stylesheet" href="https://www.campaign.plus/scratch/style.css" />
      <link rel="stylesheet" href="https://use.typekit.net/aum0mmz.css" />
      <div class="cp_container">
        <div className="container text-center pt-4 pb-4">
          <img
            alt="u-try-me"
            src="https://www.campaign.plus/wp-content/uploads/2018/09/Campaign_Plus-powered-by-sendeffect.svg"
            className="logo mb-1"
          />
          </div>
        <div className="main-content pt-3 pt-sm-4 pb-5 h-100">
          <div className="container-sm form-wrapper mt-2 mb-2">
              <div className="text-center">
                  <img src="https://www.campaign.plus/scratch/img/opa_mit_winlogo.png" alt="Scratch and Win" className="img-fluid" />
              </div>
              <div className="card shadow pr-sm-5 pl-sm-5 pb-sm-4 pt-sm-4 p-3 bg-white rounded-lg">
                <h2 className="card-title text-center mb-4" style={{ display: scratched ? 'none' : 'block' }}>
                        Code frei rubblen und<br />
                        <strong>Neukunden gewinnen!</strong>
                </h2>
                <h2 className="card-title text-center mb-4" style={{ display: !scratched ? 'none' : 'block' }}>
                        🎉 WOW  🎉<br />
                        das sieht gut aus!
                      </h2>
                <div  style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                      }}>
                  <div style={{ borderRadius: '5px', overflow: 'hidden', border: "2px dashed rgb(35 133 0)", backgroundColor: "#ecffec" }} >
                    <ScratchCard
                        width={190}
                        height={80}
                        image={require('./images/scratchcard_foreground.jpg')}
                        finishPercent={80}
                        onComplete={() => setScratched(true)}
                    >
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        >
                            <h1 className="m-0">{code}</h1>
                        </div>
                      </ScratchCard>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-success mt-4 mb-2" data-toggle="modal" data-target="#preise_modal">
                      👉 Das gibt es zu gewinnen! 👈
                    </button>
                  </div>
                  <div style={{ display: !scratched ? 'none' : 'block' }} className="mt-4">
                
                      <h2 className="card-title text-center mb-4">
                      Überprüfe, ob dein Code gewonnen hat.
                      </h2>
                      <form method="post" action="https://newsletterplus.de/webform/bdkH8/subscribe" id="anmeldung" onSubmit={handleSubmit}>
                        <div className="form-group" style={{display: "none"}}>
                          <label htmlFor="a1">Code</label>
                          <input
                            type="text"
                            name="a1"
                            id="a1"
                            className={`form-control form-control-lg ${codeIsValid ? '' : 'is-invalid'}`}
                            maxLength="4"
                          //   onBlur={handleCodeChange}
                            value={code}
                            aria-describedby="error"
                            disabled
                          />
                          <small className="form-text"></small>
                          <div id="error" className="invalid-feedback" style={{ display: codeIsValid ? 'none' : 'block' }}>
                            Bitte überprüfen Sie Ihre Eingabe
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">E-Mail Adresse</label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            onBlur={handleEmailChange}
                          //   value={email}
                            // className={`form-control form-control-lg ${emailIsValid && email.length > 0 ? 'is-valid' : 'is-invalid'}`}
                            className={`form-control form-control-lg ${email.length === 0 ? '' : emailIsValid ? 'is-valid' : 'is-invalid'}`}
                          />
                          
                          <div id="error_mail" className="invalid-feedback" style={{ display: email.length === 0 ? '' : emailIsValid  ? 'none' : 'block' }}>
                            Bitte überprüfen Sie Ihre Eingabe
                          </div>
                        </div>
                        <div className="custom-control custom-checkbox mb-2">
                          <input
                            type="checkbox"
                            // className={`custom-control-input ${termsAccepted ? '' : 'is-invalid'}`}
                            className={`custom-control-input ${!clicked ? '' : termsAccepted ? '' : 'is-invalid'}`}
                            name="Teilnahme"
                            value="Ja"
                            id="Teilnahme"
                            onChange={handleTermsChange}
                          />
                          <label className="custom-control-label" htmlFor="Teilnahme">
                            Ich stimme den <a href="#" data-toggle="modal" data-target="#teilnahmebedingungen_modal">Teilnahmebedingungen</a> und{' '}
                            <a href="https://www.campaign.plus/allgemeine-geschaeftsbedingungen/" target="_blank">Datenschutz</a> zu*
                          </label>
                        </div>
                        <input type="hidden" name="Gewinn" value="" id="a2" />
                        <div className="small mt-2">
                          Nach der Bestätigung deiner Daten wirst du für unseren Newsletter angemeldet und erhälst regelmäßig die interessantesten Themen zu Campaign.Plus. Die Abmeldung ist jederzeit möglich. Mit der Teilnahme am
                          Gewinnspiel stimmst du unseren Teilnahmebedingungen und Datenschutzbestimmungen zu.
                        </div>
                        <div className="text-center">
                          <button type="submit" className="btn btn-success btn-lg mt-3 mb-3">
                            Jetzt absenden und gewinnen
                          </button>
                        </div>
                      </form>
                 </div>
              
                </div>
              </div>
        </div>
{/*        <footer className="text-center footer pb-4">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <a href="https://www.campaign.plus">
                  <img
                    src="https://www.campaign.plus/wp-content/uploads/2019/05/Campaign_Plus-powered-by-sendeffect.png"
                    alt="Campaign.Plus powered by Sendeffect"
                    width="150"
                  />
                </a>
                <p>
                  Alle Rechte vorbehalten.
                </p>
              </div>
              <div className="col-md-4">
                <ul className="list-inline social-buttons">
                  <li>
                    <p 
                    onClick={() => setOpen(true)}
                     class="text-light" 
                    //  data-toggle="modal" 
                    //  data-target="#teilnahmebedingungen_modal" 
                     className='list-inline-item'>
                        Teilnahmebedingungen
                    </p>
   
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.campaign.plus/ueber-uns/" target="_blank">
                      Über uns
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.campaign.plus/impressum/" target="_blank">
                      Impressum
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.campaign.plus/datenschutz/" target="_blank">
                      Datenschutz
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
                    </footer>
      </div>*/}
                     <div class="text-muted pt-4 pb-4 text-center text-light bg-dark">
        <ul class="footer-bar-links">
            <li><a href="#" class="text-light" data-toggle="modal" data-target="#teilnahmebedingungen_modal">Teilnahmebedingungen</a></li>
            <li><a href="https://www.campaign.plus/allgemeine-geschaeftsbedingungen/" class="text-light" target="_blank">AGB</a></li>
            <li><a href="https://www.campaign.plus/datenschutzerklaerung/" class="text-light" target="_blank">Datenschutz</a></li>
            <li><a href="https://www.campaign.plus/impressum/" class="text-light" target="_blank">Impressum</a></li>
         </ul>
    </div>
  <div class="modal fade" id="teilnahmebedingungen_modal" tabindex="-1" role="dialog" aria-labelledby="Teilnahme_Titel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="Teilnahme_Titel">Teilnahmebedingungen</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <h3>1. Allgemeines</h3>
            <ol type="a">
                <li>Diese Teilnahmebedingungen (im Folgenden: „Teilnahmebedingungen“) gelten für die von Campaign.Plus GmbH, ansässig und tätig an der Wollmarkstraße 115b, 33098 Paderborn, Deutschland (im Folgenden: "Campaign.Plus"), veranstaltete Werbeaktion: Scratch & Win New Customers (im Folgenden:„Aktion“).</li>
                <li>Durch die Teilnahme an der Aktion akzeptiert der Teilnehmer diese Aktionsbedingungen.</li>
                <li>Diese Teilnahmebedingungen können auf der Webseite auf https://win.cornermail.de gelesen, heruntergeladen und ausgedruckt werden.</li>
                <li>Campaign.Plus ist berechtigt, diese Teilnahmebedingungen nach eigenem Ermessen und ohne vorherige Bekanntmachung während der Laufzeit der Aktion zu ändern oder anzupassen, oder diese Aktion ohne Angabe von Gründen zu beenden, zu ändern oder anzupassen, wenn die Umstände dies erfordern, ohne dass Action dafür in irgendeiner Weise zu einem Schadenersatz gegenüber den Teilnehmern verpflichtet ist. Eine Änderung oder Anpassung der Aktionsbedingungen, Beendigung, Änderung oder Anpassung der Aktion wird auf der Website https://www.campaign.plus/ und https://win.cornermail.de  bekanntgegeben.</li>
            </ol>

            <h3>2. Teilnahme</h3>
            <ol type="a">
                <li>Die Aktion läuft vom 1. September bis 30. September 2023.</li>
                <li>Die Teilnahme an der Aktion ist kostenlos.</li>
                <li>Die Teilnahme erfolgt durch die Eintragung des Codes und der E-Mail-Adresse auf der Aktionsseite https://www.Campaign.Plus.com/scratch und das Akzeptieren dieser Aktionsbedingungen. Anschließend kann man an der Aktion Scratch and Win (Rubbeln und Gewinnen) teilnehmen. Kunden erhalten im Zeitraum vom 1. Februar bis einschl. 30. März 2020 (solange der Vorrat reicht) bei jeder Transaktion eine Rubbelkarte mit einem einzigartigen Gewinncode oder eine digitale Einladung (Banner, Werbemail usw.). Der Teilnehmer rubbelt das Feld frei und gibt den einmaligen Gewinncode und die E-Mail-Adresse auf der Aktionsseite https://win.cornermail.de ein und akzeptiert die Aktionsbedingungen. Nach der Registrierung auf der Website erhält der Teilnehmer eine E-Mail mit einem Link zur Bestätigung. Der Teilnehmer muss auf den Link klicken, um zu sehen, ob er einen Action-Geschenkgutschein gewonnen hat. Der Link leitet zurück auf die Website, auf der sich der Kunde optional weiter registrieren kann (Vorname, Nachname, Postleitzahl, Geschlecht, Geburtsdatum). Nur mit Zustimmung der Speicherung der personenbezogenen Daten werden diese Daten tatsächlich gespeichert. 
                Wenn ein Teilnehmer einen Campaign.Plus-Geschenkgutschein gewonnen hat, kann er den Wertgutschein mit einer E-Mail an kontakt@campaign.plus einlösen.</li>
                <li>Nur die Gewinner werden benachrichtigt. Der Kontakt zu den Preisträgern wird hergestellt, um den Preis in einem Geschäft Ihrer Wahl abzuholen.</li>
                <li>Die Teilnahme ist unbegrenzt möglich, während des angegebenen Aktionszeitraums und solange der Vorrat reicht.</li>
                <li>Teilnahmeberechtigt sind alle Personen, die am ersten Aktionstag das 18. Lebensjahr vollendet haben und einen festen Wohnsitz oder Aufenthalt in Deutschland, Niederlande, Belgien, Frankreich, Luxemburg oder Österreich nachweisen können. Personen unter 18 Jahren dürfen nur mit Zustimmung der Eltern oder Erziehungsberechtigten an der Aktion teilnehmen und ihre personenbezogenen Daten hinterlassen.</li>
                <li>Campaign.Plus-Mitarbeiter und von Campaign.Plus beauftragte Dritte sind von der Teilnahme ausgeschlossen.</li>
                <li>Campaign.Plus hat das Recht, Teilnehmer von der Aktion auszuschließen, wenn der Teilnehmer falsche, unvollständige oder irreführende Angaben gemacht hat und/oder auf andere Art und Weise Betrug begeht oder versucht, diesen zu begehen und/oder nicht gemäß dieser Teilnahmebedingungen handelt. Bei einem Ausschluss hat der Teilnehmer keinerlei Möglichkeit, einen Preis zu gewinnen.</li>
            </ol>
            <h3>3. Datenerfassung</h3>
            <ol type="a">
                <li>Durch die Teilnahme ermächtigt der Teilnehmer Campaign.Plus, die im Rahmen der Aktion erforderlichen (personenbezogenen) Daten zu erheben und zu verarbeiten. Die Daten werden ausschließlich zur Benachrichtigung der Gewinner dieser Aktion und nicht für Marketingaktivitäten genutzt, es sei denn, dass der Telnehmer etwas anderes angegeben hat. </li>
                <li>Teilnehmer, die ihre ausdrückliche Zustimmung zum Erhalt solcher Mitteilungen von Campaign.Plus gegeben haben, können sich jederzeit durch Anklicken des entsprechenden Links, wie in jeder Mitteilung angegeben, abmelden. Personenbezogene Daten werden während der Kampagne gespeichert und sofort nach Ablauf der Kampagne gelöscht (es sei denn, es wurde die Erlaubnis erteilt, kommerzielle Angebote zu erhalten). Campaign.Plus garantiert, dass personenbezogene Daten nicht an Dritte weitergegeben werden.</li>
                <li>Gemäß der geltenden Gesetzgebung werden die personenbezogenen Daten des Teilnehmers erfasst und in der Datenbank gespeichert. Die personenbezogenen Daten werden erhoben und verarbeitet, um den reibungslosen Ablauf des Gewinnspiels zu gewährleisten und es Campaign.Plus zu ermöglichen, ihre Verpflichtungen im Zusammenhang mit dem Gewinnspiel zu erfüllen, insbesondere die Gewinner rechtzeitig zu informieren und die Teilnehmer über Angebote von Campaign.Plus sowie kommerzielle Angebote von Campaign.Plus zu informieren, wenn der Teilnehmer seine ausdrückliche Zustimmung gegeben hat.</li>
                <li>Jeder Teilnehmer hat das Recht auf Zugang, Änderung, Berichtigung oder Löschung seiner Daten. Sie haben auch das Recht, der Verarbeitung ihrer personenbezogenen Daten innerhalb des betreffenden Rahmens zu widersprechen, wenn schwerwiegende und berechtigte Gründe dafür vorliegen.</li>
            </ol>
            <h3>4. Preise</h3>
            <ol type="a">
                <li>Die Gewinner werden durch ein Losverfahren ermittelt. Die Verlosung erfolgt unmittelbar bei Teilnahme mithilfe eines Computers und ist gänzlich unparteiisch.</li>
                <li>Die folgenden Preise werden unter den Teilnehmern verlost:
                    <ol type="I">
                        <li>7x Paket "S": All inclusive Paket plus 500 Rubbellose im Wert von 999 Euro</li>
                        <li>5x Paket "M": All inclusive Paket plus 1.000 Rubbellose im Wert von 1.199 Euro</li>
                        <li>3x Paket "L": All inclusive Paket plus 2.500 Rubbellose im Wert von 2.500 Euro</li>
                        <li>Atraktive Rubbel-Rabatte auf deine nächste Campaign.Plus Buchung.</li>
                    </ol>
                </li>
                <li>Die Preise sind nicht eintauschbar, übertragbar und/oder in Bargeld auszahlbar. Ein Preis ist unteilbar und muss, wie von Campaign.Plus zugewiesen, angenommen werden.</li>
                <li>Campaign.Plus trägt, soweit nötig, Sorge für die Abgabe der Glücksspielsteuer.</li>
                <li>Preise, die aus irgendeinem Grund nicht ausgeschüttet werden, bleiben Eigentum von Campaign.Plus. Auch nicht in Empfang genommene Preise stehen Campaign.Plus zu.</li>
                <li>Über das Ergebnis kann nicht korrespondiert werden.</li>
            </ol>
            <h3>5. Haftung</h3>
            <ol type="a">
                <li>Campaign.Plus lässt größtmögliche Sorgfalt bei der Organisation der Aktion walten. Campaign.Plus haftet nicht für Schäden jedweder Art, die durch die (Teilnahme an der) Aktion entstehen oder damit zusammenhängen.</li>
                <li>Campaign.Plus ist nicht verantwortlich oder haftbar für Mängel (und daraus entstehende Schäden) der von Campaign.Plus und/oder Dritten zu vergebenen Preise.</li>
                <li>Für Druck-, Schreib-, Satz- oder andere ähnliche Fehler übernimmt Campaign.Plus keine Gewähr. Aus solchen Fehlern können keinerlei Ansprüche gegen Campaign.Plus abgeleitet werden.</li>
                <li>Campaign.Plus haftet nicht für Schäden jedweder Art, die einem Teilnehmer durch die Nutzung der Website oder von Links zu anderen Websites oder anderen technischen Diensten oder Tools entstehen.</li>
                <li>Campaign.Plus übernimmt keine Haftung für Schäden, die entstehen, wenn die Website aus irgendeinem Grund nicht (störungsfrei) verfügbar ist. Die Website kann ohne vorherige Ankündigung zeitweilig oder dauerhaft außer Betrieb gesetzt werden.</li>
                <li>Campaign.Plus übernimmt keine Haftung für Kosten, die dem Teilnehmer durch die Teilnahme an der Aktion entstehen.</li>
                <li>Der vorstehende Haftungsausschluss gilt auch für von Campaign.Plus beschäftigtes Hilfspersonal und von Campaign.Plus beauftragte Dritte.</li>
            </ol>
            <h3>6. Beschwerden</h3>
            <ol type="a">
                <li>Eventuelle Beschwerden, Fragen oder Anmerkungen zur Aktion können schriftlich in einem ausreichend frankierten Umschlag an die folgende Adresse gesandt werden: Campaign.Plus, Wollmarkstraße 115b, 33098 Paderborn, unter Angabe der betreffenden Aktion, oder über unsere Website: https://www.campaign.plus/kontakt/ Campaign.Plus beantwortet Beschwerden innerhalb von 15 Werktagen. </li>
            </ol>
            <h3>7. Sonstiges</h3>
            <ol type="a">
                <li>Falls eine oder mehrere dieser Teilnahmebedingungen nichtig sind oder nichtig werden, ist Campaign.Plus berechtigt, eine oder mehrere dieser Bestimmungen durch neue Bestimmungen zu ersetzen, sie so weit wie möglich den nichtigen oder nichtig gewordenen Bestimmungen anzupassen, ohne dass diese selbst nichtig sind oder werden.</li>
                <li>Auf diese Teilnahmebedingungen findet ausschließlich Deutsches Recht Anwendung.</li>
            </ol>
        </div>
        <div class="modal-footer d-flex justify-content-center bg-light">
          <button type="button" class="btn btn-success" data-dismiss="modal">Schließen</button>
        </div>
      </div>
    </div>
  </div>



  <div class="modal fade" id="preise_modal" tabindex="-1" role="dialog" aria-labelledby="price_title" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="price_title">🏆 Das kannst du gewinnen:</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <h5>Dein eigenes Rubbelkarten-Gewinnspiel (Online + Offline) für schnelle Neukundengewinnung</h5>
            <p className="m-0">Wir richten für dich alles Notwendige für <strong>dein eigenes Rubbelkarte-Gewinnspiel</strong> ein. Du erhältst neben den professionell gestalteten Rubbelkarten auch die DSGVO konforme Gewinnspielseite zum Einholen der Werberlaubnis inklusive aller Landingpages und E-Mail-Templates. Rubbel und gewinne jetzt eins der folgenden Preise:</p>
            <ul style={{paddingLeft: "24px", marginTop: "8px", marginBottom: "0px"}}>
              <li><strong>7x Paket "S":</strong> All inclusive Paket plus 500 Rubbellose im Wert von <strong>999 Euro</strong></li>
              <li><strong>5x Paket "M":</strong> All inclusive Paket plus 1.000 Rubbellose im Wert von <strong>1.199 Euro</strong></li>
              <li><strong>3x Paket "L":</strong> All inclusive Paket plus 2.500 Rubbellose im Wert von <strong>2.500 Euro</strong></li>
              <li><strong>Jedes Los gewinnt:</strong> Atraktive Rubbel-Rabatte auf deine nächste Campaign.Plus Buchung.</li>
            </ul>
        </div>
        <div class="modal-footer d-flex justify-content-center bg-light">
          <button type="button" class="btn btn-success" data-dismiss="modal">Schließen</button>
        </div>
      </div>
    </div>
  </div>


  </div>
      </>
    );
  }

  export default Scratch