import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
}

const DateTime = ({ value }) => {
    const { t, i18n } = useTranslation()
    if(value === null || value === undefined) {
        return null
    }
    return (
        <span> { (new Date(value)).toLocaleString(i18n.language, options)} {t('Uhr')}</span>
    )
}

DateTime.propTypes = {
    value: PropTypes.string
}

export default DateTime