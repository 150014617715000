import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Select as MuiSelect, FormControl, InputLabel, FormHelperText, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LoadingAnimation from 'components/LoadingAnimation';
import senderAddresses from 'models/senderAddresses';
import senderDomain from 'models/senderDomain';
import { cornermailSenderAddresses } from 'constants/filters';

const Select = ({ async=false, ...props }) => {
    if(async) { 
        return <AsyncSelect {...props} />
    }else {
        return <SyncSelect {...props} />
    }
}

const AsyncSelect = ({ model, renderOption, ...props}) => {
    const [ items, setItems ] = useState(null)
    const {input: {value,name, ...field}} = props
    const { t } = useTranslation()

    useEffect(() => {
        (async () => {
            try  {
                let result = null
                let result2 = null
                //! short term fix for senderdomain in webform. revert when fixed later
                if(model === senderDomain){
                    result = await model.getList(1,10, undefined, null, cornermailSenderAddresses())
                    result2 = await senderAddresses.getList(1, 10, undefined, null, cornermailSenderAddresses())
                    setItems(result.data.concat(result2.data))
                } else {
                    result = await model.getList()
                    setItems(result.data)
                }
                
                if(result.data.length === 1 || !field.value) {
                    // preset value if only one option is availlable
                    field.onChange({ target: { value: result.data[0], name: field.name }})
                }
            }catch(e) {
                //TODO proper error handling
                console.error("e: ", e)
            }
        })()
        }, [model]) // DO NOT ADD FIELD TO ARRAY. FUCKS WITH THE SENDERDOMAIN IN WEBFORMSETTINGS
    console.log("items: ", items)

    if(!items){
        return <LoadingAnimation/>
    }
    
    return (
        <SyncSelect autoWidth name={name} {...props}>
            {items === null ? t("laden ...") : null}
            {items !== null && items.length === 0 && t("Keine Ergebnisse")}
            {items !== null && items.length > 0 && (
                items.map(item => renderOption(item))
            )}
        </SyncSelect>
    )
}

AsyncSelect.propTypes = {
    model: PropTypes.shape({
        getList: PropTypes.func.isRequired
    }).isRequired,
    renderOption: PropTypes.func
}

AsyncSelect.defaultProps = {
    renderOption: option => (
        <MenuItem value={option} key={option.id}>
            {option.name || option.email || option.label || option.domain}
        </MenuItem>
    )
}

const SyncSelect = ({ label, children, formControlProps, labelProps, ...props }) => {
    const { t } = useTranslation()
    const {input, meta, ...slimmedProps} = props
    const {name, ...field} = input
    // const {input:{name, ...field }, meta} = props
    let errorText = meta.error && meta.touched ? meta.error : ''

    if (props.typeParams) {
        if (props.typeParams.length === 0) {
            errorText = t("Keine Auswahl möglich")
        }
    }

    //const {input, ...slimedProps} = props

    const handleChange = (e) => {
        field.onChange(e)
        props.onChange && props.onChange(e)
    }

    const handleBlur = (e) => {
        field.onBlur(e)
        props.onBlur && props.onBlur(e)
    }

    return (
        <FormControl {...formControlProps} error={!!errorText} fullWidth>
            {label && <InputLabel {...labelProps}>{ label }</InputLabel>}
            <MuiSelect name={name} {...field} label={label} value={field.value || ""} {...slimmedProps} onChange={handleChange} onBlur={handleBlur}>
                { children }
            </MuiSelect>
            {!!errorText && <FormHelperText>{ errorText }</FormHelperText> }
        </FormControl>
    )
}

SyncSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    formControlProps: PropTypes.object,
    labelProps: PropTypes.object
}

Select.defaultProps = {
    formControlProps: {
        margin: "normal"
    },
    labelProps: {}
}

export default Select 