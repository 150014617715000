import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { DialogProps } from "hooks/useDialog"
import { makeStyles, Theme, Dialog, Grid } from "@material-ui/core"
import DialogTitle from "dialogs/DialogTitle"
import DialogContent from "dialogs/DialogContent"
import DialogActions from "dialogs/DialogActions"
import Button from "components/Button/Button"
import { Fa, Typography } from "styled/muiComponents"
import chats from "models/chats"
import LoadingAnimation from "components/LoadingAnimation"

const useStyles = makeStyles<Theme, {}>(theme => ({
    errorColumn: {
        marginBottom: theme.spacing(1.25),
        "&:last-child": {
            marginBottom: 0
        }
    },
    warningIcon: {
        color:  theme.palette.companyRed.main,
        marginRight: theme.spacing(1)
    }
}))

type Props = {
    errors: string[]
    id: number | null
}

const SaveChatDialog: React.FC<Props & DialogProps> = ({ onClose, onError, open, errors, id }) => {
    const { t } = useTranslation()
    const classes = useStyles({})
    const [webforms, setWebforms] = useState<Number[] | null>(null)

    useEffect(() => {
        if(!id){
            setWebforms([])
            return
        }
        const checkUse = async () => {
            const response = await chats.one(id).checkUse()
            setWebforms(response)
        } 
        checkUse()
    },[id])

    const handleClose = () => {
        onClose({})
    }

    const handleSaveAnyway = () => {
        onClose({save: true, errors: errors.length})
    }

    if(!webforms){
        return <LoadingAnimation/>
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>
                <Fa icon={["fal", "exclamation-triangle"]} className={classes.warningIcon} mr={1.75} />{t("Achtung")}
            </DialogTitle>
            <DialogContent>
                <Grid container direction="column">
                    {webforms.length === 0 ?
                        <Typography variant="h5" mb={2}>{t("Dein Chat ist noch nicht vollständig eingerichtet. Erst wenn du alle nachstehende Fehler behoben hast, kann der Chat verwendet werden.")}</Typography>
                        :
                        <Typography variant="h5" mb={2}>{t("Dieser Chat wird bereits in Anmeldestrecken verwendet. Du kannst diesen Chat nur speichern wenn du alle nachstehenden Fehler behoben hast.")}</Typography>
                    }
                    {errors.map((error: string, idx: number) =>
                        <Grid container item key={idx} direction="row" alignItems="baseline" justify="flex-start" className={classes.errorColumn}>
                            <Grid item>
                                <Fa icon={["fal", "exclamation-triangle"]} className={classes.warningIcon} />
                            </Grid>
                            <Grid item>
                                <Typography>
                                    {error}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container justify="space-between">
                    <Button onClick={handleClose} variant="contained" color="error"><Fa icon={["fas", "times"]} mr={1} size={"lg"} /> {t("/common:Abbrechen")}</Button>
                    <Button onClick={handleSaveAnyway} variant="contained" color="success" disabled={webforms.length !== 0}><Fa icon={["fas", "floppy-disk"]} mr={1} /> {t("Dennoch speichern")}</Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default SaveChatDialog