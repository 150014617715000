import React from "react"
import { SetupNavigationProps, Navigation, ISetupInformation } from "../SetupWizard"
import { Grid } from "@material-ui/core"
import { Form } from 'react-final-form'
import FormikInput from "components/formik/FormikInput"
import { Typography } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { useTranslation } from "react-i18next"

type Props = {
    navigationProps: SetupNavigationProps
    classes: any
        handleChange: (event: any) => void
    setupInformation: ISetupInformation
}

const SetupExtras = ({ navigationProps, classes, handleChange, setupInformation }: Props) => {
    const { t } = useTranslation()
    const handleSubmit = () => {
    }

    const initialValues = {
        additional_information: setupInformation.additional_information
    }

    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}
            render={({ handleSubmit, submitting }) => (
                <Grid container item direction="column" className={classes.root}>
                    <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" justify="flex-start">
                                <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                    <Grid item sm={4}>
                                        <div style={{ maxWidth: "150px" }}>
                                            <Pixi size="full" pose="waiting"></Pixi>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <div className={classes.headerDescription} >
                                        <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                            <Typography variant="h5" className={classes.pixiSpeech}>
                                                {t('Wenn ich etwas vergessen haben sollte, was deiner Meinung nach unbedingt noch dem Impressum hinzugefügt werden soll, dann kannst du es hier noch ergänzen. Oder überspringe diesen Schritt mit einem Klick auf "Weiter".')}
                                            </Typography>
                                        </Typist>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item container className={classes.formContent}>
                                    <Grid item sm={12}>
                                        <FormikInput
                                            type="textarea"
                                            value={setupInformation.additional_information}
                                            name="additional_information"
                                            label={t("Zusätzliche Angaben (optional)")}
                                            variant="outlined"
                                            rows={5}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                                
                                <Grid item sm={12}>
                                    <Navigation  {...navigationProps} />
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item className={classes.formNote}>
                            <Typography variant="body1" className={classes.requiredNotice}>
                                <i>* {t("Pflichtfeld")}</i>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    )
}


export default SetupExtras