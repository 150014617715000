import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faEdit,
    faUserUnlock,
    faLockAlt,
    faEnvelope,
    faTachometerAltFast,
    faUsersCrown,
    faUserPlus,
    faMagnet,
    faCog,
    faSlidersH,
    faStar,
    faHeadset,
    faUserCog,
    faPowerOff,
    faUser,
    faArrowRight,
    faChevronLeft,
    faChevronRight,
    faExclamationTriangle,
    faPlus,
    faExpandWide,
    faLaptop,
    faTablet,
    faMobile,
    faText,
    faTextSize,
    faFillDrip,
    faTint,
    faImage,
    faWindow,
    faShieldCheck,
    faLockOpenAlt,
    faQrcode,
    faSearch,
    faSearchPlus,
    faSearchMinus,
    faPlusCircle,
    faMinusCircle,
    faLandmark,
    faIndustryAlt,
    faUserTie,
    faMoneyCheckEdit,
    faBriefcase,
    faCoins,
    faDownload,
    faPencil,
    faLongArrowLeft,
    faWrench,
    faFlagCheckered,
    faLayerPlus,
    faInfinity,
    faBan,
    faShoppingCart,
    faAngleDoubleUp,
    faAngleDoubleDown,
    faCommentAltSmile,
    faRandom,
    faRocketLaunch,
    faStars,
    faMousePointer,
    faMessageQuestion,
    faCommentAltLines,
    faTrash,
    faGlobe,
    faExternalLink,
    faCopy,
    faMessageDots,
    faFloppyDisk,
    faClapperboardPlay,
    faMessagePen,
    faFlask,
    faMessagePlus,
    faRotateLeft,
    faChessKing,
    faCrown,
    faIndustryWindows,
    faPhoneFlip,
    faPaperPlane,
    faMemoPad,
    faAt,
    faXmark,
    faArrowsMaximize,
    faFilePdf,
    faFilterList,
    faFileArrowDown,
    faImages,
    faArrowRightLong,
    faCloudDownloadAlt,
    faPenToSquare,
    faFloppyDiskCircleXmark,
    faCopyright,
    faArrowUp,
    faArrowDown,
    faSquareDashed,
    faMessageBot,
    faPrint,
    faSquareList,
    faLineHeight,
    faBorderOuter,
    faTags,
    faUserTag

 } from '@fortawesome/pro-light-svg-icons'

library.add(
    faEdit,
    faUserUnlock,
    faLockAlt,
    faEnvelope,
    faTachometerAltFast,
    faUsersCrown,
    faUserPlus,
    faMagnet,
    faCog,
    faSlidersH,
    faStar,
    faHeadset,
    faUserCog,
    faPowerOff,
    faUser,
    faArrowRight,
    faChevronLeft,
    faChevronRight,
    faExclamationTriangle,
    faPlus,
    faExpandWide,
    faLaptop,
    faTablet,
    faMobile,
    faText,
    faTextSize,
    faFillDrip,
    faTint,
    faImage,
    faWindow,
    faShieldCheck,
    faLockOpenAlt,
    faQrcode,
    faSearch,
    faPlusCircle,
    faSearchPlus,
    faSearchMinus,
    faMinusCircle,
    faLandmark,
    faIndustryAlt,
    faUserTie,
    faMoneyCheckEdit,
    faBriefcase,
    faCoins,
    faDownload,
    faPencil,
    faLongArrowLeft,
    faWrench,
    faFlagCheckered,
    faLayerPlus,
    faInfinity,
    faBan,
    faShoppingCart,
    faAngleDoubleUp,
    faAngleDoubleDown,
    faCommentAltSmile,
    faRandom,
    faRocketLaunch,
    faStars,
    faMousePointer,
    faMessageQuestion,
    faCommentAltLines,
    faTrash,
    faGlobe,
    faExternalLink,
    faCopy,
    faMessageDots,
    faFloppyDisk,
    faClapperboardPlay,
    faMessagePen,
    faFlask,
    faMessagePlus,
    faRotateLeft,
    faChessKing,
    faCrown,
    faIndustryWindows,
    faPhoneFlip,
    faPaperPlane,
    faMemoPad,
    faAt,
    faXmark,
    faArrowsMaximize,
    faFilePdf,
    faFilterList,
    faFileArrowDown,
    faImages,
    faArrowRightLong,
    faCloudDownloadAlt,
    faPenToSquare,
    faFloppyDiskCircleXmark,
    faCopyright,
    faArrowUp,
    faArrowDown,
    faSquareDashed,
    faMessageBot,
    faPrint,
    faSquareList,
    faLineHeight,
    faBorderOuter,
    faTags,
    faUserTag
    )