import React, {useState, useEffect} from 'react'
import { Grid, Card, makeStyles } from '@material-ui/core';
import { Fa, Typography } from "styled/muiComponents"
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import PagePadding from 'components/PagePadding';
import { theme } from 'theme';
import Skeleton from '@material-ui/lab/Skeleton';
import webformOpenTracking from 'models/webformOpenTracking';
import { onlyQRTrackings } from 'constants/filters';
import ratingOpenTracking from 'models/ratingOpenTracking';
import recipient from 'models/recipient';
import { useSidebar } from 'hooks/useSidebar';
import useSession from 'hooks/useSession';

const useStyles = makeStyles({
    root: {
      marginBottom: theme.spacing(2)
    },
    dashboardCard: {
      height: "200px",
      width: "200px",
      padding: theme.spacing(3),
      cursor: "pointer",
      "&:hover $statisticCardLink": {
        color: theme.palette.secondary.main
      },
      "&:hover $statisticCardLinkIcon": {
        marginLeft: theme.spacing(0.75),
      }
    },
    statisticCardLinkIcon: {
      transition: "0.1s all linear",
    },
    statisticCardLink: {
      transition: "0.3s all linear",
    },
    dashboardCardStatistics: {
      backgroundColor: theme.palette.lightGrey.main,
      overflow: "hidden",
      position: "relative",
      height: "120px",
      width: "auto",
      "&::before": {
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        content: "''",
        display: "block",
        zIndex: 2,
        position: "absolute",
        background: "url(/assets/images/profile_bg_transparent.png)",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        overflow: "hidden",
        opacity: "0.15"
      }
    },
    dashboardCardContent: {
      flexGrow: 1,
      height: "100%"
    },
    statisticIcons: {
      //fontSize: "1.25rem"
      color: theme.palette.secondary.main
    },
    kpi: {
      fontSize: "3rem",
      //color: theme.palette.success.main,
      lilneHeight: "1.25em",
      fontWeight: 500
    },
    headline: {
      fontSize: "2em",
      fontWeight: 300,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
      display: "flex",
      flexWrap: "nowrap",
      alignContent: "center",
      alignItems: "center",
    },
    greenHeadline: {
      cursor: "pointer",
      transition: "0.3s color ease",
      "&:hover": {
        color: theme.palette.success.main
      }
    },
    greenHeadlineIcon: {
      color: theme.palette.success.main
    },
    createCardHoverGreen: { 
      transition: "color 0.3s, box-shadow 0.4s ease",
      "&:hover": {
        boxShadow: "0px 2px 3px -1px rgb(0 0 0 / 20%), 0px 3px 10px 5px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        color: theme.palette.success.main
      }
    },
    blueHeadline: {
      cursor: "pointer",
      transition: "0.3s color ease",
      "&:hover": {
        color: "#269dca"
      }
    },
    blueHeadlineIcon: {
      color: "#269dca"
    },
    createCardHoverBlue: { 
      transition: "color 0.3s, box-shadow 0.4s ease",
      "&:hover": {
        boxShadow: "0px 2px 3px -1px rgb(0 0 0 / 20%), 0px 3px 10px 5px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        color: "#269dca"
      }
    },
    purpelHeadline: {
      cursor: "pointer",
      transition: "0.3s color ease",
      "&:hover": {
        color: "#e300ff"
      }
    },
    purpelHeadlineIcon: {
      color: "#e300ff"
    },
    createCardHoverPurpel: { 
      transition: "color 0.3s, box-shadow 0.4s ease",
      "&:hover": {
        boxShadow: "0px 2px 3px -1px rgb(0 0 0 / 20%), 0px 3px 10px 5px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        color: "#e300ff"
      }
    },
    orangeHeadline: {
      cursor: "pointer",
      transition: "0.3s color ease",
      "&:hover": {
        color: "#ffc600"
      }
    },
    orangeHeadlineIcon: {
      color: "#ffc600"
    },
    createCardHoverOrange: { 
      transition: "color 0.3s, box-shadow 0.4s ease",
      "&:hover": {
        boxShadow: "0px 2px 3px -1px rgb(0 0 0 / 20%), 0px 3px 10px 5px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        color: "#ffc600"
      }
    },
    createCardHover: {
      
    },
    headlineIcon: {
      fontSize: "0.7em"
    },
    skeleton: {      
      borderRadius: "8px",
    }
})

const Dashboard = () => {
  const history = useHistory()
  const classes = useStyles()
  const { t } = useTranslation()
  const {logo} = useSession()

  console.log(logo)

  const [tracking, setTracking ] = useState({
    recipients: 0,
    webformQR: 0,
    ratingQR: 0
  })

  const [hasSkeleton, setSkeleton] = useState(true);
  setTimeout(() => setSkeleton(false), 3500);

  useEffect(() => {
    const filterObject = onlyQRTrackings()
    
    const fetchCounts = async () => {
      //@ts-ignore
      const responseWf = await webformOpenTracking.getList(1,10, undefined, null, filterObject )
      //@ts-ignore
      const responseRt = await ratingOpenTracking.getList(1, 10, undefined, null, filterObject )
      const responseR = await recipient.getList()
      setTracking({
        recipients: responseR.meta.item_count,
        webformQR: responseWf.meta.item_count,
        ratingQR: responseRt.meta.item_count
      })
    }
    fetchCounts()
  },[])

  const { fixedClose } = useSidebar()
  
  const handleContactClick = () => {
    history.push("/app/stock/recipients")
  }
  const handleFormsClick = () => {
    history.push("/app/stock/webforms")
  }
  const handlePrintClick = () => {
    history.push("/app/stock/qrforms")
  }
  const handleChatsClick = () => {
    history.push("/app/stock/chats")
  }
  const handleRatingClick = () => {
    history.push("/app/stock/ratings")
  }
  const handleCreateRatingClick = () => {
    fixedClose()
    history.push("/app/stock/ratings/create")
  }  
  const handleCreatePrintClick = () => {
    fixedClose()
    history.push("/app/stock/qrforms/create")
  }
  const handleCreateFormClick = () => {
    fixedClose()
    history.push("/app/stock/webforms/create")
  }
  const handleCreateChatClick = () => {
    fixedClose()
    history.push("/app/stock/chats/builder")
  }

  return (
    <PagePadding>
      <></>
    	<Grid container item spacing={2} className={classes.root}>
			<Grid container item spacing={2} lg={6}>
				<Grid container item md={6}>
					<Grid item xs>
						<Card onClick={handleContactClick} className={[classes.dashboardCard, classes.dashboardCardStatistics].join(' ')}>
							<Grid container justify='center' alignItems='flex-start' direction="column" className={classes.dashboardCardContent} wrap="nowrap">
								<Typography><Fa icon={["fas", "user"]} fixedWidth mr={1} className={classes.statisticIcons}/>{t("Kontakte gesamt")} </Typography>
								<Typography className={classes.kpi}>{tracking.recipients}</Typography>
								<Typography className={classes.statisticCardLink}>{t("Kontakte anzeigen")} <Fa icon={["fal", "arrow-right-long"]} fixedWidth className={classes.statisticCardLinkIcon}/></Typography>
							</Grid>
						</Card>
					</Grid>
				</Grid>
				<Grid container item md={6}>
					<Grid item xs>
						<Card className={[classes.dashboardCard, classes.dashboardCardStatistics].join(' ')} style={{cursor: "unset"}}>
							<Grid container justify='center' alignItems='flex-start' direction="column" className={classes.dashboardCardContent} wrap="nowrap">
								<Typography><Fa icon={["fas", "qrcode"]} fixedWidth mr={1} className={classes.statisticIcons}/>{t("QR-Code Scans")} </Typography>
								<Typography className={classes.kpi}>{tracking.ratingQR + tracking.webformQR}</Typography>
								<Typography>{t("Scans insgesamt")}</Typography>
							</Grid>
						</Card>
					</Grid>
				</Grid>
			</Grid>
			<Grid container item spacing={2} lg={6}>
				<Grid container item md={6}>
					<Grid item xs>
						<Card className={[classes.dashboardCard, classes.dashboardCardStatistics].join(' ')} style={{cursor: "unset"}}>
							<Grid container justify='center' alignItems='flex-start' direction="column" className={classes.dashboardCardContent} wrap="nowrap">
								<Typography><Fa icon={["fas", "qrcode"]} fixedWidth mr={1} className={classes.statisticIcons}/>{t("Scans Formulare")} </Typography>
								<Typography className={classes.kpi}>{tracking.webformQR}</Typography>
								<Typography>{t("Scans für Anmeldungen")}</Typography>
							</Grid>
					</Card>
					</Grid>
				</Grid>
				<Grid container item md={6}>
					<Grid item xs >
						<Card className={[classes.dashboardCard, classes.dashboardCardStatistics].join(' ')} style={{cursor: "unset"}}>
							<Grid container justify='center' alignItems='flex-start' direction="column" className={classes.dashboardCardContent} wrap="nowrap">
								<Typography><Fa icon={["fas", "qrcode"]} fixedWidth mr={1} className={classes.statisticIcons}/>{t("Scans Bewertungen")} </Typography>
								<Typography className={classes.kpi}>{tracking.ratingQR}</Typography>
								<Typography>{t("Scans für Bewertungen")}</Typography>
							</Grid>
						</Card>
					</Grid>
				</Grid>
			</Grid>
      </Grid>
      
      <Grid container spacing={2} className={classes.root}>
        <Grid item lg={6}>
          <Grid container direction='column'>
            <Grid item>
              <Typography variant='h2'className={classes.headline}><Fa icon={["fal", "user"]} fixedWidth mr={1} className={[classes.headlineIcon, classes.greenHeadlineIcon].join(' ')}/> <span onClick={handleFormsClick} className={classes.greenHeadline}>{t("Anmeldeformulare")}</span></Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <Card className={[classes.dashboardCard, classes.createCardHoverGreen].join(' ')} onClick={handleCreateFormClick}>
                  <Grid container justify='center' alignItems='center' direction="column" className={[classes.dashboardCardContent, classes.createCardHover].join(' ')}>
                      <Grid item>
                          <Fa icon={["fat", "plus"]} size="4x" style={{display: "block"}} mb={2}/>
                      </Grid>
                      <Grid item>
                          {t("Formular erstellen")}
                      </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item>
                <Card className={classes.dashboardCard} style={{cursor: "unset"}}>
                  <Grid container alignContent='center' justify='center' style={{height: "100%"}}>
                      {hasSkeleton ? (<Skeleton animation="pulse" width="85%" height="20px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="85%" height="20px" className={classes.skeleton}/>)}
                      {hasSkeleton ? (<Skeleton animation="pulse" width="65%" height="15px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="65%" height="15px" className={classes.skeleton}/>)}
                    </Grid>
                </Card>
              </Grid>
              <Grid item>
                <Card className={classes.dashboardCard} style={{cursor: "unset"}}>
                    <Grid container alignContent='center' justify='center' style={{height: "100%"}}>
                      {hasSkeleton ? (<Skeleton animation="pulse" width="85%" height="20px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="85%" height="20px" className={classes.skeleton}/>)}
                      {hasSkeleton ? (<Skeleton animation="pulse" width="65%" height="15px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="65%" height="15px" className={classes.skeleton}/>)}
                    </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6}>
          <Grid container direction='column'>
            <Grid item>
              <Typography variant='h2' className={classes.headline}><Fa icon={["fal", "print"]} fixedWidth mr={1} className={[classes.headlineIcon, classes.blueHeadlineIcon].join(' ')}/> <span onClick={handlePrintClick} className={classes.blueHeadline}>{t("Printmedien")}</span></Typography>
            </Grid>
            <Grid item>
              <Grid item container spacing={2}>
                <Grid item>
                  <Card className={[classes.dashboardCard, classes.createCardHoverBlue].join(' ')} onClick={handleCreatePrintClick}>
                      <Grid container justify='center' alignItems='center' direction="column" className={[classes.dashboardCardContent, classes.createCardHover].join(' ')}>
                          <Grid item>
                              <Fa icon={["fat", "plus"]} size="4x" style={{display: "block"}} mb={2}/>
                          </Grid>
                          <Grid item>
                              {t("Printmedieum erstellen")}
                          </Grid>
                      </Grid>
                  </Card>
                </Grid>
                <Grid item>
                  <Card className={classes.dashboardCard} style={{cursor: "unset"}}>
                     <Grid container alignContent='center' justify='center' style={{height: "100%"}}>
                      {hasSkeleton ? (<Skeleton animation="pulse" width="85%" height="20px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="85%" height="20px" className={classes.skeleton}/>)}
                      {hasSkeleton ? (<Skeleton animation="pulse" width="65%" height="15px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="65%" height="15px" className={classes.skeleton}/>)}
                    </Grid>
                  </Card>
                </Grid>
                <Grid item>
                  <Card className={classes.dashboardCard} style={{cursor: "unset"}}>
                    <Grid container alignContent='center' justify='center' style={{height: "100%"}}>
                      {hasSkeleton ? (<Skeleton animation="pulse" width="85%" height="20px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="85%" height="20px" className={classes.skeleton}/>)}
                      {hasSkeleton ? (<Skeleton animation="pulse" width="65%" height="15px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="65%" height="15px" className={classes.skeleton}/>)}
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.root}>
        <Grid item lg={6}>
          <Grid container direction='column'>
            <Grid item>
              <Typography variant='h2' className={classes.headline}><Fa icon={["fal", "message-bot"]} fixedWidth mr={1} className={[classes.headlineIcon, classes.purpelHeadlineIcon].join(' ')}/> <span onClick={handleChatsClick} className={classes.purpelHeadline}>{t("Chats")}</span></Typography>
            </Grid>
            <Grid item>
              <Grid item container spacing={2}>
                <Grid item>
                  <Card className={[classes.dashboardCard, classes.createCardHoverPurpel].join(' ')} onClick={handleCreateChatClick}>
                      <Grid container justify='center' alignItems='center' direction="column" className={[classes.dashboardCardContent, classes.createCardHover].join(' ')}>
                          <Grid item>
                              <Fa icon={["fat", "plus"]} size="4x" style={{display: "block"}} mb={2}/>
                          </Grid>
                          <Grid item>
                              {t("Chat erstellen")}
                          </Grid>
                      </Grid>
                  </Card>
                </Grid>
                <Grid item>
                  <Card className={classes.dashboardCard} style={{cursor: "unset"}}>
                    <Grid container alignContent='center' justify='center' style={{height: "100%"}}>
                      {hasSkeleton ? (<Skeleton animation="pulse" width="85%" height="20px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="85%" height="20px" className={classes.skeleton}/>)}
                      {hasSkeleton ? (<Skeleton animation="pulse" width="65%" height="15px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="65%" height="15px" className={classes.skeleton}/>)}
                    </Grid>
                  </Card>
                </Grid>
                <Grid item>
                  <Card className={classes.dashboardCard} style={{cursor: "unset"}}>
                    <Grid container alignContent='center' justify='center' style={{height: "100%"}}>
                      {hasSkeleton ? (<Skeleton animation="pulse" width="85%" height="20px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="85%" height="20px" className={classes.skeleton}/>)}
                      {hasSkeleton ? (<Skeleton animation="pulse" width="65%" height="15px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="65%" height="15px" className={classes.skeleton}/>)}
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6}>
          <Grid container direction='column'>
            <Grid item>
              <Typography variant='h2' className={classes.headline}><Fa icon={["fal", "star"]} fixedWidth mr={1} className={[classes.headlineIcon, classes.orangeHeadlineIcon].join(' ')}/> <span onClick={handleRatingClick} className={classes.orangeHeadline}>{t("Bewertungensformular")}</span></Typography>
            </Grid>
            <Grid item>
              <Grid item container spacing={2}>
                <Grid item>
                  <Card className={[classes.dashboardCard, classes.createCardHoverOrange].join(' ')} onClick={handleCreateRatingClick}>
                      <Grid container justify='center' alignItems='center' direction="column" className={[classes.dashboardCardContent, classes.createCardHover].join(' ')}>
                          <Grid item>
                              <Fa icon={["fat", "plus"]} size="4x" style={{display: "block"}} mb={2}/>
                          </Grid>
                          <Grid item>
                              {t("Bewertung erstellen")}
                          </Grid>
                      </Grid>
                  </Card>
                </Grid>
                <Grid item>
                  <Card className={classes.dashboardCard} style={{cursor: "unset"}}>
                    <Grid container alignContent='center' justify='center' style={{height: "100%"}}>
                      {hasSkeleton ? (<Skeleton animation="pulse" width="85%" height="20px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="85%" height="20px" className={classes.skeleton}/>)}
                      {hasSkeleton ? (<Skeleton animation="pulse" width="65%" height="15px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="65%" height="15px" className={classes.skeleton}/>)}
                    </Grid>
                  </Card>
                </Grid>
                <Grid item>
                  <Card className={classes.dashboardCard} style={{cursor: "unset"}}>
                    <Grid container alignContent='center' justify='center' style={{height: "100%"}}>
                      {hasSkeleton ? (<Skeleton animation="pulse" width="85%" height="20px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="85%" height="20px" className={classes.skeleton}/>)}
                      {hasSkeleton ? (<Skeleton animation="pulse" width="65%" height="15px" className={classes.skeleton}/>) : (<Skeleton animation={false} width="65%" height="15px" className={classes.skeleton}/>)}
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PagePadding>
  )
}

export default Dashboard
