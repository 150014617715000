//import React, {useEffect, useState} from "react"
import imprints from "models/imprint"

let mustHave = [
    "city",
    "company_name",
    "county",
    "imprint_mail",
    "legal_form",
    "phone_number",
    "postal_code",
    "street_name",
    "street_number",
]

const isImprintValid = async () => {
    const imprint = await imprints.getFirst()
    if (!imprint){
        return false
    }

    if (!imprint.legal_form.includes("e.K.")) {
        mustHave = mustHave.concat(["company_board"])
    }

    if (imprint.legal_form.includes("Andere")) {
        mustHave = mustHave.concat(["legal_form_offlist"])
    }

    return (mustHave.every(item => imprint[item] !== "") && (imprint.vat_identifier !== "" || imprint.ust_identifier !== ""))
}

export default isImprintValid