import { useState, useEffect, useMemo } from 'react'

const VERSION = 0

const useViewColumns = (modelName: string): [Record<string, boolean>, (changedColumn: string, action:string) => void] => {
    const [ viewColumns, setViewColumns] = useState<Record<string, boolean>>({})
    const localStorageEntry = useMemo(() => `${modelName}_view_columns`, [modelName])


    useEffect(() => {
        const result = readFromStorage()
        if(result) {
            setViewColumns(result)
        }
        // eslint-disable-next-line
    }, [modelName])

    const handleSetViewColumns = (changedColumn: string, action: string): void => {
        switch(action) {
            case "add": 
                setViewColumns({...viewColumns, [changedColumn]: true})
                writeToStorage({...viewColumns, [changedColumn]: true})
                break
            case "remove": 
                setViewColumns({...viewColumns, [changedColumn]: false})
                writeToStorage({...viewColumns, [changedColumn]: false})
                break
            default: console.warn("unknown action: ", {changedColumn, action})
        }
    }

    const writeToStorage = (data: Record<string, boolean>) => {
        return localStorage.setItem(localStorageEntry, JSON.stringify({ data, version: VERSION }))
    }

    const readFromStorage = () => {
        const result = localStorage.getItem(localStorageEntry)
        if(result) {
            const { data, version } = JSON.parse(result)
            if(version === VERSION) {
                return data 
            }else {
                //TODO migration code here later
            }
        }
        return null
    }

    return [
        viewColumns,
        handleSetViewColumns
    ]
}

export default useViewColumns 