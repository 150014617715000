import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'

import FormikInput from '../formik/FormikInput'
import { useFormState, useForm } from 'react-final-form'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles( theme => ({
    root: {
      margin: "0",
      '& fieldset': {
        borderWidth: '1px !important',

      },
      '& input': {
        backgroundColor: "#ffffff",
      },
    }
}))

const TableInput = ({ schema, onCancel, ...props }) => {
    const {submitting } = useFormState()
    const {submit} = useForm()
    
    const [open, setOpen] = useState(true)
    const classes = useStyles()

    useEffect(() => {
        if(!open) {
            onCancel()
        }
    }, [open, onCancel])

    switch(schema.type) {
        case "string": return (
            <FormikInput 
                type={schema.type} 
                autoFocus={true} 
                inputProps={ { style: { fontSize: "0.875rem" } } } 
                margin="dense"
                variant="outlined"
                autosize
                onFocus={e => e.target.select()} 
                InputProps={{
                    endAdornment: submitting ? (
                        <InputAdornment position="end">
                            <CircularProgress color="primary" size={16} />
                        </InputAdornment>
                    ) : null
                }}
                onBlur={onCancel}
                onKeyDown={(e) => {
                    if(e.key === "Escape") {
                        onCancel()
                    }
                }}
                className={classes.root}
                {...props} 
            />
        )
        case "boolean": 
            return (
                <FormikInput 
                    type={schema.type} 
                    open={open}
                    formControlProps={{margin:"none"}}
                    onChange={(e) => {
                        submit()
                    }}
                    onClose={() => setOpen(false)}
                    {...props}
                />
            )
        case "date": 
            return (
            <FormikInput 
                type={schema.type} 
                open={open}
                onAccept={(e) => {
                    submit()
                }}
                onClose={() =>
                    setOpen(false)
                }
                {...props} 
            />
        )
        case "enum": 
            return (
                <FormikInput
                    type={schema.type}
                    typeParams={schema.enum_values}
                    open={open}
                    formControlProps={{margin:"none"}}
                    onChange={(e) => {
                        submit()
                    }}
                    onClose={() => setOpen(false)}
                    {...props}
                />
            )
        default:
            console.error("unsupported type: ", schema, props)
            return null
    }
}

export default TableInput