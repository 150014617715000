import React from "react"
import Button from "components/Button/Button"
import { DialogProps } from 'hooks/useDialog'
import { Trans, useTranslation } from "react-i18next"
import { DialogActions, DialogContent, Dialog, DialogContentText, Divider, makeStyles, Theme } from "@material-ui/core"
import DialogTitle from "dialogs/DialogTitle"
import tariffs, { ITariff } from "models/tariffs"

const useStyles = makeStyles<Theme, {}>(theme => ({
    title: {
        backgroundColor: theme.palette.primary.light,
        color: "white"
    },
    divider: {
        backgroundColor: theme.palette.companyRed.main,
        height: "2px"
    },
}))


export type Response = {
    action: null
}

export type Props = {
    subscription: {
        name: string
        scans:number | null
        price_per_month: number,
        price_additional_scans: number | null
    } 
}

const ConfirmPurchaseDialog: React.FC<Props & DialogProps> = ({ onClose, open, onError, subscription }) => {

    const { t } = useTranslation()
    const classes = useStyles({})

    const handleClose = () => {
        onClose({ action: null })
    }

    console.log(subscription)

    // const handleCancel = () => {
    //     tariffs.cancelTariff()
    //         .then(() => onClose({ success: true }))
    //         .catch((error) => onError(error))
    // }
    
    const handlePurchase = () => {
        onClose({action: 'purchase'})
    }

    //TODO upgrade downgrade

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle onClose={handleClose} className={classes.title}>
                Tarif Kaufen?
            </DialogTitle>
            <Divider className={classes.divider}/>
            <DialogContent>
                <DialogContentText>
                    <Trans>
                        {t(`Willst du die Subscription ${subscription.name.toUpperCase()} für ${subscription.price_per_month}€ wirklich kaufen?`)}
                        {true ? // TODO upgrade/downgrade
                            t('Da dies ein Upgrade ist, wird die neue Subscription sofort gebucht')
                            :
                            t('Da dies ein Downgrade ist, wird die neue Subscription erst am ende des derzeitigen Abrechnungszeitraumes gebucht')    
                        }
                    </Trans>
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handlePurchase} variant="contained" color="success"> {t("Kaufen")}</Button>
                <Button onClick={handleClose} variant="contained" color="error">{t("Abbrechen")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmPurchaseDialog