import React from "react"
import { Route, Switch } from 'react-router-dom'
import Chat from "./Chat"
import ChatSim from "./chatSim/ChatSim"


const ChatRouter = () => {
    return (
        <Chat>
            <Switch>
                <Route path="/chat/:hash" component={ChatSim}/>
            </Switch>
        </Chat>
    )
}

export default ChatRouter