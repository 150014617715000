import React, { useCallback, useEffect, useState } from "react"
import {Navigation, PDFNavigationProps } from "../StoreDisplayWizard"
import { Grid, makeStyles, Typography, Collapse, Theme, Slider } from "@material-ui/core"
import { Form } from "react-final-form"
import FormikInput from "components/formik/FormikInput"
import { QRCode, IProps as IQROptions } from "react-qrcode-logo"
import { useTranslation } from "react-i18next"
import ContrastChecker from "components/qrCodeGenerator/ContrastChecker"
import Pixi from 'components/Pixi'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { IconPrefix, IconName } from "@fortawesome/fontawesome-svg-core"
import { Fa, FaButton, Box } from "styled/muiComponents"
import {Document as SvgDocument, Page as SvgPage, pdfjs} from 'react-pdf/'
import { useLocation } from "react-router-dom"
//TODO <formik 
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
    PDF: any
    QR: IQROptions
    imgURL: string
    navigationProps: PDFNavigationProps
    handleChangeQR: (name: string, value: any) => void
    handleChangePDFText:any   // TODO typing
    handleChangePDFStyle:any  // TODO typing
    editUserText: any
    editUserStyles: any
    settings: any
    setDocumentBlob: any
    documentBlob: any
    handleChangeSize: (event: any) => void
    handleChangeSettings: (event: any) => void
}

const useStyles = makeStyles<Theme, { open? : boolean }>(theme => ({
    root2: {
        backgroundColor: theme.palette.primary.light,
        color: "#ffffff",
        height: "calc(100% - 64px)",
        display: "flex",
        flexFlow: "column",
        boxSizing: "border-box",
    },
    editorWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "stretch",
        backgroundColor: theme.palette.primary.light,
    },
    editorConfigSideBar: {
        flex: "0 1 auto",
        backgroundColor: theme.palette.primary.light,
        width: "400px",
        minWidth: "400px",
        height: "100%",
        maxHeight: "100vh",
        borderRadius: "0px",
        padding: "0px",
        overflow: "hidden",
        fontWeight: 300,
    },
    editorPreviewWrapper: {
        flex: "1 1 auto",
        height: "100%",
        maxHeight: "100vh",
        overflowX: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    pdfviewer: {
        border: "1px solid #ffffff",
    },
    header: {
        /*padding: theme.spacing(1)*/
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subheader: {
        fontSize: "1.25rem",
    },
    headerDescription: {
        padding: theme.spacing(1.2),
        fontSize: "0.8rem",
        backgroundColor: "#ffffff26",
        borderRadius: "5px",
        position: "relative",
        '&:after': {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            width: 0,
            height: 0,
            border: "18px solid transparent",
            borderRightColor: "#ffffff26",
            borderLeft: 0,
            borderBottom: 0,
            marginTop: "-9px",
            marginLeft: "-18px",
        },
    },
    DescriptionWrapper: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    cardFixedHeader: {
        flex: "0 1 auto",
    },
    cardContent: {
        padding: 0,
        overflow: "auto",
        flex: "1 1 auto",
    },
    inputGroupHeader: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        transition: "0.35s all ease",
        color:({ open }) => open ? theme.palette.companyRed.main : "white",
        "&:hover": {
            color: theme.palette.companyRed.main,
            cursor: "pointer",
        },
    },
    inputGroupContainer: {
       transition: "0.35s all ease",
       borderTopRightRadius: "5px",
       borderBottomRightRadius: "5px",
        "&:hover": {
            backgroundColor: "#ffffff0d",
        }
    },
    inputWrapper: {
      padding: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      "&:first-child": {
        paddingTop: theme.spacing(2.5),
      },
      "&:last-child": {
        paddingBottom: theme.spacing(2.5),
      },
      transition: '0.35s all ease',
      "&:hover": {
          color: theme.palette.companyRed.main
      }
    },
    collapse: {
        borderTop: "1px solid #ffffff0d",
    },
    preview: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        boxSizing: "border-box"
    },
    zoomButton:{
        color: "white",
        transition: "0.35s all ease",
        "&:hover":{
            color:theme.palette.companyRed.main
        },
        "&.Mui-disabled": {
            color: "#ffffff26"
        }
    },
    zoomSlider: {
        marginTop: "7px",
        background: "linear-gradient(to bottom right, transparent 50%, #ffffff26 50%)",
        padding: "9px 0px",
        "& .MuiSlider-track": {
            display: "none"
        },
        "& .MuiSlider-rail": {
            display: "none"
        },
        "& .MuiSlider-thumb.Mui-disabled": {
            borderRadius: "2px",
            width: "2px",
            height: "30px",
            marginTop: "-15px",
            marginLeft: 0,
            transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            color: theme.palette.companyRed.main
        }
    }
}))

const EditPDF = ({ QR, PDF, handleChangeSettings, handleChangeSize, settings, editUserText, editUserStyles, navigationProps, handleChangePDFStyle, handleChangeQR, imgURL, handleChangePDFText, setDocumentBlob, documentBlob }: Props) => {
    
    new QRCode(QR).render()
    const { t } = useTranslation()
    const initialValues = {}
    const [open, setOpen] = useState(false)
    const classes = useStyles({ open })
    const [pageScaleIndex, setPageScaleIndex] = useState(2)
    const pageScale = [1.0, 1.5, 2.0, 2.5, 3.0, 3.5]
    const location = useLocation()
    const sbRef =React.createRef<any>()
    
    const template = PDF.Template
    const handleSubmit = () => {
        console.log("test") // ToDo
    }

    const handleDocument = useCallback((url: any) => {
        setDocumentBlob(url)
    },[setDocumentBlob])

    const handleZoomIn = () => {
        if (pageScaleIndex >= 1) {
            setPageScaleIndex(pageScaleIndex - 1)
        }
    }

    const handleZoomOut = () => {
        if (pageScaleIndex <= 4) {
            setPageScaleIndex(pageScaleIndex + 1)
        }
    }

    useEffect(() => {
        template({ imgURL, userText: editUserText, userStyles: editUserStyles, handleDocument, settings})
    }, [editUserStyles, editUserText, imgURL, settings, template, handleDocument])
   
    
    const handleUpdateSize = () => {
        console.log("??")
        console.log(sbRef.current)
        sbRef.current.updateScroll()
    }

    useEffect(() => {
        handleUpdateSize()
    },[open])


    return (
        <div className={classes.editorWrapper}>
            <div className={classes.editorConfigSideBar}>
                <Navigation {...navigationProps} />
                <div className={classes.root2}>
                    <div className={classes.cardFixedHeader}>
                        <Typography variant="h3" className={classes.header}>
                            {t("Vorlage bearbeiten")}
                        </Typography>
                        <Grid container direction="row" justify="center" alignItems="center" className={classes.DescriptionWrapper}>
                            <Grid item xs={4}>
                                <Pixi size="full" pose="waiting" />
                            </Grid>
                            <Grid item xs={8}>
                                <div className={classes.headerDescription}>
                                    {t("Gestalte hier dein Printmedium nach deinen Wünschen. Anschließend kannst du es mit einem QR-Code deiner Wahl runterladen oder drucken lassen.")}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <PerfectScrollbar ref={sbRef} onScrollY={() => handleUpdateSize()}>
                        <div className={classes.cardContent}>
                            <Form initialValues={initialValues} onSubmit={handleSubmit}
                                render={({ handleSubmit, submitting }) => (
                                    <form className="editor-config" onSubmit={handleSubmit}>
                                        <ZoomPDF handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} pageScaleIndex={pageScaleIndex} />
                                        {location.state?.step ?
                                            <Settings handleChangeSettings={handleChangeSettings} settings={settings} handleChangeSize={handleChangeSize}/>
                                        : null}
                                        {Object.values(editUserText).map((item: any) =>
                                            <InputGroup item={item} handleChange={handleChangePDFText} key={item.groupName} handleUpdateSize={handleUpdateSize}/>
                                        )}
                                        {Object.values(editUserStyles).map((item: any) =>
                                            <InputGroup item={item} handleChange={handleChangePDFStyle} key={item.groupName} handleUpdateSize={handleUpdateSize} />
                                        )}
                                        <div className={classes.inputGroupContainer}>
                                            <Box display="flex" justifyContent="space-between" alignItems="center" py={2} className={classes.inputGroupHeader} onClick={() => setOpen(!open)}>
                                                <Typography variant="h3" className={classes.subheader}>
                                                    {t("QR-Code")}
                                                </Typography>
                                                <Fa icon={["fal", "qrcode"]} size="lg" fixedWidth />
                                            </Box>
                                            <Collapse in={open} className={classes.collapse} >
                                                 <div className={classes.inputWrapper} >
                                                    {QR.fgColor && QR.bgColor ?
                                                        <ContrastChecker fgColor={QR.fgColor} bgColor={QR.bgColor} />
                                                        : null}
                                                </div>
                                                <div className={classes.inputWrapper} >
                                                    <FormikInput
                                                        type="color"
                                                        name="fgColor"
                                                        onChange={handleChangeQR}
                                                        label={t("Vordergrundfarbe")}
                                                        defaultValue={QR.fgColor}
                                                    />
                                                </div>
                                                <div className={classes.inputWrapper} >
                                                    <FormikInput
                                                        type="color"
                                                        name="bgColor"
                                                        onChange={handleChangeQR}
                                                        label={t("Hintergrundfarbe")}
                                                        defaultValue={QR.bgColor}
                                                    />
                                                </div>
                                                <div className={classes.inputWrapper} >
                                                    <FormikInput
                                                        type="enum"
                                                        name="ecLevel"
                                                        typeParams={["L", "M", "Q", "H"]}
                                                        onChange={handleChangeQR}
                                                        label={t("Fehler korrektur Level")}
                                                        value={QR.ecLevel}
                                                        variant="outlined"
                                                        labelProps={{
                                                            variant: "outlined"
                                                        }}
                                                        formControlProps={{
                                                            required: true
                                                        }}
                                                        defaultValue="H"
                                                    />
                                                </div>
                                                <div className={classes.inputWrapper} >
                                                    <FormikInput
                                                        type="slider"                     //enum or slider?
                                                        name="quietZone"
                                                        onChange={handleChangeQR}
                                                        label={t("Rand")}
                                                        defaultValue={QR.quietZone}
                                                        // default={QR.quietZone}
                                                        variant="outlined"
                                                        color="secondary"
                                                    />
                                                </div>
                                                <div className={classes.inputWrapper} >
                                                    <FormikInput
                                                        type="enum"
                                                        name="qrStyle"
                                                        typeParams={["squares", "dots"]}
                                                        onChange={handleChangeQR}
                                                        label={t("Style des QRcodes")}
                                                        value={QR.qrStyle}
                                                        variant="outlined"
                                                        labelProps={{
                                                            variant: "outlined"
                                                        }}
                                                        formControlProps={{
                                                            required: true
                                                        }}
                                                        defaultValue="squares"
                                                    />
                                                </div>
                                            </Collapse>
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
            <div className={classes.editorPreviewWrapper}>
                    <SvgDocument file={documentBlob} onLoadError={(error) => console.log(error)} className={classes.pdfviewer} >
                        <SvgPage pageIndex={0} renderMode="svg" scale={pageScale[pageScaleIndex]/settings.calc}/>
                    </SvgDocument>
            </div>
        </div>
    )
}

const InputGroup = ({ item, handleChange, handleUpdateSize }: {item: any, handleChange: any, handleUpdateSize:any}) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles({ open })

    useEffect(() => {
        
    },[open])

    return (
        <div className={classes.inputGroupContainer}>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={2} className={classes.inputGroupHeader} onClick={() => {handleUpdateSize(); setOpen(!open)}}>
                <Typography variant="h3" className={classes.subheader}>
                    {item.groupName}
                </Typography>
                <Fa icon={[item.iconType as IconPrefix, item.faIcon as IconName]} size="lg" fixedWidth />
            </Box>
            <Collapse in={open} className={classes.collapse} >
                    {Object.values(item.placeholders).map((entries: any, idx:number) =>
                        <div className={classes.inputWrapper} key={idx}>
                            <FormikInput
                                key={entries.name}
                                type={entries.type}
                                name={entries.name}
                                label={entries.label}
                                defaultValue={entries.value}
                                variant="outlined"
                                {...entries.props}
                                onChange={(event: any) => handleChange(event, item.groupName)}
                            />
                        </div>
                    )}
            </Collapse>
        </div>
    )
}

const ZoomPDF = ({handleZoomIn, handleZoomOut, pageScaleIndex}: {handleZoomIn: () => void, handleZoomOut: () => void, pageScaleIndex: number}) => {
    const classes = useStyles({ })
    const { t } = useTranslation()

    return (
        <Box className={classes.inputGroupContainer}>
            <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.inputGroupHeader} style={{paddingRight: "4px"}}>
                <Typography variant="h3" className={classes.subheader}>
                    {t("Zoom")}
                </Typography>
                <table>
                    <tr>
                        <td>
                            <FaButton onClick={handleZoomIn} className={classes.zoomButton} disabled={pageScaleIndex === 0}>
                                <Fa icon={["fal", "search-minus"]} />
                            </FaButton>
                        </td>
                        <td width="100">
                            <Slider className={classes.zoomSlider} value={pageScaleIndex} min={0} max={5} disabled />
                        </td>
                        <td>
                            <FaButton onClick={handleZoomOut} className={classes.zoomButton} disabled={pageScaleIndex === 5}>
                                <Fa icon={["fal", "search-plus"]} />
                            </FaButton>
                        </td>
                    </tr>
                </table>
            </Box>
        </Box>
    )
}

const Settings = ({ handleChangeSettings, settings, handleChangeSize }: any) => {
    const [open, setOpen] = useState<boolean>(false)
    const classes = useStyles({ open })
    const { t } = useTranslation()

    return (
        <div className={classes.inputGroupContainer}>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={2} className={classes.inputGroupHeader} onClick={() => setOpen(!open)}>
                <Typography variant="h3" className={classes.subheader}>
                    {t("Einstellungen")}
                </Typography>
                <Fa icon={["fal", "wrench"]} size="lg" fixedWidth />
            </Box>
            <Collapse in={open} className={classes.collapse}>
                        <div className={classes.inputWrapper}>
                            <FormikInput
                                type="text"
                                name="name"
                                label={t("Name")}
                                onChange={handleChangeSettings}
                                variant="outlined"
                                value={settings.name}
                            />
                        </div>
                        <div className={classes.inputWrapper}>
                            <FormikInput
                                type="enum"
                                name="size"
                                label={t("DIN-Format")}
                                typeParams={["A2", "A3", "A4", "A5", "A6"]}
                                onChange={handleChangeSize}
                                variant="outlined"
                                value={settings.size}
                                labelProps={{
                                    variant: "outlined"
                                }}
                            />
                        </div>
            </Collapse>
        </div>
    )
}

export default EditPDF