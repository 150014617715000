import React from 'react'
import { makeStyles } from '@material-ui/styles'
import SwipeableViews from 'react-swipeable-views'

export type SlideGarlieProps<T> = {
   children: (arg: { option: T }) => React.ReactElement<any, any>,
   options: T[],
   maxWidth: string | number | undefined,
   index: number
}

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        height:"100%"
    },
    navButton: {
        cursor: "pointer",
        padding: "16px",
        fontSize: "20px",
        border: 0,
        outline: "none",
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "#ffffff2b"
        }
    }
}))


const SlideGalerie = <T extends {}>({ children, options, maxWidth, index }: SlideGarlieProps<T>) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <SwipeableViews index={index} style={{maxWidth}}>
                { options.map(o => children({option: o})) }
            </SwipeableViews>
        </div>
    )
}

export default SlideGalerie