import { withCrud, CrudModel, BaseModel } from "./builder";
import { post, get, } from "api/crud"


export type IUser = {
    active: boolean
    email: string
    firstName: string
    lastName: string
    status: string
    user_role_id: string //nicht "user_role"?
    isAccountant: string
    id: string
    emailActivationToken: string
    user_role: string
}

export type UserApi = CrudModel<IUser> & BaseModel

export default {
    name: "users",
    ...withCrud<IUser>("users"),
    register: (email: string, grecaptcha_token: string, fromCornerstone: boolean) => post("users/register", { email, grecaptcha_token, fromCornerstone}),

    verifyTokenActivate: (emailActivationToken: string) => get(`users/activate/${emailActivationToken}`),

    activateUser: (password: string, emailActivationToken:string) => post(`users/activate/${emailActivationToken}`, { password }),

    verifyTokenReset: (emailActivationToken: string) => get(`users/reset_password/${emailActivationToken}`),

    sendResetPasswordMail: (email: string, fromCornerstone:boolean) => post(`users/reset_password`, {email, fromCornerstone}),

    resetPassword: (password: string, emailActivationToken: string) => post(`users/reset_password/${emailActivationToken}`, {password}),

    activateRealm: (address: any) => post(`users/realm/activate`, {address}),

    realmInfo: () => get(`users/realm/client_format`),

    changePassword: (id: number, newPassword: string, oldPassword: string) => post(`users/${id}/reset_password`, {new_password: newPassword, old_password:oldPassword}, ),
}


