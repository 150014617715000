import React from "react"
import { Dialog, makeStyles, Theme } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { DialogProps } from "hooks/useDialog"
import DialogTitle from "./DialogTitle"
import DialogContent from "./DialogContent"
import DialogActions from "./DialogActions"
import Button from "components/Button/Button"
import { Fa, Typography } from '../styled/muiComponents'

type Props = {
    errorType:string
}
const useStyles = makeStyles<Theme, {}>(theme => ({
    warningIcon: {
        color: theme.palette.companyRed.main
    }
  }))
  

const ErrorDialog: React.FC<Props & DialogProps> = ({errorType, onClose, open}) => {

    const { t } = useTranslation()

    const handleClose = () => {
        onClose({action: "reload"})
    }
    const classes = useStyles({})
    const errorDialog = (errorType: string) => {
        switch (errorType) {
            case "size":
                return (
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle onClose={handleClose}><Fa icon={["fal", "exclamation-triangle"]} className={classes.warningIcon} mr={1.75} />{t("Achtung")}</DialogTitle>
                        <DialogContent>
                            <Typography variant="h2" mb={2}>{t("Bild zu Groß")}</Typography>
                            {t("Das Bild, welches Sie versuchen hochzuladen ist zu groß. Bitte verkleinern Sie es und versuchen es erneut. Die Bildgröße darf 2MB nicht überschreiten.")}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="error" onClick={handleClose}><Fa icon={["fas", "times"]} mr={1} size={"lg"} /> {t("Schließen")}</Button>
                        </DialogActions>
                    </Dialog>
                )
            case "format":
                return (
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle onClose={handleClose}><Fa icon={["fal", "exclamation-triangle"]} className={classes.warningIcon} mr={1.75} />{t("Achtung")}</DialogTitle>
                        <DialogContent>
                            <Typography variant="h2" mb={2}>{t("Falsches Bildformat")}</Typography>
                            {t("Es können nur Bilder in den Formaten JPEG, GIF und PNG gespeichert werden.")}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="error" onClick={handleClose}><Fa icon={["fas", "times"]} mr={1} size={"lg"} /> {t("Schließen")}</Button>
                        </DialogActions>
                    </Dialog>
                )
            default:
                return (
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle onClose={handleClose}><Fa icon={["fal", "exclamation-triangle"]} className={classes.warningIcon} mr={1.75} />{t("Achtung")}</DialogTitle>
                        <DialogContent>
                            <Typography variant="h2" mb={2}>{t("Unbekannter Fehler")}</Typography>
                            {t("Ein unbekannter Fehler ist aufgetreten. Bitte versuche es noch mal. Sollte das Problem weiterhin bestehen, bitten wir dich mit uns Kontakt aufzunehmen")}
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="error"><Fa icon={["fas", "times"]} mr={1} size={"lg"} /> {t("Schließen")}</Button>
                        </DialogActions>
                    </Dialog>
                )
        }
    }

    return (
        errorDialog(errorType)
    )


}

export default ErrorDialog