import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import Button from 'components/Button/Button'
import FormikInput from 'components/formik/FormikInput'
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core'
import { useDialog } from 'hooks/useDialog'
import ChangePasswordDialog, { Props as ChangePasswordDialogProps } from './ChangePasswordDialog'
import useSession from "hooks/useSession"
import { Address } from 'hooks/useSession'
import { Grid, Fa } from "styled/muiComponents"
import ChangeImprintDialog from './ChangeImprintDialog'
import imprints from "models/imprint"
import users from "models/user"
import PagePadding from 'components/PagePadding'
import { Block, BlockTitle, BlockContent } from 'components/Block'
import { useAlert } from "hooks/useAlert"
import UploadLogoDialog from "./UploadLogoDialog"
import { Form } from "react-final-form"
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import { Subscriptions } from './subscriptions/Subscriptions'

const useStyles = makeStyles(theme => ({
    tableLabel: {
        fontWeight: 400,
    },
    imprintMargin: {
        "&:last-child": {
            marginBottom: "16px"
        }
    }
}))

type Values = {
    email: string,
    first_name: string,
    last_name: string
}

const validationSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email().required()
})

const Personal = () => {

    const { realm, user } = useSession()
    const { t } = useTranslation()
    const [edit, setEdit] = useState(true)

    if (!user || !realm) {
        return null
    }
    const initialValues = { first_name: user.first_name, last_name: user.last_name, email: user.email, gender: realm.address.gender }

    const handleSubmit = async (values: any) => {
        try {
            await users.update(user.id, {
                ...values,
                user_role: user.user_role // wichtig, aber warum?
            })
            //await realms.updateImprint({...realm.address, ...values})
        } catch (e) {
            //TODO actual error?
            console.error(e)
        }
    }

    const handleEdit = () => {
        if(edit) {
            setEdit(false)
        } else {setEdit(true)}
    }

    return (
        <Block>
            <BlockTitle>
                <Typography variant="h2">{t("Persönliche Daten")}</Typography>
            </BlockTitle>
            <BlockContent>
                <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}
                    render={({ handleSubmit, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container direction="column">
                                <Grid item>
                                    <FormikInput type="string" name="email" label={t("E-Mail-Adresse")} disabled variant="outlined" />
                                </Grid>
                                {/* <Grid container item justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography>
                                            {t("Frau")}
                                        </Typography>
                                        <Radio
                                            name="gender"
                                            value="female"
                                            onChange={handleChangeGender}
                                            checked={gender === "female"}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {t("Herr")}
                                        </Typography>
                                        <Radio
                                            name="gender"
                                            value="male"
                                            onChange={handleChangeGender}
                                            checked={gender === "male"}
                                        />
                                    </Grid>
                                </Grid> */}
                                <Grid item>
                                    <FormikInput type="string" name="first_name" label={t("/fields:first_name")} variant="outlined" disabled={edit}/>
                                </Grid>
                                <Grid item>
                                    <FormikInput type="string" name="last_name" label={t("/fields:last_name")} variant="outlined" disabled={edit}/>
                                </Grid>
                                <Grid item container justify='space-between' mt={2}>
                                    <Grid item>
                                        <Button color="success" variant="contained" size="small" onClick={handleEdit} style={edit ? {display: "block"} : {display: "none"}}>
                                            <Fa icon={["far", "pen-to-square"]} mr={1}/>{t("Bearbeiten")}
                                        </Button>
                                        <Button color="error" variant="contained" size="small" onClick={handleEdit} style={!edit ? {display: "block"} : {display: "none"}}>
                                            <Fa icon={["far", "times"]} mr={1}/>{t("Abbrechen")}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button 
                                            isLoading={submitting}
                                            color="success"
                                            type="submit"
                                            variant="contained"
                                            disabled={values.first_name === initialValues.first_name && values.last_name === initialValues.last_name}
                                            onClick={handleEdit}
                                            size="small"
                                            style={!edit ? {display: "block"} : {display: "none"}}
                                        >
                                            <Fa icon={["far", "check"]} mr={1}/>{t("Änderungen speichern")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
            </BlockContent>
        </Block>
    )
}

const Password = () => {
    const { realm, user } = useSession()
    const { t } = useTranslation()
    const { dialog } = useDialog()
    const { alert } = useAlert()
    
    if (!user || !realm) {
        return null
    }
    const handleChangePassword = () => {
        dialog.show<ChangePasswordDialogProps>({
            component: ChangePasswordDialog,
            props: {
                id: user.id
            }
        }).then((response) => {
            if (response.success) {
                alert.success(t("Passwort wurde erfolgreich geändert"))
            }

        }).catch((e) => {
            alert.error(t("Passwort konnte nicht gespeichert werden"))
        })
    }

    return (
        <Block>
            <BlockTitle>
                <Typography variant="h2">{t("Passwort")}</Typography>
            </BlockTitle>
            <BlockContent>
                <Button color="success" variant="contained" onClick={handleChangePassword} size="small"><Fa icon={["far", "lock-keyhole"]} mr={1}/>{t("Passwort ändern")}</Button>
            </BlockContent>
        </Block>
    )

}

// const Billing = () => {
//     const { realm } = useSession()
//     const { t } = useTranslation()
//     const classes = useStyles()
//     const { dialog } = useDialog()
//     const { alert } = useAlert()


//     // const defaultBilling = {
//     //     name: "Max Mustermann",
//     //     brand: "MusterKarte",
//     //     last4: 1234,
//     //     exp_month: "Januar",
//     //     exp_year: "2000"
//     // }

//     const defaultAddress = {
//         gender: "male",
//         first_name: "Max",
//         last_name: "Mustermann",
//         company_name: "Musterfirma",
//         street_name: "MusterStraße",
//         street_number: 42,
//         postal_code: 12345,
//         city: "Musterstadt",
//         country: "Musterland"
//     }

//     if (!realm) return null

//     const { billing } = realm
//     // const realmBilling = { ...defaultBilling, ...billing }
//     // const realmAddress = { ...defaultAddress, ...address }
//     const { gender, first_name, last_name, company_name, street_name, street_number, postal_code, city, country,  } = realm.address ?? defaultAddress

//     /*const handleAVContract = () => {
//         console.warn("handleAVContract not implemented")
//         openDialog(AVContractDialog)
//     }*/

//     const openErrorAlert = (error:any) => {
//         alert.error("???")
//     }

//     // const handleChangePaymentMethod = () => {
//     //     dialog.show({
//     //         component: PaymentInputDialog,
//     //         props: {
//     //             openErrorAlert
//     //         }
//     //     }).then((response) => {
//     //         if (response.success) {
//     //             alert.success(t("Bezahlmethode wurde erfolgreich gespeichert"))
//     //         }
//     //     }).catch((error) =>{
            
//     //     })
//     // }

//     return (
//         <Block>
//             <BlockTitle>
//                 <Typography variant="h2">{t("Rechnungsdaten")}</Typography>
//             </BlockTitle>
//             <BlockContent>
//                 <Grid container direction="column" spacing={3}>
//                     <Grid item>
//                         <Typography variant="h2">{t("Rechnungsanschrift")}</Typography>
//                         <Grid container item direction="row" mt={2}>
//                             <Grid item md={4} sm={12} className={classes.tableLabel}>
//                                 {t("/fields:company_name")}
//                             </Grid>
//                             <Grid item md={8} sm={12}>
//                                 {company_name}
//                             </Grid>
//                         </Grid>
//                         <Grid container item direction="row">
//                             <Grid item md={4} sm={12} className={classes.tableLabel}>
//                                 {t("/fields:name")}
//                             </Grid>
//                             <Grid item md={8} sm={12}>
//                                 {gender === "male" ? t("Herr") : t("Frau")} {first_name} {last_name}
//                             </Grid>
//                         </Grid>
//                         <Grid container item direction="row" mb={2}>
//                             <Grid item md={4} sm={12} className={classes.tableLabel}>
//                                 {t("Anschrift")}
//                             </Grid>
//                             <Grid item md={8} sm={12}>
//                                 {street_name} {street_number}<br/>
//                                 {postal_code} {city}<br/>
//                                 {t(`/countries:${country}`)}
//                             </Grid>
//                         </Grid>
                   
//                         <Alert severity="info">
//                             {t("Um die Rechnungsanschrift und die Firmierung zu ändern wende dich bitte mit Angabe deiner hinterlegten E-Mail-Adresse an ")}
//                             <a href="mailto:support@campaign.plus">support@campaign.plus</a>
//                         </Alert>
//                     </Grid>
//                     <Grid item mt={1.5} mb={1.5}>
//                         <Divider />
//                     </Grid>
//                     <Grid item container direction='column'>
//                         <Typography variant="h2">{t("Zahlungsmethode")}</Typography>
//                         {billing?.iban
//                             ?
//                             <Grid container item direction='column' mt={2}>
//                                 <Grid container item direction="row">
//                                     <Grid item md={4} sm={12} className={classes.tableLabel}>
//                                         {t("Kontoinhaber")}
//                                     </Grid>
//                                     <Grid item md={8} sm={12}>
//                                         {billing?.account_holder}
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container item direction="row">
//                                     <Grid item md={4} sm={12} className={classes.tableLabel}>
//                                         {t("Letzte 4 Ziffern der IBAN")}
//                                     </Grid>
//                                     <Grid item md={8} sm={12}>
//                                         {billing?.iban}
//                                     </Grid>
//                                 </Grid>
//                             </Grid>
//                             :
//                             <Grid container item direction='column' mt={2}>
//                                 <Grid container item direction="row">
//                                     <Grid item md={4} sm={12} className={classes.tableLabel}>
//                                         {t("Kartenhalter")}
//                                     </Grid>
//                                     <Grid item md={8} sm={12}>
//                                         {billing?.name}
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container item direction="row">
//                                     <Grid item md={4} sm={12} className={classes.tableLabel}>
//                                         {t("Kartenart")}
//                                     </Grid>
//                                     <Grid item md={8} sm={12}>
//                                         {billing?.brand}
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container item direction="row">
//                                     <Grid item md={4} sm={12} className={classes.tableLabel}>
//                                         {t("Letzte 4 Stellen")}
//                                     </Grid>
//                                     <Grid item md={8} sm={12}>
//                                         ********{billing?.last4}
//                                     </Grid>
//                                 </Grid>
//                                 <Grid container item direction="row">
//                                     <Grid item md={4} sm={12} className={classes.tableLabel}>
//                                         {t("Ablaufdatum")}
//                                     </Grid>
//                                     <Grid item md={8} sm={12}>
//                                         {t(`/months:${billing?.exp_month}`)}  {billing?.exp_year}
//                                     </Grid>
//                                 </Grid>
//                             </Grid>
//                         }
//                         <Grid item mt={2}>
//                             <Button color="success" variant="contained" size="small" onClick={handleChangePaymentMethod}>
//                                 <Fa icon={["far", "pen-to-square"]} mr={1}/>{t("Zahlungsmethode ändern")}
//                             </Button>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </BlockContent>
//         </Block>
//     )
// }

export const imprintKeys: { [key: string]: any } = {
    address: ["company_name", "legal_form", "postal_code", "city", "street_name", "street_number", "imprint_mail", "phone_number"],
    management: ["company_board", "supervisory_board"],
    register: ["company_register", "registry_court", "registry_number"],
    taxNumbers: ["vat_identifier", "ust_identifier"],
    imprintJob: ["job_title_plus_country_awarded", "professional_regulations", "supervisory_authority", "responsible_chamber"],
    imprintExtra: ["additional_information"]
}

const Imprint = () => {
    const { t } = useTranslation()
    const { dialog } = useDialog()
    const [imprint, setImprint] = useState<any>(null)
    const {alert} = useAlert()
    const classes = useStyles()
    useEffect(() => {
        const fetchImprint = async () => {
            try {
                const fetchedImprint = await imprints.getFirst()
                setImprint(fetchedImprint)
            } catch (e) {
                // error
            }
        }
        fetchImprint()
    }, [])

    if (!imprint) {
        return null
    }

    const handleEditImprint = async () => {
        dialog.show({
            component: ChangeImprintDialog,
            props: {
                fetchedImprint: imprint
            }
        }).then((response: any) => {
            if (response?.success) {
                alert.success(t("Impressum erfolgreich gespeichert"))
                setImprint(response.imprint)
            }
        })
    }

    return (
        <Block>
            <BlockTitle>
                <Typography variant="h2">{t("Impressum")}</Typography>
            </BlockTitle>
            <BlockContent>
                {Object.keys(imprintKeys).map(key =>
                    <Grid container item direction="column">
                        {imprintKeys[key].map((entry: keyof Address) => {
                            if (imprint[entry]) {
                                return <Grid container item direction="row" className={classes.imprintMargin}><Grid item md={4} sm={12} className={classes.tableLabel}>{t(`/fields:${entry}`)}</Grid><Grid item md={8} sm={12} mb={1}>{imprint[entry]}</Grid></Grid>
                            } else {
                                return null
                            }
                        })}
                    </Grid>
                )}
                <Button color="success" variant="contained" size="small" onClick={handleEditImprint}>
                    <Fa icon={["far", "pen-to-square"]} mr={1}/>{t("Bearbeiten")}
                </Button>
            </BlockContent>
        </Block>
    )
}

const Logo = () => {

    const {dialog} = useDialog()
    const { t } = useTranslation()

    const handleChangeLogo = () => {
        dialog.show({
            component:UploadLogoDialog,
            props:{}
        })
    }

    return (
        <Block>
        <BlockTitle>
            <Typography variant="h2">{t("Logo")}</Typography>
        </BlockTitle>
        <BlockContent>
            <Button color="success" variant="contained" size="small" onClick={handleChangeLogo}>
                <Fa icon={["far", "pen-to-square"]} mr={1}/>{t("Logo ändern")}
            </Button>
        </BlockContent>
    </Block>
    )
}

const Account = () => {

    return (
        <PagePadding>
            <Grid container spacing={3} justify="center">
                <Grid item xs={12} lg={6}>
                    <Personal />
                    <Password />
                    {/* <Billing /> */}
                    <Imprint />
                    <Logo />
                    <Subscriptions/>
                </Grid>


                {/*
                    <Grid item lg={6}>
                        <Grid container direction="column">
                            <Button variant="contained" color="primary">Tutorial</Button>
                        </Grid>
                    </Grid>
                */}
            </Grid>
        </PagePadding>


    )
}

export default Account