import React, { useState, useEffect } from 'react'
import { makeStyles, Grid, Theme } from '@material-ui/core'
import { FaButton, Fa, Typography } from 'styled/muiComponents'
import { useTranslation } from "react-i18next"
import { IPlaceholder } from 'models/webform'
import Pixi from 'components/Pixi'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { NavigationProps, Navigation } from "../WebformWizard"
import { TemplateKey } from 'models/webform'
import View from 'components/formEditor/View';
import { FormValues } from './FirstStep'
import { ICustomHeader } from 'components/formEditor/Editor';
import { useSidebar } from 'hooks/useSidebar'
import { Device } from 'types/common'
import Button from "components/Button/Button"

const prebuildKeys: string[] = ["001", "002"]

type Props = {
    onSubmit: (prebuildKey: string) => void
    template?: string
    placeholders?: Record<string, IPlaceholder>,
    navigationProps: NavigationProps
    basicInfo: FormValues | null
    customConfigHeader: ICustomHeader
    previewIndex: number
    device: Device
    onDeviceChange: (device: Device) => void
}

const useStyles = makeStyles<Theme, {device:Device}>(theme => ({
    imageWrapper: {
        padding: theme.spacing(1),
        position: "relative",
        "& img": {
            width: "100%"
        }
    },

    overlay: {
        backgroundColor: "rgba(120, 120, 120, 0.2)"
    },

    editorWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "stretch",
        backgroundColor: theme.palette.primary.light,
    },
    editorConfigSideBar: {
        flex: "0 1 auto",
        backgroundColor: theme.palette.primary.light,
        width: "400px",
        minWidth: "400px",
        height: "100%",
        maxHeight: "100vh",
        borderRadius: "0px",
        padding: "0px",
        overflow: "hidden",
        fontWeight: 300,
    },
    editorPreviewWrapper: {
        flex: "1 1 auto",
        height: "100%",
        maxHeight: "100vh",
        overflowX: "auto"
    },



    root: {
        backgroundColor: theme.palette.primary.light,
        color: "#ffffff",
        height: "calc(100% - 64px)",
        display: "flex",
        flexFlow: "column",
        boxSizing: "border-box",
    },
    header: {
        /*padding: theme.spacing(1)*/
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subheader: {
        fontSize: "1.25rem",
    },
    headerDescription: {
        padding: theme.spacing(1.2),
        fontSize: "0.8rem",
        backgroundColor: "#ffffff26",
        borderRadius: "5px",
        position: "relative",
        '&:after': {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            width: 0,
            height: 0,
            border: "18px solid transparent",
            borderRightColor: "#ffffff26",
            borderLeft: 0,
            borderBottom: 0,
            marginTop: "-9px",
            marginLeft: "-18px",
        },
    },
    DescriptionWrapper: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    cardFixedHeader: {
        flex: "0 1 auto",
    },
    cardContent: {
        padding: 0,
        overflow: "auto",
        flex: "1 1 auto",
    },
    previewDeviceIconsWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    previewLabel: {
        textAlign: "left",
        flex: "1 1 auto",
        "& p": {
            margin: 0,
        }
    },
    previewModeFullscreenButton: { // TO DO
        color: ({ device }) => device === Device.Fullscreen ? theme.palette.companyRed.main : "white",
        transition: "0.35s all ease",
        "&:hover": {
              opacity: ({ device }) => device === Device.Fullscreen ? "1" : "0.5",
        },
        "& button": {
            paddingRight: 0,
        }
    },
    previewModeDesktopButton: { //TO DO
        color: ({ device }) => device === Device.Desktop ? theme.palette.companyRed.main : "white",
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ device }) => device === Device.Desktop ? "1" : "0.5",
        }
    },
    previewModeTabletButton: { // TO DO
        color: ({ device }) => device === Device.Tablet ? theme.palette.companyRed.main : "white",
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ device }) => device === Device.Tablet ? "1" : "0.5",
        }
    },
    previewModeSmartphonebutton: { // TO DO
        color: ({ device }) => device === Device.Smartphone ? theme.palette.companyRed.main : "white",
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ device }) => device === Device.Smartphone ? "1" : "0.5",
        }
    },

}))

const WebformPrebuilds: React.FC<Props> = ({ onSubmit, template, basicInfo, placeholders, navigationProps, customConfigHeader, device, onDeviceChange }) => {
    const classes = useStyles({ device })    
    const [highlight, setHighlight] = useState<string | null>(null)
    const { t } = useTranslation()
    const {isFixedOpen, fixedClose} = useSidebar()

    useEffect(() => {
        if (isFixedOpen) {
            fixedClose()
        }
    }, [isFixedOpen, fixedClose])

    const handleHighlight = (prebuildKey: string) => {
        setHighlight(prebuildKey)
        onSubmit(prebuildKey)
    }


    return (
        <div className={classes.editorWrapper}>
            <div className={classes.editorConfigSideBar}>
                <Navigation {...navigationProps} nextEnabled={!(highlight !== null)}/>
                <div className={classes.root}>
                    <div className={classes.cardFixedHeader}>
                        <Typography variant="h3" className={classes.header}>
                            {t("Vorlage wählen")}
                        </Typography>
                        <Grid container direction="row" justify="center" alignItems="center" className={classes.DescriptionWrapper}>
                            <Grid item xs={4}>
                                <Pixi size="full" pose="waiting" />
                            </Grid>
                            <Grid item xs={8}>
                                <div className={classes.headerDescription}>
                                   {customConfigHeader.description}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-end" alignItems="center" className={classes.previewDeviceIconsWrapper}>
                            <Grid item className={classes.previewLabel}>
                                <p>{t("Vorschau")}:</p>
                            </Grid>
                            <Grid item className={classes.previewModeSmartphonebutton}>
                                <FaButton style={{ color: "inherit" }} onClick={() => onDeviceChange(Device.Smartphone)}>
                                    <Fa icon={["fal", "mobile"]} />
                                </FaButton>
                            </Grid>
                            <Grid item className={classes.previewModeTabletButton}>
                                <FaButton style={{ color: "inherit" }} onClick={() => onDeviceChange(Device.Tablet)}>
                                    <Fa icon={["fal", "tablet"]} />
                                </FaButton>
                            </Grid>
                            <Grid item className={classes.previewModeDesktopButton}>
                                <FaButton style={{ color: "inherit" }} onClick={() => onDeviceChange(Device.Desktop)}>
                                    <Fa icon={["fal", "laptop"]} />
                                </FaButton>
                            </Grid>
                            <Grid item className={classes.previewModeFullscreenButton}>
                                <FaButton style={{ color: "inherit" }} onClick={() => onDeviceChange(Device.Fullscreen)}>
                                    <Fa icon={["fal", "window"]} />
                                </FaButton>
                            </Grid>
                        </Grid>
                    </div>
                    <PerfectScrollbar>
                        <div className={classes.cardContent}>
                            <Grid container direction="row" >
                                <Grid item container direction='row' className={classes.imageWrapper}>
                                    {prebuildKeys.map((key, idx) => (
                                        <Prebuild handleHighlight={handleHighlight} highlight={highlight} key={key} prebuildKey={key}  />
                                    ))}
                                </Grid>
                            </Grid>
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
            <div className={classes.editorPreviewWrapper}>
                {template && placeholders ?
                    <View device={device} template={template} placeholders={placeholders} basicInfo={basicInfo} customHeader={customConfigHeader} previewIdentifier={TemplateKey.formLP} />
                    : 
                    <Grid item container justify='center' alignItems='center' style={{height: "100%"}}>
                        <Grid item container direction='column' style={{maxWidth: "600px"}}>
                            <Typography variant='h1' style={{color: "#7f7f7f", textAlign: "center", fontSize: "2.75em"}} mb={5}>{t("Wähle eine Vorlage, um zu beginnen.")}</Typography>
                            <img src='/assets/images/please-choose-arrow.svg' style={{color: "#ccc", maxWidth: "400px", marginLeft: "-17%"}}/>
                        </Grid>
                    </Grid>
                    }
            </div>
        </div>
    )
}


const useStylesPrebuild = makeStyles<Theme, {highlight:boolean }>(theme => ({
    previewImage: {
        border: ({ highlight }) => highlight ? "3px solid #83ac1b" : "3px solid #ffffff",
        backgroundColor: "#83ac1b !important",
        padding: 0,
        boxSizing: "border-box",
        transition: '0.3s all ease',
        opacity: 1,
        "&:hover":{
            border: ({ highlight }) => highlight ? "3px solid #83ac1b" : "3px solid #ffffff",
            opacity: "0.8"
        },
        "& img": {
            opacity: ({ highlight }) => highlight ? "0.8" : "1",
            borderRadius: "2px"
        }
    },
    imageWrapper: {
        padding: theme.spacing(1),
        position: "relative",
        "& img": {
            width: "100%"
        }
    }
}))

const Prebuild = ({ prebuildKey, highlight, handleHighlight }: { handleHighlight: (prebuildKey: string) => void, highlight: string | null, prebuildKey: string}) => {
    const classes = useStylesPrebuild({highlight: highlight === prebuildKey})
    return (
        <Grid xs={6} item className={classes.imageWrapper} >
            <Button onClick={() => handleHighlight(prebuildKey)} className={classes.previewImage} >
                <img src={`${process.env.REACT_APP_TEMPLATE_SERVER}/webform/${prebuildKey}/preview.png`} alt={"Preview"} />
            </Button>
        </Grid>
    )
}

export default WebformPrebuilds

//${process.env.REACT_APP_TEMPLATE_SERVER}