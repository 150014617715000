import React, { useRef, useEffect, useMemo, useState, useCallback } from 'react'
import { ICustomHeader } from './formEditor/Editor'
import { Fa, Box, Grid } from 'styled/muiComponents'
import { Avatar as MuiAvatar, makeStyles } from '@material-ui/core';
import { useTranslation } from "react-i18next"
import useSession, { Address } from "hooks/useSession"
import { TemplateKey } from 'models/webform';
import { FormValues } from 'routes/app/stock/webforms/wizard/steps/FirstStep';
import Tooltip from '@material-ui/core/Tooltip';
import { ITemplateMailSettings } from 'routes/app/stock/webforms/wizard/WebformWizard';
import domains from "models/domain"
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import ChatSim from 'routes/chat/chatSim/ChatSim';
import { TemplateKey as RTemplateKey } from 'models/ratings';

const useStyles = makeStyles(({
  clickDisabledCalc:{
	// pointerEvents: "none",
	height:"calc(100% - 50px)"
  },
  clickDisabled:{
	// pointerEvents: "none",
	height:"100%"
  },
popoverArrow: {
	position: "relative",
	marginTop: "10px",
	"&::before": {
	backgroundColor: "white",
	content: '""',
	display: "block",
	position: "absolute",
	width: 12,
	height: 12,
	top: -6,
	transform: "rotate(45deg)",
	left: "calc(50% - 6px)"
	}
}
}))

type Props = {
  html: string
  width?: number
  height?: number
  variant?: "plain" | "desktop" | "tablet" | "smartphone" | "fullscreen"
  handleExpand?: () => void
  expanded?: boolean
  customHeader?: ICustomHeader
  wrapperInfo?: Record<string, string> | undefined
  previewIdentifier?: TemplateKey | RTemplateKey
  basicInfo: FormValues | null
  templateMailSettings?: ITemplateMailSettings | undefined
  domain?: string
  chat?: any | null
  chatView?: "graph" | "chat" | null
  imprint?: Address | null
}

const today = new Date();
const date = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
const time = today.getHours() + ":" + today.getMinutes();

const tag = today.getDay();
let wochentag = "";

if (tag === 0) wochentag = "So";
if (tag === 1) wochentag = "Mo";
if (tag === 2) wochentag = "Di";
if (tag === 3) wochentag = "Mi";
if (tag === 4) wochentag = "Do";
if (tag === 5) wochentag = "Fr";
if (tag === 6) wochentag = "Sa";

var dateTime = wochentag + ' ' + date + ' ' + time;
const Fullscreen: React.FC<{ handleExpand?: () => void, expanded?: boolean, wrapperInfo: Record<string, string> | undefined, templateMailSettings?: ITemplateMailSettings | undefined, previewIdentifier?: TemplateKey | RTemplateKey, basicInfo: FormValues | null, domain: string | undefined }> = ({ children, handleExpand, expanded, wrapperInfo, previewIdentifier, basicInfo, templateMailSettings, domain }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  switch (previewIdentifier) {
	case "formLP":
	case "soiLP":
	case "doiLP":
	case "qr":
	case "query":
	case "portals":
	case "feedback":
	case "feedbackLP":
	  return (
		<div className={!expanded ? "browser--frame" : "browser--frame-expanded"}>
		  <div className="browser--controls">
			<div className="window--controls">
			  <span className="close"></span>
			  <span className="minimise"></span>
			  <span className="maximise"></span>
			</div>

			<div className="page--controls">
			  <span className="back white--container"><Fa icon={["fas", "chevron-left"]} /></span>
			  <span className="next white--container"><Fa icon={["fas", "chevron-right"]} /></span>
			</div>

			<span className="url--bar white--container">
			  {domain && <>https://<strong>{domain}</strong>.cornermail.de/{wrapperInfo?.header}</>}
			</span>
			<span className="full--screen white--container" onClick={handleExpand}><Fa icon={["fas", "expand-alt"]} /></span>
		  </div>
		  <div className="browser--content">
			<div className={classes.clickDisabled}>
			  {children}
			</div>
		  </div>
		</div>
	  )
	case "doiMail":
	case "doneMail":
	  return (
		<div className={!expanded ? "browser--frame" : "browser--frame-expanded"}>
		  <Grid container direction="column" className="emailClient-container">
			 	<Grid item className="emailClient-taskbar">
					<div className="browser--controls">
						<div className="window--controls">
							<span className="close"></span>
							<span className="minimise"></span>
							<span className="maximise"></span>
						</div>
			  			<span className="programmName-bar">
							Outlook 1337 Build
						</span>
						<span className="full--screen white--container" onClick={handleExpand}><Fa icon={["fas", "expand-alt"]} /></span>
					</div>
				</Grid> 
			<Grid item container direction="row" className="emailClient-infobar">
			  <Grid item className="emailClient-avatar">
			  	<MuiAvatar src={basicInfo?.useDoiChain === true && previewIdentifier === "doiMail" ? "/assets/images/doichain.png" : " "}>
				  {wrapperInfo?.firstName.charAt(0)}{wrapperInfo?.lastName.charAt(0)}
				</MuiAvatar>
			  </Grid>
			  <Grid item container direction="column" className="emailClient-subject-wrapper">
				<Grid item container direction="row" className="emailClient-fromaddress-date">
				  <Grid item className="emailClient-fromaddress">
					{wrapperInfo?.firstName} {wrapperInfo?.lastName} &#60;&nbsp;{basicInfo?.useDoiChain === true && previewIdentifier === "doiMail" ? "doichain@doichain.org" : basicInfo ? `${basicInfo.senderEmailLocalPart}@${basicInfo.senderEmailDomain?.domain}` : "username@cornermail.de"}&nbsp;&#62;
					{basicInfo?.useDoiChain === true && previewIdentifier === "doiMail"
					  ?
					  <Tooltip title="Doichain verifiziertes Opt-In" placement="bottom" arrow>
						<Box component="span" ml={1}>
						  <Fa icon={["fas", "shield-check"]} style={{ color: "#83ac1b" }} />
						</Box>
					  </Tooltip>
					  :
					  null
					}
					{basicInfo?.useDoiChain === false && previewIdentifier === "doiMail"
					  ?
					  <Tooltip title="Nicht durch Doichain verifiziert" placement="bottom" arrow>
						<Box component="span" ml={1}>
						  <Fa icon={["fas", "lock-open-alt"]} style={{ color: "#e83338" }} />
						</Box>
					  </Tooltip>
					  :
					  null
					}


				  </Grid>
				  <Grid item className="emailClient-date">
					{dateTime}
				  </Grid>
				</Grid>
				<Grid item container direction="row" className="emailClient-subject-control">
				  <Grid item className="emailClient-subject">
					{templateMailSettings?.subject.value}
								</Grid>
				  <Grid item className="emailClient-control">
					<span><Fa icon={["fas", "reply"]} />&nbsp;&nbsp;{t("Antworten")}</span>
					<span><Fa icon={["fas", "share"]} />&nbsp;&nbsp;{t("Weiterleiten")}</span>
				  </Grid>
				</Grid>
			  </Grid>
			</Grid>
			<Grid item className="emailClient-content">
			  <div className={classes.clickDisabled}>
				{children}
			  </div>
			 </Grid>
		  </Grid>
		</div>
	  )
	default: return (
	  null
	)
  }
}

const Desktop: React.FC<{ handleExpand?: () => void, expanded?: boolean, wrapperInfo: Record<string, string> | undefined, templateMailSettings?: ITemplateMailSettings | undefined, previewIdentifier?: TemplateKey | RTemplateKey, basicInfo: FormValues | null, domain: string | undefined }> = ({ children, handleExpand, expanded, wrapperInfo, previewIdentifier, basicInfo, templateMailSettings, domain }) => {
	const { t } = useTranslation()
	const classes = useStyles()
	switch (previewIdentifier) {
		case "formLP":
		case "soiLP":
		case "doiLP":
		case "qr":
		case "query":
		case "portals":
		case "feedback":
		case "feedbackLP":
  			return (
				<div className="marvel-device macbook">
	  				<div className="top-bar"></div>
	  				<div className="camera"></div>
	  				<div className="screen">
					  <div className="browser--controls">
							<div className="window--controls">
								<span className="close"></span>
								<span className="minimise"></span>
								<span className="maximise"></span>
							</div>
							<div className="page--controls">
								<span className="back white--container"><Fa icon={["fas", "chevron-left"]} /></span>
								<span className="next white--container"><Fa icon={["fas", "chevron-right"]} /></span>
							</div>
							<span className="url--bar white--container">
							{domain && <>https://<strong>{domain}</strong>.cornermail.de/{wrapperInfo?.header}</>}
							</span>
						</div>
						<div className={classes.clickDisabledCalc}>
		  					{children}
						</div>
	  				</div>
	  				<div className="bottom-bar"></div>
				</div>
  			)
		case "doiMail":
		case "doneMail":
			return (
				<div className="marvel-device macbook">
					<div className="top-bar"></div>
					<div className="camera"></div>
					<div className="screen">
						<Grid container direction="column" className="emailClient-container">
							<Grid item className="emailClient-taskbar">
								<div className="browser--controls" style={{height: "35px"}}>
									<div className="window--controls">
										<span className="close"></span>
										<span className="minimise"></span>
										<span className="maximise"></span>
									</div>
									<span className="programmName-bar">
										Outlook 1337 Build
									</span>
									<Box className="full--screen" style={{cursor: "auto"}}></Box>
								</div>
							</Grid> 
							<Grid item container direction="row" className="emailClient-infobar">
								<Grid item className="emailClient-avatar">
									<MuiAvatar src={basicInfo?.useDoiChain === true && previewIdentifier === "doiMail" ? "/assets/images/doichain.png" : " "}>
										{wrapperInfo?.firstName.charAt(0)}{wrapperInfo?.lastName.charAt(0)}
									</MuiAvatar>
								</Grid>
								<Grid item container direction="column" className="emailClient-subject-wrapper">
									<Grid item container direction="row" className="emailClient-fromaddress-date">
										<Grid item className="emailClient-fromaddress">
											{wrapperInfo?.firstName} {wrapperInfo?.lastName} &#60;&nbsp;{basicInfo?.useDoiChain === true && previewIdentifier === "doiMail" ? "doichain@doichain.org" : basicInfo ? `${basicInfo.senderEmailLocalPart}@${basicInfo.senderEmailDomain?.domain}` : "username@cornermail.de"}&nbsp;&#62;
											{basicInfo?.useDoiChain === true && previewIdentifier === "doiMail"
												?
												<Tooltip title="Doichain verifiziertes Opt-In" placement="bottom" arrow>
												<Box component="span" ml={1}>
													<Fa icon={["fas", "shield-check"]} style={{ color: "#83ac1b" }} />
												</Box>
												</Tooltip>
												:
												null
											}
											{basicInfo?.useDoiChain === false && previewIdentifier === "doiMail"
												?
												<Tooltip title="Nicht durch Doichain verifiziert" placement="bottom" arrow>
												<Box component="span" ml={1}>
													<Fa icon={["fas", "lock-open-alt"]} style={{ color: "#e83338" }} />
												</Box>
												</Tooltip>
												:
												null
											}
										</Grid>
										<Grid item className="emailClient-date">
											{dateTime}
										</Grid>
									</Grid>
									<Grid item container direction="row" className="emailClient-subject-control">
										<Grid item className="emailClient-subject">
											{templateMailSettings?.subject.value}
										</Grid>
										<Grid item className="emailClient-control">
											<span><Fa icon={["fas", "reply"]} />&nbsp;&nbsp;{t("Antworten")}</span>
											<span><Fa icon={["fas", "share"]} />&nbsp;&nbsp;{t("Weiterleiten")}</span>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className="emailClient-content">
								<div className={classes.clickDisabled}>
									{children}
								</div>
							</Grid>
						</Grid>
					</div>
					<div className="bottom-bar"></div>
				</div>
				)
		default: return (
			null
		)
	}
}

const Smartphone: React.FC<{ handleExpand?: () => void, expanded?: boolean, wrapperInfo: Record<string, string> | undefined, templateMailSettings?: ITemplateMailSettings | undefined, previewIdentifier?: TemplateKey | RTemplateKey, basicInfo: FormValues | null, domain: string | undefined }> = ({ children, handleExpand, expanded, wrapperInfo, previewIdentifier, basicInfo, templateMailSettings, domain }) => {
	const { t } = useTranslation()
  	const classes = useStyles()
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
	setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
	setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	switch (previewIdentifier) {
		case "formLP":
		case "soiLP":
		case "doiLP":
		case "qr":
		case "query":
		case "portals":
		case "feedback":
		case "feedbackLP":
			return (
				<div className="marvel-device iphone8 silver">
					<div className="top-bar"></div>
					<div className="sleep"></div>
					<div className="volume"></div>
					<div className="camera"></div>
					<div className="sensor"></div>
					<div className="speaker"></div>
					<div className="screen">
						<div className="browser--controls">
							
							<span className="url--bar white--container" style={{marginLeft: "2%"}}>
								{domain && <>https://<strong>{domain}</strong>.cornermail.de/{wrapperInfo?.header}</>}
							</span>
							<span className="full--screen" onClick={handleExpand} ><Fa icon={["fas", "ellipsis-vertical"]} style={{fontSize: "1.5em"}}/></span>
						</div>
						<div className={classes.clickDisabledCalc}>
							{children}
						</div>
					</div>
					<div className="home"></div>
					<div className="bottom-bar"></div>
				</div>
			)
		case "doiMail":
		case "doneMail":
			return (
				<div className="marvel-device iphone8 silver">
					<div className="top-bar"></div>
					<div className="sleep"></div>
					<div className="volume"></div>
					<div className="camera"></div>
					<div className="sensor"></div>
					<div className="speaker"></div>
					<div className="screen">
						<Grid container direction="column" className="emailClient-container">
							<Grid item container direction="row" className="emailClient-infobar">
								<Grid item container direction="column" className="emailClient-subject-wrapper">
									<Grid item container direction="row" style={{flexWrap: "nowrap"}} className="emailClient-subject-control emailClient-subject-control-mobile">
										<Grid item className="emailClient-avatar" style={{minWidth: "50px"}}>
											<MuiAvatar src={basicInfo?.useDoiChain === true && previewIdentifier === "doiMail" ? "/assets/images/doichain.png" : " "}>
												{wrapperInfo?.firstName.charAt(0)}{wrapperInfo?.lastName.charAt(0)}
											</MuiAvatar>
										</Grid>
										<Grid item container direction="column">
											<Grid item className="emailClient-subject">
												{templateMailSettings?.subject.value}
											</Grid>
											<Grid item container direction="row" className="emailClient-fromaddress-date">
												<Grid item className="emailClient-date">
													{dateTime}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid item container direction='row' alignItems='center' className="emailClient-fromaddress">
										<Grid item style={{minWidth: "50px"}}>
											{t("Von")}:
										</Grid>
										<Grid item>
											<Button aria-describedby={id}  onClick={handleClick} style={{margin: 0, padding: 0, borderRadius: "25px"}}>
												<Chip
													style={{fontSize: "0.9em", maxWidth: "340px", textTransform: "none"}}
													icon={<FaceIcon />}
													label={basicInfo?.senderName}
													clickable
												/>
											</Button>
											<Popover
												id={id}
												open={open}
												anchorEl={anchorEl}
												onClose={handleClose}
												anchorOrigin={{
												   vertical: 'bottom',
												   horizontal: 'center',
												}}
												transformOrigin={{
												   vertical: 'top',
												   horizontal: 'center',
												}}
											>
												<Box style={{padding: "16px"}}>
													{basicInfo?.senderName} &#60;&nbsp;{basicInfo?.useDoiChain === true && previewIdentifier === "doiMail" ? "doichain@doichain.org" : basicInfo ? `${basicInfo.senderEmailLocalPart}@${basicInfo.senderEmailDomain?.domain}` : "username@cornermail.de"}&nbsp;&#62;
													{basicInfo?.useDoiChain === true && previewIdentifier === "doiMail"
														?
														<Tooltip title="Doichain verifiziertes Opt-In" placement="bottom" arrow>
															<Box component="span" ml={1}>
																<Fa icon={["fas", "shield-check"]} style={{ color: "#83ac1b" }} />
															</Box>
														</Tooltip>
														:
														null
													}
													{basicInfo?.useDoiChain === false && previewIdentifier === "doiMail"
														?
														<Tooltip title="Nicht durch Doichain verifiziert" placement="bottom" arrow>
															<Box component="span" ml={1}>
																<Fa icon={["fas", "lock-open-alt"]} style={{ color: "#e83338" }} />
															</Box>
														</Tooltip>
														:
														null
													}
												</Box>
											</Popover>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className="emailClient-content">
								<div className={classes.clickDisabled}>
									{children}
								</div>
							</Grid>
						</Grid>
					</div>
					<div className="home"></div>
					<div className="bottom-bar"></div>
				</div>
			)
		default: return (
			null
		)
	}
}

const Tablet: React.FC<{ handleExpand?: () => void, expanded?: boolean, wrapperInfo: Record<string, string> | undefined, templateMailSettings?: ITemplateMailSettings | undefined, previewIdentifier?: TemplateKey | RTemplateKey, basicInfo: FormValues | null, domain: string | undefined }> = ({ children, handleExpand, expanded, wrapperInfo, previewIdentifier, basicInfo, templateMailSettings, domain }) => {
	const { t } = useTranslation()
  	const classes = useStyles()
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
	setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
	setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	switch (previewIdentifier) {
		case "formLP":
		case "soiLP":
		case "doiLP":
		case "qr":
		case "query":
		case "portals":
		case "feedback":
		case "feedbackLP":
			return (
				<div className="marvel-device ipad silver">
					<div className="camera"></div>
						<div className="screen">
							<div className="browser--controls">
								<span className="url--bar white--container" style={{marginLeft: "2%"}}>
									{domain && <>https://<strong>{domain}</strong>.cornermail.de/{wrapperInfo?.header}</>}
								</span>
								<span className="full--screen" onClick={handleExpand} ><Fa icon={["fas", "ellipsis-vertical"]} style={{fontSize: "1.5em"}}/></span>
							</div>
							<div className={classes.clickDisabledCalc}>
								{children}
							</div>
						</div>
					<div className="home"></div>
				</div>
			)
		case "doiMail":
		case "doneMail":
			return (
				<div className="marvel-device ipad silver">
					<div className="camera"></div>
						<div className="screen">
							<Grid container direction="column" className="emailClient-container">
								<Grid item container direction="row" className="emailClient-infobar">
									<Grid item container direction="column" className="emailClient-subject-wrapper">
										<Grid item container direction="row" style={{flexWrap: "nowrap"}} className="emailClient-subject-control emailClient-subject-control-mobile">
											<Grid item className="emailClient-avatar" style={{minWidth: "50px"}}>
												<MuiAvatar src={basicInfo?.useDoiChain === true && previewIdentifier === "doiMail" ? "/assets/images/doichain.png" : " "}>
													{wrapperInfo?.firstName.charAt(0)}{wrapperInfo?.lastName.charAt(0)}
												</MuiAvatar>
											</Grid>
											<Grid item container direction="column">
												<Grid item className="emailClient-subject">
													{templateMailSettings?.subject.value}
												</Grid>
												<Grid item container direction="row" className="emailClient-fromaddress-date">
													<Grid item className="emailClient-date">
														{dateTime}
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item container direction='row' alignItems='center' className="emailClient-fromaddress">
											<Grid item style={{minWidth: "50px"}}>
												{t("Von")}:
											</Grid>
											<Grid item>
												<Button aria-describedby={id}  onClick={handleClick} style={{margin: 0, padding: 0, borderRadius: "25px"}}>
													<Chip
														style={{fontSize: "0.9em", maxWidth: "340px", textTransform: "none"}}
														icon={<FaceIcon />}
														label={basicInfo?.senderName}
														clickable
													/>
												</Button>
												<Popover
													id={id}
													open={open}
													anchorEl={anchorEl}
													onClose={handleClose}
													anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'center',
													}}
													transformOrigin={{
													vertical: 'top',
													horizontal: 'center',
													}}
												>
													<Box style={{padding: "16px"}}>
														{basicInfo?.senderName} &#60;&nbsp;{basicInfo?.useDoiChain === true && previewIdentifier === "doiMail" ? "doichain@doichain.org" : basicInfo ? `${basicInfo.senderEmailLocalPart}@${basicInfo.senderEmailDomain?.domain}` : "username@cornermail.de"}&nbsp;&#62;
														{basicInfo?.useDoiChain === true && previewIdentifier === "doiMail"
															?
															<Tooltip title="Doichain verifiziertes Opt-In" placement="bottom" arrow>
																<Box component="span" ml={1}>
																	<Fa icon={["fas", "shield-check"]} style={{ color: "#83ac1b" }} />
																</Box>
															</Tooltip>
															:
															null
														}
														{basicInfo?.useDoiChain === false && previewIdentifier === "doiMail"
															?
															<Tooltip title="Nicht durch Doichain verifiziert" placement="bottom" arrow>
																<Box component="span" ml={1}>
																	<Fa icon={["fas", "lock-open-alt"]} style={{ color: "#e83338" }} />
																</Box>
															</Tooltip>
															:
															null
														}
													</Box>
												</Popover>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item className="emailClient-content">
									<div className={classes.clickDisabled}>
										{children}
									</div>
								</Grid>
							</Grid>
						</div>
					<div className="home"></div>
				</div>
			)
		default: return (
			null
		)
	}
}


const PreviewFrame = ({ templateMailSettings, basicInfo, html, width, height, customHeader, variant = "plain", previewIdentifier, chat = null, chatView = "graph", imprint= null }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const header = typeof(customHeader?.header) === "string" && customHeader?.header.replace(/ /g, "-").toLowerCase()
  const { user } = useSession()
  const [wrapperInfo, setWrapperInfo] = useState({ header: "bespielansicht", firstName: "Max", lastName: "Mustermann" })
  const [domain, setDomain] = useState<string | undefined>()

  useEffect(() => {
	const fetchDomain = async () => {
	  const response = await domains.get_first()
	  setDomain(response.domain)
	}
	fetchDomain()
  },[])

  useEffect(() => {
	if (user && header) {
	  setWrapperInfo({ header: header, firstName: user.first_name, lastName: user.last_name })
	}
  }, [header, user])

  const iframeStyle = useMemo(() => ({
	height: variant === "plain" ? `${height}px` : variant === "desktop" ? "150%" : "100%",
	width: variant === "plain" ? `${width}px` : variant === "desktop" ? "150%" : "100%",
	transform: variant === "desktop" ? "scale(0.666666666) translate(-25%, -25%)" : "unset",
	border: "none"
  }), [width, height, variant])

  const handleUpdateIframe = useCallback(() => {
	if (html) {
	  writeToDocument(html)
	}
  },[html])

  const handleExpand = () => {
	if (expanded) {
	  setExpanded(false)
	} else {
	  setExpanded(true)
	}
  }

  const writeToDocument = (html: string) => {
	var iframe = iframeRef.current
	var doc = iframe?.contentWindow?.document || iframe?.contentDocument;
	if (!doc) return
	doc.open();
	doc.write(html);
	doc.close();
  }

  useEffect(() => {
	handleUpdateIframe()
  }, [html, variant, handleUpdateIframe])


	return (
		<Wrapper templateMailSettings={templateMailSettings} variant={variant} handleExpand={handleExpand} expanded={expanded} wrapperInfo={wrapperInfo} previewIdentifier={previewIdentifier} basicInfo={basicInfo} domain={domain}>
			{(chat && chatView === "chat" && previewIdentifier === "doiLP" && imprint) ?
				<ChatSim testChat={chat.chat} imp={imprint}/>
				:
				<iframe title="Vorschau" ref={iframeRef} onLoad={handleUpdateIframe} style={iframeStyle} />
			}
		</Wrapper>
	)
}

const Wrapper: React.FC<Pick<Props, "variant" | "handleExpand" | "expanded" | "wrapperInfo" | "previewIdentifier" | "basicInfo" | "templateMailSettings" | "domain">> = ({ variant, children, handleExpand, expanded, wrapperInfo, previewIdentifier, basicInfo, templateMailSettings, domain }) => {

  switch (variant) {
	case "smartphone": return <Smartphone wrapperInfo={wrapperInfo} handleExpand={handleExpand} expanded={expanded} previewIdentifier={previewIdentifier} basicInfo={basicInfo} templateMailSettings={templateMailSettings} domain={domain}>{children}</Smartphone>
	case "tablet": return <Tablet wrapperInfo={wrapperInfo} handleExpand={handleExpand} expanded={expanded} previewIdentifier={previewIdentifier} basicInfo={basicInfo} templateMailSettings={templateMailSettings} domain={domain}>{children}</Tablet>
	case "desktop": return <Desktop wrapperInfo={wrapperInfo} handleExpand={handleExpand} expanded={expanded} previewIdentifier={previewIdentifier} basicInfo={basicInfo} templateMailSettings={templateMailSettings} domain={domain}>{children}</Desktop>
	case "fullscreen": return <Fullscreen wrapperInfo={wrapperInfo} handleExpand={handleExpand} expanded={expanded} previewIdentifier={previewIdentifier} basicInfo={basicInfo} templateMailSettings={templateMailSettings} domain={domain}>{children}</Fullscreen>
	default: return <div>{children}</div>
  }
}

export default PreviewFrame