import { Page, Text, View, StyleSheet, Font, Image, Document, pdf } from '@react-pdf/renderer';
import React from "react"
import { Fade } from "@material-ui/core"

export const key = "003"
export const type = "DISPLAY"

Font.register({
    family: 'Castoro',
    src: '/assets/images/Castoro-Regular.ttf'
  });

const FixedStyles = (calc: number) => StyleSheet.create({
    page: {
        padding: 35 * calc
    },
    headerView: {
        top: 60 * calc,
        width: "100vw",
        position: "absolute"
    },
    header: {
        //fontSize: 34,
        fontFamily: 'Castoro',
        fontWeight: 100 ,
        lineHeight: 1.4 ,
        textAlign: "center",
        marginHorizontal: 35*calc,
    },
    qrcodeView: {
        position: "absolute",
        width: "100vw",
        top: 188* calc,
    },
    qrcode: {
        marginHorizontal: 100*calc,
    },
    bgimg: {
        position: "absolute",
        width: "100vw",
        top: 85*calc
    },
    footerView:{
        position: "absolute",
        width: "100vw",
        top: 350 * calc,
    },
    footer: {
        lineHeight: 1.4,
        textAlign: "center",
        marginHorizontal: 35 * calc,
        fontSize: 10 * calc
    },
    logo: {
        width: 75 * calc,
        position: "absolute",
        right: 30 * calc,
        top: 20* calc
    }
})

export const initialUserText = {
    Inhalt: {
        groupName: "Inhalt",
        faIcon: "text",
        iconType: "fal",
        placeholders: {
            header: {
                name: "header",
                label: "Überschrift",
                value: "Jetzt anmelden und keine Neuigkeiten und Angebote mehr verpassen.",
                type: "tinymce",

            },
            footer: {
                name: "footer",
                label: "Footer",
                value: "Nach Ihrer Anmeldung erhalten Sie zwei mal pro Monat exklusive Vorteile und Angebote. Eine Abmeldung ist jederzeit möglich.",
                type: "tinymce",
            }
        }
    }
}

export const initialUserStyles = (calc:number) => {
    const styles = {
        Schriftfarbe: {
            groupName: "Schriftfarbe",
            faIcon: "tint",
            iconType: "fal",
            placeholders: {
                HeaderColor: {
                    name: "HeaderColor",
                    value: "#000000",
                    type: "color",
                    label: "Überschrift",
                },
                FooterColor: {
                    name: "FooterColor",
                    value: "grey",
                    type: "color",
                    label: "Fußnote",
                },
            }
        },
        Schriftgröße: {
            groupName: "Schriftgröße",
            faIcon: "text-size",
            iconType: "fal",
            placeholders: {
                HeaderFontSize: {
                    name: "HeaderFontSize",
                    value: (15 * calc),
                    type: "slider",
                    label: "Größe der Überschrift",
                    props: {
                        returnValue: [(5*calc), (10*calc), (15*calc)],
                        min: 1,
                        max: 3,
                        marks: [{ "value": 1, "label": "S" }, { "value": 2, "label": "M" }, { "value": 3, "label": "L" }],
                        color: "secondary",
                    }
                },
            }
        },
        Hintergrund: {
            groupName: "Hintergrund",
            faIcon: "fill-drip",
            iconType: "fal",
            placeholders: {
                PageBackgroundColor: {
                    name: "PageBackgroundColor",
                    value: "#edeef0",
                    type: "color",
                    label: "Seitenhintergrund",
                }
            }
        },
    }
    return styles
}

export const preview =(
    <img src={require("./thubnail.png")} alt="preview"/>
)

export const mockup = (
    <Fade timeout={500} in={true}>
        <img src={require("./preview.jpg")} alt="preview"/>
    </Fade >
)

export const Template = async ({ imgURL, userText, userStyles, handleDocument, settings }: any) => {
    if (userText && userStyles && imgURL && settings) {
        const fixedStyles = FixedStyles(settings.calc)
        const myPdf = (
            <Document>
                <Page size={settings.size} style={[fixedStyles.page, {backgroundColor: userStyles.Hintergrund.placeholders.PageBackgroundColor.value}]}> 
                    <Image src={require("./cp-logo.png")} style={fixedStyles.logo}/>
                    <Image src={require("./bgpdf.jpg")} style={fixedStyles.bgimg}/>
                    <View style={fixedStyles.headerView}>
                        <Text style={[fixedStyles.header, {color: userStyles.Schriftfarbe.placeholders.HeaderColor.value, fontSize: userStyles.Schriftgröße.placeholders.HeaderFontSize.value}]}>
                            {userText.Inhalt.placeholders.header.value}
                        </Text>
                    </View>
                    <View style={fixedStyles.qrcodeView}>
                        <Image source={imgURL} style={fixedStyles.qrcode} />
                    </View>
                    <View style={fixedStyles.footerView}>
                        <Text style={[fixedStyles.footer, {color: userStyles.Schriftfarbe.placeholders.FooterColor.value}]}>
                            {userText.Inhalt.placeholders.footer.value}
                        </Text>
                    </View>
                </Page>
            </Document>
        )

        let url
        await pdf(myPdf)
            .toBlob()
            .then(blob => {
                url = URL.createObjectURL(blob)
            })
        handleDocument(url)
        return null
    } else {
        return null
    }
}