import React, { useState } from "react"
import { Grid, TextField, makeStyles , Paper, Tooltip} from "@material-ui/core"
import Button from "components/Button/Button"
import { useTranslation } from "react-i18next"
import { Fa, FaButton } from "styled/muiComponents"
import { Typography } from 'styled/muiComponents'


const useStyles = makeStyles(theme => ({
    iconOverlay: {
        color: "#ffffff",
        transition: "0.35s all ease",
        "&:hover": {
            color: theme.palette.companyRed.main
        }
    },
    imgContainer:{
        position:"relative",
        backgroundColor: theme.palette.primary.main,
        height: "100%"
    },
    iconBar: {
        position: "absolute",
        justifyContent: "center",
        height: "100%",
        color: "#ffffff",
        zIndex: 1000
    },
    img: {
        zIndex: 10,
        transition: "0.35s all ease",
    },
    paper: {
        marginTop: theme.spacing(1.5)
    },
    searchBar: {
        padding: theme.spacing(3),
        borderBottom: "1px solid #cccccc",
    },
    previewImage: {
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100%",
        width: "100%"
    },
    preSearchButton: {
        marginLeft: "8px",
        padding: "0 10px 0 0",
        overflow: "hidden",
        borderColor: "#cccccc"
    },
    searchPreviewImage: {
        height: "100%",
        maxHeight: "35px",
        marginRight: "8px"
    }
}))

const UnsplashImages = ({ query, handleQueryChange, handleSearch, pics, selectImage, handleTermSearch, handleLoadMore }) => {

    let imageList=[]
    pics.forEach(pic => {
        imageList.push({width: pic.width, height: pic.height})
    })
    let layoutGeometry = require('justified-layout')(imageList, {containerWidth: 1170, targetRowHeight: 200})

    const { t } = useTranslation()
    const classes = useStyles()

    const [largePreviewOpen, setLargePreviewOpen] = useState(false)
    const [largePreviewImage, setLargePreviewImage] = useState()

    const handleClickEnlarge = (image) => {
        setLargePreviewOpen(true)
        setLargePreviewImage(image)
    }

    const handleClickMinimize = () => {
        setLargePreviewOpen(false)
    }

    const searchTerms = ["clouds", "mountains", "forest", "sea", "spring", "summer", "fall", "winter"]

    return (
        <>
            <Grid item container direction="column" style={{width: "100%"}}>
                <Grid item container>
                    {largePreviewOpen ? 
                        <Grid item container style={{height: "60px", padding: "16px 16px 0"}}>
                            <FaButton className={classes.icon} onClick={handleClickMinimize}>
                                <Fa icon={["fal", "long-arrow-left"]} />
                            </FaButton>
                            <FaButton className={classes.icon} onClick={() => selectImage({ image: largePreviewImage })}>
                                <Fa icon={["fal", "plus-circle"]} />
                            </FaButton> 
                        </Grid>
                        : 
                        <Grid item container direction="column" justify="center" alignItems="center" className={classes.searchBar}>
                            <Grid item>
                                <Typography variant="h1" mb={2}>{t("Suche in unserer Bilddatenbank nach einem passenden Bild")}</Typography>
                            </Grid>
                            <Grid item containter>
                                <form onSubmit={handleSearch} style={{padding: "8px"}}>
                                    <Grid item containter>
                                        <TextField
                                            onChange={handleQueryChange}
                                            value={query}
                                            variant="outlined"
                                            placeholder="z. B. Forest"
                                            label="Bildersuche"
                                            size="small"
                                        />
                                        <Button variant="contained" color="primary" style={{marginLeft: "8px"}} onClick={handleSearch}>
                                            <Fa icon={["far", "magnifying-glass"]} mr={1}/> {t("Suchen")}
                                        </Button>
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid item container justify="center" style={{marginTop: "24px"}}>
                                {searchTerms.map((term, idx) => 
                                    <Button key={idx} onClick={(() => handleTermSearch(term))} variant="outlined" alt={t(term)} size="small" className={classes.preSearchButton}>
                                        <img src={`/assets/images/unsplash/${term}.jpg`} className={classes.searchPreviewImage} alt=""/>
                                        {t(`/fields:${term}`)}
                                    </Button>
                                )}
                            </Grid> 
                        </Grid>
                        }
                </Grid>
                <Grid item style={{padding: "16px", boxSizing: "border-box", flexGrow: 1}}>
                    {largePreviewOpen ?
                        <Grid item container className={classes.previewImage} style={{backgroundImage: `url(${largePreviewImage})`}}>
                        </Grid>
                        :
                        <>
                        <Grid container direction="row" justify="space-between">
                            {layoutGeometry.boxes.map((dimensions, idx) => 
                                <Preview pic={pics[idx]} selectImage={selectImage} handleClickEnlarge={handleClickEnlarge} dimensions={dimensions} key={pics[idx].id}/>
                            )}
                        </Grid>
                        {pics.length === 0 ? null : <Button onClick={handleLoadMore}>{t("Mehr Laden")}</Button> }
                        </>
                    }
                </Grid>
            </Grid>
        </>
    )
}

const Preview = ({pic, selectImage, handleClickEnlarge, dimensions}) => {

    const [isHovered, setIsHovered] = useState(false)

    const classes = useStyles()
    const { t } = useTranslation()

    return( 
    <Paper className={classes.paper} style={{height: "250px", width: "250px"}}>
        <Grid container direction="column" alignItems="center" justify="center" className={classes.imgContainer} onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            {isHovered &&
                <Grid container className={classes.iconBar} alignItems="center">
                    <Grid item>
                        <Tooltip title={t("Vergrößern")}>
                            <FaButton onClick={() => handleClickEnlarge(pic.urls.regular)}>
                                <Fa icon={["fal", "arrows-maximize"]} className={classes.iconOverlay} />
                            </FaButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={t("Einfügen")}>
                            <FaButton onClick={() => selectImage({ image: pic.urls.regular })}>
                                <Fa icon={["fal", "plus-circle"]} className={classes.iconOverlay} />
                            </FaButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            }
            <img className={classes.img} src={pic.urls.regular} id={pic.id} alt={pic.description} style={{ width: dimensions.width === dimensions.height ? "100%" : dimensions.width > dimensions.height ? "100%" : "auto" , height: dimensions.height > dimensions.width ? "100%" : "auto", opacity: isHovered ? 0.7 : 1}}/>
        </Grid>
    </Paper>)
}

export default UnsplashImages