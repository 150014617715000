import React, { useState } from "react"
import {Navigation, PDFNavigationProps } from "../StoreDisplayWizard"
import { makeStyles, Theme, Tooltip } from "@material-ui/core"
import { Fa, FaButton, Grid, Typography } from 'styled/muiComponents'
import FormikInput from "components/formik/FormikInput"
import {Form} from "react-final-form"
import { useTranslation } from "react-i18next"
import Button from "components/Button/Button"
import * as TEMPLATES from "../../PDFTemplates/index"
import Pixi from 'components/Pixi'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useLocation } from "react-router-dom"
import SwipeableViews from "react-swipeable-views"

type Props = {
    template: any
    imgURL: string
    pickTemplate: (template: any) => void
    navigationProps: PDFNavigationProps
    handleChangeSettings: (event: any) => void
    settings: any
    handleChangeType: (event: any) => void
    handleChangeSize: (event: any) => void
}

const useStyles = makeStyles<Theme, {highlight?: Boolean, open?:boolean}>(theme => ({
    imageWrapper: {
        padding: theme.spacing(1),
        position: "relative",
        "& img": {
            width: "100%"
        }
    },
    overlay: {
        backgroundColor: "rgba(120, 120, 120, 0.2)"
    },

    editorWrapper: {
        display: "flex",
        height: "100%",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "stretch",
        backgroundColor: theme.palette.primary.light,
    },
    editorConfigSideBar: {
        flex: "0 1 auto",
        backgroundColor: theme.palette.primary.light,
        width: "400px",
        minWidth: "400px",
        height: "100%",
        maxHeight: "100vh",
        borderRadius: "0px",
        padding: "0px",
        overflow: "hidden",
        fontWeight: 300,
    },
    editorPreviewWrapper: {
        display: "flex",
        flex: "1 1 auto",
        height: "100%",
        maxHeight: "100vh",
        overflowX: "auto",
        justifyContent: "center",
        alignItems: "center",
        "& img":{
            borderRadius: "8px",
        }
    },

    root: {
        backgroundColor: theme.palette.primary.light,
        color: "#ffffff",
        height: "calc(100% - 64px)",
        display: "flex",
        flexFlow: "column",
        boxSizing: "border-box",
    },
    header: {
        /*padding: theme.spacing(1)*/
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subheader: {
        fontSize: "1.25rem",
    },
    headerDescription: {
        padding: theme.spacing(1.2),
        fontSize: "0.8rem",
        backgroundColor: "#ffffff26",
        borderRadius: "5px",
        position: "relative",
        '&:after': {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            width: 0,
            height: 0,
            border: "18px solid transparent",
            borderRightColor: "#ffffff26",
            borderLeft: 0,
            borderBottom: 0,
            marginTop: "-9px",
            marginLeft: "-18px",
        },
    },
    DescriptionWrapper: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    cardFixedHeader: {
        flex: "0 1 auto",
    },
    cardContent: {
        padding: 0,
        overflow: "auto",
        flex: "1 1 auto",
        width: "100%",
    },
    inputGroupContainer: {
        transition: "0.35s all ease",
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px",
         "&:hover": {
             backgroundColor: "#ffffff0d",
         }
     },
     inputWrapper: {
       padding: theme.spacing(1.5),
       paddingLeft: theme.spacing(2),
       paddingRight: theme.spacing(2),
       "&:first-child": {
         paddingTop: theme.spacing(2.5),
       },
       "&:last-child": {
         paddingBottom: theme.spacing(2.5),
       },
       "& img": {
           maxWidth: "100%"
       },
       "& input": {
        margin: 0
       },
       transition: '0.35s all ease',
      "&:hover": {
          color: theme.palette.companyRed.main
      }
    },
    inputWrapperPreview: {
        padding: theme.spacing(1.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(0),
        "&:last-child": {
          paddingBottom: theme.spacing(2.5),
        },
        "& img": {
            maxWidth: "100%"
        },
       "&:hover": {
           color: theme.palette.companyRed.main
       }
     },
    hightlightSelectedTemplate: {
        //border: ({ highlight }) => highlight ? `2px solid ${theme.palette.companyRed.main}` : "none"
        padding: theme.spacing(1)
    },
    highlightSelectedWebform: {
        //border: ({ highlight }) => highlight ? `2px solid ${theme.palette.companyRed.main}` : "none"
    },
    previewImage: {
        border: ({ highlight }) => highlight ? "3px solid #83ac1b" : "3px solid #ffffff",
        backgroundColor: "#83ac1b !important",
        padding: 0,
        boxSizing: "border-box",
        transition: '0.3s all ease',
        opacity: 1,
        "&:hover":{
            border: ({ highlight }) => highlight ? "3px solid #83ac1b" : "3px solid #ffffff",
            opacity: "0.8"
        },
        "& img": {
            opacity: ({ highlight }) => highlight ? "0.8" : "1",
            borderRadius: "2px"
        }
    },
    printTypeImage: {
        border: "1px solid rgba(255, 255, 255, 0.25)",
        backgroundColor: "transparent !important",
        padding: 0,
        boxSizing: "border-box",
        transition: '0.3s all ease',
        opacity: 1,
        "&:hover":{
           // opacity: "0.8",
            border: "1px solid rgba(255, 255, 255, 1)",
            backgroundColor: "rgba(255, 255, 255, 0.25) !important",
        },
        "& img": {
            width: "100%",
        }
    },
    inputGroupHeader: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        transition: "0.35s all ease",
        color: ({ open }) => open ? theme.palette.companyRed.main : "white",
        "&:hover": {
            color: theme.palette.companyRed.main,
            cursor: "pointer",
        },
    },
    icons: {
        color: "white",
        transition: "0.35s all ease",
        "&:hover": {
            color: theme.palette.companyRed.main
        }
    },
    printTypesWrapper: {
        padding: theme.spacing(1),
        paddingTop: 0,
        //paddingLeft: theme.spacing(2),
        //paddingRight: theme.spacing(2),
    }
}))

    const PickPDFTemplate = ({ pickTemplate, handleChangeSize, handleChangeType, navigationProps, handleChangeSettings, template, settings, imgURL }: Props) => {
    const location = useLocation()
    const classes = useStyles({})
    const { t } = useTranslation()
    const [selected, setSelected] = useState<number | null>(
        Number(location.state?.template.key) - 1 || null)

    const handleSubmit = () => {
        console.warn("not implemented")
    }
    const initialValues = settings

    const handleClick = ({template, idx}: any) => {
        setSelected(idx)
        pickTemplate(template)
    }

    return (
        <div className={classes.editorWrapper}>
            <div className={classes.editorConfigSideBar}>
                <Navigation {...navigationProps} templateSelected={(selected !== null && settings.name !== "" ) ? false : true} />
                <div className={classes.root}>
                    <div className={classes.cardFixedHeader}>
                        <Typography variant="h3" className={classes.header}>
                            {t("Vorlage wählen")}
                        </Typography>
                        <Grid container direction="row" justify="center" alignItems="center" className={classes.DescriptionWrapper}>
                            <Grid item xs={4}>
                                <Pixi size="full" pose="waiting" />
                            </Grid>
                            <Grid item xs={8}>
                                <div className={classes.headerDescription}>
                                    {t("Wähle hier eine Vorlage für dein Printmedium und definiere auch gleich dein Wunsch Format.")}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.cardContent} style={{overflow: "hidden"}}>
                            <Form initialValues={initialValues} onSubmit={handleSubmit}
                                render={({ handleSubmit, submitting }) => (
                                    <form className="editor-config" onSubmit={handleSubmit} style={{height: "100%", display: "flex", flexDirection: "column", flexGrow: 1}}>
                                        <div className={classes.inputWrapper} >
                                            <FormikInput
                                                type="text"
                                                name="name"
                                                label={t("Name des Printmediums")}
                                                onChange={handleChangeSettings}
                                                variant="outlined"
                                                value={settings.name}
                                                style={{margin: 0, marginBotom: "12px"}}
                                                required
                                            />
                                        </div>
                                        <SlidingSettings handleChangeType={handleChangeType} settings={settings} handleChangeSize={handleChangeSize} template={template} handleClick={handleClick} selected={selected} imgURL={imgURL}/>
                                    </form>
                                )}
                            />
                    </div>
                </div>
            </div>
            <div className={classes.editorPreviewWrapper}>
                {template ?
                    template.mockup
                    :
                    <Grid item container justify='center' alignItems='center' style={{height: "100%"}}>
                        <Grid item container direction='column' style={{maxWidth: "600px"}}>
                            <Typography variant='h1' style={{color: "#7f7f7f", textAlign: "center", fontSize: "2.75em"}} mb={5}>{t("Wähle eine Vorlage, um zu beginnen.")}</Typography>
                            <img src='/assets/images/please-choose-arrow.svg' style={{color: "#ccc", maxWidth: "400px", marginLeft: "-17%"}} alt="arrow"/>
                        </Grid>
                    </Grid>
                }
            </div>
        </div>
    )
}

const RenderPreview = ({ template, handleClick, idx, selected }: any) => {

    const classes = useStyles({ highlight: idx === selected })
    const location = useLocation()

    return (
        <Grid item xs={6} className={classes.hightlightSelectedTemplate}>
            <Button onClick={() => handleClick({ template, idx })} style={{ maxWidth: "100%" }} disabled={location.state?.template !== undefined} className={classes.previewImage}>
                {template.preview}
            </Button>
        </Grid>
    )
}

const SlidingSettings = ({settings, handleChangeSize, template, handleClick, selected, imgURL, handleChangeType}: any) => {
    const { t } = useTranslation()
    const classes = useStyles({})
    
    const typeParams = () => {
        switch(settings.type){
            case "DISPLAY": return ["A2", "A3", "A4", "A5", "A6"]
            case "FYLER": return [""]
            case "BUSINESSCARD": return [""]
            case "SCRATCHCARD": return [""]
            case "STICKER": return [""]
            case "COASTER": return [""]
            case "STAMPCARD": return [""]
            default: return [""]
        }
    }

    return (
        
        <SwipeableViews index={settings.type === "" ? 0 : 1}
            containerStyle={{
                transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1)',
            }} >
            <PerfectScrollbar> {/* index 0*/}
            {/**TODO translations */}
                <Grid container className={classes.printTypesWrapper}>
                    <Grid item xs={6} className={classes.hightlightSelectedTemplate}>
                        <Tooltip title="DIN-Format einseitig (ideal für Aufsteller, Plakate usw.)">
                            <Button onClick={() => handleChangeType("DISPLAY")} style={{ maxWidth: "100%" }} className={classes.printTypeImage}>
                                <img src="/assets/images/print/DIN-Format.svg" alt="DIN-Format" />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} className={classes.hightlightSelectedTemplate}>
                        <Tooltip title="DIN-Format zweiseitig (ideal für Flyer)"> 
                            <Button onClick={() => handleChangeType("FLYER")} style={{ maxWidth: "100%" }} className={classes.printTypeImage}>
                                <img src="/assets/images/print/DIN-Format-beidseitig.svg" alt="DIN-Format two sides"/>
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} className={classes.hightlightSelectedTemplate}>
                        <Tooltip title="Visitenkarten">
                            <Button onClick={() => handleChangeType("BUSINESSCARD")} style={{ maxWidth: "100%" }} className={classes.printTypeImage}>
                                <img src="/assets/images/print/visitenkarte.svg" alt="Business Card" />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} className={classes.hightlightSelectedTemplate}>
                        <Tooltip title="Rubbelkarten">
                            <Button onClick={() => handleChangeType("SCRATCHCARD")} style={{ maxWidth: "100%" }} className={classes.printTypeImage}>
                                <img src="/assets/images/print/scratchcard.svg" alt="Scratch Card" />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} className={classes.hightlightSelectedTemplate}>
                        <Tooltip title="Aufkleber">
                            <Button onClick={() => handleChangeType("STICKER")} style={{ maxWidth: "100%" }} className={classes.printTypeImage}>
                                <img src="/assets/images/print/sticker.svg" alt="Sticker" />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} className={classes.hightlightSelectedTemplate}>
                        <Tooltip title="Bierdeckel">
                            <Button onClick={() => handleChangeType("COASTER")} style={{ maxWidth: "100%" }} className={classes.printTypeImage}>
                                <img src="/assets/images/print/COASTER.svg" alt="Coaster" />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} className={classes.hightlightSelectedTemplate}>
                        <Tooltip title="Stempel- und Bonuskarten">
                            <Button onClick={() => handleChangeType("STAMPCARD")} style={{ maxWidth: "100%" }} className={classes.printTypeImage}>
                                <img src="/assets/images/print/stampcard.svg" alt="Stampcard" />
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </PerfectScrollbar>
            

            <PerfectScrollbar> {/* index 1 */}
                <Grid container className={classes.printTypesWrapper}>
                    <div className={classes.cardContent}>
                        <Grid container direction="row" style={{width: "100%"}}>
                            <Grid container item wrap="nowrap" alignItems="center" style={{marginBottom: "8px"}}>
                                <Grid item>
                                    <FaButton className={classes.icons} onClick={() => handleChangeType("")} mr={1}>
                                        <Fa icon={["fal", "long-arrow-left"]} />
                                    </FaButton>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h3">
                                        {t(`/fields:${settings.type}`)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.inputWrapperPreview} style={{width: "100%"}}>
                                <FormikInput
                                    type="enum"
                                    name="size"
                                    label={t("Größe")}
                                    typeParams={typeParams()}
                                    onChange={handleChangeSize}
                                    variant="outlined"
                                    value={settings.size}
                                    style={{ margin: 0 }}
                                    labelProps={{
                                        variant: "outlined"
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.inputWrapperPreview} style={{padding: 0}}>
                        <Grid container>
                            {Object.values(TEMPLATES).filter((t: any) => t.type === settings.type).map((item: any, idx: number) =>
                                <RenderPreview template={item} handleClick={handleClick} idx={idx} selected={selected} settings={settings} imgURL={imgURL} key={idx} />
                            )}
                        </Grid>
                    </div>
                </Grid>
            </PerfectScrollbar>
        </SwipeableViews>
    )
}

export default PickPDFTemplate