import React from 'react'
import {IRecipientTag} from '../../../../types/recipient'
import Chip from '@material-ui/core/Chip';
import {makeStyles} from '@material-ui/core'
import { secondaryHue } from '../../../../theme'
import { Fa } from '../../../../styled/muiComponents'

const useStyles = makeStyles(theme => ({
    tag:{
        background: secondaryHue["c700"],
        color:"white",
    }
}))

export type Props = {
    tag: IRecipientTag
    onRemove: (tag:IRecipientTag)=>void
}

const RecipientTags = ({tag, onRemove }: Props) => {
    const classes = useStyles()

    return(
        <Chip
            className={classes.tag}
            label={`${tag.name}`}
            deleteIcon={<Fa icon={["fas", "circle-xmark"]} ml={0} style={{color: "#ffffff"}}/>}
            onDelete={onRemove}
            clickable={false}
            size="small"
        />
    )
}
export default RecipientTags