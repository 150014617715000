import React, { useEffect, useState } from "react"
import webforms, { IWebform } from "models/webform"
import { fromCornerstoneFilter } from "constants/fromCornerstoneFilter"
import { Form } from "react-final-form"
import { FormikInput } from "styled/muiComponents"
import LoadingAnimation from "components/LoadingAnimation"
import { useTranslation } from "react-i18next"
import Button from "components/Button/Button"
import { Typography, Grid, Fa } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { makeStyles } from '@material-ui/core'
import useSession from '../../../hooks/useSession'

const useStyles = makeStyles(theme => ({
	
    navigation: {
        backgroundColor: "transparent",
        color: "transparent",
        padding: `0px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
    },
    root: {
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.companyDark.light,
        backgroundImage: "none",
        overflow: "auto",
        //padding: theme.spacing(1),
        boxSizing: "border-box",
        "& p": {
            fontWeight: "300",
            "& strong": {
                fontWeight: "500"
            }
        }
    },
    form: {
        width: "100%",
        margin: "auto",
        maxWidth: "600px",
        // padding: theme.spacing(4),
        backgroundColor: theme.palette.lightGrey.main,
        borderRadius: theme.spacing(0.5),
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        boxSizing: "border-box",
        overflow: "hidden"
    },
    formHeader: {
        padding: theme.spacing(4),
        //backgroundColor: "#83ac1b2b",
        backgroundColor: "#E6ECEF",
    },
    formContent: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    formFooter: {
     //   paddingLeft: theme.spacing(4),
     //   paddingRight: theme.spacing(4),
    },
    formNote: {
        width: "100%",
        maxWidth: "600px",
        color: "#ffffff75",
        paddingTop: theme.spacing(2)
    },
    headerDescription: {
        padding: theme.spacing(2),
        fontSize: "0.8rem",
        //backgroundColor: "#00000015",
        backgroundColor: "#ffffff90",
        borderRadius: "5px",
        position: "relative",
        '&:after': {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            width: 0,
            height: 0,
            border: "18px solid transparent",
            //borderRightColor: "#00000015",
            borderRightColor: "#ffffff90",
            borderLeft: 0,
            borderBottom: 0,
            marginTop: "-9px",
            marginLeft: "-18px",
        },
    },
    success: {
        color: theme.palette.success.main
    },
    error: {
        color: theme.palette.error.main
    },
    pixiSpeech: {
     //   color: "white"
    },
    requiredNotice: {
     //   color: "#95a0a6",
        fontSize: "0.9em"
    },
    disabledInput: {
        "& .Mui-disabled fieldset": {
     //       borderColor: "rgba(255, 255, 255, 0.25) !important"
        }
    },
    table: {
        "& td": {
            padding: theme.spacing(0.5)
        }
    },
    stepsReviewRow: {
        margin: "0 -10px",
        padding: "0 10px",
        transition: "0.3s all ease",
        "&:hover": {
            backgroundColor: "#E6ECEF",
        }
    }
}))

const POSWebforms = () => {
    const [webformList, setWebformList] = useState<IWebform[] | null>(null)
    const classes = useStyles()
    const { t } = useTranslation()
    const { logout } = useSession()

    useEffect(() => {
        const fetchWebforms = async () => {
            const result = await webforms.getList()
            setWebformList(fromCornerstoneFilter(result.data))
        }
        fetchWebforms()
    },[])

    
    if(!webformList){
        return <LoadingAnimation/>
    }

    const handleSubmit = async (values: any) => {
        const webform = webformList.find((w: any) => w.name === values.webform)
        const result = await webforms.getFormUrl(webform!)
        if(values.qr){
            window.location.href=`${result}?pos&qr`
        } else {
            window.location.href=`${result}?pos`
        }
        
    }

    const webformNames = webformList.map((w: any) => w.name)

    return(
        <Form onSubmit={handleSubmit}
            render={({ handleSubmit, submitting }) =>(
                <Grid container item direction="column" className={classes.root}>
                    <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" justify="flex-start">
                                <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                    <Grid item sm={4}>
                                        <div style={{ maxWidth: "150px" }}>
                                            <Pixi size="full" pose="waiting"></Pixi>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <div className={classes.headerDescription} >
                                        <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                            <Typography variant="h5" className={classes.pixiSpeech}>
                                                {t("Wähle eine Anmeldestrecke aus.")}
                                            </Typography>
                                        </Typist>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item container className={classes.formContent}>
                                    <Grid item xs={12}>
                                        
                                        <FormikInput 
                                            type="enum"
                                            name="webform"
                                            typeParams={webformNames}
                                            variant="outlined"
                                            labelProps={{
                                                variant: "outlined"
                                            }}
                                            label={t("Anmeldestrecke")}
                                            required
                                        />
                                        <FormikInput
                                            type="checkbox"
                                            name="qr"
                                            margin="dense"
                                            label="QR-Code anzeigen"
                                        />
                                       
                                    </Grid>
                                </Grid>
                                
                                <Grid item sm={12} container className={classes.navigation} justify="space-between" alignItems="center" >
                                    <Button variant="contained" color="error" onClick={logout} size="small">
                                    <Fa icon={["fas", "power-off"]} mr={1} />
                                        {t("Abmelden")}
                                    </Button>
                                    <Button variant="contained" color="success" type="submit" size="small">
                                        {t("Formular auswählen")}
                                        <Fa icon={["fas", "chevron-right"]} ml={1}></Fa>
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>

        )}
        />
    )
}

export default POSWebforms