import React from 'react'
import PixiStanding from 'animated/pixi.svg'
import PixiDownload from 'animated/pixi-download.svg'
import { makeStyles, Theme } from '@material-ui/core'

export type Props = {
    size?: "small" | "medium" | "large" | "full",
    pose?: "waiting" | "happy" | "angry" | "funny" | "download",
    onClick?: () => void
}

const useStyles = makeStyles<Theme, Props>(() => ({
    root: {
        cursor: ({ onClick }) => onClick ? "pointer" : "default"
    },
    small: {        
        width: "20px"
    },
    medium: {
        width: "80px"
    },
    large: {
        width: "120px"
    },
    full: {
        width: "100%",
    },
    object: {
        pointerEvents: "none",
        userSelect: "none"
    }

}))

const Pixi = ({ size="medium", pose="waiting", onClick }: Props) => {
    const classes = useStyles({ size, onClick })
    const Svg = {
        waiting: PixiStanding,
        happy: PixiStanding,
        angry: PixiStanding,
        funny: PixiStanding,
        download: PixiDownload,
    }[pose]
    return (
        <div className={[classes.root, classes[size]].join(" ")} onClick={onClick}>
            <object className={[classes.object, classes[size]].join(" ")} type="image/svg+xml" data={Svg}>svg-animation</object>
        </div>
      );
}

export default Pixi