import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import recipientApi, { IRecipient } from 'models/recipient'
import { makeStyles, Card, CardHeader, CardContent, Divider } from '@material-ui/core'
import { IRecipientEvent, IRecipientTag } from 'types/recipient'
import RecipientEvent from '../recipients/RecipientEvent'
import { useTranslation } from 'react-i18next'
import RecipientTag from './RecipientTag'
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import { useDialog } from 'hooks/useDialog'
import { Form } from 'react-final-form'
import Button from "components/Button/Button"
import AddRecipientTag, { Props as AddRecipientTagProps, Response as AddRecipientTagResponse } from './AddRecipientTag'
import ConfirmRemoveRecipientTag, { Props as ConfirmRemoveRecipientTagProps, Response as ConfirmRemoveRecipientTagResponse } from './ConfirmRemoveRecipientTag'
import FormikInput from "components/formik/FormikInput"
import { Info, DBValuesSchema } from 'models/builder'
import PagePadding from "components/PagePadding"
import { Fa, Grid, Typography } from '../../../../styled/muiComponents'
import { Block, BlockTitle, BlockContent } from 'components/Block'
import Chip from '@material-ui/core/Chip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(theme => ({
    table: {
        margin: 0,
        "& td": {
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
            paddingLeft: "0px",
        }
    },
    tableLabel: {
        paddingRight: theme.spacing(1),
        fontWeight: 400
    },
    wrapper: {
        padding: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        backgroundColor: "#e6ecef"
    },
    badge: {
        marginLeft: theme.spacing(1.5),
        color: "#ffffff",
        fontSize: "0.7em",
        fontWeight: 600,
        letterSpacing: "2px",
        height: "24px",
        "& .MuiChip-label": {
            padding: "4 8px"
        }
    },
    active: {
        backgroundColor: theme.palette.success.main,
    },
    inactive: {
        backgroundColor: theme.palette.error.main,
    }
}))

export type IRouteParams = {
    id: string
}

export enum EditState {
    "IsEditing" = "IsEditing",
    "IsViewing" = "IsViewing"
}

export type RecipientLocationState = {
    recipient: IRecipient | null
    editState: EditState
} | undefined

export type IValues = DBValuesSchema

const initialValues: IValues = {

}

const Recipient = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const [recipient, setRecipient] = useState<IRecipient>(location.state?.recipient || null)
    const [editState, setEditState] = useState<EditState>(location.state?.editState || EditState.IsViewing)
    const [recipientEvents, setRecipientEvents] = useState<IRecipientEvent[]>([])
    const [recipientTags, setRecipientTags] = useState<IRecipientTag[]>([])
    const [recipientInfo, setRecipientInfo] = useState<Info | null>(null)
    const { dialog } = useDialog()
    const classes = useStyles()
    const params = useParams<IRouteParams>()
    const history = useHistory<RecipientLocationState>()
    const [tabIndex, setTabIndex] = useState(0)
    const realInitialValues = { ...initialValues,...recipient } // TODO gehts besser?

    console.log(params)

    useEffect(() => {
        history.replace(location.pathname) // reset locationState
        const id = parseInt(params.id)
        const fetchRecipient = async () => {
            try {
                const result = await recipientApi.get(id)
                setRecipient(result)
            } catch (e) {
                throw new Error("resource could not be loaded")
            }
        }
        const fetchRecipientEvents = async () => {
            try {
                const events = await recipientApi.one(id).events()
                setRecipientEvents(events)
            } catch (e) {
                //could not be loaded
            }
        }
        const fetchRecipientTags = async () => {
            try {
                const tags = await recipientApi.one(id).tags()
                setRecipientTags(tags)
            } catch (e) {
                //could not be loaded
            }
        }
        const fetchRecipientInfo = async () => {
            try {
                const info = await recipientApi.info()
                //@ts-ignore
                setRecipientInfo(info)
            } catch (e) {
                // could not be loaded
            }
        }
        fetchRecipient(); fetchRecipientEvents(); fetchRecipientTags(); fetchRecipientInfo()
    }, [params.id, history, location.pathname])

    const handleEdit = async () => {
        setEditState(EditState.IsEditing)
    }

    const handleSubmit = async (values: IValues) => {
        try {
            const result = await recipientApi.one(parseInt(params.id)).saveRecipientInfo(values)
            setRecipient(result)
            setEditState(EditState.IsViewing)
        }catch(e){
            //ERROR
        }
    }

    const handleCancel = () => {
        setEditState(EditState.IsViewing)
    }

    const handleGoBack = () => {
        console.warn("not implemented")
    }

    const handleRemove = () => {
        console.warn("not implemented")
    }

    const handleTagRemove = async (tag: IRecipientTag) => {
        dialog.show<ConfirmRemoveRecipientTagProps>({
            component: ConfirmRemoveRecipientTag,
            props: {
                recipientId: parseInt(params.id),
                tag
            }
        }).then((response: ConfirmRemoveRecipientTagResponse) => {
            if (response) {
                setRecipientTags(response)
            }
        }).catch(() => {
            
        })
    }

    const handleAddTag = async () => {
        dialog.show<AddRecipientTagProps>({
            component: AddRecipientTag,
            props: {
                recipientId: parseInt(params.id)

            }
        }).then((response: AddRecipientTagResponse) => {
            if (response) {
                setRecipientTags(response)
            }
        }).catch(() => {

        })
    }

    if (recipientInfo === null) {
        //TODO Sergei fancy loading state
        return null
    }

    return (
        <PagePadding>
            <Grid container spacing={3} justify="center">
            <Form initialValues={realInitialValues} onSubmit={handleSubmit}
                        render={({ handleSubmit, submitting }) => (
                            
                <Grid item xs={12} lg={8} xl={6}>
                    <form id="editRecipient" onSubmit={handleSubmit}>
                    <Grid container spacing={1} justify="space-between" mb={3.5}>
                        <Grid item>
                            {editState === "IsViewing" && <Button variant='contained' size="small" color="success" onClick={handleGoBack}><Fa icon={["fas", "chevron-left"]} mr={1} />{t("Zurück")}</Button>}
                            {editState === "IsEditing" && <Button variant='contained' size="small" color="error" type="submit" onClick={handleCancel}><Fa icon={["fas", "times"]} mr={1} />{t("Abbrechen")} </Button>}
                        </Grid>
                        <Grid item>
                            {editState === "IsViewing" && <Button variant="contained" size='small' color="error" onClick={handleRemove} style={{marginRight: "16px"}}><Fa icon={["fas", "trash"]} mr={1} />{t("Kontakt löschen")}</Button>}
                            {editState === "IsViewing" && <Button variant='contained' size="small" color="success" onClick={handleEdit}><Fa icon={["fas", "pencil"]} mr={1} />{t("Bearbeiten")} </Button>}
                            {editState === "IsEditing" && <Button type="submit" variant='contained' size="small" color="success" isLoading={submitting}><Fa icon={["fas", "check"]} mr={1} />{t("Speichern")}</Button>}
                        </Grid>
                    </Grid>
                    {editState === "IsViewing" &&
                        <Block>
                            <BlockTitle>
                                <Typography variant="h2">{t("Systemrelevante Informationen")}</Typography>
                            </BlockTitle>
                            <BlockContent>
                                <Grid container direction='row' alignItems='center' mb={3.5} mt={2}>
                                    <Typography variant='h2'>{recipient?.email}</Typography>
                                    <Chip
                                        className={[classes.badge, recipient?.doi_state == "CONFIRMED" && recipient?.unsubscribed == false && recipient?.deactivated == false ? classes.active : classes.inactive].join(' ')}
                                        label={recipient?.doi_state == "CONFIRMED" || recipient?.unsubscribed || recipient?.deactivated ? `${t("AKTIV")}` : `${t("INAKTIV")}`}
                                        clickable={false}
                                        size="small"
                                    />
                                </Grid>
                                <Grid container direction='row' spacing={2} mb={2}>
                                    <Grid item md={6}>
                                        <table className={classes.table}>
                                            <tbody>
                                                <tr><td className={classes.tableLabel}> {t("Name")}: </td><td> {recipient?.first_name} {recipient?.last_name}</td></tr>
                                                <tr><td className={classes.tableLabel}> {t("Quelle")}: </td><td> not supported yet </td></tr>
                                                <tr><td className={classes.tableLabel}> {t("ID")}: </td><td> {recipient?.id} </td></tr>
                                            </tbody>
                                        </table>
                                    </Grid>
                                    <Grid item md={6}>
                                        <table className={classes.table}>
                                            <tbody>
                                                <tr><td className={classes.tableLabel}> {t("DOI-Status")}: </td><td> {recipient?.doi_state}</td></tr>
                                                <tr><td className={classes.tableLabel}> {t("Abgemeldet")}: </td><td> {recipient?.unsubscribed ? t("Ja") : t("Nein")}</td></tr>
                                                <tr><td className={classes.tableLabel}> {t("Deaktiviert")}: </td><td> {recipient?.deactivated ? t("Ja") : t("Nein")} </td></tr>
                                                {console.log(recipient)}
                                            </tbody>
                                        </table>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid container direction='row' mt={3.5} mb={3}>
                                    <Grid item>
                                        <Fa icon={["fal", "tags"]} mr={1}/><Typography variant="h3" style={{display: "inline-block"}}>{t("Tags")}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.wrapper} direction="row" wrap='nowrap' justify='space-between' spacing={2}>
                                    <Grid container spacing={1} item style={{flexGrow: 1}}>
                                        {recipientTags.length > 0 ?
                                            recipientTags.map((tag, idx) =>
                                                <Grid item>
                                                    <RecipientTag tag={tag} key={idx} onRemove={() => handleTagRemove(tag)} />
                                                </Grid>
                                            )
                                        : <Grid item>{t("Dieser Kontakt hat aktuell keine Tags.")}</Grid>
                                        }
                                    </Grid>
                                    <Grid container item style={{width: "55px", height: "50px", position: "relative", padding: "0px"}}>
                                        <Button tooltip={t("Tag hinzufügen")} variant='contained' size="small" color="success" onClick={handleAddTag} style={{minWidth: "auto", position: "absolute", right: "0px", top: "0px", margin: "8px"}}><Fa icon={["far", "plus"]} /></Button>
                                    </Grid>
                                </Grid>
                            </BlockContent>
                        </Block>
                    }
                    
                        <Grid container direction="column" item>
                            <Block>
                                <BlockTitle>
                                    <Grid container alignItems='center' justify='space-between'>
                                        <Typography variant="h2">{t("Kontaktdetails")}</Typography>{editState === "IsViewing" && <Button variant='contained' size="small" color="success" onClick={handleEdit}><Fa icon={["fas", "pencil"]} mr={1} />{t("Bearbeiten")} </Button>}
                                    </Grid>
                                </BlockTitle>
                                <BlockContent>
                                    <Grid container spacing={2}>
                                        {(Object.keys(recipient || {}) as Array<keyof typeof recipient>).filter(e => !["id", "created_at"].includes(e)).map(key => (
                                            <Grid item key={key} md={6} sm={6} xs={12}>
                                                <FormikInput
                                                    type={recipientInfo.schema_info[key].type === "set" ? "enum" : recipientInfo.schema_info[key].type}
                                                    name={`${key}`}
                                                    variant="outlined"
                                                    label={t(`/fields:${key}`)}
                                                    disabled={editState === "IsViewing" ? true : false}
                                                    multiple={recipientInfo.schema_info[key].type === "set" ? true : false}
                                                    typeParams={recipientInfo.schema_info[key].type_params ? recipientInfo.schema_info[key].type_params : recipientInfo.schema_info[key].enum_values ? recipientInfo.schema_info[key].enum_values : null}
                                                    style={{margin: "0px"}}
                                                    labelProps={{
                                                        variant: "outlined"
                                                    }}
                                                    formControlProps={{margin: "0"}}
                                                />
                                                
                                            </Grid>
                                        ))}
                                    </Grid>
                                </BlockContent>
                            </Block>
                        </Grid>
                    {editState === "IsViewing" &&
                        <Grid container direction="column" item>
                            <Block>
                                <BlockTitle>
                                    <Typography variant="h2">{t("Eventaufzeichnung") + " / " + t("Ereignisprotokoll")}</Typography>
                                </BlockTitle>
                                <BlockContent>
                                        {recipientEvents.length > 0 ?
                                            recipientEvents.map((event, idx) =>
                                                <Grid container key={idx} spacing={2} direction="column">
                                                    <RecipientEvent event={event} />
                                                </Grid>)
                                            : <span>{t("Für diesen Nutzer liegen aktuell noch keine Ereignisse vor")}</span>}
                                </BlockContent>
                            </Block>
                        </Grid>
                    }
                    </form>
                </Grid>
                )}
            />
            </Grid>
        </PagePadding>
    )
}
export default Recipient