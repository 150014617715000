import React from "react"
import { Grid, Paper} from "@material-ui/core"
import { useChatState } from "../../ChatContext"
import FormikInput from "components/formik/FormikInput"
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useTranslation } from "react-i18next"

const AnswerNodeOptions = ({ node, classes }: any) => {
    const { state } = useChatState()
    const { t } = useTranslation()

    return (
        <Grid container className={classes.settingsContainer} style={{alignItems: "center", borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px" }}>
            <Grid item style={{width: "100%"}}>
                <Paper elevation={2} className={classes.settingsGroupPaper}>
                    <Grid container item direction="column" justify="center" alignItems="stretch" className={classes.settingsGroup}>
                        <Grid item style={{width: "100%"}}>
                            <label className="FormikFormLabel">{t("Text")}</label>
                            <FormikInput
                                type="entityText"
                                name="answer"
                                label="Antwort"
                                // onChange={(e: any) => handleChange(e.target.value)}
                                // defaultValue={node.data.answer}
                                    variant="outlined"
                                typeParams={state.fields}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default AnswerNodeOptions