export const template = {
  id: "template_001",
  name: "Bewertung mit Gutschein nach Anmeldung",
  template: [
    {
        "id": "1",
        "data": {
            "name": "start",
            "node": {
                "id": "1",
                "type": "start"
            },
            "text": {
                "blocks": [
                    {
                        "key": "6htph",
                        "data": {},
                        "text": "Hallo, ich bin Pixi, dein persönlicher Assistent!",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    },
                    {
                        "key": "5b0b3",
                        "data": {},
                        "text": "Ich generiere gerade einen Geschenk-Code für dich.😊",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    },
                    {
                        "key": "5pql0",
                        "data": {},
                        "text": "",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    },
                    {
                        "key": "9r67k",
                        "data": {},
                        "text": "Kann ich dir in der Zwischenzeit ein paar Fragen stellen?",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    }
                ],
                "entityMap": {}
            },
            "buttonTexts": [
                {
                    "textOne": {
                        "blocks": [
                            {
                                "key": "1cpe1",
                                "data": {},
                                "text": "Klar, schieß los!",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    },
                    "textTwo": {
                        "blocks": [
                            {
                                "key": "eubm7",
                                "data": {},
                                "text": "Nein, lieber nicht.",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    }
                }
            ],
            "withButtons": true
        },
        "type": "start",
        "position": {
            "x": 75,
            "y": 101
        }
    },
    {
        "id": "e1S-1669137311900N",
        "style": {
            "stroke": "#ffffff"
        },
        "source": "1",
        "target": "1669137311900",
        "animated": true,
        "sourceHandle": "S",
        "targetHandle": "N"
    },
    {
        "id": "1669137311900",
        "data": {
            "name": "Namen abfragen",
            "node": {
                "id": "1669137311900",
                "type": "dataCollection"
            },
            "delay": 2,
            "questions": [
                {
                    "delay": 2,
                    "field": "first_name",
                    "question": {
                        "blocks": [
                            {
                                "key": "c9fb2",
                                "data": {},
                                "text": "Klasse, das freut mich. Wie darf ich dich ansprechen?",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    },
                    "validateWith": "text"
                }
            ]
        },
        "type": "dataCollection",
        "position": {
            "x": 275,
            "y": 101
        }
    },
    {
        "id": "e1669137311900S-1669137520153N",
        "style": {
            "stroke": "#ffffff"
        },
        "source": "1669137311900",
        "target": "1669137520153",
        "animated": true,
        "sourceHandle": "S",
        "targetHandle": "N"
    },
    {
        "id": "1669137520153",
        "data": {
            "name": "Besuch",
            "node": {
                "id": "1669137520153",
                "type": "splitQuestion"
            },
            "delay": 2,
            "answers": [
                {
                    "answer": {
                        "blocks": [
                            {
                                "key": "3nr6d",
                                "data": {},
                                "text": "Ich war schon mal da.",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    },
                    "answerKey": "1669137520154"
                },
                {
                    "answer": {
                        "blocks": [
                            {
                                "key": "77oln",
                                "data": {},
                                "text": "Mein ertes Mal",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    },
                    "answerKey": "1669137586016"
                }
            ],
            "question": {
                "blocks": [
                    {
                        "key": "ab09c",
                        "data": {},
                        "text": "Freut mich dich kennen zulernen, ${first_name}. Warst du schon mal bei uns oder ist das dein erstes Mal?",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [
                            {
                                "key": 0,
                                "length": 13,
                                "offset": 33
                            }
                        ],
                        "inlineStyleRanges": []
                    }
                ],
                "entityMap": {
                    "0": {
                        "data": {
                            "placeholder": "first_name"
                        },
                        "type": "PLACEHOLDER",
                        "mutability": "IMMUTABLE"
                    }
                }
            }
        },
        "type": "splitQuestion",
        "position": {
            "x": 475,
            "y": 101
        }
    },
    {
        "id": "1669137520154",
        "data": {
            "name": "splitAnswer1",
            "node": {
                "id": "1669137520154",
                "type": "splitAnswer"
            },
            "delay": 2,
            "answer": {
                "blocks": [
                    {
                        "key": "3nr6d",
                        "data": {},
                        "text": "Ich war schon mal da.",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    }
                ],
                "entityMap": {}
            }
        },
        "type": "splitAnswer",
        "position": {
            "x": 675,
            "y": 59
        }
    },
    {
        "id": "e1669137520153S-1669137520154N",
        "style": {
            "stroke": "#ffffff"
        },
        "source": "1669137520153",
        "target": "1669137520154",
        "animated": true,
        "sourceHandle": "S",
        "targetHandle": "N"
    },
    {
        "id": "1669137586016",
        "data": {
            "name": "splitAnswer2",
            "node": {
                "id": "1669137586016",
                "type": "splitAnswer"
            },
            "delay": 2,
            "answer": {
                "blocks": [
                    {
                        "key": "77oln",
                        "data": {},
                        "text": "Mein ertes Mal",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    }
                ],
                "entityMap": {}
            }
        },
        "type": "splitAnswer",
        "position": {
            "x": 675,
            "y": 143
        }
    },
    {
        "id": "e1669137520153S-1669137586016N",
        "style": {
            "stroke": "#ffffff"
        },
        "source": "1669137520153",
        "target": "1669137586016",
        "animated": true,
        "sourceHandle": "S",
        "targetHandle": "N"
    },
    {
        "id": "e1669137520154S-1669137656799N",
        "style": {
            "stroke": "#ffffff"
        },
        "source": "1669137520154",
        "target": "1669137656799",
        "animated": true,
        "sourceHandle": "S",
        "targetHandle": "N"
    },
    {
        "id": "e1669137656799S-1669137656799PN",
        "style": {
            "stroke": "#ffffff"
        },
        "source": "1669137656799",
        "target": "1669137656799P",
        "animated": true,
        "sourceHandle": "S",
        "targetHandle": "N"
    },
    {
        "id": "e1669137656799S-1669137656799UN",
        "style": {
            "stroke": "#ffffff"
        },
        "source": "1669137656799",
        "target": "1669137656799U",
        "animated": true,
        "sourceHandle": "S",
        "targetHandle": "N"
    },
    {
        "id": "1669137656799",
        "data": {
            "name": "Rating",
            "node": {
                "id": "1669137656799",
                "type": "feedback"
            },
            "text": {
                "blocks": [
                    {
                        "key": "bi8a1",
                        "data": {},
                        "text": "Sag uns bitte, mit wie vielen Sternen ⭐ du uns bewerten würdest.",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    },
                    {
                        "key": "9ag80",
                        "data": {},
                        "text": "",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    },
                    {
                        "key": "coiuk",
                        "data": {},
                        "text": "4 - 5 ⭐ = Wir freuen uns sehr, dass du zufrieden bist! :)",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    },
                    {
                        "key": "2otpv",
                        "data": {},
                        "text": "",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    },
                    {
                        "key": "edmgp",
                        "data": {},
                        "text": "1 - 3 ⭐ = Wir sind offen für Verbesserungsvorschläge.",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    }
                ],
                "entityMap": {}
            },
            "delay": 2,
            "split": 4
        },
        "type": "feedback",
        "position": {
            "x": 875,
            "y": 59
        }
    },
    {
        "id": "1669137656799P",
        "data": {
            "name": "Rating",
            "node": {
                "id": "1669137656799P",
                "type": "socialMedia"
            },
            "delay": 2,
            "textTop": {
                "blocks": [
                    {
                        "key": "2aemb",
                        "data": {},
                        "text": "Wow, das ist ja toll! Wir würden uns sehr freuen, wenn du uns auch auf folgenden Plattformen bewerten würdest, wenn du zufrieden bist. 😊",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    },
                    {
                        "key": "2vc0n",
                        "data": {},
                        "text": "",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    },
                    {
                        "key": "2vhqd",
                        "data": {},
                        "text": "Klicke dazu einfach auf einen der Buttons:",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    }
                ],
                "entityMap": {}
            },
            "textBottom": {
                "blocks": [
                    {
                        "key": "cb028",
                        "data": {},
                        "text": "Wenn du mit deiner Bewertung fertig bist, klicke unten auf „Fertig!“ Während du uns bewertest, bereite ich schon mal deinen Gutschein-Code vor. Ich melde mich in 3 Minuten wieder.",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    }
                ],
                "entityMap": {}
            },
            "socialMedias": [
                {
                    "url": "https://www.google.de",
                    "platform": "Google"
                },
                {
                    "url": "https://www.facebook.com",
                    "platform": "Facebook"
                }
            ]
        },
        "type": "socialMedia",
        "position": {
            "x": 1075,
            "y": 17
        }
    },
    {
        "id": "e1669137656799PS-1669137858948N",
        "style": {
            "stroke": "#ffffff"
        },
        "source": "1669137656799P",
        "target": "1669137858948",
        "animated": true,
        "sourceHandle": "S",
        "targetHandle": "N"
    },
    {
        "id": "e1669137858948S-1669138048226N",
        "style": {
            "stroke": "#ffffff"
        },
        "source": "1669137858948",
        "target": "1669138048226",
        "animated": true,
        "sourceHandle": "S",
        "targetHandle": "N"
    },
    {
        "id": "1669138048226",
        "data": {
            "name": "Ende Rating Portal",
            "node": {
                "id": "1669138048226",
                "type": "end"
            },
            "text": {
                "blocks": [
                    {
                        "key": "886m9",
                        "data": {},
                        "text": "Sehr gerne! Wir bedanken uns bei dir und wünschen dir noch einen schönen Aufenthalt. Viel Spaß mit dem Geschenk und komm doch bald wieder vorbei! 😊",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    }
                ],
                "entityMap": {}
            },
            "delay": 2
        },
        "type": "end",
        "position": {
            "x": 1475,
            "y": 17
        }
    },
    {
        "id": "e1669137656799US-1669138099342N",
        "style": {
            "stroke": "#ffffff"
        },
        "source": "1669137656799U",
        "target": "1669138099342",
        "animated": true,
        "sourceHandle": "S",
        "targetHandle": "N"
    },
    {
        "id": "1669137656799U",
        "data": {
            "name": "Rating",
            "node": {
                "id": "1669137656799U",
                "type": "userFeedback"
            },
            "text": {
                "blocks": [
                    {
                        "key": "12mpg",
                        "data": {},
                        "text": "Ohje, das ist nicht gut. Ich würde mich sehr freuen, wenn du mir mitteilen könntest, was dir nicht gefallen hat. Wir nehmen jedes Feedback ernst und versuchen uns stetig zu verbessern.",
                        "type": "unstyled",
                        "depth": 0,
                        "entityRanges": [],
                        "inlineStyleRanges": []
                    }
                ],
                "entityMap": {}
            },
            "delay": 2
        },
        "type": "userFeedback",
        "position": {
            "x": 1075,
            "y": 101
        }
    },
    {
        "id": "e1669137586016S-1669138812863N",
        "style": {
            "stroke": "#ffffff"
        },
        "source": "1669137586016",
        "target": "1669138812863",
        "animated": true,
        "sourceHandle": "S",
        "targetHandle": "N"
    },
    {
        "id": "1669138812863",
        "data": {
            "name": "Zur Bewertung",
            "node": {
                "id": "1669138812863",
                "type": "goTo"
            },
            "delay": 2,
            "continueWith": {
                "id": "1669137656799",
                "data": {
                    "name": "Rating",
                    "node": {
                        "id": "1669137656799",
                        "type": "feedback"
                    },
                    "text": {
                        "_immutable": {
                            "treeMap": {
                                "2otpv": [
                                    {
                                        "end": 0,
                                        "start": 0,
                                        "leaves": [
                                            {
                                                "end": 0,
                                                "start": 0
                                            }
                                        ],
                                        "decoratorKey": null
                                    }
                                ],
                                "9ag80": [
                                    {
                                        "end": 0,
                                        "start": 0,
                                        "leaves": [
                                            {
                                                "end": 0,
                                                "start": 0
                                            }
                                        ],
                                        "decoratorKey": null
                                    }
                                ],
                                "bi8a1": [
                                    {
                                        "end": 64,
                                        "start": 0,
                                        "leaves": [
                                            {
                                                "end": 64,
                                                "start": 0
                                            }
                                        ],
                                        "decoratorKey": null
                                    }
                                ],
                                "coiuk": [
                                    {
                                        "end": 57,
                                        "start": 0,
                                        "leaves": [
                                            {
                                                "end": 57,
                                                "start": 0
                                            }
                                        ],
                                        "decoratorKey": null
                                    }
                                ],
                                "edmgp": [
                                    {
                                        "end": 53,
                                        "start": 0,
                                        "leaves": [
                                            {
                                                "end": 53,
                                                "start": 0
                                            }
                                        ],
                                        "decoratorKey": null
                                    }
                                ]
                            },
                            "allowUndo": true,
                            "decorator": {
                                "_decorators": [
                                    {}
                                ]
                            },
                            "redoStack": [],
                            "selection": {
                                "focusKey": "bi8a1",
                                "hasFocus": false,
                                "anchorKey": "bi8a1",
                                "isBackward": false,
                                "focusOffset": 0,
                                "anchorOffset": 0
                            },
                            "undoStack": [],
                            "directionMap": {
                                "2otpv": "LTR",
                                "9ag80": "LTR",
                                "bi8a1": "LTR",
                                "coiuk": "LTR",
                                "edmgp": "LTR"
                            },
                            "currentContent": {
                                "blockMap": {
                                    "2otpv": {
                                        "key": "2otpv",
                                        "data": {},
                                        "text": "",
                                        "type": "unstyled",
                                        "depth": 0,
                                        "characterList": []
                                    },
                                    "9ag80": {
                                        "key": "9ag80",
                                        "data": {},
                                        "text": "",
                                        "type": "unstyled",
                                        "depth": 0,
                                        "characterList": []
                                    },
                                    "bi8a1": {
                                        "key": "bi8a1",
                                        "data": {},
                                        "text": "Sag uns bitte, mit wie vielen Sternen ⭐ du uns bewerten würdest.",
                                        "type": "unstyled",
                                        "depth": 0,
                                        "characterList": [
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            }
                                        ]
                                    },
                                    "coiuk": {
                                        "key": "coiuk",
                                        "data": {},
                                        "text": "4 - 5 ⭐ = Wir freuen uns sehr, dass du zufrieden bist! :)",
                                        "type": "unstyled",
                                        "depth": 0,
                                        "characterList": [
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            }
                                        ]
                                    },
                                    "edmgp": {
                                        "key": "edmgp",
                                        "data": {},
                                        "text": "1 - 3 ⭐ = Wir sind offen für Verbesserungsvorschläge.",
                                        "type": "unstyled",
                                        "depth": 0,
                                        "characterList": [
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            },
                                            {
                                                "style": [],
                                                "entity": null
                                            }
                                        ]
                                    }
                                },
                                "entityMap": {},
                                "selectionAfter": {
                                    "focusKey": "bi8a1",
                                    "hasFocus": false,
                                    "anchorKey": "bi8a1",
                                    "isBackward": false,
                                    "focusOffset": 0,
                                    "anchorOffset": 0
                                },
                                "selectionBefore": {
                                    "focusKey": "bi8a1",
                                    "hasFocus": false,
                                    "anchorKey": "bi8a1",
                                    "isBackward": false,
                                    "focusOffset": 0,
                                    "anchorOffset": 0
                                }
                            },
                            "forceSelection": false,
                            "lastChangeType": null,
                            "inCompositionMode": false,
                            "inlineStyleOverride": null,
                            "nativelyRenderedContent": null
                        }
                    },
                    "delay": 2,
                    "split": 4
                },
                "type": "feedback",
                "position": {
                    "x": 875,
                    "y": 59
                }
            }
        },
        "type": "goTo",
        "position": {
            "x": 875,
            "y": 143
        }
    },
    {
        "id": "1669138099342",
        "data": {
            "name": "Gutschein-Code nach Feedback",
            "node": {
                "id": "1669138099342",
                "type": "botResponse"
            },
            "delay": 2,
            "responses": [
                {
                    "text": {
                        "blocks": [
                            {
                                "key": "eh013",
                                "data": {},
                                "text": "Vielen Dank für deine Meinung! Dein Feedback bedeutet uns sehr viel, deshalb haben wir eine Überraschung für dich. Klicke auf „Geschenk-Code zeigen!“, um deinen persönlichen Code anzuzeigen. Lass dich überraschen.",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    },
                    "type": "textWithButton",
                    "delay": 2,
                    "buttonText": {
                        "blocks": [
                            {
                                "key": "2gvo5",
                                "data": {},
                                "text": "Geschenk-Code zeigen!",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    }
                },
                {
                    "text": {
                        "blocks": [
                            {
                                "key": "4rbp0",
                                "data": {},
                                "text": "Dein persönlicher Geschenk-Code lautet:",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    },
                    "type": "coupon",
                    "delay": 2,
                    "coupon": "CP303",
                    "asBarcode": true
                },
                {
                    "text": {
                        "blocks": [
                            {
                                "key": "eo1e",
                                "data": {},
                                "text": "Zeige ihn einer Mitarbeiterin oder einem Mitarbeiter an der Kasse und du erhältst sofort dein Geschenk. 😊",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            },
                            {
                                "key": "30q79",
                                "data": {},
                                "text": "",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            },
                            {
                                "key": "ea0dm",
                                "data": {},
                                "text": "Damit der Code nicht verloren geht, schicke ich ihn dir auch nochmal per E-Mail zu.",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    },
                    "type": "textWithButton",
                    "delay": 2,
                    "buttonText": {
                        "blocks": [
                            {
                                "key": "8kksq",
                                "data": {},
                                "text": "Danke!",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    }
                }
            ]
        },
        "type": "botResponse",
        "position": {
            "x": 1275,
            "y": 101
        }
    },
    {
        "id": "e1669138099342S-1669139213458N",
        "style": {
            "stroke": "#ffffff"
        },
        "source": "1669138099342",
        "target": "1669139213458",
        "animated": true,
        "sourceHandle": "S",
        "targetHandle": "N"
    },
    {
        "id": "1669139213458",
        "data": {
            "name": "Zum Ende springen",
            "node": {
                "id": "1669139213458",
                "type": "goTo"
            },
            "delay": 2,
            "continueWith": null
        },
        "type": "goTo",
        "position": {
            "x": 1475,
            "y": 101
        }
    },
    {
        "id": "1669137858948",
        "data": {
            "name": "Geschenk-Code",
            "node": {
                "id": "1669137858948",
                "type": "botResponse"
            },
            "delay": 2,
            "responses": [
                {
                    "text": {
                        "blocks": [
                            {
                                "key": "e3erf",
                                "data": {},
                                "text": "Super, vielen Dank 🤩! Dein Feedback bedeutet uns sehr viel, deshalb haben wir eine Überraschung für dich. Klicke auf „Geschenk-Code zeigen!“, um deinen persönlichen Code anzuzeigen. Lass dich überraschen.",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    },
                    "type": "textWithButton",
                    "delay": 2,
                    "buttonText": {
                        "blocks": [
                            {
                                "key": "ftn8q",
                                "data": {},
                                "text": "Geschenk-Code zeigen",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    }
                },
                {
                    "text": {
                        "blocks": [
                            {
                                "key": "1mibp",
                                "data": {},
                                "text": "Dein persönlicher Geschenk-Code lautet:",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    },
                    "type": "coupon",
                    "delay": 2,
                    "coupon": "CP303",
                    "asBarcode": true
                },
                {
                    "text": {
                        "blocks": [
                            {
                                "key": "dmkb2",
                                "data": {},
                                "text": "Zeige ihn einer Mitarbeiterin oder einem Mitarbeiter an der Kasse und du erhältst sofort dein Geschenk. 😊",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            },
                            {
                                "key": "3u73g",
                                "data": {},
                                "text": "",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            },
                            {
                                "key": "bojjh",
                                "data": {},
                                "text": "Damit der Code nicht verloren geht, schicke ich ihn dir auch nochmal per E-Mail zu.",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    },
                    "type": "textWithButton",
                    "delay": 2,
                    "buttonText": {
                        "blocks": [
                            {
                                "key": "emg9r",
                                "data": {},
                                "text": "Ok, danke!",
                                "type": "unstyled",
                                "depth": 0,
                                "entityRanges": [],
                                "inlineStyleRanges": []
                            }
                        ],
                        "entityMap": {}
                    }
                }
            ]
        },
        "type": "botResponse",
        "position": {
            "x": 1275,
            "y": 17
        }
    }
]
}