import React, { useEffect, useState } from 'react'
import { CancelButton, IState } from '../WebformWizard'
import { makeStyles } from '@material-ui/core'
import { NavigationProps} from '../WebformWizard'
import {Form} from "react-final-form"
import { IDomain } from 'models/senderDomain'
import FormikInput from 'components/formik/FormikInput'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button/Button'
import { Typography, Grid, Fa } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { useLocation } from "react-router-dom"
import { Theme } from "@material-ui/core"
import webforms from 'models/webform'
import InputAdornment from '@material-ui/core/InputAdornment';
import useSession from 'hooks/useSession'
import LoadingAnimation from 'components/LoadingAnimation'
import imprintApi from "models/imprint"
import { useSidebar } from 'hooks/useSidebar'


type Props = {
	state: IState
	navigationProps: NavigationProps
	setBasicInfo: React.Dispatch<React.SetStateAction<any>>
	setState: React.Dispatch<React.SetStateAction<any>>
}

/** Todo
 * Bie Registrierung
 * Domain abfragen, subdomain von unserer anbieten
 */

export type FormValues = {
	name: string
	useDoiChain: boolean
	senderName: string
	senderEmailLocalPart: string
	senderEmailDomain: IDomain | null
}

export const useStyles = makeStyles<Theme, {setup: boolean}>(theme => ({
	
    navigation: {
        backgroundColor: "transparent",
        color: "transparent",
        padding: `0px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
    },
    root: {
        height: "100%",
        width: "100%",
        backgroundColor: ({ setup }) => setup ? "#1a1e2d" : theme.palette.companyDark.light,
        backgroundImage: ({ setup }) => setup ? "url(/assets/images/dark-bg.jpg)" : "none",
        backgroundSize: ({ setup }) => setup ? "cover" : "none",
        backgroundRepeat: ({ setup })  => setup ? "no-repeat" : "none",
        backgroundPosition: ({ setup }) => setup ? "top center" : "none",
        overflow: "auto",
        //padding: theme.spacing(1),
        boxSizing: "border-box",
        "& p": {
            fontWeight: "300",
            "& strong": {
                fontWeight: "500"
            }
        }
    },
    form: {
        width: "100%",
        margin: "auto",
        maxWidth: "600px",
        // padding: theme.spacing(4),
        backgroundColor: theme.palette.lightGrey.main,
        borderRadius: theme.spacing(0.5),
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        boxSizing: "border-box",
        overflow: "hidden"
    },
    formHeader: {
        padding: theme.spacing(4),
        //backgroundColor: "#83ac1b2b",
        backgroundColor: "#E6ECEF",
    },
    formContent: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    formFooter: {
     //   paddingLeft: theme.spacing(4),
     //   paddingRight: theme.spacing(4),
    },
    formNote: {
        width: "100%",
        maxWidth: "600px",
        color: "#ffffff75",
        paddingTop: theme.spacing(2)
    },
    headerDescription: {
        padding: theme.spacing(2),
        fontSize: "0.8rem",
        //backgroundColor: "#00000015",
        backgroundColor: "#ffffff90",
        borderRadius: "5px",
        position: "relative",
        '&:after': {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            width: 0,
            height: 0,
            border: "18px solid transparent",
            //borderRightColor: "#00000015",
            borderRightColor: "#ffffff90",
            borderLeft: 0,
            borderBottom: 0,
            marginTop: "-9px",
            marginLeft: "-18px",
        },
    },
    success: {
        color: theme.palette.success.main
    },
    error: {
        color: theme.palette.error.main
    },
    pixiSpeech: {
     //   color: "white"
    },
    requiredNotice: {
     //   color: "#95a0a6",
        fontSize: "0.9em"
    },
    disabledInput: {
        "& .Mui-disabled fieldset": {
     //       borderColor: "rgba(255, 255, 255, 0.25) !important"
        }
    },
    table: {
        "& td": {
            padding: theme.spacing(0.5)
        }
    },
    stepsReviewRow: {
        margin: "0 -10px",
        padding: "0 10px",
        transition: "0.3s all ease",
        "&:hover": {
            backgroundColor: "#E6ECEF",
        }
    }
}))

const FirstStep = ({ state, navigationProps, setBasicInfo, setState }: Props) => {
    const { t } = useTranslation()
    const location = useLocation()
    const setup = location.state?.setup || false
	const classes = useStyles({setup})
    const [webformNamesList, setWebformNamesList] = useState<any>([])
    const [isUniqueName, setIsUniqueName] = useState(true)
    const {user} = useSession()
    const [imprint, setImprint] = useState<any | null>(null)
    const { fixedClose } = useSidebar()
    if (setup){
        fixedClose()
    }

    useEffect(() => {
        //TODO refactor with filterobject
        const fetchWebformList = async () => {
            const list = await webforms.getList()
            const namesList: any = []
            list.data.forEach( webform => {
                namesList.push(webform.name)
            })
            setWebformNamesList(namesList)
        }
        
        const fetchImprint = async () => {
            const response = await imprintApi.getFirst()
            setImprint(response)
        }

        fetchWebformList(); fetchImprint()
    },[])

    const handleChangeName = (e: any) => {
        if (webformNamesList.find((webformName: any) => webformName === e.target.value)) {
            setIsUniqueName(false)
        } else {
            setIsUniqueName(true)
        }
    }

	const handleSubmit = async (values: FormValues) => {
		setState({...state, activeStep: state.activeStep +1})
		setBasicInfo(values)
	}

    const [hasNameFocus, setNameFocus] = useState(false);
    const [hasSenderNameFocus, setSenderNameFocus] = useState(false);

    if(!imprint) {
        return <LoadingAnimation/>
    }

    const initialValues: FormValues = {
        name: "",
        useDoiChain: false,
        senderName: `${user?.first_name} ${user?.last_name} von ${imprint.company_name}`,
        senderEmailLocalPart: "",
        senderEmailDomain: null
    }

	return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}
            render={({handleSubmit, submitting}) => ( 
                <Grid container item direction="column" className={classes.root}>
                    <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" justify="flex-start">
                                <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                    <Grid item sm={4}>
                                        <div style={{ maxWidth: "150px" }}>
                                            <Pixi size="full" pose="waiting"></Pixi>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <div className={classes.headerDescription} >
                                        <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                            <Typography variant="h5" className={classes.pixiSpeech}>
                                                {t("Bitte definiere zunächst jeweils einen Namen für deine Anmeldestrecke und einen Namen von dem die Bestätigungsmail versendet werden soll. Wähle anschließend die E-Mail-Adresse von der die Anmeldebestätigung versendet werden soll.")}
                                            </Typography>
                                        </Typist>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item container className={classes.formContent}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" mb={1} className={classes.pixiSpeech}>{t("Formulareinstellungen")}</Typography>
                                        <FormikInput
                                            name="name"
                                            variant="outlined"
                                            label={t("Name der Anmeldestrecke")}
                                            type="text"
                                            autoComplete="off"
                                            required
                                            onChange={handleChangeName}
                                            error={!isUniqueName}
                                            helperText={!isUniqueName ? t("Dieser Name ist bereits vergeben") : null}
                                            InputProps={
                                                {
                                                    onFocus:(() => setNameFocus(true)),
                                                    onBlur:(() => setNameFocus(false)),
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            { hasNameFocus ? <Fa icon={["fas", "memo-pad"]}/> : <Fa icon={["fal", "memo-pad"]}/>}
                                                        </InputAdornment>
                                                    ),
                                                }
                                            }
                                        />
                                    </Grid>
                                    {/* <Grid item sm={12}>
                                        <FormikInput
                                            name="useDoiChain"
                                            type="checkbox"
                                            label={t("DoiChain Verifizierung nutzen")}
                                            color="white"
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} mt={4}>
                                        <Typography variant="h4" mb={1} className={classes.pixiSpeech}>{t("Versandeinstellungen")}</Typography>
                                        <FormikInput
                                            name="senderName"
                                            required
                                            variant="outlined"
                                            type="text"
                                            label={t("Name des E-Mail-Absenders")}
                                            InputProps={
                                                {
                                                    onFocus:(() => setSenderNameFocus(true)),
                                                    onBlur:(() => setSenderNameFocus(false)),
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            { hasSenderNameFocus ? <Fa icon={["fas", "paper-plane"]}/> : <Fa icon={["fal", "paper-plane"]}/>}
                                                        </InputAdornment>
                                                    ),
                                                }
                                            }
                                        />
                                        <Grid item xs={12}>
                                            <FormikInput
                                                type="senderEmail"
                                                required
                                                variant="outlined"
                                                localPartProps={{
                                                    name:"senderEmailLocalPart",
                                                    label: t("E-Mail")
                                                }}
                                                domainPartProps={{
                                                    name: "senderEmailDomain",
                                                    label: t("Domain")
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                                <Grid item sm={12} container className={classes.navigation} justify="space-between" alignItems="center" >
                                   {/* <Navigation {...navigationProps}/> */}
                                    <CancelButton destination='webforms'/>
                                    <Button variant="contained" color="success" type="submit" disabled={!isUniqueName} isLoading={submitting} size="small">
                                        {t("Weiter")}
                                        <Fa icon={["fas", "chevron-right"]} ml={1}></Fa>
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            
            )}
            
        />
	)
}

export default FirstStep