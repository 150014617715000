import React, { useEffect, useState, useContext } from 'react'
import { CircularProgress, IconButton, makeStyles, TableCell, Typography } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import CSS from 'csstype'
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnOptions, MUIDataTableMeta, MUIDataTableOptions, MUIDataTableProps, MUIDataTableTextLabelsPagination } from 'mui-datatables'
import { useTranslation } from 'react-i18next'
import { Info, CrudModel, BaseModel } from '../../models/builder'
import DataCell from './DataCell'
import EditableTableCellContent from './EditableTableCellContent'
import useViewColumns from '../../hooks/useViewColumns'
import SearchTextField from './SearchTextField'
import { MuiPickersContext } from '@material-ui/pickers'
import { tableFilterToFilterObject } from './util'
import { VerboseFilterObject } from '../../types/filter.object'
import { secondaryHue } from '../../theme'
import Pagination from './Pagination'
import Button from "components/Button/Button"
import { Fa, FaButton } from "styled/muiComponents"
import { theme } from "theme"
import { useDialog } from "hooks/useDialog"

import { fromCornerstone } from 'constants/filters'

import ConfirmRemove, { Props as ConfirmRemoveProps, Response as ConfirmRemoveResponse} from "dialogs/ConfirmRemove"

import textLabels from './textLabels'
import useQueryParams from 'hooks/useQueryParams'

const normalize = <T extends { id: number }>(data: T[]): Record<number, T> => {
  return data.reduce((res, cur) => ({ ...res, [cur.id]: cur }), {})
}

export type CustomMUIDataTableColumnDef = {
  options?: {
    editable?: boolean
    align?: "left" | "right"
  } & MUIDataTableColumnOptions,
  label?: string,
  name: string
}

export type CustomDownloadParams = {
  page: number
  pageSize: number
  sort: string
  searchText: string
  filterObject: VerboseFilterObject
  columns: any[]
}

export type CustomMUIDataTableOptions = MUIDataTableOptions & {
  customDownload?: (args: CustomDownloadParams) => void
}

export type Props<T extends { id: number }> = {
  translateLabels?: boolean,
  modelApi: CrudModel<T> & BaseModel,
  customActionsRender?: (item: T, reload: () => void) => React.ReactNode | React.ReactNode[],
  customToolbarActions?: React.ReactNode[],
  columns: CustomMUIDataTableColumnDef[]
  options: CustomMUIDataTableOptions
} & Omit<MUIDataTableProps, "data" | "columns" | "options">

const stickyStyle: CSS.Properties = {
  //position: "sticky",
  //right: 0,
  //background: "white",
  //zIndex: 101
}

const useStyles = makeStyles(theme => ({
  loadingTr: {
    "& td": {
      borderBottom: "1px solid lightgray",
      height: "30px"
    }
  },
  root: {
    cursor: "context-menu",
    "& .MuiTableCell-root": {
      padding: `0 ${theme.spacing(1)}px`,
      height: "50px",
      "&:last-child": {
        padding: `0 ${theme.spacing(3)}px 0 ${theme.spacing(1)}px`,
      }
    },
    '@media screen and (max-width: 960px)': {
      "& .MuiTableCell-root": {
        padding: theme.spacing(1),
        height: "auto"
      }
    },
    "& .MuiCheckbox-root": {
      padding: theme.spacing(0.5)
    },
    "& td:not(.MuiTableCell-paddingCheckbox)": {
      position: "relative"
    },
    "& .MuiTableCell-paddingCheckbox": {
  //    background: "white"
      paddingLeft: `${theme.spacing(2.25)}px`
    },
    "& .MuiTableRow-root:hover": {
      background: theme.palette.lightGrey.main
    },
    "& .MuiTableCell-footer": {
      padding: 0
    },
    "& .MuiTableRow-root:hover .MuiTableCell-head, .MuiTableRow-head:hover, .MuiTableRow-head, .MuiTableRow-footer, .MuiTableRow-footer:hover": {
      background: "transparent"
    },
    "& .MuiTableRow-root.Mui-selected": {
      background: secondaryHue[50]
    },
    "& .MuiTableRow-root.Mui-selected .iEBWrapper": {
      background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, ${theme.palette.lightGrey.main} 40%)`,
    },
    "& .MuiTableRow-root.Mui-selected:hover": {
      background: secondaryHue[100]
    },
    "& .MuiTableRow-root.Mui-selected:hover .iEBWrapper": {
      background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, ${secondaryHue[100]} 40%)`,
    },
  },
  actionButtonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: "10px"
  },
  actionHead: {
    ...stickyStyle,
    top: 0,
    zIndex: 102,
    textAlign: "right",
    right: 0
  },
  headerAlignRight: {
    textAlign: "right",
    "& span": {
      display: "flex",
      justifyContent: "flex-end"
    }
  },
  cellAlignRight: {
    textAlign: "right",
    "& > div": {
      justifyContent: "flex-end"
    }
  }

}))

interface IState<T> {
  page: number
  pageSize: number
  normalizedData: Record<number, T>
  data: T[]
  itemCount: number
  isLoading: boolean
  modelInfo: Info | null
  searchText: string
  filterList: string[][]
  rowsSelected: { index: number, dataIndex: number }[]
  sort: string
}

const initialTableState: IState<any> = {
  page: 0,
  pageSize: 10,
  normalizedData: {},
  data: [],
  itemCount: 0,
  isLoading: true,
  modelInfo: null,
  searchText: "",
  filterList: [],
  rowsSelected: [],
  sort: ""
}

const filterOptionNames = (name: keyof Info["schema_info"], schema: Info["schema_info"]): string[] => {
  switch (schema[name].type) {
    case "set":
    case "enum": return schema[name].type_params
    case "boolean": return ["true", "false"]
    default:
      console.error("filterOption.names could not be created from DB schema, please provide your own using column.options.filterOptions.names")
      return []
  }
}

type IEncodedQueryParams = {
  page: string
  pageSize: string
  searchText: string
  sort: string
  filterList: string
}

type IDecodedQueryParams = Pick<IState<unknown>, "page" | "pageSize" | "searchText" | "sort" | "filterList">

const decodeQueryParams = (params: IEncodedQueryParams) => ({
    page: typeof params?.page === "string" ? parseInt(params?.page, 10) : initialTableState.page,
    pageSize: typeof params?.pageSize === "string" ? parseInt(params?.pageSize, 10) : initialTableState.pageSize,
    searchText: typeof params?.searchText === "string" ? params?.searchText : initialTableState.searchText,
    sort: typeof params?.sort === "string" ? params?.sort : initialTableState.sort,
    filterList: typeof params?.filterList === "string" ? JSON.parse(params?.filterList): initialTableState.filterList
})

const encodeQueryParams = (state: IDecodedQueryParams) => ({
    page: state.page.toString(),
    pageSize: state.pageSize.toString(),
    searchText: state.searchText,
    sort: state.sort,
    filterList: JSON.stringify(state.filterList)
})

const AsyncMUIDataTable = <T extends { id: number }>({ translateLabels = false, modelApi, customToolbarActions = [], customActionsRender, ...props }: Props<T>) => {

  const { t } = useTranslation()
  const classes = useStyles()
  const { dialog } = useDialog()
  const [viewColumns, setViewColumns] = useViewColumns(modelApi.name)
  const pickersContext = useContext(MuiPickersContext)
  const locale = pickersContext ? pickersContext.locale : "de"
  const [queryParams, setQueryParams] = useQueryParams<IEncodedQueryParams, IDecodedQueryParams>(encodeQueryParams, decodeQueryParams)

  const [tableState, setTableState] = useState<IState<T>>({
    ...initialTableState,
    ...queryParams
  })

  const handleRemove = (items: any, model: any, setSelectedRows: any) => {
    dialog.show<ConfirmRemoveProps>({
        component: ConfirmRemove,
        props: {
            items: items,
            model: model
        }
    }).then((response: ConfirmRemoveResponse) => {
        fetchData()
        setSelectedRows([])
    })
  }

  const columns: MUIDataTableColumn[] =
    [
      ...props.columns.map((column, colIdx) => ({
        ...column,
        label: (translateLabels ? t(`/fields:${column.label || column.name}`) : (column.label || column.name)),
        options: {
          filterList: tableState.filterList[colIdx]?.length ? tableState.filterList[colIdx] : [],
          filter: column?.options?.filter === true ? true : false,
          display: (viewColumns[column.name] === false ? "false" : "true") as "true" | "false",
          ...(column.options ? column.options : {}),
          filterOptions: {
            names: (column?.options?.filter && tableState.modelInfo) ? filterOptionNames(column.name, tableState.modelInfo.schema_info) : [],
            ...column?.options?.filterOptions,
          },
          setCellHeaderProps: () => column?.options?.align === "right" ? ({ className: classes.headerAlignRight }) : {},
          setCellProps: () => column?.options?.align === "right" ? ({ className: classes.cellAlignRight }) : {},
          customBodyRender: (value: string, tableMeta: MUIDataTableMeta, updateValue: (value: string) => void): string | React.ReactNode => {
            if (!tableState.modelInfo) return <DataCell value={value} />
            const content = column?.options?.customBodyRender === undefined ?
              <DataCell schema={tableState.modelInfo.schema_info[tableMeta.columnData.name]} value={value} />
              : column.options.customBodyRender(value, tableMeta, updateValue)

            if (column?.options?.editable) {
              return (
                <EditableTableCellContent modelInfo={tableState.modelInfo} modelApi={modelApi} value={value} tableMeta={tableMeta} updateValue={updateValue}>
                  { content}
                </EditableTableCellContent>
              )
            } else {
              return content
            }
          },
          editable: undefined,
          align: undefined
        },
      }))
      ,
      {
        name: "actions",
        options: {
          viewColumns: false,
          filter: false,
          customHeadRender: () => (
            <TableCell key="actions-header" className={classes.actionHead} >
              { t("Aktionen")}
            </TableCell>
          ),
          setCellProps: () => ({ style: stickyStyle }),
          customBodyRender: customActionsRender ? (_value: any, tableMeta: any) => (
            <div className={classes.actionButtonGroup}>
              {customActionsRender(tableState.normalizedData![tableMeta.rowData[0]], fetchData)}
            </div>
          ) : undefined
        }
      }
    ]

  const options: MUIDataTableOptions = {
    rowsPerPageOptions: [10, 20, 25, 50],
    elevation: 2,
    selectableRowsOnClick: false,
    rowHover: false,
    textLabels: textLabels[locale],
    filter: false,
    print: false,
    confirmFilters: true,
    ...props.options,
    onDownload: (buildHead: (columns: any) => string, buildBody: (data: any) => string, columns: any, data: any) => {
      if (props.options?.customDownload) {
        props.options.customDownload({
          page: tableState.page,
          pageSize: tableState.pageSize,
          searchText: tableState.searchText,
          sort: tableState.sort,
          filterObject: tableFilterToFilterObject(tableState.filterList, columns),
          columns: columns.filter((column: CustomMUIDataTableColumnDef ) => column.name !== "actions")
        })
        return false
      } else {
        if (props.options?.onDownload) {
          return props.options.onDownload(buildHead, buildBody, columns, data)
        } else {
          return false
        }
      }

    },
    onRowSelectionChange: (_currentRowsSelected: any[], rowsSelected: any[]) => {
      setTableState({
        ...tableState,
        rowsSelected
      })
    },
    onColumnSortChange: (changedColumn: string, direction: string) => {
      setTableState({
        ...tableState,
        sort: direction === "asc" ? changedColumn : `-${changedColumn}`
      })
    },
    onFilterChange: (_changedColumn: string, filterList: string[][], _type: "checkbox" | "reset" | "dropdown" | "multiselect" | "textField" | "custom" | "chip") => {
      setTableState({
        ...tableState,
        filterList
      })
    },
    customFilterDialogFooter: (currentFilterList: string[][], applyNewFilters: () => string[][]) => {
      return (
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "16px" }}>
          <Button onClick={() => applyNewFilters()}  variant="contained" style={{color: theme.palette.viewButton.contrastText, backgroundColor: theme.palette.viewButton.main}}> {/* TODO styling*/}
            <Fa icon={["far", "filter"]} mr={1} />
            {t("Filter anwenden")}
          </Button>
        </div>
      )
    },
    onViewColumnsChange: setViewColumns,
    searchOpen: tableState.searchText !== "",
    customSearchRender: (_idk, _idx, hideSearch) => (
      <SearchTextField
        value={tableState.searchText}
        onChange={(value: string) => setTableState({ ...tableState, page: 0, searchText: value })}
        delay={300}
        onHide={e => { setTableState({ ...tableState, searchText: "" }); hideSearch(e) }}
      />
    ),
    customToolbar: () => ([
      <IconButton key="refresh" onClick={() => fetchData()}>
        <RefreshIcon />
      </IconButton>,
      ...customToolbarActions
    ]
    ),
   
   customToolbarSelect : (selectedRows, displayData, setSelectedRows) => {
     const items = selectedRows.data.map(selectedRow => tableState.data[selectedRow.dataIndex])
     const models = (selectedRows.data.map(selectedRow => displayData[selectedRow.dataIndex].data[1].props.modelApi))
     const modelsEqual = models.every(( val, i, arr) => val.name === arr[0].name)
     const model = (modelsEqual) ? models[0] : (models.length === 1) ? models[0] :null
     if (model === null) {
       console.warn("models are not matching")
       return
     }
     return (
       <FaButton onClick={() => handleRemove(items, model, setSelectedRows)}>
         <Fa icon={["fas", "trash-alt"]}/>
       </FaButton>
     )
   },
    customTableBodyFooterRender: () => (
      <tbody>
        {
          [...(new Array(tableState.pageSize - tableState.data.length))].map((_, idx) => (
            <tr key={idx}><td style={{ height: "50px" }}></td></tr>
          ))
        }
      </tbody>
    ),
    // non overridable below
    count: tableState.itemCount,
    serverSide: true,
    onChangePage: (currentPage: number) => {
      setTableState({
        ...tableState,
        page: currentPage
      })
    },
    onChangeRowsPerPage: (rowsPerPage: number) => {
      setTableState({
        ...tableState,
        pageSize: rowsPerPage
      })
    },
    customFooter: (count: number, page: number, rowsPerPage: number, changeRowsPerPage: (rowsPerPage: number) => void, changePage: (page: number) => void, textLabels: MUIDataTableTextLabelsPagination) => {
      return (
        <Pagination
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          component={'div'}
          options={{ textLabels: { pagination: textLabels } }}
        />
      )
    }

  }

  useEffect(() => {
    setQueryParams({
      page: tableState.page,
      pageSize: tableState.pageSize,
      searchText: tableState.searchText,
      sort: tableState.sort,
      filterList: tableState.filterList
    })
    fetchData()
    // eslint-disable-next-line
  }, [tableState.page, tableState.pageSize, tableState.filterList, tableState.sort, tableState.searchText])

  const fetchData = async () => {
    setTableState({
      ...tableState,
      isLoading: true
    })
    const { data, meta } = await modelApi.getList(
      tableState.page + 1,
      tableState.pageSize,
      tableState.searchText,
      tableState.sort,
      //@ts-ignore // ! hack for starting with filter for webforms
      props.from_cornerstone ? fromCornerstone() :tableFilterToFilterObject(tableState.filterList, columns) 
    )

    const info = await modelApi.info()
    setTableState({
      ...tableState,
      itemCount: meta.item_count,
      normalizedData: normalize(data),
      isLoading: false,
      modelInfo: info,
      data
    })
  }

  return (
    <div className={classes.root}>
      <MUIDataTable
        {...props}
        title={<Typography variant="h2">
          {props.title}
          {tableState.isLoading && <CircularProgress size={15} style={{ marginLeft: 15}} />}
        </Typography>
        }
        columns={columns}
        data={tableState.data}
        options={options}
      />
    </div>
  )
}

export default AsyncMUIDataTable
export * from 'mui-datatables'
