import React, {useState} from "react"
import { Grid, Paper} from "@material-ui/core"
import { useChatState } from "../../ChatContext"
import FormikInput from "components/formik/FormikInput"
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FieldArray } from "react-final-form-arrays"
import { useTranslation } from "react-i18next"
import Grow from '@material-ui/core/Grow'

const StartNodeOptions = ({  classes, node }: any) => {
    const { state } = useChatState()
    const { t } = useTranslation()
    const [withButtons, setWithButtons] = useState(node.data.withButtons)

    return (
        <PerfectScrollbar style={{borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px"}}>
            <Grid container direction="column" alignItems="center" className={classes.settingsContainer}>
                <Paper elevation={2} className={classes.settingsGroupPaper} style={{marginBottom: "24px"}}>
                    <Grid container item direction="column" justify="center" alignItems="stretch" className={classes.settingsGroup}>
                        <Grid item style={{ width: "100%" }}>
                            <label className="FormikFormLabel">{t("Starttext")}</label>
                            <FormikInput
                                type="entityText"
                                name="text"
                                typeParams={state.fields}
                            />
                        </Grid>
                        <Grid item>
                            <FormikInput
                                type="checkbox"
                                name="withButtons"
                                label={t("Abfrage zum Fortführen des Chats")}
                                onClick={() => setWithButtons(!withButtons)}
                            />
                        </Grid>
                    </Grid>
                </Paper>
                {withButtons && 
                    <Grow in={true}>
                        <Paper elevation={2} className={classes.settingsGroupPaper}>
                        <Grid container item className={classes.header} wrap="nowrap" direction="row" alignItems="center" justify="space-between" style={{height: "46px"}}>
                            <Grid item>
                                {t("Button-Einstellungen")}
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" justify="center" alignItems="stretch" className={classes.settingsGroup}>
                            <FieldArray name="buttonTexts" subscription={{ submitting: true }}>
                                {({ fields }) =>
                                    fields.map((name: string, index: number) => {
                                        return (
                                            <div key={index} style={{ width: "100%" }}>
                                                <Grid item>
                                                    <label className="FormikFormLabel">{t("Button-Text zum Fortführen des Chats")}</label>
                                                    <FormikInput
                                                        type="entityText"
                                                        name={`${name}.textOne`}
                                                        variant="outlined"
                                                        typeParams={state.fields}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <label className="FormikFormLabel">{t("Button-Text zum Beenden des Chats")}</label>
                                                    <FormikInput
                                                        type="entityText"
                                                        name={`${name}.textTwo`}
                                                        variant="outlined"
                                                        typeParams={state.fields}
                                                    />
                                                </Grid>
                                            </div>
                                        )
                                    })}
                                </FieldArray>
                            </Grid>
                        </Paper>
                    </Grow>
                }
            </Grid>
        </PerfectScrollbar >
    )
}

export default StartNodeOptions