import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faSignIn,
    faEnvelope,
    faUser,
    faIdCard,
    faWrench,
    faTrash,
    faEye,
    faPencil,
    faCircle,
    faCheck,
    faTimes,
    faQrcode,
    faPrint,
    faFilePdf,
    faFileImage,
    faCheckSquare,
    faMinusSquare,
    faCloudDownloadAlt,
    faTrashAlt,
    faChevronLeft,
    faChevronRight,
    faChevronDoubleRight,
    faUndo,
    faExpandAlt,
    faReply,
    faReplyAll,
    faShare,
    faShieldCheck,
    faLockOpenAlt,
    faLock,
    faStar,
    faPaperPlaneTop,
    faRocketLaunch,
    faRotateLeft,
    faPaintRoller,
    faPhoneFlip,
    faGlobe,
    faIndustryWindows,
    faChessKing,
    faCrown,
    faPaperPlane,
    faMemoPad,
    faAt,
    faCopy,
    faDownload,
    faPaintbrush,
    faFloppyDisk,
    faEllipsisVertical,
    faCloudArrowUp,
    faFloppyDiskCircleXmark,
    faPowerOff,
    faFaceSmile,
    faTachometerAltFast,
    faSquareList,
    faMessageBot,
    faSlidersH,
    faCircleXmark

} from '@fortawesome/pro-solid-svg-icons'

library.add(
    faSignIn,
    faEnvelope,
    faUser,
    faIdCard,
    faWrench,
    faTrash,
    faEye,
    faPencil,
    faCircle,
    faCheck,
    faTimes,
    faQrcode,
    faPrint,
    faFilePdf,
    faFileImage,
    faCheckSquare,
    faMinusSquare,
    faCloudDownloadAlt,
    faTrashAlt,
    faChevronLeft,
    faChevronRight,
    faChevronDoubleRight,
    faUndo,
    faExpandAlt,
    faReply,
    faReplyAll,
    faShare,
    faShieldCheck,
    faLockOpenAlt,
    faLock,
    faStar,
    faPaperPlaneTop,
    faRocketLaunch,
    faRotateLeft,
    faPaintRoller,
    faPhoneFlip,
    faGlobe,
    faIndustryWindows,
    faChessKing,
    faCrown,
    faPaperPlane,
    faMemoPad,
    faAt,
    faCopy,
    faDownload,
    faPaintbrush,
    faFloppyDisk,
    faEllipsisVertical,
    faCloudArrowUp,
    faFloppyDiskCircleXmark,
    faPowerOff,
    faFaceSmile,
    faTachometerAltFast,
    faSquareList,
    faMessageBot,
    faSlidersH,
    faCircleXmark
)
