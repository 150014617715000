import React from 'react'

import UserQuickInfo from './UserQuickInfo'
import Nav from './Nav'
import { makeStyles, Divider, Theme } from '@material-ui/core'
import { FaButton, Grid, Fa, Typography} from '../../../styled/muiComponents'
import { Link } from 'react-router-dom'
import useSession from '../../../hooks/useSession'
import { useSidebar } from '../../../hooks/useSidebar'
import Grow from '@material-ui/core/Grow';
import Collapse from '@material-ui/core/Collapse'
import { useTariff } from 'hooks/useTariff'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles<Theme, {isFixedOpen? : boolean}>(theme => ({
    root: {
        backgroundColor: theme.palette.companyDark.main,
        height: "100%",
        width: ({ isFixedOpen }) => isFixedOpen ? "256px" : "80px",
        transition: "width 0.35s ease",
        maxWidth: "256px",
        position: "fixed",
        zIndex: 120,
        padding:0,
        "& img": {
            width: "100%",
            display: "inline-block"
        },
        "& > div": {
           width: "100%"
        }
    },
    verticalWrapper: {
        width: "80px"
    },
    horizontalWrapper: {
        width: "256px"
    },
    systemLogo: {
        padding: "25px 30px 25px 24px",
        display: "block",
        height: "40px",
        width: "196px"
    },
    systemButton: {
        color: "white",
        transition: "all 0.5s ease",
        "&:hover": {
            color: "red",
            cursor: "pointer"
        },
        padding: "0px"
    },
    navDivider: {
        backgroundColor: "rgba(50 , 52, 58, 1)",
        marginBottom: "-1px"
    },
    navbarButton: {
        color: "white",
        transition: "all 0.5s ease",
        transform: ({isFixedOpen}) => isFixedOpen ? "rotate(180deg)" : "rotate(0deg)",
        "&:hover": {
            color: "red",
            cursor: "pointer"
        }
    },
    systemLogoSmall:{
        height:"40px",
        width:"40px",
        padding: "25px 30px 25px 24px",
        display:"block"
    },
    tariffInfo:{
        color:"white"
    }
}))

const SideBar: React.FC<{}> = () => {
    const { logout } = useSession()
    const { isFixedOpen, fixedClose, fixedOpen } = useSidebar()
    const classes = useStyles({ isFixedOpen })

    const handleFixedToggle = () => {
        if (isFixedOpen) {
            fixedClose()
        }
        else {
            fixedOpen()
        }
    }


    return (
        <Grid container direction="column" className={classes.root} justify="space-between" alignItems="center" wrap="nowrap" style={{overflowX: "hidden"}}>
            <Grid item>
                {isFixedOpen  ?
                    <Grow in={isFixedOpen  } timeout={{appear:200, enter:500, exit: 500}}>
                        <Link to="/app"  className={classes.systemLogo}>
                            <img alt="logo" src={"/assets/images/cp-cornerstone-light.svg"} />
                        </Link>
                    </Grow>
                :null}
                    {!(isFixedOpen) ?
                        <Grow in={!isFixedOpen } timeout={{appear:350, enter:500, exit: 500}}>
                            <Link to="/app" className={classes.systemLogoSmall}>
                                <img alt="Cornerstone" src={"/assets/images/Campaign-Plus-Signet.svg"} />
                            </Link>
                    </Grow>
                    : null}
                <UserQuickInfo isSidebarFixedOpen={isFixedOpen} />
                <Nav />
            </Grid>
            <Grid item>
                <Divider variant="fullWidth" className={classes.navDivider} />
                <Grid container direction="column-reverse" className={classes.verticalWrapper} alignItems="center" justify="center">
                    <Collapse in={!(isFixedOpen)} timeout="auto">
                        <Grid item paddingBottom="16px" paddingTop="16px">
                            <FaButton onClick={logout} size="small" className={classes.systemButton} >
                                <Fa icon={["fal", "power-off"]} fixedWidth />
                            </FaButton>
                        </Grid>
                        <Grid item paddingBottom="16px">
                            <FaButton size="small" className={classes.systemButton} >
                                <Fa icon={["fal", "headset"]} fixedWidth />
                            </FaButton>
                        </Grid>
                        <Grid item paddingBottom="16px">
                            <FaButton onClick={() => console.warn("not implemented")} size="small" className={classes.systemButton} >
                                <Fa icon={["fal", "user"]} fixedWidth />
                            </FaButton>
                        </Grid>
                    </Collapse>
                </Grid>
                <Divider variant="fullWidth" className={classes.navDivider}/>
                <Grid container className={classes.horizontalWrapper} justify="space-around" py={2}>
                    <Grid item paddingLeft="10px">
                        <FaButton size="small" className={classes.navbarButton} onClick={handleFixedToggle} >
                            <Fa icon={["fal", "arrow-right"]} fixedWidth />
                        </FaButton>
                    </Grid>
                    <Grid item>
                        <FaButton size="small" className={classes.systemButton}>
                            <Fa icon={["fal", "headset"]} fixedWidth />
                        </FaButton>
                    </Grid>
                    <Grid item >
                        <FaButton onClick={() => console.warn("not implemented")} size="small" className={classes.systemButton}>
                            <Fa icon={["fal", "user"]} fixedWidth />
                        </FaButton>
                    </Grid>
                    <Grid item paddingRight="10px">
                        <FaButton onClick={logout} size="small" className={classes.systemButton} >
                            <Fa icon={["fal", "power-off"]} fixedWidth />
                        </FaButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}




export default SideBar