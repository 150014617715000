import React, { useEffect, useRef, useState } from "react"
import OptionsElement from "./optionsElement/OptionsElement"
import View from "./View"
import { Grid, makeStyles, Paper } from "@material-ui/core"
import chats from "models/chats"
import { useChatState } from "./ChatContext"
import { ReactFlowProvider } from "react-flow-renderer"
import { useLocation, useParams } from "react-router-dom"
import { IRouteParams } from "routes/app/stock/recipients/Recipient"
import LoadingAnimation from 'components/LoadingAnimation'
import Button from "components/Button/Button";
import Toolbar from "./Toolbar"
import customFields, { ICustomFields } from "models/customFields"
import { convertTextfields, fields, snackDescriptionRedo, snackDescriptionUndo } from "./chatFunctions"
import moment from "moment"
import { primaryHue } from 'theme'
import { useSnackbar } from "notistack"
import ChatnameDialog from "./ChatnameDialog"
import { useDialog } from "hooks/useDialog"
import { Fa } from "styled/muiComponents"
import { useTranslation } from "react-i18next"
import Slide from '@material-ui/core/Slide';
import webforms from "models/webform"
import { webformsWithChat } from "constants/filters"
import { Address } from "hooks/useSession"
import imprints from "models/imprint"

const useStyles = makeStyles((theme) => ({
  OptionsElementWrapper: {
    top: "calc(3vh + 48px)",
    maxHeight: "calc(100% - 6vh - 48px)",
    right: "3vh",
    position: "fixed",
    zIndex: 100,
    backgroundColor: "#ffffff",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    overflow: "visible"
  },
  viewWrapper: {
    height: "100vh",
    width: "100vw",
    position: "fixed"
  },
  generalSettingsWrapper: {
    top: "3vh",
    right: "3vh",
    position: "fixed",
    zIndex: 100
  },
  selectedNodeOverlay: {
    background: primaryHue[600],
    zIndex: 50,
    height: "100vh",
    width: "100vw",
    opacity: 0.6,
    position: "fixed"
  },
  goToSelectionBarWrapper: {
    marginTop: "30px",
    justifyContent: "center",
    zIndex: 100,
    width: "100%"
  },
  goToselectBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  goToPadding: {
    padding: theme.spacing(1),
  }
}))

const chatSettingDefault = {
  showAvatar: true,
  chatColor: "#efeae2",
  chatBackgroundImage: "default",
  imageColor: "#CCC0B0",
  botBubbleColor: "#ffffff",
  customerBubbleColor: "#d9fdd3",
  typing: "Pulse",
}

const start = [
  {
    "id": "1",
    "type": "start",
    //draggable:false,
    "data": {
      "node": {
        "id": "1",
        "type": "start",

      },
      "text": "",
      "withButtons": true,
      "buttonTexts": [{textOne:"", textTwo:""}],
      "name": "start"
    },
    "position": {
      "x": 0,
      "y": 0
    }
  }
]

const ChatBuilder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useChatState()
  const [initialChat, setInitialChat] = useState<any>()
  const params = useParams<IRouteParams>()
  const classes = useStyles()
  const [fieldList, setFieldList] = useState<any | null>(null)
  const ref = useRef(state)
  const location = useLocation()
  const { dialog } = useDialog()
  const [imprint, setImprint] = useState<Address | null>(null)

  useEffect(() => {
    // fetching custom fields for datacollection
    const getCustomFields = async () => {

      // ! does that reset defualts in general settings after saving?
      const list = await customFields.getList()
      const combinedList = list.data.concat(fields)

      let defaults: any = []
      combinedList.forEach((field: ICustomFields, index: number) => {
        switch (field.type) {
          case "string": {
            defaults.push({[field.name]: `${field.name}`}); break
          }
          case "date": {
            defaults.push({[field.name]: moment()}); break
          }
          case "boolean": {
            defaults.push({[field.name]: false}); break
          }
          case "enum":{
            defaults.push({[field.name]: field.type_params[1]}); break
          }
          case "set":{
            defaults.push({[field.name]: field.type_params[1]}); break
          }
          }
        }
      )
      setFieldList({ combinedList, defaults })
    }

    const fetchImprint = async () => {
      const imp = await imprints.getFirst()
      setImprint(imp)
    }

    getCustomFields(); fetchImprint()
  }, [])
  
  useEffect(() => {
    ref.current = state
  }, [state])

  useEffect(() => {
    if (!fieldList) {
      return
    }
    if (location.state) {
      setInitialChat({
        elements: convertTextfields(location.state.template, "fromRaw"),
        selected: null,
        selectedHandle: null,
        generalSettingsOpen: false,
        generalSettings: {
          chatName: "",
          withAvatar: false,
          placeholder: fieldList.defaults,
          selectedWebforms: {chosen: [], left:[]}
        },
        drawingEdge: null,
        goToSelection: false,
        testChatActive: false,
        fields: fieldList.combinedList,
        past: [],
        future: [],
        chatSettings: chatSettingDefault,
        chatSettingsMenuOpen: false,
        id:null,
        imprint: imprint
      })
    } else if (params.id) {
      const fetchChat = async () => {
        const filterObject = webformsWithChat(params.id)
        const chat = await chats.get(parseInt(params.id))
        //@ts-ignore why filterobject error when it works?
        const chosenWfs = await webforms.getList(1,10, undefined, null, filterObject)
        // TODO new customFields are not included here :/
        setInitialChat({...chat.chat, fields: fieldList.combinedList,imprint: imprint, id: params.id, generalSettings: {...chat.chat.generalSettings, selectedWebforms: {chosen: chosenWfs.data, left: []}} })
      }
      fetchChat()
    } else {
      setInitialChat({
        elements: start,
        selected: null,
        selectedHandle: null,
        generalSettingsOpen: false,
        generalSettings: {
          chatName: "",
          withAvatar: false,
          placeholder: fieldList.defaults,
          selectedWebforms: {chosen: [], left:[]}
        },
        drawingEdge: null,
        goToSelection: false,
        testChatActive: false,
        fields: fieldList.combinedList,
        past: [],
        future: [],
        chatSettings: chatSettingDefault,
        chatSettingsMenuOpen: false,
        id:null,
        imprint: imprint
      })
    }
  }, [fieldList, imprint])

  useEffect(() => {
    if (!initialChat) {
      return
    }
    if (params.id) {
      dispatch({ type: "INITIAL_RENDER_SAVED", payload: initialChat })
    } else {
      dialog.show<any>({
        component: ChatnameDialog,
        props: {

        }
      }).then((result: any) => {
        if (result !== {}) {
          dispatch({ type: "INITIAL_RENDER_NEW", payload: { ...initialChat, generalSettings: { ...initialChat.generalSettings, chatName: result.name } } })
        } else {
          dispatch({ type: "INITIAL_RENDER_NEW", payload: initialChat })
        }
      })
    }


  }, [initialChat])

  //keyPress event handlers
  // TODO add strg s to save
  let keysPressed = {}
  useEffect(() => {
    if (!initialChat) {
      return
    }
    document.addEventListener('keydown', (event) => {
      //@ts-ignore
      keysPressed[event.key] = true;
      //@ts-ignore
      if (keysPressed['Control'] && event.key === 'z') { //undo
        if (!ref.current.past || ref.current.past.length === 0) {
          return
        }
        enqueueSnackbar(snackDescriptionUndo(ref.current.past[ref.current.past.length - 1].type), { variant: "info" })
        dispatch({ type: "UNDO" })
        return
        //@ts-ignore
      } else if (keysPressed['Control'] && event.key === 'y') { //redo
        if (!ref.current.future || ref.current.future.length === 0) {
          return
        }
        enqueueSnackbar(snackDescriptionRedo(ref.current.future[ref.current.future.length - 1].type), { variant: "info" })
        dispatch({ type: "REDO" })
        return
      } else if (event.key === "Escape") { // cancel current select
        dispatch({ type: "CANCEL_SELECT" })
        return
      }
    });
    return () => document.removeEventListener("keydown", () => { })
  }, [initialChat])

  useEffect(() => {
    if (!initialChat) {
      return
    }
    document.addEventListener('keyup', (event) => {
      //@ts-ignore
      delete keysPressed[event.key];
    });
    return () => document.removeEventListener("keyup", () => { })
  }, [initialChat])

  if (!initialChat) {
    return <LoadingAnimation />
  }
  console.log(state.elements)
  console.log(state)

  const handleCancel = () => {
      dispatch({ type: "CANCEL_ALL_SELECT" })
  }

  return (
    <Grid container style={{ maxHeight: "100%" }}>
      <ReactFlowProvider>
        <Grid item>
          {
            (state.selected !== null || state.generalSettingsOpen || state.testChatActive || state.selectedhandle || state.selectedHandle || state.nodeMenuOpen) && !state.goToSelection ?
              <div className={classes.selectedNodeOverlay} onClick={handleCancel}> </div>
              :
              null
          }
        </Grid>
        <Grid item className={classes.viewWrapper}>
          <View />
        </Grid>
        <Grid container item>
          <div className={classes.goToSelectionBarWrapper} style={{ display: state.goToSelection ? "flex" : "none" }}>
              <GoToSelectionBar />
          </div>
          <div className={classes.generalSettingsWrapper} style={{ display: !state.goToSelection ? "flex" : "none" }}>
            <Toolbar />
          </div>
          <div className={classes.OptionsElementWrapper} style={{ display: !state.goToSelection ? "flex" : "none", height: state.testChatActive ? "100vh" : "auto" }}>
            {state.selected || state.selectedHandle || state.generalSettingsOpen || state.testChatActive || state.nodeMenuOpen ?
              <OptionsElement />
              : null}
          </div>
        </Grid>
      </ReactFlowProvider>
    </Grid>
  )
}

export default ChatBuilder

const GoToSelectionBar = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useChatState()

  const handleCancel = () => {
    dispatch({ type: "DISABLE_GOTO_SELECTION" })
  }

  return (
    <Slide in={state.goToSelection} direction="down" mountOnEnter unmountOnExit>
      <Paper style={{padding: "8px"}}>
        <Grid container direction="row" className={classes.goToselectBar}>
          <Grid item className={classes.goToPadding}>
            <Fa icon={["fal", "arrow-pointer"]} size="lg" ml={1} style={{fontSize: "1.5rem"}}/>
          </Grid>
          <Grid item className={classes.goToPadding}>
            <strong>Wähle den Block aus, an dem es weitergehen soll</strong>
          </Grid>
          <Grid item className={classes.goToPadding}>
            <Button onClick={handleCancel} variant="contained" color="error" size="small"><Fa icon={["fas", "times"]} mr={1}  size={"lg"} /> {t("Abbrechen")}</Button>
          </Grid>
        </Grid>
      </Paper>
    </Slide>


  )
}