import React from "react"
import Select from "./Select"
import {MenuItem} from "@material-ui/core"
import { useTranslation } from "react-i18next"


const Enum = ({...props}) => {
    const {t} = useTranslation()

    return(
        <Select {...props}>
        {props.typeParams.map((param:any) => <MenuItem key={param} value={param}>{ t(`/common:${param}`) }</MenuItem>)}
      </Select>
    )
}

export default Enum