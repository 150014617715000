import React from "react"
import { IState, Navigation, NavigationProps } from "../HtmlWizard"
import Editor from "./Editor"

type Props = {
    state: IState,
    dispatch: any,
    navigationProps: Omit<NavigationProps, "onNext"> & {onNext?:() => void}

}



const HtmlBuilder = ({ state, dispatch, navigationProps }: Props) => {

    const handleNext = () => {
        dispatch({type: "NEXT_STEP"})
    }

    return (
        <Editor 
            state={state}
            dispatch={dispatch}
            preConfigComponent={<Navigation {...navigationProps} onNext={handleNext}/>}
        />
    )
}

export default HtmlBuilder