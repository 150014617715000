import React from 'react'
import { ColorPicker as Colorpicker } from "material-ui-color"
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
    colorButtonGridItem: {
        flex: "0 1",
        "& button": {
            
        }
    },
    colorLabelGridItem: {
        flex: "1 1",
        cursor: "context-menu"
    },
    colorButton: {
        borderRadius: "50% !important",
        boxShadow: "none !important",
    }
  }))
const ColorPicker = ({ ...props }) => {

    const handleBlur = (e) => {
        if (props.onBlur) {
            props.onBlur(e)
        }
        props.input.onBlur(e)
    }

    const handleChange = (color) => {

        if (props.onChange) {
            props.onChange({ target: { name: props.input.name, value: `#${color}` } })
        }
        props.input.onChange({ target: { name: props.input.name, value: `#${color}` } })
    }


    const classes = useStyles()
   

    // const palette = {
    //     red: '#ff0000',
    //     blue: '#0000ff',
    //     green: '#00ff00',
    //     yellow: 'yellow',
    //     cyan: 'cyan',
    //     lime: 'lime',
    //     gray: 'gray',
    //     orange: 'orange',
    //     purple: 'purple',
    //     black: 'black',
    //     white: 'white',
    //     pink: 'pink',
    //     darkblue: 'darkblue',
    // };

    return (
        <Grid container direction="row" alignItems="center">
            <Grid item className={classes.colorLabelGridItem}>
                {props.label}
            </Grid>
                <Colorpicker
                    name={props.input.name}
                    //  {...props.input}
                    //  {...field}
                    value={props.input.value}
                    //  value={field.value || props.input.value}
                     onChange={(e) => handleChange(e.hex)}
                     disableAlpha={props.disableAlpha ?? true}
                    {...(props.disableAlpha !== false && { disableAlpha: true })}
                     hideTextfield
                     onBlur={(e) => handleBlur(e.hex)}
                    // palette={palette}    // ! why does palette throw error?????
                      deferred
                      style={{border: "2px solid #ffffffb3"}} // TODO MAKE THIS WORK
                />
        </Grid>
    )
}

export default ColorPicker