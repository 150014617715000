import React, {useEffect, useState} from "react"
import { SetupNavigationProps, Navigation, ISetupInformation } from "../SetupWizard"
import { Form } from 'react-final-form'
import FormikInput from "components/formik/FormikInput"
import { Typography, Grid, Fa } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { useTranslation } from "react-i18next"
import InputAdornment from '@material-ui/core/InputAdornment';

type Props = {
    navigationProps: SetupNavigationProps
    classes: any
    handleChange: (event:any) => void
    setupInformation: ISetupInformation
}

const CompanyName = ({ navigationProps, classes, handleChange, setupInformation }: Props) => {
    const { t } = useTranslation()
    const [enableNext, setEnableNext] = useState<boolean>(false)

    const handleSubmit = () => {
    }

    useEffect(() => {
        if(setupInformation.legal_form === "Andere" && setupInformation.company_name && setupInformation.legal_form_offlist) {
            setEnableNext(true)
        } else if (setupInformation.legal_form !== "Andere" && setupInformation.legal_form !== "" && setupInformation.company_name){
            setEnableNext(true)
        } else {
            setEnableNext(false)
        }
    },[setupInformation])

    const initialValues = {
        company_name: setupInformation.company_name,
        legal_form: setupInformation.legal_form,
        legal_form_offlist: setupInformation.legal_form_offlist
    }
    const [hasFocus, setFocus] = useState(false);
    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}
            render={({ handleSubmit, submitting }) => (
                <Grid container item direction="column" className={classes.root}>
                    <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" justify="flex-start">
                                <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                    <Grid item sm={4}>
                                        <div style={{ maxWidth: "150px" }}>
                                            <Pixi size="full" pose="waiting"></Pixi>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <div className={classes.headerDescription} >
                                        <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                            <Typography variant="h5" className={classes.pixiSpeech}>
                                                {t("Wie heißt dein Unternehmen und welche Rechtsform hat es?")} 
                                            </Typography>
                                        </Typist>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item container className={classes.formContent}>
                                    <Grid item xs={12}>
                                        <FormikInput
                                            type="string"
                                            value={setupInformation.company_name}
                                            name="company_name"
                                            label={t("Name des Unternehmens (inkl. Rechtsform)")}
                                            variant="outlined"
                                            onChange={handleChange}
                                            required
                                            style={{marginTop: "8px"}}
                                            InputProps={
                                                {
                                                    onFocus:(() => setFocus(true)),
                                                    onBlur:(() => setFocus(false)),
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            { hasFocus ? <Fa icon={["fas", "industry-windows"]}/> : <Fa icon={["fal", "industry-windows"]}/> }
                                                        </InputAdornment>
                                                ),
                                                }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormikInput
                                            type="enum"
                                            value={setupInformation.legal_form}
                                            name="legal_form"
                                            label={t("Rechtsform *")}
                                            variant="outlined"
                                            onChange={handleChange}
                                            labelProps={{
                                                variant: "outlined"
                                            }}
                                            typeParams={[
                                                "Einzelunternehmen",
                                                "Gesellschaft bürgerlichen Rechts (GbR)",  // TODO redo with translated fields
                                                "Eingetragener Kaufmann (e.K.)",
                                                "Offene Handelsgesellschaft (OHG)",
                                                "Kommanditgesellschaft (KG)",
                                                "Gesellschaft mit beschränkter Haftung (GmbH)",
                                                "Aktiengesellschaft (AG)",
                                                "GmbH & Co KG",
                                                "Verein (e. V.)",
                                                "Genossenschaft (e. G.)",
                                                "Andere"
                                            ]}
                                            required
                                        />
                                    </Grid>
                                    {setupInformation.legal_form === "Andere" ?
                                    <Grid item sm={12}>
                                        <FormikInput
                                                type="string"
                                                value={setupInformation.legal_form_offlist}
                                                name="legal_form_offlist"
                                                label={t("Keine der angegebenen Rechtsformen trifft zu?")}
                                                variant="outlined"
                                                onChange={handleChange}
                                        />
                                    </Grid> 
                                    :null}
                                </Grid>
                                <Grid item sm={12}>
                                    <Navigation  {...navigationProps} enableNext={!enableNext}/>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item className={classes.formNote}>
                            <Typography variant="body1" className={classes.requiredNotice}>
                                <i>* {t("Pflichtfeld")}</i>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    )
}

export default CompanyName