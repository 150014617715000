import { useState, useEffect } from 'react'

export default function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    });
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return {
      windowWidth: width,
      windowHeight: height
  }
}