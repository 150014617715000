import React from "react"
import {Grid, makeStyles, Theme} from "@material-ui/core"
import {useTranslation, Trans} from "react-i18next"
import { Fa } from "styled/muiComponents"

const useStyles = makeStyles<Theme, {result: boolean}>(theme => ({
    icon:{
        color:({result}) => result ? "#83ac1b" : "#e83338"
    },
    contrastIconGridItem: {
        flex: "0 1",
    },
    contrastLabelGridItem: {
        flex: "1 1",
        cursor: "context-menu"
    },
}))

export type IContrastCheckerProps = {
    bgColor: string
    fgColor: string
}

const ContrastChecker = ({bgColor, fgColor}: IContrastCheckerProps) => {
    
    const { t } = useTranslation()
	function hexToRgb(hex:any) {
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function(m: any, r: any, g: any, b: any) {
		return r + r + g + g + b + b;
	});

	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
	}

	const color1 = bgColor
	const color2 = fgColor
	const color1rgb = hexToRgb(color1);
	const color2rgb = hexToRgb(color2);

	function luminance(r: any, g: any, b: any) {
    var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow( (v + 0.055) / 1.055, 2.4 );
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
	}
    //@ts-ignore
    const color1luminance = luminance(color1rgb.r, color1rgb.g, color1rgb.b);
    //@ts-ignore
	const color2luminance = luminance(color2rgb.r, color2rgb.g, color2rgb.b);

	const ratio = color1luminance > color2luminance 
    ? ((color2luminance + 0.05) / (color1luminance + 0.05))
    : ((color1luminance + 0.05) / (color2luminance + 0.05));

    //AA-level large text: {ratio < 1/3 ? 'PASS' : 'FAIL'
    //AA-level small text: {ratio < 1/4.5 ? 'PASS' : 'FAIL' 
    //AAA-level large text: {ratio < 1/4.5 ? 'PASS' : 'FAIL' 
    //AAA-level small text: {ratio < 1/7 ? 'PASS' : 'FAIL' 
    
    const result = ratio < 1/4.5 ? true : false
    const classes = useStyles({result}) 

    return(
        <>
            <Grid container justify="center" alignContent="center">
                <Grid item className={classes.contrastLabelGridItem}>
                    {result
                        ?
                            <>
                                {t("Kontrast ausreichend")}
                            </>
                        :
                            <>
                                <Trans i18nKey="contrastCheckerStatusBad">
                                    Kontrast <strong><u>nicht</u></strong> ausreichend
                                </Trans>
                            </>
                    }
                </Grid>
            
                <Grid item className={classes.contrastIconGridItem}>
                    {result
                        ?
                        <>
                            <Fa icon={["fas", "check"]} size={"lg"} className={classes.icon} mr={0.5} /> 
                        </>
                        :
                        <>
                            <Fa icon={["fas", "times"]} size={"lg"} className={classes.icon} mr={0.75} />
                        </>
                    }
                </Grid>
            </Grid>
        </>
    )
}
export default ContrastChecker