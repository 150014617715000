import React, {useState, useRef, useEffect} from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Paper, Grid, Tooltip } from "@material-ui/core"
import { FormikInput } from "styled/muiComponents"
import { useChatState } from "../../ChatContext"
import Button from "components/Button/Button"
import { useTranslation } from "react-i18next"
import { Fa, FaButton, Typography } from "styled/muiComponents"
import {TypingAnimation} from "routes/chat/chatSim/ChatSim"
import Pixi from 'components/Pixi'
import Collapse from '@material-ui/core/Collapse';

const ChatSettings = ({ classes }: any) => {
    const {state, dispatch} = useChatState()
    const { t } = useTranslation()

    // const handleApplyChanges = () => {
    //     dispatch({type:"UPDATE_CHAT_SETTINGS"})
    // }
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    
    const refTop = useRef<HTMLDivElement>(null);
    const refBottom = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (refTop.current !== null) {
            setHeaderHeight(refTop.current.clientHeight);
        }
        if (refBottom.current !== null) {
            setFooterHeight(refBottom.current.clientHeight);
        }
    }, []);

    const [checked, setChecked] = React.useState(true);
    const handleChange = () => {
        setChecked((prev) => !prev);
      };
    const handleCancel = () => {
        dispatch({ type: "CANCEL_ALL_SELECT" })
    }
    return (
        <div className={classes.chatStyleComponent} style={{boxSizing: "border-box"}}>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.chatStyleComponentHeader} wrap="nowrap" ref={refTop}>
                <Grid container item direction="row" alignItems="center" wrap="nowrap">
                    <Fa icon={["far", "paint-roller"]} fixedWidth mr={1} style={{fontSize: "1.1rem"}} className={classes.chatStyleComponentHeaderIcon}/> <Typography style={{fontSize: "1.1rem"}}>{t("Chat-Design")}</Typography>
                </Grid>
                <Grid item>
                    <Tooltip title={t("Schließen")!}>
                        <FaButton onClick={handleCancel} className={classes.nodeOptionsActionButtons}>
                            <Fa icon={["fas", "times"]} fixedWidth />
                        </FaButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <PerfectScrollbar style={{ maxHeight: `calc(100% - ${headerHeight}px - ${footerHeight}px)`, backgroundColor: "#f9f9f9", height: "100%" }}>
                <Grid container direction="column" className={classes.settingsContainer} wrap="nowrap" style={{height: "auto"}}>
                    <Paper elevation={1} className={classes.settingsGroupPaper} style={{marginBottom: "16px"}}>
                        <Grid container item className={classes.header} wrap="nowrap" direction="row" alignItems="center" justify="space-between" style={{height: "46px"}}>
                            {t("Avatar")}
                        </Grid>
                        <Grid container item wrap="nowrap" direction="column" className={classes.settingsGroup}>
                            <Grid container item wrap="nowrap" direction="column" alignItems="center" justify="space-between">
                                <FormikInput
                                    type="checkbox"
                                    name="showAvatar"
                                    label={t("Avatar verwenden")}
                                    checked={checked}
                                    onChange={handleChange}
                                />
                                {/* 
                                <FormControlLabel
                                    control={<Switch checked={checked} onChange={handleChange} name="showAvatar" size="small"/>}
                                    label={t("Avatar verwenden")}
                                />
                                */}
                            </Grid>
                            <Collapse in={checked}>
                                <Grid container item wrap="nowrap" direction="row" alignItems="center" justify="space-between" style={{marginTop: "16px"}}>
                                    <Grid item style={{width: "100%"}}>
                                        <FormikInput
                                            type="enum"
                                            name="chatAvatar"
                                            label={t("Avatar")}
                                            variant="outlined"
                                            labelProps={{
                                                variant: "outlined"
                                            }}
                                            typeParams={[
                                                "Pixi"
                                            ]}
                                            formControlProps={{margin: "0"}}
                                        />
                                    </Grid>
                                    <Grid item style={{maxWidth: "55px", paddingLeft: "8px", paddingRight: "8px", marginLeft: "8px", backgroundColor: "#e6ecef", borderRadius: "4px"}}>
                                        <Pixi size="full" pose="waiting"></Pixi>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Paper>
                    <Paper elevation={1} className={classes.settingsGroupPaper} style={{marginBottom: "16px"}}>
                        <Grid container item className={classes.header} wrap="nowrap" direction="row" alignItems="center" justify="space-between" style={{height: "46px"}}>
                            {t("Chat-Hintergrund")}
                        </Grid>
                        <Grid container item wrap="nowrap" direction="column" className={classes.settingsGroup}>
                            <Grid container item wrap="nowrap" direction="row" alignItems="center" justify="space-between" style={{marginBottom: "16px"}}>
                                <FormikInput
                                    type="enum"
                                    name="chatBackgroundImage"
                                    label={t("Muster")}
                                    variant="outlined"
                                    labelProps={{
                                        variant: "outlined"
                                    }}
                                    typeParams={[
                                        "default",
                                        "restaurant",
                                        "www",
                                        "no",
                                    ]}
                                    formControlProps={{margin: "0"}}
                                />
                            </Grid>
                            <Grid container item wrap="nowrap" direction="row" alignItems="center" justify="space-between">
                                <Grid item>
                                    {t("Hintergrundfarbe")}
                                </Grid>
                                <Grid item>
                                    <FormikInput
                                        type="color"
                                        name="chatColor"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item wrap="nowrap" direction="row" alignItems="center" justify="space-between">
                                <Grid item>
                                    {t("Musterfarbe")}
                                </Grid>
                                <Grid item>
                                    <FormikInput 
                                        type="color"
                                        name="imageColor"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={1} className={classes.settingsGroupPaper} style={{marginBottom: "16px"}}>
                        <Grid container item className={classes.header} wrap="nowrap" direction="row" alignItems="center" justify="space-between" style={{height: "46px"}}>
                            {t("Sprechblasenfarbe")}
                        </Grid>
                        <Grid container item wrap="nowrap" direction="column" className={classes.settingsGroup}>
                            <Grid container item wrap="nowrap" direction="row" alignItems="center" justify="space-between">
                                <Grid item>
                                    {t("Bot")}
                                </Grid>
                                <Grid item>
                                    <FormikInput
                                        type="color"
                                        name="botBubbleColor"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item wrap="nowrap" direction="row" alignItems="center" justify="space-between">
                                <Grid item>
                                    {t("Nutzer")}
                                </Grid>
                                <Grid item>
                                    <FormikInput
                                        type="color"
                                        name="customerBubbleColor"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={1} className={classes.settingsGroupPaper} style={{marginBottom: 0}}>
                        <Grid container item className={classes.header} wrap="nowrap" direction="row" alignItems="center" justify="space-between" style={{height: "46px"}}>
                            {t("Bot schreibt Animation")}
                        </Grid>
                        <Grid container item wrap="nowrap" direction="column" className={classes.settingsGroup}>
                            <Grid container item wrap="nowrap" direction="row" alignItems="center" justify="space-between">
                                <Grid item style={{width: "100%", marginBottom: "16px"}}>
                                    <FormikInput
                                        type="enum"
                                        name="typing"
                                        variant="outlined"
                                        typeParams={["Pulse", "Flashing", "Typing"]}
                                        label={t("Animation")}
                                        formControlProps={{margin: "0"}}
                                        labelProps={{
                                            variant: "outlined"
                                        }}
                                    />    
                                </Grid>
                            </Grid>
                            <Grid container item wrap="nowrap" direction="row" alignItems="center" justify="space-between">
                                <Grid item>
                                    {t("Vorschau")}:
                                </Grid>
                                <Grid item style={{paddingRight: "14px"}}>
                                    <div style={{display: "flex", justifyContent: "right", height: "20px", alignItems: "center" }}>
                                        <TypingAnimation settings={state.chatSettings}/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </PerfectScrollbar>
            <Grid container direction="row" alignItems="center" justify="space-between" className={classes.chatStyleComponentFooter}  ref={refBottom}>
                <Tooltip title={t("Änderungen anwenden")!}>
                    <Button type="submit" variant="contained" disableElevation color="success" size="small" style={{marginRight: "8px"}}><Fa icon={["far", "check"]} mr={1}/> {t("Anwenden")}</Button>
                </Tooltip>
                <Tooltip title={t("Chat-Design zurücksetzen")!}>
                    <Button variant="contained" disableElevation color="secondary" size="small" style={{minWidth: "30px", width: "35px"}}><Fa icon={["far", "rotate-left"]} /></Button>
                </Tooltip>
            </Grid>
        </div>
    )
}

export default ChatSettings

/**
 * pixi an/aus
 * farbe des hintergrunds
 * farbe der bubbles => schriftfarbe dann auch?
 * typing animation
 */