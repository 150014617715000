import React, { useEffect, useState } from "react"
import { DialogContent, Dialog, makeStyles, Grid, AppBar, Tabs, Tooltip, Tab, Box } from "@material-ui/core"
import DialogTitle from "dialogs/DialogTitle"
import Button from "components/Button/Button"
import { useTranslation } from "react-i18next";
import images from "models/images"
import { Fa, FaButton } from "styled/muiComponents";
import ErrorDialog from "dialogs/ImageUploadErrors"
import { useAlert } from "hooks/useAlert"
import { useDialog } from "hooks/useDialog";
import { useTariff } from "hooks/useTariff";
import useSession from "hooks/useSession"

const useStyles = makeStyles(theme => ({
    cardContainer: {
        width: "400px",
        height: "50px",
        borderRadius: "5px",
        cursor: "pointer",
        outline: "none",
        //boxSizing: "borderBox",
    },
    img: {
        width: "150px",
        height: "150px",
        outline: "none"
    },
    dialog: {
        //width: "100%"
    },
    title: {
        backgroundColor: theme.palette.primary.light,
        color: "white"
    },
    divider: {
        backgroundColor: theme.palette.companyRed.main,
        height: "2px"
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.primary.light}`,
    },
    paper: ({
        width: "200px",
        margin: "4px",
        wordWrap: "break-word"

    }),
    highlight: {
        outline: "10px solid red"
    },
    img: {
        height: "100%",
        width: "100%",
    },
    names: {
        color: "black"
    },
    icon: {
        color: theme.palette.primary.main,
        transition: "0.35s all ease",
        "&:hover": {
            color: theme.palette.companyRed.main
        },
    },
    imgContainer: {
        position: "relative",
        height: "200px",
        width: "200px"
    },
    iconBar: {
        position: "absolute",
        top: "20px",
        justifyContent: "space-evenly",
        backgroundColor: "rgba(26,30,45, 0.2)", //theme.palette.companyDark.light
    },
    LabelGridItem: {
        flex: "1 1",
        cursor: "context-menu"
    },
    ButtonGridItem: {
        flex: "0 1",
        "& button": {
            padding: "0px",
            color: "white"
        }
    },
}))

// type TabPanelProp = {
//     value: number,
//     index: number,
//     children?: React.ReactNode
// }

function TabPanel({ value, index, children }) {

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
        >
            {value === index && (
                <Box p={3} >
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const UploadLogoDialog = ({autosize, ...props}) => {
    const {input:{name, value, ...field} } = props
    const classes = useStyles()
    const { t } = useTranslation()    
    const { dialog } = useDialog()
    const { permissions } = useTariff()
    const { alert } = useAlert()

    const [open, setOpen] = useState(false)
    const [imageList, setImageList] = useState(null)
    const [page, setPage] = useState(1)
    const [tabIndex, setTabIndex] = useState(0)
    const [image, setImage] = useState({filename: "empty"})
    const [logo, setLogo] = useState(null)
    const [newLogoId, setNewLogoId] = useState(null)
    const {logo: currentLogo, restore} = useSession()


    useEffect(() => {
        const fetchImages = async () => {
            const list = await images.getList(page)
            console.log(list)
            setImageList(list.data)
        }
        const fetchLogo = async () => {
            const response = await images.getLogo()
            setLogo(response)

        }
        fetchImages(); fetchLogo()
    },[page])

    const handleChangeTabIndex = (_, newValue) => {
        setTabIndex(newValue)
    }

    const handleSelectNewLogo = async () => {
        await images.selectNewLogo(newLogoId)
        images.getLogo()
        .then((r) => {setLogo(r); selectImage({image: r})})
        restore()
        
    }

    const handleUploadFile = async () => {
        if (image === undefined) {
            return
        }
        if (!image) {
            return
        }
       if (image.size > 2097152) {
            dialog.show({
                component: ErrorDialog,
                props: {
                    errorType: "size",
                }
            })
        } else if (image.type.match(/(jpg|jpeg|gif|png)/) === null) {
            dialog.show({
                component: ErrorDialog,
                props: {
                    errorType: "format"
                }
            })
        } else {
            const data = new FormData()
            data.set("image", image)
            data.set("logo", true)
            try {
                const uploadedImage = await images.create(data)
                document.getElementById("image").value = null
                //setUploadedImage(image)
                const logo = await images.get(uploadedImage.id)
                selectImage({image: logo.url})
                alert.success(t("Das Bild wurde erfolgreich hochgeladen und wird nun als Logo benutzt."))
                restore()
                return uploadedImage
            } catch (error) {
                alert.error(t("Das Bild konnte nicht hochgeladen werden."))
                return "error"
            }
        }
    }

    const selectImage = ({image}) => {
        props.onChange({target: {value: image, name: name}})
        field.onChange({target: {value: image, name: name}})
        setOpen(false)
    }
       

    const handleChangeFile = (e) => {
        setImage(e.nativeEvent.target.files[0])
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }


    console.log(image)
    
    // TODO ICONS FOR TABS
    return (
        <>
            <Grid container direction="row" alignItems="center" >
                <Grid item className={classes.LabelGridItem}>
                    {props.label}
                </Grid>

                <Grid item className={classes.ButtonGridItem}>
                    <FaButton onClick={handleOpen} style={{ margin: "6px" }}>
                        <Fa icon={["fal", "image"]} />
                    </FaButton>
                </Grid>
            </Grid>

        <Dialog open={open} onClose={handleClose} className={classes.dialog} maxWidth="md" fullWidth>
            <DialogTitle onClose={handleClose} className={classes.title}>
                {t("Logo")}
            </DialogTitle>
            <DialogContent>
                
                <AppBar position="static" color="default">
                    <Tabs
                        indicatorColor="primary"
                        variant="fullWidth"
                        value={tabIndex}
                        onChange={handleChangeTabIndex}
                        className={classes.tabs}
                    >
                        <Tooltip title={t("Logo auswählen")}>
                            <Tab icon={<Fa icon={["fal", "industry-alt"]} />} {...a11yProps(0)} />
                        </Tooltip>
                        <Tooltip title={t("Logo hochladen")}>
                            <Tab icon={<Fa icon={["fal", "user-tie"]} />} {...a11yProps(1)} />
                        </Tooltip>
                    </Tabs>
                </AppBar>
                <TabPanel value={tabIndex} index={0} >
                    {t("Derzeitiges Logo")}
                    <img src={`${currentLogo}`} alt="your logo"></img> {/*get imageid of logo somehwere beforehand*/ }
                    {/* <Button variant="outlined" onClick={handleUploadFile}>{("Neues Logo hochladen")}</Button> */}
                    <form>
                        <input type="file" name="image" id="image" onChange={handleChangeFile} />
                    </form>
                    <Button onClick={handleUploadFile} variant="contained" color="primary" disabled={!permissions.image_volume}>{t("Speichern")}</Button>

                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    {t("Logo auswählen")}
                    <ImageList images={imageList} setNewLogoId={setNewLogoId}/>
                    <Button onClick={handleSelectNewLogo}>Ausgewähltes Bild als Logo nutzen</Button>
                </TabPanel>
            </DialogContent>
        </Dialog>
        </>
    )
}

export default UploadLogoDialog

const ImageList = ({images, setNewLogoId}) => {
    const { t } = useTranslation()
    const [highlight, setHighlight] = useState(null)
    const classes = useStyles()
    
    const handleSelectImage = (id) => {
        setHighlight(id)
        setNewLogoId(id)
    }

    console.log(images)

    if (!images) {
        return <p>loading</p>
    }

    return (
        <Grid container direction="row">
            {images.map((image, idx) =>
                <Grid item key={idx}>
                    <Grid container direction="column" className={classes.imgContainer} 
                    // onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
                    >
                        
                    <img className={classes.img} src={`/api/images/${image.id}/getImage`} id={image.id} alt={t("Hochgeladenes Bild")} onClick={() => handleSelectImage(image.id)} style={{border: highlight === image.id ? "red solid 2px" : "none"}}/>

                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}