import React, { useState, useEffect, useRef } from 'react'
import { TextField, TextFieldProps } from '@material-ui/core';

export type Props = {
    delay?: number,
    onChange: (value: string) => void,
    value: string
} & Omit<TextFieldProps, "onChange" | "string">

const DelayedTextField: React.FC<Props> = ({ onChange, value, delay=500, ...props}) => {
    const [ internalValue, setInternalValue ] = useState<string>(value)
    const internalValueRef = useRef(internalValue)
    internalValueRef.current = internalValue

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(e.target.value)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (value !== internalValueRef.current) {
                onChange(internalValueRef.current)
            }
        }, delay)
        return () => clearTimeout(timer)
    }, [internalValue, delay, onChange, value])

    return (
        <TextField
            onChange={handleChange}
            value={internalValue || ""}
            variant={props.variant as any}
            {...props}
        />
    )
}

export default DelayedTextField 