import { withCrud, CrudModel, BaseModel } from "./builder";
import { post, get } from "api/crud"

export type ImprintApi = CrudModel<any> & BaseModel // TODO TYPING

export default {
    name: "imprints",
    ...withCrud<any>("imprints"),

    updateImprint: (address: any) => post(`imprint/update_imprint`, {address}),
    
    getFirst: () => get<any>('imprints/get_first')
}