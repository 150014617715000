import React from "react"
import { useTranslation } from "react-i18next"
import { DialogProps } from "hooks/useDialog"
import { Dialog, Grid } from "@material-ui/core"
import DialogTitle from "dialogs/DialogTitle"
import DialogContent from "dialogs/DialogContent"
import DialogActions from "dialogs/DialogActions"
import Button from "components/Button/Button"
import { Fa } from "styled/muiComponents"
import LoadingAnimation from "components/LoadingAnimation"

type Props = {
    webforms: number[]
}

const ConfirmChatRemoveDialog: React.FC<Props & DialogProps> = ({onClose, onError, open, webforms}) => {
        const { t } = useTranslation()
        // const classes = useStyles({})
    
        const handleClose = () => {
            onClose({delete: false})
        }

        const handleDelete = () => {
            onClose({delete: true})
        }

        if(!webforms){
            return <LoadingAnimation/>
        }
        //TODO name der webforms zeigen
        return (
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle onClose={handleClose}>
                    {t("Chat wird in Anmeldestrecke benutzt")}
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column">
                        {t("Dieser Chat wird in einer deiner Anmeldestrecken benutzt. Solltest du den Chat löschen, wird der Chat auch automatisch aus den Anmeldestrecken entfernt")}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justify="space-between">
                        <Button onClick={handleClose} variant="contained" color="error"><Fa icon={["fas", "times"]} mr={1} size={"lg"} /> {t("/common:Abbrechen")}</Button>
                        <Button onClick={handleDelete} variant="contained" color="success"><Fa icon={["fas", "floppy-disk"]} mr={1} /> {t("Löschen")}</Button>
                    </Grid>
                </DialogActions>
            </Dialog>
    )
}

export default ConfirmChatRemoveDialog