import React from "react"
import { Dialog } from '@material-ui/core'
import DialogTitle from '../../../../dialogs/DialogTitle'
import DialogContent from '../../../../dialogs/DialogContent'
import DialogActions from '../../../../dialogs/DialogActions'
import Button from '../../../../components/Button/Button'
import { IRecipientTag } from '../../../../types/recipient'
import { DialogProps } from '../../../../hooks/useDialog'
import { useTranslation } from 'react-i18next'
import FormikInput from '../../../../components/formik/FormikInput'
import { Form } from "react-final-form"
import recipientApi from '../../../../models/recipient'
import { Fa, Grid } from '../../../../styled/muiComponents'

export type Response = IRecipientTag[] | null

export type Props = {
    recipientId: number
}

type Values = {
    tagName: string
}

const initialValues: Values = {
    tagName: ""
}

const AddRecipientTag = ({ open, onClose, onError, recipientId }: Props & DialogProps) => {
    const { t } = useTranslation()

    const handleSubmit = async (values: Values) => {
        try {
            await recipientApi.one(recipientId).addTags([values.tagName])
            const tags = await recipientApi.one(recipientId).tags()
            onClose(tags)
            return Promise.resolve()
        }catch(e) {
            console.error(t("Tag konnte nicht hinzugefügt werden"))
        }
    }

    const handleClose = () => {
        onClose(null)
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle onClose={handleClose}>
                <Fa icon={["fal", "user-tag"]} mr={1.75} />{t("Tag hinzufügen")}
            </DialogTitle>
            <Form initialValues={initialValues} onSubmit={handleSubmit} 
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            {t("Gib hier den Tag ein, den du hinzufügen möchtest. Beachte bitte, dass Leerzeichen nicht erlaubt sind.")}
                            <FormikInput
                                name="tagName"
                                autoFocus
                                variant="outlined"
                                fullWidth
                                type="string"
                                label={t("Name des Tags")}
                                typeparams={null}
                                required
                            />
                        </DialogContent>
                        <DialogActions>
                            <Grid container justify="space-between">
                                <Button  variant="contained" size="small" color="error" onClick={handleClose}>
                                    <Fa icon={["far", "times"]} mr={1} />{t("Abbrechen")}
                                </Button>
                                <Button variant="contained" size="small" color="success" type="submit" isLoading={submitting}>
                                    <Fa icon={["far", "check"]} mr={1} />{t("Tag hinzufügen")}
                                </Button>
                            </Grid>
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog >
    )
}

export default AddRecipientTag