import React, { useState, useEffect } from "react"
import { SetupNavigationProps, Navigation, ISetupInformation } from "../SetupWizard"
import { Form } from 'react-final-form'
import { Typography, Grid, Fa } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { useTranslation } from "react-i18next"
import DelayedTextField from "components/DelayedTextField"
import domain from "models/domain"
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';

type Props = {
    navigationProps: SetupNavigationProps
    classes: any
    handleChange: (event: any) => void
    setupInformation: ISetupInformation
}

const DomainName = ({ navigationProps, classes, handleChange, setupInformation }: Props) => {
    const { t } = useTranslation()
    const [enableNext, setEnableNext] = useState<boolean>(false)
    const [domainAvailable, setDomainAvailable] = useState(false)
    const [error, setError] = useState({ bannedChar: false, tooShort: false })
    const handleSubmit = () => {

    }

    useEffect(() => {
        if(setupInformation.domain){
            setDomainAvailable(true)
        }
    },[navigationProps.step])

    const initialValues = {
        domain: setupInformation.domain
    }

    const handleChangeDomain = async (e: string) => {
        const subdomain = e.toLowerCase()
        const subdomainRegex = /^[A-Za-z0-9_-]*$/


        handleChange({ target: { name: "domain", value: subdomain } })
        if (subdomain.length === 0) {
            setError({ bannedChar: false, tooShort: false })
            setEnableNext(false)
            setDomainAvailable(false)
            return
        }
        if (subdomain.length < 3) {
            if (subdomainRegex.test(subdomain)) {
                setError({ bannedChar: false, tooShort: true })
                setEnableNext(false)
                setDomainAvailable(false)
                return
            }
            setError({ ...error, tooShort: true })
            setEnableNext(false)
            setDomainAvailable(false)
            return
        }

        if (!subdomainRegex.test(subdomain)) {
            setError({ bannedChar: true, tooShort: false })
            setEnableNext(false)
            setDomainAvailable(false)
        } else {
            const available = await domain.available(e.toLowerCase())
            setError({ tooShort: false, bannedChar: false })
            // @ts-ignore TODO why is domain.available typed to return vo
            if (available === "available") {
                setDomainAvailable(true)
                setEnableNext(true)
            } else {
                setDomainAvailable(false)
                setEnableNext(false)
            }
        }
    }
    const [hasFocus, setFocus] = useState(false);

    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}
            render={({ handleSubmit, submitting}) => (
                <Grid container item direction="column" className={classes.root}>
                    <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" justify="flex-start">
                                <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                    <Grid item sm={4}>
                                        <div style={{ maxWidth: "150px" }}>
                                            <Pixi size="full" pose="waiting"></Pixi>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <div className={classes.headerDescription} >
                                        <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                            <Typography variant="h5" className={classes.pixiSpeech}>
                                                {t("Wie soll deine Cornerstone-Domain lauten? Wähle am besten einen Namen, den deine Kunden wiedererkennen. Unter dieser Domain wird später zum Beispiel dein Anmeldeformular erreichbar sein.")}
                                            </Typography>
                                        </Typist>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item container className={classes.formContent}>
                                    <Grid item xs={12} mt={1}>
                                        <DelayedTextField
                                            name="domain"
                                            value={setupInformation.domain}
                                            onChange={handleChangeDomain}
                                            label={t("Domain *")}
                                            variant="outlined"
                                            fullWidth
                                            error={!domainAvailable && setupInformation.domain !== ""}
                                            //color={domainAvailable && setupInformation.domain !== "" ? "secondary" : "secondary"}
                                            InputProps={
                                                {
                                                    onFocus:(() => setFocus(true)),
                                                    onBlur:(() => setFocus(false)),                                                    
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            { hasFocus ? setupInformation.domain !== "" ? domainAvailable && setupInformation.domain !== "" ? <Fa icon={["fas", "check"]} className={classes.success}/> : <Fa icon={["fas", "times"]} className={classes.error}/> : <Fa icon={["fas", "globe"]}/> : setupInformation.domain !== "" ? domainAvailable && setupInformation.domain !== "" ? <Fa icon={["fas", "check"]} className={classes.success}/> : <Fa icon={["fas", "times"]} className={classes.error}/> : <Fa icon={["fal", "globe"]}/>}
                                                        </InputAdornment>
                                                    ),
                                                }
                                            }
                                            //aria-describedby="component-helper-text"
                                            helperText={
                                                setupInformation.domain !== "" ?
                                                    error.tooShort ?
                                                        t("Die Domain ist zu kurz. Sie muss aus mindestens drei Zeichen bestehen.")
                                                        :
                                                        error.bannedChar ?
                                                            t("Die Domain enthält ungültige Zeichen")
                                                            :
                                                            domainAvailable ?
                                                                t("Klasse, die Domain ist verfügbar.")
                                                                :
                                                                t("Die Domain ist entweder vergeben, oder ist nicht erlaubt. Probiere bitte eine andere.")
                                                            :
                                                            t("Wähle eine Domain von mindestens drei Zeichen Länge. Sonderzeichen, Umlaute, Punkte und Leerzeichen sind nicht erlaubt.")
                                                }
                                        />
                                        <FormHelperText id="component-helper-text">
                                            
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} alignItems="center">
                                        <Typography variant="h2" style={{margin: "24px 0 16px"}}>
                                            {t("Vorschau")}
                                        </Typography>
                                        <div className={"browser--frame-expanded"} style={{height: "auto"}}>
                                            <div className="browser--controls">
                                                <div className="page--controls" style={{marginLeft: "2%"}}>
                                                    <span className="back white--container"><Fa icon={["fas", "chevron-left"]} /></span>
                                                    <span className="next white--container"><Fa icon={["fas", "chevron-right"]} /></span>
                                                </div>
                                                <span className="url--bar white--container">
                                                    https://<strong>{setupInformation.domain}</strong>.cornermail.de
                                                </span>
                                            </div>
                                        </div>                                
                                    </Grid>
                                </Grid>
                                
                                <Grid item sm={12}>
                                    <Navigation {...navigationProps} enableNext={!enableNext}/>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item className={classes.formNote}>
                            <Typography variant="body1" className={classes.requiredNotice}>
                                <i>* {t("Pflichtfeld")}</i>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    )
}

export default DomainName