import FormikInput from "components/formik/FormikInput"
import React from "react"
import { useTranslation } from "react-i18next"
import { useChatState } from "../../ChatContext"
import { Grid, makeStyles, Paper} from "@material-ui/core"
import 'react-perfect-scrollbar/dist/css/styles.css'

const useStyles = makeStyles((theme) => ({
    settingsContainer: {
        maxHeight: "100%",
        height: "100%",
        overflow: "hidden",
        overflowY: "auto",
        width: "100%",
        padding: theme.spacing(2),
        backgroundColor: "#f9f9f9"
    },
    settingsGroup: {
        padding: theme.spacing(2),
        backgroundColor: "#ffffff",
        borderRadius: theme.spacing(1)
    },
    margin: {
        marginBottom: theme.spacing(2)
    },
    settingsGroupPaper: {
        marginBottom: theme.spacing(1.25),
        overflow: "visible",
        width: "100%"
    },
    seperator: {
        width: 0,
        borderRight: "1px dashed rgb(7 12 29)",
        borderLeft: "1px dashed rgb(7 12 29)",
        height: "20px",
        marginBottom: theme.spacing(1),
    }
}))

const FeedbackNodeOptions = ({ node }: any) => {
    const {state} = useChatState()
    const { t } = useTranslation()
    const classes = useStyles()

    return (        
        <Grid container className={classes.settingsContainer} style={{borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px" }}>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <div className={classes.seperator}></div>

                    <FormikInput
                        type="slider"
                        name="delay"
                        max={10}
                        min={0}
                        //defaultValue={2}
                        //defaultValue={node.data.delay ?? 2}
                        // onChange={(e: any) => handleChange(e.target.value, "delay")}
                        valueLabelDisplay="auto"
                        label="s delay"
                        variant="delay"
                    />

                    <div className={classes.seperator}></div>
                    <Paper elevation={2} className={classes.settingsGroupPaper}>
                        <Grid item className={classes.settingsGroup}>
                            <label className="FormikFormLabel">{t("Text")}</label>
                            <FormikInput 
                                type="entityText"
                                name="text"
                                // onChange={(e: any) => handleChange(e.target.value, "text")}
                                typeParams={state.fields}
                                //defaultValue={node.data.text}
                            />
                            {t("Ab wie viel Sternen soll der Chat weiter zu den Bewertungsportalen führen?")}
                            <FormikInput
                                type="slider"
                                max={4}
                                min={3}
                                name="split"
                                label={t("Rating")}
                                // onChange={(e: any) => handleChange(e.target.value, "split")}
                                //defaultValue={node.data.split}
                                valueLabelDisplay="auto"
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FeedbackNodeOptions