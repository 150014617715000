import React from "react"
import { DialogProps } from "hooks/useDialog"
import { useTranslation } from "react-i18next"
import { makeStyles, Theme, DialogActions, Dialog, Divider } from "@material-ui/core"
import DialogTitle from "dialogs/DialogTitle"
import Button from "components/Button/Button"
import { Typography } from "styled/muiComponents"


const useStyles = makeStyles<Theme, {}>(theme => ({
    title: {
        backgroundColor: theme.palette.primary.light,
        color: "white"
    },
    divider: {
        backgroundColor: theme.palette.companyRed.main,
        height: "2px"
    },
    card: {
        marginBottom: theme.spacing(1),
        display: "flex",
        justifyContent:"center",
        cursor:"pointer"

    }
}))
type Props = {

}

const DeleteNodeDialog:React.FC <Props & DialogProps> = ({onClose, onError, open}) => {

    const { t } = useTranslation()
    const classes = useStyles({})

    const handleClose = () => {
        onClose({})
    }

    const handleDelete = () => {
        onClose({success:true})
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle onClose={handleClose} className={classes.title}>
                {t("Löschen einer Node")}
            </DialogTitle>
            <Divider className={classes.divider} />

            <Typography>
                {t("Alle nachfolgenden Nodes werden damit auch gelöscht. Bist du dir sicher?")}
            </Typography>
            <Divider className={classes.divider} />
            <DialogActions>
                <Button variant="contained" color="success" onClick={handleDelete}>{t("Löschen")}</Button>
                <Button variant="contained" color="error" onClick={handleClose}>{t("Abbrechen")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteNodeDialog