import { withCrud, CrudModel, BaseModel } from "./builder";

export type ITemplate = {
  id: number
  name: string
  from_cornerstone: boolean
  subject: string
  mime_type: "multi" | "html" | "text"
  body_plain: string
  body_html: string
  client_format: any // this is the complete Editorstate of Campaign.Plus Template Editor, typing that is way out of scope and not necessary as the lib is currently in pure JS
}

export type WebformApi = CrudModel<ITemplate> & BaseModel

export default {
    name: "templates",
    ...withCrud<ITemplate>("templates")
}