import React from "react"
import { Grid, makeStyles, Theme, Tooltip } from "@material-ui/core"
import { FaButton, Fa } from "styled/muiComponents"
import { useTranslation } from "react-i18next"
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles<Theme>(theme => ({
    icon: {
        padding: theme.spacing(0.5),
        height: "30px",
        width: "30px",
        transition: ".3s all ease",
        "&:hover": {
            color: theme.palette.primary.main
        }
    }
}))

const Controls = ({index, handleRerender, fields, mutators, fieldName}: any) => {
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <Grid container item direction="row">
            <Grid item>
                <Tooltip title={t("Nach oben verschieben")!} TransitionComponent={Zoom}>
                    <FaButton size="small" disabled={index === 0} className={classes.icon} onClick={() => { handleRerender(); mutators.move(fieldName, index, index - 1) }}>
                        <Fa icon={["fal", "arrow-up"]} fixedWidth size="xs"/>
                    </FaButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title={t("Nach unten verschieben")!} TransitionComponent={Zoom}>
                    <FaButton size="small" disabled={index === fields.length - 1} className={classes.icon} onClick={() => { handleRerender(); mutators.move(fieldName, index, index + 1) }}>
                        <Fa icon={["fal", "arrow-down"]} fixedWidth size="xs"/>
                    </FaButton>
                </Tooltip>
            </Grid>
            <Grid item className="tooltip-red">
                <Tooltip title={t("Löschen")!} TransitionComponent={Zoom}>
                    <FaButton size="small" className={classes.icon} onClick={() => {handleRerender(); fields.remove(index)}}>
                        <Fa icon={["fal", "trash"]} fixedWidth size="xs"/>
                    </FaButton>
                </Tooltip>
            </Grid>
        </Grid>
    )
}

export default Controls