import React, { useState, useEffect } from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import useWindowSize from '../../hooks/useWindowSize'
import { Theme } from '@material-ui/core';


const useStyles = makeStyles<Theme, { windowWidth: number, windowHeight: number, bgHeight: number }>((theme) => ({
  root: {
    height: "100%",
    flexGrow: 1,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    background: `url(https://source.unsplash.com/collection/10878301/1600x900?sig=${Date.now()})`,
    backgroundPosition: "center",
    padding: ` ${theme.spacing(14)}px`,
    boxSizing: "border-box",
    transition: "padding 0.5s ease",
    '@media (max-width: 1280px), (max-height: 768px)': {
      padding: `${theme.spacing(9)}px`,
    },
    '@media screen and (max-width: 1024px), (max-height: 667px)': {
      padding: `${theme.spacing(5)}px`,
    },
    '@media screen and (max-width: 667px)': {
      padding: ` ${theme.spacing(4)}px`,
    },
    '@media screen and (max-width: 450px)': {
      padding: ` ${theme.spacing(3)}px`,
    },
    '@media screen and (max-width: 350px)': {
      padding: ` ${theme.spacing(2)}px`,
    },
    '@media (max-width: 300px), (max-height: 500px)': {
      padding: "0px",
    }
  },
  wrapper: {
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1), 0px 0px 25px 0px rgba(0,0,0,0.20)",
    height: `calc(100% - ${theme.spacing(28)}px)`,
    width: `calc(100% - ${theme.spacing(28)}px)`,
    boxSizing: "border-box",
    background: "inherit",
    backgroundSize: ({ bgHeight }) => `auto ${bgHeight}px`,
    backgroundPosition: "inherit",
    position: "absolute",
    borderRadius: "15px",
    overflow: "hidden",
    transition: "height 0.5s ease, width 0.5s ease",
    '@media screen and (max-width: 1280px), (max-height: 768px)': {
      height: `calc(100% - ${theme.spacing(18)}px)`,
      width: `calc(100% - ${theme.spacing(18)}px)`,
    },
    '@media screen and (max-width: 1024px), (max-height: 667px)': {
      height: `calc(100% - ${theme.spacing(10)}px)`,
      width: `calc(100% - ${theme.spacing(10)}px)`,
    },
    '@media screen and (max-width: 667px)': {
      height: `calc(100% - ${theme.spacing(8)}px)`,
      width: `calc(100% - ${theme.spacing(8)}px)`,
    },
    '@media screen and (max-width: 450px)': {
      height: `calc(100% - ${theme.spacing(6)}px)`,
      width: `calc(100% - ${theme.spacing(6)}px)`,
    },
    '@media screen and (max-width: 350px)': {
      height: `calc(100% - ${theme.spacing(4)}px)`,
      width: `calc(100% - ${theme.spacing(4)}px)`,
    },
    '@media screen and (max-width: 300px), (max-height: 500px)': {
      height: "100%",
      width: "100%",
      borderRadius: "0px"
    },
    '@media screen and (max-height: 500px)': {
      overflow: "auto"
    },
    "& p": {
      marginLeft: "0"
    }
  },
  inner: {
    backgroundColor: "#ffffff",
    minWidth: "435px",
    transition: "all 0.5s ease",
    '@media screen and (max-width: 768px)': {
      width: '100%',
      maxWidth: "100%",
      minWidth: "100%"
    },
    '@media screen and (max-height: 435px)': {
      width: '100%',
      maxWidth: "100%",
      minWidth: "100%"
    },
  },
  BgWrapper: {
    position: "absolute",
    top: "0",
    left: "0",
    background: "inherit",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  bgRed: {
    backgroundColor: "red"
  },
  blurBg: {
    width: "calc(100% + 20px)",
    height: "calc(100% + 20px)",
    marginTop: "-10px",
    marginLeft: "-10px",
    background: "inherit",
    filter: "blur(8px)",
    '-ms-filter': "blur(8px)",
    '&:before': {
      backgroundColor: "#000000",
      opacity: "0.15",
      content: "''",
      width: "100%",
      height: "100%",
      display: "block",
    },
  },
}))

const useContentStyles = makeStyles(theme => ({
  root: {
      padding: theme.spacing(6),
      height: "100%",
      backgroundColor: "white",
      '@media screen and (max-width: 450px)': {
        padding: `${theme.spacing(3)}px`,
      },
      '@media screen and (max-width: 350px)': {
        padding: ` ${theme.spacing(2)}px`,
      },
    },
    logo: {
      width: "100%",
      maxWidth: "200px",
      marginBottom: theme.spacing(4)
    },
    input: {
      width: "100%"
    },
    regpw: {
      position: "absolute",
      bottom: "48px",
      '@media screen and (max-height: 667px)': {
        marginBottom: "0px",
        marginTop: `${theme.spacing(4)}px`,
        position: "relative",
        bottom: "unset",
      }
    }
}))

const imageDimension = {
  width: 1600,
  height: 900
}

export type IClasses = {
  root: string
  logo: string
  regpw:string
  input:string
}

type Props = {
  children: (args: {classes: IClasses}) => React.ReactNode | null
}

const Auth = ({ children }: Props) => {
  const { windowWidth, windowHeight } = useWindowSize()
  const [bg, setBg] = useState<{width:number, height:number}>({width:0, height:0})


  useEffect(() => {
    if(windowWidth / windowHeight > imageDimension.width / imageDimension.height) {
      const factor = (windowWidth+20) / imageDimension.width
      setBg({
        width: imageDimension.width * factor,
        height: imageDimension.height * factor
      })
    }else {
      const factor = (windowHeight+20) / imageDimension.height
      setBg({
        width: imageDimension.width * factor,
        height: imageDimension.height * factor
      })
    }
  }, [ windowHeight, windowWidth])

  const classes = useStyles({windowWidth, windowHeight, bgHeight: bg.height})
  const contentClasses = useContentStyles()

  return (
    <Grid className={classes.root}>
      <Grid className={classes.BgWrapper}>
        <Grid className={classes.blurBg}>
        </Grid>
      </Grid>
      <Grid className={classes.wrapper} container direction="row" justify="flex-start" spacing={0}>
        <Grid item className={classes.inner} xs={3}>
          { children({classes: contentClasses}) }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Auth