import React, { useEffect, useState, useRef } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { Document, Page, pdfjs } from "react-pdf"
import { QRCode } from "react-qrcode-logo"
import { Box, Fa, FaButton } from "styled/muiComponents"
import {makeStyles, Grid} from "@material-ui/core"
import PickWebformDialog from "./wizard/dialogs/PickWebformDialog"
import { useDialog } from "hooks/useDialog"
import webforms from "models/webform"
import PerfectScrollbar from 'react-perfect-scrollbar'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.light,
        height: "100%"
    },
    icons: {
        color: "white",
        transition: "0.35s all ease",
        "&:hover": {
            color: theme.palette.companyRed.main
        }
    },
    editorPreviewWrapper: {
        height: "100%",
        maxHeight: "100vh",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    navBar: {
        backgroundColor: theme.palette.primary.main
    }
}))

const ViewPDF = ()  => {
    const location = useLocation()
    const template = location.state?.template
    const [qrConfig, setQrConfig] = useState(location.state.template.config.QR)
    
    const pageScale=[1.0, 1.5, 2.0, 2.5, 3.0, 3.5]
    const [documentBlob, setDocumentBlob] = useState()
    const [imgURL, setImgURL] = useState<any>()
    const classes = useStyles()
    const history = useHistory()
    const { dialog } = useDialog()


    if (!template) {
        history.go(-1)
    }
    new QRCode(qrConfig).render()
    useEffect(() => {
        const qrelem = document.getElementById("react-qrcode-logo")
        //@ts-ignore
        setImgURL(qrelem?.toDataURL())
    }, [qrConfig])

    const handleDocument = (url: any) => {
        setDocumentBlob(url)
    }


    useEffect(() => {
        const getTemplate = async () => {
            try {
                const fetchedTemplate = await import(`../qrStoreDisplay/PDFTemplates/templates/template_${template.key}/index`)
                fetchedTemplate.Template({ imgURL, userText: template.config.editUserText, userStyles: template.config.editUserStyles, handleDocument, settings: template.settings })
            } catch (e) {
                // throw error
            }
        }
        getTemplate()
    }, [imgURL, template, qrConfig])

    const handleBack = () => {
        history.go(-1)
    }

    const handleEdit = () => {
        history.push(`/app/stock/qrforms/${template.id}`, {template: template, step: 1})
    }

    const download = () => {
        const element = document.getElementById("download")
        if (element) {
            element.click()
        }
    }

    const handleDownload = () => {
        dialog.show({
            component: PickWebformDialog,
            props: {
                prepare: false
            }
        }).then( async (response) => {
            if(response.success){
                const link = await webforms.getFormUrl(response.webform)
                setQrConfig({...qrConfig, value: link })
                setTimeout(() => {download()} ,2000)
            }
        })
    }
    
    const [wrapperHeight, setWrapperHeight] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current !== null) {
            setWrapperHeight(ref.current.clientHeight);
        }
    }, []);

    return (
        <Box className={classes.root}>
            <div style={{ display: "none" }}>
                <QRCode  {...qrConfig} />
            </div>
            <Grid container direction="column" alignItems="center" wrap="nowrap" style={{height: "100%"}}>
                <Grid item container direction="row" justify="space-between" className={classes.navBar}>
                    <Grid item>
                        <FaButton className={classes.icons} onClick={handleBack}>
                            <Fa icon={["fal", "long-arrow-left"]} />
                        </FaButton>
                    </Grid>
                    <Grid item >
                    <a id="download" href={documentBlob} download={template.name} style={{ display: "none", textDecoration: "none" }} />
                            <FaButton onClick={handleDownload} className={classes.icons} >
                                <Fa icon={["fal", "download"]} />
                            </FaButton>

                        <FaButton className={classes.icons} onClick={handleEdit}>
                            <Fa icon={["fal", "pencil"]} />
                        </FaButton>
                    </Grid>
                </Grid>
                
                    <Grid item className={classes.editorPreviewWrapper}>
                        <PerfectScrollbar style={{width: "100%", display: "flex", justifyContent: "center", boxSizing: "border-box", padding: "24px"}}>
                            <Grid container justify="center" alignItems="center" style={{width:"100%", height: "100%"}}>
                                <Document file={documentBlob}>
                                    <Page pageIndex={0} renderMode="svg" scale={pageScale[2] / template.settings.calc} />
                                </Document>
                            </Grid>
                        </PerfectScrollbar>
                    </Grid>
                
            </Grid>

        </Box>
    )
}

export default ViewPDF