import { isNode } from 'react-flow-renderer'
import dagre from 'dagre';

export const spread = (elements) => {

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    dagreGraph.nodesep = 200
    const nodeWidth = 150;
    const nodeHeight = 34;
    dagreGraph.setGraph({ rankdir: "LR" })

    elements.forEach((el) => {
        if (isNode(el)) {

            dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
        } else {
            dagreGraph.setEdge(el.source, el.target);
        }
    });

    dagre.layout(dagreGraph)

    elements.forEach((ele, index) => {
        dagreGraph.nodes().forEach((node) => {
            if (ele.id === node) {
                elements[index] = { ...elements[index], position: { ...elements[index].position, y: dagreGraph.node(node).y, x: dagreGraph.node(node).x } }
            }
        })
    })

    return elements
}