import React from 'react'
import PropTypes from 'prop-types'

const Integer = ({ value }) => (
    <span>{ value.toLocaleString() }</span>
)

Integer.propTypes = {
    value: PropTypes.number.isRequired
}

export default Integer
