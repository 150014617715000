import React, { useEffect, useState, useCallback } from "react"
import { MobileStepper, makeStyles, Grid } from "@material-ui/core"
import EditPDF from "./steps/EditPDF"
import PickPDFtemplate from "./steps/PickPDFTemplate"
import Button from "components/Button/Button"
import { Fa, Box } from "styled/muiComponents"
import { IProps as IQRoptions, QRCode } from "react-qrcode-logo"
import { useTranslation } from "react-i18next"
import { stripHtml } from "util/string"
import { useLocation, useHistory, useParams } from "react-router-dom"
import { useAlert } from "hooks/useAlert"
import pdfTemplates from "models/pdfTemplate"
import { CancelButton } from "../../webforms/wizard/WebformWizard"
import { useSidebar } from "hooks/useSidebar"

type IFixedStylesItem ={
    fontSize: number
}

type IUserStylesItem = {
    name: string
    value: string
    type: string
    label: string
}

type IUserTextItem = {
    name: string
    value: string
    type: string
    label: string
}

type IFixedStyles = {
    [key: string]: {
        [key: string]: IFixedStylesItem
    }
}

type IUserStyles = {
    [key: string]: {
        [key: string]: IUserStylesItem
    }
}

type IUserText = {
    [key: string]: {
        [key: string]: IUserTextItem
    }
}

interface IRouteParams {
    id: string
}

export type IPDF = { // TODO Typing
    fixedStyles: any
    initialUserText: any
    initialUserStyles: any
    Template: any
    userTextGroups:any
    userStyleGroups: any
    key: string
}

const useStyles = makeStyles(theme => ({
    wizardwrapper: {
        height:"100%",
        margin: "0px",
        width: "100%",
        overflow: "hidden",
    },
    wizardStepper: {
        color: "white",
        backgroundColor: "transparent",
        padding: theme.spacing(0),
        flexGrow: 1
    },
    Wizardnav:{
        padding: theme.spacing(2),
    },
}))


const PDFWizard = () => {
    const location = useLocation()
    const history = useHistory()
    const { alert } = useAlert()
    const { t } = useTranslation()
    const params = useParams<IRouteParams>()
    const [PDF, setPDF] = useState<IPDF>()
    const { fixedClose } = useSidebar()


    const fetchSavedTemplate = async () => {
        const template = await import(`../PDFTemplates/templates/template_${location.state?.template.key}/index`)
        setPDF(template)
    }
    useEffect(() => {
        if(location.state?.template){
            fetchSavedTemplate()
        }
    },[])

    const [imgURL, setImgURL] = useState<string>("")
    const [editUserText, setEditUserText] = useState(location.state?.template.config.editUserText || null)
    const [editUserStyles, setEditUserStyles] = useState(location.state?.template.config.editUserStyles || null)
    const [ documentBlob, setDocumentBlob ] = useState(null)
    const [settings, setSettings] = useState(location.state?.template.settings || { 
        name: "",
        size: "A6",
        calc: 1,
        type: ""
    })
    const [step, setStep] = useState(location.state?.step || 0)

    const [QR, setQR] = useState<IQRoptions>(location.state?.template.config.QR || {
        value: "www.campaign.plus",
        bgColor: "#ffffff",
        fgColor: "#000000",
        ecLevel: "H",
        size: 700,
        quietZone: 50,
        qrStyle: "squares"
    })
    new QRCode(QR).render()

    useEffect(() => {
        if (PDF && !location.state?.template) { // TODO choosing new template when editing saved one doesnt work with this
            setEditUserText(PDF.initialUserText)
            setEditUserStyles(PDF.initialUserStyles(settings.calc))
        }
    }, [PDF, settings, location.state])

    useEffect(() => {
        const qrelem = document.getElementById("react-qrcode-logo")
        //@ts-ignore
        setImgURL(qrelem?.toDataURL())
    }, [QR])

    useEffect(() => {
        fixedClose()
    },[])

    const handleChangeSize = (event: any) => {
        switch (event.target.value) {
            case "A6": setSettings({ ...settings, size: event.target.value, calc: 1 }); break;
            case "A5": setSettings({ ...settings, size: event.target.value, calc: 1.419 }); break
            case "A4": setSettings({ ...settings, size: event.target.value, calc: 2.007 }); break
            case "A3": setSettings({ ...settings, size: event.target.value, calc: 2.838 }); break
            case "A2": setSettings({ ...settings, size: event.target.value, calc: 4.014 }); break
            default: setSettings({ ...settings, size: "A6", calc: 1 })
        }
    }

    const handleSave = async () => {
        pdfTemplates.create({
            key: PDF?.key,
            settings,
            config: {
                editUserText,
                editUserStyles,
                QR
            },
            name: settings.name,
            type: settings.type,
            size: settings.size
        })
            .then(() => { alert.success(t("Die PDF wurde erfolgreich gespeichert")); history.push("/app/stock/qrforms") })
            .catch(() => alert.error(t("Die PDF konnte nicht gespeichert werden")))
    }

    const handleUpdate = async () => {
        pdfTemplates.update(parseInt(params.id),
            {
                key: PDF?.key,
                settings,
                config: {
                    editUserText,
                    editUserStyles,
                    QR
                },
                name: settings.name,
                type: settings.type,
                size: settings.size
            })
            .then(() => { alert.success(t("Die PDF wurde erfolgreich gespeichert")); history.push("/app/stock/qrforms") })
            .catch(() => alert.error(t("Die PDF konnte nicht gespeichert werden")))
    }

    const onPrevious = () => {
        setStep(step - 1)
    }
    const onNext = () => {
        if (step === 1) {
            if (location.state?.template) {
                handleUpdate()
            } else {
                handleSave()
            }
        } else {
            setStep(step + 1)
        }
    }

    const pickTemplate = (template: any) => {
        setPDF(template)
    }

    const handleChangeQR = useCallback((event: any) => {
        setQR({ ...QR, [event.target.name]: event.target.value })
    }, [QR])

    const handleChangePDFText = useCallback((event: any, groupName: string) => {
        // @ts-ignore TODO typing
        setEditUserText({...editUserText, [groupName]:{...editUserText[groupName], placeholders:{...editUserText[groupName].placeholders, [event.target.name]:{...editUserText[groupName].placeholders[event.target.name], value: stripHtml(event.target.value)} } } })
    }, [editUserText])

    const handleChangePDFStyle = useCallback((event: any, groupName: string) => {
        // @ts-ignore TODO typing 
        setEditUserStyles({...editUserStyles, [groupName]:{...editUserStyles[groupName], placeholders:{...editUserStyles[groupName].placeholders, [event.target.name]:{...editUserStyles[groupName].placeholders[event.target.name], value: event.target.value} } } })
    }, [editUserStyles])

    const handleChangeSettings = useCallback((event: any) => {
        setSettings({ ...settings, [event.target.name]: event.target.value })
    }, [settings])

    const handleChangeType = (type: string) => {
        setSettings({ ...settings, type })
    }

    const renderStep = () => {
        switch (step) {
            case 0:
                return <PickPDFtemplate
                    settings={settings}
                    template={PDF}
                    imgURL={imgURL}
                    pickTemplate={pickTemplate}
                    navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext }}
                    handleChangeSettings={handleChangeSettings}
                    handleChangeType={handleChangeType}
                    handleChangeSize={handleChangeSize}
                />
            case 1:

                if (PDF === undefined) {
                    return <p>loading</p> // TODO Loading animation
                } else {
                    return(
                    <EditPDF
                        PDF={PDF}
                        QR={QR}
                        imgURL={imgURL}
                        navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext }}
                        handleChangeQR={handleChangeQR}
                        handleChangePDFText={handleChangePDFText}
                        handleChangePDFStyle={handleChangePDFStyle}
                        editUserText={editUserText}
                        editUserStyles={editUserStyles}
                        settings={settings}
                        setDocumentBlob={setDocumentBlob}
                        documentBlob={documentBlob}
                        handleChangeSize={handleChangeSize}
                        handleChangeSettings={handleChangeSettings}
                    />
                    )
                }
        }
    }
    const classes = useStyles()
    return (
        <Box className={classes.wizardwrapper}>
            <div style={{display:"none"}}>
              <QRCode {...QR} />
            </div>
            {renderStep()}
        </Box>
    )
}

export type PDFNavigationProps = {
    step: number
    onPrevious: () => void
    onNext:() => void
    templateSelected? : any
}

export const Navigation = ({step, onPrevious, onNext, templateSelected}: PDFNavigationProps) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const location = useLocation()
    return (
    <Grid container className={classes.Wizardnav} justify="flex-start" alignItems="center" direction='row'>
        <CancelButton destination={"qrforms"}/>
        <MobileStepper
            className={classes.wizardStepper}
            steps={2}
            variant="text"
            position="static"
            activeStep={step}
            
            nextButton={
                <Button onClick={onNext} variant="contained" disableElevation color="success" size="small" disabled={(step === 0 && templateSelected) || step === 2} style={{display: step === 2 ? "none" : "flex"}}>
                    { step === 0 ? null : <Fa icon={["fas", "check"]} mr={1} />  }
                    { step === 1 ? t("Speichern") : t("Weiter") }
                    { step === 0 ? <Fa icon={["fas", "chevron-right"]} ml={1}/> : null  }
                </Button>
            }
            backButton={
                !location.state ?
                <Button onClick={onPrevious} disabled={step === 0 || location.state?.step} variant="contained" disableElevation color="success" size="small" >
                    <Fa icon={["fas", "chevron-left"]} mr={1}/>
                    {t("Zurück")}
                </Button>
                : null
            }
            />
            </Grid>
    )
}



export default PDFWizard