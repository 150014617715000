import { makeStyles } from "@material-ui/core";

export const useSnackbarStyles = makeStyles({
    info:{
        backgroundColor:"#007FFF",
        opacity:"0.9"
    },
    warning:{
        //backgroundColor:"#c0a787",
        //opacity:"0.9"
    },
    error:{
        backgroundColor:"#9e7373",
        //opactiy:"0.9"
    },
    success:{
        backgroundColor:"5cb65f",
        //opacity:"0.9"
    }
})