import React, { useState, useRef, useEffect } from "react"
import { Chip, Grid, makeStyles, Theme, Paper } from "@material-ui/core"
import { useChatState } from "../../ChatContext"
import FormikInput from "components/formik/FormikInput"
import { Fa } from "styled/muiComponents"
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FieldArray } from "react-final-form-arrays"
import { useForm } from "react-final-form"
import Controls from "../../ChatComponents"
import { useTranslation } from "react-i18next"
import Grow from '@material-ui/core/Grow'

const useStyles = makeStyles<Theme>(theme => ({
    responseDivWrapper: {
        width: "100%",
        // "&:hover": {
        //     "& $icon": {
        //         opacity: 1
        //     }
        // }
    },
    // icon: {
    //     opacity: 0,
    //     transition: "0.2s opacity ease"
    // },
    settingsWrapper: {
        maxHeight: "100%",
        overflowY: "auto",
        padding: theme.spacing(2)
    },
    settingsContainer: {
        maxHeight: "100%",
        height: "100%",
        overflow: "hidden",
        overflowY: "auto",
        width: "100%",
        padding: theme.spacing(2),
        backgroundColor: "#f9f9f9",
        boxSizing: "border-box",
        display: "flex"
    },
    settingsGroup: {
        padding: theme.spacing(2),
        backgroundColor: "#ffffff",
        borderRadius: theme.spacing(1)
    },
    margin: {
        marginBottom: theme.spacing(2)
    },
    controls: {
        opacity: 0,
        transition: "0.2s opacity ease",
    },
    settingsGroupPaper: {
        marginBottom: theme.spacing(1.25),
        overflow: "visible",
        width: "100%",
        "&:hover": {
            "& $controls": {
                opacity: 1
            }
        }
    },
    seperator: {
        width: 0,
        borderRight: "1px dashed rgb(7 12 29)",
        borderLeft: "1px dashed rgb(7 12 29)",
        height: "20px",
        marginBottom: theme.spacing(1),
    },
    header: {
        backgroundColor: "#E6ECEF",
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        lineHeight: 0
    },
    elementBar: {
        backgroundColor: "#E6ECEF",
        padding: theme.spacing(1),
        width: "100%"
    },
    chip: {
        marginRight: theme.spacing(1),
        transition: "0.3s all ease",
        "&:last-child": {
            margin: 0
        },
        "&:hover": {
            backgroundColor: `${theme.palette.secondary.main} !important`,
            color: "#ffffff",
            borderColor: theme.palette.secondary.main,
        }
    }
}))

const BotResponseNodeOptions = ({ node }: any) => {
    const classes = useStyles()
    const mutators = useForm().mutators

    // swaping responses doesnt trigger rerender, so its done here
    const [forceRerender, setForceRerender] = useState(false)
    const handleRerender = () => {
        setForceRerender(!forceRerender)
    }

    const [elementBarHeight, setElementBarHeight] = useState(0);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current !== null) {
            setElementBarHeight(ref.current.clientHeight);
        }
    }, []);
    const { t } = useTranslation()
    const [hasLength, setLength] = useState(false);
    // TODO function to render every type in map in a different way
    return (
        <Grid container style={{ maxHeight: "100%", height: "100%", overflow: "hidden", borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px" }}>
            <Grid item container ref={ref} direction="row" alignItems="center" className={classes.elementBar}>
                <Fa icon={["fal", "message-plus"]} style={{fontSize: "1.25rem"}} mr={1.75} ml={0.75}/>
                <Chip label={t("Text")} variant="outlined" color="primary" className={classes.chip} onClick={() => { handleRerender(); mutators.push("responses", { type: "text", text: "", delay: 2 }) }} />
                <Chip label={t("Text mit Button")} variant="outlined" color="primary" className={classes.chip} onClick={() => { handleRerender(); mutators.push("responses", { type: "textWithButton", text: "", buttonText: "", delay: 2 }) }} />
                <Chip label={t("Gutschein-Code")} variant="outlined" color="primary" className={classes.chip} onClick={() => { handleRerender(); mutators.push("responses", { type: "coupon", text: "", asBarcode: true, delay: 2 }) }} />
            </Grid>
            <PerfectScrollbar className={classes.settingsContainer} style={hasLength ? { maxHeight: `calc(100% - ${elementBarHeight}px)`, alignItems: "center" } : { maxHeight: `calc(100% - ${elementBarHeight}px)` }}>
                <Grid container direction="column">
                    <Grid item container direction="column" alignItems="center">
                        <FieldArray name="responses" subscription={{ submitting: true }}>
                            {({ fields }) => {
                                if (fields.length === 0) {
                                    setLength(true);
                                    return (
                                        <Grid item style={{ height: "100%" }}>{t("Bitte wähle ein Element aus.")}</Grid>
                                    )
                                } else {
                                    setLength(false);
                                    return fields.map((name: string, index: number) => {
                                        return (
                                            <Grid item className={classes.responseDivWrapper} key={index}>
                                                <ResponseDiv2
                                                    fields={fields}
                                                    index={index}
                                                    name={name}
                                                    handleRerender={handleRerender}
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            }
                            }
                        </FieldArray>
                    </Grid>
                </Grid>
            </PerfectScrollbar>
        </Grid>
    )
}

export default BotResponseNodeOptions

const ResponseDiv2 = ({ fields, index, name, handleRerender }: any) => {
    const classes = useStyles()
    const mutators = useForm().mutators
    const type = useForm().getState().values.responses[index].type
    const { state } = useChatState()
    const { t } = useTranslation()

    /**swaping and moving inputs with fieldarray requires for the inputs to be using the same fields
     * breaks otherwise*/ 
    
    switch (type) {
        case "text":
            return (
                <Grow in={true}>
                    <Grid container direction="column" alignItems="center">
                        <div className={classes.seperator}></div>
                        <FormikInput
                            type="slider"
                            label="s delay"
                            variant="delay"
                            min={0}
                            max={10}
                            marks={true}
                            name={`${name}.delay`}
                        />
                        <div className={classes.seperator}></div>
                        <Paper elevation={2} className={classes.settingsGroupPaper}>
                            <Grid container item className={classes.header} wrap="nowrap" direction="row" alignItems="center" justify="space-between">
                                <Grid item>
                                    {t("Text")}
                                </Grid>
                                <Grid item className={classes.controls}>
                                    <Controls index={index} handleRerender={handleRerender} fields={fields} mutators={mutators} fieldName={"responses"} />
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" justify="center" alignItems="stretch" className={classes.settingsGroup}>
                                <Grid item style={{ width: "100%" }}>
                                    <FormikInput
                                        type="entityText"
                                        name={`${name}.text`}
                                        typeParams={state.fields}
                                    />
                                    <FormikInput
                                        type="text"
                                        name={`${name}.type`}
                                        defaultValue="text"
                                        style={{ display: "none" }}
                                    />
                                    <FormikInput
                                        type="text"
                                        name={`${name}.coupon`}
                                        typeParams={state.fields}
                                        variant="outlined"
                                        label={t("Gutschein-Code")}
                                        style={{ display: "none"}}
                                    />
                                    <FormikInput
                                        type="entityText"
                                        name={`${name}.buttonText`}
                                        typeParams={state.fields}
                                        style={{display: "none"}}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grow>
            )
        case "textWithButton":
            return (
                <Grow in={true}>
                    <Grid container direction="column" alignItems="center">
                        <div className={classes.seperator}></div>
                        <FormikInput
                            type="slider"
                            label="s delay"
                            variant="delay"
                            min={0}
                            max={10}
                            marks={true}
                            name={`${name}.delay`}
                        />
                        <div className={classes.seperator}></div>
                        <Paper elevation={2} className={classes.settingsGroupPaper}>
                            <Grid container item direction="column-reverse">
                                <Grid container item direction="column" justify="center" alignItems="stretch" className={classes.settingsGroup}>
                                    <Grid item style={{ width: "100%" }}>
                                        <label className="FormikFormLabel">{t("Text")}</label>
                                        <FormikInput
                                            type="entityText"
                                            name={`${name}.text`}
                                            typeParams={state.fields}
                                        />
                                        <label className="FormikFormLabel">{t("Button-Text")}</label>
                                        <FormikInput
                                            type="entityText"
                                            name={`${name}.buttonText`}
                                            typeParams={state.fields}
                                        />
                                        <FormikInput
                                            type="text"
                                            name={`${name}.type`}
                                            defaultValue="text"
                                            style={{ display: "none" }}
                                        />
                                        <FormikInput
                                            type="text"
                                            name={`${name}.coupon`}
                                            typeParams={state.fields}
                                            variant="outlined"
                                            label={t("Gutschein-Code")}
                                            style={{ display: "none"}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item className={classes.header} wrap="nowrap" direction="row" alignItems="center" justify="space-between">
                                    <Grid item>
                                        {t("Text mit Button")}
                                    </Grid>
                                    <Grid item className={classes.controls}>
                                        <Controls index={index} handleRerender={handleRerender} fields={fields} mutators={mutators} fieldName={"responses"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grow>
            )

        case "coupon": {
            return (
                <Grow in={true}>
                    <Grid container direction="column" alignItems="center">
                        <div className={classes.seperator}></div>
                        <FormikInput
                            type="slider"
                            label="s delay"
                            variant="delay"
                            min={0}
                            max={10}
                            marks={true}
                            name={`${name}.delay`}
                        />
                        <div className={classes.seperator}></div>
                        <Paper elevation={2} className={classes.settingsGroupPaper}>
                            <Grid container item direction="column-reverse">
                                <Grid container item direction="column" justify="center" alignItems="stretch" className={classes.settingsGroup}>
                                    <Grid item style={{ width: "100%" }}>
                                        <label className="FormikFormLabel">{t("Text")}</label>
                                        <FormikInput
                                            type="entityText"
                                            name={`${name}.text`}
                                            typeParams={state.fields}
                                        />
                                        {/* TODO auswählbar zwischen coupon von server oder hier von hand eigetragen */}
                                        <FormikInput
                                            type="text"
                                            name={`${name}.coupon`}
                                            typeParams={state.fields}
                                            variant="outlined"
                                            label={t("Gutschein-Code")}
                                        />
                                        <FormikInput
                                            type="entityText"
                                            name={`${name}.buttonText`}
                                            typeParams={state.fields}
                                            style={{display: "none"}}
                                        />
                                        <FormikInput
                                            type="text"
                                            name={`${name}.type`}
                                            defaultValue="text"
                                            style={{ display: "none" }}
                                        />
                                        {/* TODO Barcode zum Laufen bringen. Achtung Bug: Wenn Position des Elements in der Node verändert wird, crashed das System.
                                        <FormikInput
                                            type="checkbox"
                                            name={`${name}.asBarcode`}
                                            //defaultValue="text"  
                                            label={t("Barcode verwenden?")}
                                        />
                                        */}
                                    </Grid>
                                </Grid>
                                <Grid container item className={classes.header} wrap="nowrap" direction="row" alignItems="center" justify="space-between">
                                    <Grid item>
                                        {t("Text mit Gutschein")}
                                    </Grid>
                                    <Grid item className={classes.controls}>
                                        <Controls index={index} handleRerender={handleRerender} fields={fields} mutators={mutators} fieldName={"responses"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grow>
            )
        }

        default: console.warn("no matching case for type found " + type); return <p></p>
    }
}