import React, { useState, useEffect } from "react"
import { DialogProps } from "hooks/useDialog"
import { useTranslation } from "react-i18next"
import { makeStyles, Theme, DialogActions, Dialog, Grid } from "@material-ui/core"
import DialogTitle from "dialogs/DialogTitle"
import DialogContent from "dialogs/DialogContent"
import webforms, { IWebform } from "models/webform"
import Button from "components/Button/Button"
import isImprintValid from "constants/isImprintValid"
import { FormikInput, Typography, Fa } from "styled/muiComponents"
import LoadingAnimation from 'components/LoadingAnimation'
import { Form } from "react-final-form"


const useStyles = makeStyles<Theme, {}>(theme => ({
    card: {
        marginBottom: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        cursor: "pointer"

    }
}))
type Props = {
    prepare: boolean
}

// TODO pages for more than 10 webforms

const PickWebformDialog: React.FC<Props & DialogProps> = ({ onClose, onError, open, prepare }) => {

    const { t } = useTranslation()
    const classes = useStyles({})

    const [webformList, setWebformList] = useState<IWebform[] | null>(null)
    const [pickedWebform, setPickedWebform] = useState<boolean >(false)
    const [validImprint, setValidImprint] = useState<boolean>(false)
    const initialValues = { webform: "" }



    useEffect(() => {
        const fetchWebforms = async () => {
            const list = await webforms.getList()
            setWebformList(list.data)
        }
        const checkImprint = async () => {
            const result = await isImprintValid()
            setValidImprint(result)
        }
        fetchWebforms(); checkImprint()
    }, [])

    const handleClose = () => {
        onClose({})
    }

    const handleChange = (_: any) => {
        setPickedWebform(true)
    }

    if (!webformList) {
        return <LoadingAnimation />
    }

    const handleSubmit = (values: any) => {
        const webform = webformList.find((w: any) => w.name === values.webform)
        onClose({ success: true, webform: webform })
    }

    const webformNames = webformList.map((w: any) => w.name)

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <Form initialValues={initialValues} onSubmit={handleSubmit}
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle onClose={handleClose}>
                            <Fa icon={["fal", "filter-list"]} className={classes.warningIcon} mr={1.75} />{t("Wähle eine Anmeldestrecke aus")}
                        </DialogTitle>
                        {validImprint ?
                            <DialogContent >
                                {webformList.length > 0 ?
                                    <Grid container direction="column">
                                        <Typography mt={0} mb={1}>
                                            Bevor du eine druckbare PDF herunterladen kannst, musst du zunächst die Anmeldestrecke auswählen auf die der QR-Code in deinem Printmedium verweisen soll.
                                        </Typography>
                                        <FormikInput
                                            type="enum"
                                            name="webform"
                                            typeParams={webformNames}
                                            onChange={handleChange}
                                            variant="outlined"
                                            labelProps={{
                                                variant: "outlined"
                                            }}
                                            label={t("Anmeldestrecke")}
                                        />
                                    </Grid>
                                 :
                                    <Grid container>
                                        <Typography mb={2}>
                                            {t("Du hast bisher noch keine Anmeldestrecke erstellt, dessen QR Code du hier benutzen kannst.")}
                                        </Typography>
                                        <Button variant="text" href="/app/stock/webforms/create" size="small"><Fa icon={["fal", "arrow-right-long"]} mr={1} /> {t("Anmeldestrecke erstellen")}</Button>
                                    </Grid>
                                }
                            </DialogContent>
                            :
                            <DialogContent>
                                <Typography>
                                    {t("Diese Option steht dir zur Verfügung sobald du dein Impressum verfolgständigt hast. Die Möglichkeit dazu findest du unter 'Einstellungen'.")}
                                </Typography>
                            </DialogContent>
                        }
                        <DialogActions style={{padding: "16px 24px"}}>
                            <Grid container justify="space-between">
                                <Button variant="contained" color="error" onClick={handleClose} size="small"><Fa icon={["fas", "times"]} mr={1} size={"lg"} /> {t("Abbrechen")}</Button>
                                <Button type="submit" disabled={!(pickedWebform && validImprint)} variant="contained" color="success" size="small"><Fa icon={["fal", "file-pdf"]} mr={1}  /> {prepare ? t("PDF generieren") : t("PDF herunterladen")}</Button>
                            </Grid>
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    )
}

export default PickWebformDialog