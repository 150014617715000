import React, {useEffect, useState} from "react"
import Button from "components/Button/Button"
import { DialogProps} from 'hooks/useDialog'
import { useTranslation } from "react-i18next"
import { Dialog, makeStyles, Theme } from "@material-ui/core"
import DialogTitle from "dialogs/DialogTitle"
import DialogContent from "dialogs/DialogContent"
import DialogActions from "dialogs/DialogActions"
import { QRCode } from "react-qrcode-logo"
import { Document, Page, pdfjs } from "react-pdf"
import Pixi from 'components/Pixi'
import { Typography, Grid, Fa } from "styled/muiComponents"
import Typist from "react-typist"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const useStyles = makeStyles<Theme, {}>(theme => ({
    title: {
        backgroundColor: theme.palette.primary.light,
        color: "white"
    },
    divider: {
        backgroundColor: theme.palette.companyRed.main,
        height: "2px"
    },
    headerDescription: {
        padding: theme.spacing(2),
        fontSize: "0.8rem",
        //backgroundColor: "#00000015",
        backgroundColor: "#ffffff90",
        borderRadius: "5px",
        position: "relative",
        '&:after': {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            width: 0,
            height: 0,
            border: "18px solid transparent",
            //borderRightColor: "#00000015",
            borderRightColor: "#ffffff90",
            borderLeft: 0,
            borderBottom: 0,
            marginTop: "-9px",
            marginLeft: "-18px",
        },
    },
}))


export type Response = {
    action: null
}

export type Props = {
    item: any,
    webform: any
}

const DownloadPDFDialog: React.FC<Props & DialogProps> = ({ onClose, open, onError, item, webform }) => {
    const { t } = useTranslation()
    const classes = useStyles({})
    const [qrConfig, setQrConfig] = useState(item.config.QR)
    const [imgURL, setImgURL] = useState<any>()
    const [documentBlob, setDocumentBlob] = useState()
    // const template = location.state?.template

    const handleClose = () => {
        onClose({ action: null })
    }

    const handleDocument = (url: any) => {
        setDocumentBlob(url)
    }

    new QRCode(qrConfig).render()
    useEffect(() => {
        const qrelem = document.getElementById("react-qrcode-logo")
        //@ts-ignore
        setImgURL(qrelem?.toDataURL())
    }, [qrConfig])

    useEffect(() => {
        const getTemplate = async () => {
            try {
                const fetchedTemplate = await import(`../qrStoreDisplay/PDFTemplates/templates/template_${item.key}/index`)
                fetchedTemplate.Template({ imgURL, userText: item.config.editUserText, userStyles: item.config.editUserStyles, handleDocument, settings: item.settings })
            } catch (e) {
                // throw error
            }
        }
        getTemplate()
    }, [imgURL, item, qrConfig])


    const download = () => {
        const element = document.getElementById("download")
        if (element) {
            element.click()
        }
    }

    const handleDownload = async () => {
        // const link = await webforms.getFormUrl(webform)
        setQrConfig({ ...item.config.QR })
        setTimeout(() => { download(); }, 2000)
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle onClose={handleClose}>
                <Fa icon={["fal", "file-arrow-down"]} className={classes.warningIcon} mr={1.75} />{t("PDF herunterladen")}
            </DialogTitle>
            <DialogContent style={{backgroundColor: "#E6ECEF"}}>
                <div style={{ display: "none" }}>
                    <QRCode  {...qrConfig} />
                    <Document file={documentBlob}>
                        <Page pageIndex={0} renderMode="svg" scale={1.5 / item.settings.calc} />
                    </Document>
                    <a id="download" href={documentBlob} download={item.name} style={{ display: "none", textDecoration: "none" }} />
                </div>

                <Grid item container justify="flex-start" alignItems="center">
                    <Grid item sm={4}>
                        <div style={{ maxWidth: "150px" }}>
                        <Pixi size="full" pose="download"></Pixi>
                        </div>
                    </Grid>
                    <Grid item sm={8}>
                        <div className={classes.headerDescription} >
                            <Typist avgTypingDelay={5} cursor={{ show: false }}>
                                <Typography variant="h5" className={classes.pixiSpeech}>
                                    {t("Oh WOW, ich glaube in dir steckt ein echter Künstler. Dein Printmedium ist fantastisch geworden. Ich habe den QR-Code generiert und ein PDF erzeugt. Du kannst dein Kunstwerk nun herunterladen.")}
                                </Typography>
                            </Typist>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{padding: "16px 24px"}}>
                <Grid container justify="space-between">
                    <Button onClick={handleClose} variant="contained" color="error"><Fa icon={["fas", "times"]} mr={1} size={"lg"} /> {t("Abbrechen")}</Button>
                    <Button onClick={handleDownload} variant="contained" color="success"><Fa icon={["fas", "download"]} mr={1} /> {t("PDF Herunterladen")}</Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default DownloadPDFDialog