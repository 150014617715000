import React, { useState, useEffect } from 'react'
import { IconButton, makeStyles, Grid} from '@material-ui/core'
import webformApi, { IPlaceholder, IWebform, TemplateKey } from 'models/webform'
import { ITemplate } from 'models/template'
import PreviewFrame from 'components/PreviewFrame'
import { FaButton, Fa } from 'styled/muiComponents'
import { DialogProps } from 'hooks/useDialog'
import Button from 'components/Button/Button'
import { useTranslation } from 'react-i18next'
import SlideGalerie from 'components/SlideGalerie'
import { useHistory } from 'react-router-dom'
import { Device } from 'types/common'
import useSession from 'hooks/useSession'
import { insertDynamicImprint, insertImprint, insertPlaceholders } from 'util/placeholders'
import imprintApi from "models/imprint"
import LoadingAnimation from 'components/LoadingAnimation'
import FormikInput from 'components/formik/FormikInput'
import { Form } from "react-final-form"
import { theme } from 'theme'


const useStyles = makeStyles({
    portal: {
        backdropFilter: "blur(3px)",
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1300,
        backgroundColor: "#e2e2e2",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    },
    actionsWrapper: {
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 1400,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
        padding: "16px"

    },
    previewWrapper: {
        maxHeight: "600px"
    },
    galerieEntry: {
        textAlign: "center"
    },
    marginFix: {
        marginTop: "-30px"
    },
    deviceActive: {
        color: "red !important"
    },
    navButton: {
       
    },
    elementPedding: {
        padding: theme.spacing(2)
    },
    scaleDesktop: {
        transform: "scale(0.8)"
    }
})

const templateTypes: { key: TemplateKey, label: string }[] = [
    { key: TemplateKey.formLP, label: "Anmeldeseite" },
    { key: TemplateKey.soiLP, label: "Formular Landingpage" },
    { key: TemplateKey.doiMail, label: "Dopt-In Mail" },
    { key: TemplateKey.doiLP, label: "Dopt-In Landingpage" },
    { key: TemplateKey.doneMail, label: "Willkommens-Mail" }
]

const carousselWidth: Record<Device, number> = {
    [Device.Smartphone]: 480,
    [Device.Tablet]: 650,
    [Device.Desktop]: 1200,
    [Device.Fullscreen]: 1200
}

const WebformTemplatesView = ({ webform, onClose, open, editorView = false }: { webform: IWebform } & DialogProps & { editorView: boolean }) => {
    const classes = useStyles()
    const [templates, setTemplates] = useState<Record<TemplateKey, ITemplate | null> | null>(null)
    const [device, setDevice] = useState<Device>(Device.Smartphone)
    const { t } = useTranslation()
    const { user } = useSession()
    const [imprint, setImprint] = useState()
    //@ts-ignore
    // const [template, setTemplate] = useState(templateTypes[webform.step ?? 0].label)

    const history = useHistory()
    // @ts-ignore
    const [templateIndex, setTemplateIndex] = useState<number>(webform.step ?? 0)

    const handlePrevious = () => {
        setTemplateIndex(templateIndex - 1)
    }

    const handleNext = () => {
        setTemplateIndex(templateIndex + 1)
    }

    const handleChangeTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.value) {
            case t("Anmeldeseite"): setTemplateIndex(0); break
            case t("Formular Landingpage"): setTemplateIndex(1); break
            case t("Dopt-In Mail"): setTemplateIndex(2); break
            case t("Dopt-In Landingpage"): setTemplateIndex(3); break
            case t("Willkommens-Mail"): setTemplateIndex(4); break
        }
    }

    const onChangeIndex = (form: any, increase: boolean) => {
        if (increase) {
            form.change("template", t(templateTypes[templateIndex + 1].label))
        } else {
            form.change("template", t(templateTypes[templateIndex - 1].label))
        }
    }

    const handleSubmit = () => {
        return
    }

    useEffect(() => {
        if (editorView) {
            setTemplates({
                //@ts-ignore
                [TemplateKey.formLP]: webform.formLP.template, [TemplateKey.soiLP]: webform.soiLP.template, [TemplateKey.doiMail]: webform.doiMail.template, [TemplateKey.doiLP]: webform.doiLP.template, [TemplateKey.doneMail]: webform.doneMail.template
            })
        } else {
            (async () => {
                const [formLP, soiLP, doiMail, doiLP, doneMail] = await webformApi.getTemplates(webform)
                setTemplates({
                    [TemplateKey.formLP]: formLP,
                    [TemplateKey.soiLP]: soiLP,
                    [TemplateKey.doiMail]: doiMail,
                    [TemplateKey.doiLP]: doiLP,
                    [TemplateKey.doneMail]: doneMail
                })
            })()
        }
    }, [webform,editorView])
    //@ts-ignore
    let initialValues = { template: templateTypes[webform.step ?? 0].label }
    useEffect(() => {
        if (!editorView) {
            return
        }
        // initialValues = {templates: templateTypes[0].label}

        (async () => {

            const result = await imprintApi.getFirst()
            setImprint(result)

        })()
    }, [editorView])

    const customReplace = (template: string, placeholders: Record<string, Pick<IPlaceholder, "key" | "value" | "default"> | IPlaceholder>, imprint: any | null): string => {
        // return insertDynamicImprint(insertPlaceholders(template, placeholders), imprint)
        return insertImprint(insertDynamicImprint(insertPlaceholders(template, placeholders),imprint), imprint)

    }

    if (!open) return null

    const handleDeviceChange = (device: Device) => {
        setDevice(device)
    }

    const handleEdit = () => {
        console.log(templateTypes, templateIndex, device)
        if (templates) {
            history.push(`/app/stock/webforms/edit/${webform.id}/${templateTypes[templateIndex].key}?device=${device}`)
            onClose(null)
        }
    }
    //@ts-ignore


    if (editorView && !imprint || !templates) {
        return <LoadingAnimation />
    }

    return (
        <>
            <div className={classes.portal}>
                <Grid container direction="row" justify="space-between">
                    <Grid item style={{width: "150px"}}>
                        {/* spacer */}
                    </Grid>
                    <Form initialValues={initialValues} onSubmit={handleSubmit}
                        render={({ handleSubmit, form }) => (
                            <Grid item className={classes.elementPedding}>
                                <Grid container direction="row" alignItems='center'>
                                    <Grid item>
                                        {
                                            templateIndex === 0 ? 
                                            <Button variant="text" className={classes.navButton} disabled style={{height: "56px"}}> <Fa icon={["fas", "chevron-left"]}></Fa> </Button>
                                            :
                                            <Button variant="text" className={classes.navButton} onClick={() => { handlePrevious(); onChangeIndex(form, false) }} style={{height: "56px"}}> <Fa icon={["fas", "chevron-left"]} ></Fa> </Button>
                                        }
                                    </Grid>
                                    <Grid item style={{width: "250px"}}>
                                        <form>
                                            <FormikInput
                                                type="enum"
                                                name="template"
                                                id="template"
                                                // typeParams={[TemplateKey.formLP,TemplateKey.soiLP,TemplateKey.doiMail,TemplateKey.doiLP,TemplateKey.doneMail]}
                                                typeParams={[t("Anmeldeseite"), t("Formular Landingpage"), t("Dopt-In Mail"), t("Dopt-In Landingpage"), t("Willkommens-Mail")]}
                                                onChange={handleChangeTemplate}
                                                variant="outlined"
                                                style={{margin: "-16px 0 -8px"}}
                                            />
                                        </form>
                                    </Grid>
                                    <Grid item>
                                        {templateIndex === Object.keys(templates!).length - 1 ? 
                                        <Button variant="text" className={classes.navButton} disabled style={{height: "56px"}}><Fa icon={["fas", "chevron-right"]}></Fa></Button>
                                        :
                                        <Button variant="text" className={classes.navButton} onClick={() => { handleNext(); onChangeIndex(form, true) }} style={{height: "56px"}}><Fa icon={["fas", "chevron-right"]}></Fa> </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    />
                    <Grid item container direction='row' justify='flex-end' className={classes.elementPedding} style={{width: "150px"}}>
                        {!editorView ? <div> <IconButton onClick={handleEdit} style={{fontSize: "1.25em", width: "50px", height: "50px"}}>
                            <Fa icon={["fas", "wrench"]} fixedWidth></Fa>
                            </IconButton></div>
                            : null}

                        <IconButton aria-label="close" onClick={onClose} style={{width: "50px", height: "50px"}}>
                            <Fa icon={["fas", "times"]} ></Fa>
                        </IconButton>
                    </Grid>
                </Grid>

                <SlideGalerie<{ key: TemplateKey, label: string }> options={templateTypes} maxWidth={carousselWidth[device]} index={templateIndex}>
                    {({ option }) => (
                        <div key={option.key} className={`${classes.galerieEntry} ${device === Device.Desktop ? classes.scaleDesktop : ""}`}>
                            <div className={`${classes.previewWrapper} ${device === Device.Tablet ? classes.marginFix : ""}`}>
                                {templates && !editorView && <PreviewFrame variant={device} html={templates[option.key]?.body_html || ""} basicInfo={null} previewIdentifier={option.key} />} {/*TO DO templateMailSettings*/}
                                {templates && editorView && <PreviewFrame variant={device} previewIdentifier={option.key}
                                    //@ts-ignore
                                    html={customReplace(templates[option.key], webform[option.key].placeholders, imprint, user) || ""} basicInfo={null} />} {/*TO DO templateMailSettings*/}
                            </div>
                        </div>
                    )}
                </SlideGalerie>

                <DeviceSelect device={device} onDeviceChange={handleDeviceChange} />
            </div>
        </>
    )
}

const DeviceSelect = ({ device, onDeviceChange }: { device: Device, onDeviceChange: (device: Device) => void }) => {
    const classes = useStyles()
    return (
        <Grid container justify="center">
            <Grid item onClick={() => onDeviceChange(Device.Smartphone)}>
                <FaButton className={device === Device.Smartphone ? classes.deviceActive : ""} style={{ color: "inherit" }}>
                    <Fa icon={["fal", "mobile"]} />
                </FaButton>
            </Grid>
            <Grid item onClick={() => onDeviceChange(Device.Tablet)}>
                <FaButton className={device === Device.Tablet ? classes.deviceActive : ""} style={{ color: "inherit" }}>
                    <Fa icon={["fal", "tablet"]} />
                </FaButton>
            </Grid>
            <Grid item onClick={() => onDeviceChange(Device.Desktop)}>
                <FaButton className={device === Device.Desktop ? classes.deviceActive : ""} style={{ color: "inherit" }}>
                    <Fa icon={["fal", "laptop"]} />
                </FaButton>
            </Grid>
            {/*<Grid item onClick={() => onDeviceChange(Device.Fullscreen)}>
                        <FaButton className={device === Device.Fullscreen ? classes.deviceActive : ""} style={{ color: "inherit" }} >
                            <Fa icon={["fal", "window"]} />
                        </FaButton>
                    </Grid>*/}
        </Grid>
    )
}

export default WebformTemplatesView
