import MuiGrid from '@material-ui/core/Grid'
import MuiTypography from '@material-ui/core/Typography'
import { IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MuiBox from '@material-ui/core/Box';
import MuiDivider from '@material-ui/core/Divider';
import MuiButton from '@material-ui/core/Button';
import MuiFormikInput from "components/formik/FormikInput"
import { styled } from '@material-ui/core/styles';
import { palette, compose, spacing, sizing, textAlign } from '@material-ui/system';

export const Grid = styled(MuiGrid)(compose(spacing, sizing, textAlign));
export const Typography = styled(MuiTypography)(spacing)
export const Fa = styled(FontAwesomeIcon)(compose(spacing, palette));
export const FaButton = styled(IconButton)(compose(spacing, sizing, textAlign));
export const Divider = styled(MuiDivider)(spacing);
export const Box = styled(MuiBox)(spacing);
export const Button = styled(MuiButton)(spacing);
export const FormikInput = styled(MuiFormikInput)(spacing);