import React from 'react';
import { Box } from '@material-ui/core';
import { Grid } from '../../styled/muiComponents'

const Chat: React.FC = ({children}) => {

    return(
        <Grid item height="100%">
            <Box flexDirection="row" display="flex" height="100%">
                {children}
            </Box>
        </Grid>
    )
}

export default Chat