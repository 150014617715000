import React from "react"
import { useTranslation } from "react-i18next"
import { Dialog, Box, Grid } from "@material-ui/core"
import DialogTitle from "dialogs/DialogTitle"
import DialogContent from "dialogs/DialogContent"
import DialogActions from "dialogs/DialogActions"
import { DialogProps } from "hooks/useDialog"
import FormikInput from "components/formik/FormikInput"
import { Form, FormSpy } from "react-final-form"
import Button from "components/Button/Button"
import { Fa } from "styled/muiComponents"
import user  from "models/user"
//TODO look for a type like formikerrors 

export type Props = {
    id: number
}

type IValues = {
    oldPassword: string
    password: string
    passwordConfirm: string
}

const initialValues: IValues = {
    oldPassword: "",
    password: "",
    passwordConfirm: ""
}

export type Password = {
    password: string
}

export type Validator = {
    key: string
    label: string,
    isValid: (args: IValues) => boolean
}

export type Response = Password[]

const ChangePasswordDialog = ({ open, onClose, onError, id }: DialogProps & Props) => {
    const { t } = useTranslation()

    const handleClose = () => {
        onClose(null)
    }


    const validators: Validator[] = [
        {
            key: "min_8_letters",
            label: t("Mindestens 8 Zeichen"),
            isValid: ({ password }) => password ? password.length >= 8 : false
        },
        {
            key: "special_character",
            label: t("Sonderzeichen"),
            isValid: ({ password }) => password ? password.match(/[^a-zA-Z0-9]/) !== null: false
        },
        {
            key: "numbers",
            label: t("Zahlen"),
            isValid: ({ password }) => password ? password.match(/[0-9]/) !== null : false
        },
        {
            key: "lowercaseletters",
            label: t("Kleinbuchstaben"),
            isValid: ({ password }) => password ? password.match(/[a-z]/) !== null : false
        },
        {
            key: "uppercaseletters",
            label: t("Großbuchstaben"),
            isValid: ({ password }) => password ? password.match(/[A-Z]/) !== null : false
        },
        {
            key: "passwords_are_identical",
            label: t("Passwörter stimmen Überein"),
            isValid: ({ password, passwordConfirm }) => password && passwordConfirm ? password === passwordConfirm && password.length > 0 : false 
        }
    ]
    // const [validationResult, setValidationResult] = useState<Record<string, boolean>>({})

    const handleSubmit = async (values: IValues) => {
        try {
            await user.changePassword(id, values.password, values.oldPassword)
            // TODO Benachrichtigung für success
            // showSuccess(t("Passwort aktualisiert"))
            onClose({success: true})
        } catch (e) {
            //onError(e)
            /*setErrors({
                oldPassword: t("Dieses Passwort scheint falsch zu sein")
            })*/
        }

    }

    const handleValidate = (values: IValues) => {
        //  const errors: any<IValues> = {}
        const errors: any = {}
        // //const errors = {}
        let errorCount = 0
        console.log(values)
        const validationResult = validators.reduce((res, validator) => {
            if (!validator.isValid(values)) {
                errorCount++
                return { ...res, [validator.key]: false }
            } return { ...res, [validator.key]: true }
        }, {})
        if (errorCount > 0) {
            errors.password = "empty"
            errors.passwordConfirm = "empty"
        }
        return validationResult
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <Form initialValues={initialValues} onSubmit={handleSubmit} validateOnMount={true}
                render={({ handleSubmit, submitting }) => (
                    <form autoComplete="disabled" onSubmit={handleSubmit}>
                        <DialogTitle onClose={handleClose}>
                            {t("Passwort ändern")}
                        </DialogTitle>
                        <DialogContent>
                            <Grid item style={{ marginBottom: "16px" }}>
                                <FormikInput
                                    type="password"
                                    name="oldPassword"
                                    label={t("Altes Passwort")}
                                    variant="outlined"
                                />
                                <FormikInput
                                    type="password"
                                    name="password"
                                    label={t("Passwort")}
                                    variant="outlined"
                                />
                                <FormikInput
                                    type="password"
                                    name="passwordConfirm"
                                    label={t("Passwort bestätigen")}
                                    variant="outlined"
                                />
                            </Grid>

                            <FormSpy subscription={{values:true}}>
                                {({values}:{values: IValues}) => (
                                    <Validation errors={handleValidate(values)}/>
                                )}
                            </FormSpy>
                            <Grid item >
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container justify="space-between">
                                <Button color="error" variant="contained" size="small" onClick={handleClose}>
                                    <Fa icon={["far", "times"]} mr={1}/>{t("Abbrechen")}
                                </Button>
                                <Button isLoading={submitting} color="success" type="submit" variant="contained" size="small"><Fa icon={["far", "check"]} mr={1} /> {t("Passwort ändern")}</Button>
                            </Grid>
                        </DialogActions>
                    </form>
                )}
            />
        </Dialog>
    )
}

const Validation = ({errors}: any) => {
    const {t} = useTranslation()

    return (
        <>
            {Object.keys(errors).map((key: string) =>
                <Grid>{errors[key]}
                    <Grid item key={key} >
                        <Box display="flex" alignItems="center">
                            {errors[key] ? <Fa icon={["fas", "check-square"]} pr={1} color="green" /> : <Fa icon={["fas", "minus-square"]} pr={1} color="secondary.main" />}
                            {t(`/fields:${key}`)}
                        </Box>
                    </Grid>
                </Grid>

            )}
        </>
    )
}

export default ChangePasswordDialog

