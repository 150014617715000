import { withCrud, CrudModel, BaseModel, DBValuesSchema } from "./builder";
import { get, post, put, download } from '../api/crud'
import { IRecipientEvent, IRecipientTag } from '../types/recipient'
import { VerboseFilterObject } from "types/filter.object";

export type IRecipient = {
    address: string
    birthday: string,
    city: string,
    deactivated: false,
    doi_state: string,
    postal_code: string,
    unsubscribed:false,
    id: number,
    first_name: string,
    last_name: string,
    email: string
    created_at : string
}

export type RecipientApi = CrudModel<IRecipient> & BaseModel

export default {
    name: "recipients",
    ...withCrud<IRecipient>("recipients"),
    export: (searchText: string, filterObject: VerboseFilterObject, columns: string[]): Promise<void> => download(`recipients/export`, `export-recipients-campaignplus-${(new Date()).toISOString()}`, { filter_string: searchText, filter_object: filterObject, columns }),
    one: (id: number) => ({
        tags:() => {
            return get<{data: IRecipientTag[], meta: {item_count: number}}>(`recipients/${id}/recipient_tags?page=1&page_size=50`).then(response => response.data)
        },
        addTags: (tagNames: string[]) => {
            return post(`recipients/${id}/add_tags`, {tag_names: tagNames})
        },
        removeTags: (tag_id: number[]) => {
            return post(`recipients/${id}/remove_tags`, {tag_ids: tag_id})
        },
        events: () => {
            return get<IRecipientEvent[]>(`recipients/${id}/events`)
        },
        addCustomRecipientFields: () => {
            return post(`/custom_recipient_fields`)
        },
        saveRecipientInfo: (values: DBValuesSchema): Promise<IRecipient> => {
            return put(`recipients/${id}`, {...values})
        }
    })
}