import { Node, Edge, getOutgoers } from "react-flow-renderer"
import React from "react"
import { CurrentToPrevious, replaceEntities } from "./ChatSimFunctions"
import { Fa } from "styled/muiComponents"


const chatSimReducer = (state: any, { payload, type }: any) => { // TODO use state type and fix the errors
    const isStackPositionNeeded = (node: Node) => {
        return node.type === "botResponse" || node.type === "dataCollection"
    }

    const incrementStack = (node: Node) => {
        // if (!isStackPositionNeeded(node)) {
        //     return null
        // }

        return state.stackPosition + 1
    }


    const startStack = (node: Node) => {
        if (!isStackPositionNeeded(node)) {
            return null
        } else {
            return 0
        }
    }

    const currentDialog = (node: Node, stackPosition: number | null, userData?: any) => {
        switch (node.type) {
            case "start": {
                if(node.data.withButtons){
                return ({
                    text: replaceEntities(userData, node.data.text),
                    delay: 0,
                    userResponseType: "BUTTONS",
                    buttons: [{ text: replaceEntities(userData, node.data.buttonTexts[0].textOne) }, { text: replaceEntities(userData, node.data.buttonTexts[0].textTwo) }],
                    type: node.type
                })
                } else {
                    return({
                        text: replaceEntities(userData, node.data.text),
                        delay: 2,
                        userResponseType: "NONE",
                        type: node.type
                    })
                }
            }

            case "botResponse": {
                if (stackPosition === null) {
                    return ({})
                }
                const currentResponse = node.data.responses[stackPosition]

                switch (currentResponse.type) {
                    case "text": {
                        return ({
                            text: replaceEntities(userData, currentResponse.text),
                            userResponseType: "NONE",
                            delay: currentResponse.delay,
                            type: node.type
                        })
                    }
                    case "textWithButton":{
                        return({
                            text: replaceEntities(userData, currentResponse.text),
                            userResponseType: "BUTTONS",
                            delay: currentResponse.delay,
                            type: node.type,
                            buttons:[
                                {
                                    text: replaceEntities(userData, currentResponse.buttonText)
                                }
                            ]
                        })
                    }
                    case "coupon":{
                        return({
                            text: replaceEntities(userData, currentResponse.text),
                            userResponseType:"NONE",
                            delay: currentResponse.delay,
                            type: node.type,
                            coupon: currentResponse.coupon
                        })
                    }
                }
            }

            case "dataCollection": {
                if (stackPosition === null) {
                    return ({})
                }
                const currentQuestion = node.data.questions[stackPosition]
                const field = state.customFields.filter((field: any) => currentQuestion.field === field.name)[0]

                //remove after combining customfieldlist with standartfieldlist when initializing
                if(!field){
                    return({
                        text: replaceEntities(userData,currentQuestion.question),
                        userResponseType: "INPUT",
                        inputType: currentQuestion.validateWith,
                        delay: currentQuestion.delay,
                        field: currentQuestion.field,
                        type: node.type,
                        dataCollectionType: "string"
                    })
                }
                switch (field.type) {
                    case "enum": {
                        return ({
                            text: replaceEntities(userData, currentQuestion.question),
                            userResponseType: "BUTTONS",
                            delay: currentQuestion.delay,
                            field: currentQuestion.field,
                            type: node.type,
                            dataCollectionType: field.type,
                            buttons: field.type_params.map((param: string) => ({ text: param }))
                        })
                    }
                    case "set": {
                        return ({
                            text: replaceEntities(userData, currentQuestion.question),
                            userResponseType: "INPUT",
                            delay: currentQuestion.delay,
                            field: currentQuestion.field,
                            type: node.type,
                            dataCollectionType:field.type,
                            buttons: field.type_params.map((param:string) => ({text: param}))
                        })
                    }
                    case "boolean": {
                        return({
                            text: replaceEntities(userData, currentQuestion.question),
                            userResponseType: "BUTTONS",
                            delay: currentQuestion.delay,
                            field: currentQuestion.field,
                            type:node.type,
                            dataCollectionType: field.type
                        })
                    }
                    default: return ({
                        text: replaceEntities(userData, currentQuestion.question),
                        userResponseType: "INPUT",
                        inputType: currentQuestion.validateWith,
                        delay: currentQuestion.delay,
                        field: currentQuestion.field,
                        type: node.type,
                        dataCollectionType: field.type
                    })
                }
            }

            case "feedback": {
                return ({
                    //text: node.data.text, 
                    text: replaceEntities(userData, node.data.text),
                    userResponseType: "BUTTONS",
                    delay: node.data.delay ?? 2,
                    buttons: [
                        {
                            text: [`${node.data.split}-5`, <Fa icon={["fas", "star"]} ml={1} style={{fontSize: "0.9em", color: "#ffda59", marginTop: "-2px"}}/>],
                            continueID: `${node.id}P`
                        },
                        {
                            text: [`1-${node.data.split-1}`, <Fa icon={["fas", "star"]} ml={1} style={{fontSize: "0.9em", color: "#ffda59", marginTop: "-2px"}}/>],
                            continueID: `${node.id}U`
                        },
                    ],
                    type: node.type
                })
            }

            case "socialMedia": {
                return ({
                    text: [replaceEntities(userData, node.data.textTop), replaceEntities(userData, node.data.textBottom) ],
                    userResponseType: "BUTTONS",
                    delay: node.data.delay ?? 2,
                    type: node.type,
                     buttons: node.data.socialMedias.map((socialMedia: any) => {
                         return { text: socialMedia.platform, url: socialMedia.url }
                     })
                })
            }

            case "userFeedback": {
                return ({
                    text: replaceEntities(userData,node.data.text),
                    userResponseType: "INPUT",
                    inputType: "text", // ! bigger textfield
                    delay: node.data.delay ?? 2,
                    type: node.type
                })
            }

            case "splitQuestion": {
                return ({
                    text: replaceEntities(userData,node.data.question),
                    userResponseType: "BUTTONS",
                    buttons: node.data.answers.map((answer: any) => {
                        return { text: replaceEntities(userData,answer.answer), continueID: answer.answerKey }
                    }),
                    type: node.type,
                    delay: node.data.delay ?? 2
                })
            }

            case "goTo": {

                return ({
                    // ! REFACTOR DO NOT USE TEXT FOR NEXT ID
                    goto: node.data.continueWith.id,
                    userResponseType: "NONE",
                    type: node.type
                })
            }

            case "end": {
                return ({
                    text: replaceEntities(userData,node.data.text),
                    userResponseType: "END",
                    delay: node.data.delay ?? 2,
                    type: node.type
                })
            }

            case "testingEnd": {
                return ({
                    text: "KEINE WEITEREN NODES AUF DIESER STRECKE",
                    userResponseType: "END",
                    type: node.type
                })
            }

            case "splitAnswer": {
                return({
                    userResponseType: "NONE",
                    type:node.type
                })
            }

            default: {
                return (
                    {}
                )
            }
        }
    }

    switch (type) {
        case "CLICKED_PLATFORM":{
            return {...state, clickedPlatform: true}
        }
        case "INITIALIZE_CHAT": {
            const userData = {...state.userData, ...payload.dataCollection, default: [...payload.chat.generalSettings.placeholder] }
            const start = payload.chat.elements.filter((element: Node | Edge) => element.type === "start")[0]
            return {
                ...state,
                "chat": payload.chat,
                "currentNode": start,
                currentDialog: currentDialog(start, null, userData),
                isTesting: payload.isTesting,
                userData,
                customFields: payload.fields,
                chatSettings: payload.chat.chatSettings
            }
        }
        case "CONTINUE_DIALOG": {
            const userData = {...state.userData, ...payload.dataCollection,
                  default: state.userData.default
            }
            const noOrDoneStack = () => {
                return (
                    state.stackPosition === null ||
                    state.stackPosition === state.currentNode.data.responses?.length - 1 ||
                    state.stackPosition === state.currentNode.data.questions?.length - 1
                )
            }

            const toPrevious = <CurrentToPrevious state={state} userInput={payload.userInput}/>

            if (noOrDoneStack()) {
                if (!payload.nextID) {
                    const nextNode: Node = getOutgoers(state.currentNode, state.chat.elements)[0]
                    if (state.isTesting && !nextNode) {
                        return {
                            ...state,
                            currentDialog: currentDialog({ type: "testingEnd" } as Node, null),
                            stackPosition: null,
                            previousDialog: state.previousDialog.concat(toPrevious),
                            isWaiting: true,
                           userData
                        }
                    }

                    const stackPosition = startStack(nextNode)

                    return {
                        ...state,
                        "currentNode": nextNode,
                        "currentDialog": currentDialog(nextNode, stackPosition, userData),
                        "stackPosition": stackPosition,
                        previousDialog: state.previousDialog.concat(toPrevious),
                        isWaiting: true,
                        userData
                    }
                } else {
                    const nextNode: Node = state.chat.elements
                        .filter((element: Node | Edge) => element.id === payload.nextID)[0]
                    const stackPosition = startStack(nextNode)
                    return {
                        ...state,
                        "currentNode": nextNode,
                        "currentDialog": currentDialog(nextNode, stackPosition, userData),
                        "stackposition": stackPosition,
                        previousDialog: state.previousDialog.concat(toPrevious),
                        isWaiting: true,
                        userData
                    }
                }
            } else {
                const stackPosition = incrementStack(state.currentNode)
                return {
                    ...state,
                    "stackPosition": stackPosition,
                    "currentDialog": currentDialog(state.currentNode, stackPosition, userData),
                    previousDialog: state.previousDialog.concat(toPrevious),
                    isWaiting: true,
                    userData
                }
            }
        }

        case "WAITING_OVER": {
            return { ...state, isWaiting: false }
        }

        case "DATACOLLECTION_SET":{

            let newSet
            if (state.dataCollection_set.includes(payload.text)){
                newSet = state.dataCollection_set.filter((attribute: string) => attribute !== payload.text)
            } else {
                newSet = state.dataCollection_set.concat(payload.text)
            }
            return { ...state, dataCollection_set:newSet }
        }

        case "CHAT_SETTINGS" :{
            return {...state, chatSettings: {...payload.values}}
        }

        case "END_CHAT":{
            //TODO do something and post custom field data
            return state
        }
    }
}

export default chatSimReducer