import React, { useEffect, useState } from "react"
import { SetupNavigationProps, Navigation, ISetupInformation } from "../SetupWizard"
import { Form } from "react-final-form"
import FormikInput from "components/formik/FormikInput"
import { Typography, Grid } from "styled/muiComponents"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { useTranslation } from "react-i18next"

type Props = {
    navigationProps: SetupNavigationProps
    classes: any
    handleChange: (event: any) => void
    setupInformation: ISetupInformation
}

const CompanyAddress = ({navigationProps, classes, handleChange, setupInformation}: Props) => {
    const { t } = useTranslation()
    const [enableNext, setEnableNext] = useState<boolean>(false)
    const handleSubmit = () => {
    }

    const initialValues = {
        street_name: setupInformation.street_name,
        street_number: setupInformation.street_number,
        postal_code: setupInformation.postal_code,
        city: setupInformation.city,
        country: setupInformation.country
    }

    useEffect(() => {
        if(setupInformation.street_number && setupInformation.street_name && setupInformation.postal_code && setupInformation.city && setupInformation.country) {
            setEnableNext(true)
        } else {
            setEnableNext(false)
        }
    }, [setupInformation])

    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}
            render={({ handleSubmit, submitting }) => (
                <Grid container item direction="column" className={classes.root}>
                    <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" justify="flex-start">
                                <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                    <Grid item sm={4}>
                                        <div style={{ maxWidth: "150px" }}>
                                            <Pixi size="full" pose="waiting"></Pixi>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <div className={classes.headerDescription} >
                                        <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                            <Typography variant="h5" className={classes.pixiSpeech}>
                                                {t("Wie lautet die Anschrift deines Unternehmens?")}
                                            </Typography>
                                        </Typist>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={3} mt={1} className={classes.formContent}>
                                    <Grid item xs={12} sm={9}>
                                        <FormikInput
                                            type="string"
                                            value={setupInformation.street_name}
                                            name="street_name"
                                            label={t("Straße")}
                                            variant="outlined"
                                            onChange={handleChange}
                                            style={{marginTop: 0}}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormikInput
                                            type="string"
                                            value={setupInformation.street_number}
                                            name="street_number"
                                            label={t("Hausnr.")}
                                            variant="outlined"
                                            onChange={handleChange}
                                            style={{marginTop: 0}}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormikInput
                                            type="string"
                                            value={setupInformation.postal_code}
                                            name="postal_code"
                                            label={t("PLZ")}
                                            variant="outlined"
                                            onChange={handleChange}
                                            style={{marginTop: 0}}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <FormikInput
                                            type="string"
                                            value={setupInformation.city}
                                            name="city"
                                            label={t("Stadt")}
                                            variant="outlined"
                                            onChange={handleChange}
                                            style={{marginTop: 0}}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormikInput
                                            type="country"
                                            value={setupInformation.country}
                                            name="country"
                                            label={t("Land")}
                                            variant="outlined"
                                            onChange={handleChange}
                                            style={{marginTop: 0}}
                                            labelProps={{
                                                variant:"outlined"
                                            }}
                                            formControlProps={{
                                                required: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                
                                <Grid item sm={12}>
                                    <Navigation  {...navigationProps} enableNext={!enableNext}/>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item className={classes.formNote}>
                            <Typography variant="body1" className={classes.requiredNotice}>
                                <i>* {t("Pflichtfeld")}</i>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    )
}

export default CompanyAddress