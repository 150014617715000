import React, { useEffect, useState } from "react"
import { Grid, Paper } from "@material-ui/core"
import Button from "components/Button/Button"
import FormikInput from "components/formik/FormikInput"
import { useTranslation } from "react-i18next"
import customFields from "models/customFields"
import { useChatState } from "../../ChatContext"
import { Fa } from 'styled/muiComponents'
import Grow from '@material-ui/core/Grow'
import LoadingAnimation from 'components/LoadingAnimation'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FieldArray } from "react-final-form-arrays"
import { useForm } from "react-final-form"
import Controls from "../../ChatComponents"


const DataCollectionNodeOptions = ({ node, classes }: any) => {
    const [customFieldList, setCustomFieldList] = useState<any>()
    const { state } = useChatState()
    const { t } = useTranslation()
    const mutators = useForm().mutators

    // swaping responses doesnt trigger rerender, so its done here
    const [forceRerender, setForceRerender] = useState(false)
    const [hasLength, setLength] = useState(false)
    const handleRerender = () => {
        setForceRerender(!forceRerender)
    }

    let initialValues: any
    initialValues = useForm().getState().initialValues
    if (Object.keys(initialValues).length !== 0) {
        initialValues.questions.forEach((value: any, index: number) => initialValues = { ...initialValues, [`questions[${index}].field`]: value.field })
    }

    const [fieldValues, setFieldValues] = useState(initialValues)

    const possibleValidations = ["text"]

    useEffect(() => {
        const fetchCustomFields = async () => {
            const list = await customFields.getList()
            setCustomFieldList(list.data)
        }
        fetchCustomFields()
    }, [])

    if (!customFieldList) {
        return (

            <LoadingAnimation>
                Loading
            </LoadingAnimation>
        )
    }

    // const isStringInput = (value: string) => {
    //     //@ts-ignore
    //     const fieldName = fieldValues[value]
    //     if (!fieldName) {
    //         return true
    //     }
    //     const field = state.fields.filter((field: any) => field.name === fieldName)[0]
    //     return field.type === "string" || field.type === "date"
    // }
    
    return (
        <PerfectScrollbar className={classes.settingsContainer} style={hasLength ? {alignItems: "center", borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px" } : {borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px"}}>
            <Grid container >
                <FieldArray name="questions" subscription={{ submitting: true }}>
                    {({ fields }) => {
                        if (fields.length === 0) {
                            setLength(true);
                        } else {
                            setLength(false);
                        }
                        return fields.map((name: string, index: number) => {
                            return (
                                <Grow in={true} key={index}>
                                    <Grid container direction="column" alignItems="center">
                                        <div className={classes.seperator}></div>
                                        <FormikInput
                                            type="slider"
                                            name={`${name}.delay`}
                                            max={10}
                                            min={0}
                                            step={0.1}
                                            label="s delay"
                                            variant="delay"
                                        />
                                        <div className={classes.seperator}></div>
                                        <Paper elevation={2} className={classes.settingsGroupPaper}>
                                            <Grid container item className={classes.header}  wrap="nowrap" direction="row" alignItems="center" justify="space-between">
                                                <Grid item>
                                                    {t("Abfrage #")}{index+1}
                                                </Grid>
                                                <Grid item className={classes.controls}>
                                                    <Controls index={index} handleRerender={handleRerender} fields={fields} mutators={mutators} fieldName={"questions"}/>
                                                </Grid>
                                            </Grid>
                                            <Grid container item direction="column" justify="center" alignItems="stretch" className={classes.settingsGroup}>
                                                <Grid item style={{ width: "100%" }}>
                                                    <FormikInput
                                                        type="entityText"
                                                        name={`${name}.question`}
                                                        // label={t("Frage")}
                                                        variant="outlined"
                                                        typeParams={state.fields}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    {/* {isStringInput(`${name}.field`) &&  */}
                                                    <FormikInput
                                                        type="enum"
                                                        typeParams={possibleValidations}
                                                        name={`${name}.validateWith`}
                                                        label={t("Prüfen mit")}
                                                        variant="outlined"
                                                        labelProps={{
                                                            variant: "outlined"
                                                        }}
                                                        formControlProps={{
                                                            required: true
                                                        }}
                                                        className={classes.margin}
                                                    />
                                                    {/* } */}
                                                </Grid>
                                                <Grid item>
                                                    <FormikInput
                                                        type="enum"
                                                        typeParams={state.fields.map((field: any) => field.name)}
                                                        name={`${name}.field`}
                                                        label={t("Feld")}
                                                        variant="outlined"
                                                        labelProps={{
                                                            variant: "outlined"
                                                        }}
                                                        formControlProps={{
                                                            required: true
                                                        }}
                                                        className={classes.margin}
                                                        onChange={(e: any) => setFieldValues({ ...fieldValues, [`${name}.field`]: e.target.value })}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grow>
                            )
                        })
                    }}
                </FieldArray>
                <Grid item container direction="column" alignItems="center">
                    <div className={classes.seperator} style={hasLength ? {display: "none" } : { }}></div>
                    <Button startIcon={<Fa icon={["fal", "plus"]} size="xs" />} style={hasLength ? {marginBottom: 0} : {marginBottom: "16px"}} variant="contained" disableElevation color="success" size="large" onClick={() => {handleRerender(); mutators.push("questions", { question: "", field: "", validation: "", delay: 2 })}}>{t("Abfrage hinzufügen")}</Button>
                </Grid>
            </Grid>
        </PerfectScrollbar>
    )
}

export default DataCollectionNodeOptions


// TODO FIX
// SelectInput.js:342 Material-UI: You have provided an out-of-range value `undefined` for the select (name="field") component.
// Consider providing a value that matches one of the available options or ''.
// The available values are `first_name`, `last_name`, `postal_code`, `address`, `city`, `birthday`.