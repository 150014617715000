import { CrudModel } from "models/builder"
import { useLocation, useParams } from 'react-router-dom'
import { useState, useEffect } from "react"

type IParams = {
  id: string
}

/*
* Requires :id to be in route
*/

const useLocationStateOrFetch = <T>(modelApi: CrudModel<T>): T | null => {
  const location = useLocation()
  const { id } = useParams<IParams>()
  const [ value, setValue ] = useState<T>(location?.state?.state)

  useEffect(() => {
    if (!value) {
      modelApi.get(parseInt(id))
      .then(result => setValue(result))
      .catch(e => console.error(e))
    }
  }, [id, modelApi, value])

  return value ? value : null
}

export default useLocationStateOrFetch