import React, {useEffect} from 'react'
import { Link, Grid, makeStyles } from '@material-ui/core'
import Button from '../../components/Button/Button'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import InputAdornment from '@material-ui/core/InputAdornment';
import FormikInput from "../../components/formik/FormikInput"
import user from "models/user"
import { Fa, Typography } from "styled/muiComponents"
import { useHistory, useLocation } from "react-router-dom"
import { Form } from 'react-final-form'

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    agbAccepted: yup.bool().required()
})

const initialValues = {
    email: "",
    agbAccepted: false
}
const useTextStyles = makeStyles({
    root: {
        marginTop: "0"
    }
})

const Register = ({classes}) => {
    const textfieldclasses = useTextStyles()
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()

    const handleSubmit = async (values) => {
        window.grecaptcha.ready(() => window.grecaptcha
            .execute("6LfeUI0UAAAAAFW8ofPFCviQaPOSNDiC3x2p33K6", { action: "register" })
            .then(async (token) => {
                try {
                    await user.register(values.email, token, true)
                    history.push(`${location.pathname}/success`)
                } catch (e) {
                    history.push(`${location.pathname}/failure`)
                }
            }))
    }

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6LfeUI0UAAAAAFW8ofPFCviQaPOSNDiC3x2p33K6"
        document.body.appendChild(script)
    }, [])

    return (
        <Grid container direction="column" justify="center" alignItems="stretch" className={classes.root}>
            <Grid item>
                <img alt="Campaign.Plus Cornerstone" className={classes.logo} src="/assets/images/logo.svg" />
            </Grid>
            <Grid item>
                <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}
                    render={({ handleSubmit, submitting, submitSucceeded }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <FormikInput
                                        type="string"
                                        variant="filled"
                                        classes={textfieldclasses}
                                        name="email"
                                        labels="E-Mail"
                                        InputProps={
                                            {
                                                startAdornment:(
                                                    <InputAdornment position="start">
                                                        <Fa icon={["fal", "envelope"]}/>
                                                    </InputAdornment>
                                                )
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <FormikInput
                                        name="agbAccepted"
                                        margin="dense"
                                        type="checkbox"
                                        required
                                        label={<>{t("Ich akzeptiere die")} <a href="https://www.campaign.plus/allgemeine-geschaeftsbedingungen">{t("ABG")}</a> {t("und")} <a href="https://www.campaign.plus/datenschutzerklaerung">{t("Datenschutzbestimmung")}</a></>}
                                    />                                                            
                                </Grid>
                                <Grid item>
                                        {/* TODO submitting needs the submit function to return a promise */}
                                    <Button startIcon={<ExitToAppIcon />} isLoading={submitSucceeded} variant="contained" disableElevation color="success" size="large" type="submit">
                                        {t("Registrieren")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
                <div
            className="g-recaptcha"
            data-sitekey="https://www.google.com/recaptcha/api.js?render=6LfeUI0UAAAAAFW8ofPFCviQaPOSNDiC3x2p33K6"
            data-size="invisible"
         ></div>
            </Grid>
            <Grid item className={classes.regpw}>
                <Typography>
                    <Fa icon={["fal", "chevron-left"]} color="secondary.main" fixedWidth />
                    <Link component={RouterLink} to="/auth/login"> {t("Zurück zum Login")}</Link>
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Register