import React, { useState } from 'react'
import { Box, makeStyles, Collapse, Grid, Theme } from '@material-ui/core'
import FormikInput from "components/formik/FormikInput"
import { FaButton, Fa } from 'styled/muiComponents'
import { useTranslation } from "react-i18next"
import { IPlaceholder } from 'models/webform'
import { Typography } from 'styled/muiComponents'
import Pixi from 'components/Pixi'
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Device } from 'types/common'
import { IState, steps } from '../HtmlWizard'


const useStyles = makeStyles<Theme, { device?: Device, open?: boolean }>(theme => ({
    root: {
        backgroundColor: theme.palette.primary.light,
        color: "#ffffff",
        height: "calc(100% - 64px)",
        display: "flex",
        flexFlow: "column",
        boxSizing: "border-box",
    },
    header: {
        /*padding: theme.spacing(1)*/
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    subheader: {
        fontSize: "1.25rem",
    },
    headerDescription: {
        padding: theme.spacing(1.2),
        fontSize: "0.8rem",
        backgroundColor: "#ffffff26",
        borderRadius: "5px",
        position: "relative",
        '&:after': {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            width: 0,
            height: 0,
            border: "18px solid transparent",
            borderRightColor: "#ffffff26",
            borderLeft: 0,
            borderBottom: 0,
            marginTop: "-9px",
            marginLeft: "-18px",
        },
    },
    DescriptionWrapper: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    cardFixedHeader: {
        flex: "0 1 auto",
    },
    cardContent: {
        padding: 0,
        overflow: "auto",
        flex: "1 1 auto",
    },
    inputGroupHeader: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        transition: "0.35s all ease",
        color: ({ open }) => open ? theme.palette.companyRed.main : "white",
        "&:hover": {
            color: theme.palette.companyRed.main,
            cursor: "pointer",
        },
    },
    inputGroupContainer: {
        transition: "0.35s all ease",
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px",
        "&:hover": {
            backgroundColor: "#ffffff0d",
        }
    },
    inputWrapper: {
        padding: theme.spacing(1.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        "&:first-child": {
            paddingTop: theme.spacing(2.5),
        },
        "&:last-child": {
            paddingBottom: theme.spacing(2.5),
        },
        transition: '0.35s all ease',
        "&:hover": {
            color: theme.palette.companyRed.main
        }
    },
    collapse: {
        borderTop: "1px solid #ffffff0d",
    },
    previewDeviceIconsWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    previewLabel: {
        textAlign: "left",
        flex: "1 1 auto",
        "& p": {
            margin: 0,
        }
    },
    previewModeFullscreenButton: { // TO DO
        color: ({ device }) => device === Device.Fullscreen ? theme.palette.companyRed.main : "white",
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ device }) => device === Device.Fullscreen ? "1" : "0.5",
        },
        "& button": {
            paddingRight: 0,
        }
    },
    previewModeDesktopButton: { //TO DO
        color: ({ device }) => device === Device.Desktop ? theme.palette.companyRed.main : "white",
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ device }) => device === Device.Desktop ? "1" : "0.5",
        }
    },
    previewModeTabletButton: { // TO DO
        color: ({ device }) => device === Device.Tablet ? theme.palette.companyRed.main : "white",
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ device }) => device === Device.Tablet ? "1" : "0.5",
        }
    },
    previewModeSmartphonebutton: { // TO DO
        color: ({ device }) => device === Device.Smartphone ? theme.palette.companyRed.main : "white",
        transition: "0.35s all ease",
        "&:hover": {
            opacity: ({ device }) => device === Device.Smartphone ? "1" : "0.5",
        }
    },
}))


type Props = {
    state: IState,
    dispatch: any,
    usedPlaceholderKeys: string[]

}

const Edit = ({ state, dispatch, usedPlaceholderKeys }: Props) => {
    const classes = useStyles({ device: state.device })
    const { t } = useTranslation()

    const onDeviceChange = (device: Device) => {
        dispatch({ type: "DEVICE_CHANGE", payload: device })
    }

    const handleChange = (event: any) => {
        const {name, value} = event.target
        dispatch({type: "UPDATE_PLACEHOLDER_VALUE", payload: {[name]: value}})
    }

    return (
        <div className={classes.root}>
            <div className={classes.cardFixedHeader}>
                <Typography variant="h3" className={classes.header}>
                    {steps[state.step].label}
                </Typography>
                <Grid container direction="row" justify="center" alignItems="center" className={classes.DescriptionWrapper}>
                    <Grid item xs={4}>
                        <Pixi size="full" pose="waiting"></Pixi>
                    </Grid>
                    <Grid item xs={8}>
                        <div className={classes.headerDescription}>
                            {steps[state.step].description}
                        </div>
                    </Grid>
                </Grid>
                <Grid container justify="flex-end" alignItems="center" className={classes.previewDeviceIconsWrapper}>
                    <Grid item className={classes.previewLabel}>
                        <p>{t("Vorschau")}:</p>
                    </Grid>
                    <Grid item onClick={() => onDeviceChange(Device.Smartphone)} className={classes.previewModeSmartphonebutton}>
                        <FaButton style={{ color: "inherit" }}>
                            <Fa icon={["fal", "mobile"]} />
                        </FaButton>
                    </Grid>
                    <Grid item onClick={() => onDeviceChange(Device.Tablet)} className={classes.previewModeTabletButton}>
                        <FaButton style={{ color: "inherit" }}>
                            <Fa icon={["fal", "tablet"]} />
                        </FaButton>
                    </Grid>
                    <Grid item onClick={() => onDeviceChange(Device.Desktop)} className={classes.previewModeDesktopButton}>
                        <FaButton style={{ color: "inherit" }}>
                            <Fa icon={["fal", "laptop"]} />
                        </FaButton>
                    </Grid>
                    <Grid item onClick={() => onDeviceChange(Device.Fullscreen)} className={classes.previewModeFullscreenButton}>
                        <FaButton style={{ color: "inherit" }} >
                            <Fa icon={["fal", "window"]} />
                        </FaButton>
                    </Grid>
                </Grid>
            </div>
            <PerfectScrollbar>
                <div className={classes.cardContent}>

                    <form className="editor-config"
                    // onSubmit={handleSubmit}
                    >
                        <div className="editor-config">
                            {state.prebuild.config.groups.filter(group => group.placeholders.some(p => usedPlaceholderKeys.includes(p))).map(inputGroup => (
                                <InputGroup
                                    //previewIdentifier={previewIdentifier}
                                    key={inputGroup.name}
                                    name={inputGroup.name}
                                    faIcon={inputGroup.faIcon}
                                    iconType={inputGroup.iconType}
                                    placeholderKeys={inputGroup.placeholders}
                                    placeholders={state.prebuild.config.placeholders}
                                    usedPlaceholderKeys={usedPlaceholderKeys}
                                    state={state}
                                    onChange={handleChange}
                                />
                            ))}
                        </div>
                    </form>
                </div>
            </PerfectScrollbar>
        </div>
    )
}

type InputGroupProps = {
    name: string
    placeholderKeys: string[]
    placeholders: Record<string, IPlaceholder>
    usedPlaceholderKeys: string[]
    faIcon: string
    iconType: string
    state: IState
    onChange: any
    // previewIdentifier: TemplateKey
}

const InputGroup = ({
    // previewIdentifier,
    name, placeholderKeys,
    placeholders,
    usedPlaceholderKeys,
    faIcon,
    iconType,
    state,
    onChange
}: InputGroupProps) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles({ open })

    return (
        <div className={classes.inputGroupContainer}>
            <Box display="flex" justifyContent="space-between" alignItems="center" py={2} className={classes.inputGroupHeader} onClick={() => setOpen(!open)}>
                <Typography variant="h3" className={classes.subheader}>
                    {name}
                </Typography>
                <Fa icon={[iconType as IconPrefix, faIcon as IconName]} size="lg" fixedWidth />
            </Box>
            <Collapse in={open} className={classes.collapse} >
                {placeholderKeys.filter(p => usedPlaceholderKeys.includes(p)).map((placeholderKey: keyof typeof placeholders) => (
                    <div className={classes.inputWrapper} key={placeholderKey} >
                        <FormikInput variant="outlined" id={`${steps[state.step]}_${placeholders[placeholderKey].key}`} type={placeholders[placeholderKey].type} label={placeholders[placeholderKey].label} name={placeholders[placeholderKey].key} {...placeholders[placeholderKey].props} onChange={onChange}/>
                    </div>
                ))}
            </Collapse>
        </div>
    )
}


export default Edit