import React from 'react'

import DateTime from '../presentation/DateTime'
import Date from '../presentation/Date'
import Integer from '../presentation/Integer'
import Boolean from '../presentation/Boolean'
import { useTranslation } from 'react-i18next'
import { DBSchema } from '../../models/builder'

type Props = {
    schema?: Pick<DBSchema, "type" | "type_params">,
    value: any
}

const DataCell:React.FC<Props> = ({ schema={type: "string"}, value }) => {
    const { t } = useTranslation()

    switch(schema.type) {
        case "string": return value
        case "datetime": return <DateTime value={value} />
        case "date": return <Date value={value} />
        case "integer": return <Integer value={value} />
        case "boolean": return <Boolean value={value} />
        case "enum": return t(`/common:${value}`) 
        case "string_array": return value.join(",")
        default:
            console.error("unsupported datatype: ", {schema, value })
            return <span style={{border:"1px solid red", color:"red"}}> { value }</span>
    }
}

export default DataCell