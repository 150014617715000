import { CrudModel, BaseModel, withCrud } from "models/builder"

export type ISenderDomain = {
    doichain: boolean
    domain : IDomain
    localPart:string
    name: string
}
export type IDomain = {
    dkim: IDomainDkim
    dmarc: IDomainDmarc
    domain: string
    id: number
    spf: IDomainSpf
    tracking: IDomainTracking
}
export type IDomainDkim = {
    valid: boolean
    value: IDomain0
}
export type IDomain0 = {
    name: string
    type: string
    value: string
}
export type IDomainDmarc = {
    valid: boolean
    value: string
}
export type IDomainSpf = {
    valid: boolean
    value: string
}
export type IDomainTracking = {
    valid: boolean
    value: IDomainValue
}
export type IDomainValue = {
    domain: string
    id: number
    realm_id: number
    sender_domain_id: number
    valid_dns_entry: boolean
}

export type SenderDomainApi = CrudModel<IDomain> & BaseModel

export default {
    name: "senderDomains",
    ...withCrud<IDomain>("sender_domains"),
} 