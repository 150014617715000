import React from "react"
import { DialogProps } from "hooks/useDialog"
import { useTranslation } from "react-i18next"
import {  makeStyles, Theme, Dialog, Grid } from "@material-ui/core"
import DialogTitle from "dialogs/DialogTitle"
import DialogContent from "dialogs/DialogContent"
import DialogActions from "dialogs/DialogActions"
import Button from "components/Button/Button"
import { Fa, Typography } from "styled/muiComponents"
import { Form } from "react-final-form"
import FormikInput from "components/formik/FormikInput"


const useStyles = makeStyles<Theme, {}>(theme => ({
    dialogPaper: {width: "100%"}
}))
type Props = {

}

const ChatnameDialog:React.FC <Props & DialogProps> = ({onClose, onError, open}) => {

    const { t } = useTranslation()
    const classes = useStyles({})

    const handleClose = () => {
        onClose({})
    }

    const handleSubmit = (values: any) => {
        onClose({name: values.name})
    }

    return (
      
        <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
             <Form onSubmit={handleSubmit}
                render={({ handleSubmit, submitting }) =>
                    <form onSubmit={handleSubmit}>
                        <DialogTitle onClose={handleClose}>
                            {t("Name des Chats")}
                        </DialogTitle>
                        <DialogContent>
                            <Typography>Definiere bitte einen Namen für deinen Chat.</Typography>
                            <Grid container direction="column">
                                <FormikInput
                                    type="text"
                                    variant="outlined"
                                    name="name"
                                    label={t("Chat-Name")}
                                    required={true}
                                />
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container justify="space-between">
                                <Grid item>
                                    <Button variant="contained" color="error" onClick={handleClose} size="small"><Fa icon={["fas", "times"]} mr={1} /> {t("Später festlegen")}</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="success" type="submit" size="small"><Fa icon={["fas", "check"]} mr={1} />{t("Speichern und starten")}</Button>                                    
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </form>
                }
            />
        </Dialog>
    )
}

export default ChatnameDialog