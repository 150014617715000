import React, { useState, useEffect } from 'react'
import tariffs, { ITariff, ITariffVolumes } from "models/tariffs"
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import useSession from "hooks/useSession"
import bundles from 'models/bundles'

export type TariffProps = { // TODO TYPING
    // used: any
    current: any
    candidate: any 
    // fetchCurrent: () => void
    permissions: IPermissions
    // totalPrepaidVolume: any
    // totalMonthlyVolume: any
    // boughtMonthly: any
    // boughtPrepaid: any
    // candidates: any
    // fetchPrepaid: () => void
    // fetchMonthly: () => void
    // fetchCandidates: () => void
 
}

export type IPermissions = {
    subscription_volume: boolean
    chats: boolean
    bindings: boolean
    pdf_templates: boolean
    image_volume: boolean
    cornerstone_webforms: boolean
}

export enum IPermissionModules {
    subscription_volume = "subscription_volume",
    chats = "chats",
    bindings = "bindings",
    pdf_templates = "pdf_templates",
    image_volume = "image_volume",
    cornerstone_webforms = "cornerstone_webforms",
}

const TariffContext = React.createContext<TariffProps>({

    
    // used: [],
    current: null,
    candidate: null,
    // totalPrepaidVolume: [],
    // totalMonthlyVolume: [],
    // boughtMonthly: [],
    // boughtPrepaid: [],
    // candidates: [],
    // fetchPrepaid: () => { },
    // fetchMonthly: () => { },
    // fetchCandidates: () => { },
    // fetchCurrent: () => {},
    permissions: {
        subscription_volume:false,
        chats: false,
        bindings: false,
        pdf_templates: false,
        image_volume: false,
        cornerstone_webforms: false
        //         subscription_volume:true,
        // chats:true,
        // image_volume:true,
        // bindings:true,
        // pdf_templates:true,
        // cornerstone_webforms:true
    }
})

const stripePromise = loadStripe('pk_test_8F4uVBqxC4KKN9w7ynCi3HC5');

export const TariffProvider: React.FC<{}> = ({ children }) => {
    const [current, setCurrent] = useState<any>(null)
    const [candidate, setCandidate] = useState<any>(null)

    const {user} = useSession()
    const [permissions, setPermissions] = useState<IPermissions>({
        subscription_volume: false,
        chats: false,
        bindings: false,
        pdf_templates: false,
        image_volume: false,
        cornerstone_webforms: false,
        // subscription_volume:true,
        // chats:true,
        // image_volume:true,
        // bindings:true,
        // pdf_templates:true,
        // cornerstone_webforms:true
    })


    const putPermissions = () => {
        if (!current) {
            return
        }
        console.log(current)
        //todo
        const subscription_type = current.bundle_option['type']
        console.log(subscription_type)

        switch(subscription_type){
            case 'sprout':
                setPermissions({
                    subscription_volume:true,
                    chats:false,
                    image_volume:true,
                    bindings:false,
                    pdf_templates:true,
                    cornerstone_webforms:true
                })
                return
            case 'roots':
                setPermissions({
                    subscription_volume:true,
                    chats:false,
                    image_volume:true,
                    bindings:true,
                    pdf_templates:true,
                    cornerstone_webforms:true
                })
                return
            case 'grow':
                setPermissions({
                    subscription_volume:true,
                    chats:true,
                    image_volume:true,
                    bindings:true,
                    pdf_templates:true,
                    cornerstone_webforms:true
                })
                return
            default:
                setPermissions({
                    subscription_volume:false,
                    chats:false,
                    image_volume:false,
                    bindings:false,
                    pdf_templates:false,
                    cornerstone_webforms:false
                })
                
        }

        setPermissions({
            subscription_volume:true,
            chats:true,
            image_volume:true,
            bindings:true,
            pdf_templates:true,
            cornerstone_webforms:true
        })
    }

    useEffect(() => {
        putPermissions()
    }, [current])

    useEffect(() => {
        const fetchCurrent = async () => {
            await bundles.current()
            .then((response) => {
                setCurrent(response)
                setCandidate(response.candidate)
            })
        }

        fetchCurrent()
    }, [user])

    return (
        <Elements stripe={stripePromise}>
            <TariffContext.Provider value={{
                current,
                candidate,
                permissions
            }}>
                {children}
            </TariffContext.Provider>
        </Elements>
    )
}

export const useTariff = () => {
    return React.useContext(TariffContext)
}