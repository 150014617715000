import React from "react"
import FormikInput from "components/formik/FormikInput"
import { Grid, Dialog, makeStyles, Divider } from "@material-ui/core"
import {useTranslation} from "react-i18next"
import Button from "components/Button/Button"
import { DialogProps } from "hooks/useDialog"
import { Fa, Typography } from 'styled/muiComponents'
import DialogTitle from "dialogs/DialogTitle"
import moment from "moment"
import recipients from "models/recipient"
import {Form} from "react-final-form"


const useStyles = makeStyles(theme => ({
    row: {
        justifyContent: "space-between",
        alignItems: "center",
        height: "70px"
    },
    root:{
        minWidth: "50vh",
        minHeight: "50vh"
    },
    form:{
        width:"70vh"
    },
    label:{
        paddingLeft: theme.spacing(2)
    },
    input:{
        width: "200px",
        paddingRight: theme.spacing(2)
    },
    cancelButton:{
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2)
    },
    confirmButton: {
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2)
    }
}))

const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    postal_code: "",
    city: "",
    birthday: moment(),
    unsubscribed: false,
    deactivated: false,
    doi_status: "CONFIRMED"
}

const AddRecipient = ({ open, onClose, onError }: DialogProps) => {

    const { t } = useTranslation()
    const classes = useStyles()

    const handleSubmit = async (values: any) => {
        recipients.create(values)
        .then(() => onClose(null))
        .catch(() => {}/*onError*/)
    }

    const handleClose = () => {
        onClose(null)
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
            <DialogTitle onClose={handleClose}>{t("Empfänger erstellen")}</DialogTitle>
            <Form initialValues={initialValues} onSubmit={handleSubmit}
                render={({ handleSubmit, submitting }) => (
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <Divider />
                        <Grid container direction="column">
                            <Grid item container direction="row" className={classes.row}>
                                <Grid item>
                                    <Typography className={classes.label}>
                                        {t("Vorname")}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.input}>
                                    <FormikInput
                                        type="text"
                                        name="first_name"
                                        label={t("Vorname")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" className={classes.row}>
                                <Grid item>
                                    <Typography className={classes.label}>
                                        {t("Nachname")}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.input}>
                                    <FormikInput
                                        type="text"
                                        name="last_name"
                                        label={t("Nachname")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" className={classes.row}>
                                <Grid item>
                                    <Typography className={classes.label}>
                                        {t("Email-Adresse")}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.input}>
                                    <FormikInput
                                        type="text"
                                        name="email"
                                        label={t("Email Adresse")}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" className={classes.row}>
                                <Grid item>
                                    <Typography className={classes.label}>
                                        {t("Postleitzahl")}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.input}>
                                    <FormikInput
                                        type="string"
                                        name="postal_code"
                                        label={t("Postleitzahl")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" className={classes.row}>
                                <Grid item>
                                    <Typography className={classes.label}>
                                        {t("Adresse")}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.input}>
                                    <FormikInput
                                        type="text"
                                        name="address"
                                        label={t("Adresse")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" className={classes.row}>
                                <Grid item>
                                    <Typography className={classes.label}>
                                        {t("Stadt")}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.input}>
                                    <FormikInput
                                        type="text"
                                        name="city"
                                        label={t("Stadt")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" className={classes.row}>
                                <Grid item>
                                    <Typography className={classes.label}>
                                        {t("Geburtstag")}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.input}>
                                    <FormikInput
                                        type="date"
                                        name="birthday"
                                        label={t("Geburtstag")}
                                        variant="standard"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" className={classes.row}>
                                <Grid item>
                                    <Typography className={classes.label}>
                                        {t("Abgemeldet")}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.input}>
                                    <FormikInput
                                        type="boolean"
                                        name="unsubscribed"
                                        label={t("Abgemeldet")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" className={classes.row}>
                                <Grid item>
                                    <Typography className={classes.label}>
                                        {t("Deaktiviert")}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.input}>
                                    <FormikInput
                                        type="boolean"
                                        name="deactivated"
                                        label={t("Deaktiviert")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" className={classes.row}>
                                <Grid item>
                                    <Typography className={classes.label}>
                                        {t("Doi-Status")}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.input}>
                                    <FormikInput
                                        type="enum"
                                        name="doi_status"
                                        typeParams={[
                                            "CONFIRMED",
                                            "UNCONFIRMED"
                                        ]}
                                        label={t("DOI-Status")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container direction="row" justify="space-between">
                            <Grid item className={classes.cancelButton}>
                                <Button color="error" variant="contained" onClick={handleClose}>{t("Abbrechen")}</Button>
                            </Grid>
                            <Grid item className={classes.confirmButton}>
                                <Button isLoading={submitting} type="submit" color="success" variant="contained"><Fa icon={["fal", "user-plus"]} style={{paddingRight: "8px"}}/> {t("Hinzufügen")}</Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </Dialog>
    )
}

export default AddRecipient