import React, {useEffect, useState} from "react"
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import LoadingAnimation from "components/LoadingAnimation";
import Button from "components/Button/Button";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


const MultiSelect = ({...props}) => {

    if (props.async) {
        return <AsyncMultiSelect {...props}/>
    } else {
        return <SyncMultiSelect {...props}/>
    }
}


const AsyncMultiSelect = ({...props}) => {
    console.log(props)
    const [page, setPage] = useState(1)
    const [items, setItems] = useState(null)
    const [asyncSelected, setAsyncSelected] = useState([])
    const [length, setLength] = useState(0)
    
    const handleLoadMore = () => {
        setPage(page+1)
    }
    let modelLength = 0
    useEffect(() => {
        (async () => {
            try  {
                const result = await props.model.getList(page)
                modelLength = result.meta.item_count
                if(page === 1){
                    setItems(result.data)
                    setLength(result.meta.item_count)
                } else {
                    setItems(items.concat(result.data))
                }
                // if(result.data.length === 1 || !props.field.value) {
                //     // preset value if only one option is availlable
                //     props.field.onChange({ target: { value: result.data[0], name: props.field.name }})
                // }
            }catch(e) {
                //TODO proper error handling
                console.error("e: ", e)
            }
        })()
    },[page, props.model])

    useEffect(() => {
        if (!items){
            return
        }
        if(!props.input.value.chosen.every((v) => items.some((i) => i.id === v.id))){
            setPage(page+1)
        } else {
            const preChosen = props.input.value.chosen.map((v) => items.findIndex((i) => i.id === v.id))
            setAsyncSelected(preChosen)
        }
    }, [items])

    if(!items){
        return <LoadingAnimation/>
    }

    return(
        <SyncMultiSelect typeParams={items} modelLength={length} handleLoadMore={handleLoadMore} setAsyncSelected={setAsyncSelected} value={asyncSelected}  {...props}/>
    )

}

// TODO MAKE FUNCTION FOR NON ASYNC
const SyncMultiSelect = ({...props}, modelLength=0) => {
    const {input:{name, value,...field}}= props
    const { t } = useTranslation()
    const [selected, setSelected] = useState(props.value ?? props.input.value.chosen ?? [])

    useEffect(()=> {
        setSelected(props.value)
    },[props.value])

    const handleChange = (e) => {
        if(e.target.value.some((v) => v === undefined)){
            props.setAsyncSelected(selected)
            return
        }
        const returnValues = e.target.value.map((v) => props.typeParams[v])
        const leftValues = props.typeParams.filter((v, idx) => !e.target.value.includes(idx)) 

        if (props.onChange) {
            props.onChange({ target: { name: name, value: {chosen: returnValues, left: leftValues} } })
        }
        field.onChange({ target: { name: name, value: {chosen: returnValues, left: leftValues} } })
        setSelected(e.target.value)
    }

    const handleBlur = (e) => {
        const returnValues = e.target.value.map((v) => props.typeParams[v])
        const leftValues = props.typeParams.filter((v, idx) => !e.target.value.includes(idx)) 
        if (props.onBlur) {
            props.onBlur({ target: { name: name, value:{chosen: returnValues, left: leftValues}  } })
        }
        field.onBlur({ target: { name: name, value:{chosen: returnValues, left: leftValues}  } })
        setSelected(e.target.value)
    }

    return (
        <FormControl>
            <Select
                multiple
                displayEmpty
                value={selected}
                onChange={handleChange}
                input={<Input />}
                renderValue={(selected) => {
                    if ((selected).length === 0) {
                        return <em>{t("Keine Elemente ausgewählt")}</em>;
                    }
                    return (selected.map((s) => s.name)).join(', ');
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
            >
                {/* <MenuItem disabled value="">
                    <em>{t("Anmeldestrecken")}</em>
                </MenuItem> */}
                {props.typeParams.map((p, idx) => (
                    <MenuItem key={p.name} value={idx}>
                        <Checkbox checked={selected.includes(idx)}/>
                        <ListItemText primary={p.name || p.label}/>
                    </MenuItem>
                ))}
                {(props.async && props.modelLength > props.typeParams.length) && <Button onClick={(e) => {props.handleLoadMore(); }}>{t("Mehr")}</Button>}
            </Select>
        </FormControl>
    )
}


export default MultiSelect


