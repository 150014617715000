import { decorator } from "components/formik/entityText/helperFunctions"
import { convertFromRaw, convertToRaw, EditorState } from "draft-js"
import { ICustomFields, Type } from "models/customFields"
import { Connection, Edge, isNode, isEdge, Node, Elements } from "react-flow-renderer"
import { BotResponseNode, EndNode, FeedbackNode, SplitQuestionNode, SocialMediaNode, StartNode, DataCollectionNode, SplitAnswerNode, UserFeedbackNode, GoToNode } from "./customNodes"



export type ChatState = {
    elements: Elements<any>
    selected: Node | Edge | null
    selectedHandle: ISelectedHandle
    generalSettingsOpen: boolean
    generalSettings: IGeneralSettings
    drawingEdge: IDrawingEdge | null
    goToSelection: boolean
    goToHover: null | Node
    testChatActive: boolean
    currentChatSimNode : Node
    chatSettings: any
    chatSettingsMenuOpen:boolean
}

type IGeneralSettings = {
    name: string,
    withAvatar: boolean
}

type ISelectedHandle = {
    handle: IHandle
    node: NodeHandleInfo
    data?: IData
}

type IData = {
    feedback?: string
    type?: string
    handleKey?: string
    answer?: string
    url?: string
}

type IHandle = {
    id: string
}

type NodeHandleInfo = {
    id: string,
    type: string
}

type IDrawingEdge = {
    handleType: string
    id: string
    type: string
}

export const nodeTypes = {
    userFeedback: UserFeedbackNode,
    splitAnswer: SplitAnswerNode,
    dataCollection: DataCollectionNode,
    botResponse: BotResponseNode,
    splitQuestion: SplitQuestionNode,
    socialMedia: SocialMediaNode,
    feedback: FeedbackNode,
    start: StartNode,
    end: EndNode,
    goTo: GoToNode

}

export const validConnections = {
    start: ["dataCollection", "feedback", "botResponse", "splitQuestion"],
    botResponse: ["splitQuestion", "end", "feedback", "goTo", "dataCollection"],
    socialMedia: ["botResponse", "splitQuestion", "end", "goTo"],
    userFeedback: ["botResponse", "splitQuestion", "end", "goTo"],
    end: [],
    dataCollection: ["botResponse", "splitQuestion", "end", "goTo", "feedback"],
    splitQuestion: [],
    feedback: [],
    splitAnswer: ["end", "botResponse", "feedback", "dataCollection", "goTo"],
    //goTo: ["splitQuestion", "feedback", "dataCollection", "botResponse", "end", "start"] //all except for offspring nodes (portals/userfeedback & answerNodes)?
    goTo: ["start", "botResponse", "splitQuestion", "feedback","end", "dataCollection"]
}

export const fields: ICustomFields[]= [
    { name: "last_name",  type: Type["string"], type_params: []},
    { name: "first_name", type: Type["string"], type_params: []},
    { name: "postal_code", type: Type["string"], type_params: []},
    { name: "email", type: Type["string"], type_params: []},
    { name: "address", type: Type["string"], type_params: []},
    { name: "city", type: Type["string"], type_params: []},
    { name: "birthday", type: Type["date"], type_params: []},
]

export const findElement = (id: string, state: any) => {
    return state.elements.find((element: Node | Edge) => element.id === id)
}

export const gotoHighlight = (id: string, state: ChatState) => {
    if (id === state.goToHover?.id) {
        if (validConnections.goTo.includes(state.goToHover?.type as string)) {
            return { highlight: true, valid: true }
        } else {
            return { highlight: true, valid: false }
        }
    } else {
        return { highlight: false, valid: false }
    }
}

export const chatHighlight = (id:string, state: ChatState) => {
    if(!state.currentChatSimNode){
        return false
    }
    if (id === state.currentChatSimNode.id){
        return true
    } else {
        return false
    }
}

export const highlightWhileDrawing = (sourceType: string, targetType: string) => {
    // TODO fuse highlight function with normal isValidConnection and isValidEdgeUpdate after every case is known
    // ? use to highlight handles instead of nodes?

    if (!sourceType) {
        return false
    }
    //@ts-ignore TODO TYPING FOR INDEX
    if (validConnections[sourceType].includes(targetType)) {
        return true
    } else {
        return false
    }
}

export const isValidGoTo = (element: Node) => {
    return validConnections[`goTo`].includes(element.type as string)
}

export const isValidConnection = (params: Connection | Edge, state: any) => {
    const { source, sourceHandle } = params

    const sourceType = state.elements.find((element: any) => element.id === params.source).type
    const targetType = state.elements.find((element: any) => element.id === params.target).type

    //@ts-ignore TODO TYPING FOR INDEX
    if (!validConnections[sourceType].includes(targetType)) {
        return false
    }

    if (sourceType !== "answer" && sourceType !== "feedback") {
        // is there an edge with source === this node
        if (!state.elements.some((element: Node | Edge) => element.id.includes(`e${source}S`))) {
            return true
        } else {
            return false
        }
    } else {
        // is there an edge with source === this handle from answer node
        if (!state.elements.some((element: Node | Edge) => element.id.includes(`${sourceHandle}`))) {
            return true
        } else {
            return false
        }
    }
}

export const getAllNodes = (elements: (Node | Edge)[]) => {
    return elements.filter((element: Node | Edge) => isNode(element)) as Node[]
}

export const getAllEdges = (elements: (Node | Edge)[]) => {
    return elements.filter((element: Node | Edge) => isEdge(element)) as Edge[]
}

export const isValidEdgeUpdate = (oldEdge: Edge, newConnection: Connection, state: any) => {
    const sourceType = state.elements.find((element: any) => element.id === oldEdge.source).type
    const targetType = state.elements.find((element: any) => element.id === newConnection.target).type

    //@ts-ignore TODO TYPING FOR INDEX
    if (!validConnections[sourceType].includes(targetType)) {
        return false
    }

    if (sourceType !== "answer" && sourceType !== "feedback") {
        const isThereEdgeFromSource = state.elements
            .filter((element: Node | Edge) => element.id !== oldEdge.id)
            .some((ele: Node | Edge) => ele.id.includes(`e${newConnection.source}`))

        if (!isThereEdgeFromSource) {
            return true
        } else {
            return false
        }
    } else {
        const isThereEdgeFromAnswerhandle = state.elements
            .filter((element: Node | Edge) => element.id !== oldEdge.id)
            .some((ele: Node | Edge) => ele.id.includes(`${newConnection.sourceHandle}`))

        if (!isThereEdgeFromAnswerhandle) {
            return true
        } else {
            return false
        }
    }
}

export const countNodeType = (type: string, nodeCounts:any) => {
    if(!nodeCounts){
        return 1
    }
    if(!nodeCounts[type]){
        return 1
    } else {
        return nodeCounts[type].length+1
    }
}

export const convertTextToRaw = (value: any) => {
    try {
        return convertToRaw(value.getCurrentContent())
    } catch {
        return value
    }
}

export const convertTextFromRaw = (value: any) => {
    try{
        return EditorState.createWithContent(convertFromRaw(value), decorator)
    } catch {
        return value
    }
}

export const convertTextfields = (elements: (Node | Edge)[], type: "fromRaw" | "toRaw") => {

    const convert = {fromRaw: convertTextFromRaw, toRaw: convertTextToRaw}

    const convertedElements = elements.map((ele: Node | Edge) => {
        if(!isNode(ele)){
            return ele
        }
            switch (ele.type) {
            case "feedback": {
                ele["data"] = {...ele.data, text: convert[type](ele.data.text)}
                break
            }
            case "splitAnswer": {
                ele["data"] = {...ele.data, answer: convert[type](ele.data.answer)}
                break
            }
            case "userFeedback": {
                ele["data"] = {...ele.data, text: convert[type](ele.data.text)}
                break
            }
            case "botResponse": {
                const convertedResponses: any = []
                ele.data.responses.forEach((response: any) => {
                    switch (response.type) {
                        case "text": {
                            convertedResponses.push({ ...response, text: convert[type](response.text) }); break
                        }
                        case "textWithButton": {
                            convertedResponses.push({ ...response, text: convert[type](response.text), buttonText: convert[type](response.buttonText)}); break
                        }
                        case "coupon":{
                            convertedResponses.push({ ...response, text: convert[type](response.text)}); break
                        }
                    }
                })
                 ele["data"] = { ...ele.data, responses: convertedResponses }
                break
            }

            case "splitQuestion": {
                const convertedData = { ...ele.data, question: convert[type](ele.data.question) }
                const convertedAnswers: any = []
                ele.data.answers.forEach((ans: any) => {
                    convertedAnswers.push({ ...ans, answer: convert[type](ans.answer) })
                })
                 ele["data"] = { ...convertedData, answers: convertedAnswers }
                break
            }
            case "start": {
                 ele["data"] = {
                    ...ele.data,
                    text: convert[type](ele.data.text),
                    buttonTexts: [{
                        textOne:convert[type](ele.data.buttonTexts[0].textOne),
                        textTwo: convert[type](ele.data.buttonTexts[0].textTwo)
                        }]
                }
                break
            }
            case "dataCollection": {
                const convertedQuestions: any = []
                ele.data.questions.forEach((question: any) => {
                    convertedQuestions.push({ ...question, question: convert[type](question.question) })
                })
                ele["data"] = { ...ele.data, questions: convertedQuestions }
                break
            }
            case "socialMedia": {
                ele["data"] = { ...ele.data, textTop: convert[type](ele.data.textTop), textBottom:convert[type](ele.data.textBottom) }
                break
            }
            case "end":{
                ele["data"] = { ...ele.data, text: convert[type](ele.data.text)}
                break
            }
        }
        return ele
    })
    return convertedElements
}

export const snackDescriptionUndo = (type:string) => {
    const typeDescription = () => {
        switch(type){
            case "ADD_NODE": return "Erstellen einer Node"
            case "ADD_NODE_BETWEEN": return "Erstellen einer Node"
            case "EDIT_NODE_SETTINGS": return "Einstellungen einer Node"
           // case "UPDATE_NODE": return "???"  benutzt?
            case "DELETE_NODE": return "Löschen einer Node"
            case "EDIT_GENERAL_SETTINGS":return "Allgemeine Einstellungen des Chats"
        }
    }
    return `Es wurde rückgängig gemacht: ${typeDescription()}`
}

export const snackDescriptionRedo = (type:string) => {
    const typeDescription = () => {
        switch(type){
            case "ADD_NODE": return "Erstellen einer Node"
            case "ADD_NODE_BETWEEN": return "Erstellen einer Node"
            case "EDIT_NODE_SETTINGS": return "Einstellungen einer Node"
            //case "UPDATE_NODE": return "???"  benutzt?
            case "DELETE_NODE": return "Löschen einer Node"
            case "EDIT_GENERAL_SETTINGS":return "Allgemeine Einstellungen des Chats"
        }
    }
    return `Es wurde wiederhergestellt: ${typeDescription()}`
}

export const handleHasEdge = (state: ChatState) => {
    return state.elements.find((element: Node | Edge) => element.id.includes(`e${state.selectedHandle.node.id}${state.selectedHandle.handle.id}`)) !== undefined //! looks incorrect
}
