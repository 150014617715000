import { withCrud, CrudModel, BaseModel } from "./builder"

export type ICustomFields = {
    id?: string
    name: string
    type: Type | null
    type_params: string[] | []
}

export enum Type {
    "string" = "string",
    "date" = "date",
    "integer" = "integer",
    "boolean" = "boolean",
    "enum" = "enum",
    "set" = "set",
}

export type UserApi = CrudModel<ICustomFields> & BaseModel

export default {
    name: "customFields",
    ...withCrud<ICustomFields>("custom_recipient_fields")
}