import Button from "components/Button/Button"
import FormikInput from "components/formik/FormikInput"
import React from "react"
import { IChats } from "../WebformWizard"

type Props= {
    chats: IChats,
    handleLoadMoreChats: () => void
    handleSetChat: (id:number) => void
    handleChangeChatView: (view: "graph" | "chat") => void
}

const Chats = ({chats, handleLoadMoreChats, handleSetChat, handleChangeChatView}: Props) => {

    const chatList = chats.chats.map((chat: any) => {
        return `${chat.id}, ${chat.name}`
    }) 


    const handleChangeChat = (value: any) => {
        const rx = /^[^,]*/
        const result = rx.exec(value) // result[0] is id
        const chat: any = chats.chats.filter((chat: any) => chat.id === parseInt(result![0]))[0]
        handleSetChat(chat.id)
    }

    

    return(
    <>
    <p>chats</p>
        <FormikInput 
            type="enum"
            name="chat"
            onChange={(e:any) => handleChangeChat(e.target.value)}
            typeParams={chatList}
        />
        <Button onClick={handleLoadMoreChats}>Mehr</Button>
        <Button onClick={() => handleChangeChatView("graph")}>graph</Button>
        <Button onClick={() => handleChangeChatView("chat")}>chat</Button>
    </>
    )
}

export default Chats