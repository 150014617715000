import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade'
import List from '@material-ui/core/List';
import { useTheme } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MuiCollapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { navEntries } from '../../../constants/nav.entries';
import { useLocation, useHistory } from 'react-router-dom';
import { Fa } from '../../../styled/muiComponents'
import { useSidebar } from '../../../hooks/useSidebar'
import { Menu, Tooltip } from "@material-ui/core"


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.companyDark.main,
    padding: "8px 0 0 0"
  },
  nested: {
    backgroundColor: theme.palette.companyDark.light
  },
  popover: {
    pointerEvents: "none"
  },
  popoverContent: {
    pointerEvents: "auto"
  }
}));

const useEntryStyles = makeStyles(theme => ({
    tooltip:{
      backgroundColor: entry =>  entry.tooltipBackgroundColor,
      color: entry => entry.tooltipColor,
      fontSize: "0.8em"
    },
    arrow: {
      color: entry =>  entry.tooltipBackgroundColor
    }
}))

const useIconStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.companyDark.contrastText,
    minWidth: "50px",
    fontSize: "1.15em",
    marginTop: "5px",
    marginBottom: "5px"
  },
}))

const useListStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.companyDark.contrastText,
    '& span': {
      fontWeight: 300,
      fontSize: "0.9em"
    },
    '&:hover': {
      backgroundColor: theme.palette.companyDark.light
    }
  },
  selected: {
    backgroundColor: "red"
  },
  outer: {
    padding: "8px 26px",
    border: "0",
    backgroundColor: ({ selected }) => selected ? theme.palette.companyDark.light : "inherit"
  },
  primary: {
    color: theme.palette.companyDark.contrastText
  },
}))

const Collapse = ({ entry, onClick, pathname, open, onToggle, anchorEl, handleMenuClose, handleMenuOpen }) => {
  const iconClasses = useIconStyles()
  const classes = useStyles()
  const { isFixedOpen } = useSidebar()
  const listClasses = useListStyles({ selected: pathname.startsWith(entry.path) && !isFixedOpen })
  const entryClasses = useEntryStyles(entry)

  const theme = useTheme()


  const handleClick = (event) => {
    if (isFixedOpen) {
      onToggle()
    }
  }

  const handleMouseEnter = (event) => {
    if (!isFixedOpen) {
      handleMenuOpen(event)
    }
  }
  const handleMouseLeave = () => {
    handleMenuClose()
  }

  return (
    <>
      {
        <ListItem button classes={{ root: listClasses.root }} className={listClasses.outer} divider={!open} onClick={(event) => handleClick(event)} onMouseEnter={(event) => handleMouseEnter(event)} onMouseLeave={handleMouseLeave}>
          <ListItemIcon className={iconClasses.root}>
              {typeof (entry.icon) === "function" ? entry.icon(entry) : entry.icon}
          </ListItemIcon>
          <Fade in={isFixedOpen} timeout={250}>
            <ListItemText >{entry.label}</ListItemText>
          </Fade>
          <Fade in={isFixedOpen} timeout={250}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </Fade>
          <Menu
            PaperProps={{
              style: {
                backgroundColor: theme.palette.companyDark.light
              },
            }}
            classes={{
              paper: classes.popoverContent
            }}
            aria-haspopup="true"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "center", horizontal: "right" }}
            transformOrigin={{ vertical: "center", horizontal: "left" }}
            className={classes.popover}
            disableRestoreFocus
          >
            <List component="div" disablePadding className={classes.nested} >
              {entry.children.map((child, idx) => (
                <ListItem className={listClasses.outer} key={child.path} button divider={open && idx === entry.children.length - 1} classes={{ root: listClasses.root }} onClick={() => onClick(child)}>
                  <ListItemIcon className={iconClasses.root}>
                    {pathname.startsWith(child.path) ? <Fa icon={["fas", "circle"]} fixedWidth color={entry.color} transform="shrink-8" /> : <Fa icon={["far", "circle"]} fixedWidth color={entry.color} transform="shrink-8" />}
                  </ListItemIcon>
                  <ListItemText className={entryClasses.root}>{child.label}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Menu>
        </ListItem>
      }
      {isFixedOpen ?
        <MuiCollapse in={open && (isFixedOpen)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.nested}>
            {entry.children.map((child, idx) => (
              <ListItem className={listClasses.outer} key={child.path} button divider={open && idx === entry.children.length - 1} classes={{ root: listClasses.root }} onClick={() => onClick(child)}>
                <ListItemIcon className={iconClasses.root}>
                  {pathname.startsWith(child.path) ? <Fa icon={["fas", "circle"]} fixedWidth color={entry.color} transform="shrink-8" /> : <Fa icon={["far", "circle"]} fixedWidth color={entry.color} transform="shrink-8" />}
                </ListItemIcon>
                <Fade in={isFixedOpen} timeout={250}>
                  <ListItemText style={pathname.startsWith(child.path) ? { color: entry.color, whiteSpace: "nowrap" } : { color: "inherit", whiteSpace: "nowrap" }}>{child.label}</ListItemText>
                </Fade>
              </ListItem>
            ))}
          </List>
        </MuiCollapse>
        :
        null
      }
    </>
  )
}

const Entry = ({ entry, onClick, pathname }) => {
  const classes = useStyles()
  const { isFixedOpen } = useSidebar()
  const listClasses = useListStyles({ selected: pathname.startsWith(entry.path) && !isFixedOpen })
  const theme = useTheme()
  const iconClasses = useIconStyles()
  const entryClasses = useEntryStyles(entry)

  return(
  <Tooltip title={entry.label} disableHoverListener={isFixedOpen} placement="right" key={entry.path}  classes={{popper: entryClasses.popper, tooltip: entryClasses.tooltip, arrow: entryClasses.arrow}} arrow>
    <ListItem className={listClasses.outer} style={pathname.startsWith(entry.path) ? { backgroundColor: theme.palette.companyDark.light } : {}} button onClick={(event) => onClick(entry, event)} classes={{ root: listClasses.root }} selected={pathname.startsWith(entry.path)}>
      <ListItemIcon className={iconClasses.root}>
        {pathname.startsWith(entry.path) ? 
          typeof (entry.iconSelected) === "function" ? entry.iconSelected(entry) : entry.iconSelected
          :
          typeof (entry.icon) === "function" ? entry.icon(entry) : entry.icon
        }
      </ListItemIcon>
      <Fade in={isFixedOpen} timeout={250}>
        <ListItemText className={entryClasses.root} style={pathname.startsWith(entry.path) ? { color: entry.color } : { color: "unset" }}>
          {entry.label}
        </ListItemText>
      </Fade>
    </ListItem>
  </Tooltip>
  )
} 

export default () => {
  const classes = useStyles();
  const { pathname } = useLocation()
  const [ open, setOpen ] = useState(null)
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)


  useEffect(() => {
    const active = navEntries.find(entry => 
      (entry.children && entry.children.some(child => pathname.startsWith(child.path)))
      || pathname.startsWith(entry.path)
    )
    if(active && active.key) {
      setOpen(active.key)
    }
  }, [pathname])

  const handleClick = (entry) => {
      history.push(entry.path)
  }

  const handleToggle = ({ key, }) => {
    if (open === key) {
      setOpen(null)
    } else {
      setOpen(key)
    }
  }

  const handleMenuClose = () => {
    if (anchorEl) {
      setAnchorEl(null)
    }
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      {navEntries.map((entry, idx) =>
        entry.children ? (
          <Collapse key={idx} entry={entry} onClick={handleClick}  open={open === entry.key} onToggle={(value) => handleToggle(entry)} handleMenuOpen={handleMenuOpen} handleMenuClose={handleMenuClose} anchorEl={anchorEl}/>
        ) : 
          <Entry key={idx} entry={entry} onClick={handleClick} pathname={pathname}/>
      )}
    </List>
  );
}