import { withCrud, CrudModel, BaseModel } from "./builder";

export type IWebformOpenTracking = {
}

export type WebformOpenTrackingApi = CrudModel<IWebformOpenTracking> & BaseModel

export default {
    name: "webformOpenTrackings",
    ...withCrud<IWebformOpenTracking>("webform_open_trackings")
}