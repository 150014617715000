// TODO remove everything with updateNodeInternals. just for testing purposes
import React from "react"
import { makeStyles, Grid, Tooltip } from "@material-ui/core"
import { Fa } from "styled/muiComponents"
import Button from "components/Button/Button"
import { useTranslation } from "react-i18next"
import { useChatState } from "./ChatContext"
import { useHistory } from "react-router-dom"
import { validation } from "./ChatValidation"
import chats from "models/chats"
import SaveChatDialog from "./SaveChatDialog"
import { useDialog } from "hooks/useDialog"
import { useAlert } from "hooks/useAlert"
import { primaryHue, theme } from 'theme'
import { convertTextfields, snackDescriptionRedo, snackDescriptionUndo } from "./chatFunctions"
import { useSnackbar } from "notistack"
import ExitChatDialog from "./ExitChatDialog"
import RouteLeavingGuard from "components/routeLeaveGuard/RouteLeaveGuard"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "420px",
    maxWidth: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    overflow: "hidden",
    height: "48px"
  },
  tabButton: {
    height: "100%",
    minWidth: "48px",
    borderRadius: 0,
    fontSize: "1.5rem",
    color: primaryHue[600],
    transition: "0.3s all ease"
  }
}))

const Save = ({save}: any) => {
  
  const { dialog } = useDialog()
  const { state } = useChatState()
  const { t } = useTranslation()
  const classes = useStyles()

  
  

  const saveChat = async () => {
    const errors = validation(state)
    if (errors.length > 0) {
      dialog.show({
        component: SaveChatDialog,
        props: {
          errors,
          id: state.id
        }
      })
        .then((response) => response.save && save(errors.length))
        .catch((error) => console.log(error))
    } else {
      save()
    }
  }

  return (
    <Tooltip title={t("Chat speichern")!}>
      <Button onClick={saveChat} variant="text" className={classes.tabButton} >
        <Fa icon={["fal", "floppy-disk"]} />
      </Button>
    </Tooltip>
  )
}

const Toolbar = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { state, dispatch } = useChatState()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const {dialog} = useDialog()
  const { alert } = useAlert()

  const save = async (errors:number = 0) => {
    const {future, past,imprint, ...toSave } = state
    let valid = false
    
    if(errors === 0) {
      valid = true
    }
    toSave.selected = null
    toSave.selectedHandle = null
    toSave.generalSettingsOpen = false
    toSave.goToSelection = false
    toSave.elements = convertTextfields(state.elements, "toRaw")
    const {selectedWebforms, ...generalSettings} = state.generalSettings
    toSave.generalSettings = generalSettings
    if (state.id) {
      await chats.update(state.id, { valid: valid,chat: toSave })
      alert.success(t("Chat wurde geupdatet"))
    } else {
      const response = await chats.create({ name: toSave.generalSettings.chatName ?? "Chat", chat: toSave, valid: valid })
      alert.success(t("Chat wurde gespeichert"))
      dispatch({type: "ADD_ID", payload: response.id})
    }
  }

  const handleTestChat = () => {
    dispatch({ type: "START_TESTCHAT" })
  } 

  const handleOpenSettings = () => {
    dispatch({ type: "OPEN_GENERAL_SETTINGS" })
  }

  const handleUndo = () => {
    if (!state.past || state.past.length === 0) {
      return
    }
    enqueueSnackbar(snackDescriptionUndo(state.past[state.past.length - 1].type), { variant: "info" })
    dispatch({ type: "UNDO" })
  }

  const handleRedo = () => {
    if (!state.future || state.future.length === 0) {
      return
    }
    enqueueSnackbar(snackDescriptionRedo(state.future[state.future.length - 1].type), { variant: "info" })
    dispatch({ type: "REDO" })
  }

  const handleNodeMenu = () => {
    dispatch({ type: "OPEN_NODE_MENU" })
  }

  const handleExit = () => {
    const errors = validation(state)
    dialog.show({
      component: ExitChatDialog,
      props: {

      }
    })
      .then((response) => {
        if (response.save) {
          if (errors.length === 0){
            save()
            return
          }
          dialog.show({
            component: SaveChatDialog,
            props: {
              errors: errors,
              id: state.id 
            }
          })
          .then((response) => {
            if (response.save) {
              save(errors.length)
              history.push("/app/stock/chats", {noAdditionCheck:true})
            }
          })
        } else if(!response.save && response.leave){
          history.push("/app/stock/chats", {noAdditionalCheck:true})
        } else if (!response.save && !response.leave) {
          return
        }
    })
  }

  return (
      <Grid container direction="row" className={classes.container}
        style={{
          borderBottomRightRadius: state.selected || state.selectedHandle || state.generalSettingsOpen || state.testChatActive || state.selectedHandle || state.nodeMenuOpen ? '0px' : "4px",
          borderBottomLeftRadius: state.selected || state.selectedHandle || state.generalSettingsOpen || state.testChatActive || state.selectedHandle || state.nodeMenuOpen ? '0px' : "4px",
          flexWrap: "nowrap"
        }}>
          <RouteLeavingGuard
          when={true}
          navigate={path => history.push(path)}
          shouldBlockNavigation={location => {
            if(location.state){
              return false
            } 
            return true
          }}
          />
        <Grid item container>
          <Grid item style={{ backgroundColor: state.selected || state.selectedHandle || state.nodeMenuOpen ? theme.palette.primary.light : "transparent" }}>
            <Tooltip title={t("Blockeinstellungen")!}>
              <Button onClick={handleNodeMenu} variant="text" className={classes.tabButton} style={{ color: state.selected || state.selectedHandle ? "#ffffff" : " " }}>
                <Fa icon={[state.selected || state.selectedHandle || state.nodeMenuOpen ? "fad" : "fal", "message-pen"]} className="chatToolbarDuoToneIcon" />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item style={{ backgroundColor: state.testChatActive ? theme.palette.primary.light : "transparent" }}>
            <Tooltip title={t("Chat testen")!}>
              <Button onClick={handleTestChat} variant="text" className={classes.tabButton} style={{ color: state.testChatActive ? "#ffffff" : " " }}>
                <Fa icon={[state.testChatActive ? "fad" : "fal", "clapperboard-play"]} className="chatToolbarDuoToneIcon" />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item style={{ backgroundColor: state.generalSettingsOpen ? theme.palette.primary.light : "transparent" }}>
            <Tooltip title={t("Einstellungen")!}>
              <Button onClick={handleOpenSettings} variant="text" className={classes.tabButton} style={{ color: state.generalSettingsOpen ? "#ffffff" : " " }}>
                <Fa icon={[state.generalSettingsOpen ? "fad" : "fal", "gear"]} className="chatToolbarDuoToneIcon_invert" />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Save save={save}/>
          </Grid>
        </Grid>
        <Grid item container justify="flex-end" >

          <Tooltip title={t("Rückgängig")!}>
            <Button variant="text" className={classes.tabButton} disabled={!state.past || state.past.length === 0} onClick={handleUndo}>
              <Fa icon={["far", "reply"]} />
            </Button>
          </Tooltip>

          <Tooltip title={t("Wiederherstellen")!}>
            <Button variant="text" className={classes.tabButton} disabled={!state.future || state.future.length === 0} onClick={handleRedo}>
              <Fa icon={["far", "share"]} />
            </Button>
          </Tooltip>

          <Tooltip title={t("Editor schließen")!}>
            <Button variant="text" className={classes.tabButton} onClick={handleExit} >
              <Fa icon={["far", "arrow-right-from-bracket"]} />
            </Button>
          </Tooltip>

        </Grid>
      </Grid>
  )
}

export default Toolbar