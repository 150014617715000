import { withCrud, CrudModel, BaseModel } from "./builder";
import templateApi, { ITemplate } from "./template"
import { insertPlaceholders } from "util/placeholders"
import { downloadPrebuildConfigRating, downloadPrebuildHtmlRating } from "util/download";
import { IState } from "routes/app/stock/ratings/HtmlBuildWizard/HtmlWizard";
import domains from "models/domain"

export type IPrebuild = {
    config: IConfig
    templates: {
        qr: Template
        query: Template
        portals: Template
        feedback: Template
        feedbackLP: Template
    }
}

type Template = {
    template: string,
    placeholders: Record<string, IPlaceholder> | null
}

export type IRating = {
    id: string,
    name: string
    hid: string
    qr_template_id: number
    query_template_id: number
    portals_template_id: number
    feedback_template_id: number
    feedback_lp_template_id: number
    rating_portals: string[]
    config: any

}

export enum TemplateKey {
    qr = "qr",
    query = "query",
    portals = "portals",
    feedback = "feedback",
    feedbackLP = "feedbackLP"
}

export type IPlaceholder = {
    key: string
    label: string
    default: any
    value?: any
    type: "color" | "tinymce" | "number" | "text" | "string" | "unsplash" | "slider"
    typeParams?: any
    props?: any
}

export type IConfig = {
    key: string
    name: string
    groups: { name: string, placeholders: string[], faIcon: string, iconType: string }[]
    placeholders: Record<string, IPlaceholder>
}

type CustomMethods = {
    getRatingUrl: (hid: string) => Promise<{ qr: string, query: string }>
    getPrebuild: (key: string) => Promise<IPrebuild>,
    createRating: (state: IState) => Promise<IRating>,
    getTemplates: (webform: IRating, templateKeys?: TemplateKey[]) => Promise<[ITemplate | null, ITemplate | null, ITemplate | null, ITemplate | null, ITemplate | null]>,

}



//IRATING later
export type RatingApi = CrudModel<any> & BaseModel & CustomMethods

let ratings: RatingApi = {
    name: "ratings",
    ...withCrud<any>("ratings"),

    getRatingUrl: async (hid): Promise<{ qr: string, query: string }> => {
        const domain = await domains.get_first()
        const urls = { qr: `https://${domain.domain}.cornermail.de/rating/${hid}/qr`, query: `https://${domain.domain}.cornermail.de/rating/${hid}/query` }
        return urls
    },

    getPrebuild: async (key: string) => {
        const [config, qr, query, portals, feedback, feedbackLP] = await Promise.all([
            downloadPrebuildConfigRating(key),
            downloadPrebuildHtmlRating(key, "qr"),
            downloadPrebuildHtmlRating(key, "query"),
            downloadPrebuildHtmlRating(key, "portals"),
            downloadPrebuildHtmlRating(key, "feedback"),
            downloadPrebuildHtmlRating(key, "feedback_lp")
        ])

        return {
            config: config as IConfig,
            templates: {
                qr: { template: qr as string, placeholders: null },
                query: { template: query as string, placeholders: null },
                portals: { template: portals as string, placeholders: null },
                feedback: { template: feedback as string, placeholders: null },
                feedbackLP: { template: feedbackLP as string, placeholders: null }
            }
        }

    },
    createRating: (state) => {
        return new Promise((res, rej) => {
            // put buckets into config
            // use placeholders in template buckets
            const promises: [Promise<ITemplate>, Promise<ITemplate>, Promise<ITemplate>, Promise<ITemplate>, Promise<ITemplate>] = [
                templateApi.create(
                    {
                        name: `${state.name}-qr`,
                        from_cornerstone: true,
                        mime_type: "html",
                        body_html: insertPlaceholders(state.prebuild.templates.qr.template, { ...state.prebuild.config.placeholders, ...state.prebuild.templates.qr.placeholders })
                    }
                ),
                templateApi.create(
                    {
                        name: `${state.name}-query`,
                        from_cornerstone: true,
                        mime_type: "html",
                        body_html: insertPlaceholders(state.prebuild.templates.query.template, { ...state.prebuild.config.placeholders, ...state.prebuild.templates.query.placeholders })
                    }
                ),
                templateApi.create(
                    {
                        name: `${state.name}-portals`,
                        from_cornerstone: true,
                        mime_type: "html",
                        body_html: insertPlaceholders(state.prebuild.templates.portals.template, { ...state.prebuild.config.placeholders, ...state.prebuild.templates.portals.placeholders })
                    }
                ),
                templateApi.create(
                    {
                        name: `${state.name}feedback`,
                        from_cornerstone: true,
                        mime_type: "html",
                        body_html: insertPlaceholders(state.prebuild.templates.feedback.template, { ...state.prebuild.config.placeholders, ...state.prebuild.templates.feedback.placeholders })
                    }),
                templateApi.create(
                    {
                        name: `${state.name}-feedback-lp`,
                        from_cornerstone: true,
                        mime_type: "html",
                        body_html: insertPlaceholders(state.prebuild.templates.feedbackLP.template, { ...state.prebuild.config.placeholders, ...state.prebuild.templates.feedbackLP.placeholders })
                    }
                ),
            ]

            Promise.all(promises).then(([qr, query, portals, feedback, feedbackLP]) => {
                ratings.create({
                    name: state.name,
                    qr_template_id: qr.id,
                    query_template_id: query.id,
                    portals_template_id: portals.id,
                    feedback_template_id: feedback.id,
                    feedback_lp_template_id: feedbackLP.id,
                    rating_portals: state.ratingPortals,
                    config: {
                        defaultConfig: state.prebuild.config,
                        qr: state.prebuild.templates.qr.placeholders,
                        query: state.prebuild.templates.query.placeholders,
                        portals: state.prebuild.templates.portals.placeholders,
                        feedback: state.prebuild.templates.feedback.placeholders,
                        feedbackLP: state.prebuild.templates.feedbackLP.placeholders,
                        key: state.key
                    }
                })
                    .then(rating => {
                        res(rating)
                    })
            })
        })
    },
    getTemplates: async (rating: IRating, templateKeys: TemplateKey[] = [TemplateKey.qr, TemplateKey.query, TemplateKey.portals, TemplateKey.feedback, TemplateKey.feedbackLP]) => {
        return Promise.all([
            templateKeys.includes(TemplateKey.qr) && rating.qr_template_id ? templateApi.get(rating.qr_template_id) : Promise.resolve(null),
            templateKeys.includes(TemplateKey.query) && rating.query_template_id ? templateApi.get(rating.query_template_id): Promise.resolve(null),
            templateKeys.includes(TemplateKey.portals) && rating.portals_template_id ? templateApi.get(rating.portals_template_id) : Promise.resolve(null),
            templateKeys.includes(TemplateKey.feedback) && rating.feedback_template_id ? templateApi.get(rating.feedback_template_id) : Promise.resolve(null),
            templateKeys.includes(TemplateKey.feedbackLP) && rating.feedback_lp_template_id ? templateApi.get(rating.feedback_lp_template_id) : Promise.resolve(null)
        ])
    },
}
export default ratings