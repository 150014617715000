import { withCrud, CrudModel, BaseModel } from "./builder";

export type IRatingOpenTracking = {
}

export type RatingOpenTrackingApi = CrudModel<IRatingOpenTracking> & BaseModel

export default {
    name: "ratingOpenTrackings",
    ...withCrud<IRatingOpenTracking>("rating_open_trackings")
}