export const dateFormatMap: Record<string, string> = {
    "de": "dd.MM.yyyy"
}

export const timeFormatMap: Record<string, string> = {
    "de": "hh:mm Uhr"
}

export const dateTimeFormatMap: Record<string, string> = {
    "de": `${dateFormatMap["de"]} ${timeFormatMap["de"]}`
}