import { TemplateKey } from "models/ratings"
import { insertPortalButtons } from "util/placeholders"
import { IState, steps, } from "./HtmlWizard"

const fillTemplateBuckets = (state: IState) => {
    let newTemplates = { ...state.prebuild.templates }
    const templateKey = steps[state.step].key
    const placeholderKeys = Object.keys(state.prebuild.config.placeholders)
    const keysOfChangedPlaceholder: string[] = []

    // get all keys that have a value property
    placeholderKeys.forEach((key: string) => {
        if (state.prebuild.config.placeholders[key].value) {
            keysOfChangedPlaceholder.push(key)
        }
    })
    //if no value property of key in any bucket add to all buckets
    keysOfChangedPlaceholder.forEach((key: string) => {
        const value = state.prebuild.config.placeholders[key].value
        const defaultPlaceholderObject = { ...state.prebuild.config.placeholders[key] }
        // using any template to check for placeholder object
        if (newTemplates.qr.placeholders) {
            // if placeholder object present but key isnt, put key/value into all buckets
            if (!newTemplates[templateKey as TemplateKey].placeholders![key]) {
                newTemplates = {
                    qr: {
                        template: newTemplates.qr.template,
                        placeholders: { ...newTemplates.qr.placeholders, [key]: { ...defaultPlaceholderObject, value } }
                    },
                    query: {
                        template: newTemplates.query.template,
                        placeholders: { ...newTemplates.query.placeholders, [key]: { ...defaultPlaceholderObject, value } }
                    },
                    portals: {
                        template: newTemplates.portals.template,
                        placeholders: { ...newTemplates.portals.placeholders, [key]: { ...defaultPlaceholderObject, value } }
                    },
                    feedback: {
                        template: newTemplates.feedback.template,
                        placeholders: { ...newTemplates.feedback.placeholders, [key]: { ...defaultPlaceholderObject, value } }
                    },
                    feedbackLP: {
                        template: newTemplates.feedbackLP.template,
                        placeholders: { ...newTemplates.feedbackLP.placeholders, [key]: { ...defaultPlaceholderObject, value } }
                    },
                }
            }
        } else {
            // first changed value initializes the placeholder object with its value
            newTemplates = {
                qr: { template: newTemplates.qr.template, placeholders: { [key]: { ...state.prebuild.config.placeholders[key], value } } },
                query: { template: newTemplates.query.template, placeholders: { [key]: { ...state.prebuild.config.placeholders[key], value } } },
                portals: { template: newTemplates.portals.template, placeholders: { [key]: { ...state.prebuild.config.placeholders[key], value } } },
                feedback: { template: newTemplates.feedback.template, placeholders: { [key]: { ...state.prebuild.config.placeholders[key], value } } },
                feedbackLP: { template: newTemplates.feedbackLP.template, placeholders: { [key]: { ...state.prebuild.config.placeholders[key], value } } }
            }
        }
    })
    return newTemplates
}

//changes value in bucket if present
export const changeValueInBucket = (state: IState, key: string, value: string) => {
    const templateKey = steps[state.step].key
    if (state.prebuild.templates[templateKey as TemplateKey].placeholders) {
        if (state.prebuild.templates[templateKey as TemplateKey].placeholders![key]) {
            const newBucket = {
                ...state.prebuild.templates[templateKey as TemplateKey].placeholders,
                [key]: {
                    //...state.prebuild.templates[templateKey as TemplateKey].placeholders![key], value
                    ...state.prebuild.config.placeholders[key], value
                }
            }
            return newBucket
        }
    }
    // return current bucket if there is no placeholder bucket object or key isnt present in said bucket
    return state.prebuild.templates[templateKey as TemplateKey].placeholders
}


const htmlReducer = (state: any, { type, payload }: { type: string, payload: any }) => {
    switch (type) {
        case "INITIAL_RENDER": {

            const newState = {
                ...state,
                prebuild: payload.prebuild,
                imprint: payload.imprint,
            }

            if (payload.logo !== "") {
                newState.prebuild.config.placeholders.logoURL.value = payload.logo
            }

            return newState
        }

        case "FINISH_SETTINGS": {

            //payload.ratingPortals.length later
            const newPortalTemplate = insertPortalButtons(state.prebuild.templates.portals.template, 1)

            return {
                ...state,
                step: state.step + 1,
                name: payload.name,
                ratingPortals: [payload.ratingPortal], //! is going to change when there are multiple rating portals
                prebuild: {
                    ...state.prebuild, templates: {
                        ...state.prebuild.templates, portals: { template: newPortalTemplate, placeholders: null }
                    }
                }
            }
        }

        case "PREVIOUS_STEP": {
            return { ...state, step: state.step - 1 }
        }

        case "NEXT_STEP": {
            // put all placeholders that god a value into each bucket

            return {
                ...state,
                step: state.step + 1,
                prebuild: {
                    ...state.prebuild,
                    templates: fillTemplateBuckets(state)
                }
            }
        }

        case "DEVICE_CHANGE": {
            return { ...state, device: payload }
        }

        case "UPDATE_PLACEHOLDER_VALUE": {
            // have bucket for each template
            // first change of value in placeholder puts placeholder into each bucket (all templates get changed)
            // following changes only change placeholder in the current template bucket 

            const currentPlaceholders = { ...state.prebuild.config.placeholders }
            const values = payload
            const templateKey = steps[state.step].key
            const key = Object.keys(values)[0] // only one key/value pair per edit
            let diff = { key: "", value: "" }


            if (currentPlaceholders[key].value) {

                if (values[key] !== currentPlaceholders[key].value) {
                    diff = { key, value: values[key] }
                }

            } else if (values[key] !== currentPlaceholders[key].default) {
                diff = { key, value: values[key] }
            }


            const newPlaceholders = {
                ...currentPlaceholders,
                [diff.key]: {
                    ...currentPlaceholders[diff.key],
                    value: diff.value
                }
            }

            const newTemplateBucket = changeValueInBucket(state, key, values[key])

            const newState = {
                ...state,
                prebuild: {
                    ...state.prebuild,
                    config: {
                        ...state.prebuild.config, placeholders: {
                            ...newPlaceholders
                        }
                    },
                    templates: {
                        ...state.prebuild.templates,
                        [templateKey as TemplateKey]: {
                            ...state.prebuild.templates[templateKey as TemplateKey],
                            placeholders: newTemplateBucket
                        }
                        //...state.prebuild.templates[templateKey as TemplateKey]
                    }
                }
            }

            return newState
        }

        case "GOTO_TO_STEP": {
            return { ...state, step: payload.step, confirmEditMode: true }
        }

        case "CONFIRM_EDIT_DONE": {
            return { ...state, step: 6, confirmEditMode: false }
        }


        default: {
            console.warn(type + " not found")
            return state
        }
    }
}

export default htmlReducer