import React from "react"
import { makeStyles, Grid, Tooltip, Grow } from "@material-ui/core"
import StartNodeOptions from "./options/StartNodeOptions"
import EdgeOptions from "./options/Edge"
import SplitQuestionNodeOptions from "./options/SplitQuestionNodeOptions"
import BotResponseNodeOptions from "./options/BotResponse"
import SocialMediaNodeOptions from "./options/SocialMediaNodeOptions"
import FeedbackNodeOptions from "./options/FeedbackNodeOptions"
import EndNodeOptions from "./options/EndNodeOptions"
import AnswerNodeOptions from "./options/SplitAnswerNodeOptions"
import GeneralSettings from "./GeneralSettings"
import DataCollectionNodeOptions from "./options/DataCollectionNodeOptions"
import UserFeedbackNodeOptions from "./options/UserFeedbackNodeOptions"
import { useChatState } from "../ChatContext"
import GoToNodeOptions from "./options/GoToNodeOptions"
import ChatSim from "routes/chat/chatSim/ChatSim"
import { Fa, FaButton } from "styled/muiComponents"
import { getOutgoers, Node, Edge } from "react-flow-renderer"
import { useDialog } from "hooks/useDialog"
import DeleteNodeDialog from "./deleteDialog"
import CreateNodeOptions from "./options/CreateNodeOptions"
import FormikInput from "components/formik/FormikInput"
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from 'theme'
import { useTranslation } from "react-i18next"
import NodeMenu from "./options/NodeMenu"
import { Form } from "react-final-form"
import arrayMutators from 'final-form-arrays'
import { handleHasEdge } from "../chatFunctions"
import ChatSettings from "./options/ChatSettings"
import webforms from "models/webform"
import { validation } from "../ChatValidation"

// const buttonTheme = createMuiTheme({
//     overrides: {
//         MuiTouchRipple: {
//             child: {
//                 backgroundColor: theme.palette.secondary.main
//             }
//         }
//     }
// });

const useStyles = makeStyles((theme) => ({
    container: {
        width: "420px",
        maxWidth: "100%",
        height: "auto",
        // overflow: "hidden",
        minHeight: "300px",
        flexWrap: "nowrap"
    },
    buttonContainer: {
        minWidth: "100%",
        background: theme.palette.primary.light,
        maxHeight: "80px",
        zIndex: 10,
        position: "relative"
    },
    optionsContainer: {
        minWidth: "100%",
        flexGrow: 1,
        overflow: "hidden"
    },
    chatForm: {
        height: "100%"
    },
    formContainer: {
        height: "100%",
        flexWrap: "nowrap"
    },
    nodeNameInput: {
        margin: 0,
        padding: 0,
        "& input": {
            padding: 0,
            margin: 0,
            color: "#ffffff",
            cursor: "pointer",
            fontSize: "1.25rem"
        },
        "& fieldset": {
            border: 0,
        }
    },
    nodeOptionsActionButtons: {
        color: "#ffffff",
        fontSize: "1rem",
        width: "40px",
        transition: ".3s all ease",
        "&:hover": {
            color: theme.palette.secondary.main
        }
    },
    chatStyleContainer: {
        position: "absolute",
        left: "-260px",
        height: "100%",
        minHeight: "300px",
        maxHeight: "100%"
    }
}))

const useStylesChildren = makeStyles((theme) => ({
    settingsContainer: {
        height: "100%",
        width: "100%",
        padding: theme.spacing(2),
        backgroundColor: "#f9f9f9",
        boxSizing: "border-box",
        display: "flex"
    },
    settingsGroup: {
        padding: theme.spacing(2),
        backgroundColor: "#ffffff",
        borderRadius: theme.spacing(1)
    },
    margin: {
        marginBottom: theme.spacing(2)
    },
    settingsGroupPaper: {
        marginBottom: theme.spacing(1.25),
        overflow: "visible",
        width: "100%",
        "&:hover": {
            "& $controls": {
                opacity: 1
            }
        }
    },
    seperator: {
        width: 0,
        borderRight: "1px dashed rgb(7 12 29)",
        borderLeft: "1px dashed rgb(7 12 29)",
        height: "20px",
        marginBottom: theme.spacing(1),
    },
    header: {
        backgroundColor: "#E6ECEF",
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        lineHeight: 0
    },
    controls: {
        opacity: 0,
        transition: "0.2s opacity ease",
    },
    chip: {
        marginRight: theme.spacing(1),
        transition: "0.3s all ease",
        "&:last-child": {
            margin: 0
        },
        "&:hover": {
            backgroundColor: `${theme.palette.secondary.main} !important`,
            color: "#ffffff",
            borderColor: theme.palette.secondary.main,
        }
    },
    icon: {
        padding: theme.spacing(0.5),
        height: "30px",
        width: "30px",
        transition: ".3s all ease",
        "&:hover": {
            color: theme.palette.primary.main
        }
    },
    chatStyleComponent: {
        width: "250px",
        minHeight: "350px",
        backgroundColor: "#ffffff",
        borderRadius: "4px",
        overflow: "hidden",
        height: "100%"
    },
    chatStyleComponentHeader: {
        height: "48px",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(0.5),
        borderBottom: "1px solid #cccccc",
        boxSizing: "border-box",
        background: theme.palette.primary.light,
        color: "#ffffff",
        "& p": {
            fontWeight: "100"
        }
    },
    chatStyleComponentHeaderIcon: {
        color: theme.palette.secondary.main
    },
    chatStyleComponentFooter: {
        borderTop: "1px solid #cccccc",
        padding: theme.spacing(2)
    },
    nodeOptionsActionButtons: {
        color: "#ffffff",
        fontSize: "1rem",
        width: "40px",
        transition: ".3s all ease",
        "&:hover": {
            color: theme.palette.secondary.main
        }
    },
}))

export type OptionProps = {
    setNode: (...args: any) => void
    node: any
    classes: any
}


const OptionsElement = () => {
    const { state, dispatch } = useChatState()
    const classes = useStyles()
    const childClasses = useStylesChildren()
    const { dialog } = useDialog()
    const { t } = useTranslation()

    const initialValues = (state:any) => {
        if (state.testChatActive) {
            return state.chatSettings
        } else if (state.generalSettingsOpen) {
            return state.generalSettings
        } else if (state.selectedHandle) {
            return {}
        } else if (state.nodeMenuOpen) {
            return {}
        } else if (state.selected) {
            return state.selected.data
        }
    }

    // useEffect(() => {
    //     if (state.selected) {
    //         setNode({ ...state.selected })
    //         return
    //     } else {
    //         setNode(null)
    //     }
    // }, [state])


    // TODO ADD CASE FOR HANDLESELECTED
    // ? JUST USE ONE DISPATCH FOR ALL CANCEL?
    // ? HOW TO SAVE WHEN CLICKING ON OVERLAY DIV 
    const handleCancel = () => {
        dispatch({ type: "CANCEL_ALL_SELECT" })
    }

    const handleDelete = () => {
        if (getOutgoers(state.selected, state.elements).length > 0 || state.selected.type === "socialMedia" || state.selected.type === "userFeedback" || state.selected.type === "splitAnswer") {
            dialog.show<any>({
                component: DeleteNodeDialog,
                props: {

                }
            })
                .then((result) => {
                    if (result.success) {
                        dispatch({ type: "DELETE_NODE", payload: { node: state.selected } })
                        return
                    }
                })
                .catch(() => { return })
        } else {
            dispatch({ type: "DELETE_NODE", payload: { node: state.selected } })
            return
        }
    }

    const handleOpenChatSettings = () => {
        dispatch({type: "OPEN_CHAT_SETTINGS"})
    }

    const SelectedRender = () => {
        if (state.selected) {

            if (state.selected.id.includes("e")) {
                return <EdgeOptions state={state} dispatch={dispatch} />
            } else {
                switch (state.selected.type) {
                    case "start": return state.selected && <StartNodeOptions node={state.selected} classes={childClasses} />
                    case "dataCollection": return state.selected && <DataCollectionNodeOptions  node={state.selected} classes={childClasses} />
                    case "userFeedback": return state.selected && <UserFeedbackNodeOptions node={state.selected} classes={childClasses} />
                    case "splitAnswer": return state.selected && <AnswerNodeOptions node={state.selected} classes={childClasses} />
                    case "splitQuestion": return state.selected && <SplitQuestionNodeOptions  node={state.selected} classes={childClasses} />
                    case "botResponse": return state.selected && <BotResponseNodeOptions  node={state.selected} classes={childClasses} />
                    case "socialMedia": return state.selected && <SocialMediaNodeOptions  node={state.selected} classes={childClasses} />
                    case "feedback": return state.selected && <FeedbackNodeOptions node={state.selected} classes={childClasses} />
                    case "end": return state.selected && <EndNodeOptions node={state.selected} classes={childClasses} />
                    case "goTo": return state.selected && <GoToNodeOptions node={state.selected} classes={childClasses} />
                    default: console.warn(state.selected.type, "no options module found for type"); return null
                }
            }
        } else {
            return null
        }
    }

    const handleSubmit = async (values: any, adasd: any) => {
        if (state.testChatActive || state.chatSettingsMenuOpen){
            dispatch({type:"UPDATE_CHAT_SETTINGS", payload:{values}})
            return
        }

        if (state.generalSettingsOpen) {
            if(state.id && validation(state).length === 0){
                await webforms.removeChatIds(values.selectedWebforms.left, parseInt(state.id))
                await webforms.addChatIds(values.selectedWebforms.chosen, state.id)
            }

            dispatch({ type: "EDIT_GENERAL_SETTINGS", payload: {...state.generalSettings, ...values}})
        }

        if (state.selectedHandle) {
            const sourceNode = state.elements.find((element: Node | Edge) => element.id === state.selectedHandle.node.id)
            if (!handleHasEdge(state)) {
                dispatch({
                    type: "ADD_NODE",
                    payload: { type: values.type, name: values.name ?? "", sourceNode }
                })
            } else {
                dispatch({
                    type: "ADD_NODE_BETWEEN",
                    payload: { newNode: { type: values.type, name: values.name ?? "", sourceNode }, addedTo: state.selectedHandle }
                })
            }
        }

        if (!state.selected) {
            return
        }

        if (state.selected) {
            console.log(values)
            
            dispatch({ type: "EDIT_NODE_SETTINGS", payload: { newNode: { ...state.selected, data: { ...state.selected.data, ...values }}, oldNode: state.selected }})
        }
    }  
    const NodeIcon = () => {
        if (state.testChatActive) {
            return <Fa icon={["fal", "flask"]} />
        } else if (state.generalSettingsOpen) {
            return <Fa icon={["fal", "wrench"]} />
        } else if (state.selectedHandle) {
            return <Fa icon={["fal", "message-plus"]} />
        } else if (state.nodeMenuOpen) {
            return <Fa icon={["fal", "square-dashed"]} />
        }
        else {
            switch (state.selected.type) {
                case "start": return <Fa icon={["fal", "rocket-launch"]} />
                case "dataCollection": return <Fa icon={["fal", "message-question"]} />
                case "userFeedback": return <Fa icon={["fal", "comment-alt-lines"]} />
                case "splitAnswer": return <Fa icon={["fal", "mouse-pointer"]} />
                case "splitQuestion": return <Fa icon={["fal", "random"]} />
                case "botResponse": return <Fa icon={["fal", "comment-alt-smile"]} />
                case "socialMedia": return <Fa icon={["fal", "star"]} />
                case "feedback": return <Fa icon={["fal", "stars"]} />
                case "end": return <Fa icon={["fal", "flag-checkered"]} />
                case "goTo": return <Fa icon={["fal", "random"]} />
                default: return <Fa icon={["fal", "gear"]} />
            }
        }
    }

    return (
        <Grid item container direction="column" className={classes.container}>
            <Form initialValues={initialValues(state)}
                onSubmit={handleSubmit}
                subscription={{submitting:true, pristine:true}}
                mutators={{ ...arrayMutators }}
                render={({
                    handleSubmit,
                    submitting,
                    pristine,
                    values,
                    form: {
                        mutators: { push, pop }
                    },

                }) => (
                    <form className={classes.chatForm} onSubmit={handleSubmit}>
                        <Grid container direction="column" alignContent="flex-start" justify="flex-start" className={classes.formContainer}>
                            {/* Buttons and miscellaneous*/}
                            <Grid item container direction="row" className={classes.buttonContainer} style={{ flexWrap: "nowrap" }}>
                                <Grid item container direction="row" justify="flex-start" alignContent="center" style={{ flexGrow: 1, color: "#ffffff", fontSize: "1.25rem", fontWeight: 100, flexWrap: "nowrap" }}>
                                    <Grid item container direction="row" alignContent="center" justify="center" style={{ width: "48px" }}>
                                        <NodeIcon />
                                    </Grid>
                                    <Grid item container direction="row" alignContent="center" justify="flex-start" key="nameInput" style={{ maxWidth: "75%" }}>
                                        {state.selected?.data.name &&
                                            <FormikInput
                                                type="text"
                                                variant="outlined"
                                                name="name"
                                                defaultValue={state.selected.data.name}
                                                //onChange={handleChangeName}
                                                key="nameInput"
                                                className={classes.nodeNameInput}
                                            />
                                        }
                                        {state.testChatActive &&
                                            <span>{t("Teste deinen Chat")}</span>
                                        }
                                        {state.generalSettingsOpen &&
                                            <span>{t("Allgemeine Einstellungen")}</span>
                                        }
                                        {state.selectedHandle &&
                                            <span>{t("Neuen Block hinzufügen")}</span>
                                        }
                                        {state.nodeMenuOpen &&
                                            <span>{t("Blockauswahl")}</span>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item container direction="row" justify="flex-end" alignContent="center" style={{ width: "180px", height: "48px", paddingRight: "4px" }}>
                                    <ThemeProvider theme={theme}>
                                        {(state.selected && state.selected.type !== "start" && state.selected.type !== "splitAnswer") &&
                                            <Grid>
                                                <Tooltip title={t("Element löschen")!}>
                                                    <FaButton onClick={handleDelete} className={classes.nodeOptionsActionButtons}>
                                                        <Fa icon={["fal", "trash"]} fixedWidth />
                                                    </FaButton>
                                                </Tooltip>
                                            </Grid>
                                        }
                                        {!state.testChatActive && !state.nodeMenuOpen && !state.selectedHandle &&
                                            <Grid item>
                                                <Tooltip title={t("Änderungen speichern")!}>
                                                    <FaButton type="submit" className={classes.nodeOptionsActionButtons}>
                                                        <Fa icon={["fas", "check"]} fixedWidth />
                                                    </FaButton>
                                                </Tooltip>
                                            </Grid>
                                        }
                                        <Grid item>
                                            {state.testChatActive &&
                                                <Tooltip title={t("Chat Gestalten")!}>
                                                    <FaButton onClick={handleOpenChatSettings} className={classes.nodeOptionsActionButtons}>
                                                        <Fa icon={["far", "paint-roller"]} fixedWidth />
                                                    </FaButton>
                                                </Tooltip>
                                            }
                                            {state.testChatActive &&
                                                <Tooltip title={t("Chat zurücksetzen")!}>
                                                    <FaButton className={classes.nodeOptionsActionButtons}>
                                                        <Fa icon={["fas", "rotate-left"]} fixedWidth />
                                                    </FaButton>
                                                </Tooltip>
                                            }
                                            <Tooltip title={t("Schließen")!}>
                                                <FaButton onClick={handleCancel} className={classes.nodeOptionsActionButtons}>
                                                    <Fa icon={["fas", "times"]} fixedWidth />
                                                </FaButton>
                                            </Tooltip>
                                        </Grid>
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                            {state.NodeMenuOpen && <div style={{ background: "red" }}></div>}
                            {state.testChatActive && <ChatSim testChat={state} builderDispatch={dispatch} imp={state.imprint} />}
                            {/* { */}
                                 {/* state.chatSettingsMenuOpen && */}
                                <Grow in={state.chatSettingsMenuOpen}>
                                    <div className={classes.chatStyleContainer}>
                                        <ChatSettings classes={childClasses} />
                                    </div>
                                </Grow>
                            {/* } */}
                            {state.generalSettingsOpen && <GeneralSettings state={state} dispatch={dispatch} classes={childClasses} />}
                            {state.selectedHandle && <CreateNodeOptions state={state} dispatch={dispatch} classes={childClasses} />}
                            {state.nodeMenuOpen && <NodeMenu state={state} dispatch={dispatch} classes={childClasses} />}
                            {state.selected && SelectedRender()}
                        </Grid>
                    </form>
                )}
            />
        </Grid>
    )
}

export default OptionsElement