import React, { useEffect, useState } from "react"
import { Block, BlockTitle, BlockContent } from 'components/Block'
import { Typography } from '@material-ui/core'
import { Grid, Fa } from "styled/muiComponents"
import { useTranslation } from "react-i18next"
import PagePadding from 'components/PagePadding'
import Button from "components/Button/Button"
import { useParams, useHistory } from 'react-router-dom'
import feedbacks, { IFeedback } from "models/feedbacks"
import LoadingAnimation from "components/LoadingAnimation"
import { makeStyles } from '@material-ui/core'


type IRouteParams = {
    id: string
}

const useStyles = makeStyles(theme => ({
    label: {
        fontWeight: 400,
    },
}))

const Feedback = () => {
    const { t } = useTranslation()
    const params = useParams<IRouteParams>()
    const history = useHistory()
    const [feedback, setFeedback] = useState<IFeedback | null>(null)
    const classes = useStyles()

    useEffect(() => {
        const fetchFeedback = async () => {
            const response = await feedbacks.get(parseInt(params.id))
            setFeedback(response)
        }
        fetchFeedback()
    }, [params.id])


    const handleBack = () => {
        history.go(-1)
    }

    if (!feedback) {
        return <LoadingAnimation />
    }

    return (
        <PagePadding>
            <Grid container spacing={3} justify="center">
                <Grid item xs={12} lg={6}>
                    <Block>
                        <BlockTitle>
                            <Typography variant="h2">{t("Feedback")}</Typography>
                        </BlockTitle>
                        <BlockContent>
                            <Grid container direction="column">
                                <Grid item>
                                    <Grid item md={4} sm={12} className={classes.label}>
                                        {t("Name")}
                                    </Grid>
                                    {feedback.name !== "" ?
                                        <p>{feedback.name}</p>
                                        :
                                        <p>{t("Nicht angegeben")}</p>
                                    }
                                </Grid>

                                <Grid item>
                                    <Grid item md={4} sm={12} className={classes.label}>
                                        {t("Email")}
                                    </Grid>
                                    {feedback.email !== "" ?
                                        <p>{feedback.email}</p>
                                        :
                                        <p>{t("Nicht angegeben")}</p>
                                    }
                                </Grid>
                                <Grid item>
                                    <Grid item md={4} sm={12} className={classes.label}>
                                        {t("Feedback")}
                                    </Grid>
                                    <p>{feedback.feedback}</p>
                                </Grid>
                                <Grid item container justify='space-between' mt={2}>
                                    <Grid item>
                                        <Button color="error" variant="contained" size="small" onClick={handleBack}>
                                            <Fa icon={["far", "times"]} mr={1} />{t("Zurück")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </BlockContent>
                    </Block>
                </Grid>
            </Grid>
        </PagePadding >
    )
}

export default Feedback