import { CompositeDecorator } from "draft-js";
import React, { CSSProperties } from "react"
import { Fa } from 'styled/muiComponents'

const styles = {
    root: {
        fontFamily: '\'Helvetica\', sans-serif',
        padding: 20,
        width: 600,
    },
    editor: {
        border: '1px solid #ccc',
        cursor: 'text',
        minHeight: 80,
        padding: 10,
    },
    button: {
        marginTop: 10,
        textAlign: 'center',
    },
    immutable: {
        backgroundColor: "rgb(7 12 29)",
        padding: "6px 8px",
        color: "#fff",
        borderRadius: "4px",
        whiteSpace: "nowrap",
        display: "inline-block",
        lineHeight: 0
    },
    mutable: {
        backgroundColor: 'rgba(204, 204, 255, 1.0)',
        padding: '2px 0',
    },
    segmented: {
        backgroundColor: 'rgba(248, 222, 126, 1.0)',
        padding: '2px 0',
    },

};

export const getDecoratedStyle = (mutability: string) => {
    switch (mutability) {
        case 'IMMUTABLE': return styles.immutable;
        case 'MUTABLE': return styles.mutable;
        case 'SEGMENTED': return styles.segmented;
        default: return null;
    }
}

export const getEntityStrategy = (mutability: string) => {
    return function (contentBlock:any, callback:any, contentState:any) {
        contentBlock.findEntityRanges(
            (character:any) => {
                const entityKey = character.getEntity();
                if (entityKey === null) {
                    return false;
                }
                return contentState.getEntity(entityKey).getMutability() === mutability;
            },
            callback
        );
    };
}
export const TokenSpan = (props: any) => {
    const style = getDecoratedStyle(
        props.contentState.getEntity(props.entityKey).getMutability()
    );

    return (
        <div data-offset-key={props.offsetkey} style={style as CSSProperties}>
           <Fa icon={["fas", "user"]} size="xs" mr={0.5} /> {props.children}
        </div>
    );
};

export const decorator = new CompositeDecorator([
    {
        strategy: getEntityStrategy('IMMUTABLE'),
        component: TokenSpan,
    },
]);



