import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
}

const MyDate = ({ value }) => {
    const { i18n } = useTranslation()
    if(value === null || value === undefined) {
        return null
    }

    return (
        <span> { (new Date(value)).toLocaleString(i18n.language, options) }</span>
    )
}

MyDate.propTypes = {
    value: PropTypes.string
}

MyDate.defaultProps = {
    value: null
}

export default MyDate