import React, {useState} from "react"
import {Grid, TextField, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import Button from "components/Button/Button"
import PerfectScrollbar from "react-perfect-scrollbar"

const useStyles = makeStyles({

})

const URL = ({selectImage}) => {
    const {t} = useTranslation()
    const classes = useStyles()

    const [uploadNoEnding, setUploadNoEnding] = useState(false)
    const [showNoEndingDetected, setShowNoEndingDetected] = useState(false)
    const [showInvalidUrlError, setShowInvalidUrlError] = useState(false)
    const [imageURL, setImageURL] = useState("")

    const handleUploadURL = async () => {
        const data = new FormData()
        const timeout = 5000;
        let timedOut = false;
        let timer;
        const img = new Image();
        img.onerror = img.onabort = (() => {
            if (!timedOut) {
                clearTimeout(timer);
                setShowInvalidUrlError(true)
                setShowNoEndingDetected(false)
            }
        });
        img.onload = (() => {
            if (!timedOut) {
                clearTimeout(timer);
                setShowInvalidUrlError(false)
                if (imageURL.match(/\w+\.(jpg|jpeg|gif|png)$/gi) === null && uploadNoEnding) {
                    data.set("image", imageURL)
                    //create here
                    selectImage({image:imageURL})
                } else if (imageURL.match(/\w+\.(jpg|jpeg|gif|png)$/gi) === null && !uploadNoEnding) {
                    setShowNoEndingDetected(true)
                    setUploadNoEnding(true)
                } else {
                    //create here
                    selectImage({image:imageURL})
                }
            }
        });
        img.src = imageURL;
        //await images.saveURL(data)
        timer = setTimeout(() => {
            timedOut = true;
            setShowInvalidUrlError(true)
            setShowNoEndingDetected(false)
        }, timeout);
    }

    const handleUploadURLAnyway = () => {
        selectImage({image: imageURL})
    }

    const handleChangeURL = (e) => {
        setImageURL(e.target.value)
    }
    return (
        <PerfectScrollbar>
        <Grid item>
                <TextField onChange={e => {handleChangeURL(e); setShowInvalidUrlError(false);setUploadNoEnding(false);setShowNoEndingDetected(false)}}
                FormHelperTextProps={{
                    className: classes.helperText
                }}
                helperText = { showInvalidUrlError ? "Keine URL" : showNoEndingDetected ? 'Bildformat konnte nicht erkannt werden. Da E-Mail Clients nicht alle Bildformate problemlos anzeigen können, empfehlen wir die Formate JPEG, GIF, oder PNG. Falls dein Bild eines der gennanten Formate hat oder du ein anderes Format benutzten möchtest, klicke auf "Bild trotzdem einfügen".' : null}
                value={imageURL}
                />
                <Button onClick={handleUploadURL} variant="contained" color="primary">{t("Nutzen")}</Button>
                {showNoEndingDetected ? <Button onClick={handleUploadURLAnyway}>{t("Bild trotzdem einfügen")}</Button> : null}
                {imageURL && <img src={imageURL} alt="Preview"/>}
            </Grid>
            </PerfectScrollbar>
    )
}


export default URL