import React from 'react'
import { DialogProps } from '../../../../hooks/useDialog'
import {useTranslation, Trans } from 'react-i18next'
import { Dialog } from '@material-ui/core'
import DialogTitle from '../../../../dialogs/DialogTitle'
import DialogContent from '../../../../dialogs/DialogContent'
import DialogActions from '../../../../dialogs/DialogActions'
import Button from '../../../../components/Button/Button'
import RecipientApi from '../../../../models/recipient'
import { IRecipientTag } from '../../../../types/recipient'
import { makeStyles } from '@material-ui/core'
import { Fa, Grid } from '../../../../styled/muiComponents'

export type Response = IRecipientTag[] | null

export type Props = {
    tag: IRecipientTag
    recipientId: number
}


const useStyles = makeStyles(theme => ({
    confirm: {
        backgroundColor: theme.palette.companyRed.main
    }
}))

const ConfirmRemoveRecipientTag = ({ open, onClose, onError, recipientId, tag}: Props & DialogProps) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const handleConfirm = async () => {
        try {
            await RecipientApi.one(recipientId).removeTags([tag.id])
            const tags = await RecipientApi.one(recipientId).tags()
            onClose(tags)
            return Promise.resolve()
        }catch(e) {
            console.error(t("Tag konnte nicht entfernt werden"))
        }
    }

    const handleClose = () => {
        onClose(null)
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="xs">
            <DialogTitle onClose={handleClose}>
                <Fa icon={["fal", "user-tag"]} mr={1.75} />{t("Tag entfernen")}
            </DialogTitle>
            <DialogContent>
                <Trans>
                    Möchtest du den Tag <strong>"{tag.name}"</strong> wirklich entfernen?
                </Trans>
            </DialogContent>
            <DialogActions>
                <Grid container justify="space-between">
                    <Button  variant="contained" size="small" color="error" onClick={handleClose}>
                        <Fa icon={["far", "times"]} mr={1} />{t("Abbrechen")}
                    </Button>
                    <Button variant="contained" size="small" color="success" type="submit" onClick={handleConfirm}>
                        <Fa icon={["far", "check"]} mr={1} />{t("Tag entfernen")}
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog >
    )
}
export default ConfirmRemoveRecipientTag