import { withCrud, CrudModel, BaseModel } from "./builder";
import { post, get, put } from 'api/crud'

export type ITariff = {
    id: number
    price: number
    name: string
    provider: string
    prepaid: boolean
    purchase_date: Date | null
    end_of_billing_period: Date | null
    is_candidate: number
    type: string
    email_volume: number
    email_volume_remaining: number
    subscription_volume: number
    subscription_volume_remaining: number
    chats: number
    selections: number
    templates: number
    pdf_templates: number
    automations: number
    cornerstone_webforms: number
    webforms: number
    custom_fields: number
    groups: number
    blacklists: number
    whitelists: number
    shop_connections: number
    bindings: number
    image_volume: number
}

export type ITariffVolumes = {
    email_volume: number
    email_volume_remaining: number
    subscription_volume: number
    subscription_volume_remaining: number
    chats: number
    selections: number
    templates: number
    pdf_templates: number
    automations: number
    cornerstone_webforms: number
    webforms: number
    custom_fields: number
    groups: number
    blacklists: number
    whitelists: number
    shop_connections: number
    bindings: number
    image_volume: number
}

export type IPricelistOption = {
    volume: number,
    type: string,
    price_per_month: number
}
export type tariffApi = CrudModel<ITariff> & BaseModel

export default {
    name: "tariffs",
    ...withCrud<any>("tariffs"),


    cancelTariff: () => put('tariffs/cancel_tariff'),

    getPriceListMonthly: () => get<IPricelistOption[]>('tariffs/pricelist'),

    purchaseMonthly: (volume:number, type:string) => post('tariffs/purchase_tariff', {volume, type}),

    // purchasePrepaid

    getCandidate: () => get<ITariff>('tariffs/candidate_tariff'),

    getCurrent: () => get<ITariff>('tariffs/current_tariff'),

    testMonth: () => get<ITariff[]>('tariffs/test_month_active')


    // test: (tariff_module: String) => post("tariffs/nice", { tariff_module }),

    // testBillingEnd: () => post("tariffs/test_end"),

    // getPurchasableMonthly: () => get<ITariff[]>("tariffs/purchasable_monthly"),

    // getPurchasablePrepaid: () => get<ITariff[]>('tariffs/purchasable_prepaid'),

    // purchaseMonthly: (id: number) => post('tariffs/purchase_monthly', { id }),

    // purchasePrepaid: (id: number) => post('tariffs/purchase_prepaid', { id }),

    // cancelTariff: (id: number) => post('tariffs/cancel_tariff', { id }),

    // alterTariff: (to_be_altered_id: number, desired_id: number) => post('tariffs/alter_tariff', { to_be_altered_id, desired_id }),

    // upgradeTariffNow: (to_be_altered_id: number, desired_id: number) => post('tariffs/upgrade_now', { to_be_altered_id, desired_id }),

    // getCandidates: () => get<ITariff[]>('tariffs/candidate_tariffs'),

    // myMonthly: () => get<ITariff[]>('tariffs/my_monthly'),

    // myPrepaid: () => get<ITariff[]>('tariffs/my_prepaid'),

    // usedVolume: () => get<ITariffVolumes>('tariffs/used_volume'),

    // totalMonthlyVolume: () => get<ITariffVolumes>('tariffs/total_monthly_volume'),

    // totalPrepaidVolume: () => get<ITariffVolumes>('tariffs/total_prepaid_volume'),

    // possibleCandidates: (modul:string) => post<ITariff[]>('tariffs/possible_candidates',{modul}),

    //TESTFUNKTIONS REMOVE LATER

    // setDateForDiscountTest: () => post('tariffs/set_test_dates_discount')
}