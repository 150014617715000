import React from "react"
import { CancelButton, IState, steps } from "../HtmlWizard"
import Button from "components/Button/Button"
import { Typography, Grid, Fa } from "styled/muiComponents"
import { Divider } from "styled/muiComponents"
import { useTranslation } from "react-i18next"
import { IStep } from "../HtmlWizard"
import Pixi from "components/Pixi"
import { useStyles } from "./HtmlSettings"
import Typist from "react-typist"
import { useDialog } from 'hooks/useDialog';
import { Block, BlockContent, BlockTitle } from "components/Block"
import HtmlTemplateView from "../htmlViewer/htmlTemplateView"
type Props = {
    saveHtml: () => void
    state: IState
    dispatch: any
}

const ConfirmHtml = ({ saveHtml, state, dispatch }: Props) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { dialog } = useDialog()

    const onPrevious = () => {
        dispatch({type:"PREVIOUS_STEP"})
    }

    const handleView = (idx: number) => {
        dialog.show({
            component: HtmlTemplateView,
            props: {
                state,
                editorView: true,
                idx
            }
        })
    }

    const handleJumpTo = (idx: number) => {
        dispatch({type: "GOTO_TO_STEP", payload:{ step: idx }})
    }

    return (
        <>
            <Grid container item direction="column" className={classes.root}>
                <Grid container item direction="column" justify="center" alignItems="center" style={{ margin: "auto" }}>
                    <Grid container direction="row" justify="flex-start" className={classes.form}>
                        <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                            <Grid item sm={4}>
                                <div style={{ maxWidth: "150px" }}>
                                    <Pixi size="full" pose="waiting"></Pixi>
                                </div>
                            </Grid>
                            <Grid item sm={8}>
                                <div className={classes.headerDescription} >
                                    <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                        <Typography variant="h5" className={classes.pixiSpeech}>
                                            {t('Super, du bist fast fertig. Überprüfe noch einmal alle deine Angaben. Wenn du fertig bist, klicke auf "Speichern und Beenden".')}
                                        </Typography>
                                    </Typist>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item container className={classes.formContent}>
                            <Grid item xs={12}>
                                <Block>
                                    <BlockTitle>
                                        <Typography variant="h2">{t("Einstellungen")}</Typography>
                                    </BlockTitle>
                                    <BlockContent>
                                        <Grid item container direction="column" sm={12}>
                                            <Grid item container alignItems="center" mt={1} mb={1}>
                                                <Fa icon={["fal", "memo-pad"]} fixedWidth mr={2} /><span>{state.name}</span>
                                            </Grid>
                                            <Grid item container alignItems="center" mt={1} mb={1} wrap="nowrap">
                                                {/* //TODO change to map later when multiple ratingPortals */}
                                                <Fa icon={["fal", "globe"]} fixedWidth mr={2} /><span>{state.ratingPortals[0]}</span> 
                                            </Grid>
                                        </Grid>
                                    </BlockContent>
                                </Block>
                                <Typography variant="h3" mb={2} mt={3}>{t("Bewertungsstrecke")}</Typography>
                                {steps.map((step: IStep, idx: number) => {
                                    if (idx === 0 || idx === 6) {
                                        return null
                                    } else {
                                        return (
                                            <div key={idx}>
                                                <Grid container direction="row" justify="space-between" alignItems="center" >
                                                    <Grid item mt={1} mb={1}>
                                                        {step.label}
                                                    </Grid>
                                                    <Grid item mt={1} mb={1}>
                                                        <Button color="viewButton" variant="action" disableElevation size="small" onClick={() => handleView(idx-1)} tooltip={t("Ansehen")} tooltipProps={{ placement: 'left' }}>
                                                            <Fa icon={["fas", "eye"]} />
                                                        </Button>
                                                        <Button color="editButton" variant="action" disableElevation size="small" onClick={() => handleJumpTo(idx)} tooltip={t("Bearbeiten")} tooltipProps={{ placement: 'left' }}>
                                                            <Fa icon={["fas", "wrench"]} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </div>
                                        )
                                    }
                                })}
                            </Grid>
                        </Grid>

                        <Grid item sm={12} container justify="space-between" className={classes.navigation}>
                            <Grid item>
                                <CancelButton destination="ratings" />
                                <Button onClick={onPrevious} variant="contained" disableElevation color="success" size="small">
                                    <Fa icon={["fas", "chevron-left"]} mr={1}></Fa>
                                    {t("Zurück")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="success" onClick={saveHtml} size="small">
                                    <Fa icon={["fas", "check"]} mr={1} />
                                    {t("Speichern und Beenden")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default ConfirmHtml