import React from 'react'
import DelayedTextField, { Props as DelayedTextFieldProps } from '../DelayedTextField'
import { Grow, makeStyles, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'

type Props = {
    onHide: (event: React.MouseEvent<HTMLButtonElement>) => void
} & DelayedTextFieldProps

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: '1 0 auto',
        alignItems: "center"
    },
    searchIcon: {
        marginRight: theme.spacing(1),
        color: "rgba(0, 0, 0, 0.54)"
    }
}))

const SearchTextField: React.FC<Props> = ({ onHide, ...props}) => {
    const classes = useStyles()
    return (
        <Grow appear in={true} timeout={300}>
            <div className={classes.root}>
                <SearchIcon className={classes.searchIcon}/>
                <DelayedTextField fullWidth autoFocus {...props} />
                <IconButton onClick={onHide}>
                    <CloseIcon/>
                </IconButton>
            </div>
        </Grow>
    )
}

export default SearchTextField