import React, { useState } from "react"
import { useTranslation } from 'react-i18next'
import FormikInput from "components/formik/FormikInput"
import { Grid } from "@material-ui/core"
import Grow from '@material-ui/core/Grow'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import { FieldArray } from "react-final-form-arrays"
import { Alert, AlertTitle } from '@material-ui/lab';
import { Typography } from 'styled/muiComponents'
import webforms from "models/webform"
import { validation } from "../ChatValidation"

const GeneralSettings = ({ state, dispatch, classes }: any) => {
    const { t } = useTranslation()
    const { fieldDefaults, ...nonPlaceholderFields } = state.generalSettings

    // formikinputs have to be controlled :/
    const [values, setValues] = useState({ ...nonPlaceholderFields, ...fieldDefaults })

    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    //     setValues({ ...values, [key]: e.target.value })
    // }

    return (
        <PerfectScrollbar style={{borderBottomRightRadius: "4px", borderBottomLeftRadius: "4px"}}>
            <Grid container className={classes.settingsContainer}>
                <Grid item style={{ width: "100%" }}>
                    <Grow in={true}>
                        <Grid container direction="column">
                            <Typography variant="h3" style={{marginTop: "8px", marginBottom: "8px", fontWeight: 400}}>{t("Chat-Einstellungen")}</Typography>
                            <FormikInput
                                //classes={textfieldclasses}
                                type="text"
                                variant="outlined"
                                name="chatName"
                                label={t("Name des Chats")}
                                value={values.chatName}
                            />
                            <Typography variant="h3" style={{marginTop: "24px", marginBottom: "16px", fontWeight: 400}}>{t("Standardwerte für Platzhalter")}</Typography>
                            <Alert severity="info" variant="outlined" style={{marginBottom: "16px", backgroundColor: "#ffffff"}}>
                                <AlertTitle>{t("Standardwerte")}</AlertTitle>
                                {t("Die Standardwerte für Platzhalter werden dann ausgespielt, wenn du einen Platzhalter im Chat verwendest, zu dem es noch keinen Wert gibt.")}
                            </Alert>
                            {(state.id && validation(state).length === 0) ? 
                            <FormikInput 
                                name="selectedWebforms"
                                type="multiSelect"
                                model={webforms}
                                async={true}
                            />
                            :null}
                            <FieldArray name="placeholder" subscription={{ submitting: true }}>
                                {({ fields }) =>
                                    fields.map((name: string, index: number) => {
                                        switch (state.fields[index].type) {
                                            case "string": {
                                                return (
                                                    <FormikInput
                                                        name={`${name}.${state.fields[index].name}`}
                                                        label={t(`/fields:${state.fields[index].name}`)}
                                                        type="string"
                                                        //typeParams={field.typeParams}
                                                        variant="outlined"
                                                        //label="Age"
                                                    />
                                                )
                                            }

                                            case "date": {
                                                return (
                                                    <FormikInput
                                                        name={`${name}.${state.fields[index].name}`}
                                                        label={t(`/fields:${state.fields[index].name}`)}
                                                        type={"date"}
                                                        variant="outlined" // ToDo make outlined version work
                                                    />
                                                )
                                            }
                                            case "boolean": {
                                                return (
                                                    <FormikInput
                                                        name={`${name}.${state.fields[index].name}`}
                                                        label={state.fields[index].name}
                                                        type="boolean"
                                                        variant="outlined"
                                                    />
                                                )
                                            }
                                            case "enum": {
                                                return (
                                                    <FormikInput
                                                        name={`${name}.${state.fields[index].name}`}
                                                        label={state.fields[index].name}
                                                        type="enum"
                                                        typeParams={state.fields[index].type_params}
                                                        variant="outlined"
                                                    />
                                                )
                                            }
                                        }
                                    })
                                }
                            </FieldArray>
                        </Grid>
                    </Grow>
                </Grid>
            </Grid>
        </PerfectScrollbar>
    )
}

export default GeneralSettings