import React from "react"
import { MenuItem} from "@material-ui/core"
import FormikSelect from "./Select"
import { useTranslation } from "react-i18next"

const Bool = ({...props}) => {
  const {t} = useTranslation()
  const {input:{name, value, ...field}, meta } = props
  
  const handleBlur = (e) => {
    if(props.onBlur) {
      props.onBlur(e)
    }
    field.onBlur(e)
  }

  const handleChange = (e) => {
    if(props.onChange) {
      props.onChange(e)
    }
    field.onChange(e)
  }


  return(
  <FormikSelect 
    value={value}
    {...props}
    handleBlur={handleBlur}
    handleChange={handleChange} 
  > 
        <MenuItem value={true}>{ t(`/common:true`) }</MenuItem>
        <MenuItem value={false}>{ t(`/common:false`) }</MenuItem>
  </FormikSelect>
  )
}

export default Bool