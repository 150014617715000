import React, { useEffect, useState } from 'react'
import { Card, Grid, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom';
import { useDialog } from 'hooks/useDialog';
import PagePadding from 'components/PagePadding';
import { useSidebar } from 'hooks/useSidebar';
import LoadingAnimation from 'components/LoadingAnimation'
import { Fa, Typography } from 'styled/muiComponents'
import ratings, { IRating, TemplateKey } from 'models/ratings';
import feedbacks, { IFeedback } from 'models/feedbacks';
import MUIDataTable, { FilterType, CustomMUIDataTableOptions, CustomMUIDataTableColumnDef } from "../../../../components/table/MUIDataTable";

import Button from 'components/Button/Button';
import HtmlTemplateView from './HtmlBuildWizard/htmlViewer/htmlTemplateView';
import Zoom from '@material-ui/core/Zoom';
import NoPermission from 'components/NoPermission';
import { IPermissionModules } from 'hooks/useTariff';

const useStyles = makeStyles<Theme, {}>(theme => ({
    dashboardCard: {
        height: "200px",
        width: "200px",
        padding: theme.spacing(3),
        transition: "color 0.3s, box-shadow 0.4s, background-color 0.3s ease",
        "&:hover $cardLink": {
            //   color: "#ffc600"
        },
        "&:hover": {
            boxShadow: "0px 2px 3px -1px rgb(0 0 0 / 20%), 0px 3px 10px 5px rgb(0 0 0 / 10%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        },
        "&:hover $createCardIcon": {
            color: "#ffc600"
        },
        "&:hover $actionButtonsWrapper": {
            backgroundColor: "#ffffff",
            //backdropFilter: "blur(5px)"
        }
    },
    createCardIcon: {
        transition: "0.3s all linear",
    },
    cardLink: {
        transition: "0.3s all linear",
    },
    headline: {
        fontSize: "2em",
        fontWeight: 300,
        marginTop: theme.spacing(7),
        marginBottom: theme.spacing(3),
        display: "flex",
        flexWrap: "nowrap",
        alignContent: "center",
        alignItems: "center",
    },
    yellowHeadlineIcon: {
        color: "#ffc600"
    },
    cardContent: {
        //marginTop: "35px"
    },
    deleteCard: {
        width: "48px",
        marginRight: "-10px",
        marginTop: "-10px",
        "&:hover": {
            color: theme.palette.secondary.main
        }
    },
    actionButtonsWrapper: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    zoom: {
        transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'scale(0)',
    },
    zoomEnter: {
        transform: 'scale(1)',
        transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
        }),
    }
}))


const columns: CustomMUIDataTableColumnDef[] = [
    {
        name: "id",
        label: "#",
        options: {
            align: "right"
        }
    },
    {
        name: "email",
        options: {
            customBodyRender: (value) => value === "" ? <span>Nicht Angegeben</span> : <span>{value}</span>
        }
    },
    {
        name: "name",
        options: {
            customBodyRender: (value) => value === "" ? <span>Nicht Angegeben</span> : <span>{value}</span>
        }
    },
    {
        name: "feedback",
        options: {
            customBodyRender: (value) => value.length > 13 ? <span>{value.slice(0, 13)}...</span> : <span>{value}</span>
        }
    }
];

const Ratings = () => {
    const classes = useStyles({})
    const { fixedClose } = useSidebar()
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const { dialog } = useDialog()
    const [ratingsList, setRatingsList] = useState<any | null>(null)
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
    const [menuIndex, setMenuIndex] = useState<null | number>(null)
    const [show, setShow] = useState(true);
    const options: CustomMUIDataTableOptions = {
        filterType: "multiselect" as FilterType,
        download: false
    };

    useEffect(() => {
        const fetchRatings = async () => {
            const response = await ratings.getList() //TODO next 10
            setRatingsList(response.data)
        }
        fetchRatings()
    }, [])
    const [isShown, setIsShown] = React.useState<Record<number, boolean>>({});
    const handleMouseEnter = (index: number) => {
        setIsShown(prevState => ({
            ...prevState,
            [index]: true
        }));
    };

    const handleMouseLeave = (index: number) => {
        setIsShown(prevState => ({
            ...prevState,
            [index]: false
        }));
    };
    const handleCreate = () => {
        fixedClose()
        history.push(`${location.pathname}/create`)
    }

    const handleEdit = (item: any, type: string) => {
        history.push(`/app/stock/ratings/edit/${item.id}/${type}`)
    }

    const handleGoToQRCode = (rating: IRating) => {
        history.push(`${location.pathname}/${rating.id}/qrcode`)
    }

    const handleView = (rating: IRating) => {
        dialog.show({
            component: HtmlTemplateView,
            props: {
                state: rating,
                editorView: false,
                idx: 0
            }
        })
    }

    const handleOpenEditMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        setMenuIndex(parseInt(e.currentTarget.id))
        setMenuAnchorEl(e.currentTarget)
    }

    const handleCloseEditMenu = () => {
        setMenuAnchorEl(null)
    }

    const handleDelete = async (rating: IRating) => {
        // TODO
    }

    const handleViewFeedback = (item: IFeedback) => {
        history.push(`/app/stock/feedback/${item.id}`) // todo not hardcode
    }

    if (!ratingsList) {
        return (
            <LoadingAnimation />
        )
    }

    return (
        <>
            <NoPermission module={IPermissionModules.bindings} />

            <PagePadding>
                <Typography variant='h2' className={classes.headline} style={{ marginTop: 0 }}>
                    <Fa icon={["fal", "star"]} fixedWidth mr={1} className={classes.yellowHeadlineIcon} />
                    {t("Meine Bewertungsstrecken")}
                </Typography>
                <Grid container direction="row" spacing={2}>
                    <Grid item>
                        <Card className={classes.dashboardCard} onClick={handleCreate} style={{ cursor: "pointer" }}>
                            <Grid container direction='column' justify='center' alignContent='center' alignItems='center' style={{ height: "100%" }}>
                                <Grid item>
                                    <Fa icon={["fat", "plus"]} size="4x" style={{ display: "block" }} className={classes.createCardIcon} mb={2} />
                                </Grid>
                                <Grid item style={{ textAlign: "center" }}>
                                    <Typography className={classes.cardLink}>{t("Bewertungsstrecke erstellen")}</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    {ratingsList.map((rating: any, index: number) =>
                        <Grid item>
                            <Card className={classes.dashboardCard} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)}
                            //  TODO onClick={() => handleEdit(chat, "saved")}
                            >
                                <Grid container direction="column" justify='center' alignItems='center' style={{height: "100%", position: "relative"}}>
                                    <Zoom in={!isShown[index]}>
                                        <Grid container direction="column" justify='center' alignItems='center' style={{height: "100%", position: "relative"}}>
                                            <Grid item className={classes.cardContent}>
                                                <Typography className={classes.cardLink} style={{ fontSize: "1.25em" }}>{rating.name}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>ID: {rating.id}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Zoom>
                                    {isShown[index] ?
                                        <Grid item container justify='center' alignItems='center' className={classes.actionButtonsWrapper}>
                                            <Zoom in={isShown[index]}>
                                                <Grid container item justify='center' alignItems='center' direction='column'>
                                                    <Grid item>
                                                        <Typography style={{ fontSize: "1em", fontWeight: "bold" }} mb={1}>{rating.name}</Typography>
                                                    </Grid>
                                                    <Grid container item justify='center' alignItems='center'>
                                                        <Button key={"show"} onClick={() => handleView(rating)} color="viewButton" variant="action" disableElevation size="small" tooltip={t("Anzeigen")} tooltipProps={{ placement: 'bottom' }}>
                                                            <Fa icon={["fas", "eye"]} />
                                                        </Button>
                                                        <Button key={"qrcode"} onClick={() => handleGoToQRCode(rating)} color="viewButton" variant="action" disableElevation size="small" tooltip={t("QR-Code & URLs anzeigen")} tooltipProps={{ placement: 'bottom' }}>
                                                            <Fa icon={["fas", "qrcode"]} />
                                                        </Button>
                                                        <>
                                                            <Button id={rating.id.toString()} key={"edit"} color="editButton" variant="action" disableElevation size="small" tooltip={t("Bearbeiten")} tooltipProps={{ placement: 'bottom' }} onClick={handleOpenEditMenu}>
                                                                <Fa icon={["fas", "wrench"]} />
                                                            </Button>
                                                            {rating.id === menuIndex ?
                                                                <Menu
                                                                    key="edit-menu"
                                                                    anchorEl={menuAnchorEl}
                                                                    keepMounted
                                                                    open={Boolean(menuAnchorEl)}
                                                                    onClose={handleCloseEditMenu}
                                                                >
                                                                    {/* <MenuItem onClick={() => handleEdit(rating)}>{t("Einstellungen")} </MenuItem> */}
                                                                    <MenuItem onClick={() => handleEdit(rating, TemplateKey.qr)}>{t("Landingpage mit QR-Code")} </MenuItem>
                                                                    <MenuItem onClick={() => handleEdit(rating, TemplateKey.query)}>{t("Vorqualifizierung")} </MenuItem>
                                                                    <MenuItem onClick={() => handleEdit(rating, TemplateKey.portals)}>{t("Bewertungsportal")} </MenuItem>
                                                                    <MenuItem onClick={() => handleEdit(rating, TemplateKey.feedback)}>{t("Feedback-Formular")} </MenuItem>
                                                                    <MenuItem onClick={() => handleEdit(rating, TemplateKey.feedbackLP)}>{t("Feedback-Bestätigungsseite")} </MenuItem>
                                                                </Menu>
                                                                :
                                                                null
                                                            }

                                                        </>
                                                        <Button key={"delete"} color="deleteButton" variant="action" disableElevation size="small" tooltip={t("Löschen")} tooltipProps={{ placement: 'bottom' }} onClick={() => handleDelete(rating)}> {/* TODO reload on delete*/}
                                                            <Fa icon={["fas", "trash"]} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Zoom>
                                        </Grid>
                                        : null}
                                </Grid>
                            </Card>
                        </Grid>

                    )}
                </Grid>

                <Typography variant='h2' className={classes.headline}>
                    {t("Eingereichte Feedbacks")}
                </Typography>
                <MUIDataTable<IFeedback>
                    translateLabels={true}
                    modelApi={feedbacks}
                    title={t("Feedbacks")}
                    columns={columns}
                    options={options}

                    customActionsRender={(item, reload) => (
                        [
                            <Button key={"show"} color="viewButton" variant="action" disableElevation size="small" tooltip={t("Anzeigen")} tooltipProps={{ placement: 'left' }} onClick={() => handleViewFeedback(item)}>
                                <Fa icon={["fas", "eye"]} />
                            </Button>
                        ]
                    )}
                />


            </PagePadding>
        </>
    )
}

export default Ratings