import { withCrud, CrudModel, BaseModel } from "./builder"

export type IPDFTemplate = {
    id: number
    name: string
    key: string
    settings: any
    type: string
    size: string
    webform_id: string
    webform_name: string
    config: Config
}

type Config = {
    editUserStyles: any
    editUserText: any
    QR: any
}

export type PDFTemplateApi = CrudModel<IPDFTemplate> & BaseModel

export default {
    name: "pdfTemplates",
    ...withCrud<IPDFTemplate>("pdf_templates")
}

