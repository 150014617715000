import React, { Suspense, useState, useEffect } from 'react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import './i18n'
import './yup'
import './App.css'
import { DialogProvider } from './hooks/useDialog'
import { AlertProvider } from './hooks/useAlert'
import { ThemeProvider } from '@material-ui/core'
import { theme } from './theme'
import useSession, { SessionContext, LoginState, Address } from './hooks/useSession'
import User from './types/user'
import { IRealm } from "hooks/useSession"
import { Route, Switch, Redirect } from 'react-router-dom'
import AppRouter from './routes/app/App.router'
import AuthRouter from './routes/auth/Auth.router'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/de'
import moment from 'moment'
import { SidebarProvider } from "./hooks/useSidebar"
import SetupRouter from "./routes/setup/Setup.router"
import useAxiosInterceptor from 'hooks/useAxiosInterceptor'
import {TariffProvider} from "hooks/useTariff"
import {ChatProvider} from "routes/app/chats/chatbuilder/ChatContext"
import ChatRouter from "routes/chat/ChatRouter"
import { SnackbarProvider } from 'notistack';
import { useSnackbarStyles } from 'styled/snackbarStyles'
import POSRouter from "../src/routes/app/pos/POS.router"
import Scratch from 'routes/scratch/Scratch'

moment.locale("de")
const locale = "de"

const Root = () => {

    const classes = useSnackbarStyles()
    const [user, setUser] = useState<User | null>(null)
    const [loginState, setLoginState] = useState<LoginState>(LoginState.Unknown)
    const [realm, setRealm] = useState<IRealm | null>(null)
    const [isPOS, setIsPOS] = useState<boolean>(false)
    const [imprint, setImprint] = useState<Address | null>(null)
    const [logo, setLogo] = useState<string | null>(null)
    return (
        
        <SnackbarProvider maxSnack={4}  classes={{variantInfo: classes.info, variantError:classes.error, variantSuccess: classes.success, variantWarning: classes.warning}} anchorOrigin={{vertical: "top", horizontal:"center"}}>
            <Suspense fallback={null}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
                            <SessionContext.Provider value={{ user, setUser, loginState, setLoginState, realm, setRealm, isPOS, setIsPOS, imprint, setImprint, logo, setLogo }}>
                                <TariffProvider>
                                    <SidebarProvider >
                                        <DialogProvider >
                                            <AlertProvider >
                                                <ChatProvider>
                                                    <Routes />
                                                </ChatProvider>
                                            </AlertProvider>
                                        </DialogProvider>
                                    </SidebarProvider>
                                </TariffProvider>
                            </SessionContext.Provider>
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </BrowserRouter>
            </Suspense>
        </SnackbarProvider>
    )
}

const Routes = () => {
    const { restore, loginState, realm, isPOS, imprint } = useSession()
    const location = useLocation()

    useAxiosInterceptor()

    useEffect(() => {
        restore()
        // eslint-disable-next-line
    }, [])

    // if(window.location.host === "chat.localhost:3000") {
    //     return <Redirect to="/chat"/>
    // }

    switch(loginState) {
        case LoginState.Unknown:
            return null
        case LoginState.LoggedIn:
            if(location.pathname.startsWith("/auth")) {
                return <Redirect to="/app" />
            }

        if(location.pathname.startsWith("/app") && (realm?.status === "DEMO" || !imprint)  )  {
                return <Redirect to="/setup" />
            }

        if(isPOS && location.pathname.startsWith("/app")){
            return <Redirect to ="/pos" />
        }
            break
        case LoginState.LoggedOut:
            // if(location.pathname.startsWith("/scratch")){
            //     return <></>
            // }

            if(location.pathname.startsWith("/chat/:hash")){
                return <Redirect to="/chat/:hash"/>
            }

            if (location.pathname.startsWith("/app")) {
                return <Redirect to="/auth/login" />
            }

            if(location.pathname.startsWith("/pos")) {
                return <Redirect to="/auth/login/pos"/>
            }
            break
    }

    return(
        <Switch>
            <Route path="/scratch" component={Scratch}></Route>
            <Route path="/chat" component={ChatRouter} />
            <Route path="/auth" component={AuthRouter} />
            <Route path="/app" component={AppRouter} />
            <Route path="/setup" component={SetupRouter} />
            <Route path="/pos" component={POSRouter} />
            <Route>
                {loginState === LoginState.LoggedIn && isPOS ? <Redirect to="/pos"/> : null}
                {loginState === LoginState.LoggedIn && (realm?.status === "DEMO" || !imprint) ? <Redirect to="/setup" /> : null}
                {loginState === LoginState.LoggedIn && <Redirect to="/app" />}
                {loginState === LoginState.LoggedOut && <Redirect to="/auth/login" />}
            </Route>
        </Switch>
    )
}

export default Root