import React, { useState } from 'react'
import { Dialog, Grid } from '@material-ui/core'
import { DialogProps } from '../hooks/useDialog'
import DialogTitle from './DialogTitle'
import DialogContent from './DialogContent'
import DialogActions from './DialogActions'
import { Editor } from '@tinymce/tinymce-react';
import { useTranslation } from 'react-i18next'
import Button from 'components/Button/Button';
import { Fa } from '../styled/muiComponents'

type Props = {
    initialValue: string
} & DialogProps

const Tinymce: React.FC<Props> = ({ open, onClose, onError, initialValue }) => {
    const [ value, setValue ] = useState(initialValue)
    const { t } = useTranslation()

    const handleEditorChange = ( content: string ) => {
        setValue(content)
    }

    const handleClose = () => {
        if (initialValue !== value) {
            console.warn("TODO unsaved changes warning?")
        }
        onClose(initialValue)
    }

    const handleSubmit = () => {
        onClose(value)
    }

    return (
        <Dialog open={open} maxWidth="md" fullWidth={true}>
            <DialogTitle onClose={handleClose}><Fa icon={["fal", "pen-to-square"]} mr={1.75} />{t("Textbearbeitung")}</DialogTitle>
            <DialogContent>
                <Editor
                    apiKey="kuv2m1nga57gam7wv0rziyvuv4gcnpsdgn9ugdyl7bx5qt2y"
                    initialValue={initialValue}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright | removeformat | help'
                    }}
                    onEditorChange={handleEditorChange}
                />
            </DialogContent>
            <DialogActions>
                <Grid container justify="space-between">
                    <Button onClick={handleClose} variant="contained" color="error"><Fa icon={["fas", "times"]} mr={1}  size={"lg"} /> {t("Abbrechen")}</Button>
                    <Button color="success" variant="contained" onClick={handleSubmit}><Fa icon={["fas", "check"]} mr={1} /> {t("Speichern")}</Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default Tinymce