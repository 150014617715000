import React, {useState} from 'react'
import * as yup from 'yup'

import { Link, makeStyles } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '../../components/Button/Button'
import TextField from '../../components/formik/Text'

import { Link as RouterLink } from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import useSession from '../../hooks/useSession'
import { Grid, Typography, Fa } from '../../styled/muiComponents'
import { Form, Field } from "react-final-form"
import FormikInput from 'components/formik/FormikInput';
import { useLocation } from 'react-router-dom';
// import { FORM_ERROR } from 'final-form'


const useTextStyles = makeStyles({
  root: {
    marginTop: "0"
  },
  error:{
    color:"#E83338"
  }
})

const schema = yup.object().shape({
  email: yup.string()
    .email()
    .required(),
  password: yup.string()
    .required()
})

const Login = ({ classes }) => {

  const { t } = useTranslation()
  const textfieldclasses = useTextStyles()
  const { login } = useSession()
  const [error, setError] = useState("")
  const location = useLocation()
  const initialValues = {
    email: "",
    password: ""
  }

  //TODO figure out how the fuck errors are handled in final form because FORM_ERROR aint it
  const handleSubmit = async ({ email, password }, { setErrors }) => {
  
    try {
      const POS = location.pathname.match(/[a-zA-Z]+(?!.*[a-zA-Z]+)/gi)[0] === "pos"
      await login(email, password, POS)
    } catch (e) {
      // setErrors({
      //   email: t("/errors:E-Mail oder Passwort falsch"),
      //   password: t("/errors:E-Mail oder Passwort falsch")
      // })
      // return { [FORM_ERROR]: "TEST123"}
      setError(t("/errors:E-Mail oder Passwort falsch"))
    } 
      
    }
    

  return (
    <Grid container direction="column" justify="center" alignItems="stretch" className={classes.root}>
      <Grid item>
        <img alt="logo" className={classes.logo} src="/assets/images/logo.svg" />
      </Grid>
      <Grid item>
        <Form validationSchema={schema} initialValues={initialValues} onSubmit={handleSubmit}
          subscription={{ submitting: true, pristine: true }}
          render={({ handleSubmit, submitting,
            //  submitError, form, FORM_ERROR
             }) => (
            <form onSubmit={handleSubmit}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormikInput
                    classes={textfieldclasses}
                    type="text"
                    variant="filled"
                    InputProps={
                      {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Fa icon={["fal", "envelope"]} />
                          </InputAdornment>
                        ),
                      }
                    }
                    name="email"
                    label="E-Mail"
                  />
                </Grid>
                <Grid item>
                  <Field name="password" component={TextField} type="password" label="Passwort" variant="filled"
                    InputProps={
                      {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Fa icon={["fal", "lock-alt"]} />
                          </InputAdornment>
                        ),
                      }
                    }
                  />
                </Grid>
                <Grid item>
                  {error !== "" ? 
                  <div style={{color:"#E83338"}}>{error}</div> 
                  : null}
                </Grid>
                
                {/* {submitError && <div>{submitError}</div>}
                <div>{JSON.stringify(form,0,2)}</div>
                <div>{FORM_ERROR}</div>
                <p onClick={() => console.log(submitError, JSON.stringify(form,0,2), FORM_ERROR)}>test</p> */}
                <Grid item>
                  <Button isLoading={submitting} startIcon={<Fa icon={["fas", "sign-in"]} size="xs" />} variant="contained" disableElevation color="success" size="large" type="submit">
                    {t("Anmelden")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </Grid>
      <Grid item className={classes.regpw}>
        <Typography mb={1}>
          <Fa icon={["fal", "edit"]} color="secondary.main" fixedWidth mr={1} />
          <Link component={RouterLink} to="/auth/register">{t("Registrieren")}</Link>
        </Typography>
        <Typography mb={0}>
          <Fa icon={["fal", "user-unlock"]} color="secondary.main" fixedWidth mr={1} />
          <Link component={RouterLink} to="/auth/reset_password">{t("Passwort vergessen")}</Link>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Login