import React from 'react'

import { withStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';

const styles = theme => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  }
});


const DialogActions = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogActions disableTypography className={classes.root} {...other}>
      {children}
    </MuiDialogActions>
  );
});

export default DialogActions