import { makeStyles } from "@material-ui/core"
import Button from "components/Button/Button"
import LoadingAnimation from "components/LoadingAnimation"
import { useAlert } from "hooks/useAlert"
import ratings, { TemplateKey } from "models/ratings"
import template from "models/template"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { Fa } from "styled/muiComponents"
import { Device } from "types/common"
import { insertPlaceholders, insertPortalButtons } from "util/placeholders"
import { changeValueInBucket } from "../HtmlReducer"
import { CancelButton, steps } from "../HtmlWizard"
import Editor from "./Editor"

type IRouteParams = {
    id: string
    templateKey: string
}

const step = (key: string) => {
    switch (key) {
        case "qr": return 1
        case "query": return 2
        case "portals": return 3
        case "feedback": return 4
        case "feedbackLP": return 5
        default: return 1
    }
}


const EditHtmlTemplate = () => {
    const params = useParams<IRouteParams>()
    const { t } = useTranslation()
    const history = useHistory()
    const { alert } = useAlert()
    const id = parseInt(params.id)
    const templateKey = params.templateKey as TemplateKey
    const [state, setState] = useState<any | null>(null)

    useEffect(() => {
        (async () => {
            const templates = { qr: {}, query: {}, portals: {}, feedback: {}, feedbackLP: {} }
            const rating = await ratings.get(id)
            const template = (await ratings.getTemplates(rating, [templateKey])).filter(a => a !== null)[0]
            const templatePrebuilds = await ratings.getPrebuild(rating.config.key)
            templates[templateKey] = templatePrebuilds.templates[templateKey as TemplateKey]
            setState({
                rating,
                template,
                device: Device.Smartphone,
                step: step(templateKey),
                prebuild: {
                    config: {
                        ...templatePrebuilds.config,
                        ...rating.config
                    },
                    templates: {
                        qr: { template: templatePrebuilds.templates["qr"].template, placeholders: rating.config["qr"] },
                        query: { template: templatePrebuilds.templates["query"].template, placeholders: rating.config["query"] },
                        portals: { template: insertPortalButtons(templatePrebuilds.templates["portals"].template, rating.rating_portals.length), placeholders: rating.config["portals"] },
                        feedback: { template: templatePrebuilds.templates["feedback"].template, placeholders: rating.config["feedback"] },
                        feedbackLP: { template: templatePrebuilds.templates["feedbackLP"].template, placeholders: rating.config["feedbackLP"] }

                    }
                }

            })
        })()

    }, [id, templateKey])


    const dispatch = ({ type, payload }: { type: string, payload: any }) => {
        switch (type) {
            case "UPDATE_PLACEHOLDER_VALUE": {
                const currentPlaceholders = { ...state.prebuild.config.placeholders }
                const values = payload
                const templateKey = steps[state.step].key
                const placeholderKey = Object.keys(values)[0]

                const newPlaceholders = {
                    ...currentPlaceholders,
                    [placeholderKey]: {
                        ...currentPlaceholders[placeholderKey],
                        value: values[placeholderKey]
                    }
                }

                const newState = {
                    ...state,
                    prebuild: {
                        ...state.prebuild,
                        config: {
                            ...state.prebuild.config, placeholders: {
                                ...newPlaceholders
                            }
                        },
                        templates: {
                            ...state.prebuild.templates,
                            [templateKey as TemplateKey]: {
                                ...state.prebuild.templates[templateKey as TemplateKey],
                                placeholders: {
                                    ...state.prebuild.templates[templateKey as TemplateKey].placeholders,
                                    [placeholderKey]: {
                                        ...state.prebuild.config.placeholders[placeholderKey],
                                        value: values[placeholderKey]
                                    }
                                }
                            }
                        }
                    }
                }
                setState(newState)
                return
            }
        }
    }

    const handleSave = () => {

        const saveStateRating = {
            ...state.rating,
            config: {
                ...state.rating.config,
                [templateKey]: state.prebuild.templates[templateKey].placeholders
            }

        }

        const saveStateTemplate = {
            ...state.template,
            body_html: insertPlaceholders(insertPortalButtons(state.prebuild.templates[templateKey].template, state.rating.rating_portals.length), { ...state.prebuild.config.placeholders, ...state.prebuild.templates[templateKey].placeholders })
        }
        Promise.all([
            template.update(state.template.id, saveStateTemplate),
            ratings.update(id, saveStateRating)
        ]).then(() => {
            alert.success(t("Speichern erfolgreich"))
            history.push("/app/stock/ratings")
        })
    }

    if (!state) {
        return <LoadingAnimation />
    }

    return (
        <Editor
            state={state}
            dispatch={dispatch}
            preConfigComponent={<Header onSave={handleSave} />}
        />
    )
}

const useStyles = makeStyles(theme => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        padding: theme.spacing(2)
    },
    headerText: {
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}))

type HeaderProps = {
    onSave: () => void
}

const Header = ({ onSave }: HeaderProps) => {
    const { t } = useTranslation()
    const classes = useStyles()
    return (
        <div className={classes.header}>
            <CancelButton destination="ratings" />
            <Button variant="contained" color="success" onClick={onSave} size="small">
                <Fa icon={["fas", "check"]} mr={1} />
                {t("Speichern und schließen")}
            </Button>
        </div>
    )
}

export default EditHtmlTemplate