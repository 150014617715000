import React, { useState, useEffect } from 'react'
import { IconButton, makeStyles, Grid } from '@material-ui/core'
import { IPlaceholder, TemplateKey as WebformTemplateKey } from 'models/webform'
import PreviewFrame from 'components/PreviewFrame'
import { FaButton, Fa } from 'styled/muiComponents'
import { DialogProps } from 'hooks/useDialog'
import Button from 'components/Button/Button'
import SlideGalerie from 'components/SlideGalerie'
import { Device } from 'types/common'
import { insertDynamicImprint, insertImprint, insertPlaceholders, insertQR } from 'util/placeholders'
import FormikInput from 'components/formik/FormikInput'
import { Form } from "react-final-form"
import { theme } from 'theme'
import { IState } from '../HtmlWizard'
import ratings, { IRating, TemplateKey as RatingTemplateKey } from 'models/ratings'
import LoadingAnimation from 'components/LoadingAnimation'
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles({
    portal: {
        backdropFilter: "blur(3px)",
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1300,
        backgroundColor: "#e2e2e2",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    },
    actionsWrapper: {
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 1400,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
        padding: "16px"

    },
    previewWrapper: {
        maxHeight: "600px"
    },
    galerieEntry: {
        textAlign: "center"
    },
    marginFix: {
        marginTop: "-30px"
    },
    deviceActive: {
        color: "red !important"
    },
    navButton: {

    },
    elementPedding: {
        padding: theme.spacing(2)
    },
    scaleDesktop: {
        transform: "scale(0.8)"
    }
})

type Props = {
    state: IState & IRating
    editorView: boolean
    idx: number
}

const templateTypes: (type?: string) => { key: WebformTemplateKey | RatingTemplateKey, label: string }[] = (type: string = "rating") => {
    switch (type) {
        case "rating": return [
            { key: RatingTemplateKey.qr, label: "QR" },
            { key: RatingTemplateKey.query, label: "QUERY" },
            { key: RatingTemplateKey.portals, label: "PORTALS" },
            { key: RatingTemplateKey.feedback, label: "FEEDBACK" },
            { key: RatingTemplateKey.feedbackLP, label: "FEEDBACKLP" }
        ]

        case "webform": return [
            { key: WebformTemplateKey.formLP, label: "Anmeldeseite" },
            { key: WebformTemplateKey.soiLP, label: "Formular Landingpage" },
            { key: WebformTemplateKey.doiMail, label: "Dopt-In Mail" },
            { key: WebformTemplateKey.doiLP, label: "Dopt-In Landingpage" },
            { key: WebformTemplateKey.doneMail, label: "Willkommens-Mail" }
        ]
        //TODO 
        default: return [
            { key: RatingTemplateKey.qr, label: "Anmeldeseite" },
            { key: RatingTemplateKey.query, label: "Formular Landingpage" },
            { key: RatingTemplateKey.portals, label: "Dopt-In Mail" },
            { key: RatingTemplateKey.feedback, label: "Dopt-In Landingpage" },
            { key: RatingTemplateKey.feedbackLP, label: "Willkommens-Mail" }
        ]
    }


}

const carousselWidth: Record<Device, number> = {
    [Device.Smartphone]: 480,
    [Device.Tablet]: 650,
    [Device.Desktop]: 1200,
    [Device.Fullscreen]: 1200
}

const HtmlTemplateView = ({ onClose, open, state, editorView = false, idx }: Props & DialogProps) => {
    const classes = useStyles()
    const [templateIndex, setTemplateIndex] = useState(idx)
    const [device, setDevice] = useState<Device>(Device.Smartphone)
    const [templates, setTemplates] = useState<any>(null)
    const [url, setURL] = useState<string | null>(null)
    const history = useHistory()

    useEffect(() => {
        const fetchTemplates = async () => {

            const [qr, query, portals, feedback, feedbackLP] = await ratings.getTemplates(state as IRating)
            setTemplates({
                [RatingTemplateKey.qr]: qr,
                [RatingTemplateKey.query]: query,
                [RatingTemplateKey.portals]: portals,
                [RatingTemplateKey.feedback]: feedback,
                [RatingTemplateKey.feedbackLP]: feedbackLP,
            })


        }
        const fetchURL = async () => {
            const response = await ratings.getRatingUrl(state.hid)
            console.log(response)
            setURL(response.query)
        }
        if (!editorView) { fetchTemplates(); fetchURL() }
    }, [])

    useEffect(() => {
        setTemplateIndex(idx)
    }, [idx])

    const handleDeviceChange = (device: Device) => {
        setDevice(device)
    }

    const handleSubmit = () => {

    }

    const handleChangeTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value)
    }

    const handlePrevious = () => {
        if (templateIndex === 0) {
            return
        }
        setTemplateIndex(templateIndex - 1)
    }

    const handleEdit = () => {
        history.push(`/app/stock/ratings/edit/${state.id}/`)
    }

    const handleNext = () => {
        if (templateIndex === templateTypes().length - 1) {
            return
        }
        setTemplateIndex(templateIndex + 1)
    }

    const customReplace = (template: string, placeholders: Record<string, Pick<IPlaceholder, "key" | "value" | "default"> | IPlaceholder>, imprint: any | null): string => {
        return insertImprint(insertDynamicImprint(insertPlaceholders(template, placeholders), imprint), imprint)
    }

    const initialValues = { template: templateTypes()[templateIndex ?? 0].key }

    if (!open) return null

    if (!editorView && (!templates || !url)) {
        return <LoadingAnimation />
    }

    return (
        <>
            <div className={classes.portal}>
                <Grid container direction="row" justify="space-between">
                    <Grid item style={{ width: "150px" }}>
                        {/* spacer */}
                    </Grid>
                    <Form initialValues={initialValues} onSubmit={handleSubmit}
                        render={({ handleSubmit, form }) => (
                            <Grid item className={classes.elementPedding}>
                                <Grid container direction="row" alignItems='center'>
                                    <Grid item>
                                        {
                                            templateIndex === 0 ?
                                                <Button variant="text" className={classes.navButton} disabled style={{ height: "56px" }}> <Fa icon={["fas", "chevron-left"]}></Fa> </Button>
                                                :
                                                <Button variant="text" className={classes.navButton} onClick={() => { handlePrevious() }} style={{ height: "56px" }}> <Fa icon={["fas", "chevron-left"]} ></Fa> </Button>
                                        }
                                    </Grid>
                                    <Grid item style={{ width: "250px" }}>
                                        <form>
                                            <FormikInput
                                                type="improvedEnum"
                                                name="template"
                                                id="template"
                                                typeParams={templateTypes()}
                                                onChange={handleChangeTemplate}
                                                variant="outlined"
                                                style={{ margin: "-16px 0 -8px" }}
                                            />
                                        </form>
                                    </Grid>
                                    <Grid item>
                                        {templateIndex === Object.keys(templateTypes()).length - 1 ?
                                            <Button variant="text" className={classes.navButton} disabled style={{ height: "56px" }}><Fa icon={["fas", "chevron-right"]}></Fa></Button>
                                            :
                                            <Button variant="text" className={classes.navButton} onClick={() => { handleNext() }} style={{ height: "56px" }}><Fa icon={["fas", "chevron-right"]}></Fa> </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    />
                    <Grid item container direction='row' justify='flex-end' className={classes.elementPedding} style={{ width: "150px" }}>
                        {!editorView ? <div> <IconButton onClick={handleEdit} style={{ fontSize: "1.25em", width: "50px", height: "50px" }}>
                            <Fa icon={["fas", "wrench"]} fixedWidth></Fa>
                        </IconButton></div>
                            : null}

                        <IconButton aria-label="close" onClick={onClose} style={{ width: "50px", height: "50px" }}>
                            <Fa icon={["fas", "times"]} ></Fa>
                        </IconButton>
                    </Grid>
                </Grid>

                <SlideGalerie<{ key: RatingTemplateKey | WebformTemplateKey, label: string }> options={templateTypes()} maxWidth={carousselWidth[device]} index={templateIndex}>
                    {({ option }) => (
                        <div key={option.key} className={classes.galerieEntry}>
                            <div className={`${classes.previewWrapper} ${device === Device.Tablet ? classes.marginFix : ""} ${device === Device.Desktop ? classes.scaleDesktop : ""}`}>
                                {/* {!editorView && <PreviewFrame variant={device} html={templates.[option.key]?.body_html || ""} basicInfo={null} previewIdentifier={option.key} />}  */}
                                {editorView && <PreviewFrame variant={device} previewIdentifier={option.key}
                                    //@ts-ignore //TODO  apply placeholder buckts
                                    html={customReplace(state.prebuild.templates[option.key].template, { ...state.prebuild.config.placeholders, ...state.prebuild.templates[option.key].placeholders }, state.imprint) || ""} basicInfo={null} />
                                }
                                {!editorView && <PreviewFrame variant={device} previewIdentifier={option.key}
                                    // html={customReplace(templates[option.key].body_html, { ...state.config.defaultConfig.placeholders, ...state.config[option.key]}, state.imprint)} 
                                    basicInfo={null}
                                    html={insertQR(templates[option.key].body_html, url!)}
                                />
                                }
                            </div>
                        </div>
                    )}
                </SlideGalerie>
                <DeviceSelect device={device} onDeviceChange={handleDeviceChange} />
            </div>
        </>
    )
}


const DeviceSelect = ({ device, onDeviceChange }: { device: Device, onDeviceChange: (device: Device) => void }) => {
    const classes = useStyles()
    return (
        <Grid container justify="center">
            <Grid item onClick={() => onDeviceChange(Device.Smartphone)}>
                <FaButton className={device === Device.Smartphone ? classes.deviceActive : ""} style={{ color: "inherit" }}>
                    <Fa icon={["fal", "mobile"]} />
                </FaButton>
            </Grid>
            <Grid item onClick={() => onDeviceChange(Device.Tablet)}>
                <FaButton className={device === Device.Tablet ? classes.deviceActive : ""} style={{ color: "inherit" }}>
                    <Fa icon={["fal", "tablet"]} />
                </FaButton>
            </Grid>
            <Grid item onClick={() => onDeviceChange(Device.Desktop)}>
                <FaButton className={device === Device.Desktop ? classes.deviceActive : ""} style={{ color: "inherit" }}>
                    <Fa icon={["fal", "laptop"]} />
                </FaButton>
            </Grid>
            {/*<Grid item onClick={() => onDeviceChange(Device.Fullscreen)}>
                        <FaButton className={device === Device.Fullscreen ? classes.deviceActive : ""} style={{ color: "inherit" }} >
                            <Fa icon={["fal", "window"]} />
                        </FaButton>
                    </Grid>*/}
        </Grid>
    )
}

export default HtmlTemplateView