import { Page, Text, View, StyleSheet, Font, Image, Document, pdf } from '@react-pdf/renderer';
import React from "react"
import { Fade } from "@material-ui/core"

export const key= "002"
export const type = "DISPLAY"

Font.register({
    family: 'Castoro',
    src: '/assets/images/Castoro-Regular.ttf'
  });

export const FixedStyles = (calc: number) => StyleSheet.create({
    page: {
        padding: 70
    },
    headerView: {
        top: 150,
        width: "100vw",
        position: "absolute"
    },
    header: {
        //fontSize: 34, 
        fontFamily: 'Castoro',
        fontWeight: 100,
        lineHeight: 1.3,
        textAlign: "center",
        marginHorizontal: 70,
    },
    qrcodeView: {
        position: "absolute",
        width: "100vw",
        top: 350,
    },
    qrcode: {
        marginHorizontal: 200,
    },
    bgimg: {
        position: "absolute",
        width: "100vw",
        top: 170
    },
    footerView:{
        position: "absolute",
        width: "100vw",
        top: 620,
    },
    footer: {
        lineHeight: 1.3,
        textAlign: "center",
        marginHorizontal: 70
    },
    logo: {
        width: 150,
        position: "absolute",
        right: 60,
        top: 40
    }
})

export const initialUserStyles = (calc: number) => {
    const styles = {

        Hintergrund: {
            groupName: "Hintergrund",
            faIcon: "fill-drip",
            iconType: "fal",
            placeholders: {
                PageBackgroundColor: {
                    name: "PageBackgroundColor",
                    value: "#ffffff",
                    type: "color",
                    label: "Seitenhintergrund",
                },
                HeaderColor: {
                    name: "HeaderColor",
                    value: "#000000",
                    type: "color",
                    label: "Farbe der Überschrift",
                },
                FooterColor: {
                    name: "FooterColor",
                    value: "grey",
                    type: "color",
                    label: "Farbe der Fußnote",
                },
            }
        },
        Schriftgröße: {
            groupName: "Schriftgröße",
            faIcon: "text-size",
            iconType: "fal",
            placeholders: {
                HeaderFontSize: {
                    name: "HeaderFontSize",
                    value: 20,
                    type: "string",
                    label: "Größe der Überschrift"
                },
            }
        },
    }
    return styles
}

export const initialUserText = {
    Inhalt: {
        groupName: "Inhalt",
        faIcon: "text",
        iconType: "fal",
        placeholders: {
            header: {
                name: "header",
                label: "Überschrift",
                value: "Jetzt anmelden und keine Neuigkeiten und Angebote mehr verpassen.",
                type: "tinymce",
            },
            footer: {
                name: "footer",
                label: "Footer",
                value: "Nach Ihrer Anmeldung erhalten Sie zwei mal pro Monat exklusive Vorteile und Angebote. Eine Abmeldung ist jederzeit möglich.",
                type: "tinymce",
            }
        }
    }
}

export const preview =(
    <img src={require("./thubnail.png")} alt="preview"/>
)

export const mockup = (
    <Fade timeout={500} in={true}>
        <img src={require("./preview.jpg")} alt="preview"/>
    </Fade >
)

export const Template = async ({ imgURL, userText, userStyles, handleDocument, settings }: any) => {

    if (userText && userStyles && imgURL && settings) {
        const fixedStyles = FixedStyles(settings.calc)
        const myPdf = (
            <Document>
                <Page size={settings.size} style={[fixedStyles.page, { backgroundColor: userStyles.Hintergrund.placeholders.PageBackgroundColor.value }]}>
                    <Image src="/assets/images/cp-logo.png" style={fixedStyles.logo} />
                    <View style={fixedStyles.headerView}>
                        <Text style={[fixedStyles.header, { color: userStyles.Hintergrund.placeholders.HeaderColor.value, fontSize: userStyles.Schriftgröße.placeholders.HeaderFontSize.value }]}>
                            {userText.Inhalt.placeholders.header.value}
                        </Text>
                    </View>
                    <View style={fixedStyles.qrcodeView}>
                        <Image source={imgURL} style={fixedStyles.qrcode} />
                    </View>
                    <View style={fixedStyles.footerView}>
                        <Text style={[fixedStyles.footer, { color: userStyles.Hintergrund.placeholders.FooterColor.value }]}>
                            {userText.Inhalt.placeholders.footer.value}
                        </Text>
                    </View>
                </Page>
            </Document>
        )
        let url
        await pdf(myPdf)
            .toBlob()
            .then(blob => {
                url = URL.createObjectURL(blob)
            })
        handleDocument(url)
        return null
    } else {
        return null
    }

}