import React from "react"
import countryCodes from "constants/countryCodes"
import Select from "./Select"
import { useTranslation } from "react-i18next"
import {MenuItem} from "@material-ui/core"


const Country = ({...props}) => {
    const { t } = useTranslation()

    return (
        <Select {...props} >
            {countryCodes.map(code => <MenuItem key={code} value={code}>{t(`/countries:${code}`)}</MenuItem>)}
        </Select>
    )
}

export default Country