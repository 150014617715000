import React, { useState } from "react"
import { TextField } from "@material-ui/core"
import { useTranslation } from "react-i18next";

var CODE_LENGTHS = {
    AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
    CH: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
    FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
    HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
    LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
    MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
    RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
    AL: 28, BY: 28, CR: 22, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
    SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
};

const IbanInput = ({ autosize, ...props }) => {

    const { t } = useTranslation()
    const [iban, setIban] = useState("")
    const [valid, setValid] = useState(false)
    // const {input:{name, value, ...field}, meta} = props
    const {input:{name, value, ...field}} = props


    const moduloIban = (numbers) => {
        let rest = 0
        for (let i = 0; i < numbers.length;) {
            let part = numbers.slice(i, i + 9 - (rest === 0 ? 0 : rest.toString().length))
            i += 9 - (rest === 0 ? 0 : rest.toString().length)
            if (rest !== 0) {
                part = rest.toString() + part
            }
            rest = parseInt(part) % 97
        }
        return rest
    }

    const IbanValid = (iban) => {
        const country = iban.slice(0, 2)
        //@ts-ignore
        const length = CODE_LENGTHS[country]

        if (length === iban.length) {
            const firstFour = iban.slice(0, 4)
            const validationString= iban.slice(4).concat(firstFour)
            const lettersChanged = [...validationString].map((c) => {
                if (/[a-zA-Z]/.test(c)) {
                    return (c.toLowerCase().charCodeAt(0) - 87).toString()
                } else {
                    return c
                }
            })
            const numbers = lettersChanged.join('')
            if (moduloIban(numbers) === 1) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const handleBlur = (e) => {
        if (props.onBlur) {
            props.onBlur(e)
        }
        field.onBlur(e)
    }

    const handleChange = (e) => {
        setValid(IbanValid(e.target.value))

        if (props.onChange) {
            props.onChange(e)
        }
        field.onChange(e)
        setIban(e.target.value)
    }

    return (
        <TextField
            name={name}
            onChange={handleChange}
            helperText={valid ? "" : t("Keine Valide IBAN")}
            error={!valid}
            value={iban}
            onBlur={handleBlur}
        />
    )
}

export default IbanInput