import { withCrud, CrudModel, BaseDualModel } from "./builder";
import {post, get} from "api/crud"

type Domain = {
    id: number
    realm_id: number
    domain:string
}

export type DomainApi = CrudModel<any> & BaseDualModel // TODO typing

export default {
    name: "cornerstone_domains",
    ...withCrud<any>("cornerstone_domains"),

    available: (domain:string) => post('cornerstone_domains/available',{domain}),

    get_first: () => get<Domain>("cornerstone_domains/get_first")
}