import React from "react"
import { Box, makeStyles } from "@material-ui/core"
import { Grid } from 'styled/muiComponents'

const useStyles = makeStyles ((theme) => ({
    main: {
        padding: theme.spacing(4),
        width: "100%",
        height: "auto%",
        boxSizing: "border-box",
        position: "relative"
    }
}))

const PagePadding: React.FC<{}> = ({children} ) => {
    const classes = useStyles()

    return(
        <Box className={classes.main}>
            {children}
        </Box>
    )
}

export const WithPadding = (Component: React.FC , spacing: number) => {
    return () => (
        <Grid p={spacing} style={{height: "100%"}}>
            <Component/>
        </Grid>
    )
}

export default PagePadding