
//webforms
export function downloadPrebuildHtml(key, filename) {
    return fetch(`${process.env.REACT_APP_TEMPLATE_SERVER}/webform/${key}/${filename}.html`)
    .then(response => response.text())
    .then(response => response.replace(/↵/g, "\n"))
}

export function downloadPrebuildConfig(key) {
    return fetch(`${process.env.REACT_APP_TEMPLATE_SERVER}/webform/${key}/config.json`, { headers: {"Content-Type": "application/json"}})
    .then(response => response.json())
}


//ratings
export function downloadPrebuildHtmlRating(key, filename) {
    return fetch(`${process.env.REACT_APP_TEMPLATE_SERVER}/rating/${key}/${filename}.html`)
    .then(response => response.text())
    .then(response => response.replace(/↵/g, "\n"))
}

export function downloadPrebuildConfigRating(key) {
    return fetch(`${process.env.REACT_APP_TEMPLATE_SERVER}/rating/${key}/config.json`, { headers: {"Content-Type": "application/json"}})
    .then(response => response.json())
}

//${process.env.REACT_APP_TEMPLATE_SERVER}