import { IPlaceholder } from 'models/webform'


export const insertImprint = (template: string, imprint: any | null) => {
    const imprintPlaceholders = {
        "company_name": {
            "key": "company_name",
            // "value": realm?.address.company_name,
            "value": imprint?.company_name,
            "default": "Unternehmensname"
        },
        "street_name": {
            "key": "street_name",
            "value": imprint?.street_name,
            "default": "Milchstraße"
        },
        "street_number": {
            "key": "street_number",
            "value": imprint?.street_number,
            "default": "42"
        },
        "postal_code": {
            "key": "postal_code",
            "value": imprint?.postal_code,
            "default": "12345"
        },
        "city": {
            "key": "city",
            "value": imprint?.city,
            "default": "Ort"
        },
        "phone_number": {
            "key": "phone_number",
            "value": imprint?.phone_number,
            "default": "0123 - 45 67 89 00"
        },
        "vat_identifier": {
            "key": "vat_identifier",
            "value": imprint?.vat_identifier,
            "default": "Steuernummer"
        },
        "ust_identifier": {
            "key": "ust_identifier",
            "value": imprint?.ust_identifier,
            "default": "ust"

        },
        "imprint_mail": {
            "key": "imprint_mail",
            "value": imprint?.imprint_mail,
            "default": "beispiel@beispiel.de"
        },
        "country": {
            "key": "county",
            "value": imprint?.country,
            "default": "DEU"
        },
        "company_board": {
            "key": "company_board",
            "value": imprint?.company_board,
            "default": "Geschäftsführung"
        },
        "supervisory_board": {
            "key": "supervisory_board",
            "value": imprint?.supervisory_board,
            "default": "Vertretene Geschäftsführung"
        },
        "company_register": {
            "key": "company_register",
            "value": imprint?.company_register,
            "default": "Handelsregister"
        },
        "registry_number": {
            "key": "registry_number",
            "value": imprint?.registery_number,
            "default": "Register Nummer"
        },
        "registry_court": {
            "key": "registry_court",
            "value": imprint?.registry_court,
            "default": "Gericht"
        },
        "responsible_chamber": {
            "key": "responsible_chamber",
            "value": imprint?.responsible_chamber,
            "default": "Zuständige Kammer"
        },
        "job_title_plus_country_awarded": {
            "key": "job_title_plus_country_awarded",
            "value": imprint?.job_title_plus_country_awarded,
            "default": "Job Titel"
        },
        "additional_information": {
            "key": "additional_information",
            "value": imprint?.additional_information,
            "default": "Extrainfos"
        },
    }

    let replaced = template;

    (Object.keys(imprintPlaceholders) as Array<keyof typeof imprintPlaceholders>).forEach(key => {
        const reg = new RegExp("\\${" + key + "}", "g")
        replaced = replaced.replace(reg, imprintPlaceholders[key].value || imprintPlaceholders[key].default)
    })
    return replaced;
}

export const insertPlaceholders = (template: string, placeholders: Record<string, Pick<IPlaceholder, "key" | "value" | "default"> | IPlaceholder>) => {
    let replaced = template;
    (Object.keys(placeholders) as Array<keyof typeof placeholders>).forEach(key => {
        const reg = new RegExp("\\${" + key + "}", "g")
        replaced = replaced.replace(reg, placeholders[key].value || placeholders[key].default)
    })
    return replaced;
}

export const insertDynamicImprint = (template: any, imprint: any) => {
    if (!template || !imprint) {
        return template
    }

    let replaced = template

    const head = () => {
        if (["GbR", "OHG", "KG", "GmbH", "GmbH & Co KG", "Andere", "Einzelunternehmen"].some(v => (imprint.legal_form).includes(v))) {
            return `
            <p>
            Geschäftsführung: \${company_board}<br>
            ${imprint.supervisory_board !== "" && imprint.supervisory_board ? `Vertretungsberechtigter: \${supervisory_board}` : null}
            </p>`
        } else if (["AG", "e. G."].some(v => (imprint.legal_form.includes(v)))) {
            return `
            <p>
            Vorstand: \${company_board}<br>
            ${imprint.supervisory_board !== "" && imprint.supervisory_board ? `Aufsichtsrat: \${supervisory_board}` : ``}
            </p>`
        } else {
            return ``
        }
    }

    const register = () => {
        if ((imprint.registry_number !== "" || imprint.company_register !== "") || (!imprint.registry_number || !imprint.company_register)) {
            return ``
        } else {
            return `
        <p>
        \${company_register}: \${registry_number}<br>
        ${imprint.registry_court ? `Registergericht: \${registry_court}<br>` : ``}
        ${imprint.responsible_chamber && imprint.job_title_plus_country_awarded ? `\${responsible_chamber}: \${job_title_plus_country_awarded}` : ``}
        </p>
        `
        }
    }

    const vat = () => {
        return `
            <p>
            ${imprint.ust_identifier !== "" || imprint.vat_identifier !== "" ? "Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br>" : ``}
            ${imprint.ust_identifier ? `\${ust_identifier}` : ``}
            ${imprint.vat_identifier ? `Steuernummer: \${vat_identifier}` : ``}
            </p>
            `
    }

    const imprintString =
        `<span style="white-space: normal">
        <p>
        \${company_name}<br>
        \${street_name} \${street_number}<br>
        \${postal_code} \${city}<br>
        \${country}
      </p>
      ${head()}
      ${register()}
      ${vat()}
      <p>
        E-Mail: \${imprint_mail}<br>
        Telefon: \${phone_number}
      </p>
      ${imprint.additional_information !== "" && !imprint.additional_information ? `<p>\${additional_information}</p>` : ``}
      </span>
      `
    const reg = new RegExp("\\${imprint}", "g")
    replaced = replaced.replace(reg, imprintString)

    return replaced
}

export const insertLogo = (template: any, url: string| null) => {
    // else cp logo
    let replaced = template
    const reg = new RegExp("\\${logoURL}", "g")
    replaced = replaced.replace(reg, url)
    return replaced
}

export const insertPrivacy = (template: string) => {
    let replaced = template
    const text = `
    Wir verwenden auf dieser Webseite Campaign.Plus, ein Dienst für unser E-Mail-Marketing. Dienstanbieter ist
              das deutsche Unternehmen Campaign.Plus GmbH,
              Schulgasse 5, 84359 Simbach am Inn, Deutschland. Mehr über die Daten, die durch die Verwendung von
              Campaign.plus verarbeitet werden,
              erfahren Sie in den Datenschutzinformationen der Campaign.Plus GmbH auf <a
                href="https://www.campaign.plus/datenschutzinformationen"
                target="_blank">https://www.campaign.plus/datenschutzinformationen/</a>.
    `
    const reg = new RegExp("\\${privacy}","g")
    replaced = replaced.replace(reg, text)
    return replaced
}

export const insertQR = (template:string, url: string="Die Bewertungsstrecke wurde noch nicht generiert") => {
    let replaced = template
    const reg = new RegExp("\\${queryURL}")
    replaced = replaced.replace(reg, url)
    return replaced
}

export const insertPortalButtons = (template: string, amount: number, option = null) => {
    let replaced = template
    const reg = new RegExp("\\${ratingPortals}", "g")
    replaced = replaced.replace(reg, normalPortalButtons(amount))
    return replaced
}

const normalPortalButtons = (amount: number) => {
    let buttons = ""
    for (let i = 0; i<amount; i++){
        const portalURL = `\${portalURL${i}}`
        const portalButtonText = `\${portalButtonText${i}}`
        const portalButtonSize = `\${portalButtonSize}`
        buttons += `
        <div class="rating-portal">
            <a class="btn btn-${portalButtonSize} mb-2 btn-primary portal-button-${i}" href="${portalURL}">
            ${portalButtonText} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M280 80C266.7 80 256 69.25 256 56C256 42.75 266.7 32 280 32H424C437.3 32 448 42.75 448 56V200C448 213.3 437.3 224 424 224C410.7 224 400 213.3 400 200V113.9L200.1 312.1C191.6 322.3 176.4 322.3 167 312.1C157.7 303.6 157.7 288.4 167 279L366.1 80H280zM0 120C0 89.07 25.07 64 56 64H168C181.3 64 192 74.75 192 88C192 101.3 181.3 112 168 112H56C51.58 112 48 115.6 48 120V424C48 428.4 51.58 432 56 432H360C364.4 432 368 428.4 368 424V312C368 298.7 378.7 288 392 288C405.3 288 416 298.7 416 312V424C416 454.9 390.9 480 360 480H56C25.07 480 0 454.9 0 424V120z"/></svg>
            </a>
        </div>
        `
    }
    return buttons
}

// normal button    
// <div class="rating-portal">
//     <a class="btn btn-lg mb-2 btn-primary" href="${portalURL}">
//     Jetzt bewerten <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M280 80C266.7 80 256 69.25 256 56C256 42.75 266.7 32 280 32H424C437.3 32 448 42.75 448 56V200C448 213.3 437.3 224 424 224C410.7 224 400 213.3 400 200V113.9L200.1 312.1C191.6 322.3 176.4 322.3 167 312.1C157.7 303.6 157.7 288.4 167 279L366.1 80H280zM0 120C0 89.07 25.07 64 56 64H168C181.3 64 192 74.75 192 88C192 101.3 181.3 112 168 112H56C51.58 112 48 115.6 48 120V424C48 428.4 51.58 432 56 432H360C364.4 432 368 428.4 368 424V312C368 298.7 378.7 288 392 288C405.3 288 416 298.7 416 312V424C416 454.9 390.9 480 360 480H56C25.07 480 0 454.9 0 424V120z"/></svg>
//     </a>
// </div>