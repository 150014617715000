import React, { useState } from "react"
import images from "models/images"
import { Grid, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import Button from "components/Button/Button"
import { useDialog } from "hooks/useDialog"
import ErrorDialog from "dialogs/ImageUploadErrors"
import { useTariff, IPermissionModules } from "hooks/useTariff"
import NoPermission from "components/NoPermission"
import { useAlert } from "hooks/useAlert"
import { Fa } from "styled/muiComponents"
import { FileUploader } from "react-drag-drop-files";
import { Typography } from 'styled/muiComponents'
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(2)
    },
    input: {
        display: 'none',
    },
    dropzone: {
        height: "100%",
        width: "100%",
        transition: "0.35s all ease",
        "&:hover": {
            opacity: 0.7
        }
    }
}))

const UploadImage = ({setUploadedImage, selectImage}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { dialog } = useDialog()
    const { permissions } = useTariff()
    const { alert } = useAlert()  
    
    const [image, setImage] = useState({filename: "empty"})

    const handleUploadFile = async () => {
        if (image === undefined) {
            return
        }
        if (!image) {
            return
        }
       if (image.size > 2097152) {
            dialog.show({
                component: ErrorDialog,
                props: {
                    errorType: "size",
                }
            })
        } else if (image.type.match(/(jpg|jpeg|gif|png)/) === null) {
            dialog.show({
                component: ErrorDialog,
                props: {
                    errorType: "format"
                }
            })
        } else {
            const data = new FormData()
            data.set("image", image)
            try {
                const uploadedImage = await images.create(data)
                document.getElementById("image").value = null
                setUploadedImage(image)
                alert.success(t("Das Bild wurde erfolgreich hochgeladen."))
                return uploadedImage
            } catch (error) {
                alert.error(t("Das Bild konnte nicht hochgeladen werden."))
                return "error"
            }
        }
    }

    const handleChangeFile = (e) => {
        setImage(e.nativeEvent.target.files[0])
    }

    const handleUploadAndSelect = async () => {
        const image = await handleUploadFile()
        if(image === "error") {
            return
        }
        selectImage({image:`/api/images/${image.id}/getImage`})
    }
    const fileTypes = ["JPG", "PNG", "GIF", "JPG"];
    const [file, setFile] = useState(null);
    const handleChange = (file) => {
      setFile(file);
    };

    return (
        <PerfectScrollbar>
        <Grid item container alignItems="stretch" alignContent="flex-start" direction="column" className={classes.container}>
            <Typography variant="h1" mb={2}>{t("Lade dein eigenes Bild hoch")}</Typography>
            <NoPermission module={IPermissionModules.image_volume} style={{width: "100%"}}/>
            <Grid item container direction="column" alignItems="center" alignContent="center">
                <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    maxSize="0.3"
                    classes="cp_drop_zone"
                    label="Upload or drop a file right here"
                    children={
                        <Grid container direction="column" justify="center" alignItems="center" className={classes.dropzone}>
                            <Fa icon={["fas", "cloud-arrow-up"]} size={"lg"} mb={3} style={{fontSize: "2em"}}/>
                            <Typography variant="h2" mb={1}>{t("Hier klicken oder Datei hierher ziehen.")}</Typography>
                            <p><em>{t("Erlaubte Formate: JPEG, JPG, PNG und GIF")}</em></p>
                        </Grid>
                    } />
                <form>
                    <input type="file" accept="image/*" name="image" id="image" onChange={handleChangeFile} className={classes.input}/>
                    <label htmlFor="image">
                        <Button variant="contained" color="success" component="span">
                            <Fa icon={["far", "arrow-up-from-line"]} mr={1}/>{t("Bild auswählen")}
                        </Button>
                    </label>
                </form>
                <Button onClick={handleUploadFile} disabled={!permissions.image_volume}>{t("Speichern")}</Button>
                <Button onClick={handleUploadAndSelect} disabled={!permissions.image_volume}>{t("Speichern und Auswählen")}</Button>
            </Grid>
        </Grid>
        </PerfectScrollbar>
    )
}

export default UploadImage