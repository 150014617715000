import { Grid, Paper, Tooltip } from "@material-ui/core"
import Button from "components/Button/Button"
import FormikInput from "components/formik/FormikInput"
import React from "react"
import { useTranslation } from "react-i18next"
import { useChatState } from "../../ChatContext"
import Grow from '@material-ui/core/Grow'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { FieldArray } from "react-final-form-arrays"
import { useForm } from "react-final-form"
import { Fa, FaButton } from "styled/muiComponents"

const SocialMediaNodeOptions = ({ node, classes }: any) => {
    const { t } = useTranslation()
    const { state } = useChatState()
    const mutators = useForm().mutators

    return (
        <PerfectScrollbar>
            <Grid container className={classes.settingsContainer} justify="center">
                <Grid item container direction="column" alignItems="center">
                    <div className={classes.seperator}></div>
                    <FormikInput
                        type="slider"
                        name="delay"
                        max={10}
                        min={0}
                        valueLabelDisplay="auto"
                        label="s delay"
                        variant="delay"
                    />
                    <div className={classes.seperator}></div>
                </Grid>
                <Paper elevation={2} className={classes.settingsGroupPaper} style={{marginBottom: "26px"}}>
                        <Grid container item className={classes.header} wrap="nowrap" direction="row" alignItems="center" justify="space-between" style={{height: "46px"}}>
                            <Grid item>
                                {t("Text vor und nach den Plattformverlinkungen")}
                            </Grid>
                        </Grid>
                    <Grid item style={{ width: "100%" }} className={classes.settingsGroup}>
                        <label className="FormikFormLabel">{t("Text über den Links")}</label>
                        <FormikInput
                            type="entityText"
                            name="textTop"
                            label={t("Frage")}
                            defaultValue={node.data.text}
                            typeParams={state.fields}
                        />
                        <Grid item style={{width: "100%"}}>
                            <label className="FormikFormLabel">{t("Text unter den Links")}</label>
                            <FormikInput
                                type="entityText"
                                name="textBottom"
                                typeParams={state.fields}
                            />
                            </Grid>
                    </Grid>
                </Paper>
                <FieldArray name="socialMedias" subscription={{submitting:true}}>
                    {({ fields }) =>
                        fields.map((name: string, index: number) => {
                            return (
                                <Grow in={true} key={name}>
                                    <Grid item style={{ width: "100%", marginBottom: "16px" }}>
                                        <Paper elevation={2} className={classes.settingsGroupPaper}>
                                        <Grid container item className={classes.header} wrap="nowrap" direction="row" alignItems="center" justify="space-between">
                                            <Grid item>
                                                {t("Platform ")}{index+1}
                                            </Grid>
                                            <Grid item className={classes.controls}>
                                                <Tooltip title={t("Löschen")!}>
                                                    <FaButton size="small" className={classes.icon} onClick={() => fields.remove(index)}>
                                                        <Fa icon={["fal", "trash"]} fixedWidth size="xs"/>
                                                    </FaButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                            <Grid container item direction="column" justify="center" alignItems="stretch" className={classes.settingsGroup}>
                                                <Grid item style={{ width: "100%" }} key={`plattform${index}`}>
                                                    <FormikInput
                                                        type="text"
                                                        name={`${name}.platform`}
                                                        label={t("Plattform")}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item key={`URL${index}`}>
                                                    <FormikInput
                                                        type="text"
                                                        name={`${name}.url`}
                                                        label={t("URL")}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grow>
                            )
                        })
                    }
                </FieldArray>
                <Button startIcon={<Fa icon={["fal", "plus"]} size="xs" />} style={{marginBottom: "16px"}} variant="contained" disableElevation color="success" size="large" onClick={() => mutators.push("socialMedias", undefined)}>{t("Plattform hinzufügen")}</Button>
            </Grid>
        </PerfectScrollbar>
    )
}

export default SocialMediaNodeOptions