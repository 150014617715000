import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import MUIDataTable, { FilterType, MUIDataTableOptions } from "../../../../components/table/MUIDataTable";
import webformApi, { IWebform, TemplateKey } from '../../../../models/webform'
import { Fa } from '../../../../styled/muiComponents'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Button/Button'
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmRemove, { Props as ConfirmRemoveProps } from 'dialogs/ConfirmRemove'
import { useDialog } from 'hooks/useDialog';
import PagePadding from 'components/PagePadding';
import WebformTemplatesViewDialog from 'dialogs/WebformTemplateView'
import SettingsEditDialog from './SettingsEditDialog';
import { IPermissionModules, useTariff } from "hooks/useTariff"
import NoPermission from 'components/NoPermission';

const columns = [
    {
        name: "id"
    },
    {
        name: "name",
        options: {
            editable: true
        }
    }
]

const Webforms: React.FC<{}> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const { dialog } = useDialog()
    const [ menuAnchorEl, setMenuAnchorEl ] = useState<null | HTMLElement>(null)
    const [menuIndex, setMenuIndex] = useState<null | number>(null)
    const { permissions } = useTariff()

    const handleCreate = () => {
        history.push(`${location.pathname}/create`)
    }

    const handleGoToQRCode = (webform: IWebform) => {
        history.push(`${location.pathname}/${webform.id}/qrcode`, { state: webform })
    }

    const handleDelete = async (webform: IWebform, reload: () => void) => {
        dialog.show<ConfirmRemoveProps>({
            component: ConfirmRemove,
            props: {
                items: [webform],
                model: webformApi
            }
        }).then(() => {
            reload()
        })
    }


    const handleEdit = async (item: IWebform, target?: TemplateKey) => {
        handleCloseEditMenu()
        if (target) {
            history.push(`/app/stock/webforms/edit/${item.id}/${target}?device=smartphone`)
        } else {
            //open dialog for settings
            console.log("SETTINGS no dialog yet")
            dialog.show({
                component: SettingsEditDialog,
                props: {
                    webform: item
                }
            })
        }
    }

    const handleOpenEditMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
        setMenuIndex(parseInt(e.currentTarget.id))
        setMenuAnchorEl(e.currentTarget)
    }

    const handleCloseEditMenu = () => {
        setMenuAnchorEl(null)
    }

    const handleView = (item: IWebform) => {
        console.log("handleView", item)
        dialog.show({
            component: WebformTemplatesViewDialog,
            props: {
                webform: item
            }
        })
    }

    const options: MUIDataTableOptions = {
        filterType: "multiselect" as FilterType,
        download: false
    };

    return (
        <>
            <PagePadding>
                <NoPermission module={IPermissionModules.cornerstone_webforms} />
                <MUIDataTable<IWebform>
                    translateLabels={true}
                    modelApi={webformApi}
                    title={t("Anmeldefomulare (Web)")}
                    columns={columns}
                    options={options}
                    //@ts-ignore
                    from_cornerstone={true}
                    customToolbarActions={[
                        <IconButton key="add" onClick={handleCreate} disabled={!permissions.cornerstone_webforms}>
                            <AddIcon />
                        </IconButton>
                    ]}
                    customActionsRender={(item, reload) => (
                        [
                            <Button key={"show"} onClick={() => handleView(item)} color="viewButton" variant="action" disableElevation size="small" tooltip={t("Anzeigen")} tooltipProps={{ placement: 'left' }}>
                                <Fa icon={["fas", "eye"]} />
                            </Button>,
                            <Button key={"qrcode"} onClick={() => handleGoToQRCode(item)} color="viewButton" variant="action" disableElevation size="small" tooltip={t("QR-Code anzeigen")} tooltipProps={{ placement: 'left' }}>
                                <Fa icon={["fas", "qrcode"]} />
                            </Button>,
                            <>
                                <Button id={item.id.toString()} key={"edit"} color="editButton" variant="action" disableElevation size="small" tooltip={t("Bearbeiten")} onClick={handleOpenEditMenu} tooltipProps={{ placement: 'left' }}>
                                    <Fa icon={["fas", "wrench"]} />
                                </Button>
                                {item.id === menuIndex ?
                                    <Menu
                                        key="edit-menu"
                                        anchorEl={menuAnchorEl}
                                        keepMounted
                                        open={Boolean(menuAnchorEl)}
                                        onClose={handleCloseEditMenu}
                                    >
                                        <MenuItem onClick={() => handleEdit(item)}>{t("Einstellungen")} </MenuItem>
                                        <MenuItem onClick={() => handleEdit(item, TemplateKey.formLP)}>{t("Seite mit Anmeldeformular")} </MenuItem>
                                        <MenuItem onClick={() => handleEdit(item, TemplateKey.soiLP)}>{t("Zielseite nach dem das Anmeldeformular ausgefüllt wurde")} </MenuItem>
                                        <MenuItem onClick={() => handleEdit(item, TemplateKey.doiMail)}>{t("E-Mail zur Anmeldebestätigung")} </MenuItem>
                                        <MenuItem onClick={() => handleEdit(item, TemplateKey.doiLP)}>{t("Zielseite nach Anmeldebestätigung")} </MenuItem>
                                        <MenuItem onClick={() => handleEdit(item, TemplateKey.doneMail)}>{t("Willkommens-E-Mail")} </MenuItem>
                                    </Menu>
                                    :
                                    null
                                }

                            </>,
                            <Button key={"delete"} color="deleteButton" variant="action" disableElevation size="small" tooltip={t("Löschen")} tooltipProps={{ placement: 'left' }} onClick={() => handleDelete(item, reload)}>
                                <Fa icon={["fas", "trash"]} />
                            </Button>
                        ]
                    )}
                />
            </PagePadding>
        </>
    )
}

export default Webforms