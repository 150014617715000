import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faPlusCircle,
    faFlask,
    faCommentsQuestionCheck,
    faClapperboardPlay,
    faGear,
    faMessagePen
} from '@fortawesome/pro-duotone-svg-icons'

library.add( 
    faPlusCircle,
    faFlask,
    faCommentsQuestionCheck,
    faClapperboardPlay,
    faGear,
    faMessagePen
 )