import React from "react"
import { Route, Switch } from "react-router-dom"
import useSession from "hooks/useSession"
import { useHistory } from "react-router-dom"
import SetupWizard from "./setupWizard/SetupWizard"

const SetupRouter = () => {
    const { realm, imprint } = useSession()
    const history = useHistory()

    if (realm?.status !== "DEMO" && imprint !== null) {

        history.push(`/app`) // gives a warning "Cannot update during an existing state transition"
    }

    return (
        <Switch>
            <Route path="/setup">
                <SetupWizard />
            </Route>
        </Switch>
    )
}
export default SetupRouter