import React, { useEffect, useRef, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import PreviewFrame from 'components/PreviewFrame'
import { insertDynamicImprint, insertImprint, insertLogo, insertPlaceholders } from 'util/placeholders'
import { IPlaceholder, TemplateKey } from 'models/webform'
import { ICustomHeader } from "./Editor"
import SwipeableViews from "react-swipeable-views"
import { FormValues } from 'routes/app/stock/webforms/wizard/steps/FirstStep'
import { Device } from 'types/common'
import { IChats, ITemplateMailSettings } from 'routes/app/stock/webforms/wizard/WebformWizard'
import useSession, { Address } from 'hooks/useSession'
import ChatView from "routes/app/stock/webforms/wizard/steps/ChatsView"
import { ReactFlowProvider } from 'react-flow-renderer'
import images from 'models/images'

type Props = {
    template?: string
    placeholders: Record<string, IPlaceholder>
    customHeader: ICustomHeader
    previewIdentifier: TemplateKey
    device: Device
    basicInfo: FormValues | null
    templateMailSettings?: ITemplateMailSettings
    values?: any
    active?: any
    wizardState?: any
    chats?: IChats | null
    chatView?: "chat" | "graph" | null
    imprint?: Address | null
}

const useStyles = makeStyles<Theme, { device: Device }>(theme => ({
    root: {
        width: "100%",
        height: "100%",
        overflowY: "visible",
    },
    previewWrapper: {
        height: "100%",
        width: "100%",
        backgroundColor: theme.palette.primary.light,

    },
    previewDesktop: {
        width: "100%",
        minWidth: "1048px",
        overflow: "hidden",
        height: "100%",
        maxHeight: "100vh",
        border: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    previewMobile: {
        width: "100%",
        height: "100%",
        maxHeight: "100vh",
        overflow: "auto",
        border: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    previewTablet: {
        width: "100%",
        height: "100%",
        maxHeight: "100vh",
        overflow: "auto",
        border: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    previewFullscreen: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        border: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    style: {
        color: "#fff"
    },
    slide1: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        border: "0",
        justifyContent: "center",
        alignItems: "center"
    },
    slide2: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        border: "0",
        justifyContent: "center",
        alignItems: "center"
    },
    slide3: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        border: "0",
        justifyContent: "center",
        alignItems: "center"
    },
    slide4: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        border: "0",
        justifyContent: "center",
        alignItems: "center"
    }
}))

const DEVICE_ORDINAL: Record<Device, number> = {
    [Device.Smartphone]: 0,
    [Device.Tablet]: 1,
    [Device.Desktop]: 2,
    [Device.Fullscreen]: 3
}

const View = ({ templateMailSettings, basicInfo, template, placeholders, customHeader, previewIdentifier, device, values = null, active = null, wizardState = null, chats = null, chatView = null, imprint = null }: Props) => {
    const classes = useStyles({ device })
    const iframeRef = useRef<HTMLIFrameElement | null>(null)
    const [templateValues, setTemplateValues] = useState<any>(placeholders)
    const [replacedTemplate, setReplacedTemplate] = useState<any>(null)
    const replacedTemplateRef = useRef<any>("")
    const [logoURL, setLogoURL] = useState<string | null>(null)
    const {logo} = useSession()
    // const [imprint, setImprint] = useState(null)

    const customReplace = (template: string, placeholders: Record<string, Pick<IPlaceholder, "key" | "value" | "default"> | IPlaceholder>, imprint: any | null): string => {
        return insertImprint(insertDynamicImprint(insertPlaceholders(insertLogo(template, logo), placeholders), imprint), imprint)
    }

    useEffect(() => {
        if (templateValues === null) {
            setTemplateValues(values)
        } else {
            if (!template || !values) {
                return
            }
            const tempValues = { ...templateValues }
            const keys = Object.keys(values)
            let diff = { name: "", value: "" }

            keys.forEach((key: string) => {
                if (key === "subject" || key === "chat") {
                    return
                }
                if (tempValues[key]?.value) {
                    if (values[key] !== tempValues[key]?.value) {
                        diff = { name: key, value: values[key] }
                    }
                } else if (values[key] !== tempValues[key].default) {
                    diff = { name: key, value: values[key] }
                }
            })

            const newPlaceholders = {
                ...templateValues,
                [diff.name]: {
                    ...templateValues[diff.name],
                    value: diff.value
                }
            }
            const newTemplate = customReplace(template, newPlaceholders, imprint)
            setReplacedTemplate(newTemplate)
            setReplacedTemplate(insertPlaceholders(template, newPlaceholders))
            replacedTemplateRef.current = insertPlaceholders(template, templateValues)
            setTemplateValues(newPlaceholders)
            setLogoURL(logo)
        }
    }, [values])

    // useEffect(() => {
    //     const fetchLogo = async () => {
    //         const url = await images.getLogo()
    //         setLogoURL(url)
    //     }
    //     fetchLogo()
    // }, [])

    const writeToDocument = (template: string) => {
        var iframe = iframeRef.current
        var doc = iframe?.contentWindow?.document || iframe?.contentDocument;
        if (!doc) return
        doc.open();
        doc.write(template);
        doc.close();
    }

    useEffect(() => {
        if (template) {
            writeToDocument(template)
        }
    }, [template, device, templateValues, replacedTemplate, values])

    // useEffect(() => {
    //     const fetchImprint = async () => {
    //         const response = await imprints.getFirst()
    //         setImprint(response)
    //     }
    //     fetchImprint()
    // }, [])

    // if (!imprint){
    //     return <LoadingAnimation/>
    // }


    if (wizardState) {
        if (wizardState.chat !== null && wizardState.chat !== 0 && previewIdentifier === "doiLP" && chatView === "graph") {
            return (
                <ReactFlowProvider>
                    <ChatView chats={chats} id={wizardState.chat} chatView={chatView} />
                </ReactFlowProvider>
            )
        }
    }

    let chat = null
    if (chats) {
        chat = chats.chats.find((c: any) => c.id === wizardState.chat)
    }

    return (
        <div className={classes.root}>
            <div className={classes.previewWrapper}>
                <SwipeableViews index={DEVICE_ORDINAL[device]} style={{ height: "100%" }}>
                    <div className={classes.previewMobile}>
                        {DEVICE_ORDINAL[device] === 0 && template && <PreviewFrame variant="smartphone" chat={chat} chatView={chatView} html={customReplace(template, placeholders, imprint)} customHeader={customHeader} previewIdentifier={previewIdentifier} basicInfo={basicInfo} templateMailSettings={templateMailSettings} imprint={imprint} />}
                    </div>
                    <div className={classes.previewTablet}>
                        {DEVICE_ORDINAL[device] === 1 && template && <PreviewFrame variant="tablet" chat={chat} chatView={chatView} html={customReplace(template, placeholders, imprint)} customHeader={customHeader} previewIdentifier={previewIdentifier} basicInfo={basicInfo} templateMailSettings={templateMailSettings} imprint={imprint} />}
                    </div>
                    <div className={classes.previewDesktop}>
                        {DEVICE_ORDINAL[device] === 2 && template && <PreviewFrame variant="desktop" chat={chat} chatView={chatView} html={customReplace(template, placeholders, imprint)} customHeader={customHeader} previewIdentifier={previewIdentifier} basicInfo={basicInfo} templateMailSettings={templateMailSettings} imprint={imprint} />}
                    </div>
                    <div className={classes.previewFullscreen}>
                        {DEVICE_ORDINAL[device] === 3 && template && <PreviewFrame variant="fullscreen" chat={chat} chatView={chatView} html={customReplace(template, placeholders, imprint)} customHeader={customHeader} previewIdentifier={previewIdentifier} basicInfo={basicInfo} templateMailSettings={templateMailSettings} imprint={imprint} />}
                    </div>
                </SwipeableViews>
            </div>
        </div >
    )
}

export default View