import React, { useState } from "react"
import { Slider, Grid, makeStyles, Grow } from "@material-ui/core"
import { Fa } from 'styled/muiComponents'

const useStyles = makeStyles(theme => ({
    root: {
        width: "calc(100% - 12px)",
        boxSizing: "border-box",
        marginTop: "3px",
    },

    delaySliderContainer: {
        backgroundColor: theme.palette.primary.light,
        borderRadius: "25px",
        color: "#ffffff",
        marginBottom: theme.spacing(1),
        height: "36px",
        lineHeight: "0",
        boxSizing: "border-box"
    },
    sliderContainer: {
        width: "100%",
        flexWrap: "nowrap"
    },
    closeButton: {
        cursor: "pointer",
        transition: "0.3s all ease",
        "&:hover": {
            color: theme.palette.companyRed.main
        }
    },
    label: {
        transition: "0.3s background-color ease !important",
        height: "100%",
        "&:hover": {
            backgroundColor: theme.palette.companyRed.main
        }
    }
}))

const FormikSlider = ({ ...props }) => {
    const { input: { name, value }, meta, ...prop } = props

    const classes = useStyles()
    const [isActive, setActive] = useState(false)

    const toggleSlider = () => {
        setActive(!isActive);
    }

    // const handleChange = (event, value) => {
    //     if (value !== sliderValue) {
    //         setSliderValue(value)
    //         if (returnValue) {
    //             if (onChange) {
    //                 onChange({ target: { value: returnValue[value-1], name: name } })
    //             }
    //             field.onChange({ target: { value: returnValue[value-1], name: name } })
    //         } else {
    //             if (onChange) {
    //                 onChange({ target: { value: value, name: field.name } })
    //             }
    //             field.onChange({ target: { value: value, name: field.name } })
    //         }
    //     }
    // }

    switch (props.variant) {
        case "delay": return (
            <Grid container alignItems="center" justify="center" className={classes.delaySliderContainer} style={{ width: isActive ? '100%' : 'auto', overflow: isActive ? null : 'hidden', }}>
                <Grow item in={!isActive}>
                    <Grid container alignItems="center" justify="center" onClick={toggleSlider} style={{ display: !isActive ? null : "none", fontSize: "0.8em", cursor: "pointer", padding: "8px 16px", }} className={classes.label}>
                        <Fa icon={["far", "stopwatch"]} mr={1} />{value}{prop.label}
                    </Grid>
                </Grow>
                <Grow item in={isActive}>
                    <Grid container alignItems="center" justify="center" className={classes.sliderContainer} style={{ padding: !isActive ? "0" : "4px 18px", display: !isActive ? "none" : null }}>
                        <Fa icon={["far", "stopwatch"]} mr={2} />
                        <Slider
                            style={{ display: isActive ? null : "none" }}
                            variant="outlined"
                            color="secondary"
                            // {...props.input}
                            {...prop}
                            name={name}
                            value={value}
                            onChange={(_, value) => props.input.onChange(value)}
                        // onChange={props.input.onChange}
                        //value={value}
                        />
                        <Fa icon={["far", "times"]} ml={2} onClick={toggleSlider} className={classes.closeButton} />
                    </Grid>
                </Grow>
            </Grid>
        )

        default: // variant returnvalue
            const returnValueMapper = (value) => {
                const returnValue = props.returnValue
                return (returnValue.findIndex((element) => element === value) +1 || 3)
            }
            
            const handleChange = (event, value) => {
                if (value) {
                    //setSliderValue(value)
                    if (props.returnValue) {
                        if (props.onChange) {
                            props.onChange({ target: { value: props.returnValue[value - 1], name: name } })
                        }
                        //field.onChange({ target: { value: props.returnValue[value-1], name: name } })
                        props.input.onChange(value)
                    } else {
                        if (props.onChange) {
                            props.onChange({ target: { value, name } })
                        }
                        //field.onChange({ target: { value: value, name: field.name } })
                        props.input.onChange(value)

                    }
                }
            }

            if (props.label) {
                console.log(value, name, prop)
                return (
                    // TODO Make this as a variant and set just slider without a grid as default
                    <Grid container alignItems="center">
                        <Grid item xs={8}>
                            {props.label}
                        </Grid>
                        <Grid item xs={4}>
                            <Slider
                                name={name}
                                // value={value} // does this work everywhere?
                                value={prop.returnValue ? returnValueMapper(value) : value}
                                //onChange={(_, value) => props.input.onChange(value)}
                                {...prop}
                                //value={sliderValue}  
                                onChange={handleChange}
                                className={classes.root}
                            />
                        </Grid>
                    </Grid>
                )
            } else {
                return(
           
                        <Slider
                            name={name}
                            value={value} // does this work everywhere?
                            //onChange={(_, value) => props.input.onChange(value)}
                            {...prop}
                            //value={sliderValue}  
                            onChange={handleChange}
                            className={classes.root}
                        />
                   
           
                )
            }


    }
}

export default FormikSlider