import React from 'react'
import { IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import MUIDataTable, { FilterType, CustomMUIDataTableOptions } from "../../../../components/table/MUIDataTable";
import { Fa } from '../../../../styled/muiComponents'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Button/Button'
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmRemove, { Props as ConfirmRemoveProps } from 'dialogs/ConfirmRemove'
import { useDialog } from 'hooks/useDialog';
import PDFTemplateApi, { IPDFTemplate } from 'models/pdfTemplate';
import PagePadding from 'components/PagePadding';
import { useTariff, IPermissionModules } from "hooks/useTariff"
import NoPermission from 'components/NoPermission';
import DownloadPDFDialog from './DownloadPDFDialog';
import PickWebformDialog from './wizard/dialogs/PickWebformDialog';



const columns = [
    {
        name: "id"
    },
    {
        name: "name",
        options: {
            editable: true
        }
    },
    {name: "size"},
    {name: "type"}

]

const PDFTemplates: React.FC<{}> = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const { dialog } = useDialog()
    const { permissions } = useTariff()

    const handleCreate = () => {
        history.push(`${location.pathname}/create`)
    }

    const handleDelete = async (template: IPDFTemplate, reload: () => void) => {
        dialog.show<ConfirmRemoveProps>({
            component: ConfirmRemove,
            props: {
                items: [template],
                model: PDFTemplateApi
            }
        }).then(() => {
            reload()
        })
    }

    const options: CustomMUIDataTableOptions = {
        filterType: "multiselect" as FilterType,
        download: false
    };

    const handleView = (item: IPDFTemplate) => {
        history.push(`${location.pathname}/${String(item.id)}/view`, { template: item })
    }

    const handleEdit = (item: IPDFTemplate) => {
        history.push(`${location.pathname}/${String(item.id)}`, { template: item, step: 1 })
    }

    const handleDownload = (item: IPDFTemplate) => {
        dialog.show<any>({
            component: PickWebformDialog ,
            props: {
                prepare:true
            }
        }).then((response) => {
            if(response.success){
                dialog.show<any>({
                    component: DownloadPDFDialog,
                    props:{
                        webform: response.webform,
                        item
                    }
                })
            }
        })
    }

    return (
        <>
            <PagePadding>
                <NoPermission module={IPermissionModules.pdf_templates} />
                <MUIDataTable<IPDFTemplate>
                    translateLabels={true}
                    modelApi={PDFTemplateApi}
                    title={t("Printmedien")}
                    columns={columns}
                    options={options}
                    customToolbarActions={[
                        <IconButton key="add" onClick={handleCreate} disabled={!permissions.pdf_templates}>
                            <AddIcon />
                        </IconButton>
                    ]}
                    customActionsRender={(item, reload) => (
                        [
                            <Button key={"show"} color="viewButton" variant="action" disableElevation size="small" tooltip={t("Anzeigen")} tooltipProps={{ placement: 'left' }} onClick={() => handleView(item)}>
                                <Fa icon={["fas", "eye"]} />
                            </Button>,
                            <Button key={"edit"} color="editButton" variant="action" disableElevation size="small" tooltip={t("Bearbeiten")} tooltipProps={{ placement: 'left' }} onClick={() => handleEdit(item)}>
                                <Fa icon={["fas", "wrench"]} />
                            </Button>,
                            <Button key={"download"} color="success" variant="action" disableElevation size="small" tooltip={t("Herunterladen")} tooltipProps={{ placement: 'left' }} onClick={() => handleDownload(item)}>
                                <Fa icon={["fas", "download"]} />
                            </Button>,
                            <Button key={"delete"} color="deleteButton" variant="action" disableElevation size="small" tooltip={t("Löschen")} tooltipProps={{ placement: 'left' }} onClick={() => handleDelete(item, reload)}>
                                <Fa icon={["fas", "trash"]} />
                            </Button>
                        ]
                    )}
                />
            </PagePadding>
        </>
    )
}

export default PDFTemplates