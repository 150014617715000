import React, { useState } from 'react';
// import { Editor, EditorState, Entity, Modifier } from 'draft-js';
import { Editor, EditorState, Modifier } from "draft-js";
import 'draft-js/dist/Draft.css';
import { makeStyles, Menu, MenuItem } from '@material-ui/core';
import { getEntityRange, getSelectionEntity } from "draftjs-utils"
import { decorator } from './helperFunctions';
import "./entityText.css"
import { Fa, FaButton } from "styled/muiComponents"
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles((theme) => ({
    box: {
        border: "1px solid rgba(0, 0, 0, 0.23)",
        maxWidth: "100%",
        height: "auto",
        minHeight: "1.1876em",
        maxHeight: "165px",
        padding: "18.5px 14px",
        borderRadius: theme.spacing(0.5),
        overflow: "auto",
        marginBottom: theme.spacing(3),
        "&:hover": {
            borderColor: "#000000"
        }
    },
    placeholderButton: {
        marginRight: theme.spacing(1.5),
        float: "right",
        marginTop: "-37px",
        backgroundColor: theme.palette.companyDark.main,
        fontSize: "0.7em",
        padding: 0,
        color: "#ffffff",
        transition: "0.3s background-color ease !important",
        zIndex: 10,
        "&:hover": {
            backgroundColor: theme.palette.companyRed.main
        }
    },
    icon: {
        padding: "6px 7px"
    },
    formikEditor: {
        fontWeight: "300",
        "&:focus": {
            borderWidth: "2px"
        }
    },
    emojiButton: {
        marginRight: theme.spacing(5.5),
        float: "right",
        marginTop: "-37px",
        backgroundColor: theme.palette.companyDark.main,
        fontSize: "0.7em",
        padding: 0,
        color: "#ffffff",
        transition: "0.3s background-color ease !important",
        zIndex: 10,
        "&:hover": {
            backgroundColor: theme.palette.companyRed.main
        }
    }
}))

const EntityText = ({...props }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const {input:{...field}}= props

    const handleChange = (e) => {
        if (props.onChange) {
            props.onChange({ target: { name: field.name, value: e } })
        }
        field.onChange({ target: { name: field.name, value: e } })
    }

    const handleBlur = (e) => {
        if (props.onBlur) {
            props.onBlur({ target: { name: field.name, value: e } })
        }
        field.onBlur({ target: { name: field.name, value: e } })
    }

    const insertFieldEntry = (name, placeholder) => {
        const currentContent = field.value.getCurrentContent();
        let selection = field.value.getSelection();
        const contentStateWithEntity = currentContent.createEntity(
            "PLACEHOLDER",
            "IMMUTABLE",
            { placeholder: placeholder }
        );

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const entityRange = getEntityRange(field.value, getSelectionEntity(field.value))

        if (entityRange !== undefined) {
            const isBackward = selection.getIsBackward()
            if (isBackward) {
                selection = selection.merge({
                    anchorOffset: entityRange.end,
                    focusOffset: entityRange.start
                })
            } else {
                selection = selection.merge({
                    anchorOffset: entityRange.start,
                    focusOffset: entityRange.end
                })
            }

            const updatedEditorWithText = Modifier.replaceText(currentContent, selection, `\${${name}}`, null, entityKey)
            const newEditorState = EditorState.push(field.value, updatedEditorWithText, "insert-characters")
            //setEditorState(EditorState.push(newEditorState, updatedEditorWithText, "insert-characters"))
            handleChange(EditorState.push(newEditorState, updatedEditorWithText, "insert-characters"))
        } else {

            const textWithEntity = Modifier.insertText(
                currentContent,
                selection,
                `\${${name}}`,
                undefined,
                entityKey
            );
            const newState = EditorState.push(
                field.value,
                textWithEntity,
                "insert-characters"
            );
            // setEditorState(EditorState.forceSelection(
            //     newState,
            //     textWithEntity.getSelectionAfter()
            // ))
            handleChange(EditorState.forceSelection(
                     newState,
                     textWithEntity.getSelectionAfter()
                 ))
        }
    };

    // const handleKeyCommand = (value) => {
    //     if (value === "save") {
    //         handleChange()
    //     }
    // }

    // const myKeyBindingFn = (e) => {
    //     if (e.keyCode === 13) {
    //         return "save"
    //     }
    //     return getDefaultKeyBinding(e)
    // }

    const Value= () => {
        if (field.value !== null && field.value !== undefined && field.value !== ""){
            return field.value
        } else {
            return EditorState.createEmpty(decorator)
        }
    }

    const [hasFocus, setFocus] = useState(false);

    return (
        <>
            <PerfectScrollbar className={classes.box} style={hasFocus ? {borderWidth: "2px", ...props.style} : {borderWidth: "1px", ...props.style}} >
                <Editor
                    editorState={Value()}
                    onChange={(e) => {
                        handleChange(e)
                    }}
                    onBlur={(e) =>{
                        handleBlur(e);
                        setFocus(false)
                    } }
                    onFocus={() => {
                        setFocus(true)
                    }}
                    {...props.input}
                    className={classes.formikEditor}
                    // handleKeyCommand={handleKeyCommand}
                    // keyBindingFn={myKeyBindingFn}
                />
            </PerfectScrollbar>
            <FaButton 
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                size="small"
                className={classes.placeholderButton}
            >
                <Fa className={classes.icon} icon={["fas", "user"]} />
            </FaButton>
            
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {props.typeParams.map((param, index) =>
                    <MenuItem key={index} onClick={() => insertFieldEntry(param.name, param.name)}>{param.name}</MenuItem>
                )}
            </Menu>

            <FaButton 
                id="emoji-picker"
                aria-controls={open ? 'emoji-picker' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                size="small"
                className={classes.emojiButton}
            >
                <Fa className={classes.icon} icon={["fas", "face-smile"]} />
            </FaButton>

        </>
    );
}

export default EntityText
