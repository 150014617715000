import Button from "components/Button/Button"
import React from "react"

const Edge = ({state, dispatch}: any) => {

    const handleDelete = () => {
        dispatch({type:"DELETE_EDGE", payload: {edge: state.selected}})
    }

    return (
        <div>
           <p> when edge selected</p>
           <Button onClick={handleDelete}>löschen</Button>
           {JSON.stringify(state.selected)}
        </div>
        
    )
}

export default Edge