import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, } from '@material-ui/core'
import FormikInput from 'components/formik/FormikInput'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button/Button'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Grid } from "styled/muiComponents"
import { DialogProps } from 'hooks/useDialog'
import DialogTitle from 'dialogs/DialogTitle'
import webformApi, { IWebform } from 'models/webform'
import { IDomain } from 'models/senderDomain'
import { Form } from 'react-final-form'
import { useAlert } from 'hooks/useAlert'
import senderAdressApi from 'models/senderAddresses'

export type Props = {
    webform: IWebform
} & DialogProps

const SettingsEditDialog = ({ open, onClose, webform }: Props) => {
    const { t } = useTranslation()
    const [ domain, setDomain ] = useState<IDomain | null>(null)
    const { alert } = useAlert()

    useEffect(() => {
        (async () => {
            const d = await senderAdressApi.get(webform.sender_domain_id)
            setDomain(d)
        })()
    }, [webform.sender_domain_id])

    const handleSubmit = async (formValues: FormValues) => {
        console.log("handleSubmit", formValues)
        webformApi.update(webform.id, formValues)
        .then(() => {alert.success(t("Speichern erfolgreich")); onClose({})})
    }

    return (
        <Dialog open={open}>
            <DialogTitle onClose={onClose}>{t("Formular bearbeiten")}</DialogTitle>
            <DialogContent>
                { !domain && "loading"}
                { domain && <EditForm 
                    initialValues={{
                        name: webform.name,
                        useDoiChain: webform.use_doi_chain,
                        senderName: webform.sender_name,
                        senderEmailLocalPart: webform.sender_email_local_part,
                        senderEmailDomain: domain
                    }}
                    onSubmit={handleSubmit}
                />}
            </DialogContent>
        </Dialog>
    )
}

export default SettingsEditDialog


type EditFormProps = {
    initialValues: FormValues,
    onSubmit: (formValues: FormValues) => void
}

/** Todo
 * Bie Registrierung
 * Domain abfragen, subdomain von unserer anbieten
 */

export type FormValues = {
	name: string
	useDoiChain: boolean
	senderName: string
	senderEmailLocalPart: string
	senderEmailDomain: IDomain | null
}

const EditForm = ({ initialValues, onSubmit }: EditFormProps) => {
    const { t } = useTranslation()

	return (
        <Form initialValues={initialValues} onSubmit={onSubmit}
			render={({ handleSubmit, submitting }) => (
                <div>
                    <form onSubmit={handleSubmit}>
                        <Grid container direction="column" justify="flex-start">
                            <Grid item container spacing={3}>
                                <Grid item sm={12}>
                                    <FormikInput
                                        name="name"
                                        variant="outlined"
                                        label={t("Name")}
                                        type="string"
                                    />
                                </Grid>
                                {/* <Grid item sm={12}>
                                    <FormikInput
                                        name="useDoiChain"
                                        type="checkbox"
                                        label={t("DoiChain Verifizierung nutzen")}
                                        color="primary"
                                    />
                                </Grid> */}
                                <Grid item sm={12}>
                                    <FormikInput
                                        name="senderName"
                                        required
                                        variant="outlined"
                                        type="string"
                                        label={t("Absender-Name")}
                                    />
                                <Grid item sm={12}>
                                    <FormikInput
                                        type="senderEmail"
                                        required
                                        variant="outlined"
                                        localPartProps={{
                                            name:"senderEmailLocalPart",
                                            label: t("E-Mail")
                                        }}
                                        domainPartProps={{
                                            name: "senderEmailDomain",
                                            label: t("Domain")
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <Button variant="contained" color="success" type="submit" isLoading={submitting}>{t("Weiter")}<KeyboardArrowRight /></Button>
                                </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            )}
        />
	)
}