import React, { useState } from "react" 
import { SetupNavigationProps, Navigation, ISetupInformation } from "../SetupWizard"
import { Grid } from "@material-ui/core"
import Pixi from "components/Pixi"
import Typist from "react-typist"
import { Typography } from "styled/muiComponents"
import { useTranslation } from "react-i18next"

type Props = {
    navigationProps: SetupNavigationProps
    classes: any
    setupInformation: ISetupInformation
}

const SetupExplanation = ({ navigationProps, classes, setupInformation }: Props) => {
    const [enableNext, setEnableNext] = useState<boolean>(false)
    const { t } = useTranslation()

    return (
            <Grid container item direction="column" className={classes.root}>
                <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                    <Grid item className={classes.form}>
                        <Grid container direction="column" justify="flex-start">
                            <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                <Grid item sm={4}>
                                    <div style={{ maxWidth: "150px" }}>
                                        <Pixi size="full" pose="waiting"></Pixi>
                                    </div>
                                </Grid>
                                <Grid item sm={8}>
                                    <div className={classes.headerDescription} >
                                    <Typist avgTypingDelay={-100} cursor={{ show: false }} onTypingDone={() => setEnableNext(true)}>
                                        <Typography variant="h5" className={classes.pixiSpeech}>
                                          {t(`Schön dich kennen zu lernen, ${setupInformation.first_name}.`)}
                                          <br /><br />
                                          {t(`Bevor du durchstarten kannst, benötige ich noch ein paar Angaben von dir.
                                            So kann ich zum Beispiel an den rechtlich relevanten Stellen dein Impressum hinterlegen, damit du dich um diese langweiligen Dinge nicht selbst kümmern musst.`)}
                                          <br /><br />
                                          {t(`Klicke auf "Weiter", wenn du startklar bist.`)}
                                        </Typography>
                                    </Typist>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} style={{paddingTop: "32px"}}>
                            <Navigation  {...navigationProps} enableNext={!enableNext}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default SetupExplanation