import React from "react"
import { Handle, Position } from "react-flow-renderer"
import { makeStyles, Theme } from "@material-ui/core"
import { gotoHighlight, chatHighlight } from "./chatFunctions"
import { useChatState } from "./ChatContext"
import { Grid, Typography, Fa } from 'styled/muiComponents'
import { secondaryHue } from 'theme'
import { NodeTooltip } from "./NodeTooltip"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles<Theme>(theme => ({
    handleIcon: {
        pointerEvents: "none",
        color: "#000000",
    },
    defaultNodeWrapper: {
        cursor: "pointer",
        borderRadius: "20px",
        "&:hover": {
            "& $addHandler": {
                opacity: 1
            }
        }
    },
    noSourceHandleNodeWrapper: {
        cursor: "pointer",
        // "&:hover": {
        //     "& $addHandler": {
        //         opacity: 1
        //     }
        // }
    },
    defaultNodeContent: {
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        lineHeight: 0,
        minWidth: 150
    },
    defaultNodeHighlight: {
        padding: "10px 15px",
        borderRadius: "20px",
    },
    dataCollectionNode: {
        cursor: "pointer",
        "&:hover": {
            "& $addHandler": {
                opacity: 1
            }
        }
    },
    nodeContent: {
        fontSize: "0.6rem"
    },
    nodeLabel: {
        fontSize: "0.9rem",
        lineHeight: 0,
        fontWeight: 400
    },
    addHandler: {
        background: "#ffffff",
        height: 20,
        width: 20,
        right: -25,
        padding: 0,
        margin: 0,
        border: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: 0,
        transition: "0.2s opacity ease",
        cursor: "pointer"
    },

}))


const useHighlightStyles = makeStyles<Theme, { goto: boolean }>(theme => ({
    goto: {
        outline: ({ goto }) => goto ? "5px solid rgba(131, 172, 27, 1 )" : "5px solid rgb(244, 67, 54)",
        borderRadius: "20px",
    //    border: "2px solid #ffffff !important",
        outlineOffset: "4px"
    },
    chat: {
        // border: ({chat}) => chat ? "10px solid orange" : "none"
        outline: "5px solid rgba(131, 172, 27, 0.7)",
        borderRadius: "20px",
	    boxShadow: "0 0 0 0 rgba(0, 0, 0, 1)",
	    animation: "$pulse-green 1.75s infinite"
    },
    "@keyframes pulse-green": {
        "0%": {
            //transform: "scale(0.95)",
            boxShadow: "0 0 0 0 rgba(131, 172, 27, 0.8)"
        },
        "70%": {
            //transform: "scale(1)",
            boxShadow: "0 0 0 20px rgba(131, 172, 27, 0)"
        },
        "100%": {
            //transform: "scale(0.95)",
            boxShadow: "0 0 0 0 rgba(131, 172, 27, 0)"
        }

    },
}))

// IS VALIDCONNECTION CALLBACK EXISTS
export const BotResponseNode = ({ data }: any) => {
    const { t } = useTranslation()
    const { state, dispatch } = useChatState()
    const highlight = gotoHighlight(data.node.id, state)
    const highlightChat = chatHighlight(data.node.id, state)
    const classes = useStyles()
    const highlightClasses = useHighlightStyles({ goto: highlight.valid })
    const hlClasses = `${highlight.highlight ? highlightClasses.goto : null} ${highlightChat ? highlightClasses.chat : null}`

    const handleClickHandle = (handleId: string) => {
        dispatch({ type: "SELECT_HANDLE", payload: { node: data.node, handle: { id: handleId } } })
    }

    return (
        <div className={hlClasses}>
            <NodeTooltip data={data}>
                <Grid container alignItems="center" className={classes.defaultNodeWrapper}>
                    <Grid item className={classes.defaultNodeContent}>
                        <div className={classes.defaultNodeHighlight} style={highlight.highlight ? highlight.valid ? {backgroundColor: "rgba(131, 172, 27, 0.7)"} : {background: "linear-gradient(145deg, rgba(244,67,54,0.7) 10%, rgba(244,67,54,0.8) 10%, rgba(244,67,54,0.8) 20%, rgba(244,67,54,0.7) 20%, rgba(244,67,54,0.7) 30%, rgba(244,67,54,0.85) 30%, rgba(244,67,54,0.8) 40%, rgba(244,67,54,0.7) 40%, rgba(244,67,54,0.7) 50%, rgba(244,67,54,0.8) 50%, rgba(244,67,54,0.8) 60%, rgba(244,67,54,0.7) 60%, rgba(244,67,54,0.7) 70%, rgba(244,67,54,0.8) 70%, rgba(244,67,54,0.8) 80%, rgba(244,67,54,0.7) 80%, rgba(244,67,54,0.7) 90%, rgba(244,67,54,0.8) 90%, rgba(244,67,54,0.8) 100%)", color: "#ffffff"} : {backgroundColor: "transparent"}}>
                            <Typography className={classes.nodeLabel}><Fa icon={["fal", "comment-alt-smile"]} color={highlight.highlight ?  highlight.valid ? "primary.main" : "secondary.main" : "secondary.main"} fixedWidth mr={1} />{t("Bot-Antwort")}</Typography>
                            <Handle
                                id={`N`}
                                type="target"
                                position={Position.Left}
                                style={{ background: "black" }}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Handle
                            id={`S`}
                            type="source"
                            position={Position.Right}
                            style={{ pointerEvents: "none", opacity: 0 }}
                        />
                    </Grid>
                    {!state.goToSelection ?
                    <Grid item>
                        <Handle
                            id={"SCLICK"}
                            type="source"
                            position={Position.Right}
                            className={classes.addHandler}
                            onClick={() => handleClickHandle("S")}
                        >
                            <Fa icon={["fal", "plus"]} className={classes.handleIcon} />
                        </Handle>
                    </Grid>
                    : null}
                </Grid>
            </NodeTooltip>
        </div>
    )
}

export const DataCollectionNode = ({ data }: any) => {
    const { t } = useTranslation()
    const { state, dispatch } = useChatState()
    const highlight = gotoHighlight(data.node.id, state)
    const highlightChat = chatHighlight(data.node.id, state)
    const classes = useStyles()
    const highlightClasses = useHighlightStyles({ goto: highlight.valid })
    const hlClasses = `${highlight.highlight ? highlightClasses.goto : null} ${highlightChat ? highlightClasses.chat : null}`

    const handleClickHandle = (handleId: string) => {
        dispatch({ type: "SELECT_HANDLE", payload: { node: data.node, handle: { id: handleId } } })
    }

    return (
        <div className={hlClasses}>
            <NodeTooltip data={data}>
                <Grid container alignItems="center" className={classes.defaultNodeWrapper}>
                    <Grid item className={classes.defaultNodeContent} style={highlight.highlight ? {backgroundColor: "#ffffff"} : {backgroundColor: "#f8bbff"}}>
                    <div className={classes.defaultNodeHighlight} style={highlight.highlight ? highlight.valid ? {backgroundColor: "rgba(131, 172, 27, 0.7)"} : {background: "linear-gradient(145deg, rgba(244,67,54,0.7) 10%, rgba(244,67,54,0.8) 10%, rgba(244,67,54,0.8) 20%, rgba(244,67,54,0.7) 20%, rgba(244,67,54,0.7) 30%, rgba(244,67,54,0.85) 30%, rgba(244,67,54,0.8) 40%, rgba(244,67,54,0.7) 40%, rgba(244,67,54,0.7) 50%, rgba(244,67,54,0.8) 50%, rgba(244,67,54,0.8) 60%, rgba(244,67,54,0.7) 60%, rgba(244,67,54,0.7) 70%, rgba(244,67,54,0.8) 70%, rgba(244,67,54,0.8) 80%, rgba(244,67,54,0.7) 80%, rgba(244,67,54,0.7) 90%, rgba(244,67,54,0.8) 90%, rgba(244,67,54,0.8) 100%)", color: "#ffffff"} : {backgroundColor: "transparent"}}>
                            <Typography className={classes.nodeLabel}><Fa icon={["fal", "message-question"]} color="primary.main" fixedWidth mr={1} />{t("Datenabfrage")}</Typography>
                            <Handle
                                id={`N`}
                                type="target"
                                position={Position.Left}
                                style={{ background: "black", }}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Handle
                            id={`S`}
                            type="source"
                            position={Position.Right}
                            style={{ pointerEvents: "none", opacity: 0 }}
                        />
                    </Grid>
                    {!state.goToSelection ?
                    <Grid item>
                        <Handle
                            id={"SCLICK"}
                            type="source"
                            position={Position.Right}
                            className={classes.addHandler}
                            onClick={() => handleClickHandle("S")}
                        >
                            <Fa icon={["fal", "plus"]} className={classes.handleIcon} />
                        </Handle>
                    </Grid>
                    : null}
                </Grid>
            </NodeTooltip>
        </div>
    )
}

export const SocialMediaNode = ({ data }: any) => {
    const { t } = useTranslation()
    const { state, dispatch } = useChatState()
    const highlight = gotoHighlight(data.node.id, state)
    const highlightChat = chatHighlight(data.node.id, state)
    const classes = useStyles()
    const highlightClasses = useHighlightStyles({ goto: highlight.valid })
    const hlClasses = `${highlight.highlight ? highlightClasses.goto : null} ${highlightChat ? highlightClasses.chat : null}`

    const handleClickHandle = (handleId: string) => {
        dispatch({ type: "SELECT_HANDLE", payload: { node: data.node, handle: { id: handleId } } })
    }
    return (
        <div className={hlClasses}>
            <NodeTooltip data={data}>
                <Grid container alignItems="center" className={classes.defaultNodeWrapper}>
                    <Grid item className={classes.defaultNodeContent}>
                        <div className={classes.defaultNodeHighlight} style={highlight.highlight ? highlight.valid ? {backgroundColor: "rgba(131, 172, 27, 0.7)"} : {background: "linear-gradient(145deg, rgba(244,67,54,0.7) 10%, rgba(244,67,54,0.8) 10%, rgba(244,67,54,0.8) 20%, rgba(244,67,54,0.7) 20%, rgba(244,67,54,0.7) 30%, rgba(244,67,54,0.85) 30%, rgba(244,67,54,0.8) 40%, rgba(244,67,54,0.7) 40%, rgba(244,67,54,0.7) 50%, rgba(244,67,54,0.8) 50%, rgba(244,67,54,0.8) 60%, rgba(244,67,54,0.7) 60%, rgba(244,67,54,0.7) 70%, rgba(244,67,54,0.8) 70%, rgba(244,67,54,0.8) 80%, rgba(244,67,54,0.7) 80%, rgba(244,67,54,0.7) 90%, rgba(244,67,54,0.8) 90%, rgba(244,67,54,0.8) 100%)", color: "#ffffff"} : {backgroundColor: "transparent"}}>
                            <Typography className={classes.nodeLabel}><Fa icon={["fal", "star"]} color={highlight.highlight ? "" : "secondary.main"} fixedWidth mr={1} />{t("Rating-Portal")}</Typography>
                            <Handle
                                id={`N`}
                                type="target"
                                position={Position.Left}
                                style={{ background: "black", }}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Handle
                            id={`S`}
                            type="source"
                            position={Position.Right}
                            style={{ pointerEvents: "none", opacity: 0 }}
                        />
                    </Grid>
                    {!state.goToSelection ?
                    <Grid item>
                        <Handle
                            id={"SCLICK"}
                            type="source"
                            position={Position.Right}
                            className={classes.addHandler}
                            onClick={() => handleClickHandle("S")}
                        >
                            <Fa icon={["fal", "plus"]} className={classes.handleIcon} />
                        </Handle>
                    </Grid>
                    : null}
                </Grid>
            </NodeTooltip>
        </div>
    )
}

export const FeedbackNode = ({ data }: any) => {
    const { t } = useTranslation()
    const { state } = useChatState()
    const highlight = gotoHighlight(data.node.id, state)
    const highlightChat = chatHighlight(data.node.id, state)
    const classes = useStyles()
    const highlightClasses = useHighlightStyles({ goto: highlight.valid })
    const hlClasses = `${highlight.highlight ? highlightClasses.goto : null} ${highlightChat ? highlightClasses.chat : null}`

    return (
        <div className={hlClasses}>
            <NodeTooltip data={data}>
                <Grid container alignItems="center" className={classes.noSourceHandleNodeWrapper}>
                    <Grid item className={classes.defaultNodeContent} style={highlight.highlight ? {backgroundColor: "#ffffff"} : {backgroundColor: "#ffda59"}}>
                        <div className={classes.defaultNodeHighlight} style={highlight.highlight ? highlight.valid ? {backgroundColor: "rgba(131, 172, 27, 0.7)"} : {background: "linear-gradient(145deg, rgba(244,67,54,0.7) 10%, rgba(244,67,54,0.8) 10%, rgba(244,67,54,0.8) 20%, rgba(244,67,54,0.7) 20%, rgba(244,67,54,0.7) 30%, rgba(244,67,54,0.85) 30%, rgba(244,67,54,0.8) 40%, rgba(244,67,54,0.7) 40%, rgba(244,67,54,0.7) 50%, rgba(244,67,54,0.8) 50%, rgba(244,67,54,0.8) 60%, rgba(244,67,54,0.7) 60%, rgba(244,67,54,0.7) 70%, rgba(244,67,54,0.8) 70%, rgba(244,67,54,0.8) 80%, rgba(244,67,54,0.7) 80%, rgba(244,67,54,0.7) 90%, rgba(244,67,54,0.8) 90%, rgba(244,67,54,0.8) 100%)", color: "#ffffff"} : {backgroundColor: "transparent"}}>
                            <Typography className={classes.nodeLabel}><Fa icon={["fal", "stars"]} color={highlight.highlight ? "" : "secondary.main"} fixedWidth mr={1} />{t("Bewertung")}</Typography>
                            <Handle
                                id={`N`}
                                type="target"
                                position={Position.Left}
                                style={{ background: "black", }}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Handle
                            id={`S`}
                            type="source"
                            position={Position.Right}
                            style={{ pointerEvents: "none", opacity: 0 }}
                        >
                            <Fa icon={["fal", "plus"]} className={classes.handleIcon} />
                        </Handle>
                    </Grid>
                </Grid>
            </NodeTooltip>
        </div>
    )
}

export const UserFeedbackNode = ({ data }: any) => {
    const { t } = useTranslation()
    const { state, dispatch } = useChatState()
    const highlight = gotoHighlight(data.node.id, state)
    const highlightChat = chatHighlight(data.node.id, state)
    const classes = useStyles()
    const highlightClasses = useHighlightStyles({ goto: highlight.valid })
    const hlClasses = `${highlight.highlight ? highlightClasses.goto : null} ${highlightChat ? highlightClasses.chat : null}`

    const handleClickHandle = (handleId: string) => {
        dispatch({ type: "SELECT_HANDLE", payload: { node: data.node, handle: { id: handleId } } })
    }

    return (
        <div className={hlClasses}>
            <NodeTooltip data={data}>
                <Grid container alignItems="center" className={classes.defaultNodeWrapper}>
                    <Grid item className={classes.defaultNodeContent} >
                        <div className={classes.defaultNodeHighlight} style={highlight.highlight ? highlight.valid ? {backgroundColor: "rgba(131, 172, 27, 0.7)"} : {background: "linear-gradient(145deg, rgba(244,67,54,0.7) 10%, rgba(244,67,54,0.8) 10%, rgba(244,67,54,0.8) 20%, rgba(244,67,54,0.7) 20%, rgba(244,67,54,0.7) 30%, rgba(244,67,54,0.85) 30%, rgba(244,67,54,0.8) 40%, rgba(244,67,54,0.7) 40%, rgba(244,67,54,0.7) 50%, rgba(244,67,54,0.8) 50%, rgba(244,67,54,0.8) 60%, rgba(244,67,54,0.7) 60%, rgba(244,67,54,0.7) 70%, rgba(244,67,54,0.8) 70%, rgba(244,67,54,0.8) 80%, rgba(244,67,54,0.7) 80%, rgba(244,67,54,0.7) 90%, rgba(244,67,54,0.8) 90%, rgba(244,67,54,0.8) 100%)", color: "#ffffff"} : {backgroundColor: "transparent"}}>
                            <Typography className={classes.nodeLabel}><Fa icon={["fal", "comment-alt-lines"]} color={highlight.highlight ? "" : "secondary.main"} fixedWidth mr={1} />{t("Feedback")}</Typography>
                            <Handle
                                id={`N`}
                                type="target"
                                position={Position.Left}
                                style={{ background: "black", }}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Handle
                            id={"S"}
                            type="source"
                            position={Position.Right}
                            style={{ pointerEvents: "none", opacity: 0 }}
                        />
                    </Grid>
                    {!state.goToSelection ?
                    <Grid item>
                        <Handle
                            id={"SCLICK"}
                            type="source"
                            position={Position.Right}
                            className={classes.addHandler}
                            onClick={() => handleClickHandle("S")}
                        >
                            <Fa icon={["fal", "plus"]} className={classes.handleIcon} />
                        </Handle>
                    </Grid>
                    : null}
                </Grid>
            </NodeTooltip>
        </div>
    )
}

export const StartNode = ({ data }: any) => {
    const { t } = useTranslation()
    const { state, dispatch } = useChatState()
    const highlight = gotoHighlight(data.node.id, state)
    const highlightChat = chatHighlight(data.node.id, state)
    const classes = useStyles()
    const highlightClasses = useHighlightStyles({ goto: highlight.valid })
    const hlClasses = `${highlight.highlight ? highlightClasses.goto : null} ${highlightChat ? highlightClasses.chat : null}`

    const handleClickHandle = (handleId: string) => {
        dispatch({ type: "SELECT_HANDLE", payload: { node: data.node, handle: { id: handleId } } })
    }
    return (
        <div className={hlClasses}>
            <NodeTooltip data={data}>
                <Grid container alignItems="center" className={classes.defaultNodeWrapper}  >
                    <Grid item className={classes.defaultNodeContent} style={highlight.highlight ? {backgroundColor: "#ffffff"} : {backgroundColor: secondaryHue[600]}}>
                        <div className={classes.defaultNodeHighlight} style={highlight.highlight ? highlight.valid ? {backgroundColor: "rgba(131, 172, 27, 0.7)"} : {background: "linear-gradient(145deg, rgba(244,67,54,0.7) 10%, rgba(244,67,54,0.8) 10%, rgba(244,67,54,0.8) 20%, rgba(244,67,54,0.7) 20%, rgba(244,67,54,0.7) 30%, rgba(244,67,54,0.85) 30%, rgba(244,67,54,0.8) 40%, rgba(244,67,54,0.7) 40%, rgba(244,67,54,0.7) 50%, rgba(244,67,54,0.8) 50%, rgba(244,67,54,0.8) 60%, rgba(244,67,54,0.7) 60%, rgba(244,67,54,0.7) 70%, rgba(244,67,54,0.8) 70%, rgba(244,67,54,0.8) 80%, rgba(244,67,54,0.7) 80%, rgba(244,67,54,0.7) 90%, rgba(244,67,54,0.8) 90%, rgba(244,67,54,0.8) 100%)", color: "#ffffff"} : {backgroundColor: "transparent"}}>
                            <Typography className={classes.nodeLabel} style={highlight.highlight ? highlight.valid ? {color: "#000000"} : { color: "#ffffff"} : {color: "#ffffff"} }><Fa icon={["fal", "rocket-launch"]} color={highlight.highlight ? "" : "#ffffff"} fixedWidth mr={1} />{t("Start")}</Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <Handle
                            id={`S`}
                            type="source"
                            position={Position.Right}
                            style={{ pointerEvents: "none", opacity: 0 }}
                        />
                    </Grid>
                    {!state.goToSelection ?
                    <Grid item>
                        <Handle
                            id={"SCLICK"}
                            type="source"
                            position={Position.Right}
                            className={classes.addHandler}
                            onClick={() => handleClickHandle("S")}
                        >
                            <Fa icon={["fal", "plus"]} className={classes.handleIcon} />
                        </Handle>
                    </Grid>
                    : null}
                </Grid>
            </NodeTooltip>
        </div>
    )
}

export const SplitQuestionNode = ({ data }: any) => {
    const { t } = useTranslation()
    const { state } = useChatState()
    const highlight = gotoHighlight(data.node.id, state)
    const highlightChat = chatHighlight(data.node.id, state)
    const classes = useStyles()
    const highlightClasses = useHighlightStyles({ goto: highlight.valid })
    const hlClasses = `${highlight.highlight ? highlightClasses.goto : null} ${highlightChat ? highlightClasses.chat : null}`

    return (
        <div className={hlClasses}>
            <NodeTooltip data={data}>
                <Grid container alignItems="center" className={classes.noSourceHandleNodeWrapper}>
                    <Grid item className={classes.defaultNodeContent} style={highlight.highlight ? {backgroundColor: "#ffffff"} : {backgroundColor: "#e1f3b2"}}>
                        <div className={classes.defaultNodeHighlight} style={highlight.highlight ? highlight.valid ? {backgroundColor: "rgba(131, 172, 27, 0.7)"} : {background: "linear-gradient(145deg, rgba(244,67,54,0.7) 10%, rgba(244,67,54,0.8) 10%, rgba(244,67,54,0.8) 20%, rgba(244,67,54,0.7) 20%, rgba(244,67,54,0.7) 30%, rgba(244,67,54,0.85) 30%, rgba(244,67,54,0.8) 40%, rgba(244,67,54,0.7) 40%, rgba(244,67,54,0.7) 50%, rgba(244,67,54,0.8) 50%, rgba(244,67,54,0.8) 60%, rgba(244,67,54,0.7) 60%, rgba(244,67,54,0.7) 70%, rgba(244,67,54,0.8) 70%, rgba(244,67,54,0.8) 80%, rgba(244,67,54,0.7) 80%, rgba(244,67,54,0.7) 90%, rgba(244,67,54,0.8) 90%, rgba(244,67,54,0.8) 100%)", color: "#ffffff"} : {backgroundColor: "transparent"}}>
                            <Typography className={classes.nodeLabel}><Fa icon={["fal", "random"]} color="primary.main" fixedWidth mr={1} />{t("Aufteilen")}</Typography>
                            <Handle
                                id={`N`}
                                type="target"
                                position={Position.Left}
                                style={{ background: "black", }}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Handle
                            id={`S`}
                            type="source"
                            position={Position.Right}
                            //className={classes.addHandler}
                            style={{ pointerEvents: "none", opacity: 0 }}
                        />
                    </Grid>
                </Grid>
            </NodeTooltip>
        </div>
    )
}

export const SplitAnswerNode = ({ data }: any) => {
    const { t } = useTranslation()
    const { state, dispatch } = useChatState()
    const highlight = gotoHighlight(data.node.id, state)
    const highlightChat = chatHighlight(data.node.id, state)
    const classes = useStyles()
    const highlightClasses = useHighlightStyles({ goto: highlight.valid })
    const hlClasses = `${highlight.highlight ? highlightClasses.goto : null} ${highlightChat ? highlightClasses.chat : null}`

    const handleClickHandle = (handleId: string) => {
        dispatch({ type: "SELECT_HANDLE", payload: { node: data.node, handle: { id: handleId } } })
    }

    return (
        <div className={hlClasses}>
            <NodeTooltip data={data}>
                <Grid container alignItems="center" className={classes.defaultNodeWrapper}>
                    <Grid item className={classes.defaultNodeContent} >
                        <div className={classes.defaultNodeHighlight} style={highlight.highlight ? highlight.valid ? {backgroundColor: "rgba(131, 172, 27, 0.7)"} : {background: "linear-gradient(145deg, rgba(244,67,54,0.7) 10%, rgba(244,67,54,0.8) 10%, rgba(244,67,54,0.8) 20%, rgba(244,67,54,0.7) 20%, rgba(244,67,54,0.7) 30%, rgba(244,67,54,0.85) 30%, rgba(244,67,54,0.8) 40%, rgba(244,67,54,0.7) 40%, rgba(244,67,54,0.7) 50%, rgba(244,67,54,0.8) 50%, rgba(244,67,54,0.8) 60%, rgba(244,67,54,0.7) 60%, rgba(244,67,54,0.7) 70%, rgba(244,67,54,0.8) 70%, rgba(244,67,54,0.8) 80%, rgba(244,67,54,0.7) 80%, rgba(244,67,54,0.7) 90%, rgba(244,67,54,0.8) 90%, rgba(244,67,54,0.8) 100%)", color: "#ffffff"} : {backgroundColor: "transparent"}}>
                            <Typography className={classes.nodeLabel}><Fa icon={["fal", "mouse-pointer"]} color={highlight.highlight ? "" : "secondary.main"} fixedWidth mr={1} />{t("Antwort")}</Typography>
                            <Handle
                                id={`N`}
                                type="target"
                                position={Position.Left}
                                style={{ background: "black", }}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <Handle
                            id={`S`}
                            type="source"
                            position={Position.Right}
                            style={{ pointerEvents: "none", opacity: 0 }}
                        />
                    </Grid>
                    {!state.goToSelection ?
                    <Grid item>
                        <Handle
                            id={"SCLICK"}
                            type="source"
                            position={Position.Right}
                            className={classes.addHandler}
                            onClick={() => handleClickHandle("S")}
                        >
                            <Fa icon={["fal", "plus"]} className={classes.handleIcon} />
                        </Handle>
                    </Grid>
                    : null}
                </Grid>
            </NodeTooltip>
        </div>
    )
}


// give coupon here
export const EndNode = ({ data }: any) => {
    const { t } = useTranslation()
    const { state } = useChatState()
    const highlight = gotoHighlight(data.node.id, state)
    const highlightChat = chatHighlight(data.node.id, state)
    const classes = useStyles()
    const highlightClasses = useHighlightStyles({ goto: highlight.valid })
    const hlClasses = `${highlight.highlight ? highlightClasses.goto : null} ${highlightChat ? highlightClasses.chat : null}`

    return (
        <div className={hlClasses}>
            <NodeTooltip data={data}>
                <Grid container alignItems="center" className={classes.defaultNodeWrapper}>
                    <Grid item className={classes.defaultNodeContent} style={{ backgroundColor: "#ffffff" }}>
                        <div className={classes.defaultNodeHighlight} style={highlight.highlight ? highlight.valid ? {backgroundColor: "rgba(131, 172, 27, 0.7)"} : {background: "linear-gradient(145deg, rgba(244,67,54,0.7) 10%, rgba(244,67,54,0.8) 10%, rgba(244,67,54,0.8) 20%, rgba(244,67,54,0.7) 20%, rgba(244,67,54,0.7) 30%, rgba(244,67,54,0.85) 30%, rgba(244,67,54,0.8) 40%, rgba(244,67,54,0.7) 40%, rgba(244,67,54,0.7) 50%, rgba(244,67,54,0.8) 50%, rgba(244,67,54,0.8) 60%, rgba(244,67,54,0.7) 60%, rgba(244,67,54,0.7) 70%, rgba(244,67,54,0.8) 70%, rgba(244,67,54,0.8) 80%, rgba(244,67,54,0.7) 80%, rgba(244,67,54,0.7) 90%, rgba(244,67,54,0.8) 90%, rgba(244,67,54,0.8) 100%)", color: "#ffffff"} : {backgroundColor: "transparent"}}>
                            <Typography className={classes.nodeLabel}><Fa icon={["fal", "flag-checkered"]} color={highlight.highlight ? "" : "secondary.main"} fixedWidth mr={1} />{t("Ende")}</Typography>
                            <Handle
                                id={`N`}
                                type="target"
                                position={Position.Left}
                                style={{ background: "black", }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </NodeTooltip>
        </div>
    )
}

export const GoToNode = ({ data }: any) => {
    const { t } = useTranslation()
    const { state } = useChatState()
    const highlight = gotoHighlight(data.node.id, state)
    const highlightChat = chatHighlight(data.node.id, state)
    const classes = useStyles()
    const highlightClasses = useHighlightStyles({ goto: highlight.valid })
    const hlClasses = `${highlight.highlight ? highlightClasses.goto : null} ${highlightChat ? highlightClasses.chat : null}`

    return (
        <div className={hlClasses}>
            <NodeTooltip data={data}>
                <Grid container alignItems="center" className={classes.defaultNodeWrapper}>
                    <Grid item className={classes.defaultNodeContent} style={highlight.highlight ? {backgroundColor: "#ffffff"} : {backgroundColor: "orange"}}>
                        <div className={classes.defaultNodeHighlight} style={highlight.highlight ? highlight.valid ? {backgroundColor: "rgba(131, 172, 27, 0.7)"} : {background: "linear-gradient(145deg, rgba(244,67,54,0.7) 10%, rgba(244,67,54,0.8) 10%, rgba(244,67,54,0.8) 20%, rgba(244,67,54,0.7) 20%, rgba(244,67,54,0.7) 30%, rgba(244,67,54,0.85) 30%, rgba(244,67,54,0.8) 40%, rgba(244,67,54,0.7) 40%, rgba(244,67,54,0.7) 50%, rgba(244,67,54,0.8) 50%, rgba(244,67,54,0.8) 60%, rgba(244,67,54,0.7) 60%, rgba(244,67,54,0.7) 70%, rgba(244,67,54,0.8) 70%, rgba(244,67,54,0.8) 80%, rgba(244,67,54,0.7) 80%, rgba(244,67,54,0.7) 90%, rgba(244,67,54,0.8) 90%, rgba(244,67,54,0.8) 100%)", color: "#ffffff"} : {backgroundColor: "transparent"}}>
                            <Typography className={classes.nodeLabel}><Fa icon={["fal", "random"]} color={highlight.highlight ? "" : "primary.main"} fixedWidth mr={1} />{t("Gehe zu")}</Typography>
                            <Handle
                                id={`N`}
                                type="target"
                                position={Position.Left}
                                style={{ background: "black", }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </NodeTooltip>
        </div>
    )
}