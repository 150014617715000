import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from './Login'
import Register from './Register'
import ResetPassword from './ResetPassword'
import Auth from './Auth'
import RegisterSuccessFailure, { IType as IRegisterType } from "./RegisterSuccessFailure"
import ActivateReset, { IType as IPasswordType} from './ActivateReset'

const AuthRouter = () => {
  return (
    <Auth>
      {({ classes }) => (
      <Switch>
        <Route path="/auth/login/pos">
          <Login classes={classes}/>
        </Route>
        <Route path="/auth/login" >
          <Login classes={classes}/>
        </Route>
        <Route path="/auth/activate/:token">
          <ActivateReset classes={classes} type={IPasswordType.Activate}/>
        </Route>
        <Route path="/auth/register/success">
          <RegisterSuccessFailure classes={classes} type={IRegisterType.Success}/>
        </Route>
        <Route path="/auth/register/failure">
          <RegisterSuccessFailure classes={classes} type={IRegisterType.Failure}/>
        </Route>
        <Route path="/auth/register" >
          <Register classes={classes}/>
        </Route>
        <Route path="/auth/reset_password/:token">
          <ActivateReset classes={classes} type={IPasswordType.Reset}/>
        </Route>
        <Route path="/auth/reset_password" >
          <ResetPassword classes={classes}/>
        </Route>
      </Switch>
      )}
    </Auth>
  )
}

export default AuthRouter