import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faUser,
    faMessageBot,
    faImages,
    faArrowRightLong,
    faPlus
 } from '@fortawesome/pro-thin-svg-icons'

library.add(
    faUser,
    faMessageBot,
    faImages,
    faArrowRightLong,
    faPlus
    )
