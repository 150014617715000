import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faCircle,
    faCheck,
    faTimes,
    faKey,
    faFilter,
    faFile,
    faSlidersV,
    faCommentAltDots,
    faUser,
    faStopwatch,
    faReply,
    faShare,
    faArrowRightFromBracket,
    faEllipsisVertical,
    faArrowUpRightFromSquare,
    faPaintRoller,
    faAt,
    faCopy,
    faMagnifyingGlass,
    faArrowUpFromLine,
    faPowerOff,
    faLink,
    faLinkSlash,
    faArrowPointer,
    faRotateLeft,
    faLockKeyhole,
    faPenToSquare,
    faPlus
} from '@fortawesome/pro-regular-svg-icons'

library.add(
    faCircle,
    faCheck,
    faTimes,
    faKey,
    faFilter,
    faFile,
    faSlidersV,
    faCommentAltDots,
    faUser,
    faStopwatch,
    faReply,
    faShare,
    faArrowRightFromBracket,
    faEllipsisVertical,
    faArrowUpRightFromSquare,
    faPaintRoller,
    faAt,
    faCopy,
    faMagnifyingGlass,
    faArrowUpFromLine,
    faPowerOff,
    faLink,
    faLinkSlash,
    faArrowPointer,
    faRotateLeft,
    faLockKeyhole,
    faPenToSquare,
    faPlus
)