import { Grid, makeStyles } from '@material-ui/core'
import { Form } from 'react-final-form'
import { MUIDataTableMeta } from 'mui-datatables'
import React, { useState } from 'react'
import { Info, CrudModel } from '../../models/builder'
import TableInput from './TableInput'
import { Fa } from '../../styled/muiComponents'
import Button from '../../components/Button/Button'
import i18next from 'i18next'

type EditableTableCellProps<T> = {
    modelApi: CrudModel<T>,
    value: string,
    tableMeta: MUIDataTableMeta,
    updateValue: (value:string) => void,
    modelInfo: Info | null,
    children: React.ReactNode
}

const useStyles = makeStyles( theme => ({
    editableTableCellContent: {
        "&:hover $inlineEditButtonWrapper": {
            opacity: "1"
        }
    },
    inlineEditButtonWrapper: {
        position: "absolute",
        right: "0px",
        minWidth: "60px",
        textAlign: "right",
        background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, ${theme.palette.lightGrey.main} 40%)`,
        opacity: "0",
        transition: "0.3s opacity ease"
    },
    actionButtonEdit: {
        minWidth: "10px",
        padding:"3px 5px",
        marginRight: "3px",
        width: "25px",
        height: "22px",
        fontSize: "11px",
        background: "Transparent",
        border: "transparent",
        color: theme.palette.editButton.main
    },
}))

const EditableTableCellContent = <T extends {id: number}>({ modelInfo, modelApi, value, tableMeta, updateValue, children }: EditableTableCellProps<T>) => {
    const classes = useStyles()
    const [ isEditing, setIsEditing ] = useState(false)

    const handleClick = async() => {
        if(!modelInfo) return
        setIsEditing(true)
    }
    
    const handleCancel = () => {
        setIsEditing(false)
    }

    const handleSubmit = async (values: any,
        //  { setErrors }: {setErrors: any}
        )  => {
        try {
            const id = tableMeta.rowData[0]
            //@ts-ignore
            const result: T = await modelApi.update(id, values)
            const field: keyof T = tableMeta.columnData.name as keyof T
            const newValue = String(result[field])
            updateValue(newValue)
            handleCancel()
            return Promise.resolve()
        }catch(e) {
            // TODO refactor to react-final-form errorhandling
            console.warn("error not handled " + e)
            // setErrors(
            //     errorToFormikErrorObject(e)
            // )
        }
    }

    if(isEditing && modelInfo) {
        return (
            <Form onSubmit={handleSubmit} initialValues={{[tableMeta.columnData.name]: value}}
                render={({handleSubmit}) => (
                    <form onSubmit={handleSubmit}>
                        <TableInput schema={modelInfo.schema_info[tableMeta.columnData.name]} onCancel={handleCancel} name={tableMeta.columnData.name} />
                    </form>
                )}
            />
        )
    }

    return (
        <Grid className={classes.editableTableCellContent} container direction="row" justify="space-between" alignItems="center">
            <Grid item>{ children }</Grid>
            <Grid item className={[classes.inlineEditButtonWrapper, "iEBWrapper"].join(" ")}>
                <Button color="primary" variant="action" onClick={handleClick}  disableElevation size="small" className={classes.actionButtonEdit} tooltip={i18next.t("Bearbeiten")} tooltipProps={{placement:'left'}}>
                    <Fa icon={["fas", "pencil"]}/>
                </Button>
            </Grid>
        </Grid>
    )
}

export default EditableTableCellContent