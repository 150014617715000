import React, {useEffect, useState} from "react"
import { SetupNavigationProps, Navigation, ISetupInformation } from "../SetupWizard"
import { Typography, Radio } from "@material-ui/core"
import { Grid, Fa } from 'styled/muiComponents'
import Typist from "react-typist"
import Pixi from "components/Pixi"
import { Form } from 'react-final-form'
import FormikInput from "components/formik/FormikInput"
import { useTranslation } from "react-i18next"
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

type Props = {
    navigationProps: SetupNavigationProps
    classes: any
    handleChange: (event: any) => void
    setupInformation: ISetupInformation
}

const BasicInformationSetup = ({ navigationProps, classes, handleChange, setupInformation }: Props) => {
    const { t } = useTranslation()
    const [enableNext, setEnableNext] = useState<boolean>(false)

    const handleSubmit = () => {
    }

    useEffect(() => {
        if (setupInformation.gender && setupInformation.first_name && setupInformation.last_name) {
            setEnableNext(true)
        } else {
            setEnableNext(false)
        }
    }, [setupInformation])

    const initialValues = {
        gender: setupInformation.gender,
        first_name: setupInformation.first_name,
        last_name: setupInformation.last_name
    }
    const [hasFirstNameFocus, setFirstNameFocus] = useState(false);
    const [hasLastNameFocus, setLastNameFocus] = useState(false);

    return (
        <Form initialValues={initialValues} onSubmit={handleSubmit}
            render={({ handleSubmit, submitting }) => (
                <Grid container item direction="column" className={classes.root}>
                    <Grid container item direction="column" justify="center" alignItems="center" style={{margin: "auto"}}>
                        <form className={classes.form} onSubmit={handleSubmit}>
                            <Grid container direction="column" justify="flex-start">
                                <Grid item container justify="flex-start" alignItems="center" className={classes.formHeader}>
                                    <Grid item sm={4}>
                                        <div style={{ maxWidth: "150px" }}>
                                            <Pixi size="full" pose="waiting"></Pixi>
                                        </div>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <div className={classes.headerDescription} >
                                        <Typist avgTypingDelay={25} cursor={{ show: false }}>
                                            <Typography variant="h5" className={classes.pixiSpeech}>
                                                {t("Hallo, ich bin Pixi dein persönlicher Assistent. Und wie heißt du?")}
                                            </Typography>
                                        </Typist>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item container className={classes.formContent}>
                                    <Grid item sm={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{t("Anrede")} {t("*")}</FormLabel>
                                            <RadioGroup row aria-label="gender" name="gender" onChange={handleChange} style={{marginTop: "8px"}}>
                                                <FormControlLabel value="female" control={<Radio />} label={t("Frau")} checked={setupInformation.gender === "female"}/>
                                                <FormControlLabel value="male" control={<Radio />} label={t("Herr")} checked={setupInformation.gender ==="male"}/>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item spacing={3} mt={1} container>
                                        <Grid item xs={12} sm={6}>
                                            <FormikInput
                                                type="string"
                                                value={setupInformation.first_name}
                                                name="first_name"
                                                label={t("Vorname")}
                                                variant="outlined"
                                                required
                                                onChange={handleChange}
                                                autoComplete="off"
                                                fullwidth
                                                InputProps={
                                                    {
                                                        onFocus:(() => setFirstNameFocus(true)),
                                                        onBlur:(() => setFirstNameFocus(false)),
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                            { hasFirstNameFocus ? <Fa icon={["fas", "user"]}/> : <Fa icon={["fal", "user"]}/>}
                                                            </InputAdornment>
                                                        ),
                                                    }
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormikInput
                                                type="string"
                                                value={setupInformation.last_name}
                                                name="last_name"
                                                label={t("Nachname")}
                                                variant="outlined"
                                                required
                                                onChange={handleChange}
                                                autoComplete="off"
                                                fullwidth
                                                InputProps={
                                                    {
                                                        onFocus:(() => setLastNameFocus(true)),
                                                        onBlur:(() => setLastNameFocus(false)),
                                                        endAdornment: (
                                                            <InputAdornment position="start">
                                                                { hasLastNameFocus ? <Fa icon={["fas", "user"]}/> : <Fa icon={["fal", "user"]}/>}
                                                            </InputAdornment>
                                                        ),
                                                    }
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                                <Grid item sm={12}>
                                    <Navigation  {...navigationProps} enableNext={!enableNext}/>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid item className={classes.formNote}>
                            <Typography variant="body1" className={classes.requiredNotice}>
                                <i>* {t("Pflichtfeld")}</i>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        />
    )
}

export default BasicInformationSetup