import EventNoteIcon from '@material-ui/icons/EventNote';
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react'
import { Grid, Divider } from '@material-ui/core'
import { IRecipientEvent, IRecipientEventParam } from '../../../../types/recipient'
import DateTime from '../../../../components/presentation/DateTime'
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export type props = {
    event: IRecipientEvent
}

const useStyles = makeStyles(theme => ({
    container: {
        padding: "16px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        transition: "0.3s all ease",
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: "white",
            cursor: "pointer",
        }
    },
    collapsed: {
        padding: "8px",
    },
    open:{
        borderBottomRightRadius: "0px",
        borderBottomLeftRadius: "0px"
    },
    closed:{
        borderBottomRightRadius: "8px",
        borderBottomLeftRadius: "8px"
    },
    labelTD: {
        paddingRight: theme.spacing(2)
    },
    wrapper: {
        width: "100%",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px"
    }
}))

const RecipientEvent = ({ event }: props) => {
    const { t } = useTranslation()
    const classes = useStyles();

    const [showMore, setShowMore] = useState(false)

    const handleToggle = () => {
        setShowMore(!showMore)
    }

    return (
        <div className={classes.wrapper}>
            <Grid container justify="space-between" onClick={handleToggle} alignItems="center" className={[classes.container, showMore ? classes.open : classes.closed].join(' ')}>
                <Grid item style={{ display: "flex" }}>
                    <EventNoteIcon style={{ paddingRight: "16px" }} />
                    <b style={{ paddingRight: "8px" }}>{t("Ereignis")}: </b>
                    {t(`/fields:${event.name}`)}
                    <b style={{ paddingRight: "8px", paddingLeft: "8px" }}>{t("Zeitpunkt")}: </b>
                    <DateTime value={event.occurred_at} />
                </Grid>
                <Grid item style={{ display: "flex" }}>
                    {showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Grid>
            </Grid>
            {showMore ? <Divider /> : null}
            <Collapse in={showMore} timeout={"auto"}>
                <div className={classes.collapsed}>
                    <table>
                        {Object.keys(event).map((key) => (
                            //@ts-ignore TODO TYPING OF KEY
                            <tr key={key}><td className={classes.labelTD}><strong>{t(`/fields:${key}`)}</strong></td><td>{event[key as keyof IRecipientEventParam]}
                                    </td>
                                </tr>
                            ))}
                    </table>
                </div>
            </Collapse>
        </div>
    )
}
export default RecipientEvent;