import React, { useState } from "react";
import Button from "../components/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "./DialogTitle";
import DialogActions from "./DialogActions"
import DialogContent from "./DialogContent"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Fa, Typography } from '../styled/muiComponents'
import { makeStyles, Theme, Grid } from "@material-ui/core"

import { useTranslation, Trans } from "react-i18next";
import { CrudModel, BaseModel } from '../models/builder'
import { DialogProps } from '../hooks/useDialog'

export type Response = {
  action: "reload" | null
}

type Item = {
  id: number,
  name?: string,
  domain?: string,
  email?: string
}

export type Props = {
    items: Item[]
    model: Pick<CrudModel<any>, "remove" | "multiRemove"> & BaseModel
}

const useStyles = makeStyles<Theme, {}>(theme => ({
  warningIcon: {
      color: theme.palette.companyRed.main
  }
}))

const ConfirmRemove: React.FC<Props & DialogProps> = ({ onClose, open, onError, items, model }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    onClose({action: null})
  }

  const handleSubmit = async () => {
    setIsDeleting(true);
   
    try {
      if (items.length === 1) {
        await model.remove(items[0].id);
        onClose({action: "reload"});
      } else {
        await model.multiRemove(items.map(item => item.id));
        onClose({action: "reload"});
      }
    } catch (e) {
      //show some error
      onError(e)
    } finally {
      setIsDeleting(false)
    }
  };

  const translatedModelName = t(`/models:${model.name}`, { count: items.length });
  const classes = useStyles({})
  return (
    <Dialog open={open} onClose={handleClose} >
      <DialogTitle onClose={handleClose}>
        <Fa icon={["fal", "exclamation-triangle"]} className={classes.warningIcon} mr={1.75} />{t("Achtung")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h2" mb={2}>{t("{{model}} wirklich löschen?", { model: translatedModelName})}</Typography> 
        {items.length === 1 ? (
          <SingleContent item={items[0]} />
        ) : (
          <MultiContent items={items} modelName={translatedModelName} />
        )}
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          <Button onClick={handleClose} variant="contained" color="error" size="small"><Fa icon={["fas", "times"]} mr={1}  size={"lg"} /> {t("/common:Abbrechen")}</Button>
          <Button isLoading={isDeleting} onClick={handleSubmit} variant="contained" color="success" size="small">
              <Fa icon={["fas", "check"]} mr={1} /> {t("/common:Löschen")}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

//TODO name || email || domain || ... should be replaced by some model key "presentationKey" oder so
const SingleContent: React.FC<{item: Item}> = ({ item }) => (
    <Trans>
      Das Löschen von <strong>{{ name: item.name || item.email || item.domain }}</strong> kann nicht
      rückgängig gemacht werden!
    </Trans>
);

const MultiContent: React.FC<{items: Item[], modelName: string}> = ({ items, modelName }) => {
  const { t } = useTranslation()
  return (
    <Grid container>
        <Typography style={{width: "100%"}}>
          {t("Die folgenden {{modelName}} werden unwiderruflich gelöscht.", { modelName })}
        </Typography>
        <List dense>
            {items.map(item => (
              <ListItem key={item.id} style={{padding: 0, paddingLeft: "8px"}}><Fa icon={["fal", "times"]} mr={1.5} style={{color: "#f44336"}}/><ListItemText>{item.name || item.email || item.domain}</ListItemText></ListItem>
            ))}
        </List>
        <Typography style={{width: "100%"}}>
          {t("Dieser Vorgang kann nicht rückgängig gemacht werden!")}
        </Typography>
    </Grid>
  )
}

export default ConfirmRemove