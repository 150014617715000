import React, { useState } from "react"
import { makeStyles } from "@material-ui/core"
import Button from "components/Button/Button"
import { Fa, Box, Grid } from "styled/muiComponents"
import { useTranslation } from "react-i18next"
import UserInformation from "./steps/UserInformation"
import DomainName from "./steps/DomainName"
import SetupExtras from "./steps/SetupExtras"
import SetupDone from "./steps/SetupDone"
import ConfirmSetup from "./steps/ConfirmSetup"
import CompanyRegistry from "./steps/CompanyRegistry"
import CompanyName from "./steps/CompanyName"
import CompanyLegalForm from "./steps/CompanyLegalForm"
import CompanyJobTitle from "./steps/CompanyJobTitle"
import CompanyContact from "./steps/CompanyContact"
import CompanyAddress from "./steps/CompanyAddress"
import SetupExplanation from "./steps/SetupExplanation"
import VatAndUstid from "./steps/VatAndUstid"
import user from "models/user"
import imprints from "models/imprint"
import domain from "models/domain"
import { useAlert } from "hooks/useAlert"
import senderAddresses from "models/senderAddresses"
import useSession from "hooks/useSession"

export type ISetupInformation = {
    gender: string,
    first_name: string,
    last_name: string,
    city: string,
    country: string,
    imprint_mail: string,
    phone_number: string,
    postal_code: string,
    street_number: string,
    vat_identifier: string,
    ust_identifier: string
    street_name: string,
    company_name: string,
    legal_form: string,
    legal_form_offlist: string
    company_board: string,
    supervisory_board: string,
    company_register: string,
    company_register_offlist?: string,
    registry_court: string,
    registry_number: string,
    supervisory_authority: string,
    responsible_chamber: string,
    job_title_plus_country_awarded: string, // split below
    job_title?: string,
    country_awarded?: string,
    professional_regulations: string,
    additional_information: string,
    domain: string
}

const useStyles = makeStyles(theme => ({
    navigation: {
        backgroundColor: "transparent",
        color: "transparent",
        padding: `0px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
        justifyContent: "space-between"
    },
    root: {
        height: "100%",
        width: "100%",
        backgroundColor: "#1a1e2d",
        backgroundImage: "url(assets/images/dark-bg.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        overflow: "auto",
        padding: theme.spacing(1),
        boxSizing: "border-box",
        "& p": {
            fontWeight: "300",
            "& strong": {
                fontWeight: "500"
            }
        }
    },
    form: {
        width: "100%",
        margin: "auto",
        maxWidth: "600px",
        // padding: theme.spacing(4),
        backgroundColor: theme.palette.lightGrey.main,
        borderRadius: theme.spacing(0.5),
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        boxSizing: "border-box",
        overflow: "hidden"
    },
    formHeader: {
        padding: theme.spacing(4),
        //backgroundColor: "#83ac1b2b",
        backgroundColor: "#E6ECEF",
    },
    formContent: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    formFooter: {
     //   paddingLeft: theme.spacing(4),
     //   paddingRight: theme.spacing(4),
    },
    formNote: {
        width: "100%",
        maxWidth: "600px",
        color: "#ffffff75",
        paddingTop: theme.spacing(2)
    },
    headerDescription: {
        padding: theme.spacing(2),
        fontSize: "0.8rem",
        //backgroundColor: "#00000015",
        backgroundColor: "#ffffff90",
        borderRadius: "5px",
        position: "relative",
        '&:after': {
            content: "''",
            position: "absolute",
            left: 0,
            top: "50%",
            width: 0,
            height: 0,
            border: "18px solid transparent",
            //borderRightColor: "#00000015",
            borderRightColor: "#ffffff90",
            borderLeft: 0,
            borderBottom: 0,
            marginTop: "-9px",
            marginLeft: "-18px",
        },
    },
    success: {
        color: theme.palette.success.main
    },
    error: {
        color: theme.palette.error.main
    },
    pixiSpeech: {
     //   color: "white"
    },
    requiredNotice: {
     //   color: "#95a0a6",
        fontSize: "0.9em"
    },
    disabledInput: {
        "& .Mui-disabled fieldset": {
     //       borderColor: "rgba(255, 255, 255, 0.25) !important"
        }
    }
}))

const SetupWizard = () => {
    const { alert } = useAlert()
    const { t } = useTranslation()
    const {realm} = useSession()

    const [setupInformation, setSetupInformation] = useState({
        gender: realm?.address.gender ?? "",
        first_name: realm?.address.first_name ?? "",
        last_name: realm?.address.last_name ?? "",
        city: realm?.address.city ?? "",
        country: realm?.address.country ?? "",
        imprint_mail: "",
        phone_number: "",
        postal_code: realm?.address.postal_code ?? "",
        street_number: realm?.address.street_number ?? "",
        vat_identifier: "",
        ust_identifier: "",
        street_name: realm?.address.street_name ?? "",
        company_name: realm?.address.company_name ?? "",
        legal_form: "",
        legal_form_offlist: "",              // handles "Andere" case in legal_form
        company_board: "",                   // saves Geschäftsführer or Vostand depending on legal form
        supervisory_board: "",               // saves Vetretungsberechtiger or Aufsichtsrat depending on legal form
        company_register: "",
        company_register_offlist: "",        // is saved in same servervariable as company_register. handles "andere" case
        registry_court: "",
        registry_number: "",
        supervisory_authority: "",
        responsible_chamber: "",
        job_title_plus_country_awarded: "",     // split below for easy handling in form
        job_title: "",
        country_awarded: "",
        professional_regulations: "",
        additional_information: "",
        domain: realm?.address.company_name ?? ""
    })
    const [step, setStep] = useState<number>(0)
    const classes = useStyles()
    const [noInfo, setNoInfo] = useState({ register: true, job: false, taxes: true })

    const onNext = (enableNext: boolean | undefined) => {
        if (step === 11) {
            handleActivateRealmAndImprint();
            handleCreateSubdomain()
            handleCreateSenderAddress()
            //handlecomplet() here?
        }
        setStep(step + 1)
    }

    const onPrevious = () => {
        if (step === 5 && (setupInformation.legal_form === "Eingetragener Kaufmann (e.K.)" || setupInformation.legal_form === "")) {
            setStep(step - 2)
        } else {
            setStep(step - 1)
        }
    }

    const handleChangeNoInfo = (name: string) => {
        switch (name) {
            case "register":
                setNoInfo({ ...noInfo, register: !noInfo["register"] })
                setSetupInformation({ ...setupInformation, registry_court: "", company_register: "", registry_number: "", company_register_offlist: "" })
                break
            case "job":
                setSetupInformation({ ...setupInformation, supervisory_authority: "", supervisory_board: "", job_title_plus_country_awarded: "", responsible_chamber: "", professional_regulations: "" })
                setNoInfo({ ...noInfo, job: !noInfo["job"] })
                break
            case "taxes":
                setSetupInformation({ ...setupInformation, ust_identifier: "" })
                setNoInfo({ ...noInfo, taxes: !noInfo["taxes"] })
                break
            default: console.log(name)
        }
    }

    const onSkip = () => {
        setStep(step + 1)
    }

    const handleChange = (event: any) => {
        setSetupInformation({ ...setupInformation, [event.target.name]: event.target.value })
    }

    const handleActivateRealmAndImprint = async () => {
        let imprintError = ""
        let activateError = ""

        let stateHelper = ({ ...setupInformation })
        if (setupInformation.company_register === "Andere") {
            stateHelper = ({ ...stateHelper, company_register: stateHelper.company_register_offlist })
        }
        if (setupInformation.legal_form === "Andere") {
            stateHelper = ({ ...stateHelper, legal_form: stateHelper.legal_form_offlist })
        }
        const address = {
            gender: stateHelper.gender,
            first_name: stateHelper.first_name,
            last_name: stateHelper.last_name,
            company_name: stateHelper.company_name,
            street_name: stateHelper.street_name,
            street_number: stateHelper.street_number,
            postal_code: stateHelper.postal_code,
            city: stateHelper.city,
            country: stateHelper.country
        }
        imprints.create(stateHelper)
            .then()
            .catch(() => imprintError = t("Impressum konnte nicht gespeichert werden"))

        user.activateRealm(address)
            .then()
            .catch(() => activateError = t("Account Einstellungen konnten nicht gespeichert werden"))

        return [imprintError, activateError]
    }

    const handleCreateSubdomain = async () => {
        let domainError
        await domain.create({ domain: setupInformation.domain })
            .then()
            .catch(() => domainError = t("Domain konnte nicht erstellt werden"))

        return [domainError]
    }

    const handleCreateSenderAddress = async () => {
        let senderAddressesError
        await senderAddresses.new_domain(setupInformation.domain)
            .then()
            .catch(() => senderAddressesError = t("Sender Adresse konnte nicht erstellt werden"))

        return [senderAddressesError]
    }

    const handleComplete = async () => {
        const errors: any = []
        errors.concat(handleCreateSubdomain())
        errors.concat(handleActivateRealmAndImprint())
        errors.concat(handleCreateSenderAddress())

        if (errors) {
            alert.error(errors.map((error: string) => { return <><p>{error}</p><br></br></> }))
        } else {
            alert.success(t("Setup erfolgreich abgeschlossen"))
        }
    }

    const renderStep = () => {
        switch (step) {
            case 0: return <UserInformation
                navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext, onSkip: onSkip }}
                classes={classes}
                handleChange={handleChange}
                setupInformation={setupInformation}
            />

            case 1: return <DomainName
                navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext, onSkip: onSkip }}
                classes={classes}
                handleChange={handleChange}
                setupInformation={setupInformation}
            />

            case 2: return <SetupExplanation
                navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext, onSkip: onSkip }}
                classes={classes}
                setupInformation={setupInformation}
            />

            case 3: return <CompanyName
                navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext, onSkip: onSkip }}
                classes={classes}
                setupInformation={setupInformation}
                handleChange={handleChange}
            />

            case 4: return <CompanyLegalForm
                navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext, onSkip: onSkip }}
                classes={classes}
                setupInformation={setupInformation}
                handleChange={handleChange}
            />

            case 5: return <CompanyAddress
                navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext, onSkip: onSkip }}
                classes={classes}
                setupInformation={setupInformation}
                handleChange={handleChange}
            />

            case 6: return <CompanyContact
                navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext, onSkip: onSkip }}
                classes={classes}
                setupInformation={setupInformation}
                handleChange={handleChange}
            />

            case 7: return <CompanyRegistry
                navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext, onSkip: onSkip }}
                classes={classes}
                setupInformation={setupInformation}
                handleChange={handleChange}
                noInfo={noInfo["register"]}
                handleChangeNoInfo={handleChangeNoInfo}
            />

            case 8: return <VatAndUstid
                navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext, onSkip: onSkip }}
                classes={classes}
                setupInformation={setupInformation}
                handleChange={handleChange}
                handleChangeNoInfo={handleChangeNoInfo}
                noInfo={noInfo["taxes"]}
            />

            case 9: return <CompanyJobTitle
                navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext, onSkip: onSkip }}
                classes={classes}
                setupInformation={setupInformation}
                handleChange={handleChange}
                noInfo={noInfo["job"]}
                handleChangeNoInfo={handleChangeNoInfo}
            />

            case 10: return <SetupExtras
                navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext, onSkip: onSkip }}
                classes={classes}
                setupInformation={setupInformation}
                handleChange={handleChange}
            />

            case 11: return <ConfirmSetup
                navigationProps={{ step: step, onPrevious: onPrevious, onNext: onNext, onSkip: onSkip }}
                classes={classes}
                setupInformation={setupInformation}
                handleComplete={handleComplete}
            />

            case 12: return <SetupDone
                classes={classes}
            />
        }
    }

    return (
        <Box style={{ height: "100%" }}>
            {renderStep()}
        </Box>
    )
}

export type SetupNavigationProps = {
    step: number
    onNext: (enableNext: boolean | undefined) => void
    onPrevious: () => void
    enableNext?: boolean
    handleComplete?: () => void
    onSkip: () => void
    skipEnabled?: boolean
}

export const Navigation = ({ step, onNext, onPrevious, onSkip, enableNext, skipEnabled }: SetupNavigationProps) => {
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.navigation}>
            <Grid item spacing={3} sm={3}>

                {step !== 0 ?
                    <Button onClick={onPrevious} variant="contained" disableElevation color="success" size="large" >
                        <Fa icon={["fas", "chevron-left"]} mr={1}></Fa>
                        {t("Zurück")}
                    </Button>
                    : null}
            </Grid>
            <Grid item spacing={3} sm={9} style={{ textAlign: "right" }}>
                {(step === 7 || step === 8 || step === 9) && skipEnabled ?
                    <Box mr={2} style={{ display: "inline-block" }}>
                        <Button onClick={onSkip} variant="contained" disableElevation color="success" size="large" >
                            {t("Später ausfüllen")}
                            <Fa icon={["fas", "chevron-double-right"]} ml={1}></Fa>
                        </Button>
                    </Box>
                    : null}
                <Button onClick={() => onNext(enableNext)} variant="contained" disableElevation color="success" size="large" disabled={enableNext}>
                    {step === 11 ? t("Fertig stellen") : t("Weiter")}
                    {step === 11 ? <Fa icon={["fal", "flag-checkered"]} ml={1}></Fa> : <Fa icon={["fas", "chevron-right"]} ml={1}></Fa>}
                </Button>
            </Grid>
        </Grid>
    )
}

export default SetupWizard