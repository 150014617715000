import { Edge, getConnectedEdges, Node, Elements, getOutgoers, getIncomers, isEdge, isNode } from 'react-flow-renderer'
import { convertTextfields, countNodeType } from './chatFunctions'
import { spread } from './spreading'


const reducer = (state: any, { payload, type }: any) => {

    const keepNodeCount = () => {
        let nodeCount: any = {}
        const allNodes = state.elements.filter((ele: Node | Edge) => isNode(ele))
        if (state.nodeCounts) {
            nodeCount = JSON.parse(JSON.stringify(state.nodeCounts))
        }
        allNodes.forEach((node: any) => {
            if (!nodeCount[node.type]) {
                nodeCount[node.type] = [node.id]
            } else {
                if (!nodeCount[node.type].includes(node.id)) {
                    nodeCount[node.type].push(node.id)
                }
            }
        })
        return nodeCount
    }

    const findElement = (id: string) => {
        return state.elements.find((element: Node | Edge) => element.id === id)
    }

    const removeEdgeFromElements = (edge: any) => {
        if (!Array.isArray(edge)) {
            edge = [edge]
        }
        return state.elements.filter((ele: Edge | Node) => {
            return edge.every((e: any) => {
                return e.id === ele.id
            })
        })
        //   { ; return ele.id !== edge.id })
    }

    const removeNodeFromElements = (node: any, deleteConnectedEdges: boolean = true) => {
        if (!Array.isArray(node)) {
            node = [node]
        }

        if (deleteConnectedEdges) {
            const removeConnectedEdges = (elements: any): Elements => {

                const connectedEdges = getConnectedEdges(node, elements)
                if (!connectedEdges[0]) {
                    return elements
                }
                const withoutEdge = elements.filter((ele: Edge | Node) => { return ele.id !== connectedEdges[0].id })
                return removeConnectedEdges(withoutEdge)
            }

            return removeConnectedEdges(state.elements)
                .filter((ele: Edge | Node) => {
                    return node.every((n: any) => {
                        return n.id !== ele.id
                    })
                })

        } else {

            return state.elements
                .filter((ele: Edge | Node) => {
                    return node.every((n: any) => {
                        return n.id !== ele.id
                    })
                })
        }
    }

    const unselectAll = (state: any) => {
        // ! object reference fickt mich hier vielleicht? sollte aber auch wenn nicht geändert werden
        const updatedState = {...state}
        updatedState.selected = null
        updatedState.selectedHandle = null
        updatedState.generalSettingsOpen = false
        updatedState.drawingEdge = null
        updatedState.goToSelection = false
        updatedState.testChatActive = false
        updatedState.currentChatSimNode = null
        updatedState.nodeMenuOpen = false
        updatedState.chatSettingsMenuOpen = false
        updatedState.elements = convertTextfields(state.elements, "fromRaw")
        return updatedState
    }

    const undo = (state: any) => {
        let { past, future, ...present } = state

        //  fix for saved chats
        if (!future) {
            future = []
        }
        if (!past) {
            past = []
        }

        if (past.length === 0) {
            return state
        }

        const updatedPresent = past[past.length - 1].past
        const updatedPast = past.length === 1 ? [] : past.slice(0, past.length - 1)
        const updatedFuture = [...future, { future: present, type: past[past.length - 1].type }]
        return { ...unselectAll(updatedPresent), "past": updatedPast, "future": updatedFuture }
    }

    const redo = (state: any) => {
        let { past, future, ...present } = state
        //  fix for saved chats
        if (!past) {
            past = []
        }

        if (future.length === 0) {
            return state
        }

        const updatedPresent = future[future.length - 1].future
        const updatedFuture = future.length === 1 ? [] : future.slice(0, future.length - 1)
        const updatedPast = [...past, { past: present, type: future[future.length - 1].type }]
        return { ...unselectAll(updatedPresent), "past": updatedPast, "future": updatedFuture }
    }

    const newPast = (state: any, changeType: string) => {
        let { past, future, ...present } = state
        //  fix for old saved chats can probably delete later
        if (!past) {
            past = []
        }

        if (past.length > 9) {
            past = past.slice(1)
        }
        const updatedPast = [...past, { past: unselectAll(present), type: changeType }]
        return updatedPast
    }

    const createEdge = (targetId: string, sourceId: string | null = null, hidden: boolean = false) => {

        return {
            id: `e${sourceId ?? state.selectedHandle?.node.id ?? state.selected.id}S-${targetId}N`,
            source: `${sourceId ?? state.selectedHandle?.node.id ?? state.selected.id}`,
            target: `${targetId}`,
            targetHandle: `N`,
            sourceHandle: "S",
            animated: true,
            style: { stroke: "#ffffff" },
            hidden
        }
    }

    const createNode = (payload: any) => {

        const name = (node: any, type: string) => {
            if (node?.data?.name && node?.data?.name !== "") {
                return node.data.name
            } else if (payload.name){
                return payload.name
            }
             else {
                return `${type}${countNodeType(type, state.nodeCounts)}`
            }
        }

        const newID = Date.now()
        const nodeBasics: Node = {
            id: `${newID}`,
            position: { x: 0, y: 0 }, // Type Node needs position, but positions are handled by another function
            data: {
                delay: 2,
                node: { id: `${newID}`, type: payload.type },
                ...payload.data
            },
            type: payload.type,
        }

        switch (payload.type) {
            case "botResponse": {
                const newNode = { ...nodeBasics }
                newNode.data = { ...nodeBasics.data, responses: [], name: name(payload, "botResponse") }
                return [{ ...newNode }, { ...createEdge(newNode.id) }]
            }
            case "splitAnswer": {
                const newNode = { ...nodeBasics }
                newNode.id = payload.id
                newNode.data = {
                    ...nodeBasics.data,
                    answer: payload.data.answer,
                    node: { id: payload.id, type: payload.type },
                    name: name(payload, "splitAnswer")
                }
                return [{ ...newNode }, { ...createEdge(newNode.id) }]
            }
            case "splitQuestion": {
                const newNode = { ...nodeBasics }
                newNode.data = { ...nodeBasics.data, question: "", answers: [{ answer: "", answerKey: `${Date.now() + 1}` }], name: name(payload, "splitQuestion") }
                return [{ ...newNode }, { ...createEdge(newNode.id) }]
            }
            case "socialMedia": {
                const newNode = { ...nodeBasics }
                newNode.data = { ...nodeBasics.data, text:"", socialMedias: [], name: name(payload, "socialMedia") }
                return [{ ...newNode }, { ...createEdge(newNode.id) }]
            }
            case "feedback": {
                const newNode = { ...nodeBasics }
                newNode.data = { ...nodeBasics.data, delay: 2, text:"", split: 4, name: name(payload, "feedback") }

                const newNodePortals = { ...nodeBasics }
                newNodePortals.type = "socialMedia"
                newNodePortals.id = newID + "P"
                newNodePortals.position = {
                    x: payload.sourceNode.position.x + 400,
                    y: payload.sourceNode.position.y + 100
                }
                newNodePortals.data = { ...nodeBasics.data, textTop:"", textBottom:"", socialMedias: [], node: { id: newID + "P", type: "socialMedia" }, name: name(payload, "socialMedia") }

                const newNodeUserFeedback = { ...nodeBasics }
                newNodeUserFeedback.type = "userFeedback"
                newNodeUserFeedback.data = {
                    ...nodeBasics.data,
                    node: { id: newID + "U", type: "userFeedback" },
                    name: name(payload, "userFeedback"),
                    text: ""
                }
                newNodeUserFeedback.position = {
                    x: payload.sourceNode.position.x + 400,
                    y: payload.sourceNode.position.y - 100
                }
                newNodeUserFeedback.id = newID + "U"
                return [
                    { ...newNode },
                    { ...createEdge(`${newNode.id}`) },
                    { ...newNodePortals },
                    { ...createEdge(newNodePortals.id, newNode.id) },
                    { ...newNodeUserFeedback },
                    { ...createEdge(newNodeUserFeedback.id, newNode.id) }
                ]
            }
            // case "start": {
            //     const newNode = { ...nodeBasics }
            //     newNode.data = { ...nodeBasics.data, text: "", buttonTexts: ["", ""], name: name(payload, "start") }
            //     return [{ ...newNode }, { ...createEdge(newNode.id) }]
            // }
            case "end": {
                const newNode = { ...nodeBasics }
                newNode.data = { ...nodeBasics.data, name: name(payload, "end"), text: "" }
                return [{ ...newNode }, { ...createEdge(newNode.id) }]
            }
            case "dataCollection": {
                const newNode = { ...nodeBasics }
                newNode.data = {
                    ...nodeBasics.data,
                    questions: [{ delay: 2, field: "", question: "", validateWith: "" }],
                    name: name(payload, "dataCollection")
                }
                return [{ ...newNode }, { ...createEdge(newNode.id) }]
            }
            case "userFeedback": {
                const newNode = { ...nodeBasics }
                newNode.data = { ...nodeBasics.data, name: name(payload, "userFeedback"), text: "" }
                return [{ ...newNode }, { ...createEdge(newNode.id) }]
            }
            case "goTo": {
                const newNode = { ...nodeBasics }
                newNode.data = { ...nodeBasics.data, continueWith: null, name: name(payload, "goTo") }
                return [{ ...newNode }, { ...createEdge(newNode.id) }]
            }
            default: {
                console.warn("node type not found", payload.type)
                return "default"
            }
        }
    }

    const deleteNodeAndChildren: any = (elements: any, toDelete: any, stack: any = []) => {
        // is toDelete Array? if not make it one
        if (toDelete === undefined || toDelete === null) {
            return elements
        }

        if (Object.prototype.toString.call(toDelete) !== '[object Array]') {
            toDelete = [toDelete]
        }

        // escape condition
        if (stack.length === 0 && toDelete.length === 0 && toDelete !== undefined) {
            return elements
        }

        // determine stack and node to delete next
        if (toDelete.length > 1) {
            stack = toDelete.slice(1)
            toDelete = toDelete[0]
        } else if (toDelete.length === 1) {
            toDelete = toDelete[0]
        } else {
            toDelete = stack[0]
            stack = stack.slice(1)
        }

        // children of toDelete
        const children = getOutgoers(toDelete, elements)

        // deletion of toDelete
        // delete node
        elements = elements.filter((ele: Node | Edge) => {
            // const incommingEdgeReg = new RegExp("e[0-9]*S-" + toDelete.id + "N")
            // const outgoingEdgeReg = new RegExp("e" + toDelete.id + "S-[0-9]*N")
            if (isNode(ele)) {
                return ele.id !== toDelete.id
            } else {
                return true
            }
        })

        // delete edge
        elements = elements.filter((ele: Node | Edge) => {
            const incommingEdgeReg = new RegExp("e[0-9]*S-" + toDelete.id + "N")
            // const outgoingEdgeReg = new RegExp("e" + toDelete.id + "S-[0-9]*N")
            if (isEdge(ele)) {
                return !incommingEdgeReg.test(ele.id)
            } else {
                return true
            }
        })


        // determine next toDelete & stack
        if (children.length > 1) {
            if (stack.length === 0) {
                toDelete = children[0]
                stack = children.slice(1)
            } else {
                toDelete = stack[0]
                stack = stack.slice(1).concat(children)
            }
        } else if (children.length === 1) {
            toDelete = children
        } else {
            toDelete = stack[0]
            stack = stack.slice(1)
        }

        return deleteNodeAndChildren(elements, toDelete, stack)
    }

    const removeDuplicateEdges = (elements: any) => {
        elements = elements.filter((ele: Edge | Node, index: number, self: any) =>
            index === self.findIndex((t: any) => (t.id === ele.id)))

        return elements
    }

    switch (type) {
        // ! remove later, just for testing functions
        case "TEST": {
            return { ...state, "elements": spread(state.elements).concat() }
        }

        case "CURRENT_STATE": {
            return state
        }

        case "INITIAL_RENDER_NEW":{
            return { ...payload}
        }

        case "INITIAL_RENDER_SAVED": {
            payload.elements = convertTextfields(payload.elements, "fromRaw")
            return { ...payload }
        }

        case "SELECT": {
            return { ...state, "selected": payload, "selectedHandle": null, nodeMenuOpen:false }
        }

        case "ADD_NODE": {
            const newNode = createNode(payload)
            return {
                ...state,
                "elements": spread(state.elements.concat(newNode)),
                "selectedHandle": null,
                selected: newNode[0],
                "past": newPast(state, type),
                "future": [],
                nodeCounts: keepNodeCount()
            }
        }

        case "ADD_NODE_BETWEEN": {
            // stack/queue attempt with getOutgoing
            const newNode = createNode(payload.newNode)
            const from = findElement(payload.addedTo.node.id)
            // @ts-ignore
            const newEdges = createEdge(getOutgoers(from, state.elements)[0].id, newNode.sort()[0].id)
            const stack: any = []
            const eles = state.elements.filter((ele: Node | Edge) => ele.id !== `e${from.id}S-${getOutgoers(from, state.elements)[0].id}N`).concat(newNode).concat(newEdges)
            // @ts-ignore
            const afterNewNode = getOutgoers(newNode[0], eles)[0]

            // move to chatfunctions? dont think i need var "eles" when i pass elements
            const spreadHorizontal: any = (current: any, elements: any) => {
                const outs = getOutgoers(current, elements)

                if (outs.length > 1) {
                    stack.push(outs.slice(1))
                }
                // change position of current node in eles
                const index = eles.findIndex((el: any) => el.id === current.id)
                eles[index] = { ...eles[index], position: { ...eles[index].position, x: eles[index].position.x + 200 } }

                if (outs.length === 0) {
                    if (stack.length === 0) {
                        return
                    } else {
                        return spreadHorizontal(stack.pop()[0], eles)
                    }
                }
                return spreadHorizontal(outs[0], eles)
            }

            /*
            newNode is actually edge from source to newNode AND the new node
            node should always be on [0] this can actually fuck this if node is not on index 0.
            */
            spreadHorizontal(afterNewNode, eles)
            return {
                ...state,
                "elements": eles.concat(),
                "selectedHandle": null,
                "past": newPast(state, type),
                "future": [],
                nodeCounts: keepNodeCount()
            }
        }

        case "EDIT_NODE_SETTINGS": {
            if(payload.newNode.type === "goTo"){
                // old and new node always same on goto?

                const child = getOutgoers(payload.newNode, state.elements)
                const newElements = state.elements

                if (child.length > 0) {
                    newElements.deleteEdge(newElements, child[0].id, payload.newNode.id)
                }

                return {
                    ...state,
                    "elements": payload.newNode.data.continueWith 
                        ?
                        newElements.concat(createEdge(payload.newNode.data.continueWith.id, payload.newNode.id, true))
                        :
                        state.elements,
                    "selected": null,
                    "past": newPast(state, type),
                    "future": [],
                    nodeCounts: keepNodeCount()
                }
            }

            if (payload.newNode.type === "splitQuestion") {
                const answerDiff = payload.oldNode.data.answers.filter((x: any) => {
                    return !payload.newNode.data.answers.some((y: any) => {
                        return x.answerKey === y.answerKey
                    })
                })

                const nodesToRemove = state.elements.filter((ele: any) => {
                    return answerDiff.some((diff: any) => {
                        return diff.answerKey === ele?.id
                    })
                })

                let newNodeCount = 0
                const answers = payload.newNode.data.answers.map((answer: any, index: number) => {
                    const answerNode = state.elements.filter((ele: Node | Edge) =>
                        ele.id === answer.answerKey
                    )

                    if (!state.nodeCounts || !state.nodeCounts[`splitAnswer`]) {
                        newNodeCount = index
                    } else if (
                        !state.nodeCounts[`splitAnswer`].includes(answer.answerKey) &&
                        payload.oldNode.data.answers.length < index
                    ) {
                        newNodeCount += 1
                    }

                    const info = {
                        type: "splitAnswer",
                        data: {
                            answer: answer.answer,
                            name: answerNode[0]?.data?.name ?? `splitAnswer${countNodeType("splitAnswer", state.nodeCounts) + newNodeCount}`
                        },
                        position: { x: 0, y: 0 },
                        id: answer.answerKey
                    }
                    return createNode(info)
                })

                return {
                    ...state,
                    "elements": spread(
                        removeDuplicateEdges(
                            deleteNodeAndChildren(
                                removeNodeFromElements([payload.oldNode].concat(
                                    getOutgoers(payload.oldNode, state.elements)), false)
                                    .concat(payload.newNode)
                                    .concat(answers.flat()), nodesToRemove))),
                    "selected": null,
                    "past": newPast(state, type),
                    "future": [],
                    nodeCounts: keepNodeCount()
                }
            }
            if (payload.newNode.type === "splitAnswer") {
                const questionNode = getIncomers(payload.oldNode, state.elements)[0]
                const answers = questionNode.data.answers.map((answer: any) => {
                    if (answer.answerKey === payload.newNode.id) {
                        return { answer: payload.newNode.data.answer, answerKey: payload.newNode.id }
                    } else {
                        return answer
                    }
                })

                const itemsToRemove = [questionNode, payload.oldNode]

                const newQuestionNode = { ...questionNode, data: { ...questionNode.data, answers } }

                return {
                    ...state,
                    "elements": spread(removeNodeFromElements(itemsToRemove, false)
                        .concat(newQuestionNode).concat(payload.newNode)),
                    "selected": null,
                    "past": newPast(state, type),
                    "future": [],
                    nodeCounts: keepNodeCount()
                }
            }
            return {
                ...state,
                "elements": spread(removeNodeFromElements(payload.oldNode, false)
                    .concat(payload.newNode)),
                "selected": null,
                "past": newPast(state, type),
                "future": [],
                nodeCounts: keepNodeCount()
            }
        }

        // ? USED?
        case "UPDATE_NODE": {
            return {
                ...state,
                "elements": spread(removeNodeFromElements(payload, false)
                    .concat(payload)),
                "past": newPast(state, type),
                "future": [],
                nodeCounts: keepNodeCount()
            }
        }

        case "DELETE_NODE": {
            let toDelete = payload.node

            if(payload.node.type === "goTo"){
                const newElements = removeNodeFromElements(toDelete, true)
                
                return {
                    ...state,
                    "elements": spread(newElements),
                    "selected":null,
                    "past": newPast(state, type),
                    "future": []
                }
            }

            if (payload.node.type === "socialMedia" || payload.node.type === "userFeedback") {
                toDelete = getIncomers(payload.node, state.elements)[0]
            }
            return {
                ...state,
                "elements": spread(deleteNodeAndChildren(state.elements, toDelete)),
                "selected": null,
                "past": newPast(state, type),
                "future": []
            }
        }
        // ! used?
        case "ADD_EDGE": {
            // TODO ADD STYLING?
            // TODO ADD DIFFERENT EDGES??
            const newEdge = {
                id: `e${payload.source}${payload.sourceHandle}-${payload.target}${payload.targetHandle}`,
                source: payload.source,
                target: payload.target,
                sourceHandle: payload.sourceHandle,
                targetHandle: payload.targetHandle
            }
            return { ...state, "elements": state.elements.concat(newEdge) }
        }
        // ! used?
        case "EDIT_EDGE": {
            return state
            // COMMING SOON
        }
        // ! used?
        case "DELETE_EDGE": {
            return { ...state, "elements": removeEdgeFromElements(payload.edge) }
        }

        case "UPDATE_EDGE": {
            const newEdge = {
                id: `e${payload.newConnection.source}${payload.newConnection.sourceHandle}-${payload.newConnection.target}${payload.newConnection.targetHandle}`,
                source: payload.newConnection.source,
                target: payload.newConnection.target,
                sourceHandle: payload.newConnection.sourceHandle,
                targetHandle: payload.newConnection.targetHandle
            }
            return { ...state, "elements": removeEdgeFromElements(payload.oldEdge).concat(newEdge) }
        }
        case "SELECT_HANDLE": {
            return { ...unselectAll(state), "selectedHandle": payload }
        }

        case "OPEN_GENERAL_SETTINGS": {
            return { ...unselectAll(state), "generalSettingsOpen": true }
        }

        case "CLOSE_GENERAL_SETTINGS": {
            return { ...unselectAll(state) }
        }

        case "EDIT_GENERAL_SETTINGS": {
            return { ...unselectAll(state), "generalSettings": payload, "past": newPast(state, type), "future": [] }
        }
        // ! used?
        case "START_DRAWING_EDGE": {
            const index = state.elements.findIndex((element: any) => element.id === payload.id)
            return { ...state, "drawingEdge": { ...payload, type: state.elements[index].type } }
        }
        // ! used?
        case "STOP_DRAWING_EDGE": {
            return { ...state, "drawingEdge": null }
        }

        case "ENABLE_GOTO_SELECTION": {
            return { ...state, "goToSelection": true }
        }

        case "DISABLE_GOTO_SELECTION": {
            return { ...unselectAll(state) }
        }

        case "SELECT_GOTO_NODE": {
            return {
                ...state, "selected": {
                    ...state.selected,
                    data: {
                        ...state.selected.data, continueWith: payload
                    }
                },
                "goToSelection": false,
                "goToHover": null,
                "past": newPast(state, type), "future": []
            }
        }

        case "HOVER_NODE_GOTO": {
            return { ...state, "goToHover": payload }
        }

        case "LEAVE_NODE_GOTO": {
            return { ...state, "goToHover": null }
        }

        case "CANCEL_SELECT": {
            // TODO in use?
            return { ...unselectAll(state) }
        }

        case "START_TESTCHAT": {
            return { ...unselectAll(state), testChatActive:true }
        }

        case "END_TESTCHAT": {
            // TODO exchange all end_testchat dispatches with cancel_all_select dispatch
            return { ...state, "testChatActive": false, currentChatSimNode: null }
        }

        case "CANCEL_ALL_SELECT": {
            return { ...unselectAll(state) }
        }

        case "UNDO": {
            return undo(state)
        }

        case "REDO": {
            return redo(state)
        }

        case "CURRENT_CHATSIM_NODE": {
            return { ...state, currentChatSimNode: payload }
        }

        case "OPEN_NODE_MENU":{
            return { ...unselectAll(state), nodeMenuOpen: true}
        }

        case "OPEN_CHAT_SETTINGS": {
            if (state.chatSettingsMenuOpen) {
                return { ...state, chatSettingsMenuOpen: false }
            } else {
                return { ...state, chatSettingsMenuOpen: true }
            }
        }

        case "UPDATE_CHAT_SETTINGS": {

            console.log(payload)
            return {...state, chatSettings: {...payload.values} }
        }

        case "ADD_ID":{
            return {...state, id: payload}
        }
        default:
            return state
    }
}

export default reducer