import axios from "axios"

export const get = <R=void>(path:string, params?:object) => axios.get<R>(apiPath(path), { params }).then(response => response.data)

export const post =<R=void> (path:string, params?:object) => axios.post<R>(apiPath(path), params).then(response => response.data).catch(error => { throw (error.response.data) })

export const put = <R=void>(path:string, params?:object) => axios.put<R>(apiPath(path), params).then(response => response.data).catch(error => { throw (error.response.data) })

export const remove = (path: string) => axios.delete<void>(apiPath(path)).catch(error => { throw (error.response.data) })

export const download = (path: string, filename: string, params: object = {}) => {
  return axios({
      url: apiPath(path),
      method: 'post',
      responseType: 'blob',
      data: params
  } ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
  });

}

const apiPath = (path:string) => `/api/${path}`