import { withCrud, CrudModel, BaseModel } from "./builder";
import templateApi, { ITemplate } from "./template"
import recipientGroupApi, { IRecipientGroup } from "./recipientGroup"
import { insertPlaceholders } from "util/placeholders"
import { FormValues } from "../routes/app/stock/webforms/wizard/steps/FirstStep"
import { IEmailSettings, IState } from "../routes/app/stock/webforms/wizard/WebformWizard"
import { downloadPrebuildConfig, downloadPrebuildHtml } from '../util/download';
import domains from "models/domain"
import { post } from "api/crud";

export enum TemplateKey {
    formLP="formLP",
    soiLP="soiLP",
    doiMail="doiMail",
    doiLP="doiLP",
    doneMail="doneMail"
}

export type IPrebuild = {
    config: IConfig
    templates: {
        formLP: string
        soiLP: string
        doiMail: string
        doiLP: string
        doneMail: string
    }
}

export type IConfig = {
    key: string
    name: string
    groups: { name: string, placeholders: string[], faIcon: string, iconType: string }[]
    placeholders: Record<string, IPlaceholder>
}

export type IPlaceholder = {
    key: string
    label: string
    default: any
    value?: any
    type: "color" | "tinymce" | "number" | "text" | "string" | "unsplash" | "slider"
    typeParams?: any
    props?: any
}

export type IWebform = {
    dopt_in_response_redirection: string
    dopt_in_response_template_id: number | null
    dopt_in_template_id: number
    hid: string
    id: number
    name: string
    opt_in_response_redirection: string
    opt_in_response_template_id: number | null
    recipient_group_id: number
    report_interval: "daily" | "weekly" | "monthly" | null
    selected_recipient_fields: [{ key: string, required: boolean }]
    sender_domain_id: number
    sender_email_local_part: string
    sender_name: string
    use_doi_chain: boolean
    welcome_template_id: number
    form_template_id?: number
    config?: IConfig
    from_cornerstone: boolean,
    chat_id: number | null
}

type CustomMethods = {
    getFormUrl: (args: IWebform) => Promise<string>
    createWebformFromWebformWizard: (values: FormValues, result: IState, emailSettings: IEmailSettings) => Promise<IWebform>,
    getPrebuild: (key: string) => Promise<IPrebuild>,
    getTemplates: (webform: IWebform, templateKeys?: TemplateKey[]) => Promise<[ITemplate | null, ITemplate | null, ITemplate | null, ITemplate | null, ITemplate | null]>,
    addChatIds: (webforms: IWebform[], id:number) => void
    removeChatIds: (webforms: IWebform[], id:number) => void
}

export type WebformApi = CrudModel<IWebform> & BaseModel & CustomMethods

let webforms: WebformApi = {
    name: "webforms",
    ...withCrud<IWebform>("webforms"),
    getFormUrl: async ({ hid }: IWebform): Promise<string> => {
        const domain = await domains.get_first()
        return `https://${domain.domain}.cornermail.de/webform/${hid}/form`
    },

    getPrebuild: async (key: string) => {
        const [config, formLP, soiLP, doiMail, doiLP, doneMail] = await Promise.all([
            downloadPrebuildConfig(key),
            downloadPrebuildHtml(key, "form_lp"),
            downloadPrebuildHtml(key, "soi_lp"),
            downloadPrebuildHtml(key, "doi_mail"),
            downloadPrebuildHtml(key, "doi_lp"),
            downloadPrebuildHtml(key, "done_mail")
        ])

        return {
            config: config as IConfig,
            templates: {
                formLP: formLP as string,
                soiLP: soiLP as string,
                doiMail: doiMail as  string,
                doiLP: doiLP as string,
                doneMail: doneMail as string
            }
        }
    },
    addChatIds: async (webforms: IWebform[], id: number) => post('webforms/add_chat_id', {webforms, id}),

    removeChatIds: async (webforms: IWebform[], id: number) => post('webforms/remove_chat_id', {webforms, id}),

    
    getTemplates: async (webform: IWebform, templateKeys: TemplateKey[] = [TemplateKey.formLP, TemplateKey.soiLP, TemplateKey.doiMail, TemplateKey.doiLP, TemplateKey.doneMail]) => {
        console.log("get: ", webform.id, templateKeys, TemplateKey.formLP)
        return Promise.all([
            templateKeys.includes(TemplateKey.formLP) && webform.form_template_id ? templateApi.get(webform.form_template_id) : Promise.resolve(null),
            templateKeys.includes(TemplateKey.soiLP) && webform.opt_in_response_template_id ? templateApi.get(webform.opt_in_response_template_id): Promise.resolve(null),
            templateKeys.includes(TemplateKey.doiMail) && webform.dopt_in_template_id ? templateApi.get(webform.dopt_in_template_id) : Promise.resolve(null),
            templateKeys.includes(TemplateKey.doiLP) && webform.dopt_in_response_template_id ? templateApi.get(webform.dopt_in_response_template_id) : Promise.resolve(null),
            templateKeys.includes(TemplateKey.doneMail) && webform.welcome_template_id ? templateApi.get(webform.welcome_template_id) : Promise.resolve(null)
        ])
    },
    createWebformFromWebformWizard: (values, result, emailSettings) => {
        return new Promise((res, rej) => {
            if (!result.formLP?.template || !result.soiLP?.template || !result.doiMail?.template || !result.doiLP?.template || !result.doneMail?.template) {
                return
            }

            // removing logoURL from placeholders to insert it in backend
            delete result.prebuild?.config.placeholders.logoURL
            if (result && result.prebuild) {
                result.prebuild.config.groups[0].placeholders = ["logoSize"]
            }
            
            const promises: [Promise<ITemplate>, Promise<ITemplate>, Promise<ITemplate>, Promise<ITemplate>, Promise<ITemplate>, Promise<IRecipientGroup>] = [
                templateApi.create({ name: `${values.name}-form-lp`, from_cornerstone: true, subject: emailSettings.formLP.subject.value, mime_type: "html", body_html: insertPlaceholders(result?.formLP?.template, result.formLP.placeholders) }),
                templateApi.create({ name: `${values.name}-soi-lp`, from_cornerstone: true, subject: emailSettings.soiLP.subject.value, mime_type: "html", body_html: insertPlaceholders(result?.soiLP?.template, result.soiLP.placeholders) }),
                templateApi.create({ name: `${values.name}-doi-mail`, from_cornerstone: true, subject: emailSettings.doiMail.subject.value, mime_type: "html", body_html: insertPlaceholders(result?.doiMail?.template, result.doiMail.placeholders) }),
                templateApi.create({ name: `${values.name}-doi-lp`, from_cornerstone: true, subject: emailSettings.doiLP.subject.value, mime_type: "html", body_html: insertPlaceholders(result?.doiLP?.template, result.doiLP.placeholders) }),
                templateApi.create({ name: `${values.name}-done-mail`, from_cornerstone: true, subject: emailSettings.doneMail.subject.value, mime_type: "html", body_html: insertPlaceholders(result?.doneMail?.template, result.doneMail.placeholders) }),
                recipientGroupApi.create({ name: `${values.name}` })
            ]

            Promise.all(promises).then(([formLP, soiLP, doiMail, doiLP, doneMail, recipientGroup]) => {
                webforms.create({
                    name: values.name,
                    sender_name: values.senderName,
                    sender_email_local_part: values.senderEmailLocalPart,
                    sender_domain_id: values.senderEmailDomain?.id,
                    recipient_group_id: recipientGroup.id,
                    opt_in_response_template_id: soiLP.id,
                    dopt_in_template_id: doiMail.id,
                    dopt_in_response_template_id: doiLP.id,
                    welcome_template_id: doneMail.id,
                    use_doi_chain: values.useDoiChain,
                    selected_recipient_fields: [{ key: "email", required: true }],
                    form_template_id: formLP.id,
                    config: result?.prebuild?.config,
                    from_cornerstone: true,
                    chat_id: result.chat
                })
                .then(webform => {
                    const formLPWithActionBodyHtml = insertPlaceholders(formLP.body_html, {
                        "formAction": {
                            key: "formAction",
                            default: `http://tlnk.test.cornerstone.de/webforms/${webform.hid}/subscribe` //TODO domain here
                        }
                    })
                    templateApi.update(formLP.id, { body_html: formLPWithActionBodyHtml }).then(() => {
                        res(webform)
                    })
                })

            })
        })
    }
}
const originalRemove = webforms.remove
webforms = {
    ...webforms,
    remove: async (id: number) => {
        const data = await webforms.get(id)

        recipientGroupApi.remove(data.recipient_group_id)

        templateApi.remove(data.welcome_template_id)

        if (data.form_template_id) {
            templateApi.remove(data.form_template_id)
        }

        templateApi.remove(data.dopt_in_template_id)

        if (data.dopt_in_response_template_id) {
            templateApi.remove(data.dopt_in_response_template_id)
        }

        if (data.opt_in_response_template_id) {
            templateApi.remove(data.opt_in_response_template_id)
        }
        return originalRemove(id)
    },
}


export default webforms